import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime, fromEvent, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputComponent implements AfterViewInit, OnDestroy {
  @Input() control: FormControl;
  @Input() classNames: string;
  @Input() set refValue(v: string | number) {
    this._refValue = this.isCredit ? +Math.abs(+v) * -1 : v;
    if (v && this._value && this.showDiff) {
      this._refDiff = Math.abs(+this._refValue) - Math.abs(+this._value);
    }
    this._refDisplayValue = this._decimalPipe.transform(v, '1.2-2');
  }
  @Input() set value(v: string | number) {
    this._value = v;
    if (v && this._refValue && this.showDiff) {
      this._refDiff = Math.abs(+this._refValue) - Math.abs(+this._value);
    }
    if (this._init) {
      this.transform(this._value, true);
    }
  }
  @Input() alwaysNegative: boolean = false;
  @Input() diff: string | number;
  @Input() isCredit: boolean = false;
  @Input() showDiff: boolean = false;
  @Output() valueChange = new EventEmitter<number>();
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  _value: string | number;
  _refValue: string | number;
  _refDisplayValue: string | number;
  _refDiff: string | number;
  _inputControl: FormControl = new FormControl();
  _decimalPipe = new DecimalPipe('en-US');
  _defaultVal: string = '0.00';
  _prevValue: string | number = this._defaultVal;
  _validateRegex = /[\d]{1,6}([.]\d{1,2})?/;
  _decRegex = /[0-9.-]/;
  _id: string = `number-input-${Math.random()}`;
  _init: boolean = false;
  unsubscribe$ = new Subject<void>();

  _keys: Record<string, string[]> = {
    ignore: ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'],
    allow: ['Backspace', 'Delete'],
  };

  get inputValue() {
    return this.input.nativeElement.value;
  }

  ngAfterViewInit() {
    this._init = true;
    this.transform(this._value, this._init);
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(2000),
        takeUntil(this.unsubscribe$),
        tap((e: any) => this.onKeydown(e))
      )
      .subscribe();
    this._inputControl.valueChanges.pipe(debounceTime(2000)).subscribe((v) => {
      this.transform(v);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onKeydown(e: KeyboardEvent) {
    e?.preventDefault?.();
    if (!this.inputValue) {
      this.input.nativeElement.value = this._defaultVal;
      this._prevValue = this._defaultVal;
      return;
    }

    const { key, target } = e;
    if (
      !this._decRegex.test(key) &&
      !this._keys.allow.includes(key as string) &&
      !this._keys.ignore.includes(key)
    ) {
      const v = e.target['value'] as string;
      this.input.nativeElement.value = v?.slice(0, -1);
    }
  }

  registerOnChange(onChange: any) {}

  registerOnTouched(onTouched: Function) {}

  writeValue(value: any) {}

  transform(value: string | number, init: boolean = false) {
    const val =
      typeof value === 'string' ? value.replaceAll(',', '') : `${value}`;
    if (this._decRegex.test(val)) {
      const v = this.alwaysNegative ? Math.abs(+val) * -1 : +val;
      this._prevValue = v;
      const decVal = this._decimalPipe.transform(v, '1.2-2');
      const numVal = Number(v);
      this.input.nativeElement.value = decVal;
      if (!init) {
        this.control?.setValue(numVal);
        this.valueChange.emit(numVal);
      }
    } else {
      this.control?.setValue(this._prevValue, { emitEvent: false });
    }
  }
}
