import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Image } from "@models/image";
import { AuthService } from "@services/auth.service";
import { environment } from "@environments/environment";
import { FileUploader, FileItem } from "ng2-file-upload";
import { ImageEditComponent } from "@components/admin/image/image-edit/image-edit.component";
import { ProductService } from "@services/product.service";
import { ImageService } from "@services/image.service";
import { OrderService } from "@services/order.service";
import { VendorService } from "@services/vendor.service";
import { DeleteComponent } from "@components/admin/dialog/delete/delete.component";
import { MatDialog } from "@angular/material/dialog";
import { ImageDialogComponent } from "@components/admin/image/image-dialog/image-dialog.component";
import { BehaviorSubject, Observable } from "rxjs";
import ObjectId from "bson-objectid";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.css"],
  providers: [ImageEditComponent],
})
export class ImageComponent implements OnInit {
  @Input() image: any = null;
  @Input() url: any = null;
  @Input() tmp = false;
  @Input() active = false;
  @Input() edit = false;
  @Input() delete = false;
  @Input() upload = false;
  @Input() autoCrop = false;
  @Input() productId: string = '';
  @Input() vendorId: string = '';
  @Input() width = 800;
  @Input() thumbnailWidth = 150;
  @Input() type = "";
  @Input() clickable = true;
  @Output() imageDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageReplace: EventEmitter<string> = new EventEmitter<string>();
  @Output() imageCrop: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageAdd: EventEmitter<string> = new EventEmitter<string>();
  @Output() imageUpload: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('image', {static: false}) imageRef: ElementRef;
  public uploaderProduct: FileUploader = new FileUploader({url: environment.base_url + '/image/product', itemAlias: 'photoProduct', disableMultipart: true});
  img: Image = new Image();
  // imageURL = environment.base_url + '/image/';
  imageHover = false;
  timestamp: number;
  finishedLoading = false;
  imageUrl: BehaviorSubject<any> = new BehaviorSubject("");
  thumbnailUrl: BehaviorSubject<any> = new BehaviorSubject("");
  // thumbnailUrl: Observable<any>;
  // imageUrl: Observable<any>;
  images: any[] = [];
  imageWidth: number = null;
  styleMaxWidth = "";
  rnd = Math.floor(Math.random() * 1000000);
  user;

  constructor(
    private auth: AuthService,
    private productService: ProductService,
    private imageService: ImageService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private orderService: OrderService,
    private vendorService: VendorService
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
    this.uploaderProduct.onAfterAddingFile = (item: FileItem) => {
      const objectKey = new ObjectId().toString();
      this.images.push(objectKey);
      this.imageService
        .getUploadUrl(objectKey, item.file.type)
        .subscribe((url) => {
          item.url = url;
          item.method = "PUT";
          item.headers = [{ name: "Content-Type", value: item.file.type }];
          item.withCredentials = false;
          item.upload();
        });
    };

    this.uploaderProduct.onCompleteAll = () => {
      for (let x = 0; x < this.images.length; x++) {
        if (this.autoCrop) {
          this.clickCrop(this.images[x], true);
        } else {
          this.imageAdd.emit(this.images[x]);
        }
      }
      this.images = [];
    };
  }

  ngAfterViewInit() {
    this.imageWidth = this.imageService.getThumbnailWidth(this.type);
    this.styleMaxWidth = `${this.imageWidth}px`;
    this.cdRef.detectChanges();
    // console.log(this.url)

    if (this.image) {
      this.setImage(this.image);
    } else if (!this.url) {
      if (this.productId && this.productId !== 'NEW') {
        // this.productService.getProduct(this.productId).subscribe(product => {
        //   if (product && product.images && product.images[0]) {
        //     this.image = product.images[0];
        //     this.setImage(this.image);
        //   }
        // });
      }
    }
  }

  setImage(imageId: string) {
    this.img._id = imageId;
    this.cdRef.detectChanges();
    if (this.tmp) {
      this.imageService
        .getTmpThumbnailUrl(imageId, this.imageWidth)
        .subscribe((res) => {
          this.thumbnailUrl.next(res);
        });
    } else {
      if (this.url) {
        setTimeout(() => {
          this.thumbnailUrl.next(this.url);
        });
      } else {
        this.imageService
          .getThumbnailUrl(imageId, this.imageWidth)
          .subscribe((res) => {
            this.thumbnailUrl.next(res);
          });
      }
    }
  }

  clickDelete(imageId) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "250px",
      data: { message: "DELETE IMAGE?" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {

        this.deleteImage(this.productId, imageId);




        // this.productService
        //   .deleteImage(this.productId, imageId)
        //   .subscribe((res) => {
        //     this.imageDelete.emit(true);
        //   });



      }
    });
  }

  deleteImage(productId, deleteImageId, replacementImageId:any = true) {
    let deleteService: Observable<any>;
    if (this.type == 'order') {
      deleteService = this.orderService.deleteImage(productId, deleteImageId);
    } else if (productId) {
      deleteService = this.productService.deleteImage(productId, deleteImageId);
    } else if (this.vendorId) {
      deleteService = this.vendorService.deleteImage(this.vendorId, deleteImageId);
    } else {
      deleteService = this.imageService.deleteImage(deleteImageId);
    }

    deleteService.subscribe((res) => {
      this.imageDelete.emit(replacementImageId);
    });
  }

  replaceImage(imageId) {
    this.deleteImage(this.productId, this.image, imageId);
    // if (this.type == 'order') {
    //   this.orderService.deleteImage(this.productId, this.image).subscribe((res) => {
    //     this.imageReplace.emit(imageId);
    //   });
    // } else if (this.productId) {
    //   this.productService.deleteImage(this.productId, this.image).subscribe((res) => {
    //     this.imageReplace.emit(imageId);
    //   });
    // } else {
    //   this.imageService.deleteImage(this.image).subscribe((res) => {
    //     this.imageDelete.emit(imageId);
    //   });
    // }
  }

  clickCrop(imageId, newImage: boolean= false) {
    const dialogRef = this.dialog.open(ImageEditComponent, {
      // width: '600px',
      // height: '500px',
      width: "",
      height: "",
      data: {
        image: imageId,
        message: "",
        tmp: this.tmp,
        productId: this.productId,
      },
    });
    const sub = dialogRef.componentInstance.imageChange.subscribe((img) => {
      this.timestamp = new Date().getTime();
      // this.img.thumbnail = this.imageURL + imageId + '/thumbnail/1?' + ((this.timestamp) ? 'timestamp=' + this.timestamp + '&' : '') + 'bearer='+this.auth.getToken();
      // this.img.image = this.imageURL + imageId + '/thumbnail/0?' + ((this.timestamp) ? 'timestamp=' + this.timestamp + '&' : '') + 'bearer='+this.auth.getToken();
      this.imageCrop.emit(true);
      if (newImage) {
        this.imageAdd.emit(imageId);
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  clickUpload(imageId, newImage: boolean= false) {
    const dialogRef = this.dialog.open(ImageEditComponent, {
      // width: '600px',
      // height: '500px',
      width: '',
      height: '',
      data: { image: imageId, message: '', tmp: this.tmp, productId: this.productId, type: this.type }
    });
   const sub = dialogRef.componentInstance.imageChange.subscribe((img) => {
      this.timestamp = new Date().getTime();
      // this.img.thumbnail = this.imageURL + imageId + '/thumbnail/1?' + ((this.timestamp) ? 'timestamp=' + this.timestamp + '&' : '') + 'bearer='+this.auth.getToken();
      // this.img.image = this.imageURL + imageId + '/thumbnail/0?' + ((this.timestamp) ? 'timestamp=' + this.timestamp + '&' : '') + 'bearer='+this.auth.getToken();
      this.imageCrop.emit(true);
      if (newImage) {
        this.imageAdd.emit(imageId);
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });

  }

  imageMouseEnter() {
    this.imageHover = true;
  }

  imageMouseLeave() {
    this.imageHover = false;
  }

  submit() {}

  openImage(imageId) {
    if (this.user.role === "RENTAL" || this.clickable === false) {
      return;
    }
    if (imageId.startsWith("data:")) {
      this.imageUrl.next(imageId);
      this.openImageDialog(imageId, imageId);
      return;
    }
    if (this.tmp) {
      this.imageService.getTmpImageUrl(imageId).subscribe((res) => {
        this.imageUrl.next(res);
        this.openImageDialog(imageId, res);
      });
    } else {
      this.imageService.getImageUrl(imageId).subscribe((res) => {
        this.imageUrl.next(res);
        this.openImageDialog(imageId, res);
      });
    }
  }

  openImageDialog(imageId, imageUrl) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "",
      height: "",
      data: { image: imageUrl, imageId: imageId, message: "" },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    dialogRef.componentInstance.imageDelete.subscribe((imageId) => {
      dialogRef.close();
      this.clickDelete(imageId);
    });
    dialogRef.componentInstance.imageReplace.subscribe((imageId) => {
      dialogRef.close();
      this.replaceImage(imageId);
    });
    dialogRef.componentInstance.imageCrop.subscribe((imageId) => {
      dialogRef.close();
      this.clickCrop(imageId);
    });

    dialogRef.componentInstance.imageUpload.subscribe((imageId) => {
      dialogRef.close();
      this.clickUpload(imageId);
    });

    dialogRef.componentInstance.imageAdd.subscribe((imageId) => {
      dialogRef.close();
      this.imageAdd.emit(imageId);
    });
  }

  clickButton($event) {
    // $event.preventDefault();
    $event.stopPropagation();
  }
}
