import { Component, OnInit, Input, Output, ChangeDetectorRef, ViewChild, HostListener, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Product, ProductHistory } from '@models/product';
import { VendorOrderService } from '@services/vendor-order.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { concatMap, debounceTime, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { mockProductHistory } from 'src/test/mock/data/productHistory';
import { ImageDialogComponent } from '@components/admin/image/image-dialog/image-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageService } from '@services/image.service';
@Component({
  selector: 'app-inventory-history',
  templateUrl: './inventory-history.component.html',
  styleUrls: ['./inventory-history.component.css'],
})
export class InventoryHistoryComponent implements OnInit {
  @Input() public product: FormGroup = null;
  @Input() public showParts: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Output() clickField = new EventEmitter<boolean>();
  dataSource = new MatTableDataSource<ProductHistory>();
  displayedColumns: string[] = [
    'invoiceNumber',
    'date',
    'cost',
    'tax',
    'shipping',
    'duty',
    'patientInvoiceNumber',
    'patientStock',
    'status',
    'log',
  ];
  defaultSortColumn: string = 'date';
  loading: boolean = true;
  fieldOpen: boolean = false;
  imageOpen: string | null;
  imageDialogRef: MatDialogRef<ImageDialogComponent>;

  constructor(
    private vendorOrderService: VendorOrderService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private imageService: ImageService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.product.value._id) {
      this.getProductHistory();
    } else {
      this.loading = false;
      this.cdRef.detectChanges();
    }
  }

  getProductHistory() {
    console.log('getting history');
    let historyPromise: Observable<ProductHistory[]> = this.showParts
      ? this.vendorOrderService.getPartHistory(this.product.value._id)
      : this.vendorOrderService.getProductHistory(this.product.value._id);
    historyPromise.subscribe((productHistory: ProductHistory[]) => {
      this.dataSource = new MatTableDataSource<ProductHistory>(productHistory);
      this.loading = false;
      this.cdRef.detectChanges();

      // this.dataSource.sort = this.sort;
      this.setSorter();
      this.cdRef.detectChanges();
    });
  }

  // clickField($event, product, index) {
  //   this.fieldOpen = true;
  // }

  clickRow($event: Event) {
    this._clickField();
    $event.preventDefault();
    $event.stopPropagation();
    // this.fieldOpen = false;
    this.cdRef.detectChanges();
  }

  @HostListener('document:click', ['$event']) clickOutside($event) {
    if (!this.fieldOpen) {
      // if (this.vendorOrderId != "" && this.vendorOrderId != undefined) {
      //   //this.saveEditedProduct(this.editProduct, this.productIndex);
      // }
      // this.vendorOrderId = "";
    } else {
      this.fieldOpen = false;
    }
  }

  _clickField() {
    this.clickField.emit(true);
  }

  setSorter() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'invoiceNumber':
          return item.invoiceNumber.toUpperCase();
          break;
        case 'date':
          return item.date;
          break;
        case 'cost':
          return item.order.products[0].cost;
          break;
        case 'tax':
          return item.order.products[0].tax;
          break;
        case 'shipping':
          return item.order.products[0].shipping;
          break;
        case 'duty':
          return item.order.products[0].duty;
          break;
        case 'notes':
          return item.order.notes.toUpperCase();
          break;
        case 'patientInvoiceNumber':
          return item.order.invoiceNumber.toUpperCase();
          break;
        case 'patientStock':
          return item.patient
            ? item.patient.lastName.toUpperCase() +
                ',' +
                item.patient.firstName.toUpperCase()
            : 'STOCK';
          break;
        case 'status':
          return item.status.toUpperCase();
          break;
        default:
          return item[property].toUpperCase();
      }
    };
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
  }

  openPatient(patientId: string | number) {
    console.log(patientId);
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/admin/patient', patientId])
    );
    window.open(url, '_blank');
  }

  openImage(imageId?: string) {
    this.imageService.getImageUrl(imageId).subscribe((res) => {
      this.imageDialogRef = this.dialog.open(ImageDialogComponent, {
        width: '',
        height: '',
        data: { image: res, imageId: imageId, message: '' },
      });
    });
  }

  onClose() {
    this.imageOpen = null;
  }
}
