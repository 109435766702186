import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@models/store';
import { StoreService } from '@services/store.service';
import { AuthService } from '@services/auth.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ApiResponse } from '@classes/api-response';
import { Vendor } from '@models/vendor';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import ObjectID from 'bson-objectid';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { VendorOrder } from '@models/vendor-order';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss'],
})
export class ManagedBoardCreditComponent implements OnInit, OnDestroy {
  currentStore: Store = new Store();
  unsubscribe$ = new Subject<void>();
  creditForm: FormGroup;
  logForm: FormGroup;
  vendors: Array<Vendor>;
  today = new Date().toISOString();
  storeColor = localStorage.getItem('storeColor');
  order: VendorOrder;
  vendor: Vendor;
  storeName: string;

  private readonly staticFields = {
    type: 'order',
    borrowingStore: null,
    groupId: '',
    raNumber: '',
    trackingNumber: '',
    proposedCredit: '',
    datePaid: null,
    archived: false,
    store: this.currentStore._id,
  };

  constructor(
    private readonly vendorOrderService: VendorOrderService,
    private readonly vendorService: VendorService,
    private readonly storeService: StoreService,
    private readonly auth: AuthService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.auth
      .getStoreObjectMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((store: Store) => {
        this.currentStore = store;
      });
  }

  ngOnInit() {
    this.storeName = this.auth.getStore();
    this.setupForm();
    this.getVendors();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getVendors() {
    this.vendorService
      .getVendorList()
      .pipe(
        take(1),
        tap((vendors: Array<Vendor>) => {
          this.vendors = vendors.filter((v) => !v.distributor);
          console.log(this.vendors);
        })
      )
      .subscribe();
  }

  mapVendors(res: ApiResponse<any[]>): Array<Vendor> {
    return res.vendorOrderCounts
      ?.filter((voc) => !!voc.vendor)
      .map((voc: any) => voc.vendor);
  }

  setupForm() {
    this.logForm = new FormGroup({
      _id: new FormControl(new ObjectID().toString()),
      notes: new FormControl(),
      initials: new FormControl(null, Validators.required),
      date: new FormControl(new Date().toISOString()),
      user: new FormControl(this.auth.currentUser._id),
    });
    this.creditForm = new FormGroup({
      _id: new FormControl(new ObjectID().toString()),
      vendor: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      products: new FormArray([]),
      status: new FormControl('CREDITED'),
      order: new FormArray([]),
      shipping: new FormControl(null),
      tax: new FormControl(null),
      total: new FormControl(null, Validators.required),
      log: new FormArray([this.logForm]),
      store: new FormControl(this.currentStore._id),
      invoiceNumber: new FormControl(null, Validators.required),
    });
    this.cd.detectChanges();

    this.creditForm.controls.tax.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter((value) => !isNaN(+value) && value > 0),
        map((value) => Math.abs(value) * -1)
      )
      .subscribe((tax) => this.creditForm.patchValue({ tax }));
    this.creditForm.controls.shipping.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter((value) => !isNaN(+value) && value > 0),
        map((value) => Math.abs(value) * -1)
      )
      .subscribe((shipping) => this.creditForm.patchValue({ shipping }));
    this.creditForm.controls.total.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter((value) => !isNaN(+value) && value > 0),
        map((value) => Math.abs(value) * -1)
      )
      .subscribe((total) => this.creditForm.patchValue({ total }));
    this.creditForm.controls.invoiceNumber.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((value) => this.getOrder());
    this.creditForm.controls.vendor.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((value) => this.getOrder());
  }

  getOrder() {
    const { vendorId, invoiceNumber } = this.creditForm.value;
    if (!vendorId || !invoiceNumber) {
      return;
    }
    this.vendorOrderService
      .getVendorOrderByInvoiceNumber(vendorId, invoiceNumber)
      .pipe(
        tap((orders: any) => {
          this.order = orders?.[0];
          if (this.order) {
            const { tax, shipping, total, date } = this.order;
            this.creditForm.patchValue({
              tax: -tax,
              shipping: -shipping,
              total: -total,
              date: new Date(date),
            });
            this.cd.detectChanges();
          } else {
            ['tax', 'shipping', 'total', 'date'].forEach((key) => {
              this.creditForm.controls?.[key]?.setValue?.(null);
            });
          }
        })
      )
      .subscribe();
  }

  updateVendorOrder() {
    const {
      value,
      value: { shipping, tax, total, date },
    } = this.creditForm;
    const { log } = this.order ?? {};
    const payload = {
      ...value,
      ...(this.order ?? {}),
      status: 'CREDITED',
      log: [...(log ?? []), this.logForm.value],
      shipping: +Number(shipping ?? 0).toFixed(2),
      tax: +Number(tax ?? 0).toFixed(2),
      total: +Number(total).toFixed(2),
      dateAdded: date,
    };

    if (this.order) {
      this.vendorOrderService
        .updateVendorOrder(payload._id, payload)
        .pipe(take(1))
        .subscribe(() => {
          this.creditForm.reset();
          this.logForm.reset();
        });
    } else {
      this.vendorOrderService
        .addVendorOrder({ ...this.staticFields, ...payload })
        .pipe(take(1))
        .subscribe(() => {
          this.creditForm.reset();
          this.logForm.reset();
        });
    }
  }
}
