import { Component, OnInit, ChangeDetectorRef, Input, ViewChild} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { VendorOrderService } from '@services/vendor-order.service';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { VendorBaseComponent } from '@components/admin/inventory/vendor-base/vendor-base.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiResponse } from '@classes/api-response';
import { Subscription } from 'rxjs';
import { Store } from '@models/store';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: [
    './returns.component.css',
    './../base/vendors-base/vendors-base.component.css',
    './../managed-board.component.css',
  ],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ReturnsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<VendorOrders> =
    new MatTableDataSource<VendorOrders>();
  vendorOrders: VendorOrders[] = [];
  displayedColumns = ['vendor', 'count'];
  expandedElement = null;
  storeSubscription: Subscription;
  currentStore: Store;
  constructor(
    public auth: AuthService,
    public vendorOrderService: VendorOrderService,
    public cdRef: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {
    this.storeSubscription = this.auth
      .getStoreObjectMessage()
      .subscribe((store: Store) => {
        this.currentStore = store;
      });
  }

  ngOnInit() {
    this.getVendorOrders();
  }

  getVendorOrders() {
    this.vendorOrderService
      .getVendorOrders('return')
      .subscribe((res: ApiResponse<VendorOrders[]>) => {
        this.vendorOrders = res.vendorOrders;
        this.dataSource = new MatTableDataSource<VendorOrders>(
          this.vendorOrders
        );
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'vendor':
            // if (item.vendor) {
            //   let vendor: Object = item.vendor;
            //   return vendor['name'];
            // } else {
            //   return item[property];
            // }
            //case 'vendor.name': return item.vendor.name;
            default:
              return item[property];
          }
        };
        this.dataSource.sort = this.sort;

        this.cdRef.detectChanges();
      });
  }

  toggleExpanded($event, element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

  refreshVendorOrders() {
    this.expandedElement = null;
    this.getVendorOrders();
  }

  removeFrame(orderId: string, productId: string) {
    const vendorOrder = this.vendorOrders.find((vendorOrder) =>
      vendorOrder.vendorOrders.find((vo: VendorOrder) => vo._id === orderId)
    );
    const orderIndex = vendorOrder.vendorOrders.findIndex(
      (vo: VendorOrder) => vo._id === orderId
    );
    const vo = vendorOrder.vendorOrders[orderIndex];
    const productIndex = vo.products.findIndex(
      (product) => product._id === productId
    );
    const { [productIndex]: product, ...products } = vo.products;
    vo.products = products;
  }
}
