<!--<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="SEARCH INVENTORY" (input)="productSearch()">
</mat-form-field>-->
<div *ngIf="!dataSource.data">
  <h3 style="text-align:center;">LOADING RETURNS AWAITING CREDIT</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div [formGroup]="vendorOrdersForm" *ngIf="dataSource.data">
  <mat-table #table [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumn" matSortDirection="asc" formArrayName="vendorOrders" multiTemplateDataRows>

	  <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 50px" style="padding-left:0px !important;">

      </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex; let row;" fxFlex="1 1 50px">
      	<mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelect(vendorOrder) : null"
          [checked]="selection.isSelected(vendorOrder)">
				</mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> # </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="0 0 40px">
      	<span>{{ vendorOrder.value.products.length }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="raNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> RA # </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%" [formGroupName]="i">
      	<span *ngIf="vendorOrderId!=vendorOrder.value._id">{{ vendorOrder.value.raNumber }}</span>
				<mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" floatLabel="never">
          <input #formRow matInput placeholder="RA #" type="text" formControlName="raNumber">
        </mat-form-field>
      </mat-cell>
    </ng-container>

		<ng-container matColumnDef="date">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> DATE SHIPPED </mat-header-cell>
	    <mat-cell *matCellDef="let vendorOrder; let i=dataIndex; let row;" fxFlex="1 1 10%" [formGroupName]="i">
				<span *ngIf="vendorOrderId!=vendorOrder.value._id"> {{ vendorOrder.value.date | date:'M/d/yyyy': 'GMT' }} </span>
	      <mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" floatLabel="never" fxFlex="1 1 90%">
				  <input #formRow [matDatepicker]="picker" matInput placeholder="DATE" type="text" formControlName="date" (dateChange)="clickField()">
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	      	<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

    <ng-container matColumnDef="shippingMethod">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> SHIPPING METHOD </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%" [formGroupName]="i">
      	<span *ngIf="vendorOrderId!=vendorOrder.value._id">{{ vendorOrder.value.shippingMethod }}</span>
				<mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" floatLabel="never">
          <input #formRow matInput placeholder="SHIPPING METHOD" type="text" formControlName="shippingMethod">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="trackingNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> TRACKING # </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%" [formGroupName]="i">
      	<span *ngIf="vendorOrderId!=vendorOrder.value._id">{{ vendorOrder.value.trackingNumber }}</span>
				<mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" floatLabel="never">
          <input #formRow matInput placeholder="TRACKING #" type="text" formControlName="trackingNumber">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="proposedCredit">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 12%"> PROPOSED CREDIT </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 12%" [formGroupName]="i">
      	<span *ngIf="vendorOrderId!=vendorOrder.value._id">{{ vendorOrder.value.proposedCredit | currency }}</span>
				<mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" floatLabel="never" style="width:75% !important;">
          <input #formRow matInput placeholder="PROPOSED CREDIT" type="text" formControlName="proposedCredit">
        </mat-form-field>
			  <mat-icon *ngIf="vendorOrderId==vendorOrder.value._id" (click)="refreshProposedCredit($event,vendorOrder)">refresh</mat-icon>
      </mat-cell>
    </ng-container>

 		<ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 150px"></mat-header-cell>
	    <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="0 0 150px" [formGroupName]="i">
	    	<mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" style="width:35px !important;flex: 0 1 35px !important;padding-left:10px !important;" floatLabel="never">
		      <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
      	<button *ngIf="vendorOrderId==vendorOrder.value._id" mat-button type="button" (click)="saveVendorOrder(vendorOrder)" [disabled]="vendorOrder.invalid"><mat-icon>save</mat-icon><span>SAVE</span></button>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="vendorOrder!=expandedElement" [class.mat-elevation-z8]="vendorOrder==expandedElement" [@detailExpand]="vendorOrder == expandedElement ? 'expanded' : 'collapsed'">






					<mat-table #table [dataSource]="dataSource3" class="mat-elevation-z2">


            <ng-container matColumnDef="frame">
              <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                     <div *ngIf="framesSelected">
              <button mat-raised-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation?.();">
                <mat-icon>more_vert</mat-icon>
                <span>MODIFY {{ framesSelected }}</span>
                <span *ngIf="selection.selected.length != 1"> ITEMS</span>
                <span *ngIf="selection.selected.length == 1"> ITEM</span>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item type="button" (click)="removeFramesFromVendorOrder()" style="margin-left:10px;">RETURN TO INVENTORY</button>
              </mat-menu>
            </div>

              </mat-header-cell>
					      <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="1 1 10%">
              <mat-checkbox (click)="toggleSelectedFrame(product, vendorOrder)"
              [checked]="selectedFrames[product._id]">
            </mat-checkbox>					      </mat-cell>

            </ng-container>

					    <ng-container matColumnDef="image">
					      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> IMAGE </mat-header-cell>
					      <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="1 1 10%">
					        <app-image [image]="product.product.images[0]" [tmp]="false" *ngIf="product.product.images && product.product.images.length>0"></app-image>
					      </mat-cell>
					    </ng-container>

						  <ng-container matColumnDef="model">
						    <mat-header-cell *matHeaderCellDef fxFlex="1 1 15%"> MODEL </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="1 1 15%">
						    	<span>{{product.product.model}}</span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="color">
						    <mat-header-cell *matHeaderCellDef fxFlex="1 1 15%"> COLOR </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="1 1 15%">
						    	<span>{{product.product.color}}</span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="size">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> SIZE </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="0 0 50px">
						    	<span> {{product.product.size}} </span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="type">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> TYPE </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="0 0 60px">
						    	<span>{{product.type}}</span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="status">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"> </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="0 0 40px" [ngClass]="product.product.status">
						      <span>&nbsp;</span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="cost">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 1 60px"> COST </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="0 1 60px">
						      <span>{{product.cost | currency : 'USD' : 'symbol' : '1.0' }}</span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="retail">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 1 60px"> RETAIL </mat-header-cell>
						    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="0 1 60px">
						    	<span>{{product.retail | currency : 'USD' : 'symbol' : '1.0' }}</span>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="store">
					      <mat-header-cell *matHeaderCellDef fxFlex="1 1 5%"> STORE </mat-header-cell>
					      <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="1 1 5%">
									<span> {{ product.store.storeName }} </span>
					      </mat-cell>
					    </ng-container>

						  <mat-header-row
						  	*matHeaderRowDef="displayedColumns3"
						  	fxLayout="row"
						  	fxLayoutAlign="start stretch">
						  </mat-header-row>
						  <mat-row
						  	*matRowDef="let row; columns: displayedColumns3; let i=index; let even=even; let odd=odd;"
						  	fxLayout="row"
						  	fxLayoutAlign="start stretch"
						  	[ngClass]="{rowEven:even, rowOdd:odd}"
						  	style="border-left:1px solid #dfdfdf !important;">
						  </mat-row>
						</mat-table>













        </div>
      </mat-cell>
    </ng-container>
<!--
    <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="start stretch"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="start stretch" (click)="clickRow($event,row,i)" [ngClass]="{rowEven:even, rowOdd:odd}"></mat-row>
 -->
    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      fxLayout="row"
      fxLayoutAlign="start center">
    </mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;"
      fxLayout="row"
      fxLayoutAlign="start center"
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}"
      style="border-left:1px solid #dfdfdf !important;"
      (click)="clickRow($event,row,i)">
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>

  </mat-table>

  <div *ngIf="selection.hasValue()">
		<mat-table [dataSource]="dataSource2" class="mat-elevation-z2" fxFlex>
<!-- 			<ng-container matColumnDef="blank">
		    <mat-header-cell *matHeaderCellDef fxFlex="0 0 calc(60% + 50px)">  </mat-header-cell>
		    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 calc(60% + 50px)">

		    </mat-cell>
		  </ng-container> -->

		  <ng-container matColumnDef="invoiceNumber">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> INVOICE # </mat-header-cell>
		    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
		    	<mat-form-field floatLabel="never">
			      <input matInput required placeholder="INVOICE #" formControlName="invoiceNumber" style="text-align:center;">
			      <mat-error *ngIf="vendorOrdersForm.controls.invoiceNumber.hasError('required')"></mat-error>
			    </mat-form-field>
		    </mat-cell>
		  </ng-container>

	  	<ng-container matColumnDef="dateReceived">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> DATE RECEIVED </mat-header-cell>
		    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
		    	<mat-form-field floatLabel="never">
					  <input #formRow required [matDatepicker]="picker" matInput placeholder="DATE RECEIVED" type="text" style="text-align:center;" formControlName="dateReceived" (click)="clickField()">
					  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		      	<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
		    </mat-cell>
		  </ng-container>

  	  <ng-container matColumnDef="tax">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> TAX REFUNDED </mat-header-cell>
		    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
		    	<mat-form-field floatLabel="never">
			      <input matInput required placeholder="TAX REFUNDED" formControlName="tax" style="text-align:right;" (blur)="formatCurrency($event,vendorOrdersForm.controls.tax)" (keyup)="calculateDifference()">
			      <span matPrefix>$&nbsp;</span>
			    </mat-form-field>
		    </mat-cell>
		  </ng-container>

		  <ng-container matColumnDef="total">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> TOTAL CREDIT </mat-header-cell>
		    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
		    	<!-- <span>{{ order.value.total | currency }}</span> -->
		    	<mat-form-field floatLabel="never">
			      <input matInput required placeholder="TOTAL CREDIT" formControlName="total" style="text-align:right;" (blur)="formatCurrency($event,vendorOrdersForm.controls.total)" (keyup)="calculateDifference()">
			      <span matPrefix>$&nbsp;</span>
			    </mat-form-field>
		    </mat-cell>
		  </ng-container>

		  <ng-container matColumnDef="proposedCredit">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> COST </mat-header-cell>
		    <mat-cell *matCellDef="let total; let i=index; let row;" fxFlex="1 1 10%">
      		<span>{{ vendorOrdersForm.controls.cost.value | currency }}</span>
<!-- 		    	<mat-form-field floatLabel="never">
			      <input matInput required placeholder="TOTAL" formControlName="total" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.total)">
			      <span matPrefix>$&nbsp;</span>
			    </mat-form-field> -->
		    </mat-cell>
		  </ng-container>

		  <ng-container matColumnDef="taxPaid">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> TAX PAID </mat-header-cell>
		    <mat-cell *matCellDef="let total; let i=index; let row;" fxFlex="1 1 10%">
      		<span>{{ vendorOrdersForm.controls.taxPaid.value | currency }}</span>
<!-- 		    	<mat-form-field floatLabel="never">
			      <input matInput required placeholder="TOTAL" formControlName="total" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.total)">
			      <span matPrefix>$&nbsp;</span>
			    </mat-form-field> -->
		    </mat-cell>
		  </ng-container>

		  <ng-container matColumnDef="difference">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> DIFFERENCE </mat-header-cell>
		    <mat-cell *matCellDef="let total; let i=index; let row;" fxFlex="1 1 10%">
      		<span>{{ vendorOrdersForm.controls.difference.value | currency }}</span>
      	<!-- <span>{{ proposedCredit.value | currency }}</span> -->
<!-- 		    	<mat-form-field floatLabel="never">
			      <input matInput required placeholder="TOTAL" formControlName="total" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.total)">
			      <span matPrefix>$&nbsp;</span>
			    </mat-form-field> -->
		    </mat-cell>
		  </ng-container>

		  <ng-container matColumnDef="action">
		    <mat-header-cell *matHeaderCellDef fxFlex="1 1 250px">  </mat-header-cell>
		    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 250px" style="border-right:0px !important;">
		    	<mat-form-field *ngIf="selection.hasValue()" style="width:35px !important;flex: 0 1 35px !important;padding-left:10px;" floatLabel="never">
				    <input matInput required placeholder="" type="text" formControlName="initials">
				    <mat-label><mat-icon>gesture</mat-icon></mat-label>
				  </mat-form-field>
				  <button *ngIf="selection.hasValue()" mat-button type="button" (click)="markVendorOrdersPaid()" [disabled]="vendorOrdersForm.controls.initials.invalid"><mat-icon>save</mat-icon><span>MARK AS CREDITED</span></button>
		    </mat-cell>
		  </ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns2" fxLayout="row" fxLayoutAlign="start center"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns2; let i=dataIndex; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="start stretch" class="action-row"></mat-row>
		</mat-table>
	</div>
</div>
<!--
  <div *ngIf="selection.hasValue()">
	  <mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
	    <input matInput required placeholder="" type="text" formControlName="initials" (click)="clickField()">
	    <mat-label><mat-icon>gesture</mat-icon></mat-label>
	  </mat-form-field>
	  <button mat-button type="button" (click)="markVendorOrdersPaid()" [disabled]="vendorOrdersForm.controls.initials.invalid"><mat-icon>save</mat-icon><span>MARK AS PAID</span></button>
	</div>
</div> -->


