import { Sort } from '@angular/material/sort';

export const getPropertyValueForSort = (group: any, key: string) => {
  switch (key) {
    case 'invoiceDate':
    case 'datePaid':
    case 'date':
      return new Date(group[key]).getTime();
    case 'tax':
    case 'shipping':
    case 'total':
      return +group[key];
    default:
      if (['number', 'string'].includes(typeof group[key])) {
        return group[key];
      }
      return '';
  }
};

export const sortValue = (group: any, key: string) => {
  return getPropertyValueForSort(group, key);
};

export const sortCompare = (a: any, b: any, sort: Sort): number => {
  const { active, direction } = sort;
  const aPropVal = sortValue(a, active);
  const bPropVal = sortValue(b, active);
  if (!direction || (!aPropVal && !bPropVal) || aPropVal === bPropVal) {
    return 0;
  }
  const isLess = aPropVal > bPropVal;
  return (isLess && direction === 'asc') || (!isLess && direction === 'desc')
    ? 1
    : -1;
};;

export const sortTable = (sort: Sort, data: any[]) => {
  const { active, direction } = sort;
  if (!active || !direction) {
    return data;
  }
  return data.sort((a, b) => sortCompare(a, b, sort));
};
