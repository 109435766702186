<div class="example-container">
  <!--<app-autocomplete-patient mode="importCustomerImages" placeholder="SEARCH EXISTING CUSTOMERS" autofocus></app-autocomplete-patient>-->
  <div *ngIf="!patient">
    <h3 style="text-align:center;">LOADING PATIENT</h3>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <form [formGroup]="importPatientForm" class="form-horizontal" role="form" name="addPatientForm" id="addPatientForm" enctype="multipart/form-data"
    (keydown.enter)="$event.preventDefault();">
    <div *ngIf="patient">
      <mat-card>
        <mat-card-header>
          <div class="container" style="width:100%;" fxLayout="row" fxLayoutAlign="start start">
            <div fxFlex="20%">
              <mat-card-title>
                {{patient.lastName}}, {{patient.firstName}}
              </mat-card-title>
              <mat-card-subtitle>#{{patient.patientNumber}}</mat-card-subtitle>
            </div>
            <!-- 		        <div *ngIf="iOS">
							<label class="mat-raised-button" (click)="openDropbox()">OPEN DROPBOX</label>
						</div> -->
            <div>
              <input id="pastJobUpload" #fileInput type="file" accept="image/*,application/pdf" style="display:none;" name="photo" ng2FileSelect
                [uploader]="uploader" multiple />
              <label for="pastJobUpload" class="mat-raised-button">CHOOSE IMAGES</label>
            </div>
            <div fxFlex="20%" style="margin-left:10px;">
              <mat-progress-spinner mode="determinate" *ngIf="uploader.isUploading" diameter="36" [value]="uploader.progress"></mat-progress-spinner>
            </div>
            <div fxFlex="20%" style="margin-left: auto;">
              <mat-form-field style="width:100%">
                <mat-select placeholder="DISCOUNT STATUS" formControlName="discountStatus">
                  <mat-option value="">-</mat-option>
                  <mat-option *ngFor="let discount of discounts" [(value)]="discount.value">
                    {{ discount.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>

          <div formArrayName="items">
            <div *ngFor="let item of importPatientForm.controls.items['controls']; let i=index ">
              <mat-card>
                <mat-card-content>
                  <div class="container" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxLayoutGap.xs="0"
                    [formGroupName]="i">

                    <div style="width:100%">
                      <mat-card class="imageCard">
                        <mat-card-header class="imageHeader">
                          <mat-card-title class="imageCardTitle">UPLOADED IMAGES</mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="imageCardContent">


                          <div class="container" style="height:100%;" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="10px"
                            fxLayoutGap.xs="0">
                            <div fxFlex style="padding-top:10px;">
                              <app-image [image]="item.value.image" tmp="1"></app-image>
                            </div>

                            <div class="container" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="calc(100%-310px)">
                              <!-- {{item.value.order._id }}
<ng-container *ngIf="item.value.order._id">
{{item.value.order.products[0].product.vendor|json }}
</ng-container> -->

                              <mat-radio-group matInput formControlName="itemType">
                                <div style="width:300px;height:56px;" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                                  <div fxFlex>
                                    <mat-radio-button value="Order" checked="true" (change)="item.controls.order.controls.type.setValue('O')">
                                      <ng-container formGroupName="order">
                                        <mat-form-field style="width:100px;padding-top:6px">
                                          <mat-select placeholder="TYPE" formControlName="type">
                                            <mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">
                                              {{typeOption.viewValue}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </ng-container>
                                    </mat-radio-button>
                                  </div>
                                  <div fxFlex>
                                    <mat-radio-button value="Prescription"
                                      (change)="item.controls.order.controls.type.setValue('RX')">PRESCRIPTION</mat-radio-button>
                                  </div>
                                </div>
                              </mat-radio-group>

                              <ng-container formGroupName="order" *ngIf="item.value.itemType=='Order'">

                                <div style="width:300px;">

                                  <mat-form-field style="width:300px;">
                                    <mat-select #formRow placeholder="FRAME SOURCE" formControlName="_id"
                                      (selectionChange)="selectOrder($event,item,i)">
                                      <mat-option *ngFor="let order of orders" [value]="order._id">
                                        {{order.type}}&nbsp;&ndash;&nbsp;{{order.invoiceNumber }}
                                      </mat-option>
                                      <mat-option value="STOCK">ENCLOSED</mat-option>
                                      <mat-option value="P.O.F.">P.O.F.</mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <!-- <button mat-button><mat-icon>cancel</mat-icon></button> -->
                                </div>
                                <ng-container formGroupName="products">
                                  <ng-container [formGroupName]="0">
                                    <ng-container formGroupName="product">
                                      <div style="width:300px;">


                                        <mat-form-field style="width:100%"
                                          *ngIf="item.value.order._id && item.value.order._id != 'STOCK' && item.value.order._id != 'P.O.F.' && item.value.order.products[0].product"
                                          floatLabel="always">
                                          <input type="text" placeholder="VENDOR" matInput formControlName="vendor" [matAutocomplete]="auto">
                                          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayVendorName">
                                          </mat-autocomplete>
                                          <mat-label>VENDOR</mat-label>
                                        </mat-form-field>



                                        <app-autocomplete-vendors
                                          *ngIf="!item.value.order._id || (item.value.order._id == 'STOCK' || item.value.order._id == 'P.O.F.')"
                                          placeholder="VENDOR" [(productForm)]="item.controls.order.controls.products.controls[0].controls.product"
                                          [(fieldOpen)]="fieldOpen" fxFlex="0 0 300px"></app-autocomplete-vendors>
                                      </div>
                                      <div style="width:300px;">
                                        <mat-form-field style="width:100%"
                                          *ngIf="item.value.order._id && item.value.order._id != 'STOCK' && item.value.order._id != 'P.O.F.' && item.value.order.products[0].product">
                                          <input matInput placeholder="MODEL" type="text" formControlName="model">
                                        </mat-form-field>

                                        <app-autocomplete-vendor-products
                                          *ngIf="!item.value.order._id || (item.value.order._id == 'STOCK' || item.value.order._id == 'P.O.F.')"
                                          [(productForm)]="item.controls.order.controls.products.controls[0].controls.product"
                                          [(vendor)]="item.controls.order.value.products[0].product.vendor" placeholder="MODEL"
                                          (selected)="updateProduct($event,item.controls.order,i)" [(fieldOpen)]="fieldOpen" displayField="model"
                                          fxFlex="0 0 300px">
                                        </app-autocomplete-vendor-products><br />
                                      </div>
                                      <div style="width:300px;">
                                        <mat-form-field style="width:100%"
                                          *ngIf="item.value.order._id && item.value.order._id != 'STOCK' && item.value.order._id != 'P.O.F.' && item.value.order.products[0].product">
                                          <input matInput placeholder="COLOR" type="text" formControlName="color">
                                        </mat-form-field>

                                        <app-autocomplete-vendor-products
                                          *ngIf="!item.value.order._id || (item.value.order._id == 'STOCK' || item.value.order._id == 'P.O.F.')"
                                          [(productForm)]="item.controls.order.controls.products.controls[0].controls.product"
                                          [(vendor)]="item.controls.order.value.products[0].product.vendor" placeholder="COLOR"
                                          (selected)="updateProduct($event,item.controls.order,i)" [(fieldOpen)]="fieldOpen" displayField="color"
                                          fxFlex="0 0 300px">
                                        </app-autocomplete-vendor-products>
                                      </div>
                                      <div style="width:300px;">
                                        <mat-form-field style="width:100%"
                                          *ngIf="item.value.order._id && item.value.order._id != 'STOCK' && item.value.order._id != 'P.O.F.' && item.value.order.products[0].product">
                                          <input matInput placeholder="SIZE" type="text" formControlName="size">
                                        </mat-form-field>

                                        <app-autocomplete-vendor-products
                                          *ngIf="!item.value.order._id || (item.value.order._id == 'STOCK' || item.value.order._id == 'P.O.F.')"
                                          [(productForm)]="item.controls.order.controls.products.controls[0].controls.product"
                                          [(vendor)]="item.controls.order.value.products[0].product.vendor" placeholder="SIZE"
                                          (selected)="updateProduct($event,item.controls.order,i)" [(fieldOpen)]="fieldOpen" displayField="size"
                                          fxFlex="0 0 300px">
                                        </app-autocomplete-vendor-products>
                                      </div>
                                    </ng-container>


                                    <div style="width:300px;" *ngIf="item.value.order._id != 'P.O.F.'">
                                      <mat-form-field style="width:300px">
                                        <input matInput placeholder="RETAIL" type="text" formControlName="retail">
                                        <span matPrefix>$&nbsp;</span>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>
                                </ng-container>

                                <div style="width:300px;">
                                  <mat-form-field style="width:100%">
                                    <input matInput placeholder="NOTES" type="text" formControlName="notes">
                                  </mat-form-field>
                                </div>
                                <div style="width:300px;">
                                  <mat-form-field style="width:300px">
                                    <span matPrefix>{{item.value.order.type}}&nbsp;&ndash;&nbsp;</span>
                                    <input matInput placeholder="INVOICE #" type="text" formControlName="invoiceNumber">
                                  </mat-form-field>
                                </div>
                              </ng-container>

                              <div class="imageCardContent" *ngIf="item.controls.itemType.value=='Prescription'" formGroupName="prescription">
                                <app-form-prescription [(prescription)]="item.controls.prescription" [withNotes]="true"
                                  [withImage]="false"></app-form-prescription>
                              </div>



                            </div>


                          </div>





                        </mat-card-content>
                      </mat-card>






                    </div>

                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <div class="container" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div class="item item-1">
              <button mat-raised-button (click)="save()" [disabled]="saveButtonClicked || importPatientForm.invalid">SAVE</button>
            </div>
            <div class="item item-2" fxFlex [hidden]="!saveButtonClicked">
              <mat-progress-bar mode="indeterminate" [hidden]="!saveButtonClicked"></mat-progress-bar>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </form>
</div>