import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VendorOrder } from '@models/vendor-order';

@Component({
  selector: 'app-invoice-log',
  templateUrl: './invoice-log.component.html',
  styleUrls: ['./invoice-log.component.scss'],
})
export class InvoiceLogComponent {
  @Input() order: VendorOrder;
  @ViewChild('logDetail') logModal: TemplateRef<any>;

  constructor(private dialog: MatDialog) {}

  vieOrderLog() {
    this.dialog.open(this.logModal, {
      panelClass: 'minimal',
    });
  }
}
