export class Store {
	_id: string;
	storeNumber: string;
	storeName: string;
	dateAdded: string;
	dateUpdated: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	phone: string;
	fax: string;
  email: string;
  ipAddresses: string[];
  headerColor: string;

  constructor() {
    this._id = '';
    this.storeNumber = '';
    this.storeName = '';
		this.dateAdded = '';
		this.dateUpdated = '';
		this.address = '';
		this.address2 = '';
		this.city = '';
		this.state = '';
		this.zip = '';
		this.phone = '';
		this.fax = '';
		this.email = '';
		this.ipAddresses = [];
		this.headerColor = '';
  }

}