<div #printArea class="print-area" style="flex-direction:column;align-items:flex-start;">
    <div class="print-area-wrapper">
        <div class="print-header"><img src="assets/rimsandgoggles-logo.png" alt="Rims and Goggles" /></div>
        <h3 *ngIf="store?.storeName" class="print-subheader">{{ store.storeName }}</h3>
        <div *ngIf="vendor" class="print-detail">
            <h2 *ngIf="!vendor.images?.length">{{ vendor.name }}</h2>
            <app-product-image
                  *ngIf="vendor.images?.length"
                  [image]="vendor.images[0]"
                  [altHeader]="vendor.name"
                ></app-product-image>
            <div *ngIf="vendor.accountNumber" class="print-detail-row">
                <div class="print-detail-row-label">Account #:</div>
                <div class="print-detail-row-value">{{ vendor.accountNumber }} </div>
            </div>
            <div *ngIf="vendor.returnAddress" class="print-detail-row">
                <div class="print-detail-row-label">Return Address:</div>
                <div class="print-detail-row-value" [innerHTML]="vendor.returnAddress"></div>
            </div>
            <div *ngIf="vendor.phone" class="print-detail-row">
                <div class="print-detail-row-label">Phone:</div>
                <div class="print-detail-row-value">{{ vendor.phone }}</div>
            </div>
        </div>
        <div *ngIf="orders?.length" class="print-table">
            <table>
                <thead>
                    <th *ngIf="vendor.distributor">Vendor</th>
                    <th>Invoice #</th>
                    <th>Total</th>
                </thead>
                <tbody>
                    <tr *ngFor="let o of orders">
                      <td *ngIf="vendor.distributor">{{ o.vendorName }} </td>
                        <td class="print-table-invoice">{{ o.invoiceNumber }}</td>
                        <td class="print-table-amount">
                          <ng-container *ngIf="o.groupId && !o.datePaid && o.partialPayment; else paid">
                            {{ o.partialPayment | currency }}
                          </ng-container>
                          <ng-template #paid>
                            {{ o.total | currency }}
                          </ng-template>
                        </td>
                    </tr>
                    <tr>
                      <td *ngIf="vendor.distributor"></td>
                        <td></td>
                        <td class="print-table-total">{{ total  | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
