import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InternalErrorComponent } from './internal-error/internal-error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@modules/material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToggleService } from './header/toggle.service';
import { StickyNavModule } from 'ng2-sticky-nav';
import { V2SharedModule } from '../shared/v2-shared.module';
import { CustomizerSettingsService } from '../shared/customizer-settings/customizer-settings.service';
import { AlertComponent } from './alert/alert.component';
import { LoadingComponent } from './loading/loading.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoteFormComponent } from './note-form/note-form.component';
import { NumberInputComponent } from './forms/number-input/number-input.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    InternalErrorComponent,
    NotFoundComponent,
    SidebarComponent,
    AlertComponent,
    LoadingComponent,
    SnackbarComponent,
    NoteFormComponent,
    NumberInputComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgScrollbarModule,
    StickyNavModule,
    V2SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [ToggleService, CustomizerSettingsService, DatePipe, DecimalPipe],
  exports: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    InternalErrorComponent,
    AlertComponent,
    LoadingComponent,
    SnackbarComponent,
    NumberInputComponent,
  ],
})
export class UiCommonModule {}
