import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '@services/auth-guard.service';
import { CanDeactivateGuardService } from '@services/can-deactivate-guard.service';
import { AdminComponent } from '@components/admin/admin.component';
import { AdminAdminComponent } from '@components/admin/admin/admin.component';
import { MenuComponent } from '@components/admin/menu/menu.component';
import { PatientComponent } from '@components/admin/patient/patient.component';
import { AutocompletePatientComponent } from '@components/admin/autocomplete-patient/autocomplete-patient.component';
import { ImportCustomerComponent } from '@components/admin/import-customer/import-customer.component';
import { ImportCustomerImagesComponent } from '@components/admin/patient/import-customer-images/import-customer-images.component';
import { UsersComponent } from '@components/admin/users/users.component';
import { PatientsComponent } from '@components/admin/patients/patients.component';
import { OrderComponent } from '@components/admin/order/order.component';
import { FavoritesComponent } from '@components/admin/favorites/favorites.component';
import { PayableComponent } from '@components/admin/admin/payable/payable.component';
import { ReceivableComponent } from '@components/admin/admin/receivable/receivable.component';
import { ReportsComponent } from '@components/admin/admin/reports/reports.component';
import { FixOrdersComponent } from '@components/admin/admin/fix-orders/fix-orders.component';
import { FixDuplicateProductsComponent } from '@components/admin/admin/fix-duplicate-products/fix-duplicate-products.component';
import { VendorsComponent } from '@components/admin/vendors/vendors.component';
import { ManagedBoardComponent } from '@components/admin/managed-board/managed-board.component';
import { ManagedBoardStackComponent } from '@components/admin/managed-board/managed-board-stack/managed-board-stack.component';
import { ManagedBoardAwaitingFramesComponent } from '@components/admin/managed-board/managed-board-awaiting-frames/managed-board-awaiting-frames.component';
import { ManagedBoardInventoryComponent } from '@components/admin/managed-board/managed-board-inventory/managed-board-inventory.component';
import { ReturnsComponent } from '@components/admin/managed-board/returns/returns.component';
import { ExamsComponent } from '@components/admin/exams/exams.component';
import { BorrowingComponent } from '@components/admin/managed-board/borrowing/borrowing.component';
import { PrintLayoutComponent } from '@components/admin/printing/print-layout/print-layout.component';
import { InvoiceComponent } from '@components/admin/printing/invoice/invoice.component';
import { UnauthGuardService } from '@services/unauth-guard.service';
import { ChangeLogComponent } from '@components/admin/change-log/change-log.component';
import { ManagedBoardInvoiceComponent } from '@components/admin/managed-board/invoice/invoice.component';
import { ManagedBoardCreditComponent } from '@components/admin/managed-board/credit/credit.component';
import { ShopifyExportComponent } from '@v2/components/shopify-export/shopify-export.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('../../components/login/login.module').then(
        ({ LoginModule }) => LoginModule
      ),
    canActivate: [UnauthGuardService],
  },
  {
    path: 'print',
    canDeactivate: [CanDeactivateGuardService],
    canActivate: [AuthGuardService],
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [{ path: 'invoice/:invoiceIds', component: InvoiceComponent }],
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    component: AdminComponent,
    children: [
      {
        path: 'admin',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: AdminAdminComponent,
        data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
        children: [
          {
            path: 'shopify-data',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ShopifyExportComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'patients',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: PatientsComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'users',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: UsersComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'import-customers',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ImportCustomerComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'favorites',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: FavoritesComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'payable',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: PayableComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'payable/:vendorId/:orderId',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: PayableComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'payable/:vendorId/:orderId/:invoiceStatus',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: PayableComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'receivable',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ReceivableComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'reports',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ReportsComponent,
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
            outlet: 'sub',
          },
          {
            path: 'fix-orders',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: FixOrdersComponent,
            outlet: 'sub',
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
          },
          {
            path: 'fix-duplicate-products',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: FixDuplicateProductsComponent,
            outlet: 'sub',
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
          },
          {
            path: 'fix-duplicate-products-size',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: FixDuplicateProductsComponent,
            outlet: 'sub',
            data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
          },
        ],
      },
      {
        path: 'autocomplete',
        canActivate: [AuthGuardService],
        component: AutocompletePatientComponent,
      },
      { path: '', canActivate: [AuthGuardService], component: MenuComponent },
      {
        path: 'order',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: OrderComponent,
      },
      {
        path: 'patient',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: PatientComponent,
      },
      {
        path: 'patient/new/:name',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: PatientComponent,
      },
      {
        path: 'patient/new',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: PatientComponent,
      },
      {
        path: 'patient/:id',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: PatientComponent,
      },
      {
        path: 'import-customer-images',
        canActivate: [AuthGuardService],
        component: ImportCustomerImagesComponent,
      },
      {
        path: 'import-customer-images/:id',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: ImportCustomerImagesComponent,
      },
      {
        path: 'vendors',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: VendorsComponent,
      },
      {
        path: 'vendors/:vendorId',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: VendorsComponent,
      },
      {
        path: 'managed-board',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: ManagedBoardComponent,
        children: [
          {
            path: 'stack',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardStackComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'stack/:vendorId',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardStackComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'awaiting-frames',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardAwaitingFramesComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'awaiting-frames/:vendorId',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardAwaitingFramesComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'inventory',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardInventoryComponent,
            outlet: 'sub',
          },
          {
            path: 'returns',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ReturnsComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'returns/:vendorId',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ReturnsComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'borrowing',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: BorrowingComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'borrowing/:vendorId',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: BorrowingComponent,
            data: {
              allowedRoles: [
                'OPTICIAN',
                'MANAGER',
                'OPTICIAN/INVENTORY',
                'OWNER/ADMIN',
              ],
            },
            outlet: 'sub',
          },
          {
            path: 'invoice',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardInvoiceComponent,
            outlet: 'sub',
          },
          {
            path: 'credit',
            canDeactivate: [CanDeactivateGuardService],
            canActivate: [AuthGuardService],
            component: ManagedBoardCreditComponent,
            outlet: 'sub',
          },
        ],
      },
      {
        path: 'exams',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: ExamsComponent,
      },
      {
        path: 'admin/fix-orders',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: FixOrdersComponent,
        data: { allowedRoles: ['SUPERUSER'] },
      },
      {
        path: 'admin/fix-duplicate-products',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: FixDuplicateProductsComponent,
        data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
      },
      {
        path: 'admin/fix-duplicate-products-size',
        canDeactivate: [CanDeactivateGuardService],
        canActivate: [AuthGuardService],
        component: FixDuplicateProductsComponent,
        data: { allowedRoles: ['OWNER/ADMIN', 'OPTICIAN/INVENTORY'] },
      },
      {
        path: 'change-log',
        component: ChangeLogComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('../../components/my-account/my-account.module').then(
        ({ MyAccountModule }) => MyAccountModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'v2',
    loadChildren: () =>
      import('../../v2/v2.module').then(({ V2Module }) => V2Module),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class RoutingModule {}
