import { ProductElement } from '@models/product';
import { formatDate } from '@angular/common';
import { Store } from '@models/store';

export class Order {
  _id: string;
  invoiceNumber: string;
  status?: string;
  type: string;
  restockOrder?: boolean;
  patient: string;
  notes?: string;
  part?: boolean;
  specialOrder?: boolean;
  warranty?: boolean;
  todo?: string;
  notify?: boolean;
  user?: string;
  eta?: string;
  date?: string;
  dateAdded?: string;
  dateUpdated?: string;
  archived?: boolean;
  images?: Object[];
  products?: ProductElement[];
  store?: string | Store;
  log?: LogElement[];
  vendorOrder?: string;
  shipping?: number;
  tax?: number;
  total?: number;
  framesRemoved?: string[];

  constructor() {
    this._id = '';
    this.invoiceNumber = '';
    this.status = '';
    this.type = '';
    this.restockOrder = false;
    this.patient = null;
    this.notes = '';
    this.part = false;
    this.specialOrder = false;
    this.warranty = false;
    this.todo = '';
    this.notify = false;
    this.user = '';
    this.eta = '';
    this.date = '';
    this.dateAdded = '';
    this.dateUpdated = '';
    this.archived = false;
    this.images = [];
    this.products = [];
    this.store = null;
    this.log = [];
    this.vendorOrder = null;
    this.framesRemoved = [];
  }
}

export class LogElement {
  _id: string = null;
  initials?: string = '';
  user?: string = '';
  item?: string = '';
  notes?: string = '';
  date?: string = '';

  constructor() {
    this._id = null;
    this.initials = '';
    this.user = '';
    this.item = '';
    this.notes = '';
    this.date = formatDate(Date.now(), 'MM/dd/yyyy', 'en-US');
  }
}
