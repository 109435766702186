<ng-container *ngIf="vendor && vendorOrders.length > 0">
	<h2>{{vendor?.name}}</h2>
	<div class="container" style="width:100%;"
    fxLayout="row wrap"
    fxLayoutAlign="space-between start">
    <div fxFlex="20%">
			<span>ACCOUNT #:</span>
    </div>
    <div fxFlex="80%">
			<span>{{vendor.accountNumber}}</span>
    </div>

    <div fxFlex="20%">
			<span>RETURN ADDRESS:</span>
    </div>
    <div fxFlex="80%">
			<pre>{{vendor.returnAddress}}</pre>
    </div>

    <div fxFlex="20%">
			<span>PHONE:</span>
    </div>
    <div fxFlex="80%">
			<span>{{vendor.phone}}</span>
    </div>
  </div>

</ng-container>

<mat-table #table [dataSource]="dataSource">

  <ng-container matColumnDef="invoiceNumber">
    <mat-header-cell *matHeaderCellDef fxFlex="1 1 25%"> INVOICE # </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder; let i=index;" fxFlex="1 1 25%">
    	<span>{{ vendorOrder.invoiceNumber }}</span>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef fxFlex="1 1 25%"></mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef fxFlex="1 1 25%"> TOTAL </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder; let i=index;" fxFlex="1 1 25%">
    	<span>{{ vendorOrder.total | currency }}</span>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef fxFlex="1 1 25%"> {{ getTotalCost() | currency }} </mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="start stretch"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="start stretch" [ngClass]="{rowEven:even, rowOdd:odd}"></mat-row>
	<mat-footer-row *matFooterRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="center stretch"></mat-footer-row>

</mat-table>

