<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="FAVORITES SEARCH" (input)="patientSearch()">
</mat-form-field>

<div *ngIf="!dataSource.data">
  <h3 style="text-align:center;">LOADING CUSTOMERS</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 15%"> NAME </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="1 0 15%"> {{ patient.lastName }}, {{ patient.firstName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 20%"> EMAIL </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="1 1 20%"> {{ patient.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 15%"> PHONE </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="1 0 15%"> {{ patient.phone | phone }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone2">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 15%"> PHONE 2 </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="1 0 15%"> {{ patient.phone2 | phone }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cell">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 15%"> CELL </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="1 0 15%"> {{ patient.cell | phone }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="favoriteLines">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 1 12%"> FAVORITE LINES </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="0 1 12%"> {{ patient.favoriteLines }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="beBackList">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 1 12%"> BE BACK LIST </mat-header-cell>
      <mat-cell *matCellDef="let patient; let i=index;" fxFlex="0 1 12%" [innerHTML]="patient.beBackList | newline"></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="none center"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i=index;" fxLayout="row" fxLayoutAlign="none center" appHighlight (click)="clickRow($event,row,i)"></mat-row>
  </mat-table>

  <div class="container" fxLayout="row" fxLayoutAlign="none center">
  	<div class="item item-1" fxFlexOffset="10px"><button mat-raised-button type="button" (click)="saveCSV($event);$event.stopPropagation()">DOWNLOAD CSV</button></div>
  	<div class="item item-2" fxFlex><mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator></div>
</div>
