import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@services/report.service';
import { AngularCsv } from 'angular7-csv';

@Component({
  selector: 'app-customers-email',
  templateUrl: './customers-email.component.html',
  styleUrls: ['./customers-email.component.css']
})
export class CustomersEmailComponent implements OnInit {
  loading: boolean = false;
  report: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = ['frames', 'customers', 'percentage'];

  constructor(public reportService: ReportService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getCustomersReport();
  }

  getCustomersReport() {
    this.loading = true;
    // this.reportService.getCustomersReport(this.startDate, this.endDate).subscribe((res: any[]) => {
    this.reportService.getCustomerEmailsReport().subscribe((res: any[]) => {
      console.log(res)
      this.report = res;
      this.dataSource = new MatTableDataSource<any>(this.report.data);
      this.loading = false;
      this.cdRef.detectChanges();
    });
  }

  calculateFramesTotal() {
    return this.report.data.reduce((accum, curr) => accum + curr.frames, 0);
  }

  calculateCustomersTotal() {
    return this.report.data.reduce((accum, curr) => accum + curr.count, 0);
  }

  getPercentage(x, y) {
    return Math.round(x*1000/y)/10;
  }

  saveCSV($event: Event) {
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false, 
      showTitle: false,
      title: 'No sale Customer Emails',
      useBom: false,
      noDownload: false,
      headers: []
    };
    var patients = [];
    let toUpper = function(x){ 
      return x.toUpperCase();
    };
    for (var x = 0; x < this.dataSource.data.length; x++) {
      patients.push({
        email: this.report.data[x].email
      })
    }
    new AngularCsv(patients, 'No Sale Customer Emails Report', options);
    //new Angular5Csv(this.patients, 'My Report');
  }

}
