import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';


/* TECH DEBT* move all models to model folder */
export declare type UserRole = 'OWNER/ADMIN' | 'OPTICIAN/INVENTORY';
export interface NavConfig {
  path: string;
  label: string;
}

export interface RoleNavConfig {
  [key: string]: Array<NavConfig>;
}
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminAdminComponent {
  userRole: string;
  userNav: Array<NavConfig>;
  defaultNav: Array<NavConfig> = [
    {
      path: 'reports',
      label: 'REPORTS',
    },
    {
      path: 'fix-orders',
      label: 'FIX ORDERS',
    },
    {
      path: 'fix-duplicate-products',
      label: 'FIX DUPLICATES',
    },
    {
      path: 'fix-duplicate-products-size',
      label: 'FIX DUPLICATES (NO SIZE)',
    },
  ];
  roleNavConfig: RoleNavConfig = {
    ['OWNER/ADMIN']: [
      {
        path: 'patients',
        label: 'QUERY CUSTOMERS',
      },
      {
        path: 'import-customers',
        label: 'IMPORT CUSTOMERS',
      },
      { path: 'users', label: 'EMPLOYEES' },
      { path: 'favorites', label: 'FAVORITES' },
      { path: 'payable', label: 'PAYABLE' },
      {
        path: 'receivable',
        label: 'RECEIVABLE',
      },
      ...this.defaultNav,
      {
        path: 'shopify-data',
        label: 'SHOPIFY',
      },
    ],
  };

  constructor(public auth: AuthService) {
    this.userRole = this.auth.currentUser?.role;
    this.userNav = this.roleNavConfig[this.userRole] ?? this.defaultNav;
  }
}
