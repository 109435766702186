import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';

export class ProductSort {

  constructor() {

  }

  static setFilterPredicate(dataSource:MatTableDataSource<FormGroup>, selection:SelectionModel<FormGroup>) {
    dataSource.filterPredicate = ((data: FormGroup, filterString: string) => {
      let filterStringArr = filterString.split('$');
      let filter = filterStringArr[0];
      let stockOption = filterStringArr[1];
      filter = ProductSort.escapeRegExp(filter);
      let reg = new RegExp(filter, 'i');
      if (stockOption == 'stock') {
        return data.value.status != 'return' && data.value.status != 'returned' && (data.value.quantity > 0 && (!filter || reg.test(data.value.model) || reg.test(data.value.color) || selection.selected.includes(data) || data.value._id == 'NEW'));
      } else if (stockOption == 'return') {
        return (data.value.status == 'return' || data.value.status == 'returned') && (!filter || reg.test(data.value.model) || reg.test(data.value.color) || selection.selected.includes(data) || data.value._id == 'NEW');
      } else if (!filter) {
        return data.value.status != 'return' && data.value.status != 'returned' && (reg.test(data.value.model) || reg.test(data.value.color) || selection.selected.includes(data) || data.value._id == 'NEW');
      } else {
        // return data.value.status != 'return' && data.value.status != 'returned' && (!filter || reg.test(data.value.model) || selection.selected.includes(data) || data.value._id == 'NEW');
        return reg.test(data.value.model) || reg.test(data.value.color) || selection.selected.includes(data) || data.value._id == 'NEW';
      }
    });
  }

  static escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  static filterProductStock(dataSource:MatTableDataSource<FormGroup>, productsForm:FormGroup) {
    dataSource.filter = productsForm.get('search').value.trim().toUpperCase() + '$' + productsForm.get('showStock').value;
  }

  static setSorter(dataSource:MatTableDataSource<FormGroup>, sort:MatSort) {
    dataSource.sortingDataAccessor = (item, property) => {
      if (!item.value[property]) {
        item.value[property] == '';
      }
      switch(property) {
        case 'vendor':
          if (item.value.vendor) {
            let vendor: Object = item.value.vendor;
            return vendor['name'].toUpperCase();
          } else {
            return '';
          }
          break;
        case 'model':
          return item.value.model;
          break;
        case 'color':
          return item.value.color;
          break;
        case 'size':
          return item.value.size;
          break;
        case 'status':
          return item.value.status;
          break;
        case 'type':
          return item.value.type;
          break;
        case 'cost':
          return item.value.cost;
          break;
        case 'retail':
          return item.value.retail;
          break;
        case 'quantity':
          return item.value.quantity;
          break;
        case 'images':
          return item.value.images.length;
          break;
        default:
          return item.value[property].toUpperCase();
      }
    };
    dataSource.sort = sort;
  }
}
