import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const allowedRoles = (route.data as any).allowedRoles;
    const isAuthorized = await this.auth.isAuthorized(allowedRoles);

    if (isAuthorized) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    const allowedRoles = (route.data as any).allowedRoles;
    const isAuthorized = await this.auth.isAuthorized(allowedRoles);

    if (isAuthorized) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
