import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from '@models/user';
import { Observable } from 'rxjs';

const BASE_URL = `${environment.base_url}/account`;

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  getAccount(): Observable<any> {
    return this.http.get<any>(BASE_URL);
  }

  saveAccount(payload: Partial<User>): Observable<any> {
    return this.http.post<any>(BASE_URL, payload);
  }
}
