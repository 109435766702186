<div [formGroup]="parentForm" class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" *ngIf="patient">
      <div class="item item-1" fxFlex="67%" style="padding-top:10px;">
        <!-- <mat-card> -->
<!--           <mat-card-header *ngIf="!newPatient">
            <div class="container" style="width:100%;"
              fxLayout="row"
              fxLayoutAlign="space-between start">
              <div fxFlex="40%">
                <mat-card-title>{{patient.lastName}}, {{patient.firstName}}</mat-card-title>
                <mat-card-subtitle>#{{patient.patientNumber}}</mat-card-subtitle>
              </div>
              <div fxFlex="60%" style="text-align:right;">
                <button mat-raised-button type="button" (click)="importCustomerImages()" style="width:300px;">IMPORT CUSTOMER IMAGES</button>
              </div>
            </div>
          </mat-card-header> -->



          <!-- <mat-card-content> -->
<!-- 			<mat-toolbar>
				<span *ngIf="!newPatient">PATIENT INFORMATION</span>
				<span *ngIf="newPatient">NEW PATIENT INFORMATION</span>
			</mat-toolbar> -->
            <div class="container" fxLayout="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
              <div class="item item-1">
                <div class="container" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-1" fxFlex="25%">
                    <mat-form-field style="width:100%" [hideRequiredMarker]="false">
                    	 <mat-label style="font-weight:bold;">FIRST NAME</mat-label>
                      <input matInput placeholder="FIRST NAME" type="text" formControlName="firstName" [ngClass]="{'has-error':parentForm.get('firstName').invalid}" class="bigfont" autocomplete="off" required>

                      <mat-error *ngIf="parentForm.get('firstName').invalid">FIRST NAME IS REQUIRED</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="item item-2" fxFlex>
                    <mat-form-field style="width:100%" [hideRequiredMarker]="false">
                    	 <mat-label style="font-weight:bold;">LAST NAME</mat-label>
                      <input matInput placeholder="LAST NAME" type="text" formControlName="lastName" [ngClass]="{'has-error':parentForm.get('lastName').invalid}" class="bigfont" autocomplete="off" required>
                      <mat-error *ngIf="parentForm.get('lastName').invalid">LAST NAME IS REQUIRED</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="item item-1" fxFlex="10%">
                    <mat-form-field style="width:100%">
                      <mat-select placeholder="PREFIX" formControlName="prefix">
                        <mat-option value="">-</mat-option>
                        <mat-option value="MR">MR</mat-option>
                        <mat-option value="MRS">MRS</mat-option>
                        <mat-option value="MS">MS</mat-option>
                        <mat-option value="MX">MX</mat-option>
                        <mat-option value="MISS">MISS</mat-option>
                        <mat-option value="MSTR">MSTR</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="item item-2" fxFlex="160px">
                    <mat-form-field class="date">
                      <input matInput [matDatepicker]="dob" (blur)="blurDate()" placeholder="DOB" formControlName="dob" autocomplete="off">
                      <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                      <mat-datepicker #dob></mat-datepicker>
                  		<mat-hint>MMDDYYYY</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="item item-2">
                <div class="container" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-1" fxFlex>
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="ADDRESS" type="text" formControlName="address" autocomplete="off">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="item item-3">
                <div class="container" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-1" fxFlex="1 1 60%">
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="CITY" type="text" formControlName="city" autocomplete="off">
                    </mat-form-field>
                  </div>
                  <div class="item item-2" fxFlex="0 0 180px">
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="STATE" aria-label="STATE" [matAutocomplete]="auto" (change)="updateState($event)" [formControl]="stateCtrl" formControlName="state" autocomplete="off">
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let state of filteredStates | async" [value]="state.abbreviation">
                          <span>{{ state.abbreviation }} - {{ state.name }}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="item item-3" fxFlex="0 0 15%">
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="ZIP" type="text" formControlName="zip" autocomplete="off">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="item item-4">
                <div class="container" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-1" fxFlex="1 1 calc(25%-10px)">
                    <mat-form-field style="width:100%">
                      <input matInput class="bigfont" placeholder="HOME PHONE" type="tel" formControlName="phone" autocomplete="off" mask="(000)000-0000 x9999" [specialCharacters]="[ ' ', '(', ')', '-', 'x' ]" style="text-transform:none;">
                    </mat-form-field>
                  </div>
                  <div class="item item-1" fxFlex="1 1 calc(25%-10px)">
                    <mat-form-field style="width:100%">
                      <input matInput class="bigfont" placeholder="WORK PHONE" type="tel" formControlName="phone2"  mask="(000) 000-0000 x9999" [specialCharacters]="[ ' ', '(', ')', '-', 'x' ]" style="text-transform:none;" autocomplete="off">
                    </mat-form-field>
                  </div>
                  <div class="item item-2" fxFlex="1 1 calc(25%-10px)">
                    <mat-form-field style="width:100%">
                      <input matInput class="bigfont" placeholder="CELL PHONE" type="tel" formControlName="cell"  mask="(000) 000-0000 x9999" [specialCharacters]="[ ' ', '(', ')', '-', 'x' ]" style="text-transform:none;" autocomplete="off">
                    </mat-form-field>
                  </div>
                  <div class="item item-1" fxFlex="0 0 25%">
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="EMAIL" type="email" formControlName="email" autocomplete="off">
                    </mat-form-field>
                    <div class="alert alert-danger" *ngIf="0">A valid email is required.</div>
                  </div>
                </div>
              </div>
            </div>
<!--           </mat-card-content>
        </mat-card> -->
        <br/>
<!--         <mat-card>
          <mat-card-content> -->


            <div class="container" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="container" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div class="container" fxFlex="50%" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-1" fxFlex="50%">
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="OCCUPATION" type="text" formControlName="occupation" autocomplete="off">
                    </mat-form-field>
                  </div>
                  <div class="item item-2" fxFlex="50%">
                    <mat-form-field style="width:100%">
                      <input matInput placeholder="INTERESTS" type="text" formControlName="interests" autocomplete="off">
                    </mat-form-field>
                  </div>
                </div>
                <div class="item item-1" fxFlex="50%">
                  <mat-form-field style="width:100%">
                    <textarea matInput placeholder="FAMILY MEMBERS" formControlName="familyMembers" rows="5" autocomplete="off"></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="container" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div class="container" fxFlex="50%" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-2" fxFlex>
                   <!-- <mat-form-field style="width:100%">
                      <textarea matInput placeholder="FAVORITE LINES" formControlName="favoriteLines" rows="5" autocomplete="off"></textarea>
                    </mat-form-field>-->

                    <app-form-favorites [favorites]="this.patient.favoriteLines" [parentForm]="parentForm"></app-form-favorites>

                  </div>
                  <div class="item item-2" fxFlex>
                    <mat-form-field style="width:100%">
                      <mat-select placeholder="HOW DID YOU FIND US?" formControlName="howDidYouFindUs" autocomplete="off">
                        <mat-option *ngFor="let howDidYouFindUsType of howDidYouFindUsTypes" [(value)]="howDidYouFindUsType.value">
                        {{ howDidYouFindUsType.viewValue }}
                      </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="container" fxFlex="50%" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="10px" fxLayoutGap.xs="0">
                  <div class="item item-1" fxFlex>
                    <mat-form-field style="width:100%">
                      <textarea matInput placeholder="BE BACK LIST" formControlName="beBackList" rows="5" autocomplete="off"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="item item-2" fxFlex>
                      <mat-form-field style="width:100%" *ngIf="parentForm.get('howDidYouFindUs').value=='OTHER'">
                      <input matInput placeholder="OTHER" type="text" [(value)]="parentForm.value.howDidYouFindUsOther" formControlName="howDidYouFindUsOther" autocomplete="off">
                      </mat-form-field>
                  </div>
                </div>
              </div>
            </div>


        <!--   </mat-card-content>
        </mat-card> -->






      </div>
      <div class="item item-2" fxFlex="33%">
        <mat-card>
          <mat-toolbar>
            <span>PATIENT NOTES</span>
          </mat-toolbar>
          <mat-card-content>
            <div class="container" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="100%" >
                <ckeditor [editor]="ClassicEditorBuild" style="width:100%;margin:5px;" placeholder="PATIENT NOTES" [formControl]="parentForm.controls['patientNotes']" [config]="{toolbar: [ 'Bold', 'Italic' ]}" matInput></ckeditor>
              </div>
              <div class="item" fxFlex="100%">
                <mat-form-field style="width:100%">
                  <mat-label>LAST EXAM</mat-label>
                  <input matInput [matDatepicker]="pickerLastExam" formControlName="lastExam">
                  <mat-datepicker-toggle matSuffix [for]="pickerLastExam"></mat-datepicker-toggle>
                  <mat-datepicker #pickerLastExam></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="item item-3">
                <mat-form-field style="width:100%">
                  <input matInput placeholder="STORE CREDIT" type="text"  formControlName="storeCredit" (blur)="formatCurrency($event,parentForm.controls.storeCredit)" autocomplete="off">
                  <span matPrefix>$&nbsp;</span>
                </mat-form-field>
              </div>
              <div class="item item-2">
                <mat-form-field style="width:100%">
                  <mat-select placeholder="DISCOUNT STATUS" formControlName="discountStatus">
                    <mat-option value="">-</mat-option>
                    <mat-option value="SENIOR">SENIOR</mat-option>
                    <mat-option value="FRIENDSFAMILY">FRIENDS &amp; FAMILY</mat-option>
                    <mat-option value="EMPLOYEE">EMPLOYEE</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="item item-4">
               <mat-checkbox formControlName="sleep">SLEEP</mat-checkbox>
              </div>
              <div class="item item-4">
               <mat-checkbox formControlName="optOut">MARKETING OPT-OUT</mat-checkbox>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>