<div *ngIf="!dataSource.data">
  <h3 style="text-align: center">LOADING PAYABLE</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="dataSource.data">
  <ng-container *ngIf="tableLoading; else tableBlock">
    <div><h3 style="text-align: center">LOADING ORDERS</h3></div>
    <div><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
  </ng-container>
  <ng-template #tableBlock>
    <ng-container *ngIf="dataSource.data.length; else emptyBlock">
      <mat-table
        #headerTable
        [dataSource]="dataSource"
        matSort
        [matSortActive]="defaultSortColumn"
        [matSortDirection]="defaultSortDirection"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="select">
          <mat-header-cell
            *matHeaderCellDef
            fxFlex="1 1 50px"
            style="padding-left: 0px !important"
          >
            <mat-checkbox
              (change)="selectOrders($event)"
              [checked]="allSelected || someSelected"
              [indeterminate]="someSelected && !allSelected"
            ></mat-checkbox>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="vendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            VENDOR
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            STATUS
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="groupId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 20%">
            GROUP ID
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="invoiceNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            INVOICE #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let group; let groupIndex = index"
            [attr.colspan]="displayedColumns.length - 1"
            style="
              display: flex;
              flex-direction: column;
              justify-content: stretch;
            "
          >
            <mat-table
              #table
              [dataSource]="group.dataSource"
              multiTemplateDataRows
            >
              <ng-container matColumnDef="select">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell
                    *matHeaderCellDef
                    fxFlex="1 1 50px"
                    style="padding-left: 0px !important"
                  >
                  </mat-header-cell>
                </ng-container>
                <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 50px">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="toggleSelection(vendorOrder.value._id)"
                    [checked]="isSelected(vendorOrder.value._id)"
                    [aria-label]="checkboxLabel(vendorOrder.value._id)"
                  ></mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="vendor">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span>{{ vendorOrder.value.vendor.name }}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                    {{ group.status }}
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span *ngIf="vendorOrder.value.status !== 'RETURNED'">
                    {{ vendorOrder.value.status }}
                  </span>
                  <span *ngIf="vendorOrder.value.status === 'RETURNED'">
                    AWAITING CREDIT
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="groupId">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%">
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 20%"
                >
                  <span>
                    {{ vendorOrder.value.groupId }}
                    {{ vendorOrder.value.isGroupBy }}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="datePaid">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                    {{ group.datePaid | date: "M/d/yyyy":"GMT" }}
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index; let row"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span *ngIf="vendorOrderId !== vendorOrder.value._id">
                    {{ group.datePaid | date: "M/d/yyyy":"GMT" }}
                  </span>
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    floatLabel="never"
                    fxFlex="1 1 90%"
                  >
                    <input
                      #formRow
                      [matDatepicker]="picker"
                      matInput
                      placeholder="DATE"
                      type="text"
                      formControlName="datePaid"
                      (dateChange)="clickField($event, vendorOrder, i)"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="invoiceNumber">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span
                    *ngIf="
                      vendorOrder.value.groupId && vendorOrder.value.isGroupBy
                    "
                  >
                    {{ vendorOrder.value.groupId?.slice(-7) }} ({{
                      orderMap[vendorOrder.value.groupId].orders?.length
                    }})
                  </span>
                  <span *ngIf="vendorOrderId !== vendorOrder.value._id">
                    {{ vendorOrder.value.invoiceNumber }}
                  </span>
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    floatLabel="never"
                  >
                    <input
                      #formRow
                      matInput
                      placeholder="INVOICE #"
                      type="text"
                      formControlName="invoiceNumber"
                    />
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tax">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                    {{ group.tax | currency }}
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span *ngIf="vendorOrderId !== vendorOrder.value._id">
                    {{ vendorOrder.value.tax | currency }}
                  </span>
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    floatLabel="never"
                  >
                    <input
                      #formRow
                      matInput
                      placeholder="TAX"
                      type="number"
                      formControlName="tax"
                    />
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="shipping">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                    {{ group.shipping | currency }}
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span *ngIf="vendorOrderId !== vendorOrder.value._id">
                    {{ vendorOrder.value.shipping | currency }}
                  </span>
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    floatLabel="never"
                  >
                    <input
                      #formRow
                      matInput
                      placeholder="SHIPPING"
                      type="number"
                      formControlName="shipping"
                    />
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="date">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index; let row"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span *ngIf="vendorOrderId !== vendorOrder.value._id">
                    {{ vendorOrder.value.date | date: "M/d/yyyy":"GMT" }}
                  </span>
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    floatLabel="never"
                    fxFlex="1 1 90%"
                  >
                    <input
                      #formRow
                      [matDatepicker]="picker"
                      matInput
                      placeholder="DATE"
                      type="text"
                      formControlName="date"
                      (dateChange)="clickField($event, vendorOrder, i)"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="total">
                <ng-container *ngIf="group.isGroupBy">
                  <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%">
                    {{ group.total | currency }}
                  </mat-header-cell>
                </ng-container>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="1 1 10%"
                  [formGroup]="vendorOrder"
                >
                  <span *ngIf="vendorOrderId !== vendorOrder.value._id">
                    {{ vendorOrder.value.total | currency }}
                  </span>
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    floatLabel="never"
                  >
                    <input
                      #formRow
                      matInput
                      placeholder="TOTAL"
                      type="number"
                      formControlName="total"
                    />
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef fxFlex="0 0 150px">
                  <button
                    *ngIf="group.isGroupBy"
                    mat-menu-item
                    (click)="printVendorOrdersGroup(group.groupId)"
                  >
                    <mat-icon>print</mat-icon>
                    <span>PRINT</span>
                  </button>
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index"
                  fxFlex="0 0 150px"
                  [formGroup]="vendorOrder"
                >
                  <mat-form-field
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    style="width: 35px; flex: 0 1 35px; padding-left: 10px"
                    floatLabel="never"
                  >
                    <input
                      matInput
                      required
                      placeholder=""
                      type="text"
                      formControlName="initials"
                      (click)="$event.stopPropagation()"
                    />
                    <mat-label><mat-icon>gesture</mat-icon></mat-label>
                  </mat-form-field>
                  <button
                    *ngIf="vendorOrderId === vendorOrder.value._id"
                    [matMenuTriggerFor]="menu"
                    mat-button
                  >
                    <mat-icon>more_vert</mat-icon>
                    <span>SAVE</span>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      [disabled]="vendorOrder.invalid"
                      (click)="saveVendorOrder(vendorOrder, group.groupId)"
                      style="margin-left: 10px"
                    >
                      SAVE CHANGES
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="vendorOrder.value.status === 'RETURNED'"
                      [disabled]="vendorOrder.invalid"
                      (click)="markVendorOrderCredited(vendorOrder, group.groupId)"
                      style="margin-left: 10px"
                    >
                      MARK AS CREDITED
                    </button>
                    <button
                      mat-menu-item
                      [disabled]="vendorOrder.invalid"
                      (click)="deleteVendorOrder(vendorOrder, group.groupId, i)"
                      style="margin-left: 10px"
                    >
                      DELETE INVOICE
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="log">
                <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"
                  >LOG</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let vendorOrder; let i = index; let row"
                  fxFlex="0 0 40px"
                >
                  <app-log-menu
                    [order]="vendorOrder"
                    (clickField)="clickField($event, vendorOrder, i)"
                    [small]="true"
                  ></app-log-menu>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="fill">
                <mat-header-cell *matHeaderCellDef fxFlex>
                </mat-header-cell>
                <mat-cell
                  *matCellDef
                  fxFlex
                >
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef fxFlex fxLayoutAlign="end start">
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row;"
                  fxFlex="1 1 40px" fxLayoutAlign="end center"
                >
                  <!-- <span *ngIf="row !== expandedElement" class="pointer"  (click)="clickRow($event, row, groupIndex)"><mat-icon>expand_content</mat-icon></span> -->
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <mat-cell
                  *matCellDef="let vendorOrder; let orderIndex = dataIndex"
                >
                  <div
                    class="example-element-detail"
                    style="width: 100%"
                    [@detailExpand]="
                      vendorOrder === expandedElement ? 'expanded' : 'collapsed'
                    "
                  >
                    <app-payable-products
                      *ngIf="vendorOrder === expandedElement"
                      [vendorOrder]="vendorOrder.value"
                      [products]="vendorOrder.controls.products"
                      style="width: 100%"
                    ></app-payable-products>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="group.isGroupBy">
                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                  fxLayout="row"
                  fxLayoutAlign="start stretch"
                ></mat-header-row>
              </ng-container>

              <mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumns;
                  when: !group.isGroup;
                  let groupIndex = index;
                  let even = even;
                  let odd = odd
                "
                fxLayout="row"
                fxLayoutAlign="start stretch"
                (click)="clickRow($event, row, groupIndex)"
                [ngClass]="{ rowEven: even, rowOdd: odd }"
                class="mat-elevation-z2"
              ></mat-row>

              <mat-row
                *matRowDef="let element; columns: ['expandedDetail']"
                [class.example-detail-row-expanded]="
                  expandedElement === element
                "
                [class.example-detail-row-collapsed]="
                  expandedElement !== element
                "
                style="overflow: hidden"
                fxLayoutAlign="start center"
              ></mat-row>
            </mat-table>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="datePaid">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            PAID DATE
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="tax">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            TAX
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="shipping">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            SHIPPING
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            TOTAL
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%">
            INVOICE DATE
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"></mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="log">
          <mat-header-cell
            *matHeaderCellDef
            fxFlex="0 0 40px"
          ></mat-header-cell>
        </ng-container>

        <ng-container matColumnDef="fill">
          <mat-header-cell *matHeaderCellDef fxFlex>
          </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="expand">
          <mat-header-cell *matHeaderCellDef fxFlex fxLayoutAlign="end start">
          </mat-header-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns"
          fxLayout="row"
          fxLayoutAlign="start stretch"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['invoiceNumber']"></mat-row>
      </mat-table>
    </ng-container>
    <ng-template #emptyBlock>
      <div>
        <h3 style="text-align: center">NO RESULTS</h3>
      </div>
    </ng-template>
  </ng-template>
  <div class="fix-bottom" *ngIf="someSelected && orderGroups?.length">
    <ng-container *ngIf="!minimized">
      <div class="container" [formGroup]="orderGroups[0].ordersForm">
        <div class="d-flex flex-column align-start justify-start">
          <label> NOTES</label>
          <mat-form-field style="width: 100%" floatLabel="never">
            <textarea matInput formControlName="notes" rows="3"></textarea>
          </mat-form-field>
        </div>

        <div class="d-flex align-items-start justify-stretch">
          <div
            class="flex-grow-1 d-flex flex-column align-start justify-start"
            style="max-width: 50%"
          >
            <label>TOTAL</label>

            <div class="flex-grow-1 self-start currency">
              {{ orderGroups[0].ordersForm.value.total | currency }}
            </div>
          </div>

          <div class="flex-grow-1 d-flex flex-column align-start justify-start">
            <label>INITIALS</label>

            <mat-form-field
              style="width: 35px; flex: 0 1 35px; padding-left: 10px"
              floatLabel="never"
            >
              <input
                matInput
                required
                placeholder=""
                type="text"
                formControlName="initials"
              />
              <mat-label><mat-icon>gesture</mat-icon></mat-label>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex split">
            <button
              class="btn"
              mat-menu-item
              [disabled]="orderGroups[0].ordersForm.controls.initials.invalid"
              (click)="markVendorOrdersPaid()"
            >
              <mat-icon>save</mat-icon>
              <span>PAY</span>
            </button>

            <button class="btn" mat-menu-item (click)="printVendorOrders()">
              <mat-icon>print</mat-icon>
              <span>PRINT</span>
            </button>
          </div>

          <div class="d-flex">
            <button
              class="flex-grow-1 btn"
              mat-menu-item
              [disabled]="orderGroups[0].ordersForm.controls.initials.invalid"
              (click)="markVendorOrdersPaidAndPrint()"
            >
              <mat-icon>save</mat-icon>
              <span>PAY & PRINT</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="fix-actions" style="background-color: {{storeColor}}">
      <div class="flex-grow-1 d-flex justify-center count">
        {{ selectedOrderCount }} Selected
      </div>
      <span (click)="toggleMinimized()"
        ><mat-icon>{{
          !minimized ? "expand_more" : "expand_less"
        }}</mat-icon></span
      >
    </div>
  </div>
</div>
