import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '@services/print.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { VendorOrder } from '@models/vendor-order';
import { Vendor } from '@models/vendor';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  vendor: Vendor = null;
  vendorOrders: VendorOrder[] = [];
	dataSource = new MatTableDataSource<VendorOrder>();
	displayedColumns: string[] = ['invoiceNumber', 'total'];

  constructor(route: ActivatedRoute, private printService: PrintService, private vendorOrderService: VendorOrderService, public cdRef:ChangeDetectorRef) {
    console.log('here)')
    console.log(route.snapshot.params)
    this.invoiceIds = route.snapshot.params['invoiceIds'].split(',');
  }

  // ngOnInit() {
  // 	console.log('init')
  //   this.invoiceDetails = this.invoiceIds
  //     .map(id => this.getInvoiceDetails(id));
  //   Promise.all(this.invoiceDetails)
  //     .then(() => this.printService.onDataReady());
  // }

  // getInvoiceDetails(invoiceId) {
  // 	console.log('getdetails')
  //   const amount = Math.floor((Math.random() * 100));
  //   let prom = this.vendorOrderService.getVendorOrders('all', this.invoiceIds).toPromise();
  //   return prom;

  //   new Promise(resolve =>
  //     setTimeout(() => resolve({amount}), 1000)
  //   );
  // }

  ngOnInit() {

  }

  ngAfterViewInit() {
this.vendorOrderService.getVendorOrders('all', this.invoiceIds).subscribe((res) => {
      console.log(res);
      console.log(this.invoiceIds);
      // this.vendorOrders = res.vendorOrders[0].vendorOrders;
      for (let x = 0; x < res.vendorOrders.length; x++) {
        this.vendorOrders = this.vendorOrders.concat(res.vendorOrders[x].vendorOrders);
      }
      console.log(this.vendorOrders);

      this.vendor = res.vendorOrders?.[0]?.vendor;
      if (res.vendorOrders?.[0]?.vendor2) {
        this.vendor = res.vendorOrders[0].vendor2;
      } else if (res.vendorOrders?.[0]?.['borrowingStore']?.['storeName']) {
        this.vendor = res.vendorOrders[0]['borrowingStore'].storeName;
      }

      this.vendorOrders.push(new VendorOrder());
      this.vendorOrders.push(new VendorOrder());
      this.vendorOrders.push(new VendorOrder());
      // const vo = res.vendorOrders[0].vendorOrders;
      // const v = res.vendorOrders[0].vendor;
      // this.vendorOrders = new Promise(resolve => resolve({vo}));
      // this.vendor = new Promise(resolve => resolve({v}));;
      this.dataSource = new MatTableDataSource<VendorOrder>(this.vendorOrders);
      this.cdRef.detectChanges();
      setTimeout(() => {
        this.printService.onDataReady();
      }, 1000);
    });
  }

  // getInvoiceDetails(invoiceId) {
  //   this.vendorOrderService.getVendorOrders('all', this.invoiceIds).subscribe((res) => {
  //   	console.log(res);
  //   	const vo = res.vendorOrders[0].vendorOrders;
  //   	const v = res.vendorOrders[0].vendor;
  //   	this.vendorOrders = new Promise(resolve => resolve({vo}));
  //   	this.vendor = new Promise(resolve => resolve({v}));;
  //   	this.printService.onDataReady();
  //   });
  // }

  getTotalCost() {
    if (this.vendorOrders) {
      return this.vendorOrders.reduce((acc, vendorOrder:VendorOrder) => acc + vendorOrder.total, 0);
    } else {
      return 0;
    }
  }

}

 // this.invoiceDetails = this.invoiceIds.map(id => this.getInvoiceDetails(id));
 //    Promise.all(this.invoiceDetails).then(() => {this.printService.onDataReady(); console.log(this.invoiceDetails)});

    // this.vendorOrderService.getVendorOrders('all', this.invoiceIds).subscribe((res) => {
    // 	console.log(res);
    // 	const vo = res.vendorOrders[0].vendorOrders;
    // 	const v = res.vendorOrders[0].vendor;
    // 	this.vendorOrders = new Promise(resolve => resolve({vo}));
    // 	this.vendor = new Promise(resolve => resolve({v}));;
    // 	this.printService.onDataReady();
    // });
