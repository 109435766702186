import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { Order } from '@models/order';
import { ProductHistory } from '@models/product';
import { ApiResponse } from '@classes/api-response';
import { Params } from '@angular/router';
import { AuthResponse, Payable, PayableGroup } from '@models/api.model';

@Injectable({
  providedIn: 'root',
})
export class VendorOrderService {
  private base_url = environment.base_url + '/vendorOrder';

  constructor(public http: HttpClient, private auth: AuthService) {}

  getVendorOrders(
    type: string,
    ids: string[] = []
  ): Observable<ApiResponse<VendorOrders[]>> {
    const store = this.auth.getStore();
    const body = JSON.stringify(ids);
    return this.http
      .put(`${this.base_url}/store/${store}/type/${type}`, body)
      .pipe(
        take(1),
        tap((res: ApiResponse<VendorOrders[]>) => {
          this.auth.updateToken(res);
        })
      );
  }

  getBorrowingVendorOrders(borrowingStore: any): Observable<VendorOrders[]> {
    const store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/borrow/store/${store}/${borrowingStore}`)
      .pipe(
        take(1),
        map((res: ApiResponse<VendorOrders[]>) => res.vendorOrders)
      );
  }

  getDistributorVendorOrderCounts(
    type: string
  ): Observable<ApiResponse<any[]>> {
    const store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/counts/store/${store}/type/${type}`)
      .pipe(
        take(1),
        tap((res: ApiResponse<VendorOrders[]>) => this.auth.updateToken(res))
      );
  }

  getVendorPayablesList(
    type: string,
    vendorIds: Array<string>,
    params: Params = {}
  ): Observable<Payable[]> {
    const store = this.auth.getStore();
    return this.getVendorInvoices(vendorIds, params).pipe(
      take(1),
      concatMap(() =>
        this.http
          .post(
            `${environment.base_url}/invoices/store/${store}/type/${type}`,
            vendorIds,
            {
              params,
            }
          )
          .pipe(
            tap((res: AuthResponse<Payable[]>) => {
              this.auth.updateToken(res);
            }),
            map((res: ApiResponse<Payable[]>) => res.data)
          )
      )
    );
  }

  getVendorInvoices(
    vendorIds: Array<string>,
    params: Params = {}
  ): Observable<Array<PayableGroup>> {
    const store = this.auth.getStore();
    return this.http
      .post(`${environment.base_url}/invoices/store/${store}/paid-invoices`, {
        vendorIds,
        ...params,
      })
      .pipe(
        take(1),
        map((res: ApiResponse<Array<PayableGroup>>) => res.data)
      );
  }

  getVendorVendorOrders(
    type: string,
    vendorId: string,
    params: Params = {}
  ): Observable<ApiResponse<VendorOrders[]>> {
    const store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/vendor/${vendorId}/store/${store}/type/${type}`, {
        params,
      })
      .pipe(
        take(1),
        tap((res: ApiResponse<VendorOrders[]>) => this.auth.updateToken(res))
      );
  }

  getDistributorVendorOrders(
    type: string,
    ids: any[] = [],
    distributorId?: string,
    params: Params = {}
  ): Observable<VendorOrder[]> {
    const store = this.auth.getStore();
    const body = JSON.stringify(ids);
    let url = `${this.base_url}/distributor/store/${store}/type/${type}`;
    if (distributorId) {
      url += `/distributor/${distributorId}`;
    }
    return this.http.put(url, body, { params }).pipe(
      take(1),
      map((res: ApiResponse<VendorOrder[]>) => res.vendorOrders)
    );
  }

  getBorrowedVendorOrders(
    type: string,
    borrowingFromStore: string
  ): Observable<VendorOrder[]> {
    const store = this.auth.getStore();
    return this.http
      .get(
        `${this.base_url}/borrowed/store/${store}/${borrowingFromStore}/type/${type}`
      )
      .pipe(
        take(1),
        map((res: ApiResponse<VendorOrder[]>) => res.vendorOrders)
      );
  }

  getVendorOrdersById(
    type: string,
    ids: any[] = []
  ): Observable<ApiResponse<VendorOrders[]>> {
    const store = this.auth.getStore();
    const body = JSON.stringify(ids);
    return this.http
      .put(`${this.base_url}/store/${store}/type/${type}`, body)
      .pipe(
        take(1),
        tap((res: ApiResponse<VendorOrders[]>) => this.auth.updateToken(res))
      );
  }

  getVendorOrderById(type: string, ids: any[] = []): Observable<VendorOrders> {
    return this.getVendorOrdersById(type, ids).pipe(
      map((res: ApiResponse<VendorOrders[]>) => res.vendorOrders[0])
    );
  }

  getVendorOrder(vendorOrderId: any): Observable<VendorOrder> {
    return this.http.get<VendorOrder>(`${this.base_url}/${vendorOrderId}`);
  }

  getProductHistory(productId: any): Observable<ProductHistory[]> {
    return this.http.get(`${this.base_url}/productHistory/${productId}`).pipe(
      take(1),
      map((res: ApiResponse<ProductHistory[]>) => res.productHistory)
    );
  }

  getPartHistory(productId: any): Observable<ProductHistory[]> {
    return this.http.get(`${this.base_url}/partHistory/${productId}`).pipe(
      take(1),
      map((res: ApiResponse<ProductHistory[]>) => res.productHistory)
    );
  }

  addVendorOrderProduct(order: Order): Observable<VendorOrder> {
    const body = JSON.stringify(order);
    return this.http.post(`${this.base_url}/product`, body).pipe(
      take(1),
      map((res: ApiResponse<VendorOrder>) => res.vendorOrder)
    );
  }

  addProductIdToVendorOrder(order: Order): Observable<VendorOrder> {
    const body = JSON.stringify(order);
    return this.http.post(`${this.base_url}/productId`, body).pipe(
      take(1),
      map((res: ApiResponse<VendorOrder>) => res.vendorOrder)
    );
  }

  addVendorOrder(vendorOrder: VendorOrder): Observable<VendorOrder> {
    vendorOrder.store = this.auth.getStore();
    const body = JSON.stringify(vendorOrder);
    return this.http.post(`${this.base_url}`, body).pipe(
      take(1),
      map((res: ApiResponse<VendorOrder>) => res.vendorOrder)
    );
  }

  updateVendorOrder(
    vendorOrderId: any,
    vendorOrder: VendorOrder
  ): Observable<VendorOrder> {
    vendorOrder.store = this.auth.getStore();
    const body = JSON.stringify(vendorOrder);
    return this.http.put(`${this.base_url}/${vendorOrderId}`, body).pipe(
      take(1),
      map((res: ApiResponse<VendorOrder>) => res.vendorOrder)
    );
  }

  updateVendorOrderById(
    vendorOrderId: string,
    vendorOrder: Partial<VendorOrder>
  ): Observable<VendorOrder> {
    vendorOrder.store = this.auth.getStore();
    const body = JSON.stringify(vendorOrder);
    return this.http.put(`${this.base_url}/v2/${vendorOrderId}`, body).pipe(
      take(1),
      tap((res: AuthResponse<VendorOrder>) => this.auth.updateToken(res)),
      map((res: ApiResponse<VendorOrder>) => res.data)
    );
  }

  deleteVendorOrder(vendorOrderId: any): Observable<VendorOrder> {
    return this.http.delete(`${this.base_url}/${vendorOrderId}`).pipe(
      take(1),
      map((res: ApiResponse<VendorOrder>) => res.vendorOrder)
    );
  }

  deleteProductFromInvoice(
    productId: any,
    orderId: any,
    vendorOrderId: any
  ): Observable<VendorOrder> {
    return this.http
      .delete(
        `${this.base_url}/deleteProductFromInvoice/${productId}/${orderId}/${vendorOrderId}`
      )
      .pipe(map((res: ApiResponse<VendorOrder>) => res.vendorOrder));
  }

  getVendorOrderByInvoiceNumber(
    vendorId: string,
    invoiceNumber: string | number
  ): Observable<any> {
    return this.http
      .get(`${this.base_url}/vendor/${vendorId}/invoice/${invoiceNumber}`)
      .pipe(map((res: ApiResponse<VendorOrders[]>) => res.orders));
  }
}
