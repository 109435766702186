<ng-template [ngIf]="fullHeader" [ngIfElse]="slimHeader">
  <div class="container"
    [style.background-color]="currentStore?.headerColor"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="15px"
    [class.isPrinting]="isPrinting">
    <div class="item item-1" fxFlex="20%">
      <div
        fxLayout="column" style="padding-top:5px;" 
        fxLayoutAlign="space-between left"
        fxLayoutGap="10px">
        <div>{{ objDate | date : "MMMM d, yyyy"}}</div>
        <div *ngIf="user">
          <button class="storeSelect" mat-button [matMenuTriggerFor]="menu">{{ currentStore?.storeName }}</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let s of stores" (click)="setStore(s._id)">
              {{ s.storeName }}
            </button>
          </mat-menu>
  <!-- 

          <span class="storeSelect" (click)="">{{ currentStore?.storeName }}</span>
          <ng-container *ngIf="stores.length < 2; else multiBlock">
            {{ currentStore?.storeName }}
          </ng-container>
          <ng-template #multiBlock>
            <mat-form-field appearance="standard">
              <mat-label>Current store</mat-label>
              <mat-select [(ngModel)]="store">
                <mat-option *ngFor="let s of stores" [value]="s._id">
                  {{ s.storeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template> -->
        </div>
      </div>
    </div>
    <div class="item item-2" fxFlex="60%">
      <!-- <a (click)="goHome()"><img src="/assets/header-AO.png" alt="" class="logo-img-default img-responsive" /></a> -->
      <a (click)="goHome()"><img src="/assets/rimsandgoggles-logo2.svg" alt="" class="logo-img-default img-responsive largelogo" /></a>
    </div>
    <div class="item item-3" fxFlex="20%">
      <div style="padding-top:5px;padding-bottom:15px" 
        fxLayout="column"
        fxLayoutAlign="space-between right"
        fxLayoutGap="10px">
        <ng-container *ngIf="user; else elseBlock">
          <div><a [routerLink]="['/my-account']" class="myaccount">O: {{user.name}}</a></div>
          <div><a (click)="logout()">Logout</a></div>
        </ng-container>
        <ng-template #elseBlock>
          <div>Not logged in</div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #slimHeader>
  <div class="containerSmall"
    [style.background-color]="currentStore?.headerColor"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="15px"
    [class.isPrinting]="isPrinting">
    <div class="item item-1" fxFlex="30%">
      <div
        fxLayout="row" style="padding-top:5px;" 
        fxLayoutAlign="start start"
        fxLayoutGap="30px">
        <div style="padding:3px 0px 3px 0px;">{{ objDate | date : "MMMM d, yyyy"}}</div>
        <div *ngIf="user" style="max-height:22px;">
          <button class="storeSelect" mat-button [matMenuTriggerFor]="menu">{{ currentStore?.storeName }}</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let s of stores" (click)="setStore(s._id)">
              {{ s.storeName }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="item item-2" fxFlex="40%" style="padding-top:5px;">
      <!-- <a (click)="goHome()"><img src="/assets/header-AO.png" alt="" class="logo-img-default img-responsive" /></a> -->
      <a (click)="goHome()"><img src="/assets/rimsandgoggles-logo2.svg" alt="Rims &amp; Goggles" class="logo-img-default img-responsive smalllogo" /></a>
    </div>
    <div class="item item-3" fxFlex="30%">
      <div style="padding-top:5px;padding-right:0px;" 
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px">
        <ng-container *ngIf="user; else elseBlock">
          <div><a [routerLink]="['/my-account']" class="myaccount">O: {{user.name}}</a></div>
          <!-- <div><a (click)="logout()">Logout</a></div> -->
          <div style="max-height:22px;"><button style="top:-10px;" mat-button type="button" (click)="logout()"><mat-icon>logout</mat-icon></button></div>

        </ng-container>
        <ng-template #elseBlock>
          <div fxFlex>Not logged in</div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>







