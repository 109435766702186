<div *ngIf="prescription">
	<div *ngIf="prescription.value.archived; else NonArchivedBlock" style="height:100%;padding-top:5px;"
      [formGroup]="prescription"
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      fxLayoutGap="10px">
      <div fxFlex="20%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="Rx TYPE" type="text" formControlName="type" [readonly]="true" [(value)]="prescription.value.type">
        </mat-form-field>
      </div>
      <div fxFlex="20%">
        <mat-form-field *ngIf="prescription.value.type=='OTHER'" style="width:100%">
          <input matInput placeholder="OTHER" type="text" [(value)]="prescription.value.typeOther" formControlName="typeOther" [readonly]="true">
        </mat-form-field>
      </div>
      <div fxFlex style="text-align:right;">
        <mat-form-field class="date">
          <input matInput [matDatepicker]="expirationDate" (blur)="blurDate2(prescription)" placeholder="EXAM DATE" formControlName="expirationDate">
          <mat-datepicker #expirationDate></mat-datepicker>
        </mat-form-field>

      </div>
      <div fxFlex style="text-align:right;" *ngIf="withDelete">
      	<mat-checkbox formControlName="archived" [(value)]="prescription.value.archived">HIDE</mat-checkbox>
      </div>
  </div>

	<ng-template #NonArchivedBlock>
		<div 
			style="height:100%;padding-top:5px;"
			[formGroup]="prescription"
			fxLayout="row wrap"
			fxLayoutAlign="space-between center"
			fxLayoutGap="10px"
		>
			<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex="calc(100%-85px)">

				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
					<div fxFlex style="text-align:left;">
						<mat-form-field style="padding-left:10px">
							<input matInput placeholder="TYPE" type="text" [(value)]="prescription.value.type" formControlName="type">
						</mat-form-field>

						<!-- <mat-form-field>
							<mat-select placeholder="Rx TYPE" formControlName="type">
								<mat-option *ngFor="let rxType of rxTypes" [(value)]="rxType.value">
									{{ rxType.viewValue }}
								</mat-option>
							</mat-select>
						</mat-form-field> -->
					</div>
					<div *ngIf="prescription.value.from2020Now" fxFlex style="padding:0 10px 0 10px;text-align:center;">
						<img src="/assets/2020-vision-logo.svg" alt="2020 VISION PC" style="width:110px;" />
					</div>
					<div fxFlex="0 0 200px">
						<mat-radio-group aria-label="Select an option" formControlName="oneYearExpiration">
							<mat-radio-button [value]="true">1 YEAR</mat-radio-button>
							<mat-radio-button [value]="false" style="margin-left: 16px;">2 YEAR</mat-radio-button>
						</mat-radio-group>
					</div>


					<div style="justify-self:end;">
						<mat-form-field class="date" style="width:110px;">
							<input matInput [matDatepicker]="examDate" (blur)="blurDate2(prescription)" placeholder="EXAM DATE" formControlName="examDate">
							<mat-datepicker-toggle matSuffix [for]="examDate"></mat-datepicker-toggle>
							<mat-datepicker #examDate></mat-datepicker>
								<mat-hint>MMDDYYYY</mat-hint>
						</mat-form-field>
					</div>

					<div style="justify-self:end;" *ngIf="prescription.value.images?.length > 0 && !prescription.value.from2020Now">
						<button mat-raised-button type="button" (click)="setPrescriptionAsOrder()">SET AS ORDER</button>
					</div>
					<div style="justify-self:end;">
						<button mat-icon-button (click)="savePrescription(prescription)">
							<mat-icon aria-label="SAVE">save</mat-icon>
						</button>
					</div>
					<div style="justify-self:end;" *ngIf="prescription.value.images?.length > 0">
						<button mat-icon-button (click)="printImage(prescription.value.image)">
							<mat-icon aria-label="PRINT">print</mat-icon>
						</button>
					</div>
					<div style="justify-self:end;" *ngIf="prescription.value.from2020Now">
						<button mat-icon-button (click)="generatePdf(prescription)">
							<mat-icon aria-label="PRINT">print</mat-icon>
						</button>
					</div>
					<div *ngIf="withDelete" style="justify-self:end;">
						<button mat-icon-button (click)="deletePrescription()">
							<mat-icon aria-label="DELETE">delete</mat-icon>
						</button>
					</div>
				</div>

				<div>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
						<div *ngIf="prescription.value.images && withImage && !prescription.value.from2020Now" fxFlex="0 0 95px" style="padding:0 10px 0 10px">
							<app-images [images]="prescription.value.images" [tmp]="false" type="prescription"></app-images>
						</div>
						<div *ngIf="image && withImage && !prescription.value.from2020Now" fxFlex="0 0 95px" style="padding:0 10px 0 10px">
							<app-image [image]="image" [tmp]="true" type="prescription"></app-image>
						</div>
						<div *ngIf="prescription.value.from2020Now && viewableImg" fxFlex="0 0 95px" id="from2020img">
							<app-image [image]="viewableImg" [url]="viewableImg" [tmp]="false" type="prescription"></app-image>
						</div>
						<div fxFlex="0 0 calc(100% - 100px">
							<app-prescription-side label="RIGHT" [formGroup]="prescription.controls.right"></app-prescription-side>
							<app-prescription-side label="LEFT" [formGroup]="prescription.controls.left"></app-prescription-side>
						</div>
					</div>
				</div>



				<div>
					<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
						<div fxFlex="50%" *ngIf="prescription.value.eyeExam && prescription.value.from2020Now">
							<mat-toolbar>
								<span>DOCTOR Rx NOTES &amp; ADVICE</span>
							</mat-toolbar>

							<ckeditor [editor]="ClassicEditorBuild" style="width:100%;" placeholder="DOCTOR Rx NOTES & ADVICE" [formControl]="prescription.controls.doctorNotes" [config]="{toolbar: [ 'Bold', 'Italic' ]}" matInput></ckeditor>
						</div>

						<div fxFlex="50%">
							<mat-toolbar>
								<span>Rx NOTES &amp; ADVICE</span>
							</mat-toolbar>

							<ckeditor [editor]="ClassicEditorBuild2" style="width:100%;" placeholder="Rx NOTES & ADVICE" [formControl]="prescription.controls['notes']" [config]="{toolbar: [ 'Bold', 'Italic' ]}" matInput></ckeditor>
						</div>
					</div>
				</div>


				<div>
	<!-- 
		social:
	drive: Boolean,
			useTobacco: Boolean,
			drinkAlcohol: Boolean,
			useIllegalDrugs: Boolean,

	ocular:
			wearGlasses: Boolean,
			glassesAge: String,
			wearContacts: Boolean,
			eyeConditions: Boolean,
			eyeProcedures: Boolean,
			eyedrops: Boolean,

	medical:
			hasPcp: Boolean,
			pcps: String,
			hadPhysical: Boolean,
			dateOfPhysical: String,
			takeMedications: Boolean,
			medications: String,
			drugAllergies: Boolean,
			otherAllergies: Boolean,
			majorInjuries: Boolean,
			pregnantOrBreastFeeding: Boolean,

	health:
			catarcts: Boolean,
			glaucoma: Boolean,
			retinalDiseaseDetachment: Boolean,
			cancer: Boolean,
			diabetes: Boolean,
			diabetesLastA1C: String,
			diabetesBloodSugerLevel: Number,
			diabetesBloodSugerLevelDate: String,
			highBloodPressure: Boolean,
			lazyEye: Boolean,
			lazyEyeDroopyEyelid: Boolean,
			lazyEyeEyeTurnStrabismus: Boolean,
			lazyEyePrismInGlasses: Boolean,
			migraines: Boolean,
			areENTProblems: Boolean,
			macularDegeneration: Boolean,
			heartCondition: Boolean,
			thyroidDisease: Boolean,
			arthritis: Boolean,
			sleepApnea: Boolean,
			asthma: Boolean,
			lungDisease: Boolean,
			stroke: Boolean,
			intestinalDisease: Boolean,
			HIV: Boolean,
			depressionAnxiety: Boolean,
			otherConditions: Boolean,

		symptoms
			reasonForVisit: String,
			chiefComplaintPatient: String,
			redness: Boolean,
			drynessItchingTearingBurning: Boolean,
			rubbing: Boolean,
			corneaProblems: Boolean,
			keratoconus: Boolean,
			cornealDystrophy: Boolean,
			facialHerpes: Boolean,
			sand: Boolean,
			irritation: Boolean,
			foreignBody: Boolean,
			tearingInCold: Boolean,
			blurring: Boolean,
			squinting: Boolean,
			painInEyes: Boolean,
			doubleVision: Boolean,
			floaters: Boolean,
			lossOfVision: Boolean,
			lossOfPeripheralVision: Boolean,
			distortedVision: Boolean,
			lossOfSideVision: Boolean,

		notes:
			author: String,
			important: Boolean,
			datetime: String,
			comments: String,
			occupation: String,

			-->
					<mat-accordion multi *ngIf="prescription.value.eyeExam && prescription.value.from2020Now">
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									HISTORY
								</mat-panel-title>
							</mat-expansion-panel-header>

							<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="text-align:left">
								<div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
									<div><strong>social:</strong></div>
									<div *ngIf="prescription.value.eyeExam?.drive">DRIVE:</div>
									<div *ngIf="prescription.value.eyeExam?.useTobacco">TOBACCO:</div>
									<div *ngIf="prescription.value.eyeExam?.drinkAlcohol">ALCOHOL:</div>
									<div *ngIf="prescription.value.eyeExam?.useIllegalDrugs">ILLEGAL DRUGS:</div>
									<div>&nbsp;</div>

									<div><strong>ocular:</strong></div>
									<div *ngIf="prescription.value.eyeExam?.wearGlasses">WEAR GLASSES:</div>
									<div *ngIf="prescription.value.eyeExam?.glassesAge">GLASSES AGE:</div>
									<div *ngIf="prescription.value.eyeExam?.wearContacts">WEAR CONTACTS:</div>
									<div *ngIf="prescription.value.eyeExam?.eyeConditions">EYE CONDITIONS:</div>
									<div *ngIf="prescription.value.eyeExam?.eyeProcedures">EYE PROCEDURES:</div>
									<div *ngIf="prescription.value.eyeExam?.eyedrops">EYEDROPS:</div>
									<div>&nbsp;</div>

									<div><strong>medical:</strong></div>
									<div *ngIf="prescription.value.eyeExam?.hasPcp">PCP:</div>
									<div *ngIf="prescription.value.eyeExam?.pcps">PCPS:</div>
									<div *ngIf="prescription.value.eyeExam?.hadPhysical">HAD PHYSICAL:</div>
									<div *ngIf="prescription.value.eyeExam?.dateOfPhysical">DATE OF PHYSICAL:</div>
									<div *ngIf="prescription.value.eyeExam?.takeMedications">TAKE MEDICATIONS:</div>
									<div *ngIf="prescription.value.eyeExam?.medications">MEDICATIONS:</div>
									<div *ngIf="prescription.value.eyeExam?.drugAllergies">DRUG ALLERGIES:</div>
									<div *ngIf="prescription.value.eyeExam?.otherAllergies">OTHER ALLERGIES:</div>
									<div *ngIf="prescription.value.eyeExam?.majorInjuries">MAJOR INJURIES:</div>
									<div *ngIf="prescription.value.eyeExam?.pregnantOrBreastFeeding">PREGNANT OR BREASTFEEDING:</div>
								</div>

								<div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="yesNo">
									<div>&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.drive">{{ prescription.value.eyeExam?.drive ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.useTobacco">{{ prescription.value.eyeExam?.useTobacco ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.drinkAlcohol">{{ prescription.value.eyeExam?.drinkAlcohol ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.useIllegalDrugs">{{ prescription.value.eyeExam?.useIllegalDrugs ? 'YES' : 'NO' }}</div>
									<div>&nbsp;</div>

									<div>&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.wearGlasses">{{ prescription.value.eyeExam?.wearGlasses ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.glassesAge">{{ prescription.value.eyeExam?.glassesAge }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.wearContacts">{{ prescription.value.eyeExam?.wearContacts ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.eyeConditions">{{ prescription.value.eyeExam?.eyeConditions ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.eyeProcedures">{{ prescription.value.eyeExam?.eyeProcedures ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.eyedrops">{{ prescription.value.eyeExam?.eyedrops ? 'YES' : 'NO' }}</div>
									<div>&nbsp;</div>

									<div>&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.hasPcp">{{ prescription.value.eyeExam?.hasPcp ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.pcps">{{ prescription.value.eyeExam?.pcps }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.hadPhysical">{{ prescription.value.eyeExam?.hadPhysical ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.dateOfPhysical">{{ prescription.value.eyeExam?.dateOfPhysical | date:'shortDate' }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.takeMedications">{{ prescription.value.eyeExam?.takeMedications ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.medications">{{ prescription.value.eyeExam?.medications }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.drugAllergies">{{ prescription.value.eyeExam?.drugAllergies ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.otherAllergies">{{ prescription.value.eyeExam?.otherAllergies ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.majorInjuries">{{ prescription.value.eyeExam?.majorInjuries ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.pregnantOrBreastFeeding">{{ prescription.value.eyeExam?.pregnantOrBreastFeeding ? 'YES' : 'NO' }}</div>

								</div>

								<div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
									<div><strong>health:</strong></div>
									<div *ngIf="prescription.value.eyeExam?.cataracts">CATARACTS:</div>
									<div *ngIf="prescription.value.eyeExam?.glaucoma">GLAUCOMA:</div>
									<div *ngIf="prescription.value.eyeExam?.retinalDiseaseDetachment">RETINAL DISEASE/DETACHMENT:</div>
									<div *ngIf="prescription.value.eyeExam?.cancer">CANCER:</div>
									<div *ngIf="prescription.value.eyeExam?.diabetes">DIABETES:</div>
									<div *ngIf="prescription.value.eyeExam?.diabetesLastA1C">LAST A1C:</div>
									<div *ngIf="prescription.value.eyeExam?.diabetesBloodSugerLevel">BLOOD SUGAR LEVEL:</div>
									<div *ngIf="prescription.value.eyeExam?.highBloodPressure">HIGH BLOOD PRESSURE:</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEye">LAZY EYE:</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEyeDroopyEyelid">DROOPY EYELID:</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEyeEyeTurnStrabismus">TURN STRABISMUS:</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEyePrismInGlasses">PRISM IN GLASSES:</div>
									<div *ngIf="prescription.value.eyeExam?.areENTProblems">ENT PROBLEMS:</div>
									<div *ngIf="prescription.value.eyeExam?.macularDegeneration">MACULAR DEGENERATION:</div>
									<div *ngIf="prescription.value.eyeExam?.heartCondition">HEART CONDITION:</div>
									<div *ngIf="prescription.value.eyeExam?.thyroidDisease">THYROID DISEASE:</div>
									<div *ngIf="prescription.value.eyeExam?.arthritis">ARTHRITIS:</div>
									<div *ngIf="prescription.value.eyeExam?.sleepApnea">SLEEP APNEA:</div>
									<div *ngIf="prescription.value.eyeExam?.asthma">ASTHMA:</div>
									<div *ngIf="prescription.value.eyeExam?.lungDisease">LUNG DISEASE:</div>
									<div *ngIf="prescription.value.eyeExam?.stroke">STROKE:</div>
									<div *ngIf="prescription.value.eyeExam?.intestinalDisease">INTESTINAL DISEASE:</div>
									<div *ngIf="prescription.value.eyeExam?.HIV">HIV:</div>
									<div *ngIf="prescription.value.eyeExam?.depressionAnxiety">DEPRESSION/ANXIETY:</div>
									<div *ngIf="prescription.value.eyeExam?.otherConditions">OTHER CONDITIONS:</div>
								</div>

								<div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="yesNo">
									<div>&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.cataracts">{{ prescription.value.eyeExam?.cataracts ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.glaucoma">{{ prescription.value.eyeExam?.glaucoma ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.retinalDiseaseDetachment">{{ prescription.value.eyeExam?.retinalDiseaseDetachment ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.cancer">{{ prescription.value.eyeExam?.cancer ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.diabetes">{{ prescription.value.eyeExam?.diabetes ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.diabetesLastA1C">{{ prescription.value.eyeExam?.diabetesLastA1C }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.diabetesBloodSugerLevel">{{ prescription.value.eyeExam?.diabetesBloodSugerLevel }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.highBloodPressure">{{ prescription.value.eyeExam?.highBloodPressure ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEye">{{ prescription.value.eyeExam?.lazyEye ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEyeDroopyEyelid">{{ prescription.value.eyeExam?.lazyEyeDroopyEyelid ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEyeEyeTurnStrabismus">{{ prescription.value.eyeExam?.lazyEyeEyeTurnStrabismus ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lazyEyePrismInGlasses">{{ prescription.value.eyeExam?.lazyEyePrismInGlasses ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.areENTProblems">{{ prescription.value.eyeExam?.areENTProblems ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.macularDegeneration">{{ prescription.value.eyeExam?.macularDegeneration ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.heartCondition">{{ prescription.value.eyeExam?.heartCondition ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.thyroidDisease">{{ prescription.value.eyeExam?.thyroidDisease ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.arthritis">{{ prescription.value.eyeExam?.arthritis ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.sleepApnea">{{ prescription.value.eyeExam?.sleepApnea ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.asthma">{{ prescription.value.eyeExam?.asthma ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lungDisease">{{ prescription.value.eyeExam?.lungDisease ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.stroke">{{ prescription.value.eyeExam?.stroke ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.intestinalDisease">{{ prescription.value.eyeExam?.intestinalDisease ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.HIV">{{ prescription.value.eyeExam?.HIV ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.depressionAnxiety">{{ prescription.value.eyeExam?.depressionAnxiety ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.otherConditions">{{ prescription.value.eyeExam?.otherConditions ? 'YES' : 'NO' }}</div>
								</div>
							</div>

						</mat-expansion-panel>

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									SYMPTOMS
								</mat-panel-title>
							</mat-expansion-panel-header>

							<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="text-align:left">
								<div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
									<div *ngIf="prescription.value.eyeExam?.reasonForVisit">REASON FOR VISIT:</div>
									<div *ngIf="prescription.value.eyeExam?.chiefComplaintPatient">CHIEF COMPLAINT:</div>
									<div *ngIf="prescription.value.eyeExam?.redness">REDNESS:</div>
									<div *ngIf="prescription.value.eyeExam?.drynessItchingTearingBurning">DRYNESS/ITCHING/TEARING/BURNING:</div>
									<div *ngIf="prescription.value.eyeExam?.rubbing">RUBBING:</div>
									<div *ngIf="prescription.value.eyeExam?.corneaProblems">CORNEA PROBLEMS:</div>
									<div *ngIf="prescription.value.eyeExam?.keratoconus">KETATOCONUS:</div>
									<div *ngIf="prescription.value.eyeExam?.cornealDystrophy">CORNEAL DYSTROPHY:</div>
									<div *ngIf="prescription.value.eyeExam?.facialHerpes">FACIAL HERPES:</div>
									<div *ngIf="prescription.value.eyeExam?.sand">SAND:</div>
									<div *ngIf="prescription.value.eyeExam?.irritation">IRRIGATION:</div>
									<div *ngIf="prescription.value.eyeExam?.foreignBody">FOREIGN BODY:</div>
									<div *ngIf="prescription.value.eyeExam?.tearingInCold">TEARING IN COLD:</div>
									<div *ngIf="prescription.value.eyeExam?.blurring">BLURRING:</div>
									<div *ngIf="prescription.value.eyeExam?.squinting">SQUINTING:</div>
									<div *ngIf="prescription.value.eyeExam?.painInEyes">PAIN IN EYES:</div>
									<div *ngIf="prescription.value.eyeExam?.doubleVision">DOUBLE VISION:</div>
									<div *ngIf="prescription.value.eyeExam?.floaters">FLOATERS:</div>
									<div *ngIf="prescription.value.eyeExam?.lossOfVision">LOSS OF VISION:</div>
									<div *ngIf="prescription.value.eyeExam?.lossOfPeripheralVision">LOSS OF PERIPHERAL VISION:</div>
									<div *ngIf="prescription.value.eyeExam?.distortedVision">DISTORTED VISION:</div>
									<div *ngIf="prescription.value.eyeExam?.lossOfSideVision">LOSS OF SIDE VISION:</div>
								</div>

								<div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="yesNo">
									<div *ngIf="prescription.value.eyeExam?.reasonForVisit">{{ prescription.value.eyeExam?.reasonForVisit }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.chiefComplaintPatient">{{ prescription.value.eyeExam?.chiefComplaintPatient }}&nbsp;</div>
									<div *ngIf="prescription.value.eyeExam?.redness">{{ prescription.value.eyeExam?.redness ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.drynessItchingTearingBurning">{{ prescription.value.eyeExam?.drynessItchingTearingBurning ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.rubbing">{{ prescription.value.eyeExam?.rubbing ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.corneaProblems">{{ prescription.value.eyeExam?.corneaProblems ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.keratoconus">{{ prescription.value.eyeExam?.keratoconus ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.cornealDystrophy">{{ prescription.value.eyeExam?.cornealDystrophy ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.facialHerpes">{{ prescription.value.eyeExam?.facialHerpes ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.sand">{{ prescription.value.eyeExam?.sand ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.irritation">{{ prescription.value.eyeExam?.irritation ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.foreignBody">{{ prescription.value.eyeExam?.foreignBody ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.tearingInCold">{{ prescription.value.eyeExam?.tearingInCold ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.blurring">{{ prescription.value.eyeExam?.blurring ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.squinting">{{ prescription.value.eyeExam?.squinting ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.painInEyes">{{ prescription.value.eyeExam?.painInEyes ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.doubleVision">{{ prescription.value.eyeExam?.doubleVision ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.floaters">{{ prescription.value.eyeExam?.floaters ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lossOfVision">{{ prescription.value.eyeExam?.lossOfVision ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lossOfPeripheralVision">{{ prescription.value.eyeExam?.lossOfPeripheralVision ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.distortedVision">{{ prescription.value.eyeExam?.distortedVision ? 'YES' : 'NO' }}</div>
									<div *ngIf="prescription.value.eyeExam?.lossOfSideVision">{{ prescription.value.eyeExam?.lossOfSideVision ? 'YES' : 'NO' }}</div>
								</div>
							</div>

						</mat-expansion-panel>

					</mat-accordion>

				</div>

			</div>
		</div>
	</ng-template>
</div>
<iframe *ngIf="pdfUrl" [src]="pdfUrl" class="print-only" (load)="loadPdf($event)"></iframe>
<iframe *ngIf="imageData" [src]="imageData" class="hidden-view" (load)="onImgLoaded($event)"></iframe>
