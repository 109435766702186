<div *ngIf="loading">
	<h3 style="text-align:center;">LOADING REPORT</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!loading" style="width:100%">
		<div style="display:block;padding-bottom:20px;">{{ report.data.length }} EXPIRED RX CUSTOMER EMAILS</div>
  	<div style="display:block;">
  		<button mat-raised-button type="button" (click)="saveCSV($event);$event.stopPropagation()">DOWNLOAD CSV</button>
  	</div>

</div>