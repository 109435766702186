<div>
	<img #image [src]="data.image" style="max-width:75vw;max-height:75vh;" (click)="ok()">
</div>
<div style="text-align:center;" *ngIf="!data.image.startsWith('data:')">
	<button mat-button (click)="clickCrop(data.imageId)"><mat-icon>crop</mat-icon></button>
	<ng-container *ngIf="!uploaderProduct.isUploading" floatLabel="never">
		<button mat-button (click)="clickOpenUpload('fileInputProduct'+rnd)"><mat-icon>file_upload</mat-icon></button>

		<input  #fileInputProduct id="{{'fileInputProduct'+rnd}}" type="file" name="photoProduct" accept="image/*" ng2FileSelect [uploader]="uploaderProduct" multiple [hidden]="true" />
	</ng-container>
	<!-- <button mat-button (click)="clickUpload(data.imageId)"><mat-icon>file_upload</mat-icon></button> -->
  <button mat-button (click)="onPrint()"><mat-icon>print</mat-icon></button>
  <!-- <button mat-button (click)="onDownload()"><mat-icon>download</mat-icon></button> -->

	<button mat-button (click)="clickDelete(data.imageId)"><mat-icon>delete_outline</mat-icon></button>
</div>
