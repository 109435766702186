<ng-container [formGroup]="productsForm">
	<button mat-raised-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">
		<mat-icon>more_vert</mat-icon>
		<span>MODIFY {{ selection.selected.length }}</span>
		<span *ngIf="selection.selected.length != 1"> ITEMS</span>
		<span *ngIf="selection.selected.length == 1"> ITEM</span>
	</button>
	<mat-menu #menu="matMenu">
	  <button mat-menu-item type="button" (click)="setValue('quantity', 1)" style="margin-left:10px;">SET QTY 1</button>
	  <button mat-menu-item type="button" (click)="setValue('quantity', 0)" style="margin-left:10px;">SET QTY 0</button>
	  <button mat-menu-item type="button" (click)="setValue('status', 'green')" style="margin-left:10px;" class="green">SET STATUS</button>
	  <button mat-menu-item type="button" (click)="setValue('status', 'yellow')" style="margin-left:10px;" class="yellow">SET STATUS</button>
	  <button mat-menu-item type="button" (click)="setValue('status', 'red')" style="margin-left:10px;" class="red">SET STATUS</button>
	  <button mat-menu-item type="button" (click)="setValue('status', 'purple')" style="margin-left:10px;" class="purple">SET STATUS</button>
	  <button mat-menu-item type="button" (click)="setValue('status', 'white')" style="margin-left:10px;" class="white">SET STATUS</button>
	  <button mat-menu-item type="button" (click)="setValue('status', 'black')" style="margin-left:10px;" class="black">SET STATUS</button>
	  <button mat-menu-item *ngIf="authorizedSendToStack" type="button" (click)="sendToStack()" style="margin-left:10px;">SEND TO STACK</button>
	  <button mat-menu-item *ngIf="authorizedSendToStack" type="button" (click)="orderedProducts()" style="margin-left:10px;">ORDERED</button>
	  <button mat-menu-item *ngIf="authorizedSendToStack" type="button" (click)="returnProducts()" style="margin-left:10px;">RETURN</button>

	  <button mat-menu-item type="button" style="margin-left:10px;" [matMenuTriggerFor]="borrow">BORROW</button>
	  <button mat-menu-item type="button" style="margin-left:10px;" formGroupName="multipleFields" [matMenuTriggerFor]="fields">SET FIELD</button>
	  <button mat-menu-item type="button" style="margin-left:10px;" [matMenuTriggerFor]="merge">MERGE</button>
	</mat-menu>

	<mat-menu #borrow="matMenu" style="max-width:400px;width:400px;">
    <!-- <button mat-menu-item type="button" style="max-width:500px;width:500px;background-color: transparent;"> -->
    <!-- <span mat-menu-item> -->
      <mat-select #formRow #statusSelect placeholder="TO STORE" [(value)]="borrowingStore" (click)="$event.stopPropagation();" style="max-width:180px;width:180px;margin-left:20px;">
        <mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption._id">
          {{storeOption.storeName}}
        </mat-option>
      </mat-select>

      <mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
        <input matInput required placeholder="" type="text" [formControl]="borrowingInitials" (click)="$event.stopPropagation();" >
        <mat-label><mat-icon>gesture</mat-icon></mat-label>
      </mat-form-field>

      <button mat-button type="button" (click)="borrowSelectedProducts($event);" style="margin-left:10px;" [disabled]="borrowingStore==null || borrowingInitials.value=='' || borrowingInitials.value==null"><mat-icon>save</mat-icon><span>BORROW</span></button>
	  	<!-- </span> -->
	  <!-- </button> -->
  </mat-menu>

	<mat-menu #fields="matMenu">
	  <button mat-menu-item type="button" [matMenuTriggerFor]="status">STATUS</button>
	  <!-- <button mat-menu-item type="button" [matMenuTriggerFor]="type">TYPE</button> -->
	  <button mat-menu-item *ngIf="authorized" type="button" [matMenuTriggerFor]="cost">COST</button>
	  <button mat-menu-item *ngIf="authorized" type="button" [matMenuTriggerFor]="retail">RETAIL</button>
	  <button mat-menu-item type="button" [matMenuTriggerFor]="quantity">QTY.</button>
	</mat-menu>

	<ng-container formGroupName="multipleFields">
	  <mat-menu #status="matMenu">
	    <button mat-menu-item type="button">
	      <mat-form-field floatLabel="never" style="width:75px !important;" [ngClass]="productsForm.value.multipleFields.status">
					<mat-select placeholder="STATUS" formControlName="status" (click)="$event.stopPropagation();">
						<mat-option value="green" class="green"></mat-option>
						<mat-option value="yellow" class="yellow"></mat-option>
						<mat-option value="red" class="red"></mat-option>
						<mat-option value="purple" class="purple"></mat-option>
						<mat-option value="white" class="white"></mat-option>
						<mat-option value="black" class="black"></mat-option>
					</mat-select>
	      </mat-form-field>

	      <button mat-button type="button" (click)="setFieldForMultipleProducts('status');" style="margin-left:10px;"><mat-icon>save</mat-icon><span>SAVE</span></button>
	  	</button>
	  </mat-menu>

	  <mat-menu #cost="matMenu">
	    <button mat-menu-item type="button">
	      <mat-form-field floatLabel="never" style="width:60px !important;">
	        <input #formRow matInput placeholder="COST" type="text" formControlName="cost" (click)="$event.stopPropagation();">
	      </mat-form-field>

	      <button mat-button type="button" (click)="setFieldForMultipleProducts('cost');" style="margin-left:10px;"><mat-icon>save</mat-icon><span>SAVE</span></button>
	  	</button>
	  </mat-menu>

	  <mat-menu #retail="matMenu">
	    <button mat-menu-item type="button">
	      <mat-form-field floatLabel="never" style="width:60px !important;">
	        <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (click)="$event.stopPropagation();">
	      </mat-form-field>

	      <button mat-button type="button" (click)="setFieldForMultipleProducts('retail');" style="margin-left:10px;"><mat-icon>save</mat-icon><span>SAVE</span></button>
	  	</button>
	  </mat-menu>

	  <mat-menu #quantity="matMenu">
	    <span mat-menu-item>
	      <mat-form-field floatLabel="never" style="width:60px !important;">
	        <input #formRow matInput placeholder="QTY." type="text" formControlName="quantity" (click)="$event.stopPropagation();">
	      </mat-form-field>

	      <button mat-button type="button" (click)="setFieldForMultipleProducts('quantity');" style="margin-left:10px;"><mat-icon>save</mat-icon><span>SAVE</span></button>
	  	</span>
	  </mat-menu>

	  <mat-menu #merge="matMenu">
			<mat-card>
				<!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
				<mat-card-subtitle>SELECT IMAGE:</mat-card-subtitle>
				<mat-card-content>
					<mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="mergeKeepId" (click)="$event.stopPropagation();">
						<mat-button-toggle [value]="product.value._id" aria-label="" *ngFor="let product of selection.selected" style="padding:10px 0;">
							<app-images
								[images]="product.value.images"
								type="product"
								[productId]="product.value._id"
								[clickable]="false">
							</app-images>
						</mat-button-toggle>
					</mat-button-toggle-group>
				</mat-card-content>
				<mat-card-subtitle>QTY:</mat-card-subtitle>
				<mat-card-content>
					<mat-button-toggle-group #group2="matButtonToggleGroup" [formControl]="mergeQty" (click)="$event.stopPropagation();">
						<mat-button-toggle value="1" aria-label="1">1</mat-button-toggle>
						<mat-button-toggle value="2" aria-label="2">2</mat-button-toggle>
						<mat-button-toggle value="3" aria-label="3">3</mat-button-toggle>
						<mat-button-toggle value="4" aria-label="4">4</mat-button-toggle>
						<mat-button-toggle value="5" aria-label="5">5</mat-button-toggle>
					</mat-button-toggle-group>
				</mat-card-content>
				<mat-divider inset></mat-divider>
				<mat-card-actions>
					<button mat-raised-button type="button" (click)="mergeProducts()" style="margin-left:10px;"><mat-icon>merge</mat-icon><span>MERGE</span></button>
				</mat-card-actions>
			</mat-card>
		</mat-menu>
	</ng-container>
</ng-container>
