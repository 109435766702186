import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { formatNumber } from '@angular/common';
import { FormatData } from '@classes/format-data';
import { FormGroup, ControlContainer, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, switchMap, startWith } from 'rxjs/operators';
import { parsePhoneNumber } from 'libphonenumber-js'
import { User } from '@models/user';
import { AuthService } from '@services/auth.service';
import { PrescriptionService } from '@services/prescription.service';

import { Patient } from '@models/patient';

import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-form-patient',
  templateUrl: './form-patient.component.html',
  styleUrls: ['./form-patient.component.css']
})
export class FormPatientComponent implements OnInit {
	@Input() public parentForm: FormGroup;
	@Input() public patient: Patient;
	@Input() public newPatient: Boolean;

	user: User;
	stateCtrl: FormControl;
	favorites: String[];
	lastExam: string = 'test';

	public ClassicEditorBuild = ClassicEditorBuild;

	filteredStates: Observable<any[]>;

	states: any[] = [
		{"name": "Alabama", "abbreviation": "AL"},
		{"name": "Alaska", "abbreviation": "AK"},
		{"name": "Arizona", "abbreviation": "AZ"},
		{"name": "Arkansas", "abbreviation": "AR"},
		{"name": "California", "abbreviation": "CA"},
		{"name": "Colorado", "abbreviation": "CO"},
		{"name": "Connecticut", "abbreviation": "CT"},
		{"name": "Delaware", "abbreviation": "DE"},
		{"name": "District Of Columbia", "abbreviation": "DC"},
		{"name": "Florida", "abbreviation": "FL"},
		{"name": "Georgia", "abbreviation": "GA"},
		{"name": "Guam", "abbreviation": "GU"},
		{"name": "Hawaii", "abbreviation": "HI"},
		{"name": "Idaho", "abbreviation": "ID"},
		{"name": "Illinois", "abbreviation": "IL"},
		{"name": "Indiana", "abbreviation": "IN"},
		{"name": "Iowa", "abbreviation": "IA"},
		{"name": "Kansas", "abbreviation": "KS"},
		{"name": "Kentucky", "abbreviation": "KY"},
		{"name": "Louisiana", "abbreviation": "LA"},
		{"name": "Maine", "abbreviation": "ME"},
		{"name": "Maryland", "abbreviation": "MD"},
		{"name": "Massachusetts", "abbreviation": "MA"},
		{"name": "Michigan", "abbreviation": "MI"},
		{"name": "Minnesota","abbreviation": "MN"},
		{"name": "Mississippi", "abbreviation": "MS"},
		{"name": "Missouri", "abbreviation": "MO"},
		{"name": "Montana", "abbreviation": "MT"},
		{"name": "Nebraska", "abbreviation": "NE"},
		{"name": "Nevada", "abbreviation": "NV"},
		{"name": "New Hampshire", "abbreviation": "NH"},
		{"name": "New Jersey", "abbreviation": "NJ"},
		{"name": "New Mexico", "abbreviation": "NM"},
		{"name": "New York", "abbreviation": "NY"},
		{"name": "North Carolina", "abbreviation": "NC"},
		{"name": "North Dakota", "abbreviation": "ND"},
		{"name": "Ohio", "abbreviation": "OH"},
		{"name": "Oklahoma", "abbreviation": "OK"},
		{"name": "Oregon", "abbreviation": "OR"},
		{"name": "Pennsylvania", "abbreviation": "PA"},
		{"name": "Rhode Island", "abbreviation": "RI"},
		{"name": "South Carolina", "abbreviation": "SC"},
		{"name": "South Dakota", "abbreviation": "SD"},
		{"name": "Tennessee", "abbreviation": "TN"},
		{"name": "Texas", "abbreviation": "TX"},
		{"name": "Utah", "abbreviation": "UT"},
		{"name": "Vermont", "abbreviation": "VT"},
		{"name": "Virginia", "abbreviation": "VA"},
		{"name": "Washington", "abbreviation": "WA"},
		{"name": "West Virginia", "abbreviation": "WV"},
		{"name": "Wisconsin", "abbreviation": "WI"},
		{"name": "Wyoming", "abbreviation": "WY"}
	];

  	howDidYouFindUsTypes = [
	    {value: 'EXISTING CUSTOMER', viewValue: 'EXISTING CUSTOMER'},
	    {value: 'FRIEND OR FAMILY', viewValue: 'FRIEND OR FAMILY'},
	    {value: 'PRINT ADVERTISING', viewValue: 'PRINT ADVERTISING'},
	    {value: 'SEARCH ENGINE', viewValue: 'SEARCH ENGINE'},
	    {value: 'WALKING PAST STORE', viewValue: 'WALKING PAST STORE'},
	    {value: 'OTHER', viewValue: 'OTHER'}
	  ];

	constructor(private router: Router, private authService: AuthService, private prescriptionService: PrescriptionService, private cdRef:ChangeDetectorRef) {
		this.stateCtrl = new FormControl();
		this.filteredStates = this.stateCtrl.valueChanges
			.pipe(startWith(null))
			.pipe(map((state) => { this.parentForm.value['state'] = state; return state ? this.filterStates(state.toString()) : this.states.slice(); } ));
	}

	ngOnInit() {
      let lastExamSubscription = this.prescriptionService.getPatientLastExam(this.patient._id).subscribe((lastExam: string) => {
        // this.lastExam = lastExam;
    		let fb = new FormBuilder();
        // this.parentForm.addControl('lastExam', fb.control(lastExam));

        this.parentForm.addControl('lastExam', new FormControl())
        this.parentForm.get('lastExam').setValue(lastExam);
        this.parentForm.get('lastExam').disable();
        // this.parentForm.get('lastExam').setValue(lastExam);
        this.cdRef.detectChanges();
      });

    	this.authService.verify().subscribe( (res) => { 
    		this.user = res['user'];
    		if (this.user.role!='MANAGER' && this.user.role!='OWNER/ADMIN') {
    			this.parentForm.controls.discountStatus.disable();
    			this.parentForm.controls.storeCredit.disable();
    		}
    	});
		this.stateCtrl.setValue(this.patient.state);
	}

	filterStates(name: string) {
		return this.states.filter(state => (state.abbreviation + ' ' + state.name).toLowerCase().indexOf(name.toLowerCase()) >= 0);
	}

	updateState(event) {
		this.parentForm.get('state').setValue(event.target.value);
	}

	blurDate() {
		this.parentForm.get('dob').setValue(this.parentForm.get('dob').value);
	}

	blurDate2(prescriptionForm) {
		prescriptionForm.setValue(prescriptionForm.value);
	}

	blurPhone(prescriptionForm) {
		const phoneNumber = parsePhoneNumber(prescriptionForm.value, 'US');
		prescriptionForm.setValue(phoneNumber.formatNational());


	}

	phoneType(event, field) {
		var code = event.keyCode || event.which;
		if (this.parentForm.controls[field].value.length > 2) {
			const phoneNumber = parsePhoneNumber(this.parentForm.controls[field].value, 'US');
	  	if (code != '9') {
				this.parentForm.controls[field].setValue(phoneNumber.formatNational());
			}
		}
	}

  formatCurrency($event,control) {
  	let value = FormatData.formatCurrency(control.value);
    control.setValue(value);
  }

}
