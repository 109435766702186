import { Pipe, Inject, LOCALE_ID, Component, OnInit, ViewChild, HostListener, EventEmitter, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, Output, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription } from 'rxjs';
import { OrdersBaseComponent } from '@components/admin/managed-board/base/orders-base/orders-base.component';
import { FileUploader } from 'ng2-file-upload';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-returns-submit',
  templateUrl: './returns-submit.component.html',
  styleUrls: ['./returns-submit.component.css']
})
export class ReturnsSubmitComponent extends OrdersBaseComponent implements OnInit {
  @Input() orderForm: FormGroup;
  private _selected: FormGroup[];
  @Input()
  set selected(val: FormGroup[]) {
    this._selected = val;
    this.selectionInvalid = this.getSelectedInvalid();
    this.cdRef.detectChanges();
  }
  @Output() saveIncomingOrder = new EventEmitter<Boolean>();
 	@Output() updateTotal = new EventEmitter<Boolean>();
  selectionInvalid: boolean = false;
  dataSource3 = new MatTableDataSource<FormGroup>();
  displayedColumns = ['raNumber', 'date', 'shippingMethod', 'trackingNumber', 'proposedCredit', 'action'];
  storeOptions: any[];
  public uploaderProduct:FileUploader = new FileUploader({url: environment.base_url + '/image/product', itemAlias: 'photoProduct'});
  expandedElement2 = null;

  constructor(@Inject(LOCALE_ID) public locale: string, public auth: AuthService, public orderService: OrderService, public vendorOrderService: VendorOrderService, public productService: ProductService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public fb: FormBuilder, public route: ActivatedRoute, public notificationService: NotificationService) {
    super(locale, auth, orderService, vendorOrderService, productService, storeService, cdRef, fb, route, notificationService);
  }

  ngOnInit() {
  	super.ngOnInit();
  }

  ngAfterViewInit() {
    this.setOrderForm();
  }

  setOrderForm() {
    // this.orderForm = this.fb.group({
    //   orders: this.fb.array([])
    // });
    // let ordersArray = <FormArray>this.orderForm.controls['orders'];
    // ordersArray.reset();
    // ordersArray.push(this.initOrderItem());
    this.dataSource3 = new MatTableDataSource<FormGroup>([this.orderForm]);
    this.cdRef.detectChanges();
  }

  initOrderItem() {
    return this.fb.group({
      'raNumber': ['', Validators.required],
      'trackingNumber': ['', Validators.required],
      'date': [new Date(), Validators.required],
      'shippingMethod': ['', Validators.required],
      'proposedCredit': ['', Validators.required],
      'initials': ['', Validators.required]
    });
  }

  calculateTotal() {
    this.updateTotal.emit(true);
  }

  saveFrames() {
		this.saveIncomingOrder.emit(true);
  }

  getSelectedInvalid():boolean {
    let selectedInvalid = false;
    this._selected.forEach((order) => {
      if (order.invalid) {
        selectedInvalid = true;
      }
    });
    return selectedInvalid;
  }


}
