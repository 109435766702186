import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Order } from '@models/order';
import { OrderService } from '@services/order.service';
import { AuthService } from '@services/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-form-order-image',
  templateUrl: './form-order-image.component.html',
  styleUrls: ['./form-order-image.component.css']
})
export class FormOrderImageComponent implements OnInit {
	@Input() public order: FormGroup;
	@Input() public withDelete: Boolean;
	@Input() public parentForm: FormGroup;
	@Input() public index: Number;
	@Output() clickDelete = new EventEmitter<any>();
	@Output() clickReturn = new EventEmitter<any>();
	@Output() clickExchange = new EventEmitter<any>();
	@Output() clickSetAsPrescription = new EventEmitter<any>();
	@Input() selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<FormGroup>>(); 
  typeOptions: Object[] = [{viewValue: 'O', value: 'O'}, {viewValue: 'N', value: 'N'}, {viewValue: 'A', value: 'A'}, {viewValue: 'F', value: 'F'}];
  editOrderId: string = null;
  imageURL = environment.base_url + '/image/';

	constructor(private auth: AuthService, private orderService: OrderService, private fb: FormBuilder, public cdRef:ChangeDetectorRef) { }

  deleteOrder(event) {
    this.clickDelete.next({_id:this.order.value._id, index:this.index});
  }

  returnOrder(event) {
    this.clickReturn.next({order:this.order.value, index:this.index});
  }

  exchangeOrder(event) {
    this.clickExchange.next({order:this.order.value, index:this.index});
  }

  setOrderAsPrescription(event) {
  	console.log(this.order)
    this.clickSetAsPrescription.next({_id:this.order.value._id, index:this.index});
  }

  editOrder(orderId) {
  	this.editOrderId = (this.editOrderId == orderId) ? null : orderId;
  }

	toggleSelect(order) {
    this.selection.toggle(order);
    this.selectionChange.emit(this.selection);
    this.cdRef.detectChanges();
  }

	ngOnInit() {
	}

  ngAfterViewInit() {
    // this.order.addControl('initials', new FormControl('', Validators.required));

  	// this.cdRef.detectChanges();
	}


	updateImages(images, order) {
    if (order.value._id !== 'NEW') {
      order.removeControl('images');
      order.addControl('images', this.fb.array(images));
      this.cdRef.detectChanges();
      this.orderService.updateImages(order.value._id, images).subscribe((res) => {
      });
    }
  }



	printImage(images) {
		//let html = '<script type="text/javascript">setTimeout(function () { window.print(); }, 500);window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script><html><body style="width:100%;"><div style="width:100%;"><img src="'+image.image+'" style="width:100%;"></div></body></html>';
		let html = '<html><style type="text/css" media="print">@media screen { @page {size: A4 portrait; } html { width:8.5in; height:11in; margin: 0 0 0 0;} body{margin: 0 0 0 0;} img{display: block; max-height:9in;page-break-inside: avoid;}</style><script type="text/javascript">setTimeout(function () { window.print(); }, 500);window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script><body style="width:100%;"><div style="width:100%;text-align:center;">';
		for ( let x = 0; x < images.length; x++) {
      let url = this.imageURL + images[x] + '/thumbnail/0?bearer='+this.auth.getToken();
			html = html + '<img src="' + url + '" style="max-height:9in">';
		}
		html = html + '</div></body></html>';
		const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
		WindowPrt.document.write(html);
		WindowPrt.document.close();
		//WindowPrt.focus();
		//WindowPrt.print();
		//WindowPrt.close();
	}
}
