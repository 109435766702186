import { Renderer2 } from '@angular/core';

export const getStoreClass = (storeName: string) =>
  storeName?.split(' ').join('').toLowerCase();

export const removeStoreClasses = (stores: Array<any>, ren: Renderer2) => {
  stores.forEach((store) => {
    const storeName = getStoreClass(store.storeName);
    ren.removeClass(document.body, storeName);
  });
};
