import {
  LOCALE_ID,
  Inject,
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { VendorOrder } from '@models/vendor-order';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { NotificationService } from '@services/notification.service';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { Observable, of, map, combineLatest } from 'rxjs';
import { OrdersBaseComponent } from '@components/admin/managed-board/base/orders-base/orders-base.component';
import { ImageEditComponent } from '@components/admin/image/image-edit/image-edit.component';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-awaiting-frames-orders',
  templateUrl: './awaiting-frames-orders.component.html',
  styleUrls: ['./awaiting-frames-orders.component.css'],
})
export class AwaitingFramesOrdersComponent
  extends OrdersBaseComponent
  implements OnInit
{
  @Output() refreshOrders = new EventEmitter<boolean>();
  displayedColumns = [
    'select',
    'image',
    'model',
    'color',
    'size',
    'type',
    'cost',
    'retail',
    'patientStock',
    'patient',
    'invoiceNumber',
    'eta',
    'store',
    'action',
    'log',
  ];
  authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];
  multipleSaveInitials: FormControl = new FormControl('', Validators.required);
  multipleSaveAndUpdateInitials: FormControl = new FormControl(
    '',
    Validators.required
  );
  multipleCancelInitials: FormControl = new FormControl(
    '',
    Validators.required
  );

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public auth: AuthService,
    public orderService: OrderService,
    public vendorOrderService: VendorOrderService,
    public productService: ProductService,
    public storeService: StoreService,
    public cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public notificationService: NotificationService
  ) {
    super(
      locale,
      auth,
      orderService,
      vendorOrderService,
      productService,
      storeService,
      cdRef,
      fb,
      route,
      notificationService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.orderForm.addControl(
      'invoiceNumber',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl('date', new FormControl('', Validators.required));
    this.orderForm.addControl('tax', new FormControl('', Validators.required));
    this.orderForm.addControl(
      'shipping',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl(
      'total',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl(
      'initials',
      new FormControl('', Validators.required)
    );

    // this.orderForm.statusChanges.subscribe((val) => {console.log(val);console.log(this.orderForm)})
    // this.orderForm.valueChanges.subscribe((val) => {console.log(val)})

    this.setOptionalOrderFormProperties(
      this.orderForm.get('orders') as FormArray
    );
    this.cdRef.detectChanges();
  }

  setOptionalOrderFormProperties(ordersFormArray: FormArray) {
    for (let x = 0; x < ordersFormArray.length; x++) {
      ordersFormArray.get(`${x}.initials`).clearValidators();
      ordersFormArray.get(`${x}.initials`).updateValueAndValidity();

      ordersFormArray
        .get(`${x}.products.0.product.vendor`)
        .setValidators([Validators.required]);
      ordersFormArray
        .get(`${x}.products.0.product.model`)
        .setValidators([Validators.required]);
      ordersFormArray
        .get(`${x}.products.0.product.color`)
        .setValidators([Validators.required]);
      ordersFormArray
        .get(`${x}.products.0.product.size`)
        .setValidators([Validators.required]);

      ordersFormArray
        .get(`${x}.products.0.cost`)
        .setValidators([Validators.required, Validators.min(0)]);
      ordersFormArray.get(`${x}.products.0.cost`).updateValueAndValidity();
      ordersFormArray
        .get(`${x}.products.0.retail`)
        .setValidators([Validators.required, Validators.min(0)]);
      ordersFormArray.get(`${x}.products.0.retail`).updateValueAndValidity();

      if (ordersFormArray.get(`${x}.products.0.cost`).invalid) {
        ordersFormArray.get(`${x}.products.0.cost`).markAsTouched();
      }
      if (ordersFormArray.get(`${x}.products.0.retail`).invalid) {
        ordersFormArray.get(`${x}.products.0.retail`).markAsTouched();
      }
    }

    this.cdRef.detectChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.calculateTotal();
  }

  toggleSelect(order) {
    this.selection.toggle(order);
    this.calculateTotal();
  }

  saveOrderFrame(order: FormGroup, newOrder: boolean = false) {
    super.saveFrame(order, newOrder);
    this.calculateTotal();
  }

  checkboxLabel(row?: FormGroup, index?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      index + 1
    }`;
  }

  clickOrder($event, order: FormGroup, index) {
    if (!this.fieldOpen) {
      if (this.orderIndex && this.index) {
        order.controls.notify.disable();
      }
      order.controls.notify.enable();
      this.orderId = order.value._id;
      this.index = index;
      this.fieldOpen = true;

      this.cdRef.detectChanges();

      //this.editProduct = product;
      //this.setProductForms(orderIndex);
      // $event.preventDefault();
      // $event.stopPropagation();
      //this.fieldOpen = false;
    }
  }

  saveIncomingOrder(incomingBoolean) {
    // let vendorOrders: VendorOrders = this.dataSource.data[orderIndex];

    this.fieldOpen = true;

    let logItem = new LogElement();
    logItem.initials = this.orderForm.value.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.notes = '';
    logItem.date = Date.now().toString();

    //this.addCurrentActionToLog(order, 'UPDATED');

    let vendorOrder = new VendorOrder();
    vendorOrder.date = this.orderForm.value.date;
    vendorOrder.type = 'order';
    vendorOrder.invoiceNumber = this.orderForm.value.invoiceNumber;
    vendorOrder.tax = parseFloat(this.orderForm.value.tax.replace(/,/g, ''));
    vendorOrder.shipping = parseFloat(
      this.orderForm.value.shipping.replace(/,/g, '')
    );
    //vendorOrder.total = parseFloat(this.orderForm.value.total.replace(/,/g, ''));
    vendorOrder.total = parseFloat(this.orderForm.value.total);
    vendorOrder.vendor = this.vendorOrders.vendor;
    vendorOrder.user = this.auth.currentUser._id;
    vendorOrder.store = this.auth.getStore();
    vendorOrder.log.unshift(logItem);

    let totalTaxableCost = 0;
    let totalCost = 0;
    let totalTax = 0;
    let totalShipping = 0;
    let dirtyOrderCount = 0;
    for (let x = 0; x < this.selection.selected.length; x++) {
      let order = this.selection.selected[x].getRawValue();
      if (this.selection.selected[x].dirty) {
        dirtyOrderCount = dirtyOrderCount + 1;
        this.addDirtyValuesToLog(this.selection.selected[x]);
      } else {
        order.vendorOrder = vendorOrder._id;
        this.orderService
          .updateOrderVendorOrder(order)
          .subscribe((order2) => {});
      }
      totalCost = totalCost + order.products[0].cost;
      if (
        vendorOrder.vendor.taxBothRxAndSun ||
        order.products[0].type == 'RX'
      ) {
        totalTaxableCost = totalTaxableCost + order.products[0].cost;
      }
    }
    this.saveDirtyOrders(dirtyOrderCount).subscribe((saveDirtyOrders) => {
      for (let x = 0; x < this.selection.selected.length; x++) {
        // let order = JSON.parse(JSON.stringify(this.selection.selected[x]));
        let order = this.selection.selected[x].getRawValue();
        let dirty = this.selection.selected[x].dirty;

        order.status = 'RECEIVED';
        logItem.item = order.part ? 'PART RECEIVED' : 'FRAME RECEIVED';
        order.log.unshift(logItem);
        order.products[0].store = order.products[0].store?._id
          ? order.products[0].store._id
          : order.products[0].store;

        if (
          this.vendorOrders.vendor.taxBothRxAndSun ||
          order.products[0].type == 'RX'
        ) {
          order.products[0].tax =
            Math.round(
              (100 * vendorOrder.tax * order.products[0].cost) /
                totalTaxableCost
            ) / 100;
          totalTax = totalTax + order.products[0].tax;
        }

        order.products[0].shipping =
          Math.round(
            (100 * vendorOrder.shipping) / this.selection.selected.length
          ) / 100;
        totalShipping = totalShipping + order.products[0].shipping;

        if (x == this.selection.selected.length - 1) {
          if (totalShipping != vendorOrder.shipping) {
            let shippingDiff =
              Math.round(100 * (vendorOrder.shipping - totalShipping)) / 100;
            order.products[0].shipping =
              order.products[0].shipping + shippingDiff;
          }
          // LAST PRODUCT MIGHT NOT HAVE TAX... WE NEED TO REDO THIS

          if (totalTax != vendorOrder.tax) {
            let taxDiff = Math.round(100 * (vendorOrder.tax - totalTax)) / 100;
            order.products[0].tax = order.products[0].tax + taxDiff;
          }
        }
        order.products[0].product.cost = order.products[0].cost;
        order.products[0].product.retail = order.products[0].retail;
        order.products[0].product.pricingFormula =
          order.products[0].pricingFormula;
        order.products[0].product.type = order.products[0].type;
        order.vendorOrder = vendorOrder._id;
        vendorOrder.products.unshift(order.products[0]);
        vendorOrder.orders.unshift(order._id);
        vendorOrder.status = 'RECEIVED';
        vendorOrder.dateReceived = Date.now().toString();

        if (dirty && saveDirtyOrders) {
          this.orderService.updateOrderFull(order).subscribe((order2) => {
            console.log('updated dirty order');
          });
        } else {
          this.orderService
            .updateOrderTaxAndShipping(order)
            .subscribe((order2) => {
              console.log('updated order tax and shipping');
            });
        }

        if (order.specialOrder) {
          order.products[0].product.specialOrder = true;
        }

        if (order.type != 'F' && !order.part) {
          this.productService
            .incrementProductInventoryQuantity(order.products[0].product, 1)
            .subscribe((incrementProduct) => {
              if (!order.products[0].product._id) {
                order.products[0].product._id = incrementProduct.product._id;
              }

              if (
                order.products[0].product._id != incrementProduct.product._id
              ) {
                order.products[0].product = incrementProduct.product;
                this.orderService
                  .updateOrderFull(order)
                  .subscribe((order2) => {});
                //vendorOrder.products[0].product = incrementProduct.product;
              }

              this.orderService
                .updateOrderStatusAndLog(order)
                .subscribe((order2) => {
                  if (x == this.selection.selected.length - 1) {
                    this.vendorOrderService
                      .addVendorOrder(vendorOrder)
                      .subscribe((vendorOrder) => {
                        this.productService
                          .addVendorOrderToProduct(
                            order.products[0].product._id,
                            vendorOrder._id
                          )
                          .subscribe((productUpdated) => {
                            console.log(productUpdated);
                          });

                        this.refreshOrders.emit(true);
                      });
                  }
                });
            });
        } else {
          this.orderService
            .updateOrderStatusAndLog(order)
            .subscribe((order2) => {
              if (x == this.selection.selected.length - 1) {
                this.vendorOrderService
                  .addVendorOrder(vendorOrder)
                  .subscribe((vendorOrder) => {
                    this.refreshOrders.emit(true);
                  });
              }
            });
        }
      }
    });
  }

  saveDirtyOrders(dirtyOrderCount): Observable<boolean> {
    if (dirtyOrderCount > 0) {
      let dialogRef = this.dialog.open(OkCancelComponent, {
        width: '250px',
        data: { message: 'SAVE MODIFIED ORDERS?' },
      });
      return dialogRef.afterClosed().pipe(takeWhile((result) => !!result));
    } else {
      return of(true);
    }
  }

  imageEdit(image, product, imageIndex) {
    this.cropImage(image);
  }

  cropImage(imageId) {
    let dialogRef = this.dialog.open(ImageEditComponent, {
      width: '600px',
      height: '500px',
      data: { image: imageId, message: '' },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    dialogRef.componentInstance.imageChange.subscribe((img) => {
      //image.date = img.date;
    });
  }

  updateImages(images, product) {
    let imageFormArray: FormArray = product.get('images') as FormArray;
    // if (productElement.value.product._id) {
    //   this.productService.addImage(productElement.value.product._id, imageId[x])
    //     .subscribe(product => {
    //       console.log("product image added " + product._id);
    //     });
    // }
    console.log(images);
    console.log(product);
    console.log(imageFormArray);
    if (imageFormArray.value) {
      // imageFormArray.push(new FormControl(images[0]));
    } else {
      imageFormArray.setValue(images);
    }

    if (product.value._id != 'NEW') {
      product.get('images').setValue(images);
      if (product.value._id) {
        this.productService
          .updateImages(product.value._id, images)
          .subscribe((res) => {});
      }
    }
  }

  saveFrame(order: FormGroup): Observable<any> {
    return super.saveFrame(order).pipe(
      map(() => {
        this.calculateTotal();
        this.cdRef.detectChanges();
        return true;
      })
    );
  }

  multipleSaveChanges() {
    for (let x = 0; x < this.selection.selected.length; x++) {
      this.selection.selected[x]
        .get('initials')
        .setValue(this.multipleSaveInitials.value);
      this.saveFrame(this.selection.selected[x]).subscribe(() => {
        this.notificationService.show(
          'success',
          'SELECTED ORDERS SUCCESSFULLY SAVED'
        );
      });
    }
  }

  multipleSaveAndUpdateInventory() {
    for (let x = 0; x < this.selection.selected.length; x++) {
      this.selection.selected[x]
        .get('initials')
        .setValue(this.multipleSaveAndUpdateInitials.value);
      this.saveAndUpdateInventory(this.selection.selected[x]).subscribe(() => {
        this.notificationService.show(
          'success',
          'SELECTED ORDERS AND INVENTORY SUCCESSFULLY SAVED'
        );
      });
    }
  }

  multipleCancel() {
    this.selection.selected.forEach((order) =>
      order.get('initials').setValue(this.multipleCancelInitials.value)
    );
    const cancelFrames = this.selection.selected.map((order) =>
      this.cancelFrame(order)
    );
    combineLatest(cancelFrames).subscribe(() => {
      this.selection.clear();
      this.notificationService.show(
        'success',
        'SELECTED ORDERS SUCCESSFULLY CANCELED'
      );
      this.refreshOrders.emit(true);
    });
  }
}
