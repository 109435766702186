import { Pipe, LOCALE_ID, Inject, Component, OnInit, Output, EventEmitter, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location, formatDate, formatNumber } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { FormatData } from '@classes/format-data';
import { ProductForm } from '@classes/product-form';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { OrderForm } from '@classes/order-form';
import { Order, LogElement } from '@models/order';
import { AuthService } from '@services/auth.service';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';

@Component({
  selector: 'app-frame-details',
  templateUrl: './frame-details.component.html',
  styleUrls: ['./frame-details.component.css', '../order.component.css']
})
export class FrameDetailsComponent implements OnInit {
  @Input() order: Order = new Order();
  storeOptions: any[];
  orderForm: FormGroup;
  typeOptions: Object[] = [{viewValue: 'RX', value: 'RX'}, {viewValue: 'SUN', value: 'SUN'}, {viewValue: 'OTHER', value: 'OTHER'}];
  selectedProduct: Product = null;

  constructor(@Inject(LOCALE_ID) public locale: string, public auth: AuthService, public orderService: OrderService, public productService: ProductService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public fb: FormBuilder, public route: ActivatedRoute) {
    this.storeOptions = this.storeService.stores;
    this.orderForm = fb.group({});
  }

  ngOnInit(): void {
    if (!this.order.products || !this.order.products[0]) {
      this.order.products[0] = new ProductElement();
      console.log(this.order)
    }
    this.orderForm = OrderForm.initOrderFormGroup(this.order, this.auth, this.storeService.stores);
    console.log(this.orderForm)
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    

  }

  updateProduct(query:ProductQuery) {
    if (query.model.selected && query.color.selected && query.size.selected) {

      let productElements: FormArray = this.orderForm.get('products') as FormArray;
      let productElement: FormGroup = productElements.at(0) as FormGroup;
      let product: FormGroup = productElement.get('product') as FormGroup;
      let vendor: Vendor = product.value.vendor;
      this.productService.queryVendorProductsFields(vendor._id, query)
        .subscribe((res) => {
          delete res[0]?.__v;
          this.selectedProduct = res[0] as Product;
          // product.setValue(selectedProduct);
          let productFormGroup = OrderForm.initProductFormGroup(this.selectedProduct);
          productElement.setControl('product', productFormGroup);


          productElement.get('cost').setValue(this.selectedProduct.cost);
          productElement.get('pricingFormula').setValue(this.selectedProduct.pricingFormula);
          productElement.get('retail').setValue(this.selectedProduct.retail);
          productElement.get('type').setValue(this.selectedProduct.type);
          this.formatCurrency(null, productElement.get('cost'));
          this.formatCurrency(null, productElement.get('retail'));

          if (this.selectedProduct.type == 'RX' && this.orderForm.get('type').value != 'F') {
            this.orderForm.get('type').setValue('O');
          } else if (this.selectedProduct.type == 'SUN' && this.orderForm.get('type').value != 'F') {
            this.orderForm.get('type').setValue('N');
          }
          this.cdRef.detectChanges();
        });
    }
  }

  formatCurrency($event,control) {
    let tmpValue = parseFloat(control.value);
    let value = (isNaN(tmpValue)) ? 0 : tmpValue;
    control.setValue(formatNumber(value, 'en-US', '1.2-2'));
  }

}

