import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  ViewChild,
  OnDestroy,
  ElementRef,
  QueryList,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { VendorOrderService } from '@services/vendor-order.service';
import { ActivatedRoute } from '@angular/router';
import { VendorBaseComponent } from '@components/admin/inventory/vendor-base/vendor-base.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ApiResponse } from '@classes/api-response';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-payable',
  templateUrl: './payable.component.html',
  styleUrls: ['./payable.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PayableComponent implements OnInit, OnDestroy {
  @ViewChild('toggleRow') row: QueryList<ElementRef>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<VendorOrders> =
    new MatTableDataSource<VendorOrders>();
  displayedColumns = ['vendor', 'count', 'action'];
  expandedElement = null;
  expandedIndex: number = null;
  clickedStatus: boolean = false;
  invoiceStatus: string[] = [];
  vendorOrders: VendorOrders[] = [];
  filteredVendorOrders: VendorOrders[] = [];
  vendorNames: string[] = [];
  paidCount: number[] = [];
  unpaidCount: number[] = [];
  vendorId: string = null;
  orderId: string = null;
  defaultInvoiceStatus: string = null;
  selectedVendor: string | undefined;
  selectedStatus = 'unpaid';
  searchString: string = '';
  search: string;
  loading: boolean;
  searching = new Subject<string>();
  searching$ = this.searching.asObservable();
  unsubscribeInput$ = new Subject<void>();
  vendorName: string;

  constructor(
    public vendorOrderService: VendorOrderService,
    public cdRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    private location: Location
  ) {
    this.route.paramMap.subscribe((params) => {
      this.vendorId = params.get('vendorId');
      this.orderId = params.get('orderId');
      this.defaultInvoiceStatus = params.get('invoiceStatus');
    });
  }

  ngOnInit() {
    this.loading = true;
    this.getVendorOrders('all');
    this.searching$
      .pipe(
        takeUntil(this.unsubscribeInput$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((val: string) => (this.search = val));
  }

  ngOnDestroy(): void {
    this.searching.next('');
    this.searching.complete();
    this.unsubscribeInput$.next();
    this.unsubscribeInput$.complete();
  }

  onSearch(search: string) {
    this.searching.next(search);
  }

  onClearSearch() {
    this.searchString = '';
    this.searching.next('');
    this.cdRef.detectChanges();
  }

  getVendorOrders(status: string) {
    this.vendorOrderService
      .getDistributorVendorOrderCounts(status)
      .pipe(
        take(1),
        map((res: ApiResponse<any[]>) => this.setCountsIds(res)),
        tap((vendorOrderCounts: Array<any>) => {
          this.vendorNames = vendorOrderCounts
            .filter((vo: VendorOrders) => !!vo?.vendor?.name)
            .map((vo: VendorOrders) => vo.vendor.name);
          this.vendorOrders = vendorOrderCounts;
          this.filterVendorOrders();
        })
      )
      .subscribe();
  }

  setCountsIds(res: ApiResponse<any[]>) {
    return (
      res.vendorOrderCounts?.map((voc: any) => ({
        ...voc,
        id: voc.vendor?._id ?? new Date().getTime().toPrecision(24),
      })) ?? []
    );
  }

  filterVendorOrders(filterValue?: string) {
    if (filterValue){ this.loading = true;}
    this.filteredVendorOrders = [...this.vendorOrders].filter(
      (vo: VendorOrders) =>
        !filterValue ||
        vo?.vendor?.name?.toLowerCase().includes(filterValue.toLowerCase())
    );
    this.setDataSource(this.filteredVendorOrders);
  }

  setDataSource(vendorOrderCounts: Array<any>) {
    this.dataSource = new MatTableDataSource<any>(vendorOrderCounts);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
    this.loading = false;
  }

  toggleStatus(status?: string) {
    if (!status) {
      this.selectedStatus = 'unpaid';
    } else {
      this.selectedStatus = status;
    }
  }

  stop(event: any) {
    event.stopPropagation?.();
  }

  toggleVendor(vendorId?: any) {
    this.toggleStatus();
    if (!vendorId || vendorId === this.selectedVendor) {
      this.selectedVendor = undefined;
    } else {
      this.selectedVendor = vendorId;
    }
  }

  refreshVendorOrders() {
    this.expandedElement = null;
    this.getVendorOrders('all');
  }

  setInvoiceStatus(status: string, index: number) {
    this.clickedStatus = true;
    this.invoiceStatus[index] = status == 'paid' ? 'paid' : 'all';
    this.cdRef.detectChanges();
  }
}
