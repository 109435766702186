<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%" [formGroup]="orderForm">
	<div fxFlex="calc(100%-150px)" formGroupName="products">


				<div fxLayout="column" fxLayoutAlign="left" fxLayoutGap="0px" style="width:100%" formArrayName="0">

					<div fxFlex style="width:100%">




				    <div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
				      <div fxFlex="40px" style="text-align:center">
				      	<label>POF</label>
				      	<br>
				        <mat-checkbox></mat-checkbox>
				      </div>
				      <div fxFlex="calc(40%-45px)">

							<app-autocomplete-vendors 
						    	placeholder="MANUFACTURER" 
					  			[(productForm)]="orderForm.controls.products['controls'][0]['controls']['product']" 
						    	fxFlex="1 1 100%"></app-autocomplete-vendors>

				      </div>
				      <div fxFlex="calc(60%-45px)">

				      	<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="30px">
				      				<div fxFlex="0 0 10px">
						      			
						      		</div>
						      		<div fxFlex="0 0 80px">
						      			<span matBadge="A" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
						      			<mat-form-field style="width:50%">
								          <input matInput placeholder="" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['frame']['controls']['measurements']['controls']['a']">
								        </mat-form-field>
						      		</div>
						      		<div fxFlex="0 0 80px">
						      			<span matBadge="B" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
						      			<mat-form-field style="width:50%">
								          <input matInput placeholder="" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['frame']['controls']['measurements']['controls']['b']">
								        </mat-form-field>
						      		</div>
						      		<div fxFlex="0 0 80px">
						      			<span matBadge="ED" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
						      			<mat-form-field style="width:50%">
								          <input matInput placeholder="" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['frame']['controls']['measurements']['controls']['ed']">
								        </mat-form-field>
						      		</div>
						      		<div fxFlex="0 0 80px">
						      			<span matBadge="DBL" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
						      			<mat-form-field style="width:50%">
								          <input matInput placeholder="" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['frame']['controls']['measurements']['controls']['dbl']">
								        </mat-form-field>
						      		</div>
						      	</div>



				      	
				      </div>
				    </div>



				  </div>

				  <div fxFlex>

				    <div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px">
				      <div fxFlex="calc(50%-262px)">
				      	<app-autocomplete-vendor-products
					  			[(productForm)]="orderForm.controls.products['controls'][0]['controls']['product']" 
					  			[(vendor)]="orderForm.controls.products['controls'][0]['controls']['product']['value']['vendor']" 
					  			placeholder="MODEL"
					  			(selected)="updateProduct($event)"
					  			displayField="model"
								fxFlex="1 1 100%">
							</app-autocomplete-vendor-products>
						</div>
				      <div fxFlex="calc(50%-263px)">
				      	<app-autocomplete-vendor-products
					  			[(productForm)]="orderForm.controls.products['controls'][0]['controls']['product']" 
					  			[(vendor)]="orderForm.controls.products['controls'][0]['controls']['product']['value']['vendor']" 
					  			placeholder="COLOR"
					  			(selected)="updateProduct($event)"
					  			displayField="color"
								fxFlex="1 1 100%">
							</app-autocomplete-vendor-products>
				      </div>
				      <div fxFlex="0 0 100px">
				      	<app-autocomplete-vendor-products
					  			[(productForm)]="orderForm.controls.products['controls'][0]['controls']['product']" 
					  			[(vendor)]="orderForm.controls.products['controls'][0]['controls']['product']['value']['vendor']" 
					  			placeholder="SIZE"
					  			(selected)="updateProduct($event)"
					  			displayField="size"
								fxFlex="1 1 100%">
							</app-autocomplete-vendor-products>
				      </div>
				      <div fxFlex="0 0 125px">
				      	<mat-form-field fxFlex="1 1 100%">
									<mat-select placeholder="TYPE" [formControl]="orderForm.controls.products['controls'][0]['controls']['product']['controls']['type']">
										<mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">
											{{typeOption.viewValue}}
										</mat-option>
									</mat-select>
				        </mat-form-field>
				      </div>
				      <div fxFlex="0 0 40px">
				        <mat-form-field style="width:100%">
				          <input matInput placeholder="QTY" type="" autocomplete="off" formControlName="quantity">
				        </mat-form-field>
				      </div>
				      <div fxFlex="0 0 75px" style="text-align:center">
				      	<label>SPC ORDER</label>
				      	<br>
				      	<mat-checkbox aria-label="SPC ORDER" [formControl]="orderForm.controls.specialOrder"></mat-checkbox>
				      </div>
				    </div>

				  </div>

				  <div fxFlex fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px">



					<div fxFlex="1 1 100%">
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
							<div>
								<mat-form-field style="width:150px;" appearance="standard">
								  <mat-label>ORDER DATE</mat-label>
								  <input matInput [matDatepicker]="pickerOrderDate" [formControl]="orderForm.controls.date">
								  <mat-datepicker-toggle matSuffix [for]="pickerOrderDate"></mat-datepicker-toggle>
								  <mat-datepicker #pickerOrderDate></mat-datepicker>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field appearance="standard" style="width:150px;top:-2px;">
								  <mat-label>FRAME STATUS</mat-label>
								  <mat-select [formControl]="orderForm.controls.status">
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
							<div>
      					<mat-checkbox [formControl]="orderForm.controls.products['controls'][0]['controls']['frame']['controls']['nosepadInstall']" style="margin-top:24px;padding-left:20px;">NOSEPADS INSTALL</mat-checkbox>
      				</div>
      				<div>
      					<mat-checkbox style="margin-top:24px;padding-left:20px;" [formControl]="orderForm.controls.products['controls'][0]['controls']['frame']['controls']['duty']">DUTY</mat-checkbox>
      				</div>
      				<div>
      					<mat-checkbox style="margin-top:24px;padding-left:20px;" [formControl]="orderForm.controls.products['controls'][0]['controls']['lens']['controls']['drill']">DRILL MOUNT</mat-checkbox>
      				</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
				      <div>
								<mat-form-field style="width:150px;" appearance="standard">
								  <mat-label>DATE RECEIVED</mat-label>
								  <input matInput [matDatepicker]="pickerDateReceived" [formControl]="orderForm.controls.date">
								  <mat-datepicker-toggle matSuffix [for]="pickerDateReceived"></mat-datepicker-toggle>
								  <mat-datepicker #pickerDateReceived></mat-datepicker>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field appearance="standard" style="width:150px;top:-2px;">
								  <mat-label>FRAME STATUS</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
			      	</div>
			      	<div>
      					<mat-checkbox style="margin-top:24px;padding-left:20px;padding-right:10px;"></mat-checkbox>
      						<mat-form-field appearance="standard" style="width:300px">
					         	<input matInput placeholder="PART/OTHER" type="" autocomplete="off">
					        	</mat-form-field>
			      	</div>
			      	<div>
			        	<mat-form-field appearance="standard" style="width:50px;margin-left:20px">
				         <input matInput placeholder="" type="" autocomplete="off">
				         <span matPrefix>$&nbsp;</span>
			        	</mat-form-field>
      				</div>
			    	</div>
			    	<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
				      <div>
								<mat-form-field appearance="standard" style="width:150px;">
								  <mat-label>DATE RETURNED</mat-label>
								  <input matInput [matDatepicker]="pickerDateReturned">
								  <mat-datepicker-toggle matSuffix [for]="pickerDateReturned"></mat-datepicker-toggle>
								  <mat-datepicker #pickerDateReturned></mat-datepicker>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field appearance="standard" style="width:150px;top:-2px;">
								  <mat-label>FRAME STATUS</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
			      	</div>
			    	</div>
					</div>



				  </div>

				  <div fxFlex>

				        <mat-form-field appearance="fill" style="width:100%">
								  <mat-label>FRAME NOTES</mat-label>
								  <textarea matInput rows="6"></textarea>
								</mat-form-field>

				  </div>


				  


				</div>


      </div>
      <div fxFlex="150px">
  			<!-- <mat-card> -->
  				<!-- <mat-card-content> -->
						<app-image *ngIf="selectedProduct" [productId]="selectedProduct._id" type="product"></app-image>
  				<!-- </mat-card-content> -->
  			<!-- </mat-card> -->
  			<mat-card style="margin-top:10px;">
  				<mat-card-content>
		  			<mat-form-field style="width:100%">
		          <input matInput placeholder="TAX" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['tax']">
		          <span matPrefix>$&nbsp;</span>
		        </mat-form-field>
		  			<mat-form-field style="width:100%">
		          <input matInput placeholder="FRAME" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['cost']">
		          <span matPrefix>$&nbsp;</span>
		        </mat-form-field>
		  			<mat-form-field style="width:100%">
		          <input matInput placeholder="CUSTOM" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['tax']">
		          <span matPrefix>$&nbsp;</span>
		        </mat-form-field>
		        <mat-form-field style="width:100%">
		          <input matInput placeholder="SUBTOTAL" type="" autocomplete="off" [formControl]="orderForm.controls.products['controls'][0]['controls']['tax']">
		          <span matPrefix>$&nbsp;</span>
		        </mat-form-field>
		      </mat-card-content>
		    </mat-card>

		    <mat-card style="margin-top:10px;">
				<mat-card-content>

					<mat-form-field style="width:100%">
	          <input matInput placeholder="LAST EDITOR" type="" autocomplete="off" value="11/9/2021" disabled>
	          <span matPrefix>KM&nbsp;</span>
	        </mat-form-field>

	  			<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
			      <input matInput required placeholder="" type="text">
			      <mat-label><mat-icon>gesture</mat-icon></mat-label>
			    </mat-form-field>

			    <button mat-button [matMenuTriggerFor]="menu">
			    	<mat-icon>more_vert</mat-icon>
			    	<span>SAVE</span>
			    </button>
		      <mat-menu #menu="matMenu">
	          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED FRAME</button>
	          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED ON APPROVAL</button>
	          <button mat-menu-item type="button" style="margin-left:10px;">SEND TO STACK</button>
	          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/HOLD</button>
	          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/QUOTE</button>
	          <button mat-menu-item type="button" style="margin-left:10px;">ADD TO CART</button>
	          <button mat-menu-item type="button" style="margin-left:10px;">VOID</button>
	        </mat-menu>

	      </mat-card-content>
	    </mat-card>
   		</div>
    </div>