import { Pipe, LOCALE_ID, Inject, Component, OnInit, Output, EventEmitter, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { StoreService } from '@services/store.service';
import { Order, LogElement } from '@models/order';
import { Vendor, VendorInventory } from '@models/vendor';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-fix-duplicate-products',
  templateUrl: './fix-duplicate-products.component.html',
  styleUrls: ['./fix-duplicate-products.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FixDuplicateProductsComponent implements OnInit {
	dataSource: MatTableDataSource<VendorInventory> = new MatTableDataSource<VendorInventory>();
  displayedColumns = ['vendor', 'count'];
	expandedElement = null;
	duplicatesRoutePath = '';

  constructor(@Inject(LOCALE_ID) public locale: string, public auth: AuthService, public orderService: OrderService, public productService: ProductService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {
  	this.duplicatesRoutePath = this.route.routeConfig.path;
	}

  ngOnInit() {
  	this.getVendorInventory();
  }

  getVendorInventory() {
  	if (this.duplicatesRoutePath == 'fix-duplicate-products-size') {
  		this.getDuplicatesSize();
  	} else {
  		this.getDuplicates();
  	}
	}

	getDuplicates() {
		this.productService.getDuplicateProducts().subscribe((vendorInventory: VendorInventory[]) => {
			this.setDataSource(vendorInventory);
    });
	}

	getDuplicatesSize() {
		this.productService.getDuplicateProductsSize().subscribe((vendorInventory: VendorInventory[]) => {
			this.setDataSource(vendorInventory);
    });
	}

	setDataSource(vendorInventory: VendorInventory[]) {
		this.dataSource = new MatTableDataSource<VendorInventory>(vendorInventory);
    this.cdRef.detectChanges(); 
	}

	toggleExpanded($event, element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

}
