<div [formGroup]="productForm">
	<mat-form-field fxFlex="1 1 100%" floatLabel="never">
	  <input type="text" [placeholder]="placeholder" matInput formControlName="vendor" [matAutocomplete]="auto" (keyup.enter)="chooseFirstOption()" (click)="clickField($event)" (blur)="blurField($event)">
	  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayVendorName">
	    <mat-option *ngFor="let vendor of filteredVendors" (onSelectionChange)="selectVendor($event,vendor)" [value]="vendor">
	      {{ vendor.name }}
	    </mat-option>
	  </mat-autocomplete>
	  <mat-label>{{placeholder}}</mat-label>
	</mat-form-field>
</div>