import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-managed-board',
  templateUrl: './managed-board.component.html',
  styleUrls: ['./managed-board.component.css'],
})
export class ManagedBoardComponent implements OnInit {
  navLinks = [
    { path: 'stack', label: 'THE STACK' },
    { path: 'inventory', label: 'INVENTORY' },
    { path: 'awaiting-frames', label: 'AWAITING FRAMES' },
    { path: 'returns', label: 'RETURNS' },
    { path: 'borrowing', label: 'BORROWING' },
    { path: 'invoice', label: 'INVOICE' },
    { path: 'credit', label: 'CREDIT' },
  ];
  user;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    if (this.user.role === 'RENTAL') {
      this.navLinks = [{ path: 'inventory', label: 'INVENTORY' }];
    }
  }
}
