import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { Image } from '@models/image';
import { ApiResponse } from '@classes/api-response';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private base_url = environment.base_url + '/image';

  constructor(public http: HttpClient, private auth: AuthService) {}

  cropImage(
    imageId: any,
    cropBox: any,
    rotate: any,
    tmp: any
  ): Observable<Image> {
    let body = JSON.stringify({ cropBox: cropBox, rotate: rotate, tmp: tmp });
    return this.http.put(`${this.base_url}/crop/${imageId}`, body).pipe(
      map((res: ApiResponse<Image>) => {
        return res.image;
      })
    );
  }

  checkImageUrl(url: string): Observable<any> {
    return this.http.get(url);
  }

  getImage(imageId: any): Observable<any> {
    return this.http.get(`${this.base_url}s/${imageId}`).pipe(
      tap((res) => console.log(res)),
      map((res: any) => `data:image/jpeg;base64,${res}`)
    );
  }

  getImageUrl(imageId: any): Observable<any> {
    return this.http.get(`${this.base_url}/url/${imageId}`).pipe(
      map((res: ApiResponse<any>) => {
        return res.url;
      })
    );
  }

  getTmpImageUrl(imageId: any): Observable<any> {
    return this.http.get(`${this.base_url}/url/tmp/${imageId}`).pipe(
      map((res: ApiResponse<any>) => {
        return res.url;
      })
    );
  }

  getUploadUrl(imageId: any, type: any): Observable<any> {
    imageId = encodeURIComponent(imageId);
    type = encodeURIComponent(type);
    return this.http
      .get(`${this.base_url}/url/upload/${imageId}/type/${type}`)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.url;
        })
      );
  }

  getTmpUploadUrl(imageId: any, type: any): Observable<any> {
    imageId = encodeURIComponent(imageId);
    type = encodeURIComponent(type);
    return this.http
      .get(`${this.base_url}/url/upload/tmp/${imageId}/type/${type}`)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.url;
        })
      );
  }

  getThumbnailUrl(imageId: any, width: any): Observable<any> {
    imageId = encodeURIComponent(imageId);
    return this.http
      .get(`${this.base_url}/url/thumbnail/${imageId}/width/${width}`)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.url;
        })
      );
  }

  getTmpThumbnailUrl(imageId: any, width: any): Observable<any> {
    imageId = encodeURIComponent(imageId);
    return this.http
      .get(`${this.base_url}/url/thumbnail/tmp/${imageId}/width/${width}`)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.url;
        })
      );
  }

  getBulkThumbnailUrls(imageIds: any[], width: any): Observable<any> {
    let body = JSON.stringify(imageIds);
    return this.http
      .post(`${this.base_url}/url/thumbnail/width/${width}`, body)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.urls;
        })
      );
  }

  deleteImage(imageId: any): Observable<any> {
    imageId = encodeURIComponent(imageId);
    return this.http.delete(`${this.base_url}/${imageId}`).pipe(
      map((res: ApiResponse<any>) => {
        return res;
      })
    );
  }

  refreshUrl(imageId: any, width: any): Observable<any> {
    imageId = encodeURIComponent(imageId);
    return this.http
      .get(`${this.base_url}/url/refresh/${imageId}/width/${width}`)
      .pipe(
        map((res: ApiResponse<any>) => {
          return res.url;
        })
      );
  }

  getThumbnailWidth(type: any) {
    switch (type) {
      case 'order':
        return 240;
        break;
      case 'prescription':
        return 75;
        break;
      case 'product':
        return 150;
        break;
      case 'vendor':
        return 800;
        break;
      case 'gallery':
        return 800;
        break;
      default:
        return 300;
    }
  }
}
