import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PrescriptionService } from '@services/prescription.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import * as ClassicEditorBuild2 from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})
export class PrescriptionComponent implements OnInit {

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private prescriptionService: PrescriptionService,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer
  ) { }
  @Input() public prescription: FormGroup;
  @Input() public image: any;
  @Input() public withNotes: boolean;
  @Input() public withDelete: boolean;
  @Input() public withImage: boolean = true;
  @Input() public index: Number;
  @Output() clickDelete = new EventEmitter<any>();
  @Output() clickSetAsOrder = new EventEmitter<any>();
  @Input() selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<FormGroup>>();
  public ClassicEditorBuild = ClassicEditorBuild;
  public ClassicEditorBuild2 = ClassicEditorBuild2;
  doctorNotes: FormControl;
  pdfUrl: SafeUrl;
  imageData: SafeUrl;
  viewableImg: string;

  rxTypes = [
    /*{value: 'BIFOCAL', viewValue: 'BIFOCAL'},
    {value: 'COMPUTER', viewValue: 'COMPUTER'},
    {value: 'DISTANCE', viewValue: 'DISTANCE'},
    {value: 'OCCUPATIONAL', viewValue: 'OCCUPATIONAL'},
    {value: 'PROGRESSIVE', viewValue: 'PROGRESSIVE'},
    {value: 'READING', viewValue: 'READING'},
    {value: 'TRI-FOCAL', viewValue: 'TRI-FOCAL'},*/
    {value: 'RX', viewValue: 'RX'},
    {value: 'OTHER', viewValue: 'OTHER'}
  ];

  async ngOnInit() {
    const url = `data:text/html,${encodeURIComponent(this.prescription.value.html)}`;
    const blob = await fetch(url).then(res => res.blob());
    this.imageData = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  ngAfterViewInit() {
  }

  deletePrescription(): void {
    const prescriptionId = this.prescription.value._id;
    // var index = inputObj.index;
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE PRESCRIPTION?' }
    });
    dialogRef.afterClosed().pipe(takeWhile(result => !!result)).subscribe(() => {
      this.prescriptionService.deletePrescription(prescriptionId)
        .subscribe(prescription => {
          // var prescriptionFormArray: FormArray;
          // prescriptionFormArray = this.patientForm.controls.prescriptionForms as FormArray;
          // prescriptionFormArray.removeAt(index);
          this.notificationService.show('success', 'PRESCRIPTION DELETED', 'OK');
          this.clickDelete.next(this.index);
        });
    });
  }

  setPrescriptionAsOrder(): void {
    const prescriptionId = this.prescription.value._id;
    const dialogRef = this.dialog.open(OkCancelComponent, {
      width: '250px',
      data: { message: 'SET PRESCRIPTION AS ORDER?' }
    });
    dialogRef.afterClosed().pipe(takeWhile(result => !!result)).subscribe(() => {
      this.prescriptionService.setPrescriptionAsOrder(prescriptionId)
        .subscribe(order => {
          // var prescriptionFormArray: FormArray;
          // prescriptionFormArray = this.patientForm.controls.prescriptionForms as FormArray;
          // prescriptionFormArray.removeAt(index);
          // let newOrderFormGroup = OrderForm.initOrderFormGroup(order, this.auth, this.storeOptions);
          // let patientOrdersFormArray: FormArray = this.patientForm.get('orders') as FormArray;
          // patientOrdersFormArray.controls.unshift(newOrderFormGroup);
          this.notificationService.show('success', 'PRESCRIPTION CHANGED TO ORDER', 'OK');
          this.clickSetAsOrder.next(this.index);
        });
    });
  }

  setPrescriptionAsOrder2(event) {
    this.clickSetAsOrder.next({_id: this.prescription.value._id, index: this.index});
  }

  blurDate2(prescriptionForm) {
    prescriptionForm.setValue(prescriptionForm.value);
  }

  printImage(image) {
    const html = '<script type="text/javascript">setTimeout(function () { window.print(); }, 500);window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script><html><body style="width:100%;"><div style="width:100%;"><img src="' + image.image + '" style="width:100%;"></div></body></html>';
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(html);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    //WindowPrt.close();
  }

  toggleSelect(prescription) {
    this.selection.toggle(prescription);
    this.selectionChange.emit(this.selection);
    this.cdRef.detectChanges();
  }

  savePrescription(prescription: FormGroup) {
    this.prescriptionService.updatePrescription(prescription.value._id, prescription.getRawValue()).subscribe(res => {
      this.notificationService.show('success', 'PRESCRIPTION SAVED', 'OK');
    });
  }

  generatePdf(prescription) {
    this.prescriptionService.generatePdf(prescription.value._id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/pdf' });

      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') > -1) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      } else {
        const html = `
          <embed src="${window.URL.createObjectURL(blob)}" style="position:absolute;left:0;top:0;" width="100%" height="100%" type="application/pdf">
          <script type="text/javascript">
          setTimeout(function() { window.print(); }, 500);
          </script>
        `;
        const WindowPrt = window.open('', '', `left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0`);
        WindowPrt.document.write(html);
      }
    });
  }

  loadPdf(event) {
    const el = event.target as HTMLIFrameElement;
    el.contentWindow.print();
  }

  async onImgLoaded(event) {
    const canvasEl = await html2canvas((event.target as HTMLIFrameElement).contentWindow.document.body, {logging:false});
    this.viewableImg = '';
    setTimeout(() => {
      this.viewableImg = canvasEl.toDataURL();
    });
  }

}
