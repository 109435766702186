<div class="container" style="width:100%" [formGroup]="orderForm" *ngIf="dataSource.data">
	<mat-table [dataSource]="dataSource3" class="mat-elevation-z2" fxFlex>
		<ng-container matColumnDef="raNumber">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> RA # </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
		      <input matInput required placeholder="RA #" formControlName="raNumber" style="text-align:center;" (click)="clickField()">
		      <mat-error *ngIf="order.controls.raNumber.hasError('required')"></mat-error>
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="date">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> DATE SHIPPED </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
				  <input #formRow required [matDatepicker]="picker" matInput placeholder="DATE SHIPPED" type="text" style="text-align:center;" formControlName="date" (click)="clickField()">
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	      	<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="shippingMethod">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 25%"> SHIPPING METHOD </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
				  <mat-select placeholder="SHIPPING METHOD" formControlName="shippingMethod" (click)="clickField()">
						<mat-option value="USPS">USPS</mat-option>
						<mat-option value="UPS">UPS</mat-option>
						<mat-option value="FEDEX">FEDEX</mat-option>
						<mat-option value="SALES REP">SALES REP</mat-option>
					</mat-select>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="trackingNumber">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> TRACKING # </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
		      <input matInput required placeholder="TRACKING #" formControlName="trackingNumber" style="text-align:center;" (click)="clickField()">
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="proposedCredit">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> PROPOSED CREDIT </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<!-- <span>{{ order.value.total | currency }}</span> -->
	    	<mat-form-field floatLabel="never">
		      <input matInput required placeholder="PROPOSED CREDIT" formControlName="proposedCredit" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.proposedCredit)" (keyup)="calculateTotal()">
		      <span matPrefix>$&nbsp;</span>
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 150px">  </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 150px" style="border-right:0px !important;">
	    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text" formControlName="initials" (click)="clickField()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
	      <button 
	      	mat-button 
	      	type="button" 
	      	(click)="saveFrames()" 
	      	[disabled]="
	      	order.controls.raNumber.invalid ||
	      	order.controls.date.invalid ||
	      	order.controls.shippingMethod.invalid ||
	      	order.controls.trackingNumber.invalid ||
	      	order.controls.proposedCredit.invalid ||
	      	order.controls.initials.invalid
	      	">
	      	<!-- selectionInvalid || -->

	      	<mat-icon>save</mat-icon><span>SAVE</span>
	      </button>
	    </mat-cell>
	  </ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="none center"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="none center" class="action-row"></mat-row>
	</mat-table>
</div>