import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageService } from '@services/image.service';
import { catchError, concat, EMPTY, take, tap } from 'rxjs';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductImageComponent {
  @ViewChild('image') imageEl: TemplateRef<any>;
  @Input() set image(imageId: string) {
    this.imageId = imageId;
    this.getImageUrl(imageId);
  }
  @Input() set src(imageUrl: string) {
    this.imageUrl = imageUrl;
  }
  @Input() uploads: boolean;
  @Input() alt: string;
  @Input() altHeader: string;

  imageId: string;
  imageUrl: string;
  showAlt: boolean;

  constructor(
    public readonly iS: ImageService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {}

  getImageUrl(imageId: string) {
    this.iS
      .getImageUrl(imageId)
      .pipe(
        take(1),
        catchError((err) => {
          console.log(err);
          this.showAlt = true;
          this.cd.detectChanges();
          return EMPTY;
        })
      )
      .subscribe((url) => {
        this.imageUrl = url ?? undefined;
        this.cd.detectChanges();
      });
  }

  expandImage() {
    // if (!this.uploads) {
    //   return;
    // }
    this.dialog.open(this.imageEl, {
      width: '600px',
      height: '300px,',
    });
  }
}
