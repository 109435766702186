import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { environment } from '@environments/environment';
import { AlertService } from '@services/alert.service';
import { sortTable } from '@v2/shared/utils';
import {
  EMPTY,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  filter,
  fromEvent,
  reduce,
  takeUntil,
  tap,
} from 'rxjs';
import * as XLSX from 'xlsx/xlsx.mjs';

@Component({
  selector: 'app-shopify-export',
  templateUrl: './shopify-export.component.html',
  styleUrls: ['./shopify-export.component.scss'],
})
export class ShopifyExportComponent implements OnInit, AfterViewInit {
  @ViewChild('download') downloadButton: MatButton;
  @ViewChild('generate') generateButton: MatButton;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  protected readonly csvKeys = {
    handle: 'Handle',
    title: 'Title',
    bodyHtml: 'Body(HTML)',
    vendor: 'Vendor',
    product: 'Product',
    category: 'Category',
    type: 'Type',
    tags: 'Tags',
    published: 'Published',
    option1Name: 'Option1 Name',
    option1Value: 'Option1 Value',
    option2Name: 'Option2 Name',
    option2Value: 'Option2 Value',
    option3Name: 'Option3 Name',
    option3Value: 'Option3 Value',
    variantSku: 'Variant SKU',
    variantGrams: 'Variant Grams',
    variantInventoryTracker: 'Variant Inventory Tracker',
    variantInventoryQty: 'Variant Inventory Qty',
    variantInventoryPolicy: 'Variant Inventory Policy',
    variantFulfillmentService: 'Variant Fulfillment Service',
    variantPrice: 'Variant Price',
    variantCompareAtPrice: 'Variant Compare At Price',
    variantRequiresShipping: 'Variant Requires Shipping',
    variantTaxable: 'Variant Taxable',
    variantBarcode: 'Variant Barcode',
    imageSrc: 'Image Src',
    imagePosition: 'Image Position',
    imageAltText: 'Image Alt Text',
    giftCard: 'Gift Card',
    seoTitle: 'SEO Title',
    seoDescription: 'SEO Description',
    googleShoppingGoogleProductCategory:
      'Google Shopping / Google Product Category',
    googleShoppingGender: 'Google Shopping / Gender',
    googleShoppingAgeGroup: 'Google Shopping / Age Group',
    googleShoppingMpn: 'Google Shopping / MPN',
    googleShoppingAdwordsGrouping: 'Google Shopping / AdWords Grouping',
    googleShoppingAdwordsLabels: 'Google Shopping / AdWords Labels',
    googleShoppingCondition: 'Google Shopping / Condition',
    googleShoppingCustomProduct: 'Google Shopping / Custom Product',
    googleShoppingCustomLabel0: 'Google Shopping / Custom Label 0',
    googleShoppingCustomLabel1: 'Google Shopping / Custom Label 1',
    googleShoppingCustomLabel2: 'Google Shopping / Custom Label 2',
    googleShoppingCustomLabel3: 'Google Shopping / Custom Label 3',
    googleShoppingCustomLabel4: 'Google Shopping / Custom Label 4',
    variantImage: 'Variant Image',
    variantWeightUnit: 'Variant Weight Unit',
    variantTaxCode: 'Variant Tax Code',
    costPerItem: 'Cost per item',
    priceInternational: 'Price / International',
    compareAtPriceInternational: 'Compare At Price / International',
    status: 'Status',
  };

  protected readonly tableKeys = {
    productStatus: 'Internal Status',
    ...this.csvKeys,
  };

  protected excludeVariantKeys = [
    'title',
    'option1Name',
    'option2Name',
    'option3Name',
    'vendor',
    'bodyHtml',
    'category',
    'type',
    'published',
  ];

  protected inventoryKeys = [
    'Handle',
    'Title',
    'Option1 Name',
    'Option1 Value',
    'Option2 Name',
    'Option2 Value',
    'Option3 Name',
    'Option3 Value',
    'SKU',
    'HS Code',
    'COO',
    'Location',
    'Incoming',
    'Unavailable',
    'Committed',
    'Available',
    'On hand',
  ];
  protectedInventoryCsvKeys = this.inventoryKeys.reduce(
    (acc, key) => ({ ...acc, [this.getCamelCase(key)]: key }),
    {}
  );

  multiFilter: Record<string, string | number | boolean | null | undefined> =
    Object.keys(this.tableKeys).reduce(
      (acc, key) => ({ ...acc, [key]: null }),
      {}
    );
  tableFilters: Record<
    string,
    Array<string | number | boolean | null | undefined>
  >;
  filters: Array<Record<string, string | number | boolean | null | undefined>>;
  products: any[] = [];
  filteredProducts: any[] = [];
  pageSizes: Array<number>;
  defaultPageSizes: Array<number> = [25, 50, 100, 200, 500, 1000];
  unsubscribe$ = new Subject<void>();
  searchControl: FormControl;
  discountControl: FormControl;
  showAll: boolean;
  allSelected: boolean;
  defaultSort: Sort = { active: 'handle', direction: 'asc' };
  displayedColumns: string[] = ['selected', ...Object.keys(this.tableKeys)];
  sort: Sort;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private readonly http: HttpClient,
    private readonly alS: AlertService,
    protected readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.sort = this.defaultSort;
    this.searchControl = new FormControl(null);
    this.searchControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((searchText: string) => this.searchProducts(searchText));
    this.discountControl = new FormControl(null, [
      Validators.min(1),
      Validators.max(99),
      Validators.pattern(/^\d*$/),
    ]);
    this.discountControl.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.unsubscribe$),
        filter((v) => this.discountControl.valid)
      )
      .subscribe((searchText: string) => this.discountBinary(+searchText));
  }

  ngAfterViewInit() {
    fromEvent(this.downloadButton._elementRef.nativeElement, 'click')
      .pipe(
        tap(() => {
          this.alS.loading.next({
            isLoading: true,
            loadingMessage: 'Preparing CSV file...',
          });
        }),
        debounceTime(5000)
      )
      .subscribe(() => {
        this.downLoadSheet();
      });

    fromEvent(this.generateButton._elementRef.nativeElement, 'click')
      .pipe(
        tap(() => {
          this.alS.loading.next({
            isLoading: true,
            loadingMessage: 'Generating data and images...',
          });
        }),
        debounceTime(5000)
      )
      .subscribe(() => {
        this.generateCsvData();
      });
  }

  cleanHandle(value: string) {
    return value
      ?.split('')
      .filter((char: string) => !!char?.trim())
      .map(
        (char: string) =>
          (char.match(/[a-zA-Z0-9 -]/) && char.toLowerCase()) ?? ''
      )
      .join('');
  }

  generateCsvData() {
    this.http
      .get(`${environment.base_url}/products/shopify`)
      .pipe(
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe((res: Array<any>) => {
        this.products = res.map((product: any) => {
          const {
            handle,
            image,
            model,
            type,
            tags,
            option2Value = 'OneSize',
            option3Value,
          } = product;
          return {
            ...product,
            handle: this.cleanHandle(handle),
            imageSrc: `${environment.base_url}${product.imageSrc}`,
            location: option3Value
              ?.toLowerCase()
              .split(' ')
              .map(
                (word: string) =>
                  word.substring(0, 1).toUpperCase() + word.substring(1)
              )
              .join(' '),
            option2Value:
              !option2Value?.trim()?.length ||
              option2Value?.trim().toLowerCase() === 'x'
                ? 'One Size'
                : option2Value,
            variantTaxable: !['rx', 'frames'].includes(type.toLowerCase()),
            tags: tags
              ?.toLowerCase()
              .split(' ')
              .map(
                (word: string) =>
                  word.substring(0, 1).toUpperCase() + word.substring(1)
              )
              .join(' '),
            selected: true,
            option3Value: option3Value
              ?.toLowerCase()
              .split(' ')
              .map(
                (word: string) =>
                  word.substring(0, 1).toUpperCase() + word.substring(1)
              )
              .join(' '),
          };
        });
        this.allSelected = true;
        this.filteredProducts = this.products;
        this.setFilters();
        this.setDataSource();
      });
  }

  getCamelCase(value: string, isItem: boolean = false) {
    if (!value?.trim()) {
      return '';
    }
    const words = value
      .replaceAll('#', 'Number')
      .replaceAll('\r', ' ')
      .replaceAll('\n', ' ')
      .split('')
      .map((char: string) => (char.match(/[a-zA-Z0-9 -]/) && char) ?? '')
      .join('')
      .split(' ');
    return words
      .map((word: string) => word.trim()?.toLowerCase())
      .map((word: string, idx: number) =>
        !idx
          ? word.substring(0, 1).toLowerCase() + word.substring(1).toLowerCase()
          : word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
      )
      .join('');
  }

  getSheetOne(): Array<Array<any>> {
    const keys = Object.keys(this.csvKeys);
    const selectedFilteredProducts = this.filterBinary(
      this.filteredProducts,
      true,
      'selected'
    );

    /* Shopify variants not working correctly its a manual process */
    const variantData: Record<
      string,
      Array<any>
    > = selectedFilteredProducts.reduce((acc, product) => {
      if (!acc[product.handle]) {
        return {
          ...acc,
          [product.handle]: [product],
        };
      } else {
        return {
          ...acc,
          [product.handle]: [...acc[product.handle], product],
        };
      }
    }, {});
    return Object.values(variantData).reduce(
      (acc: Array<any>, variants: Array<any>) => {
        const variantsRows = variants.reduce((variantsAcc, variant, idx) => {
          /* Determine which keys should be populated */
          const reduceKeys = !idx
            ? keys
            : keys.filter((k) => !this.excludeVariantKeys.includes(k));
          const row = keys.reduce((variantsKeysAcc, key) => {
            /* Set empty value for field and we will use the values for the first variant */
            if (key === 'variantImage') {
              return [...variantsKeysAcc, variant.imageSrc];
            } else if (!reduceKeys.includes(key)) {
              return [...variantsKeysAcc, ''];
            }
            /* Set value for option field, img or handle */
            return [...variantsKeysAcc, variant[key] ?? ''];
          }, []);
          return [...variantsAcc, row];
        }, []);
        return [...acc, ...variantsRows];
      },
      [Object.values(this.csvKeys)]
    );

    // return selectedFilteredProducts.reduce(
    //   (acc: Array<any>, product: any) => {
    //     const row = keys.reduce(
    //       (keysAcc, key) => [...keysAcc, product[key] ?? ''],
    //       []
    //     );
    //     return [...acc, row];
    //   },
    //   [Object.values(this.csvKeys)]
    // );
  }

  downLoadSheet(): void {
    const data = this.getSheetOne();
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Instructors');
    this.alS.loading.next({
      isLoading: false,
    });
    /* save to file */
    XLSX.writeFile(wb, `Autooptiq-Shopify-${new Date().getTime()}.csv`, {
      bookType: 'csv',
    });
  }

  filterProducts(key: string, value: string | number) {
    this.filteredProducts = this.filterBinary(
      this.filteredProducts,
      value,
      key
    );
    this.setDataSource();
  }

  multiFilterProducts(
    key: string,
    filters: Record<string, string | number | boolean>
  ) {
    this.filteredProducts = this.filteredProducts.filter((product) => {
      /* Check all the filters in the product */
      return Object.entries(filters).reduce(
        (acc: boolean, [filterKey, filterValue]) => {
          const mid = Math.ceil(Object.keys(product).length / 2);
          for (let i = 0; i < mid; i++) {
            const preCheck = this.findValue(product, filterValue, filterKey);
            const postCheck = this.findValue(product, filterValue, filterKey);
            return postCheck || preCheck;
          }
          return acc;
        },
        false
      );
    });
    this.setDataSource();
  }

  findValue(product: any, value: string | number | boolean, key?: string) {
    if (!product) {
      return false;
    }
    if (key) {
      return (
        product?.[key] === value ||
        product?.[key]
          ?.toString()
          ?.toLowerCase()
          .includes(value.toString().toLowerCase())
      );
    }
    return !!this.findValueByKeys(product, value);
  }

  findValueByKeys(product: any, value: string | number | boolean) {
    const keys = Object.keys(this.csvKeys);
    const mid = Math.ceil(keys.length / 2);
    for (let i = 0; i < mid; i++) {
      if (this.findValue(product, value, keys[i])) {
        return product[keys[i]];
      } else if (this.findValue(product, value, keys[i + mid])) {
        return product[keys[i + mid]];
      }
    }
    return undefined;
  }

  discountBinary(discount: number = 0) {
    console.log(discount);
    if (!this.filteredProducts.length) {
      return;
    }
    const data = this.filterBinary(this.filteredProducts, true, 'selected');
    const mid = Math.ceil(data.length / 2);
    const multiplier = (100 - discount) / 100;
    for (let i = 0; i < data.length; i += 2) {
      if (data[i]) {
        const { variantCompareAtPrice } = data[i];
        data[i].variantPrice = (+variantCompareAtPrice * multiplier).toFixed(2);
      }
      if (data[i + mid]) {
        const { variantCompareAtPrice } = data[i + mid];
        data[i + 1].variantPrice = (
          +variantCompareAtPrice * multiplier
        ).toFixed(2);
      }
    }
  }

  filterBinary(
    data: Array<any>,
    value: string | number | boolean,
    key?: string
  ) {
    if (data.length < 5) {
      return data.filter((product) => this.findValue(product, value, key));
    }

    const results = [];
    const mid = Math.ceil(data.length / 2);
    for (let i = 0; i < data.length; i += 2) {
      if (this.findValue(data[i], value, key)) {
        results.push(data[i]);
      }
      if (this.findValue(data[i + 1], value, key)) {
        results.push(data[i + 1]);
      }
    }
    return results;
  }

  setAll(key: string, value: boolean) {
    this.setBinary(this.filteredProducts, value, key);
  }

  onAllSelection() {
    this.allSelected = !this.allSelected;
    this.setAll('selected', this.allSelected);
    this.setDataSource();
  }

  setBinary(data: Array<any>, value: string | number | boolean, key: string) {
    const mid = Math.ceil(data.length / 2);
    for (let i = 0; i < mid; i++) {
      data[i][key] = value;
      if (data[i + mid]) {
        data[i + mid][key] = value;
      }
    }
  }

  searchProducts(value: string | number) {
    this.showAll = true;
    this.filteredProducts = !value
      ? this.products
      : this.filterBinary(this.products, value);
    this.setDataSource();
  }

  setDataSource(products: Array<any> = this.filteredProducts) {
    if (this.table && products) {
      this.dataSource = new MatTableDataSource(products);
      this.dataSource.paginator = this.paginator;
      this.cdRef.detectChanges();
      this.alS.loading.next({
        isLoading: false,
      });
    }
  }

  selectProduct(product: any) {
    product.selected = !product.selected;
    this.cdRef.detectChanges();
  }

  sortData(sort?: Sort) {
    this.sort = sort ?? this.defaultSort;
    this.filteredProducts = sortTable(this.sort, this.filteredProducts);
    this.setDataSource();
  }

  onFilterChange(key: string, value: string | number | boolean) {
    this.multiFilter[key] = value;
    this.multiFilterProducts(key, this.multiFilter);
  }

  onFilterRemove(key: string) {
    const { [key]: value, ...rest } = this.multiFilter;
    this.multiFilter = rest;
    this.setFilters();
    this.multiFilterProducts(key, this.multiFilter);
  }

  setFilters() {
    const keys = Object.keys(this.tableKeys);
    const keyChoices: Record<
      string,
      Array<string | number | boolean | null | undefined>
    > = Object.keys(this.tableKeys).reduce(
      (acc, key) => ({ ...acc, [key]: [] }),
      {}
    );
    this.products.forEach((product) => {
      const mid = Math.ceil(keys.length / 2);
      const nextChoices = [];
      for (let i = 0; i < mid; i++) {
        keyChoices[keys[i]] = [...keyChoices[keys[i]], product[keys[i]]];
        if (product[keys[i + mid]]) {
          keyChoices[keys[i + mid]] = [
            ...keyChoices[keys[i + mid]],
            product[keys[i + mid]],
          ];
        }
      }
    }, {});
    this.tableFilters = keyChoices;
    /* TODO: change the unique task to the previous block */
    this.filters = Object.entries(keyChoices).reduce(
      (acc, [key, value]) => [
        ...acc,
        { key, value: Array.from(new Set(value)) },
      ],
      []
    );
  }
}
