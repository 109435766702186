import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleService } from './common/header/toggle.service';
import { CustomizerSettingsService } from './shared/customizer-settings/customizer-settings.service';
import { PayableSelectionService } from './shared/services/payable-selection.service';

@Component({
  selector: 'app-v2-home',
  templateUrl: './v2-home.component.html',
  styleUrls: ['./v2-home.component.scss'],
})
export class V2HomeComponent {
  title = 'Redesign of Autooptiq';

  isToggled = false;

  constructor(
    public router: Router,
    private readonly toggleService: ToggleService,
    private readonly psS: PayableSelectionService,
    public themeService: CustomizerSettingsService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  toggleRightSidebarTheme() {
    this.themeService.toggleRightSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}
