<ng-container *ngIf="!showAlt; else altBlock">
  <img *ngIf="imageUrl" [src]="imageUrl" alt="" />
  <ng-template #image
    ><img [src]="imageUrl" alt=""
  /></ng-template> </ng-container
>
<ng-template #altBlock>
  <h2 *ngIf="altHeader; else altText">{{ altHeader }}</h2>
  <ng-container #altText><span class="img-alt">{{ alt ?? '' }}</span></ng-container>
</ng-template>
