<div *ngIf="loading">
  <h3 style="text-align: center">LOADING PAYABLE</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="container" style="width: 100%">
  <mat-table
    #orderTable
    matSort
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z2"
  >
    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px">
        VENDOR
      </mat-header-cell>
      <mat-cell
        *matCellDef="let vendorCount; let orderIndex = dataIndex"
        fxFlex="1 1 200px"
      >
        <span style="font-weight: bold" *ngIf="vendorCount?.vendor">
          {{ vendorCount.vendor.name }}
        </span>
        <span
          style="font-weight: bold"
          *ngIf="!vendorCount?.vendor && vendorCount?.borrowingStore"
        >
          {{ vendorCount?.store.storeName }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px">
        QUANTITY
      </mat-header-cell>
      <mat-cell
        *matCellDef="let vendorCount; let orderIndex = dataIndex"
        fxFlex="1 1 200px"
      >
        <span *ngIf="vendorCount.unpaid != 1">
          {{ vendorCount.unpaid }} ORDERS</span
        >
        <span *ngIf="vendorCount.unpaid == 1">
          {{ vendorCount.unpaid }} ORDER</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 100%">
        <mat-form-field class="mx-2" floatLabel="never">
          <mat-label>Search Vendors</mat-label>
          <input matInput type="text" [(ngModel)]="vendorName" (ngModelChange)="filterVendorOrders(vendorName)" />
        </mat-form-field>

        <mat-form-field class="mx-2" floatLabel="never">
          <mat-label>Select a Vendor</mat-label>
          <mat-select [(ngModel)]="vendorName" (ngModelChange)="filterVendorOrders(vendorName)">
            <mat-option [value]="''">All Vendors</mat-option>
            <mat-option *ngFor="let name of vendorNames" [value]="name">
              {{name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </mat-header-cell>
      <mat-cell
        *matCellDef="let vendorCount; let orderIndex = dataIndex"
        fxFlex="1 1 100%"
        style="
        padding-left: 16px;
          align-items: center;
          justify-content: flex-start !important;
        "
      >
        <span
          *ngIf="selectedVendor === vendorCount"
          (click)="stop($event)"
        >
          <mat-radio-group matInput>
            <mat-radio-button
              value="unpaid"
              [checked]="selectedStatus === 'unpaid'"
              (click)="toggleStatus('unpaid')"
              >UNPAID</mat-radio-button
            >&nbsp;&nbsp;
            <mat-radio-button
              value="paid"
              [checked]="selectedStatus === 'paid'"
              (click)="toggleStatus('paid')"
              >PAID</mat-radio-button
            >
          </mat-radio-group>
          <mat-form-field floatLabel="never">
            <input
              #searchInput
              matInput
              placeholder="SEARCH"
              type="text"
              [(ngModel)]="searchString"
              (ngModelChange)="onSearch($event)"
            />
            <span matSuffix
              ><mat-icon (click)="onClearSearch()">close</mat-icon></span
            >
          </mat-form-field>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let vendorCount; let orderIndex = dataIndex">
        <div
          class="example-element-detail"
          style="width: 100%"
          [class.mat-elevation-z0]="vendorCount.id !== selectedVendor"
          [class.mat-elevation-z8]="vendorCount.id === selectedVendor"
        >
          <app-payable-orders
            *ngIf="selectedVendor === vendorCount"
            [vendor]="vendorCount.vendor"
            [borrowingFromStore]="vendorCount.borrowingFromStore"
            (refreshVendorOrders)="getVendorOrders('all')"
            [status]="selectedStatus"
            [search]="search"
            style="width: 100%"
          ></app-payable-orders>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
    </mat-header-row>
    <mat-row
      *matRowDef="
        let row;
        columns: displayedColumns;
        let i = dataIndex;
        let even = even;
        let odd = odd
      "
      fxLayout="row"
      fxLayoutAlign="start center"
      [ngClass]="{ rowEven: i % 2, rowOdd: (i + 1) % 2 }"
      style="border-left: 1px solid #dfdfdf !important"
      (click)="toggleVendor(row)"
    >
    </mat-row>
    <mat-row
      *matRowDef="let element; columns: ['expandedDetail']"
      [class.example-detail-row-expanded]="selectedVendor === element"
      [class.example-detail-row-collapsed]="selectedVendor !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center"
    >
    </mat-row>
  </mat-table>
</div>
