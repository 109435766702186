import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ReportService } from '@services/report.service';

@Component({
  selector: 'app-tax-report',
  templateUrl: './tax-report.component.html',
  styleUrls: ['./tax-report.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TaxReportComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	selectedValue: string;
	report: any;
	loading: boolean = false;
  displayedColumns = ['vendor', 'count'];
  expandedElement = null;

  dateOptions: any = [
  	{ value: 'currentMonth', viewValue: 'CURRENT MONTH'},
  	{ value: 'previousMonth', viewValue: 'PREVIOUS MONTH'},
  	{ value: 'yearToDate', viewValue: 'YEAR TO DATE'},
  	{ value: 'previousYear', viewValue: 'PREVIOUS YEAR'},
  	{ value: 'customDateRange', viewValue: 'CUSTOM DATE RANGE'}
  ];
  selectedDateOption: any;
  startDate: any;
  endDate: any;

  constructor(public reportService: ReportService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit(): void {
  	// this.getTaxReport();
  }

  getTaxReport() {
    this.loading = true;
    this.reportService.getTaxReport(this.startDate, this.endDate).subscribe((res: any[]) => {
      this.report = res;
console.log(res)
      this.dataSource = new MatTableDataSource<any>(this.report.data);
      this.loading = false;
      this.cdRef.detectChanges();
    });
  }

  selectDateOption($event:any): void {
		this.selectedDateOption = $event.value;
  	let date = new Date();
  	let day = date.getDate();
  	let month = date.getMonth();
    let year = date.getFullYear();

		switch(this.selectedDateOption) {
			case 'currentMonth' :
				this.startDate = new Date(year, month, 1);
				this.endDate = new Date(year, month, day);
				break;
			case 'previousMonth' :
				this.startDate = new Date(year, month-1, 1);
				this.endDate = new Date(year, month, 0);
				break;
			case 'yearToDate' :
				this.startDate = new Date(year, 0, 1);
				this.endDate = new Date(year, month, day);
				break;
			case 'previousYear' :
				this.startDate = new Date(year-1, 0, 1);
				this.endDate = new Date(year-1, 11, 31, 23, 59, 59);
				break;
		}

		if (this.selectedDateOption != 'customDateRange') {
  		this.getTaxReport();
		}
    this.cdRef.detectChanges(); 
  }

  selectCustomDateRangeStart($event:any): void {
		this.startDate = $event.value;
    this.cdRef.detectChanges(); 
  }

  selectCustomDateRangeEnd($event:any): void {
		this.endDate = $event.value;
		this.getTaxReport();
    this.cdRef.detectChanges(); 
  }

  toggleExpanded($event, element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
    this.cdRef.detectChanges();
  }

}
