<div [formGroup]="order" *ngIf="order.value.images" class="container" style="height:100%;position:relative;max-width:500px;min-width:240px;"
	  fxLayout="column"
	  fxLayoutAlign="space-between"
	  fxLayoutGap="10px"
	  fxLayoutGap.xs="0">
	<div fxFlex="10px"></div>
	<div style="flex-shrink:0;flex-grow:1;" *ngIf="!order.value.archived">
		<app-images [images]="order.value.images" type="order" [productId]="order.value._id" (imagesChange)="updateImages($event,order)" [autoCrop]="false"></app-images>
				<!-- 
		<img [src]="order.value.image.thumbnail" [id]="order.value.image.id" style="max-width:240px;" [appThumbnail]="['500px','240px',order.value.image.image,order.value.image.thumbnail]"> -->
	</div>
	<div class="container" style="max-width:240px;width:240px;display:flex;"
	    fxLayout="row"
	    fxLayoutAlign="space-between center"
	    fxLayoutGap="10px"
	    fxLayoutGap.xs="0">
	  <div style="flex:1 0 25%;display:flex;" *ngIf="editOrderId==order.value.id">
	    <mat-form-field style="width:50px;flex: 1 1 20px;">
				<mat-select placeholder="TYPE" formControlName="type">
					<mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">
						{{typeOption.viewValue}}
					</mat-option>
				</mat-select>
      </mat-form-field>
    </div>
	  <div style="flex:0 1 100%;display:flex;">
	    <mat-form-field style="width:50px;flex: 1 1 20px;">
	    	<span matPrefix>{{order.value.type}}&nbsp;&ndash;&nbsp;</span>
	      <input matInput placeholder="INVOICE #" [(value)]="order.value.invoiceNumber" type="text" class="form-control" id="invoiceNumber" name="invoiceNumber" autocomplete="off" formControlName="invoiceNumber" (click)="$event.stopPropagation()">
	      <mat-icon matSuffix (click)="editOrder(order.value.id)">mode_edit</mat-icon>
	    </mat-form-field>
	  </div>
	</div>

<mat-checkbox
  (change)="$event ? toggleSelect(order) : null"
  [checked]="selection.isSelected(order)">
  COMPARE
</mat-checkbox>

	<div 
			*ngIf="order"
			class="container" 
			style="max-width:240px;width:240px;display:flex;"
	    fxLayout="row"
	    fxLayoutAlign="space-between center"
	    fxLayoutGap="10px"
	    fxLayoutGap.xs="0">
	  <div style="flex:0 1 50px;display:flex;">
	  	<mat-form-field style="width:35px;flex: 0 1 35px;" floatLabel="never">
		    <input matInput required placeholder="" type="text" formControlName="initials">
		    <mat-label><mat-icon>gesture</mat-icon></mat-label>
		  </mat-form-field>
	  </div>
	  <div style="flex:1 0 25%;display:flex;" *ngIf="editOrderId==order.value.id">
		  <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon>OPTIONS</button>
		  <mat-menu #menu="matMenu">
		    <button mat-menu-item type="button" aria-label="PRINT" (click)="printImage(order.value.images);$event.stopPropagation()">
		    	<mat-icon>print</mat-icon>
		    	PRINT
		    </button>
		    <button mat-menu-item type="button" [disabled]="order.value.initials=='' || order.value.status == 'RETURNED'" (click)="returnOrder($event);$event.stopPropagation()">
		    	<mat-icon>undo</mat-icon>
		    	RETURN
		    </button>
		    <button mat-menu-item type="button" [disabled]="order.value.initials=='' || order.value.status == 'EXCHANGED'" (click)="exchangeOrder($event);$event.stopPropagation()">
		    	<mat-icon>undo</mat-icon>
		    	EXCHANGE
		    </button>
		    <button mat-menu-item type="button" [disabled]="order.value.initials==''" (click)="deleteOrder($event);$event.stopPropagation()">
		    	<mat-icon>delete</mat-icon>
		    	DELETE
		  	</button>
		    <button mat-menu-item type="button" (click)="setOrderAsPrescription($event);$event.stopPropagation()">
		    	<mat-icon>edit</mat-icon>
		    	SET AS RX
		    </button>
		    <button mat-menu-item>
		    	<mat-checkbox formControlName="archived" [(value)]="order.value.archived">HIDE</mat-checkbox>
		    </button>
	  	</mat-menu>
	  </div>
	</div>

	




	<!-- <div class="container" style="max-width:240px;width:240px;display:flex;"
	    fxLayout="row"
	    fxLayoutAlign="space-between center"
	    fxLayoutGap="10px"
	    fxLayoutGap.xs="0">
	  <div style="flex:0 1 50px;display:flex;">
	  	<button mat-icon-button>
		    <mat-icon aria-label="PRINT" (click)="printImage(order.value.image)">print</mat-icon>
		  </button>
	  </div>
	  <div style="flex:1 0 25%;display:flex;" *ngIf="editOrderId==order.value.id">
	    <div style="width:50px;flex: 1 1 20px;"><mat-checkbox formControlName="archived" [(value)]="order.value.archived">HIDE</mat-checkbox></div>
	  </div>
	</div>

	<div class="container" fxFlex="40px" *ngIf="editOrderId==order.value.id"
	    fxLayout="row"
	    fxLayoutAlign="space-between center"
	    fxLayoutGap="10px"
	    fxLayoutGap.xs="0">
		<div class="item item-1"><button mat-raised-button type="button" (click)="deleteOrder($event);$event.stopPropagation()">DELETE</button></div>
		<div class="item item-1"><button mat-raised-button type="button" (click)="setOrderAsPrescription($event);$event.stopPropagation()">SET AS Rx</button></div>
	</div> -->
</div>