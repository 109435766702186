import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertOptions } from '../v2/common/alert/alert.component';
import { LoadingOptions } from '../v2/common/loading/loading.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alert = new Subject<AlertOptions>();
  alert$ = this.alert.asObservable();

  loading = new Subject<LoadingOptions>();
  loading$ = this.loading.asObservable();
}
