<div>
	<form [formGroup]="favoritesForm" enctype="multipart/form-data" (ngSubmit)="submit()">
		<mat-table #table [dataSource]="dataSource" formArrayName="favoritesFormArray" matSort>
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef mat-sort-header> NAME </mat-header-cell>
				<mat-cell *matCellDef="let favorite; let i=index;" [formGroupName]="i">
					<span *ngIf="favoriteId!=favorite._id">{{favorite.name}}</span>
					<mat-form-field *ngIf="favoriteId==favorite._id" floatLabel="never">
            <input #formRow matInput placeholder="NAME" type="text" formControlName="name" [(ngModel)]="favorite.name">
          </mat-form-field>
         </mat-cell>
			</ng-container>

			<ng-container matColumnDef="save">
				<mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;">  </mat-header-cell>
				<mat-cell *matCellDef="let favorite; let i=index" [formGroupName]="i" style="flex: 0 0 100px;">
					<span *ngIf="favoriteId!=favorite._id"></span>
		            <span *ngIf="favoriteId==favorite._id">
						<button mat-raised-button (click)="saveEditedFavorite(favorite,i)">SAVE</button>
		            </span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="delete">
				<mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;">  </mat-header-cell>
				<mat-cell *matCellDef="let favorite; let i=index" [formGroupName]="i" style="flex: 0 0 100px;">
					<span *ngIf="favoriteId!=favorite._id"></span>
		            <span *ngIf="favoriteId==favorite._id">
						<button mat-raised-button (click)="deleteFavorite(favorite,i)">DELETE</button>
		            </span>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns; let i=index;" appHighlight (click)="clickRow($event,row,i)"></mat-row>
		</mat-table>

		<div class="container"
			  fxLayout="row"
			  fxLayoutAlign="space-between center"
			  fxLayoutGap="10px"
			  fxLayoutGap.xs="0">
			<div class="item item-1" fxFlex="50%">
			  <button mat-raised-button type="button" (click)="addFavorite($event)">+ ADD FAVORITE LINE</button>
			</div>
			<div class="item item-2" fxFlex="50%">
				<mat-paginator #paginator
					[length]="dataSource.data.length"
					[pageSize]="10"
					[pageSizeOptions]="[10, 20, 100]">
				</mat-paginator>
			</div>
		</div>
	</form>
</div>