<mat-table #table [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumn" [matSortDirection]="defaultSortDirection" multiTemplateDataRows>

  <!-- <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef fxFlex="1 1 50px" style="padding-left:0px !important;">
      <mat-checkbox (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder; let i=index; let row;" fxFlex="1 1 50px">
      <mat-checkbox (click)="$event.stopPropagation()"
        (change)="$event ? toggleSelect(vendorOrder) : null"
        [checked]="selection.isSelected(vendorOrder)"
        [aria-label]="checkboxLabel(vendorOrder,i)">
      </mat-checkbox>
    </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> STATUS </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 10%">
      {{ vendorOrder.status }}
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="datePaid">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> PAID DATE </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder; let i=index; let row;" fxFlex="1 1 10%" [formGroup]="vendorOrder">
      <span *ngIf="vendorOrderId!=vendorOrder.value._id"> {{ vendorOrder.value.datePaid | date:'M/d/yyyy': 'GMT' }} </span>
      <mat-form-field *ngIf="vendorOrderId==vendorOrder.value._id" floatLabel="never" fxFlex="1 1 90%">
        <input #formRow [matDatepicker]="picker" matInput placeholder="DATE" type="text" formControlName="datePaid" (dateChange)="clickField($event,vendorOrder,i)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="invoiceNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> INVOICE # </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 10%">
      {{ vendorOrder.invoiceNumber }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tax">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> TAX </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 10%">
      {{ vendorOrder.tax | currency }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="shipping">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> SHIPPING </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 10%">
      {{ vendorOrder.shipping | currency }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> INVOICE DATE </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 10%">
      {{ vendorOrder.date | date:'M/d/yyyy': 'GMT' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> TOTAL </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="1 1 10%">
      {{ vendorOrder.total | currency }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"></mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="0 0 40px">
      <button mat-icon-button (click)="printVendorOrdersGroup(vendorOrder.groupId)">
        <mat-icon>print</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="log">
    <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"> LOG </mat-header-cell>
    <mat-cell *matCellDef="let vendorOrder" fxFlex="0 0 40px">
      <app-log-menu [order]="vendorOrder" [small]="true"></app-log-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="start stretch"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" fxLayout="row" fxLayoutAlign="start stretch" class="mat-elevation-z2"></mat-row>

</mat-table>
<button mat-raised-button class="btn-showmore" (click)="showMore()" *ngIf="dataSource.data.length < vendorOrders?.length">SHOW ALL</button>
