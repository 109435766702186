<!-- <div class="example-action-buttons">
  <button mat-button (click)="accordion.openAll()">Expand All</button>
  <button mat-button (click)="accordion.closeAll()">Collapse All</button>
</div>
 -->
<div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px" style="padding:12px; padding-left: 24px;width:100%">
  <div fxFlex="0 0 75px">
    PATIENT:
  </div>
  <div fxFlex="1 1 calc(35%-75px)">
    HINES, JOE
  </div>
  <div fxFlex="0 0 50px" style="text-align:center">
  	CELL:
  </div>
  <div fxFlex="1 1 calc(25%-50px)">
    (561)909-7797
  </div>
  <div fxFlex="1 1 40%" style="text-align:center;">
  	<span style="font-weight:bold;">No. 68574</span>
    <mat-form-field appearance="outline" style="width: 100%;margin-bottom:0px;" floatLabel="always">
		  <mat-label>INTERNAL NOTE</mat-label>
		  <textarea matInput rows="1"></textarea>
		</mat-form-field>
  </div>
</div>

<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel style="width:100%" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        FRAME DETAILS
      </mat-panel-title>
    </mat-expansion-panel-header>
		<app-frame-details></app-frame-details>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        RX DETAILS
      </mat-panel-title>
    </mat-expansion-panel-header>
		<app-prescription-details></app-prescription-details>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        LENS DETAILS
      </mat-panel-title>
    </mat-expansion-panel-header>
		<app-lens-details></app-lens-details>
  </mat-expansion-panel>
</mat-accordion>