    <mat-card>
      <mat-toolbar [style.background-color]="currentStore?.headerColor">
        <span>RETURNS</span>
      </mat-toolbar>
      <mat-card-content>
    		<app-vendors-base section="returns" (refreshReturnOrders)="getVendorOrders()"></app-vendors-base>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-toolbar [style.background-color]="currentStore?.headerColor">
        <span>AWAITING CREDIT</span>
      </mat-toolbar>
      <mat-card-content>

				<div *ngIf="!dataSource.data">
					<h3 style="text-align:center;">LOADING RETURNS AWAITING CREDIT</h3>
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				</div>
				<div class="container" style="width:100%">
				  <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">

				    <ng-container matColumnDef="vendor">
				      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
				      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;" fxFlex="1 1 200px">
				        <span style="font-weight:bold;"> {{ vendor.vendor.name }} </span>
				      </mat-cell>
				    </ng-container>

				    <ng-container matColumnDef="count">
				      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
				      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;" fxFlex="1 1 200px">
				        <span *ngIf="vendor.vendorOrders.length != 1"> {{ vendor.vendorOrders.length }} RETURNS</span>
				        <span *ngIf="vendor.vendorOrders.length == 1"> {{ vendor.vendorOrders.length }} RETURN</span>
				      </mat-cell>
				    </ng-container>

				    <ng-container matColumnDef="expandedDetail">
				      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;">
				        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="vendor!=expandedElement" [class.mat-elevation-z8]="vendor==expandedElement" [@detailExpand]="vendor == expandedElement ? 'expanded' : 'collapsed'">
<!-- <pre>{{vendor|json}}</pre> -->
				        <!-- <app-payable-orders [(vendorOrders)]="vendor" (refreshVendorOrders)="getVendorOrders()" style="width:100%;"></app-payable-orders> -->
								<app-returns-sent *ngIf="vendor===expandedElement" [vendorOrders]="vendor"  (refreshVendorOrders)="getVendorOrders()" style="width:100%;"></app-returns-sent>

				        </div>
				      </mat-cell>
				    </ng-container>


				    <mat-header-row
				      *matHeaderRowDef="displayedColumns"
				      fxLayout="row"
				      fxLayoutAlign="start center">
				    </mat-header-row>
				    <mat-row
				      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;"
				      fxLayout="row"
				      fxLayoutAlign="start center"
				      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}"
				      style="border-left:1px solid #dfdfdf !important;"
				      (click)="toggleExpanded($event, row, i)">
				    </mat-row>
				    <mat-row *matRowDef="let element; columns: ['expandedDetail']"
				      [class.example-detail-row-expanded]="expandedElement === element"
				      [class.example-detail-row-collapsed]="expandedElement !== element"
				      style="overflow: hidden"
				      fxLayoutAlign="start center">
				    </mat-row>
				  </mat-table>
				</div>

      </mat-card-content>
    </mat-card>

<br/>








