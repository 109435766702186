<button mat-raised-button type="button" *ngIf="selection.hasValue()" (click)="compareOrders()">COMPARE SELECTED ORDERS</button>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div [formGroup]="formGroup" *ngIf="!loading">
	<div style="padding-top:10px;" class="container"
			fxLayout="row wrap"
			fxLayoutAlign="start stretch"
			fxLayoutGap="10px"
			fxLayoutGap.xs="0"
			formArrayName="orders">
		<div *ngFor="let order of ordersFormArray.controls; let i=index" class="item">
			<app-form-order-image 
				[order]="ordersFormArray.controls[i]" 
				[withDelete]="true" 
				[(selection)]="selection" 
				[index]="i" 
				(clickDelete)="deleteOrder($event)" 
				(clickReturn)="returnOrder($event)" 
				(clickExchange)="exchangeOrder($event)" 
				(clickSetAsPrescription)="setOrderAsPrescription($event)" 
				fxFlex>
			</app-form-order-image>
		</div>
	</div>
</div>
