import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '@services/store.service';

@Pipe({
  name: 'storeName'
})
export class StoreNamePipe implements PipeTransform {

	constructor(private storeService: StoreService) { }

  transform(value: unknown, ...args: unknown[]): unknown {
  	if (value) {
  		let storeObject = this.storeService.getStoreObjectFromId(value);
  		if (storeObject) {
  			return storeObject.storeName;
  		}
  	}
    return null;
  }

}
