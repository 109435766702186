import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { LogElement, Order } from '@models/order';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { VendorOrderService } from '@services/vendor-order.service';
import { AuthService } from '@services/auth.service';
import { PrintService } from '@services/print.service';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { OrderForm } from '@classes/order-form';
import ObjectId from 'bson-objectid';
import { ApiResponse } from '@classes/api-response';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  defaultStatus,
  getTableConfig,
  orderStatusMap,
  searchVendorOrder,
} from './payable-orders.utils';
import {
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { MatTab } from '@angular/material/tabs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { VendorOrderForm } from '@classes/vendor-order-form';

export interface GroupAggregate {
  shipping: number;
  tax: number;
  total: number;
}
@Component({
  selector: 'app-payable-orders',
  templateUrl: './payable-orders.component.html',
  styleUrls: ['./payable-orders.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayableOrdersComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('headerTable') parentSortTable: MatTable<any>;
  @Input() public vendor: Vendor;
  @Input() public borrowingFromStore: any;
  @Input() public borrowingStore: any;
  @Input() set status(status: string) {
    this.changeOrdersStatus(status);
  }
  @Input() set search(val: string) {
    this.searchString = val ?? '';
    this.clearSelections();
    this.filterVendorOrders(val);
  }
  @Output() refreshVendorOrders = new EventEmitter<boolean>();

  orderStatus: 'paid' | 'unpaid' = defaultStatus;

  vendorOrders: VendorOrder[];
  vendorOrdersForm: FormGroup;
  defaultSortColumn: string = 'date';
  defaultSortDirection: string = 'desc';
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[];
  displayedColumns2: string[] = ['notes', 'total', 'action'];
  fieldOpen: boolean = false;
  vendorOrderId: string = null;
  vendorOrder: FormGroup = null;
  selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(
    true,
    []
  );
  expandedElement = null;
  searchString: string;
  orderMap: Record<string, any>;
  orderGroups: Array<any>;
  filteredOrderMap: Record<string, any> = {};
  selectedFields: string | undefined;
  selectedOrderId: string = null;
  selectedOrders: Record<string, boolean> = {};
  selectedOrderCount: number = 0;
  selectedGroupId: string | undefined;
  selectedGroups: Record<string, boolean> = {};
  deleteDialogRef: MatDialogRef<DeleteComponent>;
  unsubscribe$ = new Subject<void>();
  tableLoading: boolean;
  floatActions: boolean = false;
  minimized: boolean = false;
  expandedOrder: string | undefined;
  storeColor = localStorage.getItem('storeColor');
  currentSort: Sort;

  get allSelected() {
    const ordersCount = this.orderGroups.reduce(
      (acc: any[], group: any) => [...acc, ...(group.vendorOrders ?? [])],
      []
    ).length;
    return this.selectedOrderCount === ordersCount;
  }

  get someSelected() {
    return !!Object.keys(this.selectedOrders).length;
  }

  constructor(
    private vendorOrderService: VendorOrderService,
    private auth: AuthService,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public printService: PrintService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.selectedOrderId = params.get('orderId');
    });
  }

  ngOnInit() {
    this.setSorter();
    this.unsubscribe$
      .pipe(
        take(1),
        tap(() => {
          this.clearSelections();
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.getVendorOrders('all');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setLoading() {
    this.tableLoading = true;
    return of(true);
  }

  stopLoading() {
    return timer(1500).pipe(
      tap(() => {
        this.tableLoading = false;
        this.cdRef.detectChanges();
      })
    );
  }

  clearSelections() {
    this.selectedGroupId = undefined;
    this.selectedGroups = {};
  }

  changeOrdersStatus(status: string, sort?: Sort) {
    this.clearSelections();
    this.orderStatus = orderStatusMap[status] ?? defaultStatus;
    const { columns, sortBy, sortDir } = getTableConfig(
      this.vendor,
      this.orderStatus
    );
    this.displayedColumns = columns;
    this.defaultSortColumn = sortBy;
    this.defaultSortDirection = sortDir;
    if (this.orderMap) {
      this.setLoading()
        .pipe(
          tap(() => {
            this.filteredOrderMap = this.setVendorFormsArray(
              this.orderMap,
              sort
            );
            this.orderGroups = Object.values(this.filteredOrderMap);
            this.sortTableData(
              {
                active: this.defaultSortColumn,
                direction: this.defaultSortDirection as SortDirection,
              },
              true
            );
            this.dataSource = new MatTableDataSource<any>(this.orderGroups);
            this.setSorter();
            this.cdRef.detectChanges();
          }),
          concatMap(() => this.stopLoading())
        )
        .subscribe();
    }
  }

  getVendorOrders(status: string) {
    const store = this.auth.getStore();
    if (this.borrowingFromStore && this.borrowingFromStore._id == store) {
      this.vendorOrderService
        .getBorrowedVendorOrders('receivable', this.borrowingStore._id)
        .pipe(concatMap((res: VendorOrder[]) => this.setOrdersResponse(res)))
        .subscribe();
    } else if (this.borrowingFromStore) {
      this.vendorOrderService
        .getBorrowedVendorOrders(status, this.borrowingFromStore._id)
        .pipe(concatMap((res: VendorOrder[]) => this.setOrdersResponse(res)))
        .subscribe();
    } else if (this.vendor?.distributor) {
      this.vendorOrderService
        .getDistributorVendorOrders(status, this.vendor.distributorVendors)
        .pipe(concatMap((res: VendorOrder[]) => this.setOrdersResponse(res)))
        .subscribe();
    } else {
      this.vendorOrderService
        .getVendorVendorOrders(status, this.vendor._id)
        .pipe(
          map(
            (res: ApiResponse<VendorOrders[]>) =>
              res.vendorOrders[0].vendorOrders
          ),
          concatMap((res: VendorOrder[]) => this.setOrdersResponse(res))
        )
        .subscribe();
    }
  }

  deselectGroup(groupId: string) {
    const { [groupId]: _, ...rest } = this.selectedGroups;
    this.selectedOrders = rest;
    this.selectedOrders = {};
    this.selectedOrderCount = 0;
    this.selectedGroupId = undefined;
  }

  deselectOrder(id: string) {
    const { [id]: _, ...rest } = this.selectedOrders;
    this.selectedOrders = rest;
    this.selectedOrderCount = Object.keys(this.selectedOrders).length;
  }

  selectGroup(groupId: string) {
    if (this.selectedGroups[groupId]) {
      this.deselectGroup(groupId);
    }
    this.selectedGroupId = groupId;
    this.selectedOrders = this.filteredOrderMap[groupId].orders.reduce(
      (acc: Record<string, string>, o: Order) => ({
        ...acc,
        [o._id]: true,
      }),
      {}
    );
    this.selectedOrderCount = Object.keys(this.selectedOrders).length;
  }

  selectOrder(id: string) {
    if (this.selectedOrders[id]) {
      return this.deselectOrder(id);
    }
    this.selectedGroups = { ...this.selectedGroups, [id]: true };
    this.selectedOrderCount = Object.keys(this.selectedOrders).length;
  }

  setOrdersResponse(vendorOrders: VendorOrder[]) {
    this.vendorOrders = Array.from(new Set(vendorOrders));
    this.orderMap = this.getOrderGroupMap(this.vendorOrders);
    this.changeOrdersStatus(this.orderStatus ?? defaultStatus);
    return EMPTY;
  }

  filterVendorOrders(search: string) {
    if (this.orderMap) {
      if (!search) {
        this.changeOrdersStatus(this.orderStatus, this.currentSort);
      } else {
        this.setLoading()
          .pipe(
            tap(() => {
              this.filteredOrderMap = Object.entries(this.orderMap).reduce(
                (acc: Record<string, any>, [groupId, group]) => {
                  const matchedOrders = group.orders.filter((o: any) =>
                    searchVendorOrder(o, search)
                  );
                  if (matchedOrders?.length) {
                    return {
                      ...acc,
                      [groupId]: { ...group, orders: matchedOrders },
                    };
                  }
                  return acc;
                },
                [] as Array<VendorOrder>
              );
              this.filteredOrderMap = this.setVendorFormsArray(
                this.filteredOrderMap,
                this.currentSort
              );
              this.orderGroups = Object.values(this.filteredOrderMap);
              this.sortTableData(
                {
                  active: this.defaultSortColumn,
                  direction: this.defaultSortDirection as SortDirection,
                },
                true
              );
              this.dataSource = new MatTableDataSource<any>(this.orderGroups);
              this.cdRef.detectChanges();
            }),
            concatMap(() => this.stopLoading())
          )
          .subscribe();
      }
    }
  }

  getOrderGroupMap(vendorOrders: Array<VendorOrder>) {
    return vendorOrders?.reduce((acc: Record<string, any>, vo: VendorOrder) => {
      const { groupId, status } = vo;
      if (groupId || status === 'PAID') {
        const id = groupId || status;
        return {
          ...acc,
          [id]: {
            ...(acc?.id ?? {}),
            status: 'paid',
            orderMap: {
              ...(acc[id]?.orderMap ?? {}),
              [vo._id]: vo,
            },
            orders: [...(acc[id]?.orders ?? []), vo],
            isGroupBy: true,
            datePaid: vo.datePaid,
          },
        };
      }

      return {
        ...acc,
        ['unpaid']: {
          ...(acc?.['unpaid'] ?? {}),
          status: 'unpaid',
          orderMap: {
            ...(acc['unpaid']?.orderMap ?? {}),
            [vo._id]: vo,
          },
          orders: [...(acc['unpaid']?.orders ?? []), vo],
        },
      };
    }, {});
  }

  setVendorFormsArray(
    map: Record<string, any>,
    sort?: Sort
  ): Record<string, any> {
    return Object.entries(map)
      .filter(([_, group]) => group.status === this.orderStatus)
      .reduce((acc: Record<string, any>, [groupId, group]) => {
        const { orders, isGroupBy, datePaid, orderMap } = group;
        let sortedOrders = orders;
        if (sort) {
          sortedOrders = orders.sort((a: any, b: any) =>
            this.sortCompare(a, b, sort)
          );
        }
        const orderControls: Array<FormGroup> = [];
        const { tax, shipping, total } = sortedOrders.reduce(
          (acc: GroupAggregate, o: VendorOrder) => {
            orderControls.push(this.initVendorOrderFormItem(o));
            return {
              shipping: acc.shipping + (o.shipping ?? 0),
              tax: acc.tax + (o.tax ?? 0),
              total: acc.total + (o.total ?? 0),
            };
          },
          { shipping: 0, tax: 0, total: 0 }
        );
        const ordersForm = new FormGroup({
          vendorOrders: new FormArray(orderControls, Validators.required),
          initials: new FormControl(null, Validators.required),
          notes: new FormControl(),
          total: new FormControl(total),
        });
        const value = ordersForm.value;
        // ordersForm.valueChanges
        //   .pipe(takeUntil(this.unsubscribe$))
        //   .subscribe((value) => {
        //     group.value = value;
        //     this.cdRef.detectChanges();
        //   });

        return {
          ...acc,
          [groupId]: {
            ...value,
            datePaid,
            isGroupBy,
            isGroup: () => !!isGroupBy,
            groupId,
            tax,
            total,
            shipping,
            orderMap,
            ordersForm,
            dataSource: new MatTableDataSource<FormGroup>(orderControls),
            dataSource2: new MatTableDataSource<FormGroup>([
              ordersForm.get('total') as FormGroup,
            ]),
            value,
          },
        };
      }, {});
  }

  getGroupSortProperty(group: any, key: string) {
    switch (key) {
      case 'datePaid':
      case 'date':
        return new Date(group[key]).getTime();
      case 'tax':
      case 'shipping':
      case 'total':
        return +group[key];
      default:
        if (['number', 'string'].includes(typeof group[key])) return group[key];
        return '';
    }
  }

  sortCompare(a: any, b: any, sort: Sort): number {
    const { active, direction } = sort;
    const aPropVal = this.getGroupSortProperty(a, active);
    const bPropVal = this.getGroupSortProperty(b, active);
    if (!direction || (!aPropVal && !bPropVal) || aPropVal === bPropVal) {
      return 0;
    }
    const isLess = aPropVal > bPropVal;
    return (isLess && direction === 'asc') || (!isLess && direction === 'desc')
      ? 1
      : -1;
  }

  /* Sort the groups and the controls within the groups */
  sortTableData(sort: Sort, sameDir?: boolean) {
    this.currentSort = sort;
    const { active } = sort;
    this.defaultSortColumn = active;
    this.defaultSortDirection = sameDir
      ? this.defaultSortDirection
      : this.defaultSortDirection === 'asc'
      ? 'desc'
      : 'asc';

    const dataSortProps: Sort = {
      active: this.defaultSortColumn,
      direction: this.defaultSortDirection as SortDirection,
    };
    this.orderGroups.sort((a, b) => this.sortCompare(a, b, dataSortProps));
    this.cdRef.detectChanges();
  }

  sortData(sort: Sort) {
    this.sortTableData(sort);
    this.dataSource = new MatTableDataSource<any>(this.orderGroups);
    this.cdRef.detectChanges();
    this.parentSortTable.renderRows();
  }

  setDisplay() {
    this.floatActions = !this.floatActions;
  }

  pad(n, width) {
    let z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  setSorter() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      const {
        ordersForm: { value },
      } = item;
      switch (property) {
        case 'datePaid':
        case 'date':
          return new Date(value[property]).getTime();
        case 'tax':
        case 'shipping':
        case 'total':
          return +value[property];
        default:
          return value[property];
      }
    };
  }

  isGroup(index, item): boolean {
    return item.value.isGroupBy;
  }

  isNotGroup(index, item): boolean {
    return !item.value.isGroupBy;
  }

  initVendorOrderFormItem(vendorOrder: VendorOrder) {
    const productsFormArray: FormArray = this.fb.array([]);
    for (const product of vendorOrder.products) {
      const productFormGroup = OrderForm.initProductFormGroup(product.product);
      productsFormArray.push(
        this.fb.group({
          _id: product._id,
          cost: product.cost,
          pricingFormula: product.pricingFormula,
          retail: product.retail,
          duty: [{ value: product.duty, disabled: true }],
          quantity: product.quantity,
          frame: this.fb.group(product.frame),
          product: productFormGroup,
          store: product.store,
          type: product.type,
        })
      );
    }

    const orderForms = vendorOrder.orders.map((o) => new FormControl(o._id));
    const orders = new FormArray(orderForms);
    const vendorOrderFormGroup = this.fb.group({
      _id: vendorOrder._id,
      vendor: vendorOrder.vendor,
      store: vendorOrder.store,
      invoiceNumber: [vendorOrder.invoiceNumber, Validators.required],
      status: vendorOrder.status,
      groupId: vendorOrder.groupId,
      tax: vendorOrder.tax,
      shipping: vendorOrder.shipping,
      total: vendorOrder.total,
      user: vendorOrder.user,
      date: vendorOrder.date,
      datePaid: vendorOrder.datePaid,
      products: productsFormArray,
      orders,
      initials: ['', Validators.required],
      log: this.fb.array(vendorOrder.log),
    });

    if (vendorOrder.status === 'RETURNED') {
      vendorOrderFormGroup.controls['tax'].valueChanges
        .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
        .subscribe((value: number) => {
          if (value > 0) {
            vendorOrderFormGroup.controls['tax'].setValue(-value);
          }
        });

      vendorOrderFormGroup.controls['shipping'].valueChanges
        .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
        .subscribe((value: number) => {
          if (value > 0) {
            vendorOrderFormGroup.controls['shipping'].setValue(-value);
          }
        });
    }

    vendorOrderFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
      .subscribe((value) => {
        console.log(value);
        const { shipping, tax } = value;
        const total = vendorOrder.total + shipping + tax;
        if (total !== value.total) {
          vendorOrderFormGroup.controls['total'].setValue(total);
        }
      });
    return vendorOrderFormGroup;
  }

  @HostListener('document:click', ['$event']) clickOutside($event) {
    if (!this.fieldOpen) {
      if (this.vendorOrderId != '' && this.vendorOrderId != undefined) {
        //this.saveEditedProduct(this.editProduct, this.productIndex);
      }
      this.vendorOrderId = '';
    } else {
      this.fieldOpen = false;
    }
  }

  clickRow($event: Event, vendorOrder: FormGroup, index) {
    if (!this.fieldOpen) {
      this.vendorOrderId = vendorOrder.value._id;
      if (this.expandedElement !== vendorOrder) {
        this.expandedElement = vendorOrder;
      } else {
        this.expandedElement = null;
      }
      $event.preventDefault();
      $event.stopPropagation();
      this.fieldOpen = false;
      this.cdRef.detectChanges();
    }
  }

  clickField($event, product, index) {
    this.fieldOpen = true;
  }

  isSelected(id: string): boolean {
    return !!this.selectedOrders[id];
  }

  selectOrders(change: MatCheckboxChange) {
    const { checked } = change;
    if (!checked) {
      this.selectedOrders = {};
    } else {
      this.orderGroups.forEach((g: any) => {
        g.vendorOrders.forEach(
          (order) => (this.selectedOrders[order._id] = true)
        );
      });
    }
    this.setSelectedCount();
  }

  setSelectedCount() {
    this.selectedOrderCount = Object.keys(this.selectedOrders).length;
    this.calculateTotal();
  }

  toggleExpanded(id: string) {
    if (id === this.expandedOrder) {
      this.expandedOrder = undefined;
    }
    this.expandedOrder = id;
  }

  toggleMinimized() {
    this.minimized = !this.minimized;
  }

  toggleSelection(id: string) {
    const selected = this.isSelected(id);
    if (selected) {
      const { [id]: _, ...rest } = this.selectedOrders;
      this.selectedOrders = rest;
    } else {
      this.selectedOrders[id] = true;
    }
    this.setSelectedCount();
  }

  calculateTotal() {
    const selectedOrderIds = Object.keys(this.selectedOrders);
    const total = [...this.orderGroups]
      .reduce(
        (acc: Array<FormGroup>, group: any) => [
          ...acc,
          ...group.ordersForm.controls.vendorOrders.controls,
        ],
        []
      )
      .filter((form: FormGroup) => selectedOrderIds.includes(form.value._id))
      .reduce((acc: number, fg: FormGroup) => acc + (fg.value.total ?? 0), 0);
    this.orderGroups[0]?.ordersForm.controls.total.setValue(total);
  }

  checkboxLabel(id: string, all?: boolean): string {
    if (all) {
      return `${this.allSelected ? 'select' : 'deselect'} all`;
    }
    return `${this.selectedOrders[id] ? 'deselect' : 'select'} order ${id}`;
  }

  saveVendorOrder(vendorOrder: FormGroup, groupId: string) {
    const updateStatus = 'UPDATED';
    const updateOrder = this.getVendorOrderUpdates(
      vendorOrder,
      groupId,
      updateStatus
    );
    this.fieldOpen = true;
    this.vendorOrderService
      .updateVendorOrder(updateOrder._id, updateOrder)
      .pipe(
        take(1),
        tap(() => this.getVendorOrders('all'))
      )
      .subscribe();
  }

  deleteVendorOrder(vendorOrder: FormGroup, groupId: string, index: number) {
    const updateStatus = 'DELETED';
    const updateOrder = this.getVendorOrderUpdates(
      vendorOrder,
      groupId,
      updateStatus
    );
    this.fieldOpen = true;
    this.deleteDialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: {
        message: 'DELETE INVOICE #' + vendorOrder.value.invoiceNumber + '?',
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.vendorOrderService
          .updateVendorOrder(updateOrder._id, {
            ...updateOrder,
            status: updateStatus,
          })
          .pipe(
            take(1),
            tap(() => this.getVendorOrders('all'))
          )
          .subscribe();
      }
    });
  }

  getVendorOrderUpdates(
    vendorForm: FormGroup,
    groupId: string,
    status: string,
    props: Record<string, any> = {}
  ) {
    const {
      value,
      value: { _id, initials, notes, tax, shipping, total },
    } = vendorForm;
    const order = this.orderMap[groupId].orderMap[_id];
    const { log } = order;
    return {
      ...value,
      ...props,
      tax,
      shipping,
      total,
      log: log.concat([this.getLogItem(status, initials.toUpperCase(), notes)]),
    };
  }

  getLogItem(status: string, initials: string, notes: string = '') {
    return {
      _id: new ObjectId().toString(),
      initials: initials.toUpperCase(),
      user: this.auth.currentUser._id,
      item: status,
      notes,
      date: new Date().toISOString(),
    };
  }

  markVendorOrdersPaidAndPrint() {
    this.markVendorOrdersPaid();
    this.printVendorOrders();
  }

  markOrderPaid(id: string, payload: VendorOrder) {
    return this.vendorOrderService.updateVendorOrder(id, payload).pipe(take(1));
  }

  markVendorOrdersPaid() {
    const updateStatus = 'PAID';
    const groupId = new ObjectId().toString();
    const datePaid = new Date().toISOString();
    const selectedOrderIds = Object.keys(this.selectedOrders);
    const selectedOrderForms = this.orderGroups
      .reduce(
        (acc: Array<FormGroup>, group: any) => [
          ...acc,
          ...group.ordersForm.controls.vendorOrders.controls,
        ],
        []
      )
      .filter((form: FormGroup) => selectedOrderIds.includes(form.value._id));
    const payVendors = selectedOrderForms.map((form: FormGroup) => {
      const updateOrder = this.getVendorOrderUpdates(
        form,
        'unpaid',
        updateStatus,
        { groupId, datePaid }
      );
      return this.markOrderPaid(updateOrder._id, {
        ...updateOrder,
        status: updateStatus,
      });
    });

    combineLatest(payVendors)
      .pipe(
        tap(() => {
          this.refreshVendorOrders.emit(true);
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  markVendorOrderCredited(vendorOrder: FormGroup, groupId: string) {
    const updateStatus = 'CREDITED';
    const updateOrder = this.getVendorOrderUpdates(
      vendorOrder,
      groupId,
      updateStatus
    );
    this.fieldOpen = true;
    this.vendorOrderService
      .updateVendorOrder(updateOrder._id, {
        ...updateOrder,
        status: updateStatus,
      })
      .pipe(tap(() => this.getVendorOrders('all')))
      .subscribe();
  }

  printVendorOrders() {
    this.printService.printVendorInvoices(Object.keys(this.selectedOrders));
  }

  printVendorOrdersGroup(groupId: string) {
    this.printService.printVendorInvoices(
      this.orderMap[groupId].orders.map((o) => o._id)
    );
  }
}

export interface GroupBy {
  date: string;
  isGroupBy: boolean;
}
