<mat-card>
  <mat-toolbar [style.background-color]="currentStore?.headerColor">
    <span>{{ borrowingStore.storeName }} TO {{ currentStore.storeName }}</span>
  </mat-toolbar>
  <mat-card-content>
    <!-- <app-borrowing-orders [borrowingStore]="0"></app-borrowing-orders> -->
    <app-borrowing-vendors [borrowingStore]="0" ></app-borrowing-vendors>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-toolbar [style.background-color]="currentStore?.headerColor">
    <span>{{ currentStore.storeName }} TO {{ borrowingStore.storeName }}</span>

    <mat-select #formRow #statusSelect placeholder="BORROWING" [(value)]="borrowingStore._id">
      <ng-container *ngFor="let storeOption of storeOptions">
        <mat-option *ngIf="storeOption._id !== currentStore._id" [value]="storeOption._id">
            {{storeOption.storeName}}
        </mat-option>
      </ng-container>
    </mat-select>

  </mat-toolbar>
  <mat-card-content>
    <!-- <app-borrowing-orders [borrowingStore]="borrowingStore._id"></app-borrowing-orders> -->
    <app-borrowing-vendors [borrowingStore]="borrowingStore._id" ></app-borrowing-vendors>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="currentRentalUser">
  <mat-toolbar [style.background-color]="currentStore?.headerColor">
    <span>RENTING {{ currentStore.storeName }} TO {{ currentRentalUser.name }}</span>

    <mat-select placeholder="RENTING" [value]="currentRentalUser._id" (selectionChange)="changeRentalUser($event)">
      <mat-option *ngFor="let rentalUser of rentalUsers" [value]="rentalUser._id">
        {{rentalUser.name}}
      </mat-option>
    </mat-select>

  </mat-toolbar>
  <mat-card-content>
    <app-borrowing-vendors [rentingUser]="currentRentalUser._id"></app-borrowing-vendors>
  </mat-card-content>
</mat-card>
