<mat-dialog-content>{{ data.message }}</mat-dialog-content>
<mat-dialog-actions>
	<mat-form-field>
	  <input matInput placeholder="CREDIT" (blur)="formatCurrency()" [(ngModel)]="credit">
    <span matPrefix>$&nbsp;</span>
	</mat-form-field>
  <button mat-raised-button (click)="return()">RETURN</button>
  <button mat-raised-button (click)="cancel()">CANCEL</button>
</mat-dialog-actions>

