import { Pipe, LOCALE_ID, Inject, Component, OnInit, Output, EventEmitter, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { StoreService } from '@services/store.service';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-fix-orders',
  templateUrl: './fix-orders.component.html',
  styleUrls: ['./fix-orders.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FixOrdersComponent implements OnInit {
	dataSource = new MatTableDataSource<VendorOrders>();
	dataSource2: MatTableDataSource<Order>[] = [];
  displayedColumns = ['vendor', 'count'];
  displayedColumns2 = ['date', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'patientStock', 'patient', 'invoiceNumber', 'quantity', 'action'];
  authorized: boolean = false;
  authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN'];
  expandedElement = null;
  dataLoading: boolean = false;

  constructor(@Inject(LOCALE_ID) public locale: string, public auth: AuthService, public orderService: OrderService, public productService: ProductService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {
  }

  ngAfterViewInit() {
    this.authorized = this.authorizedRoles.includes(this.auth.currentUser.role);
    this.getNewOrders();

	}

  getNewOrders() {
    this.orderService.getDispensedOrders().subscribe(vendorOrders => {
      console.log(vendorOrders)
      // this.route.paramMap.subscribe(params => {
      //   let vendorId = params.get('vendorId');
      //   this.expandedElement = vendorOrders.find(x => x.vendor._id === vendorId);
      // })



      this.dataSource = new MatTableDataSource<VendorOrders>(vendorOrders);
      // this.vendorOrders = vendorOrders;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'vendor': 
            // if (item.vendor) {
            //   let vendor: Object = item.vendor;
            //   return vendor['name'];
            // } else {
            //   return item[property];
            // }
          //case 'vendor.name': return item.vendor.name;
          default: return item[property];
        }
      };
      // this.dataSource.sort = this.sort;

      for (let x = 0; x < vendorOrders.length; x++) {
      	this.dataSource2.push(new MatTableDataSource<Order>(vendorOrders[x].orders));
      }


      this.dataLoading = false;
      this.cdRef.detectChanges(); 
      
    });
	}

  ngOnInit() {

  }

  decrementInventory(order, orderIndex, index) {
    this.productService.incrementProductInventoryQuantity(order.productInventory, -1).subscribe(product => {
			this.dataSource2[orderIndex].data.splice(index, 1);
  		this.dataSource2[orderIndex]._updateChangeSubscription();
    });
  }

	toggleExpanded(element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

}
