import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Patient } from '@models/patient';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { EyeExamService } from '@services/eye-exam.service';
import { AuthService } from '@services/auth.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { Store } from '@models/store';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ExamsComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  loading = false;
  expandedElement = null;
  displayedColumns = ['patient', 'encounter', 'linkedPatient', 'date'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  dateOptions: any = [
    { value: 'today', viewValue: 'TODAY'},
    { value: 'yesterday', viewValue: 'YESTERDAY'},
    { value: 'currentMonth', viewValue: 'CURRENT MONTH'},
    { value: 'previousMonth', viewValue: 'PREVIOUS MONTH'},
    { value: 'yearToDate', viewValue: 'YEAR TO DATE'},
    { value: 'previousYear', viewValue: 'PREVIOUS YEAR'},
    { value: 'customDateRange', viewValue: 'CUSTOM DATE RANGE'}
  ];
  selectedDateOption: any;
  startDate = moment.utc().hour(0).minute(0).second(0).format();
  endDate = moment.utc().hour(23).minute(59).second(59).format();
  currentStore: Store;
  storeSubscription: Subscription;
  refreshing: boolean = false;
  examCount: number = 0;

  exams = [];

  constructor(public cdRef: ChangeDetectorRef, private eyeExamService: EyeExamService, private auth: AuthService, private notificationService: NotificationService) {
    this.storeSubscription = this.auth.getStoreObjectMessage().subscribe((store: Store) => {
      this.currentStore = store;
    });
  }

  ngOnInit(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default: return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.loadExams();
    this.cdRef.detectChanges();
  }

  loadExams() {
    this.refreshing = true;
    this.eyeExamService.getAll([this.startDate, this.endDate], this.auth.getStoreObject()._id).subscribe(res => {
      this.exams = res;
      this.dataSource = new MatTableDataSource<any>(this.exams);
      this.examCount = res.length;
      this.refreshing = false;
    });
  }

  selectDateOption($event: any) {
    this.selectedDateOption = $event.value;

    switch (this.selectedDateOption) {
      case 'today' :
        this.startDate = moment.utc().hour(0).minute(0).second(0).format();
        this.endDate = moment.utc().hour(23).minute(59).second(59).format();
        break;
      case 'yesterday' :
        this.startDate = moment.utc().subtract(1, 'day').hour(0).minute(0).second(0).format();
        this.endDate = moment.utc().subtract(1, 'day').hour(23).minute(59).second(59).format();
        break;
      case 'currentMonth' :
        this.startDate = moment.utc().startOf('month').hour(0).minute(0).second(0).format();
        this.endDate = moment.utc().endOf('month').hour(23).minute(59).second(59).format();
        break;
      case 'previousMonth' :
        this.startDate = moment.utc().subtract(1, 'month').startOf('month').hour(0).minute(0).second(0).format();
        this.endDate = moment.utc().subtract(1, 'month').endOf('month').hour(23).minute(59).second(59).format();
        break;
      case 'yearToDate' :
        this.startDate = moment.utc().startOf('year').hour(0).minute(0).second(0).format();
        this.endDate = moment.utc().endOf('year').hour(23).minute(59).second(59).format();
        break;
      case 'previousYear' :
        this.startDate = moment.utc().subtract(1, 'year').startOf('year').hour(0).minute(0).second(0).format();
        this.endDate = moment.utc().subtract(1, 'year').endOf('year').hour(23).minute(59).second(59).format();
        break;
    }

    if (this.selectedDateOption !== 'customDateRange') {
      this.loadExams();
    }

    this.cdRef.detectChanges();
  }

  selectCustomDateRangeStart($event: any): void {
    this.startDate = moment.utc($event.value).hour(0).minute(0).second(0).format();
    this.cdRef.detectChanges();
  }

  selectCustomDateRangeEnd($event: any): void {
    if (!$event.value) { return; }
    this.endDate = moment.utc($event.value).hour(23).minute(59).second(59).format();
    this.loadExams();
    this.cdRef.detectChanges();
  }

  selectPatient(patient: Patient, exam: any) {
    exam.selectedPatient = patient;
    this.expandedElement = exam;
    this.cdRef.detectChanges();
  }

  linkPatient(examId: string, patientId: string) {
    this.eyeExamService.linkPatient(examId, patientId).subscribe(res => {
      if (res) {
        this.notificationService.show('success', 'Linked patient successfully!');
        this.loadExams();
      }
    });
  }

  toggleExpanded($event, element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

  refresh() {
    this.loadExams();
  }

  open2020VisionPC() {
    window.open('https://emr.for2020now.com/Account/Login', '_blank');
  }

  unlinkPatient(examId: string, patientId: string) {
    this.eyeExamService.unlinkPatient(examId, patientId).subscribe(res => {
      if (res) {
        this.notificationService.show('success', 'Unlinked patient successfully!');
        this.loadExams();
      }
    });
  }
}
