<mat-dialog-content class="image-edit-dialog">
	<div class="cropper-view">
		<image-cropper
			#cropper
			*ngIf="loaded"
			[imageURL]="imageUrl"
			[aspectRatio]="2434 / 1048"
			[canvasRotation]="rotate"
			(imageCropped)="imageCropped($event)"
		>
		</image-cropper>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div style="display:block;">
		<button mat-button type="button" (click)="rotateLeft()"><mat-icon>rotate_left</mat-icon></button>
		<button mat-button type="button" (click)="rotateRight()" class="btn-rotate-right"><mat-icon>rotate_right</mat-icon></button>
		<button mat-button type="button" (click)="reset()" class="btn-clear"><mat-icon>clear</mat-icon></button>
	</div>
	<div style="display:block;">
	  <button mat-raised-button (click)="save()">SAVE</button>
	  <button mat-raised-button (click)="cancel()">CANCEL</button>
	</div>
</mat-dialog-actions>
