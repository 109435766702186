import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
//import { FormGroup, ControlContainer, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { ProductService } from '@services/product.service';
import { environment } from '@environments/environment';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { AuthService } from '@services/auth.service';

const imageURL = environment.base_url + '/image/';

import { map, startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-vendor-products',
  providers: [ProductService],
  templateUrl: './autocomplete-vendor-products.component.html',
  styleUrls: ['./autocomplete-vendor-products.component.css']
})
export class AutocompleteVendorProductsComponent implements OnInit {
	@Input() placeholder: string;
	@Input() public displayField: string;
 	@Output() selected = new EventEmitter<Object>();
	filteredProducts: Observable<Object>;

  private _productForm: FormGroup;
	@Input()
	set productForm(val: FormGroup) {
		this._productForm = val;
	}
	get productForm(): FormGroup {
		return this._productForm;
	}

	@Output()
  fieldOpenChange = new EventEmitter<boolean>();
  private _fieldOpen: boolean;
	get fieldOpen(): boolean {
		return this._fieldOpen;
	}
	@Input()
	set fieldOpen(val: boolean) {
		this._fieldOpen = val;
		this.fieldOpenChange.emit(this._fieldOpen);
	}

	private _vendor: Vendor;
	get item(): Vendor {
		return this._vendor;
	}
	@Input()
	set vendor(val: Vendor|string) {
		if (typeof val == 'string' || !val) {
			this._vendor = null;
		} else {
			this._vendor = val;
			if (this._vendor._id) {
				this.filteredProducts = this.productService.queryVendorProductsField(this._vendor._id, new ProductQuery(), this.displayField).pipe(
					map((res) => {
						return res[this.displayField];
					})
				); 
			}
		}
		
	}

	constructor(private router: Router, private productService: ProductService, private authService: AuthService) {}

	selectProduct($event, product: Product): string {
		if ($event.source.selected && this._vendor != null) {
			let queryCtrl: FormControl = <FormControl>this._productForm.get('query');
			let query = queryCtrl.value;
  		query[this.displayField].selected = true;
  		query[this.displayField].value = $event.source.value;
			this.selected.emit(Object.assign({}, query));
			this._fieldOpen = true;
			this.fieldOpenChange.emit(true);
		}
		return 'test';
	}

  clickField($event) {
    this._fieldOpen = true;
  	this.fieldOpenChange.emit(true);
  }

	chooseFirstOption($event): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

	ngAfterViewInit() {
		let productCtrl: FormControl = <FormControl>this._productForm.get(this.displayField);
		let queryCtrl: FormControl = <FormControl>this._productForm.get('query');
		let queryProductsCtrl: FormControl = <FormControl>this._productForm.get('queryProducts');
		let fieldCtrl: FormControl = <FormControl>this._productForm.get(this.displayField);
		let query = queryCtrl.value;

		queryProductsCtrl.valueChanges.subscribe((queryString) => {
			this.filteredProducts = of(queryString[this.displayField])
		})

		fieldCtrl.valueChanges.pipe(
			takeWhile(() => this._vendor != null )
		).subscribe((queryString) => {
			let queryCtrl: FormControl = <FormControl>this._productForm.get('query');

			query[this.displayField].value = queryString;
  		queryCtrl.setValue(query);

			this.productService.queryVendorProductsField(this._vendor._id, query, this.displayField).subscribe((res) => {
  			queryProductsCtrl.setValue(res);
			});

		})
		
  }

  ngOnInit() {
	}
}
