import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { User } from '@models/user';
import { Store } from '@models/store';
import {
  Subject,
  Observable,
  AsyncSubject,
  BehaviorSubject,
  of,
  Subscription,
  timer,
  map,
  take,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfigService } from '@services/app-config.service';

import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '@components/admin/dialog/logout/logout.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // private base_url = 'http://localhost:3002/api/user';
  // private base_url = 'http://ec2-54-175-59-117.compute-1.amazonaws.com:3002/api/user';
  private base_url = `${environment.base_url}/auth`;
  token: string;
  public currentUser: User;
  private subject = new Subject<any>();
  private storeSubject = new BehaviorSubject<any>('');
  private storeObject = new BehaviorSubject<Store>(new Store());
  private logoutTimer: Observable<number>;
  private logoutTimerSubscription: Subscription;
  private logoutTimerStarted = false;
  private stores: Array<Store>;

  public get store(): string {
    return this.storeSubject.value;
  }

  get initials(): string {
    return this.currentUser.name
      .split(' ')
      .map((item) => item[0].toUpperCase())
      .join('');
  }

  get user(): User {
    return this.currentUser;
  }

  get userId(): string {
    return this.user?._id;
  }

  constructor(
    public http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    public appConfigService: AppConfigService
  ) {}

  syncLocalFromStorage() {
    this.token = localStorage.getItem('authToken');
    const storeName = localStorage.getItem('store');
    const store = this.appConfigService
      .getConfig()
      .stores.find((s) => s.storeName === storeName);
    this.setStore(store);
  }

  setUser(user: User) {
    this.currentUser = user;
    this.subject.next(user);
  }

  setStore(store: Store) {
    this.storeSubject.next(store?._id);
    this.storeObject.next(store);
  }

  registerUser(user: User): Observable<boolean> {
    const body = JSON.stringify(user);
    return this.http
      .post(`${this.base_url}/register`, body)
      .pipe(map((res) => this.setToken(res)));
  }

  loginUser(user): Observable<Object> {
    const body = JSON.stringify(user);
    return this.http
      .post(`${this.base_url}/login`, body)
      .pipe(map((res) => this.setToken(res)));
  }

  logout(): Promise<boolean> {
    if (this.logoutTimerSubscription) {
      this.logoutTimerSubscription.unsubscribe();
    }

    this.token = null;
    this.setUser(null);
    this.setStore(null);
    localStorage.removeItem('authToken');
    localStorage.removeItem('store');
    return this.router.navigateByUrl('/login');
  }

  logoutWarning() {
    const timeout = timer(60 * 1000);

    timeout.subscribe(() => {
      this.dialog.closeAll();
      this.logout();
    });

    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '300px',
      data: { message: 'LOGOUT DUE TO INACTIVITY' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.logoutTimerStarted = false;
        this.startInactivityTimeout();

        return this.verify2().subscribe((res) => this.parseRes(res));
      }
    });
  }

  verify2(): Observable<any> {
    return this.http.get<any>(
      `${this.base_url}/check-state/${this.currentUser._id}`
    );
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('authToken');
    }

    return this.token;
  }

  public getUser(): User {
    return this.currentUser;
  }

  public getStore(): string {
    return this.storeSubject.value;
  }

  public getStoreObject(): Store {
    return this.storeObject.value;
  }

  verify() {
    if (this.isLoggedIn()) {
      return this.http
        .get(`${this.base_url}/check-state/${this.currentUser._id}`)
        .pipe(map((res) => this.parseRes(res)));
    } else {
      const res = { success: false };
      this.logout();
      res['user'] = null;
      return of(res);
    }
  }

  isAuthorized(allowedRoles: string[]): Promise<boolean> {
    if (this.isLoggedIn()) {
      if (this.currentUser) {
        if (!allowedRoles || allowedRoles.length === 0) {
          return Promise.resolve(true);
        } else {
          return Promise.resolve(allowedRoles.includes(this.currentUser.role));
        }
      } else {
        return new Promise((resolve) => {
          this.getMessage().subscribe((user: any) => {
            if (!allowedRoles || allowedRoles.length === 0) {
              resolve(true);
            } else {
              resolve(allowedRoles.includes(user?.role));
            }
          });
        });
      }
    } else {
      return Promise.resolve(false);
    }
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getStoreMessage(): Observable<any> {
    return this.storeSubject.asObservable();
  }

  getStoreObjectMessage(): Observable<any> {
    return this.storeObject.asObservable();
  }

  setToken(res) {
    if (res.success) {
      const user = res.user;
      this.setUser(user);
      const currentStore = this.appConfigService
        .getConfig()
        .stores.find((s) => s._id === res.store);
      this.setStore(currentStore);
      this.token = res.token;
      localStorage.setItem('authToken', this.token);
      localStorage.setItem('store', currentStore?.storeName);
      localStorage.setItem('storeColor', currentStore?.headerColor);
    }
    return res;
  }

  parseRes(res) {
    if (!res.success) {
      this.logout();
      res.user = null;
      if (this.logoutTimerSubscription) {
        this.logoutTimerSubscription.unsubscribe();
      }
    } else {
      this.token = res.token;
      res.user = this.currentUser;

      this.startInactivityTimeout();

      localStorage.setItem('authToken', this.token);
    }
    return res;
  }

  updateToken(res) {
    this.parseRes(res);
  }

  startInactivityTimeout() {
    if (this.logoutTimerStarted) {
      this.logoutTimerSubscription.unsubscribe();
    }
    // if (!this.logoutTimerStarted) {
    this.logoutTimerStarted = true;
    this.logoutTimer = timer(60 * 60 * 1000 - 60 * 1000).pipe(take(1));

    // this.logoutTimer = interval(5000).pipe(take(1));

    this.logoutTimerSubscription = this.logoutTimer
      .pipe(take(1))
      .subscribe(() => {
        this.logoutTimerSubscription.unsubscribe();
        this.logoutWarning();
      });
    // }
  }

  getAllStores(): Observable<any> {
    return this.http.get(`${this.base_url}/getAllStores/`);
  }
}
