import { Pipe, LOCALE_ID } from '@angular/core';
import { formatNumber, formatDate } from '@angular/common';

export class FormatData {

  constructor() {

  }

  static formatCurrency(currency:any) {
    if (typeof currency == 'string') {
      currency = currency.replace(/,/g, '')
    }
    let tmpValue = parseFloat(currency);
    let value = (isNaN(tmpValue)) ? 0 : tmpValue;
    return formatNumber(value, 'en-US', '1.2-2');
  }

  static formatFloat(value:any) {
    if (typeof value == 'string') {
      value = value.replace(/,/g, '')
    }
    let tmpValue = parseFloat(value);
    value = (isNaN(tmpValue)) ? 0 : tmpValue;
    return value;
  }
}
