import { Component, ChangeDetectorRef, Input, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { VendorOrders } from '@models/vendor-order';
import { Store } from '@models/store';
import { VendorOrderService } from '@services/vendor-order.service';
import { AuthService } from '@services/auth.service';
import { RentService } from '@services/rent.service';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-borrowing-vendors',
  templateUrl: './borrowing-vendors.component.html',
  styleUrls: ['./borrowing-vendors.component.css', '../../base/vendors-base/vendors-base.component.css', '../../managed-board.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class BorrowingVendorsComponent implements OnChanges, AfterViewInit {
  @Input() rentingUser: string;
  @Input() borrowingStore: string;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<VendorOrders> = new MatTableDataSource<VendorOrders>();
  displayedColumns = ['vendor', 'count'];
  expandedElement = null;
  dataLoading = false;
  currentStore: Store = new Store();

  constructor(
    public vendorOrderService: VendorOrderService,
    public auth: AuthService,
    public cdRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    private rentService: RentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.borrowingStore && changes.borrowingStore.currentValue) {
      this.getVendorOrders();
    }

    if (changes.rentingUser && changes.rentingUser.currentValue) {
      this.getRents();
    }
  }

  ngAfterViewInit() {
    this.currentStore = this.auth.getStoreObject();
    this.cdRef.detectChanges();
  }

  getVendorOrders() {
    this.dataLoading = true;
    this.vendorOrderService.getBorrowingVendorOrders(this.borrowingStore).subscribe((vendorOrders: VendorOrders[]) => {
      for (let x = 0; x < vendorOrders.length; x++) {
        vendorOrders[x].count = 0;
        for (let y = 0; y < vendorOrders[x].vendorOrders.length; y++) {
          for (let z = 0; z < vendorOrders[x].vendorOrders[y].products.length; z++) {
            vendorOrders[x].count++;
          }
        }
      }

      this.dataSource = new MatTableDataSource<VendorOrders>(vendorOrders);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'vendor':
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.dataLoading = false;

      this.cdRef.detectChanges();
    }, () => {
      this.dataLoading = false;
    });
  }

  getRents() {
    this.dataLoading = true;
    this.rentService.fetchRents(this.rentingUser, this.auth.getStore()).subscribe(rents => {
      const groupedRents = rents.reduce((arr, rent) => {
        if (!arr[rent.product.vendor._id]) {
          arr[rent.product.vendor._id] = {
            vendor: rent.product.vendor,
            count: 0,
            rentingProducts: []
          };
        }
        arr[rent.product.vendor._id].count += rent.qty;
        arr[rent.product.vendor._id].rentingProducts.push({
          ...rent.product,
          quantity: rent.qty,
          rent: rent._id
        });
        return arr;
      }, {});
      const vendorRents = Object.keys(groupedRents).map(vendorId => ({
        _id: vendorId,
        vendor: groupedRents[vendorId].vendor,
        rentingProducts: groupedRents[vendorId].rentingProducts,
        count: groupedRents[vendorId].count
      }));

      this.dataSource = new MatTableDataSource<VendorOrders>(vendorRents);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'vendor':
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.dataLoading = false;
    });
  }

  toggleExpanded($event, element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

  refreshVendorOrders() {
    this.expandedElement = null;
    this.getVendorOrders();
  }
}
