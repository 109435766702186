import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Patient } from '@models/patient';
import { Prescription } from '@models/prescription';
import { Vendor } from '@models/vendor';
import { Store } from '@models/store';
import { Product, ProductQuery } from '@models/product';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { parsePhoneNumber } from 'libphonenumber-js'
import { FormatData } from '@classes/format-data';
import { OrderForm } from '@classes/order-form';
import { PrescriptionForm } from '@classes/prescription-form';

export class PatientForm {

  constructor() {

  }

  static initPatientFormGroup(patient:Patient, auth: AuthService, storeOptions: any[]) {
    let fb = new FormBuilder();
    let phone = patient.phone;
    let phone2 = patient.phone2;
    let cell = patient.cell;
    try {
      phone = (!patient.phone) ? '' : parsePhoneNumber(patient.phone, 'US').formatNational();
      phone2 = (!patient.phone2) ? '' : parsePhoneNumber(patient.phone2, 'US').formatNational();
      cell = (!patient.cell) ? '' : parsePhoneNumber(patient.cell, 'US').formatNational();
    } catch(err) {
      console.log(err)
    }
    
    // let orderFormArray = OrderForm.initOrderFormArray(patient.orders as Order[], auth, storeOptions);
    // let prescriptionFormArray = PrescriptionForm.initPrescriptionFormArray(patient.prescriptions as Prescription[], auth, storeOptions);
    let favoriteLinesFormArray = fb.array(patient.favoriteLines);

    let patientFormGroup:FormGroup = fb.group({
      'prefix': patient.prefix,
      'dob': patient.dob,
      'discountStatus': patient.discountStatus,
      'storeCredit': FormatData.formatCurrency(patient.storeCredit),
      'firstName': patient.firstName,
      'lastName': patient.lastName,
      'address': patient.address,
      'address2': patient.address2,
      'city': patient.city,
      'state': patient.state,
      'zip': patient.zip,
      'phone': phone,
      'phone2': phone2,
      'cell': cell,
      'email': patient.email,
      'since': patient.since,
      'badAddress': patient.badAddress,
      'occupation': patient.occupation,
      'interests': patient.interests,
      'favoriteLines': null,
      'familyMembers': patient.familyMembers,
      'beBackList': patient.beBackList,
      'howDidYouFindUs': patient.howDidYouFindUs,
      'howDidYouFindUsOther': patient.howDidYouFindUsOther,
      'patientNotes': (patient.patientNotes!=null) ? patient.patientNotes : '',
      'sleep': patient.sleep,
      'optOut': patient.optOut,
      'doctorName': patient.doctorName,
      'doctorPhone': patient.doctorPhone,

      'doctorFax': patient.doctorFax,

      'doctorEmail': patient.doctorEmail,
      'lastExam': patient.lastExam,
      'callForRx': patient.callForRx,
      

      // 'orderForms': orderFormArray,
      // 'prescriptionForms': prescriptionFormArray,
      'prescriptionNotes': (patient.prescriptionNotes!=null) ? patient.prescriptionNotes : '',
      'from2020Now': patient.from2020Now,
    });
    patientFormGroup.get('favoriteLines').setValue(patient.favoriteLines);

    return patientFormGroup;
  }
}
