export class Image {
  _id: string;
  image: string;
  thumbnail: string;
  date: string;

  constructor()
  {
    this._id = '';
    this.image = '';
    this.thumbnail = '';
    this.date = '';
  }
}