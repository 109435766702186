import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Component, Inject, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageService } from '@services/image.service';

@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.css']
})
export class ImageEditComponent implements OnInit {
  imageChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageUrl = '';
  loaded = false;
  rotate = 0;
  cropBox: any;

  constructor(
    public dialogRef: MatDialogRef<ImageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageService: ImageService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (this.data.tmp) {
      this.imageService.getTmpImageUrl(this.data.image).subscribe((url) => {
        console.log(url);
        this.imageUrl = url;
        this.loaded = true;
        this.cdRef.detectChanges();
      });
    } else {
      this.imageService.getImageUrl(this.data.image).subscribe((url) => {
        console.log(url);
        this.imageUrl = url;
        this.loaded = true;
        this.cdRef.detectChanges();
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  save() {
      console.log('res');
    this.imageService.cropImage(this.data.image, this.cropBox, this.rotate * 90, this.data.tmp).subscribe((res) => {
      console.log('imageChange');
      console.log(res);
      this.imageChange.emit(true);
      this.dialogRef.close(true);
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event)
    this.cropBox = {
      left: Math.round(event.imagePosition.x1),
      top: Math.round(event.imagePosition.y1),
      width: Math.round(event.width),
      height: Math.round(event.height)
    };
  }

  rotateLeft() {
    if (this.rotate === 0) {
      this.rotate = 3;
    } else {
      this.rotate--;
    }
  }

  rotateRight() {
    if (this.rotate === 3) {
      this.rotate = 0;
    } else {
      this.rotate++;
    }
  }

  reset() {
    this.rotate = 0;
  }
}
