<div *ngIf="!loaded">
	<h3 style="text-align:center;">LOADING ORDERS</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div style="width:100%" [formGroup]="orderForm" *ngIf="loaded">
	<div>
    <button mat-button type="button" (click)="addOrder($event)">
    	<mat-icon>add_box_outline</mat-icon><span>ADD ORDER</span>
    </button>
  </div>

	<mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z2" formArrayName="orders" multiTemplateDataRows>

		<ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 196px"> STATUS </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 196px">
	    	<ng-container *ngIf="order.value.products[0].product.vendor">
		      <button *ngIf="order.value.products && order.value.products.length>0 && orderStatus.indexOf(order.value.status)!=-1" mat-button (click)="clickButton($event, order, i)" [routerLink]="['/admin/managed-board', { outlets: { sub: ['stack', order.value.products[0].product.vendor._id] } }]" style="width:300px;">{{ order.value.status }}</button>

					<button *ngIf="order.value.products.length>0 && awaitingStatus.indexOf(order.value.status)!=-1" mat-button (click)="clickButton($event, order, i)" [routerLink]="['/admin/managed-board', { outlets: { sub: ['awaiting-frames', order.value.products[0].product.vendor._id] } }]" style="width:300px;">{{ order.value.status }}</button>

          <button
						*ngIf="order.value.products.length>0 && awaitingStatus.indexOf(order.value.status)==-1 && orderStatus.indexOf(order.value.status)==-1"
            mat-button
            (click)="clickButton($event, order, i)"
						[routerLink]="['/admin/managed-board', { outlets: { sub: ['awaiting-frames', order.value.products[0].product.vendor._id] } }]" style="width:300px;">{{ order.value.status }}</button>

		  	</ng-container>
				<span *ngIf="!order.value.products[0].product.vendor">{{ order.value.status }}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 230px"> VENDOR </mat-header-cell>
		  <mat-cell *matCellDef="let order; let i=dataIndex" [formGroupName]="i" fxFlex="1 1 230px">
		  	<span *ngIf="orderId!=order.value._id && order.value.products[0].product.vendor"> {{ order.value.products[0].product.vendor.name }} </span>
        <app-autocomplete-vendors placeholder="VENDOR" *ngIf="orderId==order.value._id"
          [(productForm)]="order.controls.products['controls'][0]['controls']['product']" [(fieldOpen)]="fieldOpen"
		    	fxFlex="1 1 90%"></app-autocomplete-vendors>
		  </mat-cell>
		</ng-container>

	  <ng-container matColumnDef="model">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 15%"> MODEL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 15%" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.model}}</span>
        <app-autocomplete-vendor-products [(productForm)]="order.controls.products['controls'][0]['controls']['product']"
          [vendor]="order.value.products[0].product.vendor"
	  			placeholder="MODEL"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="model"
					fxFlex="1 1 90%"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> COLOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 10%" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.color}}</span>
        <app-autocomplete-vendor-products [(productForm)]="order.controls.products['controls'][0]['controls']['product']"
          [vendor]="order.value.products[0].product.vendor"
	  			placeholder="COLOR"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="color"
					fxFlex="1 1 90%"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> SIZE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 50px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id"> {{order.value.products[0].product.size}} </span>
        <app-autocomplete-vendor-products [(productForm)]="order.controls.products['controls'][0]['controls']['product']"
          [vendor]="order.value.products[0].product.vendor"
	  			placeholder="SIZE"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="size"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> TYPE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 60px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id && order.value._id!=''">{{order.value.products[0].type}}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
						<mat-form-field *ngIf="orderId==order.value._id || order.value._id==''" floatLabel="never" style="width:90%">
							<mat-select #formRow placeholder="TYPE" formControlName="type" (click)="clickField2($event, order, i);$event.stopPropagation();" (selectionChange)="changeProductType($event,order)">
								<mat-option *ngFor="let typeOption of productTypeOptions" [value]="typeOption.value">
									{{typeOption.viewValue}}
								</mat-option>
							</mat-select>
		        </mat-form-field>
	        </ng-container>
		    </ng-container>
	    	<!-- <pre>{{order.value.products[0]|json}}</pre> -->
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="invoiceNumber">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 110px"> INVOICE # </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 110px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id && order.value.invoiceNumber">{{order.value.type}}&nbsp;&ndash;&nbsp;{{order.value.invoiceNumber }} </span>


	    	<mat-form-field *ngIf="orderId==order.value._id" floatLabel="always">
				  <mat-select placeholder="TYPE" formControlName="type" style="width:30px;" (click)="clickField2($event, order, i);" (selectionChange)="clickField2($event, order, i)">
						<mat-option *ngFor="let orderTypeOption of orderTypeOptions" [value]="orderTypeOption.value">
							{{ orderTypeOption.viewValue }}
						</mat-option>
					</mat-select>
				</mat-form-field>
	    	<mat-form-field *ngIf="orderId==order.value._id" >
		      <input matInput placeholder="INVOICE #" formControlName="invoiceNumber" (click)="clickField2($event,order, i)">
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 120px"> DATE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 120px" [formGroupName]="i">
	      <span *ngIf="orderId!=order.value._id"> {{ order.value.date | date:'M/d/yyyy': 'GMT' }} </span>

				<mat-form-field *ngIf="orderId==order.value._id" floatLabel="never" style="width:90%">
				  <input #formRow [matDatepicker]="picker" matInput placeholder="DATE" type="text" formControlName="date" (dateChange)="clickDate($event,order,i)">
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        	<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

		 <ng-container matColumnDef="view">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> VIEW </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 50px">
	    	<button mat-button (click)="openImage(order.value.images[0])" *ngIf="order.value.images?.[0]">
          <mat-icon>visibility</mat-icon>
        </button>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 90px"> ACTION </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 90px" [formGroupName]="i">
	    	<mat-form-field style="width:35px;flex: 0 1 35px;" floatLabel="never">
			    <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
			    <mat-label><mat-icon>gesture</mat-icon></mat-label>
			  </mat-form-field>

	    	<button mat-button [matMenuTriggerFor]="menu" class="small"><mat-icon>more_vert</mat-icon></button>
			  <mat-menu #menu="matMenu">
			    <button mat-menu-item type="button" aria-label="PRINT" (click)="printImage(order.value.images);">
			    	<mat-icon>print</mat-icon>
			    	PRINT
			    </button>
			    <button mat-menu-item type="button" [disabled]="order.value.initials=='' || order.value.status == 'RETURNED'" (click)="returnOrder(order);$event.stopPropagation()">
			    	<mat-icon>undo</mat-icon>
			    	RETURN
			    </button>
			    <button mat-menu-item type="button" [disabled]="order.value.initials=='' || order.value.status == 'EXCHANGED'" (click)="exchangeOrder(order);$event.stopPropagation()">
			    	<mat-icon>undo</mat-icon>
			    	EXCHANGE
			    </button>
			    <button mat-menu-item type="button" [disabled]="order.value.initials==''" (click)="deleteOrder(order,i);$event.stopPropagation()">
			    	<mat-icon>delete</mat-icon>
			    	DELETE
			  	</button>
		  	</mat-menu>
	    </mat-cell>
	  </ng-container>



		<ng-container matColumnDef="log">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 300px"> LOG </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 300px" [formGroupName]="i">
	    	<!-- {{ order.value.initials }} -->
	    	<app-log-menu [order]="order" (clickField)="clickField2($event,order,i)"></app-log-menu>
	    </mat-cell>
	  </ng-container>








	  <ng-container matColumnDef="expandedDetail">
	 	  <mat-cell *matCellDef="let order2; let orderIndex=dataIndex;" [formGroupName]="orderIndex" class="expandedCell">

	      <div class="example-element-detail" style="width:100%" [class.mat-elevation-z0]="order2!=expandedElement" [class.mat-elevation-z8]="order2==expandedElement" [@detailExpand]="order2 == expandedElement ? 'expanded' : 'collapsed'">

	      		<mat-table #table [dataSource]="dataSource2[orderIndex]" class="mat-elevation-z2">


						  <ng-container matColumnDef="notes">
						    <mat-header-cell *matHeaderCellDef fxFlex="1 1 50%"> NOTES </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 50%">
						      <span *ngIf="orderId!=order.value._id">{{ order.value.notes }}</span>
						      <mat-form-field *ngIf="orderId==order.value._id" style="width:100%;padding-left:10px;padding-right:10px;" floatLabel="never">
							      <input matInput placeholder="NOTES" formControlName="notes" (click)="clickField2($event, order, orderIndex)">
							    </mat-form-field>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="part">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> PART </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index" class="duty" fxFlex="0 0 60px">
					    		<mat-checkbox formControlName="part" (click)="clickField2($event,order2,orderIndex)" (change)="clickPart($event,order2,orderIndex)"></mat-checkbox>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="specialOrder">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 80px"> SPC. ORD. </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index" class="duty" fxFlex="0 0 80px">
					    		<mat-checkbox formControlName="specialOrder" (click)="clickField2($event,order2,orderIndex)"></mat-checkbox>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="warranty">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 80px"> WARRANTY </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index" class="duty" fxFlex="0 0 80px">
					    		<mat-checkbox formControlName="warranty" (click)="clickField2($event,order2,orderIndex)"></mat-checkbox>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="cost">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 1 60px"> COST </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 1 60px">
						      <span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].cost | currency : 'USD' : 'symbol' : '1.0' }}</span>
						    	<ng-container formGroupName="products">
							    	<ng-container [formGroupName]="0">
								      <mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
								        <input #formRow matInput placeholder="COST" type="text" formControlName="cost" (keyup)="changeCost(order)">
								      </mat-form-field>
								    </ng-container>
							    </ng-container>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="priceLink">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 35px">
						      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula!='c'">link_outline</mat-icon>
						      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="retail">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 1 60px"> RETAIL </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 1 60px">
						    	<span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">
					    			{{order.value.products[0].retail | currency : 'USD' : 'symbol' : '1.0' }}
						    	</span>
						    	<ng-container formGroupName="products">
							    	<ng-container [formGroupName]="0">
								    	<mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
								        <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (keyup)="changeRetail(order)">
								      </mat-form-field>
								    </ng-container>
								  </ng-container>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="duty">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> DUTY </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index" class="duty" fxFlex="0 0 60px">
						    	<ng-container formGroupName="products">
							    	<ng-container [formGroupName]="0">
							    		<mat-checkbox formControlName="duty" (click)="toggleDuty($event,order2,orderIndex)"></mat-checkbox>
							    	</ng-container>
						    	</ng-container>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="retailPlusDuty">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> RETAIL + DUTY </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index" class="duty" fxFlex="0 0 60px">
			    				{{order.value.products[0].retail + 20 | currency : 'USD' : 'symbol' : '1.0' }}
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="action">
						    <mat-header-cell *matHeaderCellDef fxFlex="0 0 150px"></mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 150px">
						    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
							      <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
							      <mat-label><mat-icon>gesture</mat-icon></mat-label>
							    </mat-form-field>
						      <button mat-button [matMenuTriggerFor]="menu" (menuClosed)="clickField2($event, order, orderIndex)"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
						      <mat-menu #menu="matMenu">
					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'ORDERED FRAME',i)" *ngIf="order.value.type!='F'" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">ORDERED FRAME</button>

										<button mat-menu-item type="button" (click)="clickSaveOrder(order,'ORDERED PART',i)" *ngIf="order.value.type=='F'" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">ORDERED PART</button>

					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'ORDERED ON APPROVAL',i)" *ngIf="order.value.type!='F'" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">ORDERED ON APPROVAL</button>

					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'SEND TO STACK',i)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">SEND TO STACK</button>

					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'SAVE',i)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid || order.value._id==''">SAVE</button>



					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'ORDERED PART',i)" *ngIf="order.value.type=='F'" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">SENT OUT</button>



					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'ORDERED ACCESSORY',i)" *ngIf="order.value.type=='A'" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">ORDERED ACCESSORY</button>

					          <button mat-menu-item type="button" (click)="clickSaveOrder(order,'ADDED TO INVENTORY',i)" *ngIf="order.value.specialOrder" style="margin-left:10px;" [disabled]="order.controls.initials.invalid">ADD TO INVENTORY</button>
					        </mat-menu>
						    </mat-cell>
						  </ng-container>

              <mat-header-row *matHeaderRowDef="getDisplayedColumns2(order2)" fxLayout="row"
						  	fxLayoutAlign="start center">
						  </mat-header-row>
              <mat-row *matRowDef="let row; columns: getDisplayedColumns2(order2); let i=index; let even=even; let odd=odd;" fxLayout="row"
                fxLayoutAlign="start center" appHighlight [ngClass]="{rowEven:even, rowOdd:odd}" style="border-left:1px solid #dfdfdf !important;"
						  	(click)="clickOrder($event, row, orderIndex)">
						  </mat-row>
						</mat-table>

				</div>
	    </mat-cell>
	  </ng-container>




    <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row"
	  	fxLayoutAlign="start center">
	  </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="start center"
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" style="border-left:1px solid #dfdfdf !important;"
	  	(click)="toggleExpanded($event, row, i)">
	  </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']"
	  	[class.example-detail-row-expanded]="expandedElement === element"
	  	[class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>

	</mat-table>
</div>
