import { Pipe, Component, OnInit, ViewChild, HostListener, EventEmitter, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, Output, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-vendors-base',
  templateUrl: './vendors-base.component.html',
  styleUrls: ['./vendors-base.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class VendorsBaseComponent implements OnInit {
  @Input() public section: string = 'orders';
  @Output() refreshReturnOrders = new EventEmitter<boolean>();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<VendorOrders> = new MatTableDataSource<VendorOrders>();
  displayedColumns = ['alert', 'vendor', 'count'];
	expandedElement = null;
  validateEmail = Vendor.validateEmail;
  validateUrl = Vendor.validateUrl;
  dataLoading: boolean = false;

  constructor(public orderService: OrderService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit() {
  	this.getNewOrders();
  }

  getNewOrders() {
    this.dataLoading = true;
    this.orderService.getNewOrders(this.section).subscribe(vendorOrders => {
      this.route.paramMap.subscribe(params => {
        let vendorId = params.get('vendorId');
        this.expandedElement = vendorOrders.find(x => x.vendor._id === vendorId);
      })

      this.dataSource = new MatTableDataSource<VendorOrders>(vendorOrders);
      // this.vendorOrders = vendorOrders;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'vendor': 
            // if (item.vendor) {
            //   let vendor: Object = item.vendor;
            //   return vendor['name'];
            // } else {
            //   return item[property];
            // }
          //case 'vendor.name': return item.vendor.name;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.dataLoading = false;
      this.cdRef.detectChanges(); 
      
    });
	}

	toggleExpanded(element, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
      if (this.section == 'returns') {
        this.displayedColumns = ['alert', 'vendor', 'count', 'accountNumber', 'repName', 'returnDetails', 'returnAddress'];
      } else {
        this.displayedColumns = ['alert', 'vendor', 'count', 'accountNumber', 'repName', 'orderProtocol', 'orderInfo'];
      }
    } else {
      this.expandedElement = null;
  		this.displayedColumns = ['alert', 'vendor', 'count'];
    }
  }

  refreshOrders() {
  	this.expandedElement = null;
		this.displayedColumns = ['alert', 'vendor', 'count'];
		this.getNewOrders();
  }

  refreshVendorOrders() {
    this.refreshReturnOrders.emit(true);
  }



}
