<!--<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="SEARCH INVENTORY" (input)="productSearch()">
</mat-form-field>-->

<div *ngIf="dataLoading">
  <h3 style="text-align:center;">LOADING INVENTORY</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<form [formGroup]="productsForm" enctype="multipart/form-data" (ngSubmit)="submit()" *ngIf="dataSource.data">
	  
  <mat-table #table [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumn" matSortDirection="asc" formArrayName="productsFormArray">
    <ng-container matColumnDef="images">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 185px"> IMAGE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index; let d=sortIndex" fxFlex="1 0 185px" [formGroupName]="i">
      	<!--<span *ngIf="productId!=product._id">{{product.images}}</span>-->
      	<!--<img *ngIf="product.images[0] && productId!=product._id" [src]="product.images[0].thumbnail" [id]="product.images[0].id" style="max-width:50px;" [appThumbnail]="['800px','50px',product.images[0].image,product.images[0].thumbnail]">-->


<app-images
          [images]="product.value.product.images"
          type="product"
          [active]="productId==product.value.product._id"
          [productId]="product.value.product._id">
        </app-images>

<!-- 


				<div *ngFor="let image of product.value.product.images; let j = index" style="position:relative;" (mouseenter)="imageMouseEnter(product.controls.product.controls.imageHover);" (mouseleave)="imageMouseLeave($event,product.controls.product.controls.imageHover);">
					<img [src]="image.thumbnail" [id]="image.id" style="max-width:150px;" [appThumbnail]="['800px','150px',image.image,image.thumbnail]">
					<app-image [image]="image"></app-image>

					<mat-icon style="position:absolute;top:-5px;right:-5px;" *ngIf="product.value.product.imageHover && productId==product.value.product._id" (click)="imageDelete(image,i,j,product.value.product._id)">delete_outline</mat-icon>
				</div>
      	 -->

				<span *ngIf="productId==product.value._id && !uploaderProduct.isUploading" floatLabel="never">
          <input #fileInputProduct id="fileInputProduct" type="file" name="photoProduct" accept="image/*" ng2FileSelect [uploader]="uploaderProduct" multiple [hidden]="true"/>
					<label for="fileInputProduct" class="mat-icon-button" (click)="$event.stopPropagation();clickImage($event,product,i)">
						<mat-icon>add_photo_alternate_outline</mat-icon>
					</label>
        </span>
				<mat-spinner mode="determinate" [value]="uploaderProduct.progress" *ngIf="uploaderProduct.isUploading && productId==product.value._id"></mat-spinner>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 10%"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="1 0 10%" [formGroupName]="i">
        {{product.value?.product.vendor.name}}
        <!-- <button *ngIf="product.value.product.vendor" mat-button (click)="clickField($event, product, i)" [routerLink]="['/admin/vendors', product.value.vendor._id]" style="width:90%;">{{product.value.product.vendor.name}}</button> -->
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 0 10%"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="model">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 10%"> MODEL </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index; let row; let even=even; let odd=odd;" fxFlex="1 0 10%" [formGroupName]="i">
      	<span *ngIf="productId!=product.value.product._id">{{product.value.product.model}}</span>
      	<ng-container formGroupName="product">
					<mat-form-field *ngIf="productId==product.value.product._id" floatLabel="never">
	          <input #formRow matInput placeholder="MODEL" type="text" formControlName="model">
	        </mat-form-field>
      	</ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 75px"> COLOR </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="1 0 75px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value.product._id">{{product.value.product.color}}</span>
      	<ng-container formGroupName="product">
					<mat-form-field *ngIf="productId==product.value.product._id" floatLabel="never">
	          <input #formRow matInput placeholder="COLOR" type="text" formControlName="color">
	        </mat-form-field>
	      </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> SIZE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 40px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value.product._id">{{product.value.product.size}}</span>
      	<ng-container formGroupName="product">
					<mat-form-field *ngIf="productId==product.value.product._id" floatLabel="never">
	          <input #formRow matInput placeholder="SIZE" type="text" formControlName="size">
	        </mat-form-field>
	      </ng-container>
      </mat-cell>
    </ng-container>

	  <ng-container matColumnDef="status">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
	    <mat-cell *matCellDef="let product; let i=index; let row;" fxFlex="0 0 40px" [ngClass]="product.value.product.status">
	      <span>&nbsp;</span>
	    </mat-cell>
	  </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 85px"> TYPE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 85px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value.product._id && product.value.product._id!='NEW'">{{product.value.product.type}}</span>
				<mat-form-field *ngIf="productId==product.value.product._id || product.value._id=='NEW'" floatLabel="never">
					<mat-select #formRow placeholder="TYPE" formControlName="type" (selectionChange)="changeProductType($event,product,i)">
						<mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">
							{{typeOption.viewValue}}
						</mat-option>
					</mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 75px"> COST </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 75px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id">{{ product.value.cost | currency }}</span>
				<mat-form-field *ngIf="productId==product.value._id" floatLabel="never">
          <input #formRow matInput placeholder="COST" type="text" formControlName="cost" (keyup)="changeCost($event,product,i+paginator.pageSize*paginator.pageIndex)">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="priceLink">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 35px" [formGroupName]="i">
        <mat-icon (click)="togglePriceLink($event,product,i+paginator.pageSize*paginator.pageIndex)" *ngIf="product.value.pricingFormula!='c'">link_outline</mat-icon>
        <mat-icon (click)="togglePriceLink($event,product,i+paginator.pageSize*paginator.pageIndex)" *ngIf="product.value.pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="retail">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 75px"> RETAIL </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 75px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id">{{ product.value.retail | currency }}</span>
				<mat-form-field *ngIf="productId==product.value._id" floatLabel="never">
          <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (keyup)="changeRetail($event,product,i+paginator.pageSize*paginator.pageIndex)">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="store">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> STORE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 10%" [formGroupName]="i">
        <span>{{product.value.store | storeName }}</span>
      	<!-- <span *ngIf="productId!=product.value._id && product.value.store">{{product.value.store.storeName}}</span>
        <mat-form-field *ngIf="productId==product.value._id" floatLabel="never">
					<mat-select #formRow placeholder="STORE" formControlName="store" (click)="clickField($event,product,index);$event.stopPropagation();">
						<mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption">
							{{storeOption.storeName}}
						</mat-option>
					</mat-select>
        </mat-form-field> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 65px"> QTY. </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 65px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id">{{product.value.quantity}}</span>
				<mat-form-field *ngIf="productId==product.value._id" floatLabel="never">
          <input #formRow matInput placeholder="QTY." type="text" formControlName="quantity">
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="patientStock">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> PATIENT/STOCK </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 10%" [formGroupName]="i">
    		<span>{{product.frame.frameSource}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="patient">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> PATIENT </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 10%" [formGroupName]="i">
      	<span>{{product.patient}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> ORDER DATE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 10%" [formGroupName]="i">
      	<span>{{ product.orderDate | date: 'MM/dd/yyyy'}}</span>
      </mat-cell>
    </ng-container>

 		<ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"></mat-header-cell>
	    <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 100px" [formGroupName]="i">
      	<button mat-button (click)="saveEditedProduct(product,i)"><mat-icon>save</mat-icon><span>SAVE</span></button>
	    </mat-cell>
	  </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"></mat-header-cell>
      <mat-cell *matCellDef="let product; let i=index;" fxFlex="0 0 50px" [formGroupName]="i">
        <button mat-button (click)="deleteProductFromInvoice($event, product, vendorOrder.orders[i], i)"><mat-icon>delete_outline</mat-icon></button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="none center"></mat-header-row>
    <!-- <mat-row *matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="none center" appHighlight [ngClass]="{rowEven:even, rowOdd:odd}"></mat-row> -->
    <mat-row *matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="none center" (click)="clickRow($event,row,i)" [ngClass]="{rowEven:even, rowOdd:odd}"></mat-row>
  </mat-table>

</form>
