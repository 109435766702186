import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectorRef,
  LOCALE_ID,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { formatNumber, formatDate } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';

import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';

import { Order, LogElement } from '@models/order';
import { OrderForm } from '@classes/order-form';
import { OrderService } from '@services/order.service';

import {
  Product,
  ProductElement,
  ProductQuery,
  ProductHistory,
} from '@models/product';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';

import { Vendor } from '@models/vendor';
import { VendorOrder } from '@models/vendor-order';
import { VendorOrderService } from '@services/vendor-order.service';

import { Prescription, PrescriptionAttributes } from '@models/prescription';
import { PrescriptionService } from '@services/prescription.service';

import { AuthService } from '@services/auth.service';
import { User } from '@models/user';

import { FileUploader } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { ImageService } from '@services/image.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { SaveComponent } from '@components/admin/dialog/save/save.component';
import ObjectId from 'bson-objectid';
import { NotificationService } from '@services/notification.service';
import { fromBuffer, fromBase64, fromPath } from 'pdf2pic';
import { combineLatest } from 'rxjs';

const pdfUploadUrl = environment.base_url + '/image/pdf/';

@Component({
  selector: 'app-import-customer-images',
  providers: [PatientService, OrderService, PrescriptionService], //, DropboxService, SocketService],
  templateUrl: './import-customer-images.component.html',
  styleUrls: ['./import-customer-images.component.css'],
})
export class ImportCustomerImagesComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput') fileInput: HTMLInputElement;
  @Input() mode: string;
  user: User;
  patient: Patient;
  orders: Order[] = [];
  patientImages: Object;
  importPatientForm: FormGroup;
  patientId: string;
  public images: any[] = [];
  public uploader: FileUploader = new FileUploader({
    url: pdfUploadUrl,
    itemAlias: 'photo',
    disableMultipart: false,
  });
  // public uploader:FileUploader = new FileUploader({url: URL, itemAlias: 'photo'});
  items: FormArray[] = [];
  saveButtonClicked: Boolean = false;
  typeOptions: Object[] = [
    { viewValue: 'OPTICAL', value: 'O' },
    { viewValue: 'NON-RX/SUN', value: 'N' },
    { viewValue: 'ACCESSORY', value: 'A' },
    { viewValue: 'REPAIR/FIX', value: 'F' },
  ];
  queries: ProductQuery[] = [];
  storeOptions: any[] = [];
  fieldOpen: boolean = false;

  iOS: boolean = false;

  discounts = [
    { value: 'EMPLOYEE', viewValue: 'EMPLOYEE' },
    { value: 'FRIENDSFAMILY', viewValue: 'FRIENDS & FAMILY' },
    { value: 'SENIOR', viewValue: 'SENIOR' },
  ];

  rxTypes = [
    { value: 'BIFOCAL', viewValue: 'BIFOCAL' },
    { value: 'COMPUTER', viewValue: 'COMPUTER' },
    { value: 'DISTANCE', viewValue: 'DISTANCE' },
    { value: 'OCCUPATIONAL', viewValue: 'OCCUPATIONAL' },
    { value: 'PROGRESSIVE', viewValue: 'PROGRESSIVE' },
    { value: 'READING', viewValue: 'READING' },
    { value: 'TRI-FOCAL', viewValue: 'TRI-FOCAL' },
    { value: 'OTHER', viewValue: 'OTHER' },
  ];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private imageService: ImageService,
    private patientService: PatientService,
    private orderService: OrderService,
    private productService: ProductService,
    private prescriptionService: PrescriptionService,
    private storeService: StoreService,
    private vendorOrderService: VendorOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private auth: AuthService,
    private cdRef: ChangeDetectorRef
  ) {
    this.importPatientForm = fb.group({
      discountStatus: '',
      items: this.fb.array([]),
    });

    const appleDevices = ['iPad', 'iPhone', 'iPod'];
    // tslint:disable-next-line: deprecation
    if (appleDevices.indexOf(navigator.platform) > -1) {
      this.iOS = true;
    }
  }

  ngOnInit() {
    this.auth.verify().subscribe((res) => {
      this.user = res['user'];
      if (this.user.role != 'MANAGER' && this.user.role != 'OWNER/ADMIN') {
        this.importPatientForm.controls.discountStatus.disable();
      }
    });

    this.route.params.subscribe((params) => {
      this.patientId = params['id'];
      if (this.patientId) {
        this.route.paramMap
          .pipe(
            switchMap((params: ParamMap) =>
              this.patientService.getPatient(params.get('id'))
            )
          )
          .subscribe((patient) => {
            this.storeService.getAllStores().subscribe((res) => {
              this.storeOptions = res;

              this.setPatient(patient);
            });
          });
      }
    });
  }

  ngAfterViewInit() {
    this.uploader.onAfterAddingFile = (item) => {
      if (item.file.type == 'application/pdf') {
        this.uploader.authToken = `Bearer ${this.auth.getToken()}`;
        item.withCredentials = false;
        this.uploader.options.disableMultipart = false;

        this.uploader.onCompleteAll = () => {};

        this.uploader.onCompleteItem = (
          item: any,
          response: any,
          status: any,
          headers: any
        ) => {
          const imageResponse = JSON.parse(response);
          const images = imageResponse.images;
          if (item.file.type == 'application/pdf') {
            for (let x = 0; x < images.length; x++) {
              this.images.push(images[x]);
              const productQuery = new ProductQuery();
              this.queries.push(productQuery);
              this.addItem(images[x]);
            }
          }
        };

        this.uploader.uploadAll();
      } else {
        const objectKey = new ObjectId().toString();
        this.images.push(objectKey);

        this.uploader.options.disableMultipart = true;

        this.imageService
          .getTmpUploadUrl(objectKey, item.file.type)
          .subscribe((url) => {
            item.url = url;
            item.method = 'PUT';
            item.headers = [{ name: 'Content-Type', value: item.file.type }];
            item.withCredentials = false;
            item.upload();
          });

        this.uploader.onCompleteAll = () => {
          for (let x = 0; x < this.images.length; x++) {
            // this.images.push(this.images[x]);
            const productQuery = new ProductQuery();
            this.queries.push(productQuery);
            this.addItem(this.images[x]);
          }
        };

        this.uploader.onCompleteItem = (
          item: any,
          response: any,
          status: any,
          headers: any
        ) => {};
      }
    };
  }

  getPatient() {
    return this.patientService
      .getPatient(this.patientId)
      .pipe(tap((patient: Patient) => this.setPatient(patient)));
  }

  getNewOrders() {
    this.orderService
      .getPatientOrders(this.patient._id, 'orders', 'RECEIVED')
      .subscribe((res: Order[]) => {
        this.orders = res;
      });
  }

  addItem(image) {
    const control = <FormArray>this.importPatientForm.controls['items'];
    const itemsCtrl = this.initItem(image, new Order());
    control.push(itemsCtrl);
    this.cdRef.detectChanges();
  }

  initItem(image, order: Order): FormGroup {
    const orderFormGroup = this.initOrder(order, '');

    const prescriptionFormGroup: FormGroup = this.fb.group({
      _id: '',
      patientId: '',
      notes: '',
      dateAdded: '',
      dateUpdated: '',
      type: '',
      typeOther: '',
      images: [[]],
      expirationDate: '',
      archived: false,
      left: this.fb.group(new PrescriptionAttributes()),
      right: this.fb.group(new PrescriptionAttributes()),
    });

    const group = this.fb.group({
      image: image,
      itemType: 'Order',
      discountStatus: '',
      order: orderFormGroup,
      prescription: prescriptionFormGroup,
    });

    group.get('itemType').valueChanges.subscribe((itemType) => {
      if (itemType === 'Order') {
        orderFormGroup
          .get('invoiceNumber')
          .setValidators([Validators.required]);
      } else {
        orderFormGroup.get('invoiceNumber').setValidators(null);
      }
      orderFormGroup.get('invoiceNumber').updateValueAndValidity();
    });

    return group;
  }

  initOrder(order: Order, orderId) {
    if (!order.products || !order.products[0]) {
      order.products[0] = new ProductElement();
    }
    const orderFormGroup = OrderForm.initOrderFormGroup(
      order,
      this.auth,
      this.storeOptions
    );
    return orderFormGroup;
  }

  transformDate(date) {
    return formatDate(date, 'MM/dd/yyyy', this.locale);
  }

  canDeactivate() {
    if (this.importPatientForm.dirty && !this.saveButtonClicked) {
      return window.confirm('Discard changes?');
    }

    return true;
  }

  blurDate2(prescriptionForm) {
    prescriptionForm.setValue(prescriptionForm.value);
  }

  validateDate(c: FormControl) {
    const date = new Date(c.value);
    return c.value != null && date instanceof Date && !isNaN(date.valueOf())
      ? null
      : {
          validateDate: {
            valid: false,
          },
        };
  }

  removeItem(i: number) {
    const control = <FormArray>this.importPatientForm.controls['items'];
    control.removeAt(i);
  }

  displayVendorName(vendor: Vendor): string {
    return vendor ? vendor.name : '';
  }

  selectOrder($event: MatSelectChange, form: FormGroup, index: number) {
    const orderForm = form.get('order');
    const orderId = $event.value;
    for (let x = 0; x < this.orders.length; x++) {
      if (this.orders[x]._id == orderId) {
        const items: FormArray = this.importPatientForm.controls
          .items as FormArray;
        const item: FormGroup = items.controls[index] as FormGroup;
        const newItem = this.initItem(item.value.image, this.orders[x]);
        newItem.get('order.products.0.frame.frameSource').setValue('STOCK');
        if (newItem.get('order.products.0.product.vendor')) {
          newItem.get('order.products.0.product.vendor').disable();
          newItem.get('order.products.0.product.model').disable();
          newItem.get('order.products.0.product.color').disable();
          newItem.get('order.products.0.product.size').disable();
          newItem.get('order.products.0.retail').disable();
          newItem.get('order.invoiceNumber').disable();
          items.removeAt(index);
          items.insert(index, newItem);
          this.cdRef.detectChanges();
          break;
        }
      } else if (orderId == 'STOCK' || orderId == 'P.O.F.') {
        const items: FormArray = this.importPatientForm.controls
          .items as FormArray;
        const item: FormGroup = items.controls[index] as FormGroup;

        const newItem = this.initItem(item.value.image, new Order());
        newItem.get('order._id').setValue(orderId);
        newItem.get('order.products.0.frame.frameSource').setValue(orderId);
        items.removeAt(index);
        items.insert(index, newItem);
        this.cdRef.detectChanges();
        break;
      }
    }
  }

  setPatient(patient) {
    this.patient = patient;
    this.getNewOrders();
    this.importPatientForm.reset({
      discountStatus: this.patient.discountStatus,
    });
  }

  updateProductData($event, i) {
    const formArray = <FormArray>this.importPatientForm.controls['items'];
    const formGroup = <FormGroup>formArray.controls[i];
    const vendorControl = <FormControl>formGroup.controls.vendor;
    vendorControl.setValue($event.product.vendor);
  }

  save() {
    if (!this.saveButtonClicked) {
      this.saveButtonClicked = true;
      const formModel = this.importPatientForm.value;
      const patientDiscount =
        this.importPatientForm.get('discountStatus').value;

      this.patient.favoriteLines = this.patient.favoriteLines
        ? this.patient.favoriteLines
        : [];
      this.patient.prescriptionNotes = this.patient.prescriptionNotes
        ? this.patient.prescriptionNotes
        : '';
      const favoriteLines = this.patient.favoriteLines
        ? this.patient.favoriteLines
        : [];
      let prescriptionNotes = this.patient.prescriptionNotes
        ? this.patient.prescriptionNotes
        : '';
      const newOrders = [];
      for (let x = 0; x < this.importPatientForm.value.items.length; x++) {
        const itemForm: FormGroup = this.importPatientForm.get(
          `items.${x}`
        ) as FormGroup;
        const saveItem = itemForm.getRawValue();
        if (saveItem.itemType == 'Order') {
          this.saveOrderItem(saveItem, newOrders, favoriteLines);
        } else if (saveItem.itemType == 'Prescription') {
          console.log(saveItem);
          this.savePrescriptionItem(saveItem);
          if (saveItem.prescription.notes.length > 0) {
            prescriptionNotes =
              (prescriptionNotes.length > 0 ? prescriptionNotes : '') +
              `\r\n<p>${saveItem.prescription.notes}</p>`;
          }
        }
      }

      if (favoriteLines.length > this.patient.favoriteLines.length) {
        this.saveFavoriteLines(favoriteLines);
      }

      if (
        prescriptionNotes != null &&
        prescriptionNotes.length > this.patient.prescriptionNotes.length
      ) {
        this.savePrescriptionNotes(prescriptionNotes);
      }

      this.patientService
        .updatePatientDiscount(this.patientId, { discount: patientDiscount })
        .subscribe((patient) => {});

      if (newOrders.length > 0) {
        this.orderService.addOrdersFromTmp(newOrders).subscribe((res) => {
          this.router.navigate(['/admin/patient', this.patientId]);
          this.notificationService.show('success', 'PATIENT SAVED', 'OK');
        });
      } else {
        this.router.navigate(['/admin/patient', this.patientId]);
        this.notificationService.show('success', 'PATIENT SAVED', 'OK');
      }
    }
  }

  saveOrderItem(saveItem, newOrders, favoriteLines) {
    const product: ProductElement = saveItem.order.products[0];
    product._id = new ObjectId().toString();
    const newOrder: Order = saveItem.order;

    if (saveItem.order.products[0].retail) {
      product.retail =
        typeof saveItem.order.products[0].retail == 'string'
          ? parseFloat(saveItem.order.products[0].retail.replace(/,/g, ''))
          : saveItem.order.products[0].retail;
    } else {
      product.retail = null;
    }
    product.quantity = 1;
    product.frame.frameSource = product.frame.frameSource;
    const vendorObj: Vendor = product.product.vendor as Vendor;
    const vendor = vendorObj?._id;
    product.product.vendor = vendorObj;

    if (saveItem.order._id == 'STOCK' || saveItem.order._id == 'P.O.F.') {
      product.frame.frameSource = saveItem.order._id;
      newOrder._id = null;
    }

    newOrder.patient = this.patientId as string;
    newOrder.date = formatDate(
      Date.now(),
      'yyyy/MM/dd HH:mm:ss.SSS',
      this.locale,
      'PST'
    );
    newOrder.dateAdded = formatDate(
      Date.now(),
      'yyyy/MM/dd HH:mm:ss.SSS',
      this.locale,
      'PST'
    );
    newOrder.dateUpdated = formatDate(
      Date.now(),
      'yyyy/MM/dd HH:mm:ss.SSS',
      this.locale,
      'PST'
    );
    newOrder.archived = false;
    newOrder.notes = saveItem.order.notes.toUpperCase() as string;
    newOrder.images = [saveItem.image];
    newOrder.products = [product];
    newOrder.status = 'DISPENSED';
    newOrder.user = this.auth.currentUser._id;
    newOrder.store = this.auth.getStore();
    newOrder.log =
      newOrder.log && newOrder.log != undefined ? newOrder.log : [];

    const logOrderItem = new LogElement();
    logOrderItem.initials = this.auth.currentUser.initials;
    logOrderItem.user = this.auth.currentUser._id;
    logOrderItem.item = 'SCANNED';
    logOrderItem.notes = saveItem.order.notes.toUpperCase() as string;
    logOrderItem.date = Date.now().toString();

    newOrder.log.push(logOrderItem);

    if (
      product.product.borrowing != undefined &&
      product.product.borrowing.length > 0
    ) {
      for (let x = 0; x < product.product.borrowing.length; x++) {}
    }

    if (
      product.product.borrowing != undefined &&
      product.product.borrowing.length > 0
    ) {
      if (product.product._id) {
        let borrowed = false;
        for (let b = 0; b < product.product.borrowing.length; b++) {
          if (
            product.product.borrowing[b].store == parseInt(this.auth.getStore())
          ) {
            borrowed = true;
            const borrowing = product.product.borrowing[b];

            const logItemProduct = new LogElement();
            logItemProduct.initials = this.auth.currentUser.initials;
            logItemProduct.user = this.auth.currentUser._id;
            logItemProduct.item =
              'SOLD BORROWED FRAME AT STORE ' + this.auth.getStore();
            logItemProduct.notes = '';
            logItemProduct.date = Date.now().toString();

            const logItem = new LogElement();
            logItem.initials = this.auth.currentUser.initials;
            logItem.user = this.auth.currentUser._id;
            logItem.item = 'SOLD BORROWED FRAME';
            logItem.notes = '';
            logItem.date = Date.now().toString();

            this.productService
              .soldBorrowedProduct(
                product.product._id,
                this.auth.getStore(),
                1,
                product.product.store,
                logItemProduct
              )
              .subscribe((product) => {
                console.log('success 1');
              });

            this.productService
              .incrementProductInventoryQuantity(product.product, -1)
              .subscribe((product2) => {
                console.log(product2);
              });

            newOrder._id = new ObjectId().toString();
            newOrders.push(newOrder);

            this.vendorOrderService
              .getProductHistory(product.product._id)
              .subscribe((productHistory: ProductHistory[]) => {
                // productHistory[0].tax
                console.log(productHistory);
                let tax = 0;
                let shipping = 0;
                if (productHistory) {
                  const orders = productHistory
                    .filter((hist) => hist.type == 'order')
                    .sort(function (a, b) {
                      return (
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                      );
                    });
                  if (orders[0]) {
                    tax = orders[0].product['tax'];
                    shipping = orders[0].product['shipping'];
                  }

                  const vendorOrder = new VendorOrder();
                  vendorOrder.date = Date.now().toString();
                  vendorOrder.type = 'borrow';
                  vendorOrder.status = 'SOLD';

                  vendorOrder.tax = tax;
                  // vendorOrder.tax = parseFloat(tax.replace(/,/g, ''));
                  vendorOrder.shipping = shipping;
                  // vendorOrder.shipping = parseFloat(shipping.replace(/,/g, ''));
                  vendorOrder.total = product.product.cost + tax + shipping;

                  // vendorOrder.vendor = vendor;
                  vendorOrder.vendor = null;
                  vendorOrder.user = this.auth.currentUser._id;
                  // vendorOrder.store = borrowing.store.toString();
                  // vendorOrder.borrowingStore = this.auth.getStore();
                  vendorOrder.store = this.auth.getStore();
                  vendorOrder.borrowingStore = product.product.store;
                  vendorOrder.log.unshift(logItem);
                  vendorOrder.products = [product];
                  vendorOrder.orders = [newOrder];

                  this.vendorOrderService
                    .addVendorOrder(vendorOrder)
                    .subscribe((vendorOrder) => {
                      console.log('success 2');
                    });
                }
              });

            break;
          }
        }

        if (!borrowed) {
          this.updateProductInventory(product, saveItem);
        }
      }
    } else if (
      product.frame.frameSource == 'STOCK' &&
      saveItem.order.type != 'F'
    ) {
      this.updateProductInventory(product, saveItem);
    }

    if (newOrder._id) {
      this.orderService.updateOrderDispensed(newOrder).subscribe((res) => {});
    } else {
      newOrders.push(newOrder);
    }

    if (saveItem.favoriteLines) {
      favoriteLines = favoriteLines.concat(saveItem.favoriteLines);
    }
  }

  updateProductInventory(product, saveItem) {
    if (saveItem.order.specialOrder) {
      this.productService
        .decrementSpecialOrderProductInventoryQuantity(product.product, -1)
        .subscribe((product2) => {
          console.log(product2);
        });
    } else {
      console.log('decrement');
      console.log(product.product);

      this.productService
        .incrementProductInventoryQuantity(product.product, -1)
        .subscribe((product2) => {
          console.log(product2);

          if (
            product.product.status == 'green' ||
            product.product.status == 'yellow'
          ) {
            this.productService
              .getProduct(product.product._id)
              .subscribe((checkProduct: Product) => {
                if (checkProduct.quantity < 1) {
                  this.addStockOrder(product, saveItem.order.type);
                }
              });
          }
        });
    }
  }

  savePrescriptionItem(saveItem: any) {
    const date = new Date(saveItem.prescription.expirationDate);
    const prescriptionDate =
      saveItem.prescription.expirationDate != null &&
      saveItem.prescription.expirationDate != '' &&
      date instanceof Date &&
      !isNaN(date.valueOf())
        ? date.toString()
        : null;

    const newPrescription: Prescription = {
      _id: '',
      patientId: this.patientId as string,
      dateAdded: prescriptionDate,
      dateUpdated: prescriptionDate,
      expirationDate: prescriptionDate,
      examDate: prescriptionDate,
      oneYearExpiration: false,
      archived: false,
      type: saveItem.prescription.type as string,
      typeOther: saveItem.prescription.typeOther as string,
      notes: saveItem.prescription.notes as string,
      images: [saveItem.image],
      left: saveItem.prescription.left as PrescriptionAttributes,
      right: saveItem.prescription.right as PrescriptionAttributes,
    };

    this.savePrescription(newPrescription);
  }

  saveOrders(newOrders) {
    this.orderService.addOrdersFromTmp(newOrders).subscribe((res) => {
      console.log(res);
    });
  }

  addStockOrder(product: ProductElement, type: string) {
    product.frame.frameSource = 'STOCK';

    const logItem = new LogElement();
    logItem.initials = this.auth.currentUser.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'ORDER FOR STOCK';
    logItem.notes = '';
    logItem.date = Date.now().toString();

    const newOrder: Order = {
      _id: '',
      invoiceNumber: '',
      type: type,
      restockOrder: true,
      patient: this.patientId as string,
      date: formatDate(
        Date.now(),
        'yyyy/MM/dd HH:mm:ss.SSS',
        this.locale,
        'PST'
      ),
      dateAdded: formatDate(
        Date.now(),
        'yyyy/MM/dd HH:mm:ss.SSS',
        this.locale,
        'PST'
      ),
      dateUpdated: formatDate(
        Date.now(),
        'yyyy/MM/dd HH:mm:ss.SSS',
        this.locale,
        'PST'
      ),
      archived: false,
      notes: '',
      todo: 'REORDER',
      images: [],
      products: [product],
      status: '',
      user: this.auth.currentUser._id,
      log: [logItem],
      store: this.auth.getStore(),
    };

    this.orderService
      .addOrder(newOrder)
      .subscribe((savedNewOrderArray: Order[]) => {
        console.log(savedNewOrderArray);
        // let savedNewOrder = savedNewOrderArray[0];
      });
  }

  saveFavoriteLines(favoriteLines) {
    const favoriteLinesObj = { favoriteLines: favoriteLines };
    this.patientService
      .updateFavoriteLines(this.patientId, favoriteLinesObj)
      .subscribe((res) => {});
  }

  savePrescriptionNotes(prescriptionNotes) {
    const prescriptionNotesObj = {
      prescriptionNotes: prescriptionNotes.toUpperCase(),
    };
    this.prescriptionService
      .updatePrescriptionNotes(this.patientId, prescriptionNotesObj)
      .subscribe((res) => {});
  }

  savePrescription(newPrescription) {
    this.prescriptionService
      .addPrescriptionFromTmp(newPrescription)
      .pipe(switchMap((res) => this.expirePrescriptions()))
      .subscribe((res) => {});
  }

  expirePrescriptions() {
    // Expire any existing prescriptions with expiration dates in the future after new prescription is added
    const prescriptionsToExpire = this.patient.prescriptions.filter(
      (presc: Prescription) =>
        new Date(presc.expirationDate).getTime() > new Date().getTime()
    );
    console.log(prescriptionsToExpire);
    const expirePrescriptions = this.patient.prescriptions
      .filter(
        (presc: Prescription) =>
          new Date(presc.expirationDate).getTime() > new Date().getTime()
      )
      .map((presc: Prescription) =>
        this.prescriptionService.expirePrescription(presc._id)
      );
    return combineLatest(expirePrescriptions).pipe(
      switchMap(() => this.getPatient())
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SaveComponent, {
      width: '250px',
      data: { message: 'PATIENT SAVED' },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  updateProduct($event: ProductQuery, order: FormGroup, productIndex) {
    this.queries[productIndex] = $event;
    const query = this.queries[productIndex];
    if (query.model.selected && query.color.selected && query.size.selected) {
      const productElements: FormArray = order.get('products') as FormArray;
      const productElement: FormGroup = productElements.at(0) as FormGroup;
      const product: FormGroup = productElement.get('product') as FormGroup;
      const vendor: Vendor = product.value.vendor;
      this.productService
        .queryVendorProductsFields(vendor._id, $event)
        .subscribe((res) => {
          delete res[0].__v;
          const selectedProduct: Product = res[0] as Product;
          // product.setValue(selectedProduct);
          const productFormGroup =
            OrderForm.initProductFormGroup(selectedProduct);
          productElement.setControl('product', productFormGroup);

          productElement.get('cost').setValue(selectedProduct.cost);
          productElement
            .get('pricingFormula')
            .setValue(selectedProduct.pricingFormula);
          productElement.get('retail').setValue(selectedProduct.retail);
          productElement.get('type').setValue(selectedProduct.type);
          this.formatCurrency(null, productElement.get('cost'));
          this.formatCurrency(null, productElement.get('retail'));

          if (selectedProduct.type == 'RX' && order.get('type').value != 'F') {
            order.get('type').setValue('O');
          } else if (
            selectedProduct.type == 'SUN' &&
            order.get('type').value != 'F'
          ) {
            order.get('type').setValue('N');
          }
          this.cdRef.detectChanges();
        });
    }
  }

  formatCurrency($event, control) {
    const tmpValue = parseFloat(control.value);
    const value = isNaN(tmpValue) ? 0 : tmpValue;
    control.setValue(formatNumber(value, 'en-US', '1.2-2'));
  }

  updateQuery($event, productIndex) {
    this.queries[productIndex] = $event;
  }
}
