<mat-card class="mb-25 ao-card">
  <mat-card-header class="d-block pb-2">
    <div class="d-flex align-items-center justify-between w-100">
      <div class="flex-grow-1">
        <mat-slide-toggle [(ngModel)]="showAll" (ngModelChange)="viewType()">Show All</mat-slide-toggle>
      </div>
      <div>
        <form class="search-box relative">
          <input
            type="text"
            [formControl]="searchControl"
            class="input-search d-block dark"
            placeholder="Search Vendors"
          />
          <button type="submit" class="icon">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="mat-mdc-card-content">
    <div class="mat-elevation-z0 mat-table mt-18">
      <table
        mat-table
        matSort
        class="ao-table"
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell"
            mat-sort-header="name"
          >
            Vendor
          </th>
          <td mat-cell *matCellDef="let element" class="ao-table-cell">
            <div class="d-flex align-items-center name-info">
              {{ element.name }}
            </div>
          </td>
        </ng-container>

        <!-- Unpaid Column -->
        <ng-container matColumnDef="unpaid">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell"
            mat-sort-header="unpaid"
          >
            Unpaid
          </th>
          <td mat-cell *matCellDef="let element" class="ao-table-cell">
            {{ element.unpaid ?? 0 }}
          </td>
        </ng-container>

        <!-- Paid Column -->
        <ng-container matColumnDef="paid">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell"
            mat-sort-header="paid"
          >
            Paid
          </th>
          <td mat-cell *matCellDef="let element" class="ao-table-cell">
            {{ element.paid ?? 0 }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="ao-header-row th-light"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="ao-table-row pointer"
          (click)="viewVendorOrders(row.vendorId, row.distributor)"
        ></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
