import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { Vendor, VendorInventory } from '@models/vendor';
import { ApiResponse } from '@classes/api-response';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  private base_url = environment.base_url + '/vendor';

  constructor(public http: HttpClient, private auth: AuthService) {}

  getAllVendors(store = null): Observable<Vendor[]> {
    if (!store) {
      store = this.auth.getStore();
    }
    return this.http.get(`${this.base_url}/store/${store}`).pipe(
      map((res: ApiResponse<Vendor[]>) => {
        this.auth.updateToken(res);
        return res.vendors;
      })
    );
  }

  getVendorList(store = null): Observable<Vendor[]> {
    if (!store) {
      store = this.auth.getStore();
    }
    return this.http.get(`${this.base_url}s/list/store/${store}`).pipe(
      map((res: ApiResponse<Vendor[]>) => {
        this.auth.updateToken(res);
        return res.vendors;
      })
    );
  }

  getVendor(vendorId: any): Observable<Vendor> {
    let store = this.auth.getStore();
    return this.http.get(`${this.base_url}/${vendorId}/store/${store}`).pipe(
      map((res: ApiResponse<Vendor>) => {
        this.auth.updateToken(res);
        return res.vendor;
      })
    );
  }

  getVendorNames(vendorIds: Array<string>) {
    return this.http
      .get(`${this.base_url}/store/${this.auth.store}/details/vendors`, {
        params: {
          vendorIds: vendorIds.join(','),
        },
      })
      .pipe(
        map((res: ApiResponse<any>) => {
          this.auth.updateToken(res);
          return res.vendors;
        })
      );
  }

  getVendorProductQuantities(): Observable<any[]> {
    let store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/getVendorProductQuantities/store/${store}`)
      .pipe(
        map((res: ApiResponse<any[]>) => {
          this.auth.updateToken(res);
          return res.vendorInventory;
        })
      );
  }

  getVendorInventory(
    vendor?: Vendor,
    filterString?: string,
    stockOption?: string,
    store?: any
  ): Observable<VendorInventory[]> {
    if (!store) {
      store = this.auth.getStore();
    }
    let body = JSON.stringify({
      vendor: vendor,
      filter: filterString,
      stockOption: stockOption,
    });
    return this.http
      .post(`${this.base_url}/getVendorInventory/store/${store}`, body)
      .pipe(
        map((res: ApiResponse<VendorInventory[]>) => {
          this.auth.updateToken(res);
          return res.vendorInventory;
        })
      );
  }

  addVendor(vendor: Vendor): Observable<Vendor> {
    vendor.store = this.auth.getStore();
    let body = JSON.stringify(vendor);
    return this.http.post(`${this.base_url}`, body).pipe(
      map((res: ApiResponse<Vendor>) => {
        this.auth.updateToken(res);
        return res.vendor;
      })
    );
  }

  updateVendor(vendorId: any, vendor: Vendor): Observable<Vendor> {
    vendor.store = this.auth.getStore();
    let body = JSON.stringify(vendor);
    return this.http.put(`${this.base_url}/${vendorId}`, body).pipe(
      map((res: ApiResponse<Vendor>) => {
        this.auth.updateToken(res);
        return res.vendor;
      })
    );
  }

  addImage(vendorId: any, imageId: any): Observable<Vendor> {
    let body = JSON.stringify({ imageId: imageId });
    return this.http.post(`${this.base_url}/${vendorId}/image`, body).pipe(
      map((res: ApiResponse<Vendor>) => {
        this.auth.updateToken(res);
        return res.vendor;
      })
    );
  }

  deleteVendor(vendorId: any): Observable<Vendor> {
    return this.http.delete(`${this.base_url}/${vendorId}`).pipe(
      map((res: ApiResponse<Vendor>) => {
        this.auth.updateToken(res);
        return res.vendor;
      })
    );
  }

  deleteImage(vendorId: any, imageId: any): Observable<any> {
    return this.http
      .delete(`${this.base_url}/${vendorId}/image/${imageId}`)
      .pipe(
        map((res: ApiResponse<Vendor>) => {
          this.auth.updateToken(res);
          return res.vendor;
        })
      );
  }

  checkVendorForBorrowing(vendorId: any, borrowingStore: any): Observable<any> {
    let store = this.auth.getStore();
    return this.http
      .get(
        `${this.base_url}/checkForBorrowing/${vendorId}/store/${store}/borrowingStore/${borrowingStore}`
      )
      .pipe(
        map((res: ApiResponse<Vendor>) => {
          this.auth.updateToken(res);
          return res;
        })
      );
  }

  addBorrowingVendor(vendorId: any, borrowingStore: any): Observable<any> {
    let store = this.auth.getStore();
    return this.http
      .get(
        `${this.base_url}/addBorrowingVendor/${vendorId}/store/${store}/borrowingStore/${borrowingStore}`
      )
      .pipe(
        map((res: ApiResponse<Vendor>) => {
          this.auth.updateToken(res);
          return res;
        })
      );
  }

  getVendorDistributor(vendorId: string): Observable<any> {
    const store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/${vendorId}/store/${store}/distributor`)
      .pipe(
        map((res: ApiResponse<Vendor>) => {
          this.auth.updateToken(res);
          return res;
        })
      );
  }
}
