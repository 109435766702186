import { Component, Inject, LOCALE_ID, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LogElement } from '@models/order';
import { VendorOrders } from '@models/vendor-order';
import { Product } from '@models/product';
import { AuthService } from '@services/auth.service';
import { ProductService } from '@services/product.service';
import { VendorService } from '@services/vendor.service';
import { StoreService } from '@services/store.service';
import { OrderService } from '@services/order.service';
import { ImageService } from '@services/image.service';
import { RentService } from '@services/rent.service';
import { Store } from '@models/store';
import { formatNumber } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { InventoryBaseComponent } from '@components/admin/inventory/inventory-base/inventory-base.component';

@Component({
  selector: 'app-borrowing-products',
  templateUrl: './borrowing-products.component.html',
  styleUrls: ['../../managed-board.component.css', './borrowing-products.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class BorrowingProductsComponent extends InventoryBaseComponent implements OnInit {
  @Input() public vendorOrders: VendorOrders;
	@Input() public borrowingStore: any;
  @Input() rentingUser;
 	@Output() refreshVendorOrders = new EventEmitter<boolean>();

 	vendorOrdersForm: FormGroup;
  defaultSortColumn = '';
	// dataSource = new MatTableDataSource<Product>();
	displayedColumns: string[] = ['image', 'model', 'color', 'size', 'type', 'status', 'cost', 'retail', 'quantity', 'action'];
	fieldOpen = false;
	vendorOrderId: string;
	vendorOrder: FormGroup;
  // selection: SelectionModel<Product> = new SelectionModel<Product>(true, []);
  dataLoading = false;
	expandedElement = null;
  // productsForm: FormGroup;
  // imageUrls: any[] = [];
  currentStore: Store = new Store();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public vendorService: VendorService,
    public storeService: StoreService,
    public auth: AuthService,
    public productService: ProductService,
    public orderService: OrderService,
    public imageService:ImageService,
    public fb: FormBuilder,
    public cdRef:ChangeDetectorRef,
    public dialog: MatDialog,
    private rentService: RentService
  ) {
    super(locale, vendorService, storeService, auth, productService, orderService, imageService, fb, cdRef, dialog);

    this.productsForm = this.fb.group({
      productsFormArray: this.fb.array([]),
      search: '',
      showStock: 'all'
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.currentStore = this.auth.getStoreObject();
    if (this.borrowingStore) {
  	  this.setBorrowingProducts();
    }
    if (this.rentingUser) {
      this.setRentingProducts();
    }
	}

  setBorrowingProducts() {
    let products:Product[] = [];
  	for (let x = 0; x < this.vendorOrders.vendorOrders.length; x++) {
  		for (let y = 0; y < this.vendorOrders.vendorOrders[x].products.length; y++) {
  			products.push(this.vendorOrders.vendorOrders[x].products[y].product)
  		}
  	}
    console.log(products)
    this.setProductForms(products).subscribe(() => {
      this.cdRef.detectChanges();
    });

    // this.dataSource = new MatTableDataSource<Product>(products);
		this.cdRef.detectChanges();
  }

  setRentingProducts() {
    this.setProductForms(this.vendorOrders.rentingProducts).subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  returnBorrowedProduct($event, product) {
    let logItem = new LogElement();
    logItem.initials = this.auth.currentUser.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'RETURN BORROWED FRAME FROM STORE ' + this.borrowingStore;
    logItem.notes = '';
    logItem.date = Date.now().toString();

    let quantity = 1;

    let borrowingStore = (this.borrowingStore == '0') ? this.auth.getStore() : this.borrowingStore;
    let borrowedFromStore = (this.borrowingStore == '0') ? product.value.store : this.auth.getStore();

    this.productService.returnBorrowedProduct(product.value._id, borrowingStore, quantity, borrowedFromStore, logItem).subscribe((product2) => {
      let dataIndex = this.dataSource.data.findIndex(d => d === product);
      this.removeProduct(dataIndex);
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  returnRentingProduct(_, product) {
    this.rentService.returnProduct(product.value.rent).subscribe(() => {
      this.refreshVendorOrders.next(true);
    });
  }

  markAsSold($event, product) {
    //
  }

//   setProductForms(products:Product[], showStock = 'all'):Observable<any> {
//     let imageIds = [];
//     for (var x = 0; x < products.length; x++) {
//         imageIds = imageIds.concat(products[x].images);
//     }

//     return this.imageService.getBulkThumbnailUrls(imageIds, this.imageService.getThumbnailWidth('product')).pipe(
//       map((urls) => {
//         this.imageUrls = urls;
//       }),
//       map(() => {
//         let productFormArray = ProductForm.initProductFormArray(products, this.auth, this.storeService.stores);
//         this.productsForm = this.fb.group({
//           productsFormArray: productFormArray,
//           search: '',
//           showStock: showStock,
//           multipleFields: this.fb.group({
//             field: '',
//             status: '',
//             type: '',
//             cost: '',
//             retail: '',
//             quantity: ''
//           })
//         });
// console.log(this.productsForm.controls.productsFormArray['controls'])
//         this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
//       })
//     );
//   }

//   setDataSource(dataFormGroups: FormGroup[]) {
//     this.dataSource = new MatTableDataSource<FormGroup>(dataFormGroups);
//     // ProductSort.setFilterPredicate(this.dataSource, this.selection);
//     // ProductSort.filterProductStock(this.dataSource, this.productsForm);
//     // ProductSort.setSorter(this.dataSource, this.sort);
//     this.cdRef.detectChanges();
//   }

  formatCurrency($event,control) {
  	let tmpValue = parseFloat(control.value);
  	let value = (isNaN(tmpValue)) ? 0 : tmpValue;
    control.setValue(formatNumber(value, 'en-US', '1.2-2'));
  }

  formatFloat(value:any) {
    let tmpValue = parseFloat(value);
    value = (isNaN(tmpValue)) ? 0 : tmpValue;
    return value;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    this.calculateTotal();
  }

  toggleSelect(order) {
    this.selection.toggle(order)
    this.calculateTotal();
  }

  calculateTotal() {
    // let total = 0;
    // for (let x = 0; x < this.selection.selected.length; x++) {
    //   let id = this.selection.selected[x].value._id;
    //   let cost = this.productCosts[id];
    //   total = total + cost;
    //   // total = total + parseFloat(this.selection.selected[x].value.products[0].cost);
    // }
    // let tax = parseFloat(this.orderForm.value.tax);
    // let shipping = parseFloat(this.orderForm.value.shipping);
    // if (!isNaN(tax)) {
    //   total = total + tax;
    // }
    // if (!isNaN(shipping)) {
    //   total = total + shipping;
    // }
    // this.orderForm.get('total').setValue(total);
    // this.cdRef.detectChanges();
  }


  getTotalCost() {
    if (this.vendorOrders.vendorOrders) {
      return this.dataSource.filteredData.reduce((acc, formGroup:FormGroup) => acc + formGroup.value.cost * formGroup.value.quantity, 0);
      // return this.vendorInventory.products.reduce((acc, value) => acc + value.cost * value.quantity, 0);
    } else {
      return 0;
    }
  }

  getTotalQty() {
    if (this.vendorOrders.vendorOrders) {
      return this.dataSource.filteredData.map(t => t.value.quantity).reduce((acc, value) => { return (parseFloat(value)) ? acc + parseFloat(value) : acc}, 0);
      // return this._vendorInventory.products.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }
}
