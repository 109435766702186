import { Component, OnInit, ChangeDetectorRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '@services/auth.service';
import { Patient } from '@models/patient';
import { Prescription } from '@models/prescription';
import { OrderService } from '@services/order.service';
import { PrescriptionService } from '@services/prescription.service';
import { StoreService } from '@services/store.service';
import { PrescriptionForm } from '@classes/prescription-form';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { map } from 'rxjs';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PrescriptionsComponent implements OnInit {
  @Input() public patient: Patient;
  loading: boolean = false;
  displayedColumns = [
    'status',
    'rxType',
    'examDate',
    'doctor',
    'hide',
    'compare',
    'view',
  ];
  formGroup: FormGroup;
  dataSource = new MatTableDataSource<FormGroup>();
  expandedElement = null;
  fieldOpen: boolean = false;
  prescriptions: Array<Prescription> = [];

  @Input() selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(
    true,
    []
  );
  // @Output() selectionChange = new EventEmitter<SelectionModel<FormGroup>>();

  constructor(
    public auth: AuthService,
    public orderService: OrderService,
    public prescriptionService: PrescriptionService,
    public storeService: StoreService,
    public cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.formGroup = this.fb.group({
      prescriptions: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    const patientId = this.route.snapshot.params.id;
    if (!patientId) {
      this.loading = false;
      return;
    } else {
      this.prescriptionService
        .getPatientPrescriptions(this.patient._id)
        .pipe(
          map((prescriptions) =>
            prescriptions
              // .filter((p) => !p.hide)
              .sort(
                (a, b) =>
                  new Date(b.dateUpdated).getTime() -
                  new Date(a.dateUpdated).getTime()
              )
          )
        )
        .subscribe((prescriptions) => {
          this.prescriptions = prescriptions;
          this.setPrescriptionsForm(prescriptions);
          this.setDataSource(this.formGroup.controls.prescriptions['controls']);
          this.loading = false;
          this.cdRef.detectChanges();
        });
    }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (!changes.patient.firstChange && changes.patient.currentValue.prescriptions.length > 0) {
  //     this.setPrescriptionsForm(changes.patient.currentValue.prescriptions);
  //     this.setDataSource(this.formGroup.controls.prescriptions['controls']);
  //     this.cdRef.detectChanges();
  //   }
  // }

  setPrescriptionsForm(prescriptions) {
    const prescriptionFormArray =
      PrescriptionForm.initPrescriptionFormArray(prescriptions);
    this.formGroup = this.fb.group({
      prescriptions: prescriptionFormArray,
    });
  }

  setDataSource(dataFormGroups: FormGroup[]) {
    this.dataSource = new MatTableDataSource<FormGroup>(dataFormGroups);
    // ProductSort.setFilterPredicate(this.dataSource, this.selection);
    // ProductSort.filterProductStock(this.dataSource, this.productsForm);
    // ProductSort.setSorter(this.dataSource, this.sort);
    this.cdRef.detectChanges();
  }

  toggleExpanded(formGroup: FormGroup) {
    if (this.expandedElement !== formGroup) {
      this.expandedElement = formGroup;
    } else {
      this.expandedElement = null;
    }
    this.cdRef.detectChanges();
  }

  isExpired(prescription: FormGroup) {
    const { examDate, oneYearExpiration } = prescription.getRawValue();
    if (!examDate) { return true; }
    const dateOfExam = new Date(examDate);
    const dateOfExamYear = dateOfExam.getFullYear();
    const dateOfExamMonth = dateOfExam.getMonth();
    const dateOfExamDay = dateOfExam.getDate();
    const yearOffset = oneYearExpiration ? 1 : 2;
    return new Date(dateOfExamYear + yearOffset, dateOfExamMonth, dateOfExamDay) < new Date();
  }

  toggleSelect(prescription) {
    this.selection.toggle(prescription);
    // this.selectionChange.emit(this.selection);
    this.cdRef.detectChanges();
  }

  deletePrescription(index: number): void {
    const prescriptions: FormArray = this.formGroup.get(
      'prescriptions'
    ) as FormArray;
    prescriptions.removeAt(index);
    this.setDataSource(this.formGroup.controls.prescriptions['controls']);
  }

  setAsOrder(index: number): void {
    const prescriptions: FormArray = this.formGroup.get(
      'prescriptions'
    ) as FormArray;
    prescriptions.removeAt(index);
    this.cdRef.detectChanges();
  }

  onHidePrescription(prescription: FormGroup) {
    const index = this.prescriptions.findIndex(
      (p) => p._id === prescription.value._id
    );
    const prescriptions: FormArray = this.formGroup.get(
      'prescriptions'
    ) as FormArray;
    prescriptions.removeAt(index);
    this.prescriptions.splice(index, 1);
    this.setDataSource(this.formGroup.controls.prescriptions['controls']);
    this.prescriptionService
      .hidePrescription(prescription.value._id)
      .subscribe();
  }
}
