<div *ngIf="order" class="row justify-content-center">
  <div>
    <mat-card class="mb-25 ao-card">
      <mat-card-header class="mat-mdc-card-header border-bottom">
        <div class="top d-flex align-items-center w-100">
          <div class="flex-grow-1">
            <h3 *ngIf="vendor?.images">
              <app-product-image
                *ngIf="vendor?.images?.length"
                [image]="vendor.images[0]"
                [uploads]="true"
                class="logo"
              ></app-product-image>
            </h3>
          </div>
          <ol class="breadcrumb mb-0 ps-0">
            <li *ngIf="vendor" class="breadcrumb-item">
              <i class="ri-home-8-line"></i>
              {{ distributor?.name ?? vendor.name | case }}
            </li>
            <li *ngIf="vendor" class="breadcrumb-item">
              <a (click)="viewVendorOrders()">
                <i class="ri-home-8-line"></i>
                Invoices
              </a>
            </li>
            <li
              *ngIf="order?.invoiceNumber"
              class="breadcrumb-item fw-semibold"
            >
              {{ order.invoiceNumber }}
            </li>
          </ol>
        </div>
      </mat-card-header>
      <!-- Return Frame{{ framesSelected ? 's' : '' }} to inventory -->
      <mat-card-content class="mat-mdc-card-content">
        <div class="mat-table">
          <table mat-table [dataSource]="dataSource" class="ao-table">
            <ng-container matColumnDef="toggleSpacer">
              <td mat-header-cell *matHeaderCellDef class="center no-bg">
                <span class="actions-button-sm">
                  <button *ngIf="framesSelected" [matMenuTriggerFor]="menu" class="btn modify-button">
                    <mat-icon>more_vert</mat-icon> Modify
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item type="button" (click)="removeFrames()" style="margin-left:10px;">RETURN TO INVENTORY</button>
                  </mat-menu>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="hspacer">
              <ng-container *ngIf="fourColumns; else fourCol">
                <td
                mat-header-cell
                *matHeaderCellDef
                class="center no-bg"
                colspan="3"
              ></td>
            </ng-container>
            <ng-template #fourCol>
              <td mat-header-cell *matHeaderCellDef class="center no-bg" colspan="4"></td>
            </ng-template>

            </ng-container>

            <ng-container matColumnDef="hstatus">
              <td
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center no-bg"
              >
                {{ order.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hinvoice">
                <ng-container *ngIf="isPaid">
                <td
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center no-bg"
              >
                {{ order.invoiceNumber }}
              </td>
              </ng-container>

              <ng-container *ngIf="!isPaid">
              <td
                mat-header-cell
                *matHeaderCellDef
                class="ao-table-cell td-input focus focus-input selected-input no-bg"
              >
                <div [formGroup]="orderForm" class="inpt lg">
                  <input
                    class="input"
                    type="text"
                    formControlName="invoiceNumber"
                    placeholder="Invoice Number"
                  />
                </div>
              </td>
              </ng-container>
            </ng-container>

            <ng-container matColumnDef="hdate">
              <td
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center no-bg"
              >
                {{ order.date | date: 'shortDate' }}
              </td>
            </ng-container>

            <!-- Frame Select -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef class="ao-header-cell center th-dark">
                <span class="ao-checkbox">
                  <input type="checkbox" [checked]="allSelected" (click)="selectAllProducts($event)" class="check" />
                </span>
              </th>
              <td mat-cell *matCellDef="let element" class="ao-table-cell center">
                <span class="ao-checkbox">
                  <input type="checkbox" [checked]="selectedFrames[element.objectId]" (click)="selectProduct($event, element)" class="check" />
                </span>
              </td>
            </ng-container>

            <!-- Image Column -->
            <ng-container matColumnDef="image">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Image
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                <app-product-image
                  *ngIf="element.images?.length"
                  [image]="element.images[0]"
                ></app-product-image>
              </td>
            </ng-container>

            <!-- Vendor Column -->
            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Vendor
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                <div class="d-flex align-items-center name-info">
                  {{ element.name }}
                </div>
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="model">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Model
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                {{ element.model | case: 'upper' }}
              </td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="color">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Color
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                {{ element.color }}
              </td>
            </ng-container>

            <!-- Size Column -->
            <ng-container matColumnDef="size">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Size
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                {{ element.size }}
              </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Type
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                {{ element.type }}
              </td>
            </ng-container>

            <!-- Tax Column -->
            <ng-container matColumnDef="tax">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Tax
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                <div class="inpt" *ngIf="!isPaid && !isCredit">
                  <app-number-input
                    classNames="product-input"
                    [value]="element.tax ?? 0"
                    [alwaysNegative]="isCredit"
                    (valueChange)="
                      updateProduct(
                        element.uniqueId,
                        $event,
                        'tax'
                      )
                    "
                  >
                  </app-number-input>
                </div>
                <strong *ngIf="isPaid || isCredit">{{ element.tax | currency }}</strong>
              </td>
            </ng-container>

            <!-- Shipping Column -->
            <ng-container matColumnDef="shipping">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Shipping
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                <div class="inpt" *ngIf="!isPaid && !isCredit">
                  <app-number-input
                    classNames="product-input"
                    [value]="element.shipping"
                    [alwaysNegative]="isCredit"
                    (valueChange)="
                      updateProduct(
                        element.uniqueId,
                        $event,
                        'shipping'
                      )
                    "
                  ></app-number-input>
                </div>
                <strong *ngIf="isPaid || isCredit">{{ element.shipping | currency }}</strong>
              </td>
            </ng-container>

            <!-- Cost Column -->
            <ng-container matColumnDef="cost">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center th-dark"
              >
                Cost
              </th>

              <td
                mat-cell
                *matCellDef="let element"
                class="ao-table-cell center"
              >
                <div class="inpt" *ngIf="!isPaid && !isCredit">
                  <app-number-input
                    classNames="product-input"
                    [value]="element.cost"
                    [alwaysNegative]="isCredit"
                    (valueChange)="
                      updateProduct(
                        element.uniqueId,
                        $event,
                        'cost'
                      )
                    "
                  ></app-number-input>
                </div>
                <strong *ngIf="isPaid || isCredit">{{ element.cost | currency }}</strong>
              </td>
            </ng-container>

            <ng-container matColumnDef="actualSpacer">
              <ng-container *ngIf="fourColumns; else threeCol">
                <td mat-footer-cell *matFooterCellDef colspan="5"></td>
              </ng-container>
              <ng-template #threeCol>
                <td mat-footer-cell *matFooterCellDef colspan="4"></td>
              </ng-template>
            </ng-container>

            <ng-container matColumnDef="actualLabel">
              <td mat-footer-cell *matFooterCellDef class="center"><strong>Subtotal</strong></td>
            </ng-container>

            <ng-container matColumnDef="actualTax">
              <td mat-footer-cell *matFooterCellDef class="filled-light center">
                <div *ngIf="!isPaid">
                  <app-number-input classNames="product-input" [value]="order.tax" [alwaysNegative]="isCredit" (valueChange)="updateTax($event)"></app-number-input>
                </div>
                <strong *ngIf="isPaid">{{ order.tax | currency }}</strong>
              </td>
            </ng-container>

            <ng-container matColumnDef="actualShipping">
              <td mat-footer-cell *matFooterCellDef class="filled-light center">
                <div *ngIf="!isPaid">
                  <app-number-input classNames="product-input" [value]="order.shipping" [alwaysNegative]="isCredit"
                    (valueChange)="updateShipping($event)"></app-number-input>
                </div>
                <strong *ngIf="isPaid">{{ order.shipping | currency }}</strong>
              </td>
            </ng-container>

            <ng-container matColumnDef="actualCost">
              <td mat-footer-cell *matFooterCellDef class="filled-light center">
                <div *ngIf="!isPaid">
                  <app-number-input classNames="product-input" [value]="order.cost" [alwaysNegative]="isCredit" (valueChange)="updateCost($event)"></app-number-input>
                </div>
                <strong *ngIf="isPaid">{{ order.cost | currency }}</strong>
              </td>
            </ng-container>

            <ng-container  matColumnDef="aspacer">
              <td mat-footer-cell *matFooterCellDef colspan="6"></td>
            </ng-container>

            <ng-container *ngIf="!isCredit && !isPaid" matColumnDef="paidLabel">
              <td mat-footer-cell *matFooterCellDef class="center">
                <strong>Amt. Paid</strong>
              </td>
            </ng-container>

            <ng-container *ngIf="!isCredit && !isPaid" matColumnDef="paid">
              <td mat-footer-cell *matFooterCellDef class="center">
                <div class="agg-div" *ngIf="!isPaid">
                  <app-number-input classNames="product-input" [value]="order.partialPayment" (valueChange)="updatePayment($event)"></app-number-input>
                </div>
                <strong *ngIf="isPaid">{{ order.partialPayment | currency }}</strong>
              </td>
            </ng-container>

            <ng-container matColumnDef="dspacer">
              <td mat-footer-cell *matFooterCellDef colspan="6"></td>
            </ng-container>

            <ng-container *ngIf="!isCredit" matColumnDef="dueLabel">
              <td mat-footer-cell *matFooterCellDef class="center">
                <strong>Amt. Due</strong>
              </td>
            </ng-container>

            <ng-container *ngIf="!isCredit" matColumnDef="due">
              <td mat-footer-cell *matFooterCellDef class="center">
                <strong>{{ order.amountDue ?? 0 | currency }}</strong>
              </td>
            </ng-container>

            <ng-container *ngIf="isCredit" matColumnDef="lossLabel">
              <td mat-footer-cell *matFooterCellDef class="center">
                <strong>Proposed Credit</strong>
              </td>
            </ng-container>

            <ng-container *ngIf="isCredit" matColumnDef="loss">
              <td mat-footer-cell *matFooterCellDef class="center agg-cell">
                <div class="agg-div">
                  <app-number-input classNames="product-input" [value]="order.proposedCredit" (valueChange)="updateProposedCredit($event)"></app-number-input>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="spacer">
              <td mat-footer-cell *matFooterCellDef colspan="6"></td>
            </ng-container>

            <ng-container matColumnDef="totalLabel">
              <td mat-footer-cell *matFooterCellDef class="center">
                <strong>Total</strong>
              </td>
            </ng-container>

            <ng-container matColumnDef="total">
              <td mat-footer-cell *matFooterCellDef class="center agg-cell">
                <div *ngIf="!isPaid" class="agg-div">
                  <app-number-input classNames="product-input" [value]="order.total" [showDiff]="isCredit" [isCredit]="isCredit" [alwaysNegative]="isCredit"
                    [diff]="expectedLossText" [refValue]="order.proposedCredit ?? productsTotal" (valueChange)="updateTotal($event)"></app-number-input>
                </div>
                <strong *ngIf="isPaid">{{ order.total | currency }}</strong>
              </td>
            </ng-container>



            <tr
              mat-header-row
              *matHeaderRowDef="['toggleSpacer','hspacer', 'hstatus', 'hinvoice', 'hdate']"
              class="ao-header-row th"
            ></tr>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns"
              class="ao-header-row th"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="ao-table-row"
              (click)="viewProduct(row)"
            ></tr>
             <tr
                mat-footer-row
                *matFooterRowDef="['actualSpacer', 'actualLabel', 'actualTax', 'actualShipping', 'actualCost']"
                class="ao-table-row tf"
              ></tr>

              <!-- <ng-container *ngIf="isAwaitingCredit">
                                            <tr mat-footer-row *matFooterRowDef="['spacer', 'lossLabel', 'loss']" class="ao-table-row tf"></tr>
                                          </ng-container> -->
            <tr
            mat-footer-row
            *matFooterRowDef="['spacer', 'totalLabel', 'total']"
            class="ao-table-row tf"
          ></tr>

              <ng-container *ngIf="!isCredit && !isPaid">
              <tr
                mat-footer-row
                *matFooterRowDef="['aspacer', 'paidLabel', 'paid']"
                class="ao-table-row tf"
              ></tr>
              </ng-container>

            <ng-container *ngIf="!isCredit && !isPaid">
              <tr
                mat-footer-row
                *matFooterRowDef="['dspacer', 'dueLabel', 'due']"
                class="ao-table-row tf"
              ></tr>
            </ng-container>


            <!-- <ng-container *ngIf="isCredit && order.status !== 'CREDITED'">
              <tr
                mat-footer-row
                *matFooterRowDef="['spacer', 'diffLabel', 'diff']"
                class="ao-table-row tf"
              ></tr>
            </ng-container> -->


          </table>
        </div>
      </mat-card-content>
      <mat-card-actions class="mactions">
        <div class="d-flex justify-right w-100">
          <div
            *ngIf="editMode && !isPaid"
            class="fix-actions"
            [formGroup]="orderForm"
          >
            <div class="input">
              <mat-form-field floatLabel="never">
                <input
                  class="sm"
                  matInput
                  required
                  placeholder=""
                  type="text"
                  formControlName="initials"
                />
                <span matSuffix><mat-icon class="icon">gesture</mat-icon></span>
              </mat-form-field>
            </div>
          </div>
          <!-- <button
            *ngIf="!isPaid && !order.groupId"
            [disabled]="!orderForm.valid && editMode"
            class="btn icon dark"
            (click)="payOrder()"
          >
            <mat-icon matTooltip="Pay Invoice">attach_money</mat-icon>
          </button>
          <button
          *ngIf="!isPaid  && !order.groupId"
            [disabled]="!orderForm.valid && editMode"
            class="btn icon dark"
            (click)="payPrint()"
          >
            <mat-icon matTooltip="Pay & Print Invoice">receipt_long</mat-icon>
          </button>  -->
          <button
            *ngIf="isAwaitingCredit"
            [disabled]="
              !orderForm.valid || !orderForm.controls['invoiceNumber'].value
            "
            class="btn icon secondary"
            (click)="markInvoiceCredited()"
          >
            <mat-icon matTooltip="Mark as Credited">payments</mat-icon>
          </button>
          <button
            *ngIf="!isPaid"
            [disabled]="!orderForm.valid"
            class="btn icon secondary"
            (click)="saveOrder()"
          >
            <mat-icon matTooltip="Update Invoice">save</mat-icon>
          </button>
          <button
            *ngIf="!isPaid"
            [disabled]="!orderForm.valid"
            class="btn icon secondary"
            (click)="deleteInvoice()"
          >
            <mat-icon matTooltip="Delete Invoice">delete</mat-icon>
          </button>
          <app-invoice-log
            [order]="order"
            class="d-flex order-actions"
          ></app-invoice-log>
          <app-invoice-notes
            [order]="order"
            class="d-flex order-actions"
            (updateOrderEvent)="addNote($event)"
          ></app-invoice-notes>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
