import { Product, ProductElement } from '@models/product';
import { Vendor } from '@models/vendor';
import { Order, LogElement } from '@models/order';
import { Store } from '@models/store';
import ObjectId from 'bson-objectid';
import { Payment } from './api.model';

export class VendorOrder {
  _id: string;
  vendor: Vendor | string;
  store: Store | string;
  borrowingStore: Store | string;
  type: string;
  invoiceNumber: string;
  groupId?: string;
  raNumber: string;
  trackingNumber: string;
  status?: string;
  tax?: number;
  shipping?: number;
  shippingMethod?: string;
  total?: number;
  proposedCredit?: number;
  user?: string;
  date?: string;
  dateReceived?: string;
  datePaid?: string;
  dateAdded?: string;
  dateUpdated?: string;
  archived?: boolean;
  products?: ProductElement[];
  orders?: Order[];
  log?: LogElement[];
  vendor2?: any;
  partialPaymentDate?: string;
  partialPayment?: number;
  notes?: Partial<LogElement>[];
  amountDue?: number;
  payments?: Array<Payment>;
  taxBothRxAndSun: boolean;
  cost?: number;
  credit?: number;
  framesRemoved?: string[];

  constructor() {
    this._id = new ObjectId().toString();
    this.vendor = new Vendor();
    this.store = new Store();
    this.borrowingStore = new Store();
    this.type = '';
    this.invoiceNumber = '';
    this.groupId = '';
    this.raNumber = '';
    this.trackingNumber = '';
    this.status = '';
    this.tax = null;
    this.shipping = null;
    this.shippingMethod = '';
    this.total = null;
    this.proposedCredit = null;
    this.user = '';
    this.date = '';
    this.dateReceived = '';
    this.datePaid = '';
    this.dateAdded = '';
    this.dateUpdated = '';
    this.archived = false;
    this.products = [];
    this.orders = [];
    this.log = [];
    this.notes = [];
    this.partialPaymentDate = '';
    this.partialPayment = 0;
    this.amountDue = 0;
    this.payments = [];
    this.taxBothRxAndSun = false;
    this.cost = 0;
    this.credit = 0;
    this.framesRemoved = [];
  }
}


export class VendorOrders {
	_id: string;
  vendor: Vendor;
  vendor2?: Vendor;
	alert?: boolean;
	distributor?: boolean;
	orders?: Order[];
	vendorOrders?: VendorOrder[];
  count?: number;
  rentingProducts?: Product[];

  constructor()
  {
    this._id = '';
    this.vendor = new Vendor();
    this.vendor2 = new Vendor();
    this.alert = false;
    this.distributor = false;
    this.orders = [];
    this.vendorOrders = [];
		this.count = 0;
    this.rentingProducts = [];
  }
}
