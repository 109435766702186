import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { FavoriteLine } from '@models/favorite-line';

@Injectable({
  providedIn: 'root'
})
export class FavoriteLineService {
  private base_url = environment.base_url + '/favoriteLine';

  constructor(public http: HttpClient, private auth: AuthService) { }

  getAllFavoriteLines(): Observable<FavoriteLine[]> {
    let store = this.auth.getStore();
    return this.http.get(`${this.base_url}/store/${store}`).pipe(map( (res: FavoriteLine[]) => {return res;}));
  }

  addFavoriteLine(favoriteLine: FavoriteLine): Observable<any> {
    favoriteLine.store = this.auth.getStore();
    let body = JSON.stringify(favoriteLine);
    return this.http.post(`${this.base_url}`, body).pipe(map( (res) => res ));
  }

  updateFavoriteLine(favoriteId: any, favoriteLine: FavoriteLine): Observable<FavoriteLine> {
    favoriteLine.store = this.auth.getStore();
    let body = JSON.stringify(favoriteLine);
    return this.http.put(`${this.base_url}/${favoriteId}`, body).pipe(map( (res) => res as FavoriteLine));
  }

  deleteFavoriteLine(favoriteId: any,): Observable<FavoriteLine> {
    return this.http.delete(`${this.base_url}/${favoriteId}`).pipe(map( (res) => res as FavoriteLine));
  }
}
