<div *ngFor="let image2 of images; let i = index">
	<app-image 
		#productImage 
		[image]="image2" 
		[url]="urls[image2]" 
		[type]="type" 
		[edit]="true" 
		[delete]="true" 
		[active]="active"
		[autoCrop]="autoCrop" 
		[productId]="productId"
		[clickable]="clickable"
		(imageDelete)="deleteImage(image2,i)"
		(imageReplace)="replaceImage($event,i)"
		(imageCrop)="imageEdit(image2,i)"
		(imageAdd)="imageAdd($event)">
	</app-image>
</div>
<app-image 
	*ngIf="oneImage"
	#productImage 
	[type]="type" 
	[edit]="true" 
	[delete]="true" 
	[upload]="true" 
	[autoCrop]="autoCrop" 
	[active]="active"
	[productId]="productId"
	[clickable]="clickable"
	(imageDelete)="deleteImage(images[0],productId)"
	(imageCrop)="imageEdit(images[0],productId)"
	(imageAdd)="imageAdd($event)">
</app-image>
