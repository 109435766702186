import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { VendorOrder } from '@models/vendor-order';
import { PrintService } from '@services/print.service';
import { VendorOrderService } from '@services/vendor-order.service';

@Component({
  selector: 'app-vendor-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class VendorInvoicesComponent implements OnInit {
  @Input() vendor: string;

	dataSource = new MatTableDataSource<VendorOrder>();
  vendorOrders: VendorOrder[] = [];
	displayedColumns = ['status', 'invoiceNumber', 'date', 'tax', 'shipping', 'total', 'action', 'log'];
  defaultSortColumn = 'date';
  defaultSortDirection = 'asc';

  constructor(private vendorOrderService: VendorOrderService, private printService: PrintService) { }

  ngOnInit(): void {
    this.vendorOrderService.getVendorVendorOrders('all', this.vendor).subscribe(res => {
      this.vendorOrders = res.vendorOrders[0]?.vendorOrders;
      this.dataSource.data = this.vendorOrders?.slice(0, 5);
    });
  }

  printVendorOrdersGroup(groupId: string) {
    const vendorOrderIds: string[] = [];
    for (let x = 0; x < this.vendorOrders.length; x++) {
      if (this.vendorOrders[x].groupId == groupId) {
        vendorOrderIds.push(this.vendorOrders[x]._id);
      }
    }
    this.printService.printDocument('invoice', vendorOrderIds);
  }

  showMore() {
    this.dataSource.data = this.vendorOrders;
  }
}
