<div [formGroup]="parentForm">
	<mat-form-field class="example-chip-list">
	  <mat-chip-list #chipList>
	    <mat-chip
	      *ngFor="let favorite of favoriteCounts"
	      [selectable]="selectable"
	      [removable]="removable"
	      (removed)="remove(favorite)">
	      {{ favorite.value }}<span *ngIf="favorite.count>1">({{ favorite.count }})</span>
	      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
	    </mat-chip>
	    <input
	      placeholder="FAVORITE LINES"
	      #favoriteInput
	      [formControl]="favoriteCtrl"
	      [matAutocomplete]="auto"
	      [matChipInputFor]="chipList"
	      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
	      [matChipInputAddOnBlur]="addOnBlur"
	      (matChipInputTokenEnd)="add($event)">
	  </mat-chip-list>
	  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
	    <mat-option *ngFor="let favorite of filteredFavorites | async" [value]="favorite">
	      {{ favorite }}
	    </mat-option>
	  </mat-autocomplete>
	</mat-form-field>
</div>