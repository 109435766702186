import {
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { Product, ProductElement } from '@models/product';
import { Vendor, VendorInventory } from '@models/vendor';
import { Store } from '@models/store';
import { User } from '@models/user';
import { ProductForm } from '@classes/product-form';
import { ProductSort } from '@classes/product-sort';
import { ProductService } from '@services/product.service';
import { VendorService } from '@services/vendor.service';
import { StoreService } from '@services/store.service';
import { OrderService } from '@services/order.service';
import { ImageService } from '@services/image.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { ImageEditComponent } from '@components/admin/image/image-edit/image-edit.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { map, debounceTime, switchMap, mergeWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';

const imageURL = environment.base_url + '/image/';

@Component({
  selector: 'app-inventory-base',
  templateUrl: './inventory-base.component.html',
  styleUrls: ['./inventory-base.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed, void', style({ height: '0px' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])


  ]
})
export class InventoryBaseComponent implements OnInit, AfterViewInit {
  public _vendorInventory: VendorInventory;

  @Input() public managedBoard: boolean;
	@Input() public borrowingView: boolean;
  @Input() public borrowingStore: string = null;
  @Input() public borrowingSelection: SelectionModel<FormGroup>;
  @Input() public differentStoreSelected = false;
  @Input() rentingView: boolean;
  @Input() rentingUser: User;
  @Input() rentingList: any[];
  @Input() allRentingQtyByProduct;
  @Input() updatedRentingList: any[];
  @Input()
  set vendor(val: Vendor) {
    this.dataSource = new MatTableDataSource<FormGroup>();
    this._vendor = val;
    this.productsForm.get('showStock').setValue('all');
  }

  @Output() selectRenting = new EventEmitter();

  @ViewChildren('productImage') productImages: QueryList<ElementRef>;
  @ViewChildren('formRow') rows: QueryList<ElementRef>;
	@ViewChild(MatTable, {static: false}) table: MatTable<FormControl>;
	@ViewChild('search', {static: false}) search: MatInput;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
 	@ViewChild('statusSelect', {static: false}) set content(content: MatSelect) {
 		if (content !== undefined) {
 			this.statusSelect = content;
 			if (this.clickedStatus >= 0) {
 				this.statusSelect.open();
 				this.cdRef.detectChanges();
 			}
 		}
 	}

  public _vendor: Vendor;

  fieldOpen = false;
  clickedImage = false;
  index: number;
  expandedElement = null;
  clickedStatus: number;
  storeOptions: any[];
 	statusSelect: MatSelect;
	products: Product[];
  dataSource = new MatTableDataSource<FormGroup>();
  dataLoading = false;
	displayedColumns: string[] = ['images', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'store', 'quantity', 'borrow', 'action', 'delete'];
 	productsForm: FormGroup;
  productId: String;
  productIndex: number;
  editProduct: FormGroup;
  typeOptions: Object[] = [{viewValue: 'RX', value: 'RX'}, {viewValue: 'SUN', value: 'SUN'}, {viewValue: 'OTHER', value: 'OTHER'}];
  commissionOptions: Object[] = [{viewValue: 'NONE', value: 'NONE'}, {viewValue: 'SPIFF', value: 'SPIFF'}, {viewValue: 'DRILLMOUNT', value: 'DRILLMOUNT'}];
  defaultSortColumn = '';
	public uploaderProduct: FileUploader = new FileUploader({url: environment.base_url + '/image/product', itemAlias: 'photoProduct'});
	authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];
  authorized = false;
  authorizedSendToStackRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];
  authorizedSendToStack = false;
  selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(true, []);
  timestamp: number = new Date().getTime();
  imageUrls: any[] = [];
  showParts = false;
  user;
  currentStore: Store;
  searchControl: FormControl = new FormControl();
  showData: boolean = false;
  showAll: boolean = false;
  lodash = _;
  
  constructor(@Inject(LOCALE_ID) public locale: string, public vendorService: VendorService, public storeService: StoreService, public auth: AuthService, public productService: ProductService, public orderService: OrderService, public imageService: ImageService, public fb: FormBuilder, public cdRef: ChangeDetectorRef, public dialog: MatDialog) {

    this.storeOptions = this.storeService.stores;
    this.productsForm = this.fb.group({
      productsFormArray: this.fb.array([]),
      search: '',
      showStock: 'all'
    });
    this.getAuthorized();
    this.getAuthorizedSendToStack();
    this.currentStore = this.auth.getStoreObject();

    this.productsForm = this.fb.group({
      productsFormArray: this.fb.array([]),
      search: '',
      showStock: 'all',
      multipleFields: this.fb.group({
        field: '',
        status: '',
        type: '',
        cost: '',
        retail: '',
        quantity: ''
      })
    });

    this.searchControl = this.productsForm.get('search') as FormControl;
    const searchValueChange = this.searchControl.valueChanges;
    const searchValueChangeMid = searchValueChange.pipe(
      map((changedValue) => {
        this.dataLoading = true;
      })
    );
    const searchValueChangeEnd = searchValueChange.pipe(
      debounceTime(500),
      switchMap((changedValue) => {
        return this.filterProducts(changedValue);
      })
    );

    searchValueChange.pipe(mergeWith(searchValueChangeMid, searchValueChangeEnd)).subscribe(res => {})
  }

  getAuthorized() {
    this.authorized = this.authorizedRoles.includes(this.auth.currentUser.role);
  }

  getAuthorizedSendToStack() {
    this.authorizedSendToStack = this.authorizedSendToStackRoles.includes(this.auth.currentUser.role);
  }

	ngOnInit() {
    this.user = this.auth.getUser();
	}

	ngAfterViewInit() {
  	this.setTableColumns();
    this.cdRef.detectChanges();
  }

  setTableColumns() {
    this.showParts = false;
    if (this.managedBoard) {
      this.defaultSortColumn = '';
      if (this.user.role === 'RENTAL') {
        this.displayedColumns = ['images', 'model', 'color', 'size', 'status', 'type', 'quantity', 'orderDate', 'rent', 'action'];
      } else {
        this.displayedColumns = ['images', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'quantity', 'orderDate', 'borrow', 'rent', 'action'];
      }
    } else if (this.productsForm.get('showStock').value === 'parts') {
      this.showParts = true;
      this.defaultSortColumn = '';
      if (this.user.role === 'RENTAL') {
        this.displayedColumns = ['select', 'images', 'model', 'color', 'size', 'type', 'quantity', 'rent', 'action', 'delete'];
      } else {
        this.displayedColumns = ['select', 'images', 'model', 'color', 'size', 'type', 'cost', 'priceLink', 'retail', 'quantity', 'borrow', 'rent', 'action', 'delete'];
      }
    } else {
      this.defaultSortColumn = '';
      if (this.user.role === 'RENTAL') {
        this.displayedColumns = ['select', 'images', 'model', 'color', 'size', 'status', 'type', 'quantity', 'rent', 'action', 'delete'];
      } else {
        this.displayedColumns = ['select', 'images', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'quantity', 'borrow', 'rent', 'action', 'delete'];
      }
    }
  }

  getVendorInventory(filterString?: string) {
    const stockOption = this.productsForm.get('showStock').value;
    let vendorInventory = this.vendorService.getVendorInventory(this._vendor, filterString, stockOption);
    if (this._vendor.differentStore) {
      vendorInventory = this.vendorService.getVendorInventory(this._vendor, filterString, stockOption, this._vendor.differentStore);
    }
    vendorInventory.subscribe((vendorInventories: VendorInventory[]) => {
      const _vendorInventory = vendorInventories[0];
      _vendorInventory.products = _vendorInventory.products.map(product => {
        product.borrowing = _(product.borrowing).groupBy('store').map((objs, key) => ({
          store: key,
          quantity: _.sumBy(objs, 'quantity')
        })).value();
        return product;
      });
      this._vendorInventory = _vendorInventory;
      this.setProductForms(this._vendorInventory.products, stockOption).subscribe(() => {
        this.dataLoading = false;
      });
    });
  }



  filterProductStock() {
    this.dataLoading = true;
    this.getVendorInventory(this.searchControl.value);
  }

  filterParts() {
    this.orderService.getPartsOrders(this._vendor._id).subscribe((partsOrders: any[]) => {
      this.setTableColumns();
      let products = [];
      for (let x = 0; x < partsOrders.length; x++) {
        products = products.concat(partsOrders[x]['products']);
      }
      this.setProductForms(products, 'parts').subscribe(() => {
        this.cdRef.detectChanges();
        this.dataLoading = false;
      });
    });
  }

  filterInventory() {
    this.setProductForms(this._vendorInventory.products, this.productsForm.get('showStock').value).subscribe(() => {
      ProductSort.filterProductStock(this.dataSource, this.productsForm);
      this.cdRef.detectChanges();
      this.dataLoading = false;
    });
  }

  setProductForms(products: Product[], showStock = 'all'): Observable<any> {
    let imageIds = [];
    for (let x = 0; x < products.length; x++) {
        imageIds = imageIds.concat(products[x].images);
    }
    const productFormArray = ProductForm.initProductFormArray(products, this.auth, this.storeService.stores);
    this.productsForm.setControl('productsFormArray', productFormArray); 
    this.productsForm.get('showStock').setValue(showStock); 
    this.setDataSource(this.productsForm.controls.productsFormArray['controls']);

// return of(true)
    return this.imageService.getBulkThumbnailUrls(imageIds, this.imageService.getThumbnailWidth('product')).pipe(
      map((urls: any) => {
        this.imageUrls = urls;
      }),
      // map(() => {
      //   const productFormArray = ProductForm.initProductFormArray(products, this.auth, this.storeService.stores);
      //   this.productsForm.setControl('productsFormArray', productFormArray); 
      //   this.productsForm.get('showStock').setValue(showStock); 
      //   this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
      // })
    );
  }

  setDataSource(dataFormGroups: FormGroup[]) {
    this.dataSource = new MatTableDataSource<FormGroup>(dataFormGroups);
    // ProductSort.setFilterPredicate(this.dataSource, this.selection);
    // ProductSort.filterProductStock(this.dataSource, this.productsForm);
    ProductSort.setSorter(this.dataSource, this.sort);
    this.cdRef.detectChanges();
  }

  searchControlEnter($event) {
    if (this.searchControl.value == '') {
      this.showEverything();
    }
  }

  showEverything() {
    this.showAll = true;
    // this.productsForm.get('search').setValue('');
    this.dataLoading = true;
    this.getVendorInventory('');
    this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
    this.dataSource.filter = '$' + this.productsForm.get('showStock').value;
    this.dataLoading = false;
  }

  async filterProducts(filterValue: string) {
    if (filterValue.length < 1) {
      this.showAll = false;
      // this.dataSource = new MatTableDataSource<FormGroup>();
      if (!this.showAll) {
        this.dataSource = new MatTableDataSource<FormGroup>();
        this.dataLoading = false;
      }
    } else {
      this.dataLoading = true;
      this.cdRef.detectChanges();
      this.getVendorInventory(filterValue);
      // if (this.dataSource.data.length < 1) {
      //   // this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
      //   this.dataSource.filter = filterValue.trim().toUpperCase() + '$' + this.productsForm.get('showStock').value;
      // } else {
      //   this.dataSource.filter = filterValue.trim().toUpperCase() + '$' + this.productsForm.get('showStock').value;
      // }
      this.dataLoading = false;
    }
    // this.dataSource.filter = filterValue.trim().toUpperCase() + '$' + this.productsForm.get('showStock').value;
    return true;
  }

  addProductFormItem(product) {
    const control = <FormArray>this.productsForm.controls['productsFormArray'];
    const productFormGroup = ProductForm.initProductFormGroup(product);
    control.push(productFormGroup);
    return productFormGroup;
  }

  getStoreObject(store: Store): Store {
    return this.storeService.getStoreObjectFromId(store._id);
  }

  clickRow($event: Event, product: FormGroup, index) {
    if (this.user && this.user.role === 'RENTAL') {
      return;
    }
    if (!this.fieldOpen) {
      if (this.borrowingView) {

      } else if (this.productId === product.value._id) {
        this.productId = null;
        this.productIndex = null;
        this.index = null;
        this.expandedElement = null;
        this.editProduct = null;
        if (!this.clickedImage) {
          $event.preventDefault();
          $event.stopPropagation();
        }
        this.fieldOpen = false;
        this.cdRef.detectChanges();
      } else {
        this.productId = product.value._id;
        this.productIndex = index;
        this.index = index;
        this.expandedElement = product;
        this.editProduct = product;
        if (!this.clickedImage) {
          $event.preventDefault();
          $event.stopPropagation();
        }
        this.fieldOpen = false;
        this.cdRef.detectChanges();
      }
	  } else {

    }
  }

  @HostListener('document:click', ['$event']) clickOutside ($event) {
    if (!this.fieldOpen && !this.clickedImage) {
      this.productId  = '';
	    this.productIndex = null;
      this.expandedElement = null;
      this.editProduct = null;
    } else {
      this.fieldOpen = false;
    }
  }

  saveEditedProduct($event, editProduct: FormGroup, index, ordered = false) {
    if (!ordered) {
      this.fieldOpen = true;
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (this.productId === 'NEW') {
    	if (this.editProduct.value.model !== '') {
        editProduct.value._id = null;
        editProduct.value.vendor = this._vendor._id;
        editProduct.value.model = editProduct.value.model.toUpperCase();

				if (!Array.isArray(editProduct.value.images)) {
					editProduct.value.images = [];
				}
				const editImages = editProduct.value.images;
				editProduct.value.images = [];
	      this.productService.addProduct(editProduct.value)
	        .subscribe(res => {
	          if (res['success']) {
	          	const formArray = <FormArray>this.productsForm.controls['productsFormArray'];
	          	const productFormGroup: FormGroup = this.fb.group(res['product']);
              const newProductId = res['product']['_id'];
              editProduct.get('_id').setValue(newProductId);

              if (ordered) {
                const productElement = new ProductElement();
                productElement.product = editProduct.value;
                productElement.type = editProduct.value.type;
                productElement.quantity = 1;
                productElement.retail = editProduct.value.retail;
                productElement.cost = editProduct.value.cost;
                productElement.store = this.auth.getStoreObject();

                this.orderService.addStockOrder(productElement, editProduct.value.type, 'AWAITING FRAME').subscribe((order) => {
                  console.log(order);
                });
                console.log('ordered new product');
              }

          		editProduct.markAsPristine();
	          	for (let x = 0; x < editImages.length; x++) {
			        	this.productService.addImage(res.product._id, editImages[x])
					        .subscribe(product => {
					          console.log('product image added ' + product._id);
										editProduct.get('images').setValue(product.images);
										// this.getVendorProducts();
  									this.cdRef.detectChanges();
					        });
			        }

              this.productId = '';
			        this.editProduct = null;
			        this.productIndex = null;
              this.expandedElement = null;

    					this.cdRef.detectChanges();
	          } else {
	          	editProduct.value._id = 'NEW';
	          	const dialogRef = this.dialog.open(SaveComponent, {
					      width: '250px',
					      data: { message: res['message'] }
					    });
					  	dialogRef.afterClosed().subscribe(result => {});
	          }
	        });
      }
    } else {
      if (editProduct.value.status === 'return') {
        editProduct.get('status').setValue('white');
      }

      if (ordered) {
        const productElement = new ProductElement();
        productElement.product = editProduct.value;
        productElement.type = editProduct.value.type;
        productElement.quantity = 1;
        productElement.retail = editProduct.value.retail;
        productElement.cost = editProduct.value.cost;
        productElement.store = this.auth.getStoreObject();
        console.log(productElement);
        console.log(editProduct.value.type);

        this.orderService.addStockOrder(productElement, editProduct.value.type, 'AWAITING FRAME').subscribe((order) => {
          console.log(order);
        });
        console.log('ordered new product');
      }

      this.saveProduct(editProduct);
    }

  }

  addStockOrder(editProduct) {

  }

  saveProduct(editProduct: FormGroup) {
    this.productService.updateProduct(editProduct.value._id, editProduct.value)
      .subscribe(res => {
      	if (res.success) {
					editProduct.markAsPristine();
	        this.productId = '';
	        this.editProduct = null;
	        this.productIndex = null;
					this.expandedElement = null;
	        // this.getVendorProducts();


          if (editProduct.value.return) {
            const productElement = new ProductElement();
            productElement.product = editProduct.value;
            productElement.type = editProduct.value.type;
            productElement.quantity = editProduct.value.quantity;
            productElement.retail = editProduct.value.retail;
            productElement.cost = editProduct.value.cost;
            productElement.store = this.auth.getStoreObject();

            this.orderService.addReturnOrder(productElement, editProduct.value.type);
          }

	    		this.cdRef.detectChanges();
      	} else {
        	const dialogRef = this.dialog.open(SaveComponent, {
			      width: '250px',
			      data: { message: res['message'] }
			    });
			  	dialogRef.afterClosed().subscribe(result => {});
      	}
    	});
  }


  deleteProduct($event, editProduct: FormGroup, index) {
    $event.preventDefault();
    $event.stopPropagation();
  	const dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE PRODUCT?' }
    });
  	dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const dataIndex = this.dataSource.data.findIndex(d => d === editProduct);
		    if (editProduct.value._id === 'NEW' || !editProduct.value._id) {
          this.removeProduct(dataIndex);
		    } else {
		      this.productService.deleteProduct(editProduct.value._id).subscribe(product => {
	          this.removeProduct(dataIndex);
	        });
		    }
      }
    });
  }

  removeProduct(dataIndex) {
    this.removeProductFormItem(dataIndex);
    this.productId = '';
    this.editProduct = null;
    this.productIndex = null;
    this.expandedElement = null;
  }

  getTimestamp(timestamp: Date) {
    return timestamp.getTime();
  }

  prepareSaveProduct(editProduct) {
    const product = new Product();
    product._id = editProduct.value._id;
    product.model = editProduct.value.name;
    return product;
  }

  removeProductFormItem(index) {
    const control = <FormArray>this.productsForm.controls['productsFormArray'];
    control.removeAt(index);
    this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
  }

  addProduct($event) {
    this.fieldOpen = true;
    const newProduct = new Product();
    newProduct._id = 'NEW';
    newProduct.type = 'RX';
    newProduct.status = this._vendor.defaultManagedBoardStatus;
    newProduct.vendor = this._vendor;
    newProduct.quantity = 0;
    newProduct.store = this.auth.getStoreObject();
    const newIndex = this.dataSource.data.length;

    this.editProduct = this.addProductFormItem(newProduct);
    if (this.user.role === 'RENTAL') {
      this.editProduct = null;
    }

    this.productId = newProduct._id;
    this.productIndex = newIndex;
    $event.preventDefault();
    $event.stopPropagation();
    this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
  }

  submit() {
    for (let x = 0; x < this.productsForm.controls['productsFormArray']['length']; x++) {
      const form = this.productsForm.controls['productsFormArray']['controls'][x];
    }
  }

  clickField($event, product, index) {
    this.fieldOpen = true;
  }

  clickImage($event, product, index) {
    this.clickedImage = true;
    console.log($event);
    console.log(product);
    console.log(index);
    // $event.preventDefault();
    // $event.stopPropagation();
  }

	clickStatus($event, product, index) {
    if (this.clickedStatus !== index) {
			$event.preventDefault();
    	$event.stopPropagation();
    }
    this.clickedStatus = index;
  }

  changeStatus($event, product, index) {
  	if (this.productId !== 'NEW') {
    	this.clickedStatus = -1;
	    const saveProduct = this.productsForm.value.productsFormArray[index];
 			this.statusSelect.close();
 			this.cdRef.detectChanges();
		  this.saveEditedProduct($event, saveProduct, index);
		}
  }

  changeProductStatus($event, product: FormGroup, index): boolean {
    if ($event.value === 'black') {
      product.get('return').setValue(true);
    } else {
      product.get('return').setValue(false);
    }
    return this.changeProductType(product);
  }

  changeProductType(product: FormGroup): boolean {
    // if ($event.source.selected && $event.isUserInput) {
      this.changeCost(product);
    // }
    return true;
  }

  togglePriceLink($event, product: FormGroup, index) {
    this.fieldOpen = true;
    if (this.authorized) {
      const saveProduct = ProductForm.togglePriceLink(this.productsForm, product, this.editProduct, this._vendor, index);

      if (saveProduct) {
        this.saveProduct(product);
      }
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  cropImage(imageId) {
    const dialogRef = this.dialog.open(ImageEditComponent, {
      width: '600px',
      height: '500px',
      data: { image: imageId, message: '' }
    });
    dialogRef.afterClosed().subscribe(result => {});
    dialogRef.componentInstance.imageChange.subscribe(img => {
      // image.date = img.date;
    });
  }

  calculateProductPrice(product) {
    return ProductForm.calculateProductPrice(product);
  }

  changeRetail(product: FormGroup) {
    ProductForm.changeRetail(product, this._vendor);
  }

  changeCost(product: FormGroup) {
    ProductForm.changeCost(product, this._vendor);
  }

  updateImages(images, product, p2) {
    if (product.value._id !== 'NEW') {
      product.get('images').setValue(images);
                    this.cdRef.detectChanges();
      this.productService.updateImages(product.value._id, images).subscribe((res) => {
      });
    }
  }








  checkboxLabel(row?: FormGroup, index?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
        // this.dataSource.data.forEach(row => this.selection.select(row));
    this.cdRef.detectChanges();
  }

  toggleSelect(vendor, order) {
    this.selection.toggle(order);
    this.cdRef.detectChanges();
  }

  toggleSelectBorrow(vendor, order) {
    this.borrowingSelection.toggle(order);
    this.cdRef.detectChanges();
  }

  getTotalCost() {
    if (this._vendorInventory && this._vendorInventory.products) {
      return this.dataSource.filteredData.reduce((acc, formGroup: FormGroup) => acc + formGroup.value.cost * formGroup.value.quantity, 0);
      // return this.vendorInventory.products.reduce((acc, value) => acc + value.cost * value.quantity, 0);
    } else {
      return 0;
    }
  }

  getTotalQty() {
    if (this._vendorInventory && this._vendorInventory.products) {
      return this.dataSource.filteredData.map(t => t.value.quantity).reduce((acc, value) => (parseFloat(value)) ? acc + parseFloat(value) : acc, 0);
      // return this._vendorInventory.products.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  borrowMultipleProducts(storeObject: Store) {
    console.log(storeObject);

  }

  updateProducts() {
    this.setDataSource(this.productsForm.controls.productsFormArray['controls']);
  }

  onSelectRenting(event: MatCheckboxChange, productId: string, vendorId: string) {
    this.selectRenting.next({
      checked: event.checked,
      productId,
      vendorId
    });
  }

  getRenting(productId: string) {
    return this.rentingList.find(renting => renting.product._id === productId);
  }

  getUpdatedRenting(productId: string) {
    return this.updatedRentingList.find(renting => renting._id === productId);
  }

  getBorrowingSum(borrowingList) {
    let sum = 0;
    for (const borrowing of borrowingList) {
      sum += borrowing.quantity;
    }
    return sum;
  }
}
