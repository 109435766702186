import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css', './../../../../../../node_modules/font-awesome/css/font-awesome.css']
})
export class GalleryComponent {
	images: any;

  constructor(public dialogRef: MatDialogRef<GalleryComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  	this.images = data.images;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
