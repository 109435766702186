import { EventEmitter, Pipe, Component, Inject, LOCALE_ID, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, Output, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Store } from '@models/store';
import { LogElement } from '@models/order';
import { VendorOrder } from '@models/vendor-order';
import { FormatData } from '@classes/format-data';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { OrderService } from '@services/order.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { SelectionModel } from '@angular/cdk/collections';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';
import { Observable, iif, of, from, forkJoin } from 'rxjs';
import { mergeMap, switchMap, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-inventory-menu',
  templateUrl: './inventory-menu.component.html',
  styleUrls: ['./inventory-menu.component.css']
})
export class InventoryMenuComponent implements OnInit {
	@Input() selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(true, []);
  @Input() productsForm: FormGroup;
  @Output() public borrowing: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateProducts: EventEmitter<any> = new EventEmitter<any>();

	authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];
  authorized: boolean = false;
  authorizedSendToStackRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];
  authorizedSendToStack: boolean = false;
  storeOptions: any[];
  borrowingStore: string = null;
  borrowingInitials: FormControl = new FormControl();
  mergeKeepId: FormControl = new FormControl('', Validators.required);
  mergeQty: FormControl = new FormControl('', Validators.required);

  constructor(@Inject(LOCALE_ID) private locale: string, private vendorService: VendorService, public storeService: StoreService, private auth: AuthService, private productService: ProductService, private orderService: OrderService, private vendorOrderService:VendorOrderService, private fb: FormBuilder, private cdRef:ChangeDetectorRef, public dialog: MatDialog) {
  	this.getAuthorized();
    this.getAuthorizedSendToStack();
  }

  getAuthorized() {
    this.authorized = this.authorizedRoles.includes(this.auth.currentUser.role);
  }

  getAuthorizedSendToStack() {
    this.authorizedSendToStack = this.authorizedSendToStackRoles.includes(this.auth.currentUser.role);
  }

  ngOnInit() {
    this.storeOptions = this.storeService.stores;
  }

  openDialog(message: string) {
  	let dialogRef = this.dialog.open(OkCancelComponent, {
      width: '250px',
      data: { message: message }
    });
    return dialogRef.afterClosed().pipe(switchMap(v => iif(() => v == true, from(this.selection.selected), null)));
  }

  setValue(type: string, value: any) {
  	let message = (`SET ${type} TO ${value} FOR ${this.selection.selected.length} ` + ((this.selection.selected.length == 1) ? 'ITEM?' : 'ITEMS?')).toUpperCase();
  	let something = this.openDialog(message).pipe(
  		tap((product: FormGroup) => this.productServiceSelector(type, product.value._id, value)),
  		tap((product: FormGroup) => product.get(type).setValue(value))
  	);

  	forkJoin(something).subscribe((v) => {
			let message = (`${type} SET TO ${status} FOR ${this.selection.selected.length} ` + ((this.selection.selected.length == 1) ? 'ITEM' : 'ITEMS')).toUpperCase();
			this.openConfirmationDialog(message);
  	});
  }

  productServiceSelector(type: string, productId: string, value: string) {
  	switch(type) {
  		case 'quantity':
  			return this.productService.setQuantity(productId, value).subscribe(() => {});
  		case 'status':
  			return this.productService.setStatus(productId, value).subscribe(() => {});;
  		case 'cost':
  			return this.productService.setCost(productId, value).subscribe(() => {});;
  		case 'retail':
  			return this.productService.setRetail(productId, value).subscribe(() => {});;
  	}
  	return of(null);
  }

  setQuantity(quantity) {
  	this.setValue('quantity', quantity);
  }

  setStatus(status:string) {
  	this.setValue('status', status);
  }

  setCost(cost:any) {
  	this.setValue('cost', cost);
  }

  setRetail(retail:any) {
  	this.setValue('retail', retail);
  }

	sendOrder(type: string, destination: string, status: string) {
  	let message = (`SEND ${this.selection.selected.length} ` + ((this.selection.selected.length == 1) ? `ITEM TO ${destination}?` : `ITEMS TO ${destination}?`)).toUpperCase();
  	let something = this.openDialog(message).pipe(
  		map((product: FormGroup) => {
  			let productElement = new ProductElement();
        productElement.product = product.value;
        productElement.type = product.value.type;
        productElement.quantity = 1;
        productElement.retail = product.value.retail;
        productElement.cost = product.value.cost;
        productElement.store = this.auth.getStoreObject();
  			this.orderServiceSelector(type, productElement, status)
  		})
  	);

  	forkJoin(something).subscribe((v) => {
      let message = (`SENT ${this.selection.selected.length} ` + ((this.selection.selected.length == 1) ? `ITEM TO ${destination}` : `ITEMS TO ${destination}`));
			this.openConfirmationDialog(message);
  	});
  }

  orderServiceSelector(type: string, productElement: ProductElement, value: string) {
  	switch(type) {
  		case 'orderedProducts':
        return this.orderService.addStockOrder(productElement, productElement.product.type, 'AWAITING FRAME').subscribe(() => {});
  		case 'sendToStack':
        return this.orderService.addStockOrder(productElement, productElement.product.type, '').subscribe(() => {});
  		case 'returnProducts':
        return this.orderService.addReturnOrder(productElement, productElement.product.type).subscribe(() => {});
  	}
  	return of(null);
  }

  orderedProducts() {
  	this.sendOrder('orderedProducts', 'AWAITING FRAMES', 'AWAITING FRAME');
  }

  sendToStack() {
  	this.sendOrder('sendToStack', 'STACK', '');
  }

  returnProducts() {
  	this.sendOrder('returnProducts', 'RETURNS', 'return');
  }

  setFieldForMultipleProducts(field: string) {
    switch(field) {
      case 'status' :
        this.setValue('status', this.productsForm.get('multipleFields.status').value);
        break;
      case 'cost' :
        this.setValue('cost', FormatData.formatCurrency(this.productsForm.get('multipleFields.cost').value));
        break;
      case 'retail' :
        this.setValue('retail', FormatData.formatCurrency(this.productsForm.get('multipleFields.retail').value));
        break;
      case 'quantity' :
        this.setValue('quantity', this.productsForm.get('multipleFields.quantity').value);
        break;
    }
  }

  borrowMultipleProducts() {
    let storeObject: Store = this.storeService.getStoreObjectFromId(this.borrowingStore);

    this.borrowing.emit(storeObject);


    // console.log(storeObject.storeName);
    // let message = (`BORROW ${this.selection.selected.length} ` + ((this.selection.selected.length == 1) ? `ITEM TO ${storeObject.storeName}?` : `ITEMS TO ${storeObject.storeName}?`)).toUpperCase();
    // let something = this.openDialog(message).pipe(
    //   map((product) => {
    //     let productElement = new ProductElement();
    //     productElement.product = product.value;
    //     productElement.type = product.value.type;
    //     productElement.quantity = 1;
    //     productElement.retail = product.value.retail;
    //     productElement.cost = product.value.cost;
    //     productElement.store = this.auth.getStoreObject();
    //     this.orderServiceSelector(type, productElement, status)
    //   })
    // );

    // forkJoin(something).subscribe((v) => {
    //   let message = (`SENT ${this.selection.selected.length} ` + ((this.selection.selected.length == 1) ? `ITEM TO ${storeObject.storeName}` : `ITEMS TO ${storeObject.storeName}`));
    //   this.openConfirmationDialog(message);
    // });
  }


  borrowSelectedProducts($event) {
    let storeObject: Store = this.storeService.getStoreObjectFromId(this.borrowingStore);
    console.log(this.selection)

    let logItemProduct = new LogElement();
    // logItemProduct.initials = this.auth.currentUser.initials;
    logItemProduct.initials = this.borrowingInitials.value;
    logItemProduct.user = this.auth.currentUser._id;
    logItemProduct.item = 'BORROW FRAME FOR STORE ' + this.borrowingStore;
    logItemProduct.notes = '';
    logItemProduct.date = Date.now().toString();

    let vendorOrders: Object = {};
    let logItem = new LogElement();
    logItem.initials = this.borrowingInitials.value;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'BORROWED FRAMES';
    logItem.notes = '';
    logItem.date = Date.now().toString();

    let borrowingSelectionCount = this.selection.selected.length;
    for (let x = 0; x < this.selection.selected.length; x++) {
      let productForm = this.selection.selected[x];
      let product = productForm.getRawValue();
      // product.vendor = product.vendor._id;
      product.store = product.store._id;
      let productElement: ProductElement = new ProductElement();

      this.vendorService.checkVendorForBorrowing(product.vendor, this.borrowingStore).subscribe((res) => {
        console.log(res)
        if (!res.vendor) {
          let dialogRef = this.dialog.open(OkCancelComponent, {
            width: '250px',
            data: { message: 'VENDOR DOES NOT EXIST AT ' + storeObject.storeName + '. DO YOU WANT TO CREATE IT?' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
              this.vendorService.addBorrowingVendor(product.vendor, this.borrowingStore).subscribe((res) => {
                console.log(res)
              });
            }
          });
        }
      });

      let quantity = 1;
      this.productService.borrowProduct(product._id, this.borrowingStore, quantity, this.auth.getStore(), logItemProduct).subscribe((product) => {
        console.log(product)
        console.log(productForm)
        productForm.get('borrowing').setValue(product.borrowing);
        this.cdRef.detectChanges(); 
      });

      if (!vendorOrders[product.vendor]) {
        vendorOrders[product.vendor] = new VendorOrder();
        vendorOrders[product.vendor].date = Date.now().toString();
        vendorOrders[product.vendor].type = 'borrow';
        vendorOrders[product.vendor].vendor = product.vendor;
        vendorOrders[product.vendor].user = this.auth.currentUser._id;
        vendorOrders[product.vendor].store = this.auth.getStore();
        vendorOrders[product.vendor].borrowingStore = this.borrowingStore;
        vendorOrders[product.vendor].log.unshift(logItem);
      }
      productElement.product = product;
      vendorOrders[product.vendor].products.unshift(productElement);
    }
    console.log(vendorOrders)
    Object.keys(vendorOrders).forEach((key) => {
      this.vendorOrderService.addVendorOrder(vendorOrders[key]).subscribe(vendorOrder => {
        this.selection.clear();
        let dialogRef = this.dialog.open(SaveComponent, {
            width: '250px',
            data: { message: borrowingSelectionCount + ' PRODUCTS SUCCESSFULLY BORROWED BY ' + storeObject.storeName + '.' }
          });
          dialogRef.afterClosed().subscribe(result => {

          });
      });
    });
    
  }

  getMergeProducts() {
    for (let x = 0; x < this.selection.selected.length; x++) {

    }
  }

  mergeProducts() {
    console.log(this.mergeQty.value)
    let deleteIds = [];
    for (let x = 0; x < this.selection.selected.length; x++) {
      if (this.selection.selected[x].value._id != this.mergeKeepId.value) {
        deleteIds.push(this.selection.selected[x].value._id);
      }
    }

    let productsFormArray: FormArray = this.productsForm.get('productsFormArray') as FormArray;
    console.log(this.mergeKeepId.value)
    console.log(deleteIds)
      console.log(productsFormArray)
      console.log(productsFormArray.length)



    this.productService.fixDuplicateProducts(this.mergeKeepId.value, deleteIds, this.mergeQty.value).subscribe((productObject: Product) => {
      for (let x = 0; x < productsFormArray.length; x++) {
        let productControl: FormControl = productsFormArray.controls[x] as FormControl;
        if (deleteIds.includes(productControl.value._id)) {
          productsFormArray.removeAt(x);
        }
      }
      this.updateProducts.emit();
    });


  }

	openConfirmationDialog(message: string): void {
    let dialogRef = this.dialog.open(SaveComponent, {
      width: '250px',
      data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

  

}
