import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceComponent } from './invoice/invoice.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { MaterialModule } from '@modules/material/material.module';
import { RouterModule } from '@angular/router';
import { UiCommonModule } from '@v2/common/ui-common.module';
import { ImageModule } from '../image/image.module';
import { ComponentsModule } from '@v2/components/components.module';

@NgModule({
  declarations: [InvoiceComponent, PrintInvoiceComponent, PrintLayoutComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    UiCommonModule,
    ImageModule,
    ComponentsModule,
  ],
  exports: [InvoiceComponent, PrintInvoiceComponent, PrintLayoutComponent],
})
export class PrintModule {}
