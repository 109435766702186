import { Component, Input } from '@angular/core';
import { Subject, takeUntil, pipe, Observable } from 'rxjs';

export interface LoadingOptions {
  header?: string;
  message?: string;
  diameter?: number;
  isLoading?: boolean;
  loadingMessage?: string;
}

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() loadingStream: Observable<LoadingOptions>;

  content: LoadingOptions | undefined;
  isLoading: boolean;
  unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.loadingStream
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((options: LoadingOptions) => {
        const { isLoading } = options;
        if (!isLoading) {
          this.clearLoader();
        } else {
          this.showLoader(options);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearLoader() {
    this.isLoading = false;
    this.content = undefined;
  }

  showLoader(options: LoadingOptions) {
    this.content = options;
    this.isLoading = true;
  }
}
