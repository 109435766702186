<div class="sticky alert" *ngIf="isOpen && content">
    <div class="sticky_container">
        <div class="sticky_wrapper d-flex align-items-center">
            <div class="sticky_icon">
                <ng-container *ngIf="content.success; else failBlock">
                    <span class="success self-middle icon">
                        <mat-icon>check_circle</mat-icon>
                    </span>
                </ng-container>
                <ng-template #failBlock>
                    <span class="success self-middle icon">
                        <mat-icon>warning</mat-icon>
                    </span>
                </ng-template>
            </div>
            <div class="sticky_caption d-flex flex-column align-stretch flex-grow-1">
                <h3 class="sticky_msg_header mb-0 self-middle">
                    <strong class="sticky_msg_header_text dark">{{ content.heading }}</strong>
                    <p class="sticky_msg_description self-middle">{{ content.description }}</p>
                </h3>
            </div>
            <span class="sticky_close icon" (click)="clearAlert()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
    </div>
</div>
