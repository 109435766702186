<div *ngIf="prescription" class="container">
	<div *ngIf="prescription.value.archived" class="container" style="height:100%;"
        [formGroup]="prescription"
        fxLayout="row wrap"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div class="item item-1" fxFlex="20%">
          <mat-form-field style="width:100%">
            <input matInput placeholder="Rx TYPE" type="text" formControlName="type" [readonly]="true" [(value)]="prescription.value.type">
          </mat-form-field>
        </div>
        <div class="item item-2" fxFlex="20%">
          <mat-form-field *ngIf="prescription.value.type=='OTHER'" style="width:100%">
            <input matInput placeholder="OTHER" type="text" [(value)]="prescription.value.typeOther" formControlName="typeOther" [readonly]="true">
          </mat-form-field>
        </div>
        <div class="item item-3" fxFlex="20%" style="text-align:right;">
          <mat-form-field class="date">
            <input matInput [matDatepicker]="expirationDate" (blur)="blurDate2(prescription)" placeholder="EXAM DATE" formControlName="expirationDate" disabled>
            <mat-datepicker #expirationDate></mat-datepicker>
          </mat-form-field>

        </div>
        <div class="item item-4" fxFlex style="text-align:right;" *ngIf="withDelete">
        	<mat-checkbox formControlName="archived" [(value)]="prescription.value.archived">HIDE</mat-checkbox>
        </div>
    </div>


	 <div *ngIf="!prescription.value.archived" class="container" style="height:100%;"
        [formGroup]="prescription"
        fxLayout="row wrap"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div *ngIf="prescription.value.images && withImage" fxFlex>
          <app-images [images]="prescription.value.images" [tmp]="false"></app-images>
        </div>

        <div *ngIf="image && withImage" fxFlex>
          <app-image [image]="image" [tmp]="true" type="prescription"></app-image>
        </div>

        <div class="container"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayoutGap="10px"
            fxLayoutGap.xs="0" fxFlex="calc(100%-310px)">


          <div class="container"
              fxLayout="row"
              fxLayoutAlign="space-between center">
            <div class="item item-1" fxFlex="20%">
              <mat-form-field>
                <mat-select placeholder="Rx TYPE" formControlName="type">
                  <mat-option *ngFor="let rxType of rxTypes" [(value)]="rxType.value">
                    {{ rxType.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="item item-2" fxFlex="20%">
              <mat-form-field *ngIf="prescription.value.type=='OTHER'" style="width:100%">
                <input matInput placeholder="OTHER" type="text" [(value)]="prescription.value.typeOther" formControlName="typeOther">
              </mat-form-field>
            </div>

            <div class="item item-3" fxFlex="25%" style="text-align:right;">
              <mat-form-field class="date">
                <input matInput [matDatepicker]="expirationDate" (blur)="blurDate2(prescription)" placeholder="EXAM DATE" formControlName="expirationDate">
                <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
                <mat-datepicker #expirationDate></mat-datepicker>
                  <mat-hint>MMDDYYYY</mat-hint>
              </mat-form-field>
            </div>

            <div class="item item-4" fxFlex style="text-align:right;" *ngIf="withDelete">
            	<div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
			      		<div class="item item-1"></div>
			      		<div class="item item-1">
			      			<mat-checkbox formControlName="archived" [(value)]="prescription.value.archived">HIDE</mat-checkbox>
			      		</div>
			      		<div class="item item-1">
			      			<mat-checkbox
					          (change)="$event ? toggleSelect(prescription) : null"
					          [checked]="selection.isSelected(prescription)">
					          COMPARE
					        </mat-checkbox>

			      		</div>
	        	 		<div class="item item-1">
	        	 			<button mat-raised-button type="button" (click)="deletePrescription($event);$event.stopPropagation()">DELETE</button>
	        	 		</div>
	           		<div class="item item-1">
	           			<button mat-raised-button type="button" (click)="setPrescriptionAsOrder($event);$event.stopPropagation()">SET AS ORDER</button>
	           		</div>
	         		</div>
            </div>
            <div style="flex:0 1 50px;display:flex;">
					  	<button mat-icon-button>
						    <mat-icon aria-label="PRINT" (click)="printImage(prescription.value.image)">print</mat-icon>
						  </button>
					  </div>
          </div>

          <div>
            <div class="container prescription"
			    fxLayout="column"
			    fxLayoutAlign="center">
			  <div class="item item-2" fxFlex>
			    <div class="container" fxLayout="row" fxLayoutAlign="space-between center" formGroupName="right">
			      <div class="item item-1" fxFlex="7%"><b>RIGHT</b></div>
			      <div class="item item-2" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="SPH" type="text" class="form-control" formControlName="sph">
			        </mat-form-field>
			      </div>
			      <div class="item item-3" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="CYL" type="text" class="form-control" formControlName="cyl">
			        </mat-form-field>
			      </div>
			      <div class="item item-4" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="AXIS" type="text" class="form-control" autocomplete="off" formControlName="axis">
			        </mat-form-field>
			      </div>
			      <div class="item item-5" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="ADD" type="text" class="form-control" formControlName="add">
			        </mat-form-field>
			      </div>
			      <div class="item item-6" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="SEG HGT" [readonly]="true" type="text" class="form-control" formControlName="segHgt">
			        </mat-form-field>
			      </div>
			      <div class="item item-7" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism1">
			        </mat-form-field>
			      </div>
			      <div class="item item-8" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <mat-select placeholder="B" formControlName="b1">
			            <mat-option value="U">U</mat-option>
			            <mat-option value="D">D</mat-option>
			            <mat-option value="O">O</mat-option>
			            <mat-option value="I">I</mat-option>
			          </mat-select>
			        </mat-form-field>
			      </div>
			      <div class="item item-9" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism2">
			        </mat-form-field>
			      </div>
			      <div class="item item-10" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <mat-select placeholder="B" formControlName="b2">
			            <mat-option value="U">U</mat-option>
			            <mat-option value="D">D</mat-option>
			            <mat-option value="O">O</mat-option>
			            <mat-option value="I">I</mat-option>
			          </mat-select>
			        </mat-form-field>
			      </div>
			      <div class="item item-11" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PD DIST" type="text" class="form-control" formControlName="pdd">
			        </mat-form-field>
			      </div>
			      <div class="item item-12" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PD NEAR" type="text" class="form-control" formControlName="pdn">
			        </mat-form-field>
			      </div>
			      <div class="item item-13" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="OC" type="text" class="form-control" formControlName="oc">
			        </mat-form-field>
			      </div>
			    </div>
			  </div>

			  <div class="item item-1" fxFlex>
			    <div class="container" fxLayout="row" fxLayoutAlign="space-between center" formGroupName="left">
			      <div class="item item-1" fxFlex="7%"><b>LEFT</b></div>
			      <div class="item item-2" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="SPH" type="text" class="form-control" formControlName="sph">
			        </mat-form-field>
			      </div>
			      <div class="item item-3" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="CYL" type="text" class="form-control" formControlName="cyl">
			        </mat-form-field>
			      </div>
			      <div class="item item-4" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="AXIS" type="text" class="form-control" autocomplete="off" formControlName="axis">
			        </mat-form-field>
			      </div>
			      <div class="item item-5" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="ADD" type="text" class="form-control" formControlName="add">
			        </mat-form-field>
			      </div>
			      <div class="item item-6" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="SEG HGT" [readonly]="true" type="text" class="form-control" formControlName="segHgt">
			        </mat-form-field>
			      </div>
			      <div class="item item-7" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism1">
			        </mat-form-field>
			      </div>
			      <div class="item item-8" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <mat-select placeholder="B" formControlName="b1">
			            <mat-option value="U">U</mat-option>
			            <mat-option value="D">D</mat-option>
			            <mat-option value="O">O</mat-option>
			            <mat-option value="I">I</mat-option>
			          </mat-select>
			        </mat-form-field>
			      </div>
			      <div class="item item-9" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism2">
			        </mat-form-field>
			      </div>
			      <div class="item item-10" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <mat-select placeholder="B" formControlName="b2">
			            <mat-option value="U">U</mat-option>
			            <mat-option value="D">D</mat-option>
			            <mat-option value="O">O</mat-option>
			            <mat-option value="I">I</mat-option>
			          </mat-select>
			        </mat-form-field>
			      </div>
			      <div class="item item-11" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PD DIST" type="text" class="form-control" formControlName="pdd">
			        </mat-form-field>
			      </div>
			      <div class="item item-12" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="PD NEAR" type="text" class="form-control" formControlName="pdn">
			        </mat-form-field>
			      </div>
			      <div class="item item-13" fxFlex="7%">
			        <mat-form-field style="width:100%">
			          <input matInput placeholder="OC" type="text" class="form-control" formControlName="oc">
			        </mat-form-field>
			      </div>
			    </div>
			  </div>
				<div class="item item-1" fxFlex *ngIf="withNotes">
					<mat-form-field style="width:100%">
						<input matInput placeholder="NOTES" value="" type="text" formControlName="notes">
					</mat-form-field>
				</div>

			</div>

          </div>
        </div>
    </div>


</div>
