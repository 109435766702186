<div *ngIf="isLoading" class="load-wrapper">
  <div class="load">
    <div class="load-content">
    <div class="load-spinner loader"><span class="arrow"></span></div>
    <div
      *ngIf="!content?.header && !content?.message"
      class="load-text loader"
    >
      {{ content?.loadingMessage ?? 'Loading' }}...
    </div>
  </div>
  </div>
</div>

<h1 *ngIf="content?.header" class="search-load-text mt-5 text-center">
  {{ content.header }}
</h1>
<h3
  *ngIf="content?.message"
  class="search-load-subtext mt-3 text-center"
>
  {{ content.message }}
</h3>
