import { Pipe, LOCALE_ID, Inject, Component, OnInit, Output, EventEmitter, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location, formatDate, formatNumber } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { FormatData } from '@classes/format-data';
import { ProductForm } from '@classes/product-form';
import { OrderForm } from '@classes/order-form';
import { OrderSort } from '@classes/order-sort';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Store } from '@models/store';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { Patient } from '@models/patient';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription, from } from 'rxjs';
import { filter, startWith, pairwise, map, catchError } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-orders-base',
  templateUrl: './orders-base.component.html',
  styleUrls: ['./orders-base.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class OrdersBaseComponent implements OnInit {
  @Input() public patient: Patient;
  @Input() public vendorOrders: VendorOrders;

  @Output() public vendorOrdersChange: EventEmitter<VendorOrders> =
    new EventEmitter<VendorOrders>();
  @Input() public orderIndex: number;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource = new MatTableDataSource<FormGroup>();
  dataSource2: MatTableDataSource<FormGroup>[] = [];
  // displayedColumns = ['alert', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'patientStock', 'patient', 'invoiceNumber', 'todo', 'notes', 'eta', 'action', 'log'];
  displayedColumns = ['alert', 'model', 'color', 'size', 'status', 'type'];
  displayedColumns2 = [];
  displayedColumns3 = [];
  orderForm: FormGroup;
  expandedElement = null;
  orderTypeOptions: Object[] = [
    { viewValue: 'O', value: 'O' },
    { viewValue: 'N', value: 'N' },
    { viewValue: 'A', value: 'A' },
    { viewValue: 'F', value: 'F' },
  ];
  productTypeOptions: Object[] = [
    { viewValue: 'RX', value: 'RX' },
    { viewValue: 'SUN', value: 'SUN' },
  ];
  storeOptions: any[];
  authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];
  authorized: boolean = false;
  dataLoading: boolean = false;

  fieldOpen: boolean = false;
  orderId: string;
  index: number;
  selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(
    true,
    []
  );
  productCosts: number[] = [];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public auth: AuthService,
    public orderService: OrderService,
    public vendorOrderService: VendorOrderService,
    public productService: ProductService,
    public storeService: StoreService,
    public cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public notificationService: NotificationService
  ) {
    this.storeOptions = this.storeService.stores;
    this.orderForm = new FormGroup({
      orders: new FormArray([]),
    });
    this.getAuthorized();
  }

  ngAfterViewInit() {
    this.setOrderForm(this.vendorOrders.orders);
    // this.authorized = this.authorizedRoles.includes(this.auth.currentUser.role);
  }

  ngOnInit() {}

  getAuthorized() {
    this.authorized = this.authorizedRoles.includes(this.auth.currentUser.role);
  }

  setOrderForm(orders: Order[]) {
    let orderFormArray = OrderForm.initOrderFormArray(
      orders,
      this.auth,
      this.storeService.stores
    );
    this.orderForm = this.fb.group({
      orders: orderFormArray,
    });
    this.setDataSource(this.orderForm.controls.orders['controls']);
    this.setDataSource2(this.orderForm.controls.orders['controls']);
    this.dataLoading = false;
    this.cdRef.detectChanges();
  }

  setDataSource(dataFormGroups: FormGroup[]) {
    this.dataSource = new MatTableDataSource<FormGroup>(dataFormGroups);
    OrderSort.setFilterPredicate(this.dataSource, this.selection);
    OrderSort.setSorter(this.dataSource, this.sort);
    this.cdRef.detectChanges();
  }

  setDataSource2(dataFormGroups: FormGroup[]) {
    for (var x = 0; x < dataFormGroups.length; x++) {
      this.dataSource2.push(
        new MatTableDataSource<FormGroup>(new Array(dataFormGroups[x]))
      );

      // let id = this.vendorOrders.orders[x]._id;
      // this.productCosts[id] = products[0].cost;
    }
  }

  @HostListener('document:click', ['$event']) clickOutside($event) {
    if (!this.fieldOpen) {
      if (this.orderId != '' && this.orderId != undefined) {
        //this.saveEditedProduct(this.editProduct, this.productIndex);
      }
      if (this.expandedElement) {
        this.expandedElement.get('notify').disable();
        this.expandedElement.get('products.0.duty').disable();
      }
      this.orderId = '';
      this.expandedElement = null;
    } else {
      this.fieldOpen = false;
    }
  }

  toggleExpanded($event, order: FormGroup, index) {
    if (!this.fieldOpen) {
      if (this.expandedElement !== order) {
        order.get('notify').enable();
        order.get('products.0.duty').enable();
        this.orderId = order.value._id;
        this.expandedElement = order;
      } else if (
        this.expandedElement === order &&
        this.orderId != order.value._id
      ) {
        this.orderId = order.value._id;
        this.expandedElement = order;
      }
    }
    this.fieldOpen = false;
    $event.preventDefault();
    $event.stopPropagation();
    this.cdRef.detectChanges();
  }

  clickOrder($event, order: FormGroup, index) {
    if (!this.fieldOpen) {
      if (this.orderIndex && this.index) {
        order.controls.notify.disable();
      }

      order.controls.notify.enable();
      this.orderId = order.value._id;
      this.index = index;
      //this.editProduct = product;
      //this.setProductForms(orderIndex);
      $event.preventDefault();
      $event.stopPropagation();
      this.fieldOpen = false;
      this.cdRef.detectChanges();
    }
  }

  clickField() {
    this.fieldOpen = true;
  }

  clickButton($event, order: FormGroup, index) {
    this.fieldOpen = true;
  }

  toggleDuty($event, order: FormGroup, index) {
    if (this.orderId == order.get('_id').value) {
      order
        .get('products.0.duty')
        .setValue(!order.get('products.0.duty').value);
      this.clickField();
    } else {
      this.toggleExpanded($event, order, index);
    }
  }

  toggleNotify($event, order: FormGroup, index) {
    if (this.orderId == order.get('_id').value) {
      order.get('notify').setValue(!order.get('notify').value);
      this.clickField();
    } else {
      this.toggleExpanded($event, order, index);
    }
  }

  clickDate($event, order: FormGroup, index) {
    this.clickField();
  }

  saveFrame(order: FormGroup, newOrder: boolean = false): Observable<any> {
    this.fieldOpen = true;

    if (!order.controls.notes.dirty) {
      order.controls.notes.setValue('');
    }
    if (order.value._id == 'NEW') {
      order.controls._id.setValue(null);
    }
    for (let x = 0; x < order.value.products.length; x++) {
      if (order.value.products[x]._id == 'NEW') {
        order.get(`products.${x}._id`).setValue(null);
      }
      let cost = order.get(`products.${x}.cost`);
      let retail = order.get(`products.${x}.retail`);
      if (typeof cost.value === 'string') {
        cost.setValue(parseFloat(cost.value.replace(/,/g, '')));
      }
      if (typeof retail.value === 'string') {
        retail.setValue(parseFloat(retail.value.replace(/,/g, '')));
      }
    }
    this.productCosts[order.value._id] = order.get('products.0.cost').value;

    if (!newOrder) {
      this.addDirtyValuesToLog(order);
    } else {
      this.addCurrentActionToLog(order, order.value.todo);
    }

    return this.orderService.updateOrderFull(order.value).pipe(
      map((order2) => {
        order.get('_id').setValue(order2._id);
        order.get('initials').setValue(null);
        order.get('_id').setValue(order2._id);
        order.markAsPristine();
        order.markAsUntouched();
        this.closeExpandedElement(order);
        return true;
      })
    );
  }

  saveAndUpdateInventory(
    order: FormGroup,
    newOrder: boolean = false
  ): Observable<any> {
    this.fieldOpen = true;

    if (!order.controls.notes.dirty) {
      order.controls.notes.setValue('');
    }
    if (order.value._id == 'NEW') {
      order.controls._id.setValue(null);
    }
    for (let x = 0; x < order.value.products.length; x++) {
      if (order.value.products[x]._id == 'NEW') {
        order.get(`products.${x}._id`).setValue(null);
      }
      let cost = order.get(`products.${x}.cost`);
      let retail = order.get(`products.${x}.retail`);
      let product: Product = order.value.products[x].product;
      if (typeof cost.value === 'string') {
        let costFloat = parseFloat(cost.value.replace(/,/g, ''));
        cost.setValue(costFloat);
        product.cost = costFloat;
      } else {
        product.cost = cost.value;
      }
      if (typeof retail.value === 'string') {
        let retailFloat = parseFloat(retail.value.replace(/,/g, ''));
        retail.setValue(retailFloat);
        product.retail = retailFloat;
      } else {
        product.retail = retail.value;
      }

      if (product._id) {
        this.productService
          .updateProduct(product._id, product)
          .subscribe((product2) => {
            console.log(product2);
          });
      }
    }

    if (!newOrder) {
      this.addDirtyValuesToLog(order);
    } else {
      this.addCurrentActionToLog(order, order.value.todo);
    }
    // this.addCurrentActionToLog(order, 'UPDATED');

    return this.orderService.updateOrderFull(order.value).pipe(
      map((order2) => {
        order.get('_id').setValue(order2._id);
        order.get('initials').setValue(null);
        order.get('_id').setValue(order2._id);
        order.markAsPristine();
        order.markAsUntouched();
        this.closeExpandedElement(order);
        return true;
      })
    );
  }

  addDirtyValuesToLog(order) {
    let dirtyValues = this.getDirtyValues(order);
    dirtyValues = this.flattenObject(dirtyValues);
    Object.keys(dirtyValues).forEach((key) => {
      if (!['initials', 'notes'].includes(key)) {
        let logItem = new LogElement();
        logItem.initials = order.value.initials;
        logItem.user = this.auth.currentUser._id;
        logItem.item = key + ': ' + dirtyValues[key]; // + ' (' + this.auth.currentUser.initials + ')';
        logItem.notes = '';
        logItem.date = Date.now().toString();
        order.value.log.unshift(logItem);
      }
    });
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  flattenObject(obj: any) {
    return Object.assign(
      {},
      ...(function _flatten(o) {
        if (o) {
          return [].concat(
            ...Object.keys(o).map((k) =>
              typeof o[k] === 'object' && !(o[k] instanceof Date)
                ? _flatten(o[k])
                : { [k]: o[k] }
            )
          );
        } else {
          return [];
        }
      })(obj)
    );
  }

  addCurrentActionToLog(order, item) {
    let logItem = new LogElement();
    logItem.initials = order.value.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = item;
    logItem.notes = order.value.notes;
    logItem.date = Date.now().toString();
    order.value.log.unshift(logItem);
  }

  closeExpandedElement(order: FormGroup) {
    order.get('notify').disable();
    order.get('products.0.duty').disable();
    this.orderId = null;
    this.expandedElement = null;
  }

  orderFrame(order: FormGroup, index) {
    this.fieldOpen = true;
    order.get('status').setValue('AWAITING FRAME');
    let saveOrder: Order = new Order();
    if (order.controls.notes.dirty) {
      saveOrder.notes = order.value.notes;
    } else {
      saveOrder.notes = '';
      order.controls.notes.setValue('');
    }

    this.addDirtyValuesToLog(order);
    this.addCurrentActionToLog(order, 'ORDERED');

    this.orderService.updateOrderFull(order.value).subscribe((order2) => {
      let ordersFormArray: FormArray = <FormArray>(
        this.orderForm.controls.orders
      );

      let dataIndex = this.dataSource.data.findIndex((d) => d === order);

      ordersFormArray.removeAt(dataIndex);
      this.setDataSource(this.orderForm.controls.orders['controls']);

      this.vendorOrders.orders.splice(dataIndex);
      this.vendorOrdersChange.emit(this.vendorOrders);
      this.closeExpandedElement(order);
    });
  }

  cancelFrame(order): Observable<any> {
    this.fieldOpen = true;
    order.get('status').setValue('CANCELED');

    this.addCurrentActionToLog(order, 'CANCELED');

    if (this.orderForm.value.orders.length < 1) {
      this.expandedElement = null;
      this.displayedColumns = ['alert', 'vendor', 'count'];
    }

    return this.orderService.updateOrderStatusAndLog(order.value).pipe(
      map((order2) => {
        let ordersFormArray: FormArray = <FormArray>(
          this.orderForm.controls.orders
        );
        let dataIndex = this.dataSource.data.findIndex((d) => d === order);
        ordersFormArray.removeAt(dataIndex);
        this.setDataSource(this.orderForm.controls.orders['controls']);
        this.closeExpandedElement(order);
        this.cdRef.detectChanges();
        return true;
      })
    );
  }

  addOrder($event, status?) {
    let newProduct: ProductElement = new ProductElement();
    newProduct.quantity = 1;
    newProduct.frame.frameSource = 'PATIENT';

    let newOrder: Order = new Order();
    newOrder.patient = this.patient._id;
    newOrder.status = status ? status : '';
    newOrder.type = 'O';
    newOrder.date = this.transformDate(Date.now());
    newOrder.user = this.auth.currentUser._id;
    newOrder.store = this.auth.getStore();

    newProduct.product.vendor = new Vendor();
    newProduct.product.vendor.images = null;
    newProduct.product.store = this.auth.getStoreObject();
    newOrder.products.push(newProduct);
    //console.log(newOrder)

    let orderFormGroup = OrderForm.initOrderFormGroup(
      newOrder,
      this.auth,
      this.storeOptions
    );

    const control = <FormArray>this.orderForm.controls['orders'];
    // const itemsCtrl = this.initOrderFormItem(newOrder);
    orderFormGroup.get('products.0.duty').enable();
    control.insert(0, orderFormGroup);

    this.setDataSource(this.orderForm.controls.orders['controls']);
    this.dataSource2.unshift(
      new MatTableDataSource<FormGroup>(
        new Array(this.orderForm.controls.orders['controls'][0])
      )
    );

    this.orderId = newOrder._id;
    this.expandedElement = orderFormGroup;
    $event.preventDefault();
    $event.stopPropagation();
  }

  updateProduct($event: ProductQuery, order: FormGroup, productIndex) {
    let productElements: FormArray = order.get('products') as FormArray;
    let productElement: FormGroup = productElements.at(0) as FormGroup;
    let product: FormGroup = productElement.get('product') as FormGroup;
    let vendor: Vendor = product.value.vendor;
    let query = product.value.query;
    if (query.model.selected && query.color.selected && query.size.selected) {
      this.productService
        .queryVendorProductsFields(vendor._id, $event)
        .pipe(
          catchError((err) => {
            console.log(err);
            return of([]);
          })
        )
        .subscribe((res) => {
          try {
            if (res?.[0]) {
              delete res[0].__v;
            }
            let selectedProduct: Product = res[0] as Product;
            selectedProduct.query = query;
            selectedProduct.queryProducts = product.value.queryProducts;
            selectedProduct.borrowing = product.value.borrowing;
            selectedProduct.pricingFormula = product.value.pricingFormula;

            let imageArray: FormArray = this.fb.array(selectedProduct.images);
            product.removeControl('images');
            delete selectedProduct.images;
            product.removeControl('return');
            product.patchValue(selectedProduct);
            product.addControl('images', imageArray);

            const productElementValue = {
              cost: selectedProduct.cost,
              pricingFormula: selectedProduct.pricingFormula,
              retail: selectedProduct.retail,
              type: selectedProduct.type,
            };
            productElement.patchValue(productElementValue);

            // order.get('vendor').setValue(product.value.vendor);
            this.formatCurrency(null, productElement.get('cost'));
            this.formatCurrency(null, productElement.get('retail'));

            if (selectedProduct.type == 'RX') {
              order.get('type').patchValue('O');
            } else if (selectedProduct.type == 'SUN') {
              order.get('type').patchValue('N');
            }
            this.cdRef.detectChanges();
          } catch (err) {
            console.log(err);
          }
        });
    }
  }

  changeProductType($event, order: FormGroup): boolean {
    this.changeCost(order);
    let productElements: FormArray = order.get('products') as FormArray;
    let productElement: FormGroup = productElements.at(0) as FormGroup;
    let product: FormGroup = productElement.get('product') as FormGroup;
    let vendor: Vendor = product.get('vendor').value as Vendor;
    if (productElement.get('type').value == 'RX') {
      order.get('type').setValue('O');
      order
        .get('products.0.pricingFormula')
        .setValue(vendor.pricingFormulaOptical);
    } else if (productElement.get('type').value == 'SUN') {
      order.get('type').setValue('N');
      order.get('products.0.pricingFormula').setValue(vendor.pricingFormulaSun);
    }
    return true;
  }

  togglePriceLink($event, order: FormGroup) {
    this.fieldOpen = true;
    if (this.authorized) {
      if (order.value.products[0].pricingFormula == 'c') {
        let product: Product = new Product();
        let vendor: Vendor = order.get('products.0.product.vendor')
          .value as Vendor;
        product.cost = order.value.products[0].cost;
        product.type = order.value.products[0].type;
        product.pricingFormula =
          product.type == 'RX'
            ? vendor.pricingFormulaOptical
            : vendor.pricingFormulaSun;
        order.controls['products']['controls'][0]['controls'][
          'pricingFormula'
        ].setValue(product.pricingFormula);
        order.controls['products']['controls'][0]['controls'][
          'retail'
        ].setValue(Product.calculateProductPrice(product));
      } else {
        order.controls['products']['controls'][0]['controls'][
          'pricingFormula'
        ].setValue('c');
      }
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  changeRetail(order: FormGroup) {
    let vendor: Vendor = order.get('products.0.product.vendor').value as Vendor;
    let product: FormGroup = order.get('products.0') as FormGroup;
    ProductForm.changeRetail(product, vendor, true);
  }

  changeCost(order: FormGroup) {
    let vendor: Vendor = order.get('products.0.product.vendor').value as Vendor;
    let product: FormGroup = order.get('products.0') as FormGroup;
    ProductForm.changeCost(product, vendor, true);
    this.calculateTotal();
  }

  calculateTotal() {
    if (this.orderForm.get('total')) {
      let total = 0;
      for (let x = 0; x < this.selection.selected.length; x++) {
        let id = this.selection.selected[x].value._id;
        let cost = FormatData.formatFloat(
          this.selection.selected[x].value.products[0].cost
        );
        total = total + cost;
      }
      let tax = FormatData.formatFloat(this.orderForm.value.tax);
      let shipping = FormatData.formatFloat(this.orderForm.value.shipping);
      if (!isNaN(tax)) {
        total = total + tax;
      }
      if (!isNaN(shipping)) {
        total = total + shipping;
      }
      this.orderForm.get('total').setValue(total);
      this.cdRef.detectChanges();
    }
  }

  formatCurrency($event, control) {
    if (control != undefined) {
      let value = isNaN(control.value) ? 0 : control.value;
      if (typeof control.value == 'string') {
        let tmpValue = parseFloat(control.value.replace(',', ''));
        value = isNaN(tmpValue) ? 0 : tmpValue;
      }
      control.setValue(formatNumber(value, 'en-US', '1.2-2'));
    }
  }

  transformDate(date) {
    return formatDate(date, 'MM/dd/yyyy', this.locale);
  }

  getDisplayedColumns2(order: FormGroup): string[] {
    if (order.value.products[0].duty) {
      return this.displayedColumns3;
    } else {
      return this.displayedColumns2;
    }
  }

  onCancelFrame(order) {
    this.cancelFrame(order).subscribe(() => {
      this.notificationService.show('success', 'ORDER SUCCESSFULLY CANCELED');
    });
  }
}
