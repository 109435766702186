<div *ngIf="dataSource.data.length < 1">
	<h3 style="text-align:center;">LOADING INVENTORY</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="container" style="width:100%" *ngIf="dataSource.data.length > 0">
  <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">

    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span style="font-weight:bold;"> {{ vendor.name }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="vendor.products.length != 1"> {{ vendor.products.length }} FRAMES</span>
        <span *ngIf="vendor.products.length == 1"> {{ vendor.products.length }} FRAME</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="vendor!=expandedElement" [class.mat-elevation-z8]="vendor==expandedElement" [@detailExpand]="vendor == expandedElement ? 'expanded' : 'collapsed'">

        <app-duplicate-products *ngIf="vendor==expandedElement" [vendorInventory]="vendor" style="width:100%;"></app-duplicate-products>

        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row 
      *matHeaderRowDef="displayedColumns" 
      fxLayout="row" 
      fxLayoutAlign="start center">
    </mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
      fxLayout="row" 
      fxLayoutAlign="start center" 
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
      style="border-left:1px solid #dfdfdf !important;" 
      (click)="toggleExpanded($event, row, i)">
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>
  </mat-table>
</div>


