import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Product, ProductHistory } from '@models/product';
import { Order } from '@models/order';
import { ProductService } from '@services/product.service';
import { OrderService } from '@services/order.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-duplicate-products-history',
  templateUrl: './duplicate-products-history.component.html',
  styleUrls: ['./duplicate-products-history.component.css']
})
export class DuplicateProductsHistoryComponent implements OnInit {
  @Input() public product: any = null;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	dataSource = new MatTableDataSource<ProductHistory>();
	dataSource2 = new MatTableDataSource<ProductHistory>();
	displayedColumns: string[] = ['id', 'size', 'images', 'quantity', 'orders', 'invoiceNumber', 'date', 'cost', 'tax', 'shipping', 'duty', 'patient', 'patientStock', 'store',];
  defaultSortColumn:string = 'date';
  productObject: Product;
  orders: Order[];

  constructor(private productService: ProductService, private orderService: OrderService, private vendorOrderService: VendorOrderService, private cdRef:ChangeDetectorRef) {}

  ngOnInit() {
  }

	ngAfterViewInit() {
  	this.getProductHistory();
	}

  getProductHistory() {
    this.productService.getProduct(this.product).subscribe((productObject: Product) => {
    	this.productObject = productObject;

	    	this.vendorOrderService.getProductHistory(this.product).subscribe((productHistory: ProductHistory[]) => {

	    		this.orderService.getProductOrders(this.product).subscribe((orders: Order[]) => {
		    		this.orders = orders;

		  			if (productHistory.length == 0) {
							this.displayedColumns = ['id', 'size', 'images', 'quantity', 'orders', 'noHistory'];
		  				productHistory = [new ProductHistory()];
		  				productHistory[0].product = this.productObject;
		  			}
		    		this.dataSource = new MatTableDataSource<ProductHistory>(productHistory);
		    		this.cdRef.detectChanges(); 
		  		});
  		});

    });
	}

}
