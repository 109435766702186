<!--<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="SEARCH INVENTORY" (input)="productSearch()">
</mat-form-field>-->


<form [formGroup]="productsForm" enctype="multipart/form-data" (ngSubmit)="submit()" autocomplete="off">
	<input type="hidden" autocomplete="off">
		<div class="container"
		  fxLayout="row"
		  fxLayoutAlign="start center"
		  fxLayoutGap="10px"
		  style="padding:0px 10px 0px 10px;">
		<div class="item item-1" fxFlex="1 1 150px">
			<mat-form-field floatLabel="always">
				<mat-select #formRow #statusSelect placeholder="SHOW" formControlName="showStock" (selectionChange)="filterProductStock()">
					<mat-option value="all">ALL</mat-option>
					<mat-option value="stock">IN-STOCK</mat-option>
					<mat-option value="return">RETURNED</mat-option>
					<mat-option value="rx">RX</mat-option>
					<mat-option value="sun">SUN</mat-option>
					<mat-option value="parts">PARTS</mat-option>
				</mat-select>
      </mat-form-field>

			<!-- <mat-radio-group formControlName="showStock2" (change)="filterProductStock()">
				<mat-radio-button value="all">SHOW ALL</mat-radio-button> &nbsp;
				<mat-radio-button value="stock">SHOW IN-STOCK</mat-radio-button>
			</mat-radio-group> -->
		</div>
		<div fxFlex="1 1 calc(65% - 430px)">
			<mat-form-field floatLabel="never" style="width:100% !important;">
	      <input matInput placeholder="SEARCH" type="text" formControlName="search" autocomplete="off" (keyup.enter)="searchControlEnter($event)">
	      <!-- <input matInput placeholder="SEARCH" type="text" formControlName="search" (keyup)="filterProducts($event.target.value)" autocomplete="off"> -->
	    </mat-form-field>
	  </div>
	  <div fxFlex="0 0 160px">
			<button mat-raised-button (click)="showEverything()">SHOW EVERYTHING</button>
	  </div>

	  <div fxFlex="0 0 35%">
		  <h3 style="text-align:center;" *ngIf="dataLoading">LOADING INVENTORY</h3>
		  <mat-progress-bar mode="indeterminate" *ngIf="dataLoading"></mat-progress-bar>
		</div>
	</div>

  <mat-table #table [dataSource]="dataSource"  matSort formArrayName="productsFormArray" multiTemplateDataRows>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px" style="padding-left:0px !important;">
        <mat-checkbox (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelect(vendor,order) : null"
          [checked]="selection.isSelected(order)"
          [aria-label]="checkboxLabel(order,i)">
        </mat-checkbox>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 100px" class="alignLeftCellFooter" style="padding-left:0px !important;"> TOTAL </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="images">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 150px">
      	<ng-container *ngIf="selection.hasValue()">
					<app-inventory-menu [selection]="selection" [productsForm]="productsForm" (borrowing)="borrowMultipleProducts($event)" (updateProducts)="updateProducts()"></app-inventory-menu>
				</ng-container>
      	<ng-container *ngIf="!selection.hasValue()">
          IMAGE
				</ng-container>
			</mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex; " fxFlex="0 0 150px" [formGroupName]="i">
        <app-images
					[images]="product.value.images"
					[urls]="imageUrls"
					type="product"
					(imagesChange)="updateImages($event,product,productsForm.controls.productsFormArray['controls'][i])"
					[edit]="true"
					[delete]="true"
					[upload]="true"
					[autoCrop]="true"
					[active]="productId==product.value._id"
					[productId]="product.value._id"
					(click)="clickImage($event,product,i)">
				</app-images>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 85px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="vendor" *ngIf="!vendor">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 10%"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex; let row;" fxFlex="1 0 10%" [formGroupName]="i">
      	<button *ngIf="product.value.vendor" mat-button (click)="clickField($event, product, i)" [routerLink]="['/admin/vendors', product.value.vendor._id]" style="width:90%;">{{product.value.vendor.name}}</button>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 10%"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="model">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 10%"> MODEL </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex; let row; let even=even; let odd=odd;" fxFlex="1 0 10%" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.model}}</span>
      	<app-autocomplete-vendor-products
	  			[productForm]="product"
	    		[vendor]="_vendor"
	  			placeholder="MODEL"
	  			*ngIf="productId==product.value._id && !showParts"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="model"
					style="width:90%"
	  			>
				</app-autocomplete-vendor-products>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 10%"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 75px"> COLOR </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="1 0 75px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.color}}</span>
      	<app-autocomplete-vendor-products
	  			[productForm]="product"
	    		[vendor]="_vendor"
	  			placeholder="COLOR"
	  			*ngIf="productId==product.value._id && !showParts"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="color"
					style="width:90%"
	  			>
				</app-autocomplete-vendor-products>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 2.5%"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> SIZE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 40px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.size}}</span>
      	<app-autocomplete-vendor-products
	  			[productForm]="product"
	    		[vendor]="_vendor"
	  			placeholder="SIZE"
	  			*ngIf="productId==product.value._id && !showParts"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="size"
					style="width:90%"
	  			>
				</app-autocomplete-vendor-products>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 75px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 65px"> STATUS </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 65px" style="padding-left:0px;min-width:calc(100%-32px);" [ngClass]="product.value.status" [formGroupName]="i">

      	<mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
					<mat-select #formRow #statusSelect placeholder="STATUS" [formControl]="product.controls.status" (selectionChange)="changeProductStatus($event,product,i)" appClickField [(fieldOpen)]="fieldOpen">
						<mat-option value="green" class="green"></mat-option>
						<mat-option value="yellow" class="yellow"></mat-option>
						<mat-option value="red" class="red"></mat-option>
						<mat-option value="purple" class="purple"></mat-option>
						<mat-option value="white" class="white"></mat-option>
						<mat-option value="black" class="black"></mat-option>
					</mat-select>
        </mat-form-field>


<!-- 				<mat-form-field *ngIf="productId==product.value._id" floatLabel="never" style="min-width:calc(100%-32px);" class="statusSelect" style="min-width:65px;">

					<mat-select #statusSelect #formRow placeholder="" [formControl]="product.controls.status" panelClass="status-select-panel"  (click)="clickField($event, product.value, i);$event.stopPropagation();" (selectionChange)="changeProductType($event,product,i)">
						<mat-option value="green" class="green"></mat-option>
						<mat-option value="yellow" class="yellow"></mat-option>
						<mat-option value="red" class="red"></mat-option>
						<mat-option value="white" class="white"></mat-option>
						<mat-option value="black" class="black"></mat-option>
					</mat-select>
        </mat-form-field> -->


<!--
      	<mat-form-field *ngIf="clickedStatus==i+paginator.pageSize*paginator.pageIndex" floatLabel="never" style="width:20px;">

					<mat-select #statusSelect #formRow placeholder="" formControlName="status" (click)="clickRush($event,product,i+paginator.pageSize*paginator.pageIndex)" (selectionChange)="changeStatus($event,product,i+paginator.pageSize*paginator.pageIndex)" class="statusSelect" panelClass="status-select-panel" disableRipple style="width:20px;">
						<mat-option value="green" class="green"><span style="width:20px;">G</span></mat-option>
						<mat-option value="yellow" class="yellow"><span style="width:20px;">Y</span></mat-option>
						<mat-option value="red" class="red"><span style="width:20px;"></span>R</mat-option>
						<mat-option value="red" class="white"><span style="width:20px;"></span>x</mat-option>
					</mat-select>
        </mat-form-field>
        -->

      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 65px"> </mat-footer-cell>
    </ng-container>
<!--
    <ng-container matColumnDef="commission">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 2.5%"> COMM. </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" [formGroupName]="i" fxFlex="1 0 2.5%">
      	<span *ngIf="productId!=product._id">{{product.commission}}</span>
				<mat-form-field *ngIf="productId==product._id" floatLabel="never">
					<mat-select #formRow placeholder="COMM." formControlName="commission" (click)="clickCommission($event,product,i)">
						<mat-option *ngFor="let commissionOption of commissionOptions" [value]="commissionOption.value">
							{{commissionOption.viewValue}}
						</mat-option>
					</mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>
-->

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> TYPE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px" [formGroupName]="i">
      	<span *ngIf="(productId!=product.value._id && product.value._id!='NEW') || showParts">{{product.value.type}}</span>
				<mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
					<mat-select #formRow placeholder="TYPE" [formControl]="product.controls.type" (click)="clickField($event,product,i);$event.stopPropagation();" (selectionChange)="changeProductType(product)">
						<mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">
							{{typeOption.viewValue}}
						</mat-option>
					</mat-select>
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 50px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> COST </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 60px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || !authorized || showParts">{{ product.value.cost | currency }}</span>
				<mat-form-field *ngIf="productId==product.value._id && authorized && !showParts" floatLabel="never">
          <input #formRow matInput placeholder="COST" type="text" [formControl]="product.controls.cost" (keyup)="changeCost(product)" appClickField [(fieldOpen)]="fieldOpen">
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 75px"> {{ getTotalCost() | currency }} </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="priceLink">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 35px" [formGroupName]="i">
        <mat-icon (click)="togglePriceLink($event,product,i+paginator.pageSize*paginator.pageIndex)" *ngIf="product.value.pricingFormula!='c'">link_outline</mat-icon>
        <mat-icon (click)="togglePriceLink($event,product,i+paginator.pageSize*paginator.pageIndex)" *ngIf="product.value.pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 35px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="retail">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> RETAIL </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 60px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || !authorized || showParts">{{ product.value.retail | currency }}</span>
				<mat-form-field *ngIf="productId==product.value._id && authorized && !showParts" floatLabel="never">
          <input #formRow matInput placeholder="RETAIL" type="text" [formControl]="product.controls.retail" (keyup)="changeRetail(product)" appClickField [(fieldOpen)]="fieldOpen">
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 75px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="store">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> STORE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 10%" [formGroupName]="i">
      	<span *ngIf="(productId!=product.value._id && product.value.store) || showParts">{{product.value.store.storeName}}</span>
        <mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
					<mat-select #formRow placeholder="STORE" formControlName="store" (click)="clickField($event,product,index);$event.stopPropagation();">
						<mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption">
							{{storeOption.storeName}}
						</mat-option>
					</mat-select>
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 10%"> </mat-footer-cell>
    </ng-container>
<!--
	  <ng-container matColumnDef="store">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 5%"> STORE </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 0 5%" [formGroupName]="i">
				<span *ngIf="orderId!=order.value._id"> {{ order.value.products[0].store.storeName }} </span>
      	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
						<mat-form-field *ngIf="orderId==order.value._id" style="width:90%" floatLabel="never">
		          <mat-select placeholder="STORE" formControlName="store" (click)="clickField(order, orderIndex);$event.stopPropagation();">
		            <mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption">{{storeOption.storeName}}</mat-option>
		          </mat-select>
		        </mat-form-field>
		      </ng-container>
		    </ng-container>
      </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> QTY. </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.quantity}}</span>
				<mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
          <input #formRow matInput placeholder="QTY." type="text" [formControl]="product.controls.quantity" appClickField [(fieldOpen)]="fieldOpen">
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 65px"> {{ getTotalQty() }} </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="orderDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> ORDER DATE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 10%" [formGroupName]="i">
      	<span>{{ product.orderDate | date:'M/d/yyyy': 'GMT' }}</span>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 10%"> </mat-footer-cell>
    </ng-container>
<!--
    <ng-container matColumnDef="backordered">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px" style="justify-content:center;"> B.O. </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" [formGroupName]="i" fxFlex="0 0 50px">
      	<mat-slide-toggle formControlName="backordered" (click)="clickRush($event,product,i)" (change)="changeRush($event,product,i)" color="primary"></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="wishlist">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px" style="justify-content:center;"> WISH LIST </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" [formGroupName]="i" fxFlex="0 0 50px">
      	<mat-slide-toggle formControlName="wishlist" (click)="clickRush($event,product,i)" (change)="changeRush($event,product,i)" color="primary"></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sold">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px" style="justify-content:center;"> SOLD </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" [formGroupName]="i" fxFlex="0 0 50px">
      	<span *ngIf="productId!=product._id">{{product.sold}}</span>
				<mat-form-field *ngIf="productId==product._id" floatLabel="never">
          <input #formRow matInput placeholder="SOLD" type="text" formControlName="sold">
        </mat-form-field>
      </mat-cell>
    </ng-container>
-->

		<ng-container matColumnDef="borrow">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 120px"><ng-container *ngIf="borrowingView">BORROW</ng-container></mat-header-cell>
	    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 120px" [formGroupName]="i">
      	<!-- <button mat-button *ngIf="productId!=product.value._id && borrowingView" (click)="borrowProduct($event,product,i)">
      		<mat-icon>forward</mat-icon>
      		<span>BORROW</span>
      	</button> -->
      	<ng-container *ngIf="_vendor.borrowingStores?.includes(currentStore._id); else notBorrowingBlock">
	      	<ng-container *ngFor="let borrowing of product.value.borrowing">
	      		{{ product.value.store.storeName }}
	      	</ng-container>
      	</ng-container>
      	<ng-template #notBorrowingBlock>
					<ng-container *ngIf="!borrowingView || product.value.quantity - (allRentingQtyByProduct[product.value._id] ?? 0) - getBorrowingSum(product.value.borrowing || []) === 0">
						<ng-container *ngFor="let borrowing of product.value.borrowing">
							{{ storeService.getStoreObjectFromId(borrowing.store)?.storeName }}
							({{borrowing.quantity}})
						</ng-container>
					</ng-container>

					<mat-checkbox *ngIf="productId!=product.value._id && borrowingView && product.value.quantity - (allRentingQtyByProduct[product.value._id] ?? 0) - getBorrowingSum(product.value.borrowing || []) > 0"
						(click)="$event.stopPropagation()"
						(change)="$event ? toggleSelectBorrow(vendor,product) : null"
						[checked]="borrowingSelection.isSelected(product)">
					</mat-checkbox>
      	</ng-template>

        <!-- <button mat-button *ngIf="productId==product.value._id" [matMenuTriggerFor]="menu" style="padding:0 10px 0 5px !important;"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
	      <mat-menu #menu="matMenu">
	    	 	<button mat-menu-item type="button" (click)="saveEditedProduct($event,product,i,true)" style="margin-left:10px;">ORDERED</button>
	    	 	<button mat-menu-item type="button" (click)="saveEditedProduct($event,product,i)" style="margin-left:10px;">SAVE</button>
	      </mat-menu> -->
	    </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 100px"> </mat-footer-cell>
		</ng-container>

		<ng-container matColumnDef="rent">
			<mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 120px">
				<ng-container *ngIf="user.role === 'RENTAL'; else rentHeaderBlock">BORROW</ng-container>
				<ng-template #rentHeaderBlock>RENT</ng-template>
			</mat-header-cell>
			<mat-cell *matCellDef="let product; let i = dataIndex;" fxFlex="0 0 120px" [formGroupName]="i">
      	<mat-checkbox *ngIf="!_vendor.borrowingStores?.includes(currentStore._id) && productId !== product.value._id && user.role !== 'RENTAL' && rentingView && rentingUser && product.value.quantity - (allRentingQtyByProduct[product.value._id] ?? 0) - getBorrowingSum(product.value.borrowing || []) > 0"
      		(click)="$event.stopPropagation()"
          (change)="onSelectRenting($event, product.value._id, product.value.vendor)"
          [checked]="getUpdatedRenting(product.value._id)?.checked">
				</mat-checkbox>
				<ng-container *ngIf="(user.role === 'RENTAL' || (rentingUser && (!rentingView || product.value.quantity - (allRentingQtyByProduct[product.value._id] ?? 0) - getBorrowingSum(product.value.borrowing || []) === 0))) && getRenting(product.value._id)">
					{{ getRenting(product.value._id).qty }}
				</ng-container>
				<ng-container *ngIf="!rentingUser && user.role !== 'RENTAL' && product.value.quantity > 0">
					<div fxLayout="column">
						<ng-container *ngFor="let rentsObj of rentingList | keyvalue">
							<ng-container *ngFor="let rent of rentsObj.value">
								<span *ngIf="rent.product._id === product.value._id">
									{{ rentsObj.key }} ({{ rent.qty }})
								</span>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>
	    </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 100px"> </mat-footer-cell>
		</ng-container>

 		<ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 120px"></mat-header-cell>
	    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 120px" [formGroupName]="i">
      	<!-- <button mat-button *ngIf="productId!=product.value._id && borrowingView" (click)="borrowProduct($event,product,i)">
      		<mat-icon>forward</mat-icon>
      		<span>BORROW</span>
      	</button> -->
<!--
      	<mat-checkbox *ngIf="productId!=product.value._id && borrowingView"
      		(click)="$event.stopPropagation()"
          (change)="$event ? toggleSelectBorrow(vendor,product) : null"
          [checked]="borrowingSelection.isSelected(product)">
        </mat-checkbox> -->

        <button mat-button *ngIf="productId==product.value._id" [matMenuTriggerFor]="menu" (click)="clickField($event,product,i)" style="padding:0 10px 0 5px !important;"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
	      <mat-menu #menu="matMenu">
	    	 	<button mat-menu-item type="button" (click)="saveEditedProduct($event,product,i,true)" [disabled]="differentStoreSelected" style="margin-left:10px;">ORDERED</button>
	    	 	<button mat-menu-item type="button" (click)="saveEditedProduct($event,product,i)" [disabled]="differentStoreSelected" style="margin-left:10px;">SAVE</button>
	      </mat-menu>
	    </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 100px"> </mat-footer-cell>
	  </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"></mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px" [formGroupName]="i">
				<mat-icon (click)="deleteProduct($event, product,i+paginator.pageSize*paginator.pageIndex)">delete_outline</mat-icon>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 50px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let product; let orderIndex=dataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;padding: 20px 0px 20px 0px;" [class.mat-elevation-z0]="product!=expandedElement" [class.mat-elevation-z8]="product==expandedElement && product.value._id!='NEW'" [@detailExpand]="product == expandedElement && product.value._id!='NEW' ? 'expanded' : 'collapsed'" fxLayout="column" fxLayoutAlign="space-around start">

	        <div style="width:1000px; margin-left:205px;" fxFlex>
		       	<mat-form-field style="width:100%;">
		          <input #formRow matInput placeholder="NOTES" type="text" [formControl]="product.controls.notes" appClickField [(fieldOpen)]="fieldOpen">
		        </mat-form-field>
		      </div>
	        <div style="width:1000px; margin-left:205px;" fxFlex>
		        <app-inventory-history *ngIf="product==expandedElement && product.value._id!='NEW'" [product]="product" [showParts]="showParts" (clickField)="clickField($event,product,orderIndex)" style="width:100%;"></app-inventory-history>
		      </div>
        </div>
      </mat-cell>
    	<!-- <mat-footer-cell *matFooterCellDef> </mat-footer-cell> -->
    </ng-container>

    <mat-header-row
    	*matHeaderRowDef="displayedColumns"
    	fxLayout="row"
    	fxLayoutAlign="none center"></mat-header-row>
    <!-- <mat-row *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="none center" appHighlight [ngClass]="{rowEven:even, rowOdd:odd}"></mat-row> -->
    <mat-row
    	*matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;"
    	fxLayout="row"
    	fxLayoutAlign="none center"
    	(click)="clickRow($event,row,i)"
    	(dblclick)="clickRow($event,row,i)"
    	[class.dirty-row]="row.dirty"
    	[ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}"
    	[class.return]="row.value.status=='return'"
    	[class.returned]="row.value.status=='returned'"
    	color="warn"
    	></mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>


    <ng-container *ngIf="authorizedSendToStack">
	  	<mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
	  </ng-container>



  </mat-table>

	<div class="container"
		  fxLayout="row"
		  fxLayoutAlign="start center"
		  fxLayoutGap="10px">
		<div class="item item-1" fxFlex="50%">
		  <button mat-raised-button *ngIf="_vendor && user.role !== 'RENTAL'" type="button" (click)="addProduct($event)" style="margin-left:10px;">+ ADD PRODUCT</button>
		</div>
		<div class="item item-2" fxFlex="50%">
			<mat-paginator #paginator
				[length]="dataSource.data.length"
				[pageSize]="dataSource.data.length">
				<!--[pageSize]="10"
				[pageSizeOptions]="[0,10,20,100]">-->
			</mat-paginator>
		</div>
	</div>
</form>
