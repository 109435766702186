<div [formGroup]="productForm">
	<mat-form-field fxFlex="1 1 100%" floatLabel="never">
	  <input type="text" [placeholder]="placeholder" matInput [formControlName]="displayField" [matAutocomplete]="auto" (keyup.enter)="chooseFirstOption($event)" appClickField [(fieldOpen)]="fieldOpen">
	  <mat-autocomplete #auto="matAutocomplete">
	    <mat-option *ngFor="let product of filteredProducts | async" (onSelectionChange)="selectProduct($event,product)" [value]="product">
	      {{ product }}
	    </mat-option>
	  </mat-autocomplete>
	  <mat-label>{{placeholder}}</mat-label>
	</mat-form-field>
</div>
