<div *ngIf="dataLoading">
	  <h3 style="text-align:center;">LOADING</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="container" style="width:100%" *ngIf="!dataLoading">
  <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">
    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span style="font-weight:bold;"> {{ order.vendor.name }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order.orders.length != 1"> {{ order.orders.length }} ITEMS</span>
        <span *ngIf="order.orders.length == 1"> {{ order.orders.length }} ITEM</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;">
        <div 
          class="example-element-detail" 
          style="margin-left:20px;width:100%;" 
          *ngIf="order==expandedElement" 
          [class.mat-elevation-z0]="order!=expandedElement" 
          [class.mat-elevation-z8]="order==expandedElement" 
          [@detailExpand]="order == expandedElement ? 'expanded' : 'collapsed'">
<!-- 
<pre>
{{dataSource2[orderIndex].data|json}}
</pre>

 -->
          <mat-table #table [dataSource]="dataSource2[orderIndex]" class="mat-elevation-z2">
					  <ng-container matColumnDef="vendor">
						  <mat-header-cell *matHeaderCellDef fxFlex="1 1 220px"> VENDOR </mat-header-cell>
						  <mat-cell *matCellDef="let order; let i=index" fxFlex="1 1 220px">
						  	<span> {{ order.products[0].product.vendor.name }} </span>
						  </mat-cell>
						</ng-container>

 						<ng-container matColumnDef="date">
						  <mat-header-cell *matHeaderCellDef fxFlex="1 1 100px"> DATE </mat-header-cell>
						  <mat-cell *matCellDef="let order; let i=index" fxFlex="1 1 100px">
								<span> {{ order.date | date:'M/d/yyyy': 'GMT' }} </span>
						  </mat-cell>
						</ng-container>

					  <ng-container matColumnDef="model">
					    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> MODEL </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%">
					    	<span>{{order.products[0].product.model}}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="color">
					    <mat-header-cell *matHeaderCellDef fxFlex="1 1 8%"> COLOR </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 8%">
					    	<span>{{order.products[0].product.color}}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="size">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> SIZE </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px">
					    	<span> {{order.products[0].product.size}} </span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="type">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> TYPE </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 60px">
					    	<span>{{order.products[0].type}}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="status">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"> </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px" [ngClass]="order.products[0].product.status">
					      <span>&nbsp;</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="cost">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 1 60px"> COST </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" class="alignRightCell" fxFlex="0 1 60px">
					      <span>{{order.products[0].cost | currency : 'USD' : 'symbol' : '1.0' }}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="priceLink">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 35px">
					      <mat-icon *ngIf="order.products[0].pricingFormula!='c'">link_outline</mat-icon>
					      <mat-icon *ngIf="order.products[0].pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="retail">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 1 60px"> RETAIL </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" class="alignRightCell" fxFlex="0 1 60px">
					    	<span>{{order.products[0].retail | currency : 'USD' : 'symbol' : '1.0' }}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="patientStock">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 110px"> ORDER FOR </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 110px">
					    	<span>{{order.products[0].frame.frameSource}}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="patient">
					    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> PATIENT </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%">
					      <button mat-button [routerLink]="['/admin/patient', order.patient._id]" style="width:100%;text-transform:uppercase;">{{order.patient.lastName}}, {{order.patient.firstName}}</button>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="invoiceNumber">
					    <mat-header-cell *matHeaderCellDef fxFlex="1 1 110px"> INVOICE # </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 110px">
					    	<span>{{order.type}}&nbsp;&ndash;&nbsp;{{order.invoiceNumber }}</span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="quantity">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 100px"> INVENTORY </mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 100px" [ngClass]="{red:!order.productInventory}" >
					      <span *ngIf="order.productInventory">{{ order.productInventory.quantity }}</span>
					      <span *ngIf="!order.productInventory"></span>
					    </mat-cell>
					  </ng-container>

					  <ng-container matColumnDef="action">
					    <mat-header-cell *matHeaderCellDef fxFlex="0 0 150px"></mat-header-cell>
					    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 150px">
					      <button mat-raised-button *ngIf="order.productInventory" (click)="decrementInventory(order,orderIndex,i)"><mat-icon>exposure_neg_1</mat-icon></button>

					    	<!-- <mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
						      <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
						      <mat-label><mat-icon>gesture</mat-icon></mat-label>
						    </mat-form-field>
					      <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
					      <mat-menu #menu="matMenu">
					    	 	<button mat-menu-item type="button" (click)="saveFrame(order)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid">SAVE CHANGES</button>
					        <button mat-menu-item type="button" (click)="orderFrame(order,i)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">ORDERED FRAME</button>
					        <button mat-menu-item type="button" (click)="cancelFrame(order)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">CANCEL ORDER</button>
					      </mat-menu> -->
					    </mat-cell>
					  </ng-container>

					  <mat-header-row 
					  	*matHeaderRowDef="displayedColumns2" 
					  	fxLayout="row" 
					  	fxLayoutAlign="flex-start center">
					  </mat-header-row>
					  <mat-row 
					  	*matRowDef="let row; columns: displayedColumns2; let i=index; let even=even; let odd=odd;" 
					  	fxLayout="row" 
					  	fxLayoutAlign="flex-start center" 
					  	[ngClass]="{rowEven:even, rowOdd:odd}" 
					  	style="border-left:1px solid #dfdfdf !important;">
					  </mat-row>
					</mat-table>










        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let element; columns: displayedColumns; let r=dataIndex"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="toggleExpanded(element, r)"
      fxLayoutAlign="start stretch" 
      >
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden">
    </mat-row>
  </mat-table>
</div>








<div class="container" style="width:100%">
	
</div>