import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';

export class OrderSort {

  constructor() {

  }

  static setFilterPredicate(dataSource:MatTableDataSource<FormGroup>, selection:SelectionModel<FormGroup>) {
    dataSource.filterPredicate = ((data: FormGroup, filterString: string) => {
      let filterStringArr = filterString.split('$');
      let filter = filterStringArr[0];
      let stockOption = filterStringArr[1];
      let reg = new RegExp(filter, 'i');
      if (stockOption == 'stock') {
        return data.value.status != 'return' && data.value.status != 'returned' && (data.value.quantity > 0 && (!filter || reg.test(data.value.model) || selection.selected.includes(data) || data.value._id == 'NEW'));
      } else if (stockOption == 'return') {
        return (data.value.status == 'return' || data.value.status == 'returned') && (!filter || reg.test(data.value.model) || selection.selected.includes(data) || data.value._id == 'NEW');
      } else {
        return data.value.status != 'return' && data.value.status != 'returned' && (!filter || reg.test(data.value.model) || selection.selected.includes(data) || data.value._id == 'NEW');
      }
    });
  }

  static setSorter(dataSource:MatTableDataSource<FormGroup>, sort:MatSort) {
    dataSource.sortingDataAccessor = (item, property) => {
      if (!item.value[property]) {
        item.value[property] == '';
      }
      switch(property) {
        case 'model':
          return (item.value.products[0].product.model) ? item.value.products[0].product.model : item[property];
          break;
        case 'color':
          return (item.value.products[0].product.color) ? item.value.products[0].product.color : item[property];
          break;
        case 'size':
          return (item.value.products[0].product.size) ? item.value.products[0].product.size : item[property];
          break;
        case 'type':
          return (item.value.products[0].type) ? item.value.products[0].type : item[property];
          break;
        case 'cost':
          return (item.value.products[0].cost) ? item.value.products[0].cost : item[property];
          break;
        case 'retail':
          return (item.value.products[0].retail) ? item.value.products[0].retail : item[property];
          break;
        case 'store':
          return (item.value.store) ? item.value.store : item[property];
          break;
        default:
          return item.value[property];
      }
    };
    dataSource.sort = sort;
  }
}
