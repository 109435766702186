<div>
	<nav *ngIf="userRole" mat-tab-nav-bar>
		<ng-container *ngFor="let link of userNav">
	  	<a mat-tab-link
				[routerLink]="[{ outlets: { sub: [link.path] } }]"
				routerLinkActive #rla="routerLinkActive"
				[active]="rla.isActive">
				{{link.label}}
		  </a>
		</ng-container>
	</nav>
</div>
<router-outlet name="sub"></router-outlet>
