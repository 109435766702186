import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Prescription } from '@models/prescription';
import { Order } from '@models/order';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
	private base_url = environment.base_url + '/prescription';

	constructor(public http: HttpClient) { }

	getPatientPrescriptions(patientId: String): Observable<any> {
		return this.http.get(`${this.base_url}/patient/${patientId}`, httpOptions);
	}

	addPrescription(prescription: Prescription): Observable<boolean> {
		let body = JSON.stringify(prescription);
		return this.http.post<boolean>(`${this.base_url}`, body, httpOptions);
	}

	addPrescriptionFromTmp(prescription: Prescription): Observable<boolean> {
		let body = JSON.stringify(prescription);
    return this.http.post<boolean>(`${this.base_url}/addFromTmp`, body, httpOptions);
	}

	updatePrescriptionNotes(patientId: any, prescriptionNotes: Object): Observable<Prescription> {
		let body = JSON.stringify(prescriptionNotes);
		return this.http.put<Prescription>(`${this.base_url}/notes/${patientId}`, body, httpOptions)
	}

	updatePrescription(prescriptionId: any, prescription: Prescription): Observable<Prescription> {
		let body = JSON.stringify(prescription);
    return this.http.put<Prescription>(`${this.base_url}/${prescriptionId}`, body, httpOptions);
	}

	deletePrescription(prescriptionId: any): Observable<Prescription> {
    return this.http.delete<Prescription>(`${this.base_url}/${prescriptionId}`, httpOptions);
	}

	setPrescriptionAsOrder(prescriptionId: any): Observable<Order> {
    return this.http.put<Order>(`${this.base_url}/setAsOrder/${prescriptionId}`, httpOptions);
	}

	getPatientLastExam(patientId: String): Observable<any> {
    return this.http.get(`${this.base_url}/lastExam/${patientId}`, httpOptions);
	}

	generatePdf(prescriptionId: string): Observable<any> {
		return this.http.post(`${this.base_url}/${prescriptionId}/generatePdf`, {}, { responseType: 'blob' });
  }

  expirePrescription(prescriptionId: string): Observable<Prescription> {
    return this.http.put<Prescription>(`${this.base_url}/expire/${prescriptionId}`, {}, httpOptions);
  }

  hidePrescription(prescriptionId: string): Observable<Prescription> {
    return this.http.put<Prescription>(`${this.base_url}/hide/${prescriptionId}`, {}, httpOptions);
  }
}
