import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {

  constructor(private el: ElementRef) {
  }

  @Input('appHighlight')
  appHighlight: string;

	@HostListener('mouseenter') onMouseEnter() {
		this.el.nativeElement.style.backgroundColor = 'white';
  	this.el.nativeElement.style.backgroundColor = 'rgb(227, 239, 62)';
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.el.nativeElement.style.backgroundColor = 'black';
    	this.el.nativeElement.style.backgroundColor = 'white';
	}
	
	@HostListener('mouseup') onMouseUp() {
	    this.el.nativeElement.style.backgroundColor = 'white';
   	 	this.el.nativeElement.style.backgroundColor = 'rgb(227, 239, 62)';
	}
}