import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil, timer } from 'rxjs';

export interface AlertOptions {
  heading: string;
  description: string;
  success: boolean;
  duration?: number;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() alertStream: Observable<AlertOptions>;

  content: AlertOptions | undefined;
  isOpen: boolean;
  unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.alertStream
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((options: AlertOptions) => this.setData(options));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearAlert() {
    this.isOpen = false;
    this.content = undefined;
  }

  setData(options: AlertOptions) {
    this.content = options;
    const { duration = 10000 } = this.content;
    this.showAlert(duration);
  }

  showAlert(duration?: number) {
    this.isOpen = true;
    if (duration && duration > 0) {
      timer(duration).subscribe(() => this.clearAlert());
    }
  }
}
