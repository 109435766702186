import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VendorOrder } from '@models/vendor-order';

export interface InvoiceDialogData {
  order: VendorOrder;
}

@Component({
  selector: 'app-payable-order-dialog',
  template: `<app-payable-order
    [vendorOrder]="data.order"
  ></app-payable-order>`,
  styleUrls: ['./payable-order.component.scss'],
})
export class PayableOrderDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InvoiceDialogData) {}
}
