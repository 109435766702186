import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  ElementRef,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { User } from '@models/user';
import { UserService } from '@services/user.service';
import { StoreService } from '@services/store.service';
import { ActivatedRoute } from '@angular/router';
// import { MatPaginator, MatTableDataSource, MatTable } from '@angular/material';
// import { MatTableModule } from '@angular/material/table';
// import { MatTableDataSource } from '@angular/material/table';
// import { MatPaginatorModule } from '@angular/material/paginator';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { EMPTY, catchError, switchMap, Subscription } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [UserService, StoreService],
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('confirmDelete') confirmDeleteTemplate: TemplateRef<ElementRef>;

  editUserForm: FormGroup;
  users: User[];
  displayedColumns = [
    'name',
    'initials',
    'email',
    'role',
    'stores',
    'password',
    'anyIp',
    'disabled',
    'save',
  ];
  dataSource = new MatTableDataSource<User>();
  editUserId: String;
  editUser: User;
  editUserForms: FormArray[] = [];
  edittingForm: FormGroup;
  roleOpen: Boolean = false;
  storesOpen: Boolean = false;

  roleOptions: String[] = [
    'OPTICIAN',
    'MANAGER',
    'OPTICIAN/INVENTORY',
    'OWNER/ADMIN',
    'RENTAL',
  ];
  storeOptions: any[];
  subscriptions: {[id: string]: Subscription} = {};

  /**
   * Set the paginator after the view init since this component will
   * be able to query its view for the initialized paginator.
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private userService: UserService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private el: ElementRef,
    private dialog: MatDialog
  ) {
    this.editUserForm = fb.group({
      editUserForms: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(switchMap(() => this.userService.getAllUsers()))
      .subscribe((users) => {
        this.dataSource.data = users.sort((a, b) => {
          if (a.disabled && !b.disabled) {
            return 1;
          } else if (!a.disabled && b.disabled) {
            return -1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        });
        this.setUserForms();
        this.users = this.dataSource.data;
        this.dataSource.paginator = this.paginator;
      });

    this.storeService.getAllStores().subscribe((res) => {
      this.storeOptions = res;
    });
  }

  ngOnDestroy() {
    for (const id in this.subscriptions) {
      if (this.subscriptions[id]) {
        this.subscriptions[id].unsubscribe();
      }
    }
  }

  clickRow($event: Event, user, idx) {
    if (this.editUserId === user._id) {
      return;
    }
    if (this.editUserId === 'NEW') {
      this.dataSource.data.pop();
      this.subscriptions['NEW'].unsubscribe();
      delete this.subscriptions['NEW'];
      this.dataSource.data = this.dataSource.data;
    }
    const index = this.getIdx(idx);
    this.editUserId = user._id;
    this.editUser = user;
    this.edittingForm = this.editUserForm
      .get('editUserForms')
      .get(`${index}`) as FormGroup;
    this.syncForm();
    $event.preventDefault();
    $event.stopPropagation();
  }

  syncForm() {
    const user = this.editUser;
    this.edittingForm.setValue({
      _id: user._id,
      name: user.name,
      initials: user.initials ?? '',
      email: user.email,
      password: user.password ?? '',
      role: user.role,
      stores: user.stores,
      anyIp: user.anyIp,
      disabled: user.disabled ?? false
    });
  }

  clickRole($event, user, index) {
    this.editUserId = user._id;
    this.editUser = user;
    this.roleOpen = true;
  }

  changeRole(user, index) {
    this.editUserId = user._id;
    this.editUser = user;
  }

  clickStores($event, user, index) {
    this.editUserId = user._id;
    this.editUser = user;
    this.storesOpen = true;
    $event.preventDefault();
    $event.stopPropagation();
  }

  clickStoresOption($event, user, index) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  changeStores(user, index) {
    this.editUserId = user._id;
    this.editUser = user;
  }

  @HostListener('document:click', ['$event']) clickOutside($event: Event) {
    console.log('click off');
    console.log($event);
    if (!this.roleOpen && !this.storeOptions) {
      if (this.editUserId !== '' && this.editUserId !== undefined) {
        this.saveEditedUser();
      }
      this.editUserId = '';
      this.editUser = null;
    } else {
      this.roleOpen = false;
      this.storesOpen = false;
    }
  }

  saveEditedUser() {
    if (this.edittingForm.invalid) {
      return;
    }
    const editUser = this.edittingForm.getRawValue();
    if (this.editUserId === 'NEW') {
      this.route.paramMap
        .pipe(
          switchMap(() =>
            this.userService.addUser(editUser).pipe(
              catchError((e) => {
                this.notificationService.show('error', e.error.message);
                return EMPTY;
              })
            )
          )
        )
        .subscribe((res) => {
          if (res.success) {
            this.notificationService.show('success', 'Created successfully!');
            this.subscriptions['NEW'].unsubscribe();
            delete this.subscriptions['NEW'];
            this.dataSource.data[this.dataSource.data.length - 1] = res.user;
            this.dataSource.data = this.dataSource.data.sort((a, b) => {
              if (a.disabled && !b.disabled) {
                return 1;
              } else if (!a.disabled && b.disabled) {
                return -1;
              } else {
                return a.name > b.name ? 1 : -1;
              }
            });
            const idx = this.dataSource.data.findIndex(u => u._id === res.user._id);
            (this.editUserForm.controls['editUserForms'] as FormArray).controls.pop();
            (this.editUserForm.controls['editUserForms'] as FormArray).insert(idx, this.createFormGroup(res.user));
            this.editUserId = null;
            this.editUser = null;
            this.edittingForm = null;
          }
        });
    } else {
      this.route.paramMap
        .pipe(
          switchMap(() =>
            this.userService.updateUser(editUser._id, editUser).pipe(
              catchError((e) => {
                this.notificationService.show('error', e.error.message);
                return EMPTY;
              })
            )
          )
        )
        .subscribe(() => {
          this.notificationService.show('success', 'Updated successfully!');
          let idx = this.dataSource.data.findIndex(u => u._id === this.editUserId);
          this.dataSource.data[idx] = editUser;
          this.dataSource.data = this.dataSource.data.sort((a, b) => {
            if (a.disabled && !b.disabled) {
              return 1;
            } else if (!a.disabled && b.disabled) {
              return -1;
            } else {
              return a.name > b.name ? 1 : -1;
            }
          });
          idx = this.dataSource.data.findIndex(u => u._id === this.editUserId);
          (this.editUserForm.controls['editUserForms'] as FormArray).controls[idx] = this.createFormGroup(editUser);
          this.editUserId = null;
          this.editUser = null;
          this.edittingForm = null;
        });
    }
  }

  setUserForms() {
    for (let x = 0; x < this.dataSource.data.length; x++) {
      const user = this.dataSource.data[x] as User;
      const newUser = new User();
      newUser._id = user._id;
      newUser.name = user.name;
      newUser.initials = user.initials;
      newUser.role = user.role;
      newUser.stores = user.stores;
      newUser.email = user.email;
      newUser.password = user.password;
      newUser.anyIp = user.anyIp;
      newUser.disabled = user.disabled ?? false;
      this.addUserFormItem(newUser);
    }
  }

  createFormGroup(user) {
    const itemsCtrl = this.fb.group({
      _id: user._id,
      name: user.disabled ? this.fb.control({ value: user.name, disabled: true }) : user.name,
      initials: user.disabled ? this.fb.control({ value: user.initials, disabled: true }) : user.initials,
      email: this.fb.control(user.disabled ? { value: user.email, disabled: true } : user.email, [
        Validators.required,
        Validators.email,
      ]),
      password: this.fb.control(user.disabled ? { value: user.password, disabled: true } : user.password, user._id === 'NEW' ? Validators.required : []),
      role: this.fb.control(user.disabled ? { value: user.role, disabled: true } : user.role, Validators.required),
      stores: this.fb.control(user.disabled ? { value: user.stores, disabled: true } : user.stores, Validators.required),
      anyIp: user.disabled ? this.fb.control({ value: user.anyIp, disabled: true }) : user.anyIp,
      disabled: user.disabled ?? false
    });

    if (this.subscriptions[user._id]) {
      this.subscriptions[user._id].unsubscribe();
      delete this.subscriptions[user._id];
    }
    this.subscriptions[user._id] = itemsCtrl.get('role').valueChanges.subscribe((val) => {
      if (val === 'RENTAL') {
        itemsCtrl.get('anyIp').setValue(true);
      }
    });

    return itemsCtrl;
  }

  addUserFormItem(user) {
    const control = <FormArray>this.editUserForm.controls['editUserForms'];
    const itemsCtrl = this.createFormGroup(user);
    control.insert(control.controls.length, itemsCtrl);
  }

  addUser($event) {
    this.paginator.lastPage();
    const newUser = new User();
    newUser._id = 'NEW';
    this.dataSource.data = [...this.dataSource.data, newUser];
    this.addUserFormItem(newUser);
    const rows = this.el.nativeElement.querySelectorAll('mat-row');
    const newRow = rows[rows.length - 1];
    newRow.click();
    $event.preventDefault();
    $event.stopPropagation();
  }

  getStoreNames(stores: number[]) {
    return stores
      .map(
        (store) =>
          this.storeOptions?.find((opt) => opt._id === store)?.storeName
      )
      .join(', ');
  }

  getIdx(i: number) {
    return i + this.paginator.pageSize * this.paginator.pageIndex;
  }

  cancelUserEdit($event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.editUserId === 'NEW') {
      this.dataSource.data.pop();
      this.subscriptions['NEW'].unsubscribe();
      delete this.subscriptions['NEW'];
      this.dataSource.data = this.dataSource.data;
    }

    this.editUserId = null;
    this.editUser = null;
    this.edittingForm = null;
  }

  deleteUser($event, idx: number) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialog.open(this.confirmDeleteTemplate).afterClosed().subscribe(res => {
      const index = this.getIdx(idx);
      const userId = this.dataSource.data[index]._id;
      this.userService.deleteUser(userId).subscribe(() => {
        this.notificationService.show('success', 'Deleted successfully!');
        this.dataSource.data.splice(index, 1);
        this.dataSource.data = this.dataSource.data;
        (this.editUserForm.controls['editUserForms'] as FormArray).controls.splice(index, 1);
      });
    });
  }
}
