import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { AngularCsv } from 'angular7-csv';
import { parsePhoneNumber } from 'libphonenumber-js';

@Component({
	selector: 'app-patients',
	templateUrl: './patients.component.html',
	styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatTable, {static: false}) table: MatTable<Patient>;
	@ViewChild('search', {static: false}) search: MatInput;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	patients: Patient[];
	data: Patient[] = [];
	dataSource = new MatTableDataSource<Patient>();
	displayedColumns: string[] = ['lastName', 'email', 'phone', 'phone2', 'cell', 'favoriteLines', 'beBackList'];

	constructor(private patientService: PatientService, private route: ActivatedRoute, private router: Router) {

	}

	clickRow($event: Event, patient, index) {
		this.router.navigate(['/admin/patient', patient._id]);
		$event.preventDefault();
		$event.stopPropagation();
	}

	saveCSV($event: Event) {
		var options = { 
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true, 
			showTitle: false,
			title: 'Your title',
			useBom: false,
			noDownload: false,
			headers: ["NAME", "EMAIL", "PHONE", "PHONE2", "CELL", "FAVORITE LINES", "BE BACK LIST"]
		};
		var patients = [];
		let toUpper = function(x){ 
		  return x.toUpperCase();
		};
		console.log(this.dataSource.data)
		for (var x = 0; x < this.dataSource.data.length; x++) {
		console.log('1')
			let patient = this.dataSource.data[x] as Patient;
			if (!patient.favoriteLines) patient.favoriteLines = [];
			if (!patient.beBackList) patient.beBackList = '';
			
			let phone = '';
	    let phone2 = '';
	    let cell = '';
			try {
	    phone = (!patient.phone.trim()) ? '' : parsePhoneNumber(patient.phone.trim(), 'US').formatNational();
	    phone2 = (!patient.phone2.trim()) ? '' : parsePhoneNumber(patient.phone2.trim(), 'US').formatNational();
	    cell = (!patient.cell.trim()) ? '' : parsePhoneNumber(patient.cell.trim(), 'US').formatNational();
} catch (err) {

		console.log(err)
}
	    


			patients.push({
				name: patient.lastName.toString().toUpperCase() + ', ' + patient.firstName.toString().toUpperCase(),
				email: patient.email,
				phone: phone,
				phone2: phone2,
				cell: cell,
				favoriteLines: patient.favoriteLines.map(toUpper).join(', '),
				beBackList: patient.beBackList.toString().toUpperCase()
			})
		}
		console.log('2')
		new AngularCsv(patients, 'Patient Favorite Lines Report', options);
		//new Angular5Csv(this.patients, 'My Report');
	}

	patientSearch() {
		var searchInput: any = this.search;

		this.patientService.queryPatientsList(searchInput.nativeElement.value).subscribe(res => {
			this.dataSource.data = res;
			console.log(res)
			this.dataSource.paginator = this.paginator;
    	this.dataSource.sort = this.sort;
		})
	}

	ngOnInit() {
		this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

		// this.route.paramMap
		// .pipe(switchMap((params: ParamMap) => this.patientService.getAllPatients()))
		// .subscribe(patients => {
		// 	this.dataSource.data = patients;
		// 	this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
		// });

	}

}