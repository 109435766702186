<div *ngIf="!dataSource; else elseBlock">
	<h3 style="text-align:center;">LOADING INVENTORY</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<ng-template #elseBlock>
  <div class="container" style="width:100%">
    <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">

      <ng-container matColumnDef="vendor">
        <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
        <mat-cell *matCellDef="let vendorInventory; let orderIndex=dataIndex;" fxFlex="1 1 200px">
          <span style="font-weight:bold;"> {{ vendorInventory.vendor?.name }} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
        <mat-cell *matCellDef="let vendorInventory; let orderIndex=dataIndex;" fxFlex="1 1 200px">
          <span *ngIf="vendorInventory.count !== 1"> {{ vendorInventory.count }} FRAMES</span>
          <span *ngIf="vendorInventory.count === 1"> {{ vendorInventory.count }} FRAME</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="borrowing">
        <mat-header-cell *matHeaderCellDef fxFlex="1 1 400px" style="margin-left: auto;">
          <mat-checkbox [(ngModel)]="borrowingView" (change)="setDefaultBorrowingStore()"></mat-checkbox> &nbsp;
          <mat-select #formRow #statusSelect placeholder="BORROWING" [(value)]="borrowingStore" [disabled]="!borrowingView">
            <ng-container *ngFor="let storeOption of storeOptions">
              <mat-option [value]="storeOption._id" *ngIf="storeOption._id !== auth.getStoreObject()._id">
                {{storeOption.storeName}}
              </mat-option>
            </ng-container>
          </mat-select>
          <ng-container *ngIf="borrowingSelection.hasValue()" [formGroup]="borrowingFormGroup">
            <mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
              <input matInput required placeholder="" type="text" formControlName="initials">
              <mat-label><mat-icon>gesture</mat-icon></mat-label>
            </mat-form-field>
            <button mat-raised-button type="button" (click)="borrowSelectedProducts($event)" style="margin-left:10px;">BORROW SELECTED</button>
          </ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let vendorInventory; let orderIndex=dataIndex;" fxFlex="1 1 400px" style="margin-left: auto;">
          <span *ngIf="vendorInventory.vendor.borrowingStores?.includes(auth.getStoreObject()._id)">
            BORROWED FROM {{ getStoreObjectFromId(vendorInventory.vendor.store).storeName }}
          </span>
          <div *ngIf="vendorInventory.vendor.borrowingStores && vendorInventory.vendor.borrowingStores.length > 0 && !vendorInventory.vendor.borrowingStores.includes(auth.getStoreObject()._id)" fxLayout="column">
            <span *ngFor="let storeId of vendorInventory.vendor.borrowingStores">
              {{ getStoreObjectFromId(storeId).storeName }}
            </span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="renting">
        <mat-header-cell *matHeaderCellDef fxFlex="1 1 400px" style="margin-left: auto;">
          <ng-container *ngIf="currentUser.role === 'RENTAL'; else noRentalBlock">
            RENTED
            <button mat-raised-button type="button" style="margin-left:10px;width:unset;" *ngIf="rentingList.length > 0" (click)="!downloading && downloadFrames()">
              <ng-container *ngIf="downloading; else normalBtnBlock">
                <mat-icon>
                  <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
                DOWNLOADING...
              </ng-container>
              <ng-template #normalBtnBlock>DOWNLOAD FRAMES</ng-template>
            </button>
          </ng-container>
          <ng-template #noRentalBlock>
            <mat-checkbox [(ngModel)]="rentingView"></mat-checkbox> &nbsp;
            <mat-select #formRow #statusSelect placeholder="RENTING" [(ngModel)]="rentingUser" (selectionChange)="selectRentingUser()" [disabled]="!rentingView">
              <mat-option>ALL</mat-option>
              <mat-option *ngFor="let user of rentalUsers" [value]="user">
                {{user.name}}
              </mat-option>
            </mat-select>
            <button mat-raised-button type="button" style="margin-left:10px;" *ngIf="rentingListChanged()" (click)="rentSelected()">RENT SELECTED</button>
          </ng-template>
        </mat-header-cell>
        <mat-cell *matCellDef="let vendorInventory; let orderIndex = dataIndex;" fxFlex="1 1 400px" style="margin-left: auto;">
          <div *ngIf="!rentingUser && currentUser.role !== 'RENTAL'" fxLayout="column">
            <span *ngFor="let rentListObj of groupRentingByVendor(vendorInventory.vendor._id) | keyvalue">
              {{ rentListObj.key }} ({{ getSum(rentListObj.value) }})
            </span>
          </div>
          <span *ngIf="getRentingByVendor(vendorInventory.vendor._id).length > 0">
            {{ getSum(getRentingByVendor(vendorInventory.vendor._id)) }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let vendorInventory; let orderIndex=dataIndex;">
          <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="vendorInventory!=expandedElement" [class.mat-elevation-z8]="vendorInventory==expandedElement" [@detailExpand]="vendorInventory == expandedElement ? 'expanded' : 'collapsed'">

          <app-inventory-base
            *ngIf="vendorInventory.vendor && (vendorInventory==expandedElement || loadedVendors.indexOf(vendorInventory._id) >= 0)"
            [vendor]="vendorInventory.vendor"
            [managedBoard]="true"
            [borrowingView]="borrowingView"
            [borrowingStore]="borrowingStore"
            [(borrowingSelection)]="borrowingSelection"
            [rentingView]="rentingView"
            [rentingUser]="rentingUser"
            [rentingList]="(rentingUser || currentUser.role === 'RENTAL') ? getRentingByVendor(vendorInventory.vendor._id) : groupRentingByVendor(vendorInventory.vendor._id)"
            [allRentingQtyByProduct]="getVendorQtys(vendorInventory.vendor._id)"
            (selectRenting)="selectRenting($event)"
            [updatedRentingList]="getUpdatedRentingList(vendorInventory.vendor._id)"
            style="width:100%;"
          ></app-inventory-base>
          </div>
        </mat-cell>
      </ng-container>


      <mat-header-row 
        *matHeaderRowDef="displayedColumns" 
        fxLayout="row" 
        fxLayoutAlign="start center">
      </mat-header-row>
      <mat-row 
        *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
        fxLayout="row" 
        fxLayoutAlign="start center" 
        [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
        style="border-left:1px solid #dfdfdf !important;" 
        (click)="toggleExpanded($event, row, i)">
      </mat-row>
      <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
        [class.example-detail-row-expanded]="expandedElement === element"
        [class.example-detail-row-collapsed]="expandedElement !== element"
        style="overflow: hidden"
        fxLayoutAlign="start center">
      </mat-row>
    </mat-table>
  </div>
</ng-template>
