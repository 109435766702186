<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [formGroup]="formGroup">
  <div fxFlex="0 0 50px"><b>{{ label }}</b></div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="SPH" type="text" class="form-control" formControlName="sph">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="CYL" type="text" class="form-control" formControlName="cyl">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="AXIS" type="text" class="form-control" autocomplete="off" formControlName="axis">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="ADD" type="text" class="form-control" formControlName="add">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/10)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="SEG HGT" type="text" formControlName="segHgt">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism1">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <mat-select placeholder="B" formControlName="b1">
        <mat-option value="U">U</mat-option>
        <mat-option value="D">D</mat-option>
        <mat-option value="O">O</mat-option>
        <mat-option value="I">I</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism2">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <mat-select placeholder="B" formControlName="b2">
        <mat-option value="U">U</mat-option>
        <mat-option value="D">D</mat-option>
        <mat-option value="O">O</mat-option>
        <mat-option value="I">I</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="PD DIST" type="text" class="form-control" formControlName="pdd">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="PD NEAR" type="text" class="form-control" formControlName="pdn">
    </mat-form-field>
  </div>
  <div fxFlex="0 0 calc((100%-200px)/12)">
    <mat-form-field style="width:100%">
      <input matInput placeholder="OC" type="text" class="form-control" formControlName="oc">
    </mat-form-field>
  </div>
</div>
