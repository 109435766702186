import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@services/report.service';

@Component({
  selector: 'app-customers-report',
  templateUrl: './customers-report.component.html',
  styleUrls: ['./customers-report.component.css']
})
export class CustomersReportComponent implements OnInit {
  loading: boolean = false;
  report: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = ['frames', 'customers', 'percentage'];

  constructor(public reportService: ReportService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getCustomersReport();
  }

  getCustomersReport() {
    this.loading = true;
    // this.reportService.getCustomersReport(this.startDate, this.endDate).subscribe((res: any[]) => {
    this.reportService.getCustomersReport().subscribe((res: any[]) => {
      this.report = res;
      this.dataSource = new MatTableDataSource<any>(this.report.data);
      this.loading = false;
      this.cdRef.detectChanges();
    });
  }

  calculateFramesTotal() {
    return this.report.data.reduce((accum, curr) => accum + curr.frames, 0);
  }

  calculateCustomersTotal() {
    return this.report.data.reduce((accum, curr) => accum + curr.count, 0);
  }

  getPercentage(x, y) {
    return Math.round(x*1000/y)/10;
  }

}
