import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Store } from '@models/store';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@classes/api-response';
import { Config } from '@classes/config';
import { environment } from '@environments/environment';
import { AppConfigService } from '@services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private base_url = environment.base_url + '/store';
  public stores: Store[];

  constructor(public http: HttpClient, public appConfigService: AppConfigService) {
  	let appConfig: Config = appConfigService.getConfig();
  	this.stores = appConfig.stores;
  }

	getAllStores(): Observable<Store[]> {
		return this.http.get(`${this.base_url}`).pipe(map( (res: ApiResponse<Store[]>) => res.stores ));
	}

  getStoreObjectFromId(storeId: any): Store {
    for (let x = 0; x < this.stores.length; x++) {
      if (this.stores[x]._id == storeId) {
        return this.stores[x];
      }
    }
    return null;
  }

}
