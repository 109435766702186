import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { Prescription } from '@models/prescription';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-form-prescription',
  templateUrl: './form-prescription.component.html',
  styleUrls: ['./form-prescription.component.css']
})

export class FormPrescriptionComponent implements OnInit {
  @Input() public prescription: FormGroup;
	@Input() public image: any;
	@Input() public withNotes: boolean;
  @Input() public withDelete: boolean;
  @Input() public withImage: boolean = true;
	@Input() public index: Number;
  @Output() clickDelete = new EventEmitter<any>();
  @Output() clickSetAsOrder = new EventEmitter<any>();
  
  @Input() selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<FormGroup>>(); 

  constructor(private cdRef:ChangeDetectorRef) { }

	ngOnInit() {
		//this.form = <FormGroup>this.controlContainer.control;
	}

  ngAfterViewInit() {
  }

  deletePrescription(event) {
    this.clickDelete.next({_id:this.prescription.value._id, index:this.index});
  }

  setPrescriptionAsOrder(event) {
    this.clickSetAsOrder.next({_id:this.prescription.value._id, index:this.index});
  }

  blurDate2(prescriptionForm) {
    prescriptionForm.setValue(prescriptionForm.value);
  }

  printImage(image) {
    let html = '<script type="text/javascript">setTimeout(function () { window.print(); }, 500);window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script><html><body style="width:100%;"><div style="width:100%;"><img src="'+image.image+'" style="width:100%;"></div></body></html>';
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(html);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    //WindowPrt.close();
  }

  toggleSelect(prescription) {
    console.log('test')
    this.selection.toggle(prescription);
    this.selectionChange.emit(this.selection);
    this.cdRef.detectChanges();
  }

  rxTypes = [
    /*{value: 'BIFOCAL', viewValue: 'BIFOCAL'},
    {value: 'COMPUTER', viewValue: 'COMPUTER'},
    {value: 'DISTANCE', viewValue: 'DISTANCE'},
    {value: 'OCCUPATIONAL', viewValue: 'OCCUPATIONAL'},
    {value: 'PROGRESSIVE', viewValue: 'PROGRESSIVE'},
    {value: 'READING', viewValue: 'READING'},
    {value: 'TRI-FOCAL', viewValue: 'TRI-FOCAL'},*/
    {value: 'RX', viewValue: 'RX'},
    {value: 'OTHER', viewValue: 'OTHER'}
  ];

}
