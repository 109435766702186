import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private snackbar: MatSnackBar) { }

  show(type: 'error' | 'success', message: string, action?: string) {
    this.snackbar.open(message, action, {
      duration: 3000,
      panelClass: `notification--${type}`,
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }
}
