import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ok-cancel-dialog',
  templateUrl: './ok-cancel.component.html',
  styleUrls: ['./ok-cancel.component.css']
})
export class OkCancelComponent {

  constructor(
    public dialogRef: MatDialogRef<OkCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	cancel() {
		this.dialogRef.close(false);
	}

	ok() {
		this.dialogRef.close(true);
	}

}
