
    <form [formGroup]="patientForm" enctype="multipart/form-data" onsubmit="event.preventDefault(); return false;" autocomplete="off">
    <div *ngIf="loading">
      <h3 style="text-align:center;">LOADING PATIENT</h3>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="!loading">


      <div class="container" style="width:100%;padding-left:24px;"
        fxLayout="row"
        fxLayoutAlign="start start">
        <div>
          <mat-card-title style="">{{patient.lastName}}, {{patient.firstName}}</mat-card-title>
          <mat-card-subtitle *ngIf="patient.patientNumber">
            <span>#{{patient?.store?.abbreviation}}{{patient.patientNumber}}</span>
            <span> (updated {{patient.dateUpdated | date:'shortDate'}})</span>
          </mat-card-subtitle>
        </div>

        <div *ngIf="authorized">
         
          <button mat-button [matMenuTriggerFor]="menu" style="padding:0px !important;top:-10px;">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item type="button" (click)="deletePatient()">DELETE PATIENT</button>
          </mat-menu>

        </div>


        <div style="margin-left: auto;">
          <button mat-raised-button type="button" (click)="importCustomerImages()" style="width:300px;">IMPORT CUSTOMER IMAGES</button>
        </div>
      </div>

      <mat-accordion multi>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenStatePatientInformation = true" (closed)="panelOpenStatePatientInformation = false">
        <!-- <mat-expansion-panel [expanded]="true" (opened)="panelOpenStatePatientInformation = true" (closed)="panelOpenStatePatientInformation = false"> -->
          <mat-expansion-panel-header [style.background-color]="(panelOpenStatePatientInformation) ? currentStore?.headerColor : null">
            <mat-panel-title>
              PATIENT INFORMATION
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-form-patient [parentForm]="patientForm" [patient]="patient"></app-form-patient>
          <div class="container" 
              fxLayout="row"
              fxLayoutAlign="space-between"
              fxLayoutGap="10px"
              fxLayoutGap.xs="0">
            <div class="item item-1">
              <button mat-raised-button type="button" (click)="save()" [disabled]="saveButtonClicked || patientForm.invalid">SAVE</button>
            </div>
            <div class="item item-2" fxFlex [hidden]="!saveButtonClicked">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="false" (opened)="panelOpenStateInquiries = true" (closed)="panelOpenStateInquiries = false">
          <mat-expansion-panel-header [style.background-color]="(panelOpenStateInquiries) ? currentStore?.headerColor : null">
            <mat-panel-title>
              INQUIRIES
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-patient-inquiries [patient]="patient" style="width:100%;"></app-patient-inquiries>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenStateOrders = true" (closed)="panelOpenStateOrders = false">
          <mat-expansion-panel-header  [style.background-color]="(panelOpenStateOrders) ? currentStore?.headerColor : null">
            <mat-panel-title>
              ORDERS
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-patient-orders 
            [patient]="patient" 
            (clickDelete)="deleteOrder($event)" 
            (clickReturn)="returnOrder($event)" 
            (clickExchange)="exchangeOrder($event)" 
            [storeCreditControl]="patientForm.controls['storeCredit']"
            style="width:100%;">
              
          </app-patient-orders>
        </mat-expansion-panel>

        

        <mat-expansion-panel *ngIf="false" (opened)="panelOpenStateDoctorInformation = true" (closed)="panelOpenStateDoctorInformation = false">
          <mat-expansion-panel-header [style.background-color]="(panelOpenStateDoctorInformation) ? currentStore?.headerColor : null">
            <mat-panel-title>
              DOCTOR INFORMATION
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="item item-1">
            
            <div class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="50%">
                <mat-form-field style="width:100%">
                  <input autocomplete="off" matInput placeholder="NAME" type="text" formControlName="doctorName" autoComplete="off">
                </mat-form-field>
              </div>
              <div class="item item-2" fxFlex="20%">
                <mat-form-field class="date" style="width:100%;">
                  <input autocomplete="off" matInput [matDatepicker]="lastExam" placeholder="LAST EXAM" type="text" formControlName="lastExam">
                  <mat-datepicker-toggle matSuffix [for]="lastExam"></mat-datepicker-toggle>
                  <mat-datepicker #lastExam></mat-datepicker>
                  <mat-hint>MMDDYYYY</mat-hint>
                </mat-form-field>
              </div>
              <div class="item item-3" fxFlex="20%" fxFlexAlign="center">
                <mat-checkbox matInput formControlName="callForRx">CALL FOR Rx</mat-checkbox>
              </div>
            </div>
          </div>
          <div class="item item-1">
            <div class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="0 0 calc(25%-5px)">
                <mat-form-field style="width:100%">
                  <input matInput placeholder="PHONE" type="tel" formControlName="doctorPhone" (keyup)="phoneType($event,'doctorPhone')" autocomplete="off">
                </mat-form-field>
              </div>
              <div class="item item-2" fxFlex="0 0 calc(25%-5px)">
                <mat-form-field style="width:100%">
                  <input matInput placeholder="FAX #" type="tel" formControlName="doctorFax" (keyup)="phoneType($event,'doctorFax')" autocomplete="off">
                </mat-form-field>
              </div>
              <div class="item item-3" fxFlex>
                <mat-form-field style="width:100%">
                  <input matInput placeholder="EMAIL" type="email" formControlName="doctorEmail" autocomplete="off">
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenStatePrescriptions = true" (closed)="panelOpenStatePrescriptions = false">
          <mat-expansion-panel-header [style.background-color]="(panelOpenStatePrescriptions) ? currentStore?.headerColor : null">
            <mat-panel-title>
              PRESCRIPTIONS
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="item item-3">

                <button mat-raised-button type="button" (click)="importCustomerImages()">UPLOAD Rx IMAGES</button>

          </div>
          <div class="item">
            <app-prescriptions [patient]="patient"></app-prescriptions>
<!-- 
            <app-form-prescription-group 
              [prescriptions]="patient.prescriptions" 
              (clickDelete)="deletePrescription($event)" 
              (clickSetAsOrder)="setPrescriptionAsOrder($event)" 
              style="width:100%">
            </app-form-prescription-group> -->
          </div>
          <div class="item item-4">
            <mat-toolbar>
              <span>Rx NOTES &amp; ADVICE</span>
            </mat-toolbar>


            <ckeditor [editor]="ClassicEditorBuild" style="width:100%;" placeholder="Rx NOTES & ADVICE" [formControl]="patientForm.controls['prescriptionNotes']" [config]="{toolbar: [ 'Bold', 'Italic' ]}" matInput></ckeditor>

          </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenStateScannedOrders = true" (closed)="panelOpenStateScannedOrders = false">
          <mat-expansion-panel-header [style.background-color]="(panelOpenStateScannedOrders) ? currentStore?.headerColor : null">
            <mat-panel-title>
              SCANNED ORDERS
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container" fxLayout="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" style="width:100%">
            <div class="item item-1">
                  <button mat-raised-button type="button" (click)="importCustomerImages()">UPLOAD ORDER IMAGES</button>

            </div>
            <div class="item item-2" *ngIf="uploaderOrder.isUploading">
              <mat-progress-bar mode="determinate" [value]="uploaderOrder.progress" *ngIf="uploaderOrder.isUploading"></mat-progress-bar>
            </div>
            
            <div class="item item-4">
              <app-form-order-image-group 
                [orders]="patient.orders" 
                (clickReturn)="returnOrder($event)" 
                (clickExchange)="exchangeOrder($event)" 
                (clickSetAsPrescription)="setOrderAsPrescription($event)">
              </app-form-order-image-group>
            </div>
          </div>
        </mat-expansion-panel>

      </mat-accordion>

<!-- 
    <mat-card style="margin-bottom:20px;" *ngIf="!newPatient">
      <mat-card-header>
        <div class="container" style="width:100%;"
          fxLayout="row"
          fxLayoutAlign="start start">
          <div>
            <mat-card-title style="">{{patient.lastName}}, {{patient.firstName}}</mat-card-title>
            <mat-card-subtitle>#{{patient.patientNumber}}</mat-card-subtitle>
          </div>

          <div *ngIf="authorized">
           
            <button mat-button [matMenuTriggerFor]="menu" style="padding:0px !important;top:-10px;">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button" (click)="deletePatient()">DELETE PATIENT</button>
            </mat-menu>

          </div>


          <div style="margin-left: auto;">
            <button mat-raised-button type="button" (click)="importCustomerImages()" style="width:300px;">IMPORT CUSTOMER IMAGES</button>
          </div>
        </div>
      </mat-card-header>


      <mat-toolbar>
        <span>INQUIRIES</span>
      </mat-toolbar>
      <mat-card-content>

        <app-patient-inquiries [patient]="patient" style="width:100%;"></app-patient-inquiries>
      </mat-card-content>
    </mat-card>

    <mat-card style="margin-bottom:20px;" *ngIf="!newPatient">
      <mat-toolbar>
        <span>ORDERS</span>
      </mat-toolbar>
      <mat-card-content>

        <app-patient-orders [patient]="patient" style="width:100%;"></app-patient-orders>
      </mat-card-content>
    </mat-card>


    <app-form-patient [parentForm]="patientForm" [patient]="patient"></app-form-patient>
    
      <br/>
      <div class="container" 
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayoutGap="10px"
          fxLayoutGap.xs="0">
        <div class="item item-1">
          <button mat-raised-button type="button" (click)="save()" [disabled]="saveButtonClicked || patientForm.invalid">SAVE</button>
        </div>
        <div class="item item-2" fxFlex [hidden]="!saveButtonClicked">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>

    <br/>
    <mat-card>
      <mat-toolbar>
        <span>DOCTOR INFORMATION</span>
      </mat-toolbar>
      <mat-card-content>
        <div class="container" fxLayout="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" style="width:100%">
          <div class="item item-1">
            
            <div class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="50%">
                <mat-form-field style="width:100%">
                  <input autocomplete="off" matInput placeholder="NAME" type="text" formControlName="doctorName" autoComplete="off">
                </mat-form-field>
              </div>
              <div class="item item-2" fxFlex="20%">
                <mat-form-field class="date" style="width:100%;">
                  <input autocomplete="off" matInput [matDatepicker]="lastExam" placeholder="LAST EXAM" type="text" formControlName="lastExam">
                  <mat-datepicker-toggle matSuffix [for]="lastExam"></mat-datepicker-toggle>
                  <mat-datepicker #lastExam></mat-datepicker>
                  <mat-hint>MMDDYYYY</mat-hint>
                </mat-form-field>
              </div>
              <div class="item item-3" fxFlex="20%" fxFlexAlign="center">
                <mat-checkbox matInput formControlName="callForRx">CALL FOR Rx</mat-checkbox>
              </div>
            </div>
          </div>
          <div class="item item-1">
            <div class="container" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="0 0 calc(25%-5px)">
                <mat-form-field style="width:100%">
                  <input matInput placeholder="PHONE" type="tel" formControlName="doctorPhone" (keyup)="phoneType($event,'doctorPhone')" autocomplete="off">
                </mat-form-field>
              </div>
              <div class="item item-2" fxFlex="0 0 calc(25%-5px)">
                <mat-form-field style="width:100%">
                  <input matInput placeholder="FAX #" type="tel" formControlName="doctorFax" (keyup)="phoneType($event,'doctorFax')" autocomplete="off">
                </mat-form-field>
              </div>
              <div class="item item-3" fxFlex>
                <mat-form-field style="width:100%">
                  <input matInput placeholder="EMAIL" type="email" formControlName="doctorEmail" autocomplete="off">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="item item-3">
            <mat-toolbar>
              <span>PRESCRIPTIONS</span>
            </mat-toolbar>
          </div>
          <div class="item item-3">

                <button mat-raised-button type="button" (click)="importCustomerImages()">UPLOAD Rx IMAGES</button>

          </div>
          <div class="item">
            <app-form-prescription-group 
              [prescriptions]="patient.prescriptions" 
              (clickDelete)="deletePrescription($event)" 
              (clickSetAsOrder)="setPrescriptionAsOrder($event)" 
              style="width:100%">
            </app-form-prescription-group>
          </div>
          <div class="item item-4">
            <mat-toolbar>
              <span>Rx NOTES &amp; ADVICE</span>
            </mat-toolbar>


            <ckeditor [editor]="ClassicEditorBuild" style="width:100%;" placeholder="Rx NOTES & ADVICE" formControlName="prescriptionNotes" [config]="{toolbar: [ 'Bold', 'Italic' ]}" matInput></ckeditor>

          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br/>

    <mat-card *ngIf="!newPatient">
      <mat-toolbar>
        <span>SCANNED ORDERS</span>
      </mat-toolbar>
      <mat-card-content>
        <div class="container" fxLayout="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" style="width:100%">
          <div class="item item-1">
                <button mat-raised-button type="button" (click)="importCustomerImages()">UPLOAD ORDER IMAGES</button>

          </div>
          <div class="item item-2" *ngIf="uploaderOrder.isUploading">
            <mat-progress-bar mode="determinate" [value]="uploaderOrder.progress" *ngIf="uploaderOrder.isUploading"></mat-progress-bar>
          </div>
          
          <div class="item item-4">
            <app-form-order-image-group 
              [orders]="patient.orders" 
              (clickDelete)="deleteOrder($event)" 
              (clickReturn)="returnOrder($event)" 
              (clickExchange)="exchangeOrder($event)" 
              (clickSetAsPrescription)="setOrderAsPrescription($event)">
            </app-form-order-image-group>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

 -->
    <br/>
    <div class="container" 
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
        fxLayoutGap.xs="0">
      <div class="item item-1">
        <button mat-raised-button type="button" (click)="save()" [disabled]="saveButtonClicked || patientForm.invalid">SAVE</button>
      </div>
      <div class="item item-2" fxFlex [hidden]="!saveButtonClicked">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
    </form>

