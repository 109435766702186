import { Product } from '@models/product';

export class Vendor {
  _id: string;
  name: string;
  store: string;
  distributor?: boolean;
  distributorVendors?: Array<string>;
  phone?: string;
  phoneExt?: string;
  fax?: string;
  website?: string;
  username?: string;
  password?: string;
  accountNumber?: string;
  repName?: string;
  repPhone?: string;
  repPhoneExt?: string;
  repEmail?: string;
  accountStatus?: string;
  defaultManagedBoardStatus?: string;
  pricingFormulaOptical?: string;
  pricingFormulaSun?: string;
  taxBothRxAndSun?: boolean;
  idealInventory?: string;
  updateBoard?: string;
  shippingMin?: string;
  orderProtocol?: string;
  orderInfo?: string;
  shippingFee?: string;
  returnAddress?: string;
  returnDetails?: string;
  returnNotes?: string;
  namingKey?: string;
  images?: Object[];
  dateAdded?: string;
  dateUpdated?: string;
  borrowed?: boolean;
  differentStore?: string;
  borrowingStores?: number[];
  hasDistributor?: boolean;

  constructor() {
    this._id = '';
    this.name = '';
    this.store = '';
    this.distributor = false;
    this.distributorVendors = [];
    this.phone = '';
    this.phoneExt = '';
    this.fax = '';
    this.website = '';
    this.username = '';
    this.password = '';
    this.accountNumber = '';
    this.repName = '';
    this.repPhone = '';
    this.repPhoneExt = '';
    this.repEmail = '';
    this.accountStatus = '';
    this.defaultManagedBoardStatus = '';
    this.pricingFormulaOptical = '';
    this.pricingFormulaSun = '';
    this.taxBothRxAndSun = false;
    this.idealInventory = '';
    this.updateBoard = '';
    this.shippingMin = '';
    this.orderProtocol = '';
    this.orderInfo = '';
    this.shippingFee = '';
    this.returnAddress = '';
    this.returnDetails = '';
    this.returnNotes = '';
    this.namingKey = '';
    this.images = [];
    this.dateAdded = '';
    this.dateUpdated = '';
    this.borrowed = false;
  }

  static validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  static validateUrl(url) {
    if (
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url
      )
    ) {
      return true;
    }
    return false;
  }
}

export class VendorInventory {
  _id: string;
  vendor: Vendor;
  products: Product[];
  pricingFormulaOptical: string;
  pricingFormulaSun: string;

  constructor() {
    this._id = '';
    this.vendor = new Vendor();
    this.products = [];
    this.pricingFormulaOptical = '';
    this.pricingFormulaSun = '';
  }
}
