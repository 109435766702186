<div class="container" style="width:100%" [formGroup]="orderForm">
<div>
    <button mat-button type="button" (click)="addOrder($event, 'INQUIRY')">
    	<mat-icon>help</mat-icon><span>ADD INQUIRY</span>
    </button>
  </div>
	<mat-table #table [dataSource]="dataSource" matSort matSortActive="vendor" matSortDirection="asc" class="mat-elevation-z2" formArrayName="orders" multiTemplateDataRows>
	  <ng-container matColumnDef="alert">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 40px">
	      <!-- <mat-icon color="warn" *ngIf="order.products.frame.frameSource!='STOCK'">notification_important</mat-icon> -->
	    </mat-cell>
	  </ng-container>

		<ng-container matColumnDef="vendor">
		  <mat-header-cell *matHeaderCellDef fxFlex="1 1 220px"> VENDOR </mat-header-cell>
		  <mat-cell *matCellDef="let order; let i=dataIndex" [formGroupName]="i" class="product-cell" fxFlex="1 1 220px">
		  	<span *ngIf="orderId!=order.value._id && order.value.products[0] && order.value.products[0].product.vendor"> {{ order.value.products[0].product.vendor.name }} </span>
		    <app-autocomplete-vendors 
		    	placeholder="VENDOR" 
		    	*ngIf="orderId==order.value._id" 
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']" 
		    	[(fieldOpen)]="fieldOpen" 
		    	fxFlex="1 1 190px"></app-autocomplete-vendors>
		  </mat-cell>
		</ng-container>

	  <ng-container matColumnDef="model">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> MODEL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 10%" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.model}}</span>
	    	<app-autocomplete-vendor-products 
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']" 
	    		[vendor]="order.value.products[0].product.vendor" 
	  			placeholder="MODEL"
	  			*ngIf="orderId==order.value._id" 
	  			(selected)="updateProduct($event,order,i)" 
	  			[(fieldOpen)]="fieldOpen" 
	  			displayField="model" 
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="color">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 8%"> COLOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="1 1 8%" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.color}}</span>
	    	<app-autocomplete-vendor-products 
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']" 
	    		[vendor]="order.value.products[0].product.vendor" 
	  			placeholder="COLOR"
	  			*ngIf="orderId==order.value._id" 
	  			(selected)="updateProduct($event,order,i)" 
	  			[(fieldOpen)]="fieldOpen" 
	  			displayField="color" 
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="size">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> SIZE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 50px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id"> {{order.value.products[0].product.size}} </span>
	    	<app-autocomplete-vendor-products 
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']" 
	    		[vendor]="order.value.products[0].product.vendor" 
	  			placeholder="SIZE"
	  			*ngIf="orderId==order.value._id" 
	  			(selected)="updateProduct($event,order,i)" 
	  			[(fieldOpen)]="fieldOpen" 
	  			displayField="size" 
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

		<ng-container matColumnDef="type">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> TYPE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 60px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id && order.value._id!=''">{{order.value.products[0].type}}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
						<mat-form-field *ngIf="orderId==order.value._id || order.value._id==''" floatLabel="never" style="width:90%">
							<mat-select #formRow placeholder="TYPE" formControlName="type" (click)="clickField2($event, order, i);$event.stopPropagation();" (selectionChange)="changeProductType($event,order)">
								<mat-option *ngFor="let typeOption of productTypeOptions" [value]="typeOption.value">
									{{typeOption.viewValue}}
								</mat-option>
							</mat-select>
		        </mat-form-field>
	        </ng-container>
		    </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="todo">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 175px"> TO DO </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" [formGroupName]="i" fxFlex="0 0 175px">
				<span *ngIf="orderId!=order.value._id" [ngStyle]="{'text-decoration' : order.status === 'AWAITING FRAME' ? 'line-through' : 'inherit' }"> 
					<button 
						*ngIf="order.value.products[0].product.vendor"
						mat-button type="button"
						(click)="clickButton($event, order, orderIndex)" 
						[routerLink]="['/admin/managed-board', { outlets: { sub: ['stack', order.value.products[0].product.vendor._id] } }]" 
						style="width:90%;">
						{{ order.value.todo }} 
					</button>
				</span>
				<mat-form-field *ngIf="orderId==order.value._id" floatLabel="never" style="width:90%">
				  <mat-select placeholder="TO DO" formControlName="todo" (click)="clickField2($event, order, orderIndex)">
						<mat-option *ngFor="let todoOption of todoOptions" [value]="todoOption.value" (click)="clickField2($event,order, orderIndex)">
							{{ todoOption.viewValue }}
						</mat-option>
					</mat-select>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="notify">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> NOT. </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex;" [formGroupName]="i" fxFlex="0 0 50px">
				<mat-checkbox (click)="toggleNotify($event,order, orderIndex)" formControlName="notify">
				</mat-checkbox>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 200px"></mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex;" fxFlex="0 0 200px" [formGroupName]="i">
	    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
		    <button mat-button (click)="clickSaveOrder(order)" [disabled]="order.controls.initials.invalid || !order.value.products[0].product.vendor"><mat-icon>save_box_outline</mat-icon>SEND TO STACK</button>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="log">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 300px"> LOG </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=dataIndex; let row;" fxFlex="0 0 300px" [formGroupName]="i">
	    	<app-log-menu [order]="order" (clickField)="clickField2($event,order,i)"></app-log-menu>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="expandedDetail">
	 	  <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" [formGroupName]="orderIndex" class="expandedCell">

	      <div class="example-element-detail" style="width:100%" [class.mat-elevation-z0]="order!=expandedElement" [class.mat-elevation-z8]="order==expandedElement" [@detailExpand]="order == expandedElement ? 'expanded' : 'collapsed'">

	      		<mat-table #table [dataSource]="dataSource2[orderIndex]" class="mat-elevation-z2">
						  

						  <ng-container matColumnDef="notes">
						    <mat-header-cell *matHeaderCellDef fxFlex="1 1 90%"> NOTES </mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 90%">
						      <span *ngIf="orderId!=order.value._id">{{ order.value.notes }}</span>
						      <mat-form-field *ngIf="orderId==order.value._id" style="width:100%;padding-left:10px;padding-right:10px;" floatLabel="never">
							      <input matInput placeholder="NOTES" formControlName="notes" (click)="clickField2($event, order, orderIndex)">
							    </mat-form-field>
						    </mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="action">
						    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 220px"></mat-header-cell>
						    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 220px">
						    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
							      <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
							      <mat-label><mat-icon>gesture</mat-icon></mat-label>
							    </mat-form-field>
							    <button mat-button (click)="clickSaveOrder(order)" [disabled]="order.controls.initials.invalid || !order.value.products[0].product.vendor"><mat-icon>save_box_outline</mat-icon>SEND TO STACK</button>
						    </mat-cell>
						  </ng-container>

						  <mat-row 
						  	*matRowDef="let row; columns: displayedColumns2; let i=index; let even=even; let odd=odd;" 
						  	fxLayout="row" 
						  	fxLayoutAlign="space-between center" 
						  	appHighlight 
						  	[ngClass]="{rowEven:even, rowOdd:odd}" 
						  	style="border-left:1px solid #dfdfdf !important;" 
						  	(click)="clickOrder($event, row, orderIndex)">
						  </mat-row>
						</mat-table>

				</div>
	    </mat-cell>
	  </ng-container>

	  <mat-header-row 
	  	*matHeaderRowDef="displayedColumns" 
	  	fxLayout="row" 
	  	fxLayoutAlign="start center">
	  </mat-header-row>
	  <mat-row 
	  	*matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
	  	fxLayout="row" 
	  	fxLayoutAlign="start center" 
	  	[ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
	  	style="border-left:1px solid #dfdfdf !important;" 
	  	(click)="toggleExpanded($event, row, i)">
	  </mat-row>
	  <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
	  	[class.example-detail-row-expanded]="expandedElement === element"
	  	[class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>
	</mat-table>
</div>