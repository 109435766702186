<div *ngIf="!dataSource.data">
	<h3 style="text-align:center;">LOADING RECEIVABLE</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="container" style="width:100%">
  <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">

    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let vendorCount; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span style="font-weight:bold;" *ngIf="vendorCount?.vendor"> {{ vendorCount.vendor.name }} </span>
        <span style="font-weight:bold;" *ngIf="!vendorCount?.vendor && vendorCount?.borrowingStore"> {{ vendorCount?.borrowingStore.storeName }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
      <mat-cell *matCellDef="let vendorCount; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="vendorCount.unpaid != 1"> {{ vendorCount.unpaid }} ORDERS</span>
        <span *ngIf="vendorCount.unpaid == 1"> {{ vendorCount.unpaid }} ORDER</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 100%">  </mat-header-cell>
      <mat-cell *matCellDef="let vendor; let orderIndex=dataIndex;" fxFlex="1 1 100%" style="padding-left:16px;justify-content: flex-start !important;">
      	<mat-radio-group *ngIf="expandedElement === vendor" matInput>
					<mat-radio-button value="all" [checked]="invoiceStatus[orderIndex]=='all'" (click)="setInvoiceStatus('all',orderIndex)">UNPAID</mat-radio-button>&nbsp;&nbsp;
					<mat-radio-button value="paid" [checked]="invoiceStatus[orderIndex]=='paid'" (click)="setInvoiceStatus('paid',orderIndex)">PAID</mat-radio-button>
				</mat-radio-group>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let vendorCount; let orderIndex=dataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="vendorCount!=expandedElement" [class.mat-elevation-z8]="vendorCount==expandedElement" [@detailExpand]="vendorCount == expandedElement ? 'expanded' : 'collapsed'">

        <app-payable-orders *ngIf="vendorCount==expandedElement" [vendor]="vendorCount.vendor" [borrowingFromStore]="vendorCount.borrowingFromStore" [borrowingStore]="vendorCount.borrowingStore" (refreshVendorOrders)="getVendorOrders('receivable')" [status]="invoiceStatus[orderIndex]" style="width:100%;"></app-payable-orders>

        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row 
      *matHeaderRowDef="displayedColumns" 
      fxLayout="row" 
      fxLayoutAlign="start center">
    </mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
      fxLayout="row" 
      fxLayoutAlign="start center" 
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
      style="border-left:1px solid #dfdfdf !important;" 
      (click)="toggleExpanded($event, row, i)">
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>
  </mat-table>
</div>


