import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Patient } from '@models/patient';
import { ApiResponse } from '@classes/api-response';
import { AuthService } from '@services/auth.service';
import { FavoriteLine } from '@models/favorite-line';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private base_url = environment.base_url + '/patient';

  constructor(public http: HttpClient, private auth: AuthService) { }

  queryPatients(query: String): Observable<Patient[]> {
    var store = this.auth.getStore();
    return this.http.get(`${this.base_url}/query/${query}/store/${store}`).pipe(map( (res: ApiResponse<Patient[]>) => res.patients ));
  }

  queryPatientsList(query: String): Observable<Patient[]> {
    var store = this.auth.getStore();
    return this.http.get(`${this.base_url}/queryList/${query}/store/${store}`).pipe(map( (res: ApiResponse<Patient[]>) => res.patients ));
  }

  getPatient(patientId: String): Observable<Patient> {
    return this.http.get(`${this.base_url}/${patientId}`).pipe(map( (res: ApiResponse<Patient>) => res.patient ));
  }

  getAllPatients(): Observable<Patient[]> {
    var store = this.auth.getStore();
    return this.http.get(`${this.base_url}/store/${store}`).pipe(map( (res: ApiResponse<Patient[]>) => res.patients ));
  }

  addPatient(patient: Patient): Observable<ApiResponse<any>> {
    patient.store = this.auth.getStore();
    let body = JSON.stringify(patient);
    return this.http.post(`${this.base_url}`, body).pipe(map( (res: ApiResponse<any>) => res ));
  }

  updatePatient(patient: Patient): Observable<Patient> {
    let body = JSON.stringify(patient);
    return this.http.put(`${this.base_url}`, body).pipe(map( (res: ApiResponse<Patient>) => res.patient ));
  }

  deletePatient(patientId: any): Observable<ApiResponse<any>> {
    return this.http.delete(`${this.base_url}/${patientId}`).pipe(map( (res: ApiResponse<Patient>) => res ));
  }

  updatePatientDiscount(patientId: any, discount: Object): Observable<Patient> {
    let body = JSON.stringify(discount);
    return this.http.put(`${this.base_url}/updateDiscount/${patientId}`, body).pipe(map( (res: ApiResponse<Patient>) => res.patient ));
  }

  updateFavoriteLines(patientId: any, favoriteLines: Object): Observable<Patient> {
    let body = JSON.stringify(favoriteLines);
    return this.http.put(`${this.base_url}/updateFavoriteLines/${patientId}`, body).pipe(map( (res: ApiResponse<Patient>) => res.patient ));
  }
}
