<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
  <div fxFlex="calc(100%-160px)" [formGroup]="formGroup">

  	<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
		  <div fxFlex>
		    <mat-form-field appearance="fill" fxFlex="1 1 100%">
				  <mat-label>RX USE</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>
		  </div>
		  <div fxFlex="100px" style="text-align:center">
		  	<label>RX TO COME</label>
		  	<br>
		    <mat-checkbox></mat-checkbox>
		  </div>
		  <div fxFlex>
		  	<mat-form-field appearance="fill" style="width:140px;">
				  <mat-label>EXAM DATE</mat-label>
				  <input matInput [matDatepicker]="pickerExamDate">
				  <mat-datepicker-toggle matSuffix [for]="pickerExamDate"></mat-datepicker-toggle>
				  <mat-datepicker #pickerExamDate></mat-datepicker>
				</mat-form-field>
			</div>
		  <div fxFlex>
		    <mat-form-field appearance="fill" fxFlex="1 1 100%">
				  <mat-label>EXPIRES</mat-label>
				  <mat-select>
				    <mat-option>1 YEAR</mat-option>
				    <mat-option>2 YEARS</mat-option>
				  </mat-select>
				</mat-form-field>
		  </div>
		  <div fxFlex="40px" style="text-align:center">
		  	<label>HIDE</label>
		  	<br>
		    <mat-checkbox></mat-checkbox>
		  </div>
		</div>


    <div fxLayout="row" fxLayoutAlign="space-between center" formGroupName="right">
      <div fxFlex="7%"><b>RIGHT</b></div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="SPH" type="text" class="form-control" formControlName="sph">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="CYL" type="text" class="form-control" formControlName="cyl">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="AXIS" type="text" class="form-control" autocomplete="off" formControlName="axis">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="ADD" type="text" class="form-control" formControlName="add">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="SEG HGT" [readonly]="true" type="text" class="form-control" formControlName="segHgt">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism1">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <mat-select placeholder="B" formControlName="b1">
            <mat-option value="U">U</mat-option>
            <mat-option value="D">D</mat-option>
            <mat-option value="O">O</mat-option>
            <mat-option value="I">I</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism2">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <mat-select placeholder="B" formControlName="b2">
            <mat-option value="U">U</mat-option>
            <mat-option value="D">D</mat-option>
            <mat-option value="O">O</mat-option>
            <mat-option value="I">I</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PD DIST" type="text" class="form-control" formControlName="pdd">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PD NEAR" type="text" class="form-control" formControlName="pdn">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="OC" type="text" class="form-control" formControlName="oc">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" formGroupName="left">
      <div fxFlex="7%"><b>LEFT</b></div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="SPH" type="text" class="form-control" formControlName="sph">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="CYL" type="text" class="form-control" formControlName="cyl">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="AXIS" type="text" class="form-control" autocomplete="off" formControlName="axis">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="ADD" type="text" class="form-control" formControlName="add">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="SEG HGT" [readonly]="true" type="text" class="form-control" formControlName="segHgt">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism1">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <mat-select placeholder="B" formControlName="b1">
            <mat-option value="U">U</mat-option>
            <mat-option value="D">D</mat-option>
            <mat-option value="O">O</mat-option>
            <mat-option value="I">I</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PRISM" type="text" class="form-control" formControlName="prism2">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <mat-select placeholder="B" formControlName="b2">
            <mat-option value="U">U</mat-option>
            <mat-option value="D">D</mat-option>
            <mat-option value="O">O</mat-option>
            <mat-option value="I">I</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PD DIST" type="text" class="form-control" formControlName="pdd">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="PD NEAR" type="text" class="form-control" formControlName="pdn">
        </mat-form-field>
      </div>
      <div fxFlex="7%">
        <mat-form-field style="width:100%">
          <input matInput placeholder="OC" type="text" class="form-control" formControlName="oc">
        </mat-form-field>
      </div>
    </div>


		<div>
			<mat-form-field appearance="fill" style="width:100%">
			  <mat-label>RX NOTES</mat-label>
			  <textarea matInput rows="6"></textarea>
			</mat-form-field>
		</div>

	</div>

	<div fxFlex="150px">
		<mat-card>
			<mat-card-content>
				PHOTO
			</mat-card-content>
		</mat-card>

    <div fxLayout="row" fxLayoutAlign="space-around center">
			<div>
				<button mat-icon-button>
	        <mat-icon>email</mat-icon>
	      </button>
			</div>
			<div>
				<button mat-icon-button>
	        <mat-icon>print</mat-icon>
	      </button>
			</div>
			<div>
				<button mat-icon-button>
	        <mat-icon>delete</mat-icon>
	      </button>
			</div>
		</div>


		<mat-card>
			<mat-card-content>

				<mat-form-field style="width:100%">
          <input matInput placeholder="LAST EDITOR" type="" autocomplete="off" value="11/9/2021" disabled>
          <span matPrefix>KM&nbsp;</span>
        </mat-form-field>

  			<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>

		    <button mat-button [matMenuTriggerFor]="menu">
		    	<mat-icon>more_vert</mat-icon>
		    	<span>SAVE</span>
		    </button>
	      <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED FRAME</button>
          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED ON APPROVAL</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SEND TO STACK</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/HOLD</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/QUOTE</button>
          <button mat-menu-item type="button" style="margin-left:10px;">ADD TO CART</button>
          <button mat-menu-item type="button" style="margin-left:10px;">VOID</button>
        </mat-menu>

      </mat-card-content>
    </mat-card>
	</div>
</div>