import { Pipe, Component, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Product } from '@models/product';
import { Vendor, VendorInventory } from '@models/vendor';
import { VendorOrder } from '@models/vendor-order';
import { Store } from '@models/store';
import { ProductService } from '@services/product.service';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { StoreService } from '@services/store.service';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';

const imageURL = environment.base_url + '/image/';

@Component({
  selector: 'app-payable-products',
  templateUrl: './payable-products.component.html',
  styleUrls: ['./payable-products.component.css']
})
export class PayableProductsComponent implements OnInit {
  @Input() public vendorOrder: VendorOrder = null;
  // @Input() public products: FormArray = null;


  @Input()
  set products(val: FormArray) {
    this._products = val;
    this.dataLoading = true;
    this.setProductForms();
    this.dataLoading = false;
    this.dataSource.paginator = this.paginator;
    this.cdRef.detectChanges();
  }
  public _products: FormArray;


	@Input() public orderIndex: number = null;
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatTable, {static: false}) table: MatTable<FormControl>;
	@ViewChild('search', {static: false}) search: MatInput;
	@ViewChildren('formRow') rows: QueryList<ElementRef>;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
 	@ViewChild('statusSelect', {static: false}) set content(content: MatSelect) { 
 		if (content != undefined) {
 			this.statusSelect = content;
 			if (this.clickedStatus >= 0) {
 				this.statusSelect.open();
 				this.cdRef.detectChanges(); 
 			}
 		} 
 	}
  fieldOpen: boolean = false;
  clickedImage: boolean = false;
  index: number;
  expandedElement = null;
  clickedStatus: number;
  storeOptions: any[];
 	statusSelect: MatSelect;
	dataSource = new MatTableDataSource<FormControl>();
  dataLoading: boolean = false;
	displayedColumns: string[] = ['images', 'vendor', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'store', 'quantity', 'patient', 'action', 'delete'];
 	productsForm: FormGroup;
  productId: String;
  productIndex: number;
  editProduct: FormGroup;
  typeOptions: Object[] = [{viewValue: 'RX', value: 'RX'}, {viewValue: 'SUN', value: 'SUN'}, {viewValue: 'OTHER', value: 'OTHER'}];
  commissionOptions: Object[] = [{viewValue: 'NONE', value:'NONE'}, {viewValue: 'SPIFF', value:'SPIFF'}, {viewValue: 'DRILLMOUNT', value:'DRILLMOUNT'}];
  defaultSortColumn:string = '';
	public uploaderProduct:FileUploader = new FileUploader({url: environment.base_url + '/image/product', itemAlias: 'photoProduct'});

  constructor(private vendorService: VendorService, private vendorOrderService: VendorOrderService, private storeService: StoreService, private auth: AuthService, private productService: ProductService, private fb: FormBuilder, private cdRef:ChangeDetectorRef, public dialog: MatDialog) {
    this.storeOptions = this.storeService.stores;
    this.productsForm = this.fb.group({
      productsFormArray: this.fb.array([])
    });
  }

	ngOnInit() {
		this.uploaderProduct.authToken = `Bearer ${this.auth.getToken()}`;
		this.uploaderProduct.onAfterAddingFile = (file)=> {
			file.withCredentials = false;
			this.uploaderProduct.uploadAll();
		};

		this.uploaderProduct.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
			var imageId = JSON.parse(response);
			for (let x = 0; x < imageId.length; x++) {
				let image = imageId[x];
  			if (!Array.isArray(this.productsForm.value.productsFormArray[this.productIndex].images)) {
					this.productsForm.value.productsFormArray[this.productIndex].images = [];
				}
  			this.productsForm.value.productsFormArray[this.productIndex].images.push(image);
  			if (this.productId == 'NEW') {
          // this.productsForm.value.productsFormArray[this.productIndex].images = this.editProduct.value.images;
  			} else {
  				this.productService.addImage(this.productId, imageId[x])
		        .subscribe(product => {
		          console.log("product image added " + product._id);
		        });
  			}
			}
    	this.cdRef.detectChanges(); 
		};
	}

	ngAfterViewInit() {
  }

  setProductForms() {
    this.productsForm = this.fb.group({
      productsFormArray: this._products
    });
    this.dataSource = new MatTableDataSource<FormControl>(this.productsForm.controls.productsFormArray['controls']);
    // this.storeService.getAllStores().subscribe((res) => {
    //   this.storeOptions = res;
	   //  this.cdRef.detectChanges();
    // });
  }

  addProductFormItem(product) {
    const control = <FormArray>this.productsForm.controls['productsFormArray'];
    const itemsCtrl = this.initProductFormItem(product);
    control.push(itemsCtrl);
    return itemsCtrl;
  }

  initProductFormItem(product: Product) {
    let newProduct = new Product(product);
    newProduct.images = newProduct.images;
    newProduct.store = (newProduct.store) ? this.getStoreObject(newProduct.store) : this.auth.getStoreObject();
    let formGroup: FormGroup = this.fb.group(newProduct);
    formGroup.addControl('imageHover', new FormControl(false));
    return formGroup;
  }

  getStoreObject(store: Store): Store {
    for (let x = 0; x < this.storeOptions.length; x++) {
      if (this.storeOptions[x]._id == store._id) {
        return this.storeOptions[x];
      }
    }
  }

  setSorter() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (!item.value[property]) {
        item.value[property] == '';
      }
      switch(property) {
        case 'vendor': 
          if (item.value.vendor) {
            let vendor: Object = item.value.vendor;
            return vendor['name'].toUpperCase();
          } else {
            return '';
          }
          break;
        //case 'vendor.name': return item.vendor.name;
        default: 
          return item.value[property].toUpperCase();
      }
    };
    this.dataSource.sort = this.sort;
  }

  // clickRow($event: Event, product: FormGroup, index) {
  //   if (!this.fieldOpen) {
  //     	return;
  //     } else if (this.productId != "" && this.productId != undefined && this.productId != product.value._id) {
  //       this.saveEditedProduct(this.editProduct, this.productIndex);
  //     }

  //     this.editProduct = product;
  //     this.productId = product.value._id;
  //     this.productIndex = index;
  //   }
  // }

  clickRow($event: Event, product: FormGroup, index) {
  	console.log('clickRow')
    if (!this.fieldOpen) {
	    this.productId = product.value._id;
	    this.productIndex = index
	    this.index = index;
	    if (!this.clickedImage) {
	      $event.preventDefault();
	      $event.stopPropagation();
	    }
	    this.fieldOpen = false;
	  }
  }

  @HostListener('document:click', ['$event']) clickOutside ($event) {
    if (!this.fieldOpen && !this.clickedImage) {
			if (this.productId != '' && this.productId != undefined) {
        //this.saveEditedProduct(this.editProduct, this.productIndex);
      }

      this.productId  = '';
	    this.productIndex = null;
      this.expandedElement = null;
    } else {
      this.fieldOpen = false;
    }
  }

  // @HostListener('document:click', ['$event']) clickOutside ($event: Event) {
  //   if (!this.fieldOpen) {
	 //    if (this.productId == "NEW" && this.productsForm['model'] == "") {
	 //    	this.removeProductFormItem(this.productIndex);
	 //      this.productId = "";
	 //      //this.productsForm = null;
	 //    } else {
		// 		if (this.productId != "" && this.productId != undefined) {
  //         // this.editProduct = this.productsForm.value.productsFormArray[this.productIndex];
	 //        this.saveEditedProduct(this.editProduct, this.productIndex);
	 //      }
	 //      this.productId = "";
	 //      //this.productsForm = null;
	 //    }
  //   } else {
  //     this.fieldOpen = false;
  //   }
  // }

  saveEditedProduct(editProduct, index) {
    if (this.productId == "NEW") {
    	if (this.editProduct.value.model != '') {
        editProduct.value._id = null;
        editProduct.value.vendor = (this.vendorOrder.vendor as Vendor)._id;
        editProduct.value.model = editProduct.value.model.toUpperCase();
        
				if (!Array.isArray(editProduct.value.images)) {
					editProduct.value.images = [];
				}
				var editImages = editProduct.value.images;
				editProduct.value.images = [];
	      this.productService.addProduct(editProduct.value)
	        .subscribe(res => {
	          if (res["success"]) {
	          	for (let x = 0; x < editImages.length; x++) {
			        	this.productService.addImage(res.product._id, editImages[x])
					        .subscribe(product => {
					          console.log("product image added " + product._id);
										//this.getVendorProducts();
					        });
			        }
	          	const formArray = <FormArray>this.productsForm.controls['productsFormArray'];
          		formArray.at(index).patchValue(res["product"]);
          		this.dataSource.data[index] = res["product"];

							//this.getVendorProducts();
	          }
	        });
        this.productId = "";
        this.editProduct = null;
        this.productIndex = null;
      }
    } else {
      this.saveProduct(editProduct);
    }
    
  }

  saveProduct(editProduct) {
    this.productService.updateProduct(editProduct.value._id, editProduct.value)
      .subscribe(product => {
        this.productId = "";
        this.editProduct = null;
        this.productIndex = null;
        //this.getVendorProducts();
      });
  }


  deleteProductFromInvoice($event, editProduct:FormGroup, order, index) {
    $event.preventDefault();
    $event.stopPropagation()
  	let dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE PRODUCT FROM INVOICE?' }
    });
  	dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
		    if (this.productId == "NEW") {
		    	this.removeProductFormItem(index);
		      this.productId = "";
		      this.editProduct = null;
					this.productIndex = null;
		    } else {
		      this.vendorOrderService.deleteProductFromInvoice(editProduct.value.product._id, order, this.vendorOrder._id).subscribe(product => {
		          this.productId = "";
		          this.editProduct = null;
							this.productIndex = null;
		    			this.removeProductFormItem(index);
		        });
		    }
      }
    });
  }

  prepareSaveProduct(editProduct) {
    var product = new Product();
    product._id = editProduct.value._id;
    product.model = editProduct.value.name;
    return product;
  }





  removeProductFormItem(index) {
    const control = <FormArray>this.productsForm.controls['productsFormArray'];
    control.removeAt(index);
    this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
  }

  addProduct($event) {
    var newProduct = new Product();
    newProduct._id = "NEW";
    newProduct.type = "RX";
    let tmpVendor: Vendor = this.vendorOrder.vendor as Vendor;
    newProduct.status = tmpVendor.defaultManagedBoardStatus;
    newProduct.vendor = this.vendorOrder.vendor;
    newProduct.quantity = 1;
    newProduct.store = this.auth.getStoreObject();
    //let newIndex = ((this.paginator.pageIndex + 1) * this.paginator.pageSize - 1);
    //newIndex = (newIndex > this.paginator.length) ? this.paginator.length : newIndex;
    let newIndex = this.dataSource.data.length;



    this.editProduct = this.addProductFormItem(newProduct);

    this.dataSource = new MatTableDataSource<FormControl>(this.productsForm.controls.productsFormArray['controls']);
    this.paginator.pageSize = newIndex+1;
    this.dataSource.paginator = this.paginator;






    this.productId = newProduct._id;
    this.productIndex = newIndex;
    $event.preventDefault();
    $event.stopPropagation();
  }

  submit() {
    for (var x = 0; x < this.productsForm.controls["productsFormArray"]["length"]; x++) {
      var form = this.productsForm.controls["productsFormArray"]["controls"][x];
    }
  }

  clickField($event, product, index) {
    this.fieldOpen = true;
  }

  clickImage($event, product, index) {
    this.clickedImage = true;
  }

	clickStatus($event, product, index) {
    if (this.clickedStatus != index) {
			$event.preventDefault();
    	$event.stopPropagation();
    }
    this.clickedStatus = index;
  }

  changeStatus($event, product, index) {
  	if (this.productId != "NEW") {
    	this.clickedStatus = -1;
	    let saveProduct = this.productsForm.value.productsFormArray[index];
 			this.statusSelect.close();
 			this.cdRef.detectChanges();
		  this.saveEditedProduct(saveProduct, index);
		}
  }

  changeProductType($event, product: FormGroup, index): boolean {
    // if ($event.source.selected && $event.isUserInput) {
      this.changeCost($event, product, index);
    // }
    return true;
  }

  togglePriceLink($event, product: FormGroup, index) {
    this.fieldOpen = true;
    if (product.value.pricingFormula == 'c') {
      product.controls.pricingFormula.setValue((product.value.type == 'RX') ? product.value.vendor.pricingFormulaOptical : product.value.vendor.pricingFormulaSun);
      product.controls.retail.setValue(Product.calculateProductPrice(product.value));
      let arr:FormArray = <FormArray>this.productsForm.controls['productsFormArray'];
      let control:FormGroup = <FormGroup>arr.controls[index];
      control.controls.retail.setValue(product.value.retail);
      if (!this.editProduct || this.editProduct && this.editProduct.value._id != product.value._id) {
        this.saveProduct(product);
      }
    } else {
      product.controls.pricingFormula.setValue('c');
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  calculateProductPrice(product) {
    let rx_validate = /^x.+p.+$/;
    let rx_extract = /^x(.+)p(.+)$/;
    let result = [];
    let multiplier = 1;
    let adder = 0;
    if (rx_validate.test(product.pricingFormula)) {
      result = rx_extract.exec(product.pricingFormula);
      multiplier = parseFloat(result[1]);
      adder = parseFloat(result[2]);
    }
    if (parseFloat(product.cost)) {
      return Math.ceil((Math.round((parseFloat(product.cost) * multiplier + adder) * 100) / 100)/5)*5;
    }
    return null;
  }

  changeRetail($event, product: FormGroup, index) {
    let arr:FormArray = <FormArray>this.productsForm.controls['productsFormArray'];
    let control:FormGroup = <FormGroup>arr.controls[index];
    let cost = control.controls.cost.value;
    let retail = control.controls.retail.value;
    product.get('cost').setValue(cost);
    product.get('pricingFormula').setValue((product.value.type == 'RX') ? product.value.vendor.pricingFormulaOptical : product.value.vendor.pricingFormulaSun);

    let calculatedRetail = Product.calculateProductPrice(product.value);
    if (calculatedRetail != retail) {
      product.controls.pricingFormula.setValue('c');
    }
    control.controls.pricingFormula.setValue(product.value.pricingFormula);
    this.editProduct = product;
  }

  changeCost($event, product: FormGroup, index) {
    // let arr:FormArray = <FormArray>this.productsForm.controls['productsFormArray'];
    // let control:FormGroup = <FormGroup>arr.controls[index];
    // let cost = control.controls.cost.value;
    // let retail = control.controls.retail.value;
    // let type = control.controls.type.value;
    // product.get('type').setValue(type);
    // product.get('cost').setValue(cost);
    console.log(product)
    if (product.value.pricingFormula != 'c') {
      product.controls.pricingFormula.setValue((product.value.type == 'RX') ? product.value.vendor.pricingFormulaOptical : product.value.vendor.pricingFormulaSun);
      product.controls.retail.setValue(Product.calculateProductPrice(product.value));
    }
    //control.controls.retail.setValue(product.value.retail);
    this.editProduct = product;
  }

  imageMouseEnter(imageHover) {
    imageHover.setValue(true);
  }

  imageMouseLeave(event, imageHover) {
    imageHover.setValue(false);
  }

  imageDelete(image, productIndex, imageIndex, productId) {
  	let dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE IMAGE?' }
    });
  	dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.productService.deleteImage(productId, image).subscribe((res) => {
          let imageArray = this.productsForm.value.productsFormArray[productIndex].images;
          imageArray.splice(imageIndex, 1);
					this.productsForm.controls['productsFormArray']['controls'][productIndex]['controls']['images'].setValue(imageArray);
   			 });
      }
    });
  }
}


