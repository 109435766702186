    <mat-card>
      <mat-toolbar [style.background-color]="currentStore?.headerColor">
        <span>INQUIRIES</span>
      </mat-toolbar>
      <mat-card-content>
        <app-stack-vendors section="inquiries"></app-stack-vendors>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-toolbar [style.background-color]="currentStore?.headerColor">
        <span>ORDERS</span>
      </mat-toolbar>
      <mat-card-content>
        <app-stack-vendors section="orders"></app-stack-vendors>
      </mat-card-content>
    </mat-card>