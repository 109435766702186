import { VendorOrder } from '@models/vendor-order';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import ObjectID from 'bson-objectid';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Payable } from '@models/api.model';
import { filter, of } from 'rxjs';
import {
  paidColumns,
  unpaidColumns,
  allColumns,
  defaultSort,
  statusColumns,
  defaultColumns,
  colSortOrder,
  sortConfig,
  orderStatusMap,
} from '../constants';
import { Order } from '@models/order';
import { Product, ProductElement } from '@models/product';
import { Vendor } from '@models/vendor';
import LodashGet from 'lodash/get';
import { OrderDetails } from '../models';
import { DecimalPipe } from '@angular/common';

export const createInvoiceForm = (
  order: VendorOrder,
  editMode: boolean,
  isCredit?: boolean
): FormGroup => {
  return new FormGroup({
    invoiceNumber: new FormControl(
      { value: order.invoiceNumber ?? null, disabled: !editMode },
      isCredit ? [] : Validators.required
    ),
    date: new FormControl(
      { value: order.date ?? new Date(), disabled: !editMode },
      Validators.required
    ),
    datePaid: new FormControl({
      value: order.datePaid ?? null,
      disabled: !editMode,
    }),
    status: new FormControl(order.status ?? null),
    shipping: new FormControl(
      {
        value: getNumberPrecision(order.shipping ?? 0),
        disabled: !editMode,
      },
      Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/)
    ),
    tax: new FormControl(
      {
        value: getNumberPrecision(order.tax ?? 0.0),
        disabled: !editMode,
      },
      Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/)
    ),
    total: new FormControl(
      { value: getNumberPrecision(order.total), disabled: !editMode },
      [
        Validators.required,
        Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/),
      ]
    ),
    amountDue: new FormControl(
      {
        value: getNumberPrecision(
          order.amountDue ?? order.total - (order.partialPayment ?? 0)
        ),
        disabled: true,
      },
      [
        Validators.required,
        Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/),
      ]
    ),
    initials: new FormControl(null, Validators.required),
    notes: new FormControl(null),
    partialPayment: new FormControl(
      {
        value: getNumberPrecision(order.partialPayment ?? 0),
        disabled: !editMode,
      },
      Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/)
    ),
    partialPaymentDate: new FormControl({
      value: order.partialPaymentDate ?? null,
      disabled: !editMode,
    }),
    cost: new FormControl(
      {
        value: getNumberPrecision(order.cost ?? getProductsCost(order)),
        disabled: !editMode,
      },
      Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/)
    ),
    credit: new FormControl(
      {
        value: getNumberPrecision(order.credit ?? 0),
        disabled: !editMode,
      },
      Validators.pattern(/^-?(?:\d{1,9}(?:\.\d{1,2})?)?$/)
    ),
  });
};

export const getProductsTotal = (
  order: VendorOrder,
  isCredit: boolean = false
) => {
  const total = getNumberPrecision(
    order.products.reduce((acc, p) => (acc += p.cost + p.tax + p.shipping), 0)
  );
  return isCredit ? total * -1 : total;
};

export const getProductsCost = (
  order: VendorOrder,
  isCredit: boolean = false
) => {
  const cost = getNumberPrecision(
    order.products.reduce((acc, p) => {
      return acc + p.cost ?? 0;
    }, 0)
  );
  return isCredit ? cost * -1 : cost;
};

export const getProductsTax = (
  order: VendorOrder,
  isCredit: boolean = false
) => {
  const tax = getNumberPrecision(
    order.products.reduce((acc, p) => (acc += p.tax ?? 0), 0)
  );
  return isCredit ? tax * -1 : tax;
};

export const getProductsShipping = (
  order: VendorOrder,
  isCredit: boolean = false
) => {
  const shipping = getNumberPrecision(
    order.products.reduce((acc, p) => (acc += p.shipping ?? 0), 0)
  );
  return isCredit ? shipping * -1 : shipping;
};

export const getProductsTotalCost = (
  order: VendorOrder,
  isCredit: boolean = false
) => {
  const totalCost = getNumberPrecision(
    order.products.reduce(
      (acc, p) => acc + (p.shipping ?? 0) + (p.tax ?? 0) + (p.cost ?? 0),
      0
    )
  );
  return isCredit ? totalCost * -1 : totalCost;
};

export const getThousandths = (val: number | string) => {
  const endNumbers = `${val}`.split('.')?.[1]?.slice(2);
  return endNumbers?.length
    ? +endNumbers / (100 * Math.pow(10, endNumbers.length))
    : 0.0;
};

export const recalculateOrderValues = (
  value: Record<string, string | number>,
  order: VendorOrder,
  updateProducts?: boolean
): Record<string, number> => {
  const isCredit = ['RETURNED', 'CREDITED', 'AWAITING CREDIT'].includes(
    order.status
  );
  const { products } = order;
  const { shipping, tax, partialPayment, cost } = value;
  const orderShipping = Number(shipping ?? 0);
  const orderTax = Number(tax ?? 0);

  if (!isCredit && updateProducts) {
    const productsTax = getProductsTax(order);
    const productsShipping = getProductsShipping(order);
    const taxableProducts = products.filter(
      (p) => order.taxBothRxAndSun || p.type?.toLowerCase() === 'rx'
    );
    const taxDiff = +(orderTax - productsTax).toFixed(2);
    // Calculate the amount of tax to add to each taxable product
    const avgTax = taxDiff / taxableProducts.length;
    const preciseAvgTax = +(avgTax - getThousandths(avgTax)).toFixed(2);
    const preciseDiff = getThousandths(avgTax);
    const additionalTaxDiff = preciseDiff * taxableProducts.length;

    // Add the tax to each taxable product
    taxableProducts.forEach((p) => (p.tax += preciseAvgTax));

    // Spread the additional cents of taxable products
    const numberOfTaxableProductsToAdjust = additionalTaxDiff / 0.01;
    for (let i = 0; i < numberOfTaxableProductsToAdjust; i++) {
      taxableProducts[i].tax += 0.01;
    }

    // Calculate the amount of shipping to add to each taxable product
    const shippingDiff = +(orderShipping - productsShipping).toFixed(2);
    const avgShipping = shippingDiff / products.length;
    const preciseAvgShipping = +(avgTax - getThousandths(avgTax)).toFixed(2);
    const preciseShippingDiff = getThousandths(avgShipping);
    const additionalShippingDiff = preciseShippingDiff * products.length;
    // Add the tax to each taxable product
    products.forEach((p) => (p.shipping += preciseAvgShipping));

    // Spread the additional cents of taxable products
    const numberOfProductsToAdjust = additionalShippingDiff / 0.01;
    for (let i = 0; i < numberOfProductsToAdjust; i++) {
      products[i].shipping += 0.01;
    }
  }

  const {
    cost: accCost,
    shipping: accShipping,
    tax: accTax,
  } = products.reduce(
    (acc, p) => {
      return {
        shipping: getNumberPrecision(acc.shipping + p.shipping),
        tax: getNumberPrecision(acc.tax + p.tax),
        cost: getNumberPrecision(acc.cost + p.cost),
      };
    },
    {
      shipping: 0,
      tax: 0,
      cost: 0,
    }
  );
  // console.log(accShipping, accTax, accCost)

  const total = getProductsTotalCost(order, isCredit);
  const proposedCredit =
    order.proposedCredit ??
    +getNumberPrecision(Math.abs(total) - Math.abs(+shipping)) * -1;

  const expectedLoss = -(
    Math.abs(+total) - Math.abs(Math.abs(+total) - Math.abs(+shipping))
  );

  order.total = total;
  order.amountDue = +getNumberPrecision(+total - +partialPayment);
  order.tax = +getNumberPrecision(+orderTax);
  order.shipping = +getNumberPrecision(+orderShipping);
  order.partialPayment = +getNumberPrecision(partialPayment);
  order.cost = +cost ?? getProductsCost(order);

  return {
    total: +getNumberPrecision(total),
    amountDue: +getNumberPrecision(+total - +partialPayment),
    tax: +getNumberPrecision(+orderTax),
    shipping: +getNumberPrecision(+orderShipping),
    partialPayment: +getNumberPrecision(partialPayment),
    proposedCredit: +getNumberPrecision(proposedCredit),
    expectedCost: +getNumberPrecision(accCost),
    expectedTax: +getNumberPrecision(accTax),
    expectedShipping: +getNumberPrecision(accShipping),
    expectedLoss,
  };
};

export const recalculateOrderShipping = (
  shipping: number,
  order: VendorOrder,
  isCredit: boolean = false,
  updateProducts: boolean = true
) => {
  if (updateProducts && !isCredit) {
    const { products } = order;
    const productsShipping = getProductsShipping(order);
    console.log(productsShipping);
    const shippingDiff = shipping - productsShipping;
    console.log(shippingDiff);
    const mod = shippingDiff < 0 ? -1 : 1;
    console.log(mod);
    const minDivisor = (1 / 100) * products.length;
    console.log(minDivisor);
    const remainder =
      Math.ceil((Math.abs(shippingDiff) % minDivisor) * 100) / 100;
    console.log(remainder);
    const remainderProducts = remainder * 100;
    console.log(remainderProducts); // Calculate the amount of tax to add to each taxable product
    const avgShipping =
      (mod * (Math.abs(shippingDiff) - remainder)) / products.length;
    console.log(avgShipping);
    // Add the tax to each taxable product
    products.forEach((p) => {
      p.shipping = getNumberPrecision(p.shipping + avgShipping);
    });
    // Spread the additional cents products
    for (let i = 0; i < remainderProducts; i++) {
      products[i].shipping = getNumberPrecision(products[i].shipping + 0.01);
    }
  }

  const total = shipping + order.tax + order.cost;
  order.total = total;
  order.amountDue = isCredit ? 0 : total - order.partialPayment ?? 0;
  order.shipping = shipping;
  return {
    total,
    amountDue: order.amountDue,
    shipping,
  };
};

export const recalculateOrderTax = (
  tax: number,
  order: VendorOrder,
  isCredit: boolean = false,
  updateProducts: boolean = true
) => {
  if (updateProducts && !isCredit) {
    const { products } = order;
    const productsTax = getProductsTax(order);
    const taxableProducts = products.filter(
      (p) => order.taxBothRxAndSun || p.type?.toLowerCase() === 'rx'
    );
    const taxDiff = tax - productsTax;
    const mod = taxDiff < 0 ? -1 : 1;
    const minDivisor = (1 / 100) * taxableProducts.length;
    const remainder = Math.ceil((Math.abs(taxDiff) % minDivisor) * 100) / 100;
    const remainderProducts = remainder * 100;
    const avgTax =
      (mod * (Math.abs(taxDiff) - remainder)) / taxableProducts.length;
    // Add the tax to each taxable product
    taxableProducts.forEach((p) => {
      p.tax = getNumberPrecision(p.tax + avgTax);
    });
    // Spread the additional cents of taxable products
    for (let i = 0; i < remainderProducts; i++) {
      taxableProducts[i].tax = getNumberPrecision(
        taxableProducts[i].tax + 0.01
      );
    }
  }
  const total = tax + order.shipping + order.cost;
  order.total = total;
  order.amountDue = isCredit ? 0 : total - order.partialPayment ?? 0;
  order.tax = tax;
  return {
    total,
    amountDue: order.amountDue,
    tax,
  };
};

export const productUpdated = (
  order: VendorOrder,
  field: string,
  isCredit: boolean = false
) => {
  const updates = {
    total: order.total,
    cost: order.cost,
    shipping: order.shipping,
    tax: order.tax,
    amountDue: isCredit ? 0 : order.total - order.partialPayment,
  };

  switch (field) {
    case 'cost':
      updates.cost = getProductsCost(order, isCredit);
      break;
    case 'tax':
      updates.tax = getProductsTax(order, isCredit);
      break;
    case 'shipping':
      updates.shipping = getProductsShipping(order, isCredit);
      break;
    default:
      break;
  }

  updates.total = updates.tax + updates.shipping + updates.cost;

  Object.keys(updates).forEach((key) => {
    if (order[key] !== updates[key]) {
      order[key] = updates[key];
    }
  });

  return updates;
};

/**
 * Only returns the actual updated values from the form and the new log event. Does not return all current order values.
 * @param order - The original vendor order to compare against
 * @param orderForm - The current form value with vendor order changes
 * @param authService - Auth service to get current user
 * @param updateStatus - New status to update the order to
 * @returns Partial<VendorOrder> - The updated vendor order data plus the new status (if changed) and log event
 */
export const getOrderUpdates = (
  order: VendorOrder,
  changes: Record<string, string | number>,
  authService: AuthService,
  updateStatus?: string,
  includeProducts?: boolean
): Partial<VendorOrder> => {
  const {
    amountDue,
    initials,
    notes: orderNotes,
    status: orderStatus,
    ...rest
  } = changes;
  const isCredit = ['CREDITED', 'RETURNED', 'AWAITING CREDIT'].includes(
    order.status
  );
  const status = updateStatus ?? (orderStatus as string) ?? order.status;
  const log = [
    {
      initials: (initials as string).toUpperCase(),
      item: updateStatus ?? 'UPDATE',
      date: new Date().toISOString(),
      user: authService.currentUser._id,
      _id: new ObjectID().toHexString(),
    },
  ];
  const notes = orderNotes && [
    {
      item: 'NOTE',
      notes: orderNotes as string,
      date: new Date().toISOString(),
      user: authService.currentUser._id,
      _id: new ObjectID().toHexString(),
    },
  ];
  const numbers = {
    amountDue: true,
    partialPayment: true,
    tax: true,
    shipping: true,
    total: true,
    cost: true,
    credit: true,
  };
  const credits = {
    amountDue: true,
    tax: true,
    shipping: true,
    total: true,
    cost: true,
    credit: true,
  };
  const allowNegatives = {
    tax: true,
    shipping: true,
    total: true,
  };
  const orderUpdate = Object.entries(changes).reduce(
    (acc: Record<string, any>, [key, value]: [string, any]) => {
      if (order[key] === value) {
        return acc;
      }
      if (numbers[key]) {
        const num = Math.abs(value as number);
        const mod = isCredit && credits[key] && allowNegatives[key] ? -1 : 1;
        return { ...acc, [key]: getNumberPrecision(num * mod) };
      }
      return { ...acc, [key]: value };
    },
    {}
  );

  if (!includeProducts) {
    return {
      ...orderUpdate,
      status,
      log,
      notes,
    };
  }

  return {
    ...orderUpdate,
    status,
    log,
    notes,
    products: changes.products as any as ProductElement[],
  };
};

export const getOrderFormUpdates = (
  order: VendorOrder,
  orderForm: FormGroup,
  products: Array<ProductElement>,
  authService: AuthService,
  updateStatus?: string,
  includeProducts?: boolean
): Partial<VendorOrder> => {
  const updates = getOrderUpdates(
    order,
    orderForm.getRawValue(),
    authService,
    updateStatus
  );

  if (!includeProducts) {
    return updates;
  }

  return {
    ...updates,
    products,
  };
};

export const recalculateOrderOnProductRemoval = (
  item: any,
  order: VendorOrder
) => {
  const {
    tax: orderTax,
    shipping: orderShipping,
    total: orderTotal,
    products: orderProducts,
    partialPayment = 0,
  } = order;
  const {
    tax: productTax,
    shipping: productShipping,
    cost: productCost,
  } = order.products.find((p) => p.uniqueId === item.uniqueId);
  const products = [...orderProducts].filter(
    (p) => p.product._id !== item.productId
  );
  const taxProducts = products.filter((p) => p.type?.toLowerCase() === 'rx');
  const tax = taxProducts.length
    ? orderTax
    : +getNumberPrecision(orderTax - (productTax ?? 0));
  const shipping = +getNumberPrecision(orderShipping - (productShipping ?? 0));
  const productTotal =
    productCost + productShipping + (taxProducts.length ? 0 : productTax);
  const isCredit = ['CREDITED', 'RETURNED'].includes(order.status);
  const newTotal = isCredit
    ? orderTotal + productTotal
    : orderTotal - productTotal;
  const total = Math.max(+getNumberPrecision(newTotal), 0);
  const additionalTaxDiff = productTax % taxProducts.length;
  const additionalTax = (productTax - additionalTaxDiff) / taxProducts.length;
  taxProducts.forEach((p, i) => {
    p.tax = +getNumberPrecision(
      p.tax + additionalTax + (!i ? additionalTaxDiff : 0),
      2
    );
  });
  return {
    tax,
    shipping,
    total,
    amountDue: Math.max(total - partialPayment, 0),
    products,
  };
};

export const filterOrders = (
  orders: Array<Payable>,
  route: ActivatedRoute,
  filterColumns?: Array<string>
) => {
  const { search } = route.snapshot.queryParams;
  if (search?.length) {
    return of(
      [...orders]?.filter((order) =>
        searchPayable(order, search, filterColumns)
      )
    );
  }
  return of(orders);
};

/** *
 * Based on the status of the payables list, return the appropriate columns to display.
 */
export const getDisplayedColumns = (status: string) => {
  if (status === 'paid') {
    return paidColumns;
  } else if (status === 'unpaid') {
    return unpaidColumns;
  }
  return allColumns;
};

/**
 * Since some values come from the backend as strings, we need to make sure they are numbers when recalculated/saved/displayed.
 * Takes in a string or number and returns a string with 2 decimal places.
 */
export const getNumberPrecision = (
  val: number | string,
  precision?: number
): number => {
  if (!val || isNaN(+val)) {
    return Number('0.00');
  }
  return Number(
    new DecimalPipe('en-US').transform(val, '1.2-2').replace(/,/g, '')
  );
};

/**
 * Flatten the orderGroupMap into an array of OrderDetails objects.
 */
export const setOrders = (
  orderGroupMap: Record<string, any> = {},
  status: string,
  sort?: Sort
) => {
  const { active, direction } = sort ?? defaultSort;
  const sortedOrderGroups = Object.entries(orderGroupMap)
    .sort(([k, v], [j, x]) =>
      direction === 'asc' ? v[active] - x[active] : x[active] - v[active]
    )
    .reduce((acc: Array<any>, [key, value]) => {
      const {
        orders,
        shipping,
        tax,
        total,
        unPaid,
        datePaid: groupDate,
      } = orderGroupMap[key];
      const order = orders[0];
      const { datePaid, groupId, invoiceDate, invoiceNumber } = order;
      if (!key?.length || key === 'no-group' || key === '0') {
        return [
          ...acc,
          ...orders.map((order) => ({ ...order, printable: true })),
        ];
      }

      const header = status !== 'unpaid' && {
        header: true,
        invoiceNumber,
        invoiceDate,
        datePaid: datePaid ?? groupDate,
        shipping,
        tax,
        total,
        groupId,
        unPaid,
      };

      if (header) {
        return [
          ...acc,
          header,
          ...orders.map((order) => ({ ...order, printable: false })),
        ];
      }

      return [
        ...acc,

        ...orders.map((order) => ({ ...order, printable: false })),
      ];
    }, []);
  return [...sortedOrderGroups];
};

export const setGroupMap = (
  data: Array<Payable>,
  status: string,
  sort?: Sort
) => {
  const payables = data.map((item: Payable) => ({
    ...item,
    awaitingCredit: item.status === 'RETURNED',
    status: item.status === 'RETURNED' ? 'AWAITING CREDIT' : item.status,
    unPaid: item.status?.toLowerCase() !== 'paid',
  }));

  return payables.reduce((acc: Record<string, any>, order: OrderDetails) => {
    let groupKeys: Array<string> = [];
    const { groupId, payments: orderPayments } = order;
    if (
      status.toLowerCase() === 'unpaid' &&
      !groupId &&
      !orderPayments?.length
    ) {
      groupKeys = ['no-group'];
    } else if (
      status.toLowerCase() === 'paid' &&
      !groupId &&
      !orderPayments?.length
    ) {
      groupKeys = [new ObjectID().toHexString()];
    } else {
      const keys =
        status.toLowerCase() === 'paid' && orderPayments.length
          ? [...(orderPayments ?? [])]
              .map((p) => p.groupId)
              .filter((key) => !!key)
          : [];
      groupKeys = groupId
        ? Array.from(new Set([...keys, groupId]))
        : keys.length
        ? keys
        : ['0'];
    }

    if (groupKeys.length > 1) {
      // console.log(groupKeys);
    }
    const map = groupKeys.reduce(
      (groupAcc: Record<string, any>, groupKey: string) => {
        const o = { ...order };
        const orders = [...(acc?.[groupKey]?.orders ?? []), o];

        if (groupKeys.length > 1) {
          // console.log(groupKey);
          // console.log('orders', orders);
        }

        const tax = +getNumberPrecision(
          orders.reduce(
            (taxAcc: number, od: OrderDetails) => taxAcc + (+od.tax ?? 0),
            0
          ),
          2
        );
        const shipping = +getNumberPrecision(
          orders.reduce(
            (shippingAcc: number, od: OrderDetails) =>
              shippingAcc + (+od.shipping ?? 0),
            0
          ),
          2
        );
        const payments = +getNumberPrecision(
          orders.reduce((paymentsAcc: number, od: OrderDetails) => {
            if (status.toLowerCase() === 'paid' && orderPayments.length) {
              const payment = orderPayments.find((p) => p.groupId === groupKey);
              if (payment) {
                od.partialPayment = payment.amount;
                return paymentsAcc + (+payment.amount ?? 0);
              }
            }
            return paymentsAcc + (+od.partialPayment ?? 0);
          }, 0),
          2
        );

        const groupOrdersTotal = orders.reduce(
          (totalAcc: number, od: OrderDetails) => {
            if (status.toLowerCase() === 'paid' && orderPayments.length) {
              const payment = orderPayments.find((p) => p.groupId === groupKey);
              if (payment) {
                od.total = payment.amount;
                return totalAcc + (+payment.amount ?? 0);
              }
            }
            return (
              totalAcc +
              (+(od.groupId && !od.datePaid && od.partialPayment
                ? od.partialPayment
                : od.total) ?? 0)
            );
          },
          0
        );

        if (groupKeys.length > 1) {
          // console.log('groupOrdersTotal', groupOrdersTotal);
        }

        const total = +getNumberPrecision(groupOrdersTotal, 2);
        const datePaid =
          orders.find((order) => order.datePaid)?.datePaid ??
          orders[0].dateUpdated;

        return {
          ...groupAcc,
          [groupKey]: {
            datePaid,
            invoiceDate: orders[0].invoiceDate,
            orders,
            shipping,
            tax,
            total,
            payments,
            amountDue: total - payments,
            unPaid: orders.some((order) => order.status !== 'PAID'),
          },
        };
      },
      acc
    );
    return map;
  }, {});
};

export const sortData = (
  orders: Array<Payable>,
  route: ActivatedRoute,
  filterColumns?: Array<string>
) => {
  if (!orders?.length) {
    return of([]);
  }
  return filterOrders(orders, route, filterColumns);
};

export const getColumns = (vendor: any, status: string): Array<string> => {
  return [
    ...(statusColumns[status] || []),
    ...(vendor?.distributor ? ['vendor'] : []),
    ...defaultColumns,
  ].sort((a, b) => (colSortOrder[a] < colSortOrder[b] ? -1 : 1));
};

export const getSort = (status: string) => {
  return sortConfig[status];
};

export const getTableConfig = (vendor: any, status: string) => {
  const orderStatus = orderStatusMap[status];
  return {
    columns: getColumns(vendor, orderStatus),
    ...getSort(orderStatus),
  };
};

export const getAggregates = (orders: Array<Order>): Record<string, number> => {
  return orders.reduce(
    (acc: Record<string, number>, o: Order) => ({
      shipping: acc.shipping + (o.shipping ?? 0),
      tax: acc.tax + (o.tax ?? 0),
      total: acc.total + (o.total ?? 0),
    }),
    {}
  );
};

export const getKeyVal = (obj: any, key: string): any => {
  const val = LodashGet(obj, key);
  if (
    (typeof val === 'string' && !val?.length) ||
    !['string', 'number'].includes(typeof val)
  ) {
    return '';
  }
  return `${val}`.toLowerCase();
};

export const searchLog = (l: any, search: string): boolean => {
  const searchKeys = ['initials', 'notes', 'item'];
  return searchKeys.some((key: string) => getKeyVal(l, key)?.includes(search));
};

export const searchLogs = (logs: Array<any>, search: string): Array<any> => {
  return logs.filter((l: any) => searchLog(l, search));
};

export const searchOrder = (o: Order, search: string): boolean => {
  const searchKeys = ['_id', 'invoiceNumber', 'notes', 'status'];
  return searchKeys.some((key: string) => getKeyVal(o, key)?.includes(search));
};

export const searchOrders = (
  orders: Array<Order>,
  search: string
): Array<Order> => {
  return orders.filter((o: Order) => searchOrder(o, search));
};

export const searchProduct = (o: Product, search: string): boolean => {
  const searchKeys = ['_id', 'model', 'color'];
  return searchKeys.some((key: string) => getKeyVal(o, key)?.includes(search));
};

export const searchProducts = (
  products: Array<ProductElement>,
  search: string
): boolean => {
  return products.some((p: ProductElement) => searchProduct(p.product, search));
};

export const searchVendor = (v: Vendor | string, search: string): boolean => {
  if (typeof v === 'string') {
    return `${v}`?.toLowerCase()?.includes(search);
  }
  const searchKeys = ['_id', 'name', 'returnNotes'];
  return searchKeys.some((key: string) => getKeyVal(v, key)?.includes(search));
};

export const searchVendor2 = (v: Vendor, search: string): boolean => {
  if (!v) {
    return false;
  }
  const searchKeys = ['_id', 'accountStatus', 'name', 'returnNotes'];
  return searchKeys.some((key: string) => getKeyVal(v, key)?.includes(search));
};

export const searchPayable = (
  payable: Payable,
  search: string,
  filterColumns?: Array<string>
): boolean => {
  return (filterColumns ?? Object.keys(payable)).some((key: string) =>
    getKeyVal(payable, key)?.includes(search)
  );
};

export const searchVendorOrder = (
  vo: VendorOrder,
  search: string
): VendorOrder | undefined => {
  const searchKeys = [
    '_id',
    'invoiceNumber',
    'trackingNumber',
    'tax',
    'total',
    'shipping',
    'status',
  ];
  const orderMatch = searchKeys.some((key: string) =>
    getKeyVal(vo, key)?.includes(search)
  );
  const orderProductsMatch = searchProducts(vo.products, search);
  const orderVendorMatch =
    searchVendor(vo.vendor, search) ||
    searchVendor(vo.vendor?.[0], search) ||
    searchVendor(vo.vendor2, search);
  const orderVendor2Match = searchVendor2(vo.vendor2, search);
  const match =
    orderMatch || orderProductsMatch || orderVendorMatch || orderVendor2Match;
  /* If the search string matches the vendorOrder, vendor, vendor2 or products return all vendor order properties */
  if (match) {
    return vo;
  }
  /* If the search string matches some orders return vendorOrder with those orders with updated totals */
  const ordersMatched = searchOrders(vo.orders, search);
  const { shipping, tax, total } = getAggregates(vo.orders);
  if (ordersMatched?.length) {
    return {
      ...vo,
      orders: ordersMatched,
      shipping,
      tax,
      total,
    };
  }
  return undefined;
};
