import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LogElement } from '@models/order';
import { Store } from '@models/store';
import { ProductElement } from '@models/product';
import { Vendor, VendorInventory } from '@models/vendor';
import { VendorOrder } from '@models/vendor-order';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { AuthService } from '@services/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { UserService } from '@services/user.service';
import { User } from '@models/user';
import { RentService } from '@services/rent.service';
import * as _ from 'lodash';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-vendor-base',
  templateUrl: './vendor-base.component.html',
  styleUrls: ['./vendor-base.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class VendorBaseComponent implements OnInit {
  @Input() public vendor: Vendor = null;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @Input() public borrowingView = false;
  @Input() public borrowingStore: string = null;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['vendor', 'count', 'borrowing', 'renting'];
	expandedElement = null;
  validateEmail = Vendor.validateEmail;
  validateUrl = Vendor.validateUrl;
  productCount = [];
  storeOptions: any[];
  borrowingSelection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(true, []);
  loadedVendors: any[] = [];
  borrowingFormGroup: FormGroup;
  rentalUsers: User[];
  rentingView = false;
  rentingUser: User;
  rentingList = [];
  oldRentingList = [];
  currentUser;
  downloading = false;
  allRentingList = [];
  updatedRentingList = [];

  constructor(
    public auth: AuthService,
    public vendorService: VendorService,
    public vendorOrderService: VendorOrderService,
    public productService: ProductService,
    public storeService: StoreService,
    public cdRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public userService: UserService,
    public rentService: RentService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.currentUser = this.auth.getUser();
    if (this.currentUser.role === 'RENTAL') {
      this.displayedColumns = ['vendor', 'count', 'renting'];
      this.rentService.fetchRents(this.currentUser._id).subscribe(res => {
        const rents = res.map(rent => {
          if (!rent.qty) {
            rent.qty = 1;
          }
          return rent;
        });
        this.rentingList = _.cloneDeep(rents);
        this.oldRentingList = _.cloneDeep(rents);
      });
    } else {
      this.userService.getRentalUsers(parseInt(this.auth.getStore(), 0)).subscribe(users => {
        this.rentalUsers = users;
      });
      this.rentService.fetchRents().subscribe(res => {
        const rents = res.map(rent => {
          if (!rent.qty) {
            rent.qty = 1;
          }
          return rent;
        });
        this.allRentingList = rents;
      });
    }
  	this.getVendorInventory();
    this.setBorrowingFormGroup();
    this.storeOptions = this.storeService.stores;
  }

  setDefaultBorrowingStore() {
    const store = this.auth.getStore();
    if (this.storeOptions.length === 2) {
      for (let x = 0; x < this.storeOptions.length; x++) {
        if (this.storeOptions[x]._id !== store) {
          this.borrowingStore = this.storeOptions[x]._id;
          this.cdRef.detectChanges();
          break;
        }
      }
    }
  }

  getVendorInventory() {
    this.vendorService.getVendorProductQuantities().subscribe((vendorInventory: any[]) => {
      this.route.paramMap.subscribe(params => {
        const vendorId = params.get('vendorId');
        this.expandedElement = vendorInventory.find(x => x._id === vendorId);
      });

      // for (let x = 0; x < vendorInventory.length; x++) {
      //   this.productCount[x] = 0;
      //   for (let y = 0; y < vendorInventory[x].products.length; y++) {
      //     if (vendorInventory[x].products[y].quantity > 0) {
      //       this.productCount[x] = this.productCount[x] + vendorInventory[x].products[y].quantity;
      //     }
      //   }
      // }

      this.dataSource = new MatTableDataSource<any>(vendorInventory);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'vendor':
            // if (item.vendor) {
            //   let vendor: Object = item.vendor;
            //   return vendor['name'];
            // } else {
            //   return item[property];
            // }
          // case 'vendor.name': return item.vendor.name;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;

      this.cdRef.detectChanges();





    });
	}

  setBorrowingFormGroup() {
    this.borrowingFormGroup = this.fb.group({
      initials: ''
    });
  }

  borrowProduct($event, editProduct: FormGroup, index) {
    console.log(editProduct);

    const logItem = new LogElement();
    logItem.initials = this.auth.currentUser.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'BORROW FRAME FOR STORE ' + this.borrowingStore;
    logItem.notes = '';
    logItem.date = Date.now().toString();

    const quantity = 1;
    this.productService.borrowProduct(editProduct.value._id, this.borrowingStore, quantity, this.auth.getStore(), logItem).subscribe((product) => {
      console.log(product);
    });
    $event.preventDefault();
    $event.stopPropagation();
  }


  borrowSelectedProducts($event) {
    console.log(this.borrowingSelection);

    const logItemProduct = new LogElement();
    logItemProduct.initials = this.auth.currentUser.initials;
    logItemProduct.user = this.auth.currentUser._id;
    logItemProduct.item = 'BORROW FRAME FOR STORE ' + this.borrowingStore;
    logItemProduct.notes = '';
    logItemProduct.date = Date.now().toString();

    const vendorOrders: Object = {};
    const logItem = new LogElement();
    logItem.initials = this.borrowingFormGroup.value.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'BORROWED FRAMES';
    logItem.notes = '';
    logItem.date = Date.now().toString();

    const borrowingSelectionCount = this.borrowingSelection.selected.length;
    for (let x = 0; x < this.borrowingSelection.selected.length; x++) {
      const productForm = this.borrowingSelection.selected[x];
      const product = productForm.getRawValue();
      // product.vendor = product.vendor._id;
      product.store = product.store._id;
      const productElement: ProductElement = new ProductElement();

      this.vendorService.checkVendorForBorrowing(product.vendor._id, this.borrowingStore).subscribe((res) => {
        console.log(res);
        if (!res.vendor) {
          const dialogRef = this.dialog.open(OkCancelComponent, {
            width: '250px',
            data: { message: 'VENDOR DOES NOT EXIST AT ' + this.getStoreObjectFromId(this.borrowingStore).storeName + '. DO YOU WANT TO CREATE IT?' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.vendorService.addBorrowingVendor(product.vendor._id, this.borrowingStore).subscribe((res) => {
                console.log(res);
              });
            }
          });
        }
      });

      const quantity = 1;
      this.productService.borrowProduct(product._id, this.borrowingStore, quantity, this.auth.getStore(), logItemProduct).subscribe(prod => {
        const borrowingList = _(prod.borrowing).groupBy('store').map((objs, key) => ({
          store: key,
          quantity: _.sumBy(objs, 'quantity')
        })).value();
        productForm.get('borrowing').setValue(borrowingList);
        this.cdRef.detectChanges();
      });

      if (!vendorOrders[product.vendor]) {
        vendorOrders[product.vendor] = new VendorOrder();
        vendorOrders[product.vendor].date = Date.now().toString();
        vendorOrders[product.vendor].type = 'borrow';
        vendorOrders[product.vendor].vendor = product.vendor;
        vendorOrders[product.vendor].user = this.auth.currentUser._id;
        vendorOrders[product.vendor].store = this.auth.getStore();
        vendorOrders[product.vendor].borrowingStore = this.borrowingStore;
        vendorOrders[product.vendor].log.unshift(logItem);
      }
      productElement.product = product;
      vendorOrders[product.vendor].products.unshift(productElement);
    }
    console.log(vendorOrders);
    Object.keys(vendorOrders).forEach((key) => {
      this.vendorOrderService.addVendorOrder(vendorOrders[key]).subscribe(vendorOrder => {
        this.borrowingSelection.clear();
        const dialogRef = this.dialog.open(SaveComponent, {
            width: '250px',
            data: { message: borrowingSelectionCount + ' PRODUCTS SUCCESSFULLY BORROWED BY ' + this.getStoreObjectFromId(this.borrowingStore).storeName + '.' }
          });
          dialogRef.afterClosed().subscribe(result => {

          });
      });
    });

  }

  getStoreObjectFromId(storeId: any): Store {
    for (let x = 0; x < this.storeOptions.length; x++) {
      if (this.storeOptions[x]._id === storeId) {
        return this.storeOptions[x];
      }
    }
  }

	toggleExpanded($event, element: VendorInventory, index) {
    if (this.expandedElement !== element) {
      this.expandedElement = element;
      this.loadedVendors.push(element.vendor?._id);
    } else {
      this.expandedElement = null;
    }
  }

  refreshInventory() {
  	this.expandedElement = null;
		this.getVendorInventory();
  }

  selectRenting({ checked, productId, vendorId }) {
    const idx = this.rentingList.findIndex(renting => renting.product._id === productId);
    if (checked && idx === -1) {
      this.rentingList.push({
        product: {
          _id: productId,
          vendor: vendorId
        },
        qty: 1,
        user: this.rentingUser._id
      });
      this.updatedRentingList.push({
        _id: productId,
        vendor: vendorId,
        checked: true
      });
    } else if (checked && idx > -1) {
      this.rentingList[idx].qty = this.rentingList[idx].qty + 1;
      this.rentingList = _.cloneDeep(this.rentingList);
      this.updatedRentingList.push({
        _id: productId,
        vendor: vendorId,
        checked: true
      });
    } else if (!checked && idx > -1) {
      if (this.rentingList[idx].qty === 1) {
        this.rentingList.splice(idx, 1);
      } else {
        this.rentingList[idx].qty = this.rentingList[idx].qty - 1;
        this.rentingList = _.cloneDeep(this.rentingList);
      }
      this.updatedRentingList.push({
        _id: productId,
        vendor: vendorId,
        checked: false
      });
    }
  }

  rentingListChanged(): boolean {
    return !_.isEqual(this.rentingList, this.oldRentingList);
  }

  rentSelected() {
    this.rentService.saveRents({
      products: this.rentingList.map(renting => renting.product._id),
      user_id: this.rentingUser._id,
      quantities: this.rentingList.map(rent => rent.qty)
    }).subscribe(() => {
      this.rentService.fetchRents().subscribe(res => {
        const rents = res.map(rent => {
          if (!rent.qty) {
            rent.qty = 1;
          }
          return rent;
        });
        this.allRentingList = rents;
        this.selectRentingUser();
      });
      this.updatedRentingList = [];

      this.notificationService.show('success', 'Selected products successfully rented');
    });
  }

  selectRentingUser() {
    if (this.rentingUser) {
      this.rentingList = _.cloneDeep(this.allRentingList.filter(rent => rent.user === this.rentingUser._id));
      this.oldRentingList = _.cloneDeep(this.rentingList);
    } else {
      this.rentingList = [];
      this.oldRentingList = [];
    }
    this.updatedRentingList = [];
  }

  getRentingByVendor(vendorId) {
    return this.oldRentingList.filter(renting => renting.product.vendor === vendorId);
  }

  groupRentingByVendor(vendorId) {
    return this.allRentingList.filter(renting => renting.product.vendor === vendorId).reduce((res, renting) => {
      const rentingUser = this.rentalUsers.find(user => user._id === renting.user);
      if (!res[rentingUser.name]) {
        res[rentingUser.name] = [];
      }

      res[rentingUser.name].push(renting);

      return res;
    }, {});
  }

  downloadFrames() {
    this.downloading = true;
    this.rentService.downloadFrames().subscribe((res: string) => {
      this.downloading = false;
      const byteArray = Uint8Array.from(atob(res).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'frames.zip';
      link.click();
    }, () => {
      this.downloading = false;
      this.notificationService.show('error', 'Something went wrong while downloading frames.');
    });
  }

  getSum(rents) {
    let sum = 0;
    for (const rent of rents) {
      sum += rent.qty;
    }
    return sum;
  }

  getVendorQtys(vendorId: string) {
    return this.allRentingList.filter(renting => renting.product.vendor === vendorId).reduce((res, renting) => {
      if (!res[renting.product._id]) {
        res[renting.product._id] = 0;
      }

      res[renting.product._id] += renting.qty;
      return res;
    }, {});
  }

  getUpdatedRentingList(vendorId: string) {
    return this.updatedRentingList.filter(renting => renting.vendor === vendorId);
  }
}
