export class FavoriteLine {
	_id: string;
	name: string;
	store: string;

  constructor()
  {
    this._id = '';
    this.name = '';
    this.store = '';
  }

}