<div class="d-flex align-items-center justify-center invoice">
    <div *ngIf="creditForm" class="d-flex flex-column form">
        <div class="d-flex justify- align-middle" [formGroup]="creditForm">
            <div class="formGroup">
                <mat-form-field>
                    <mat-label>Select Vendor</mat-label>
                    <mat-select formControlName="vendor">
                        <mat-option *ngFor="let v of vendors" [value]="v._id">
                            {{ v.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex justify-between">
            <div class="formGroup">
                <span *ngIf="vendor?.repName">
                    {{ vendor?.repName }}
                </span>
                <span *ngIf="vendor?.phone">
                    {{ vendor?.phone }}
                </span>
                <span *ngIf="vendor?.website">
                    {{ vendor?.website }}
                </span>
            </div>
            <div *ngIf="currentStore" class=" formGroup d-flex flex-column">
                <label>Store</label>
                <p>{{ currentStore.storeName }}</p>
            </div>
        </div>
        <div class="d-flex justify-between" [formGroup]="creditForm">
            <div class="formGroup">
                <mat-form-field>
                    <mat-label>Invoice #</mat-label>
                    <input matInput formControlName="invoiceNumber" type="text" />
                </mat-form-field>
            </div>
            <div class="formGroup">
                <div class="d-flex justify-right">
                    <mat-form-field>
                        <input [matDatepicker]="picker" matInput placeholder="Invoice Date" type="text" formControlName="date" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="formGroup sm">
                <mat-form-field>
                    <mat-label>Shipping</mat-label>
                    <input matInput formControlName="shipping" type="number" />
                    <span matSuffix>
                        <mat-icon style="color: {{storeColor}}">attach_money</mat-icon>
                    </span>
                </mat-form-field>
            </div>
            <div class="formGroup sm">
                <mat-form-field>
                    <mat-label>Tax</mat-label>
                    <input matInput formControlName="tax" type="number" />
                    <span matSuffix>
                        <mat-icon style="color: {{storeColor}}">attach_money</mat-icon>
                    </span>
                </mat-form-field>
            </div>
            <div class="formGroup sm">
                <mat-form-field>
                    <mat-label>Total</mat-label>
                    <input matInput formControlName="total" type="number" />
                    <span matSuffix>
                        <mat-icon style="color: {{storeColor}}">attach_money</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="logForm" class="d-flex justify-start">
            <div class="formGroup xl" [formGroup]="logForm">
                <mat-form-field appearance="fill">
                    <mat-label>Notes</mat-label>
                    <textarea matInput formControlName="notes"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="logForm" class="d-flex justify-right align-middle">
            <div class="formGroup xsm" [formGroup]="logForm">
                <mat-form-field>
                    <mat-label>Initials</mat-label>
                    <input matInput formControlName="initials" type="text" />
                </mat-form-field>
            </div>
            <div class="formGroup">
                <button mat-button (click)="updateVendorOrder()" [disabled]="creditForm.invalid || !creditForm.dirty">
                    <mat-icon>save</mat-icon> <span>SAVE</span>
                </button>
            </div>
            <div class="formGroup">
                <button mat-button [disabled]="creditForm.invalid">
                    <mat-icon>edit</mat-icon> <span>EDIT</span>
                </button>
            </div>
        </div>
    </div>
</div>
