import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AuthService } from '@services/auth.service';
import { User } from '@models/user';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { PriceCheckComponent } from '@components/admin/inventory/price-check/price-check.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  user: User;
  message: String;

  constructor(
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
  }

  openPriceCheck(): void {
    this._bottomSheet.open(PriceCheckComponent);
  }
}
