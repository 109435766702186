<mat-form-field style="width:300px;" floatLabel="never">
  <input type="text" [placeholder]="'LAST, FIRST'" matInput [formControl]="patientCtrl" [matAutocomplete]="auto" (keyup.enter)="chooseFirstOption()" (click)="clickField($event)">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPatientName">
    <mat-option *ngFor="let patient of filteredPatients | async" (onSelectionChange)="selectPatient(patient)">
      {{ patient.lastName }}, {{ patient.firstName }} <span class="dob" *ngIf="patient.dob!=''">({{ patient.dob }})</span> <span class="sleep" *ngIf="patient.sleep"> SLEEP</span>
    </mat-option>
    <mat-option *ngIf="mode!='importCustomerImages' && mode!='exams'" (onSelectionChange)="gotoNewPatient()">
      +NEW CUSTOMER
    </mat-option>
  </mat-autocomplete>
  <mat-label>{{placeholder}}</mat-label>
</mat-form-field>