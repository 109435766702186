import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FavoriteLine } from '@models/favorite-line';
import { FavoriteLineService } from '@services/favorite-line.service';

// import * as countArrayValues from 'count-array-values';
const countArrayValues = require('count-array-values');

@Component({
  selector: 'app-form-favorites',
  templateUrl: './form-favorites.component.html',
  styleUrls: ['./form-favorites.component.css']
})
export class FormFavoritesComponent implements OnInit {
	@Input() public parentForm: FormGroup;
	@Input() public favorites: string[];
  
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  favoriteCtrl = new FormControl();
  filteredFavorites: Observable<string[]>;
  allFavorites: string[] = [];
  favoriteCounts: Object[];

  @ViewChild('favoriteInput', {static: false}) favoriteInput: ElementRef;

  constructor(private favoriteLineService: FavoriteLineService) {
		this.favoriteLineService.getAllFavoriteLines().subscribe((res)=>{
			for (let x = 0; x < res.length; x++) {
				this.allFavorites.push(res[x]['name']);
			}
			this.filteredFavorites = this.favoriteCtrl.valueChanges.pipe(
        startWith(null),
        map((favorite: string | null) => favorite ? this._filter(favorite) : this.allFavorites.slice()));
		})
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.favorites.push(value.toUpperCase().trim());
      this.parentForm.controls.favoriteLines.setValue(this.favorites);
    }
		this.favoriteCounts = countArrayValues(this.favorites);

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.favoriteCtrl.setValue(null);
  }

  remove(favorite: any): void {
    const index = this.favorites.indexOf(favorite.value);

    if (index >= 0) {
      this.favorites.splice(index, 1);
    }
    this.parentForm.value.favoriteLines = this.favorites;
		this.favoriteCounts = countArrayValues(this.favorites);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.favorites.push(event.option.viewValue);
    this.parentForm.controls.favoriteLines.setValue(this.favorites);
    this.favoriteCounts = countArrayValues(this.favorites);
    this.favoriteInput.nativeElement.value = '';
    this.favoriteCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allFavorites.filter(favorite => favorite.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
		if (this.favorites) {
			this.favoriteCounts = countArrayValues(this.favorites);
		} else {
			this.parentForm.value.favoriteLines = [];
			this.favorites = [];
		}
  }
}
