import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { VendorBaseComponent } from '@components/admin/inventory/vendor-base/vendor-base.component';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@services/user.service';
import { RentService } from '@services/rent.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class InventoryComponent extends VendorBaseComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public vendorService: VendorService,
    public productService: ProductService,
    public vendorOrderService: VendorOrderService,
    public storeService: StoreService,
    public cdRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public userService: UserService,
    public rentService: RentService,
    public notificationService: NotificationService
  ) {
    super(
      auth,
      vendorService,
      vendorOrderService,
      productService,
      storeService,
      cdRef,
      route,
      fb,
      dialog,
      userService,
      rentService,
      notificationService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
