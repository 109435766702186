import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store } from '@models/store';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { AuthService } from '@services/auth.service';
import { PrintDetails, PrintService } from '@services/print.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { concatMap, EMPTY, map, of, Subject, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss'],
})
export class PrintInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('printArea') printArea: ElementRef;
  unsubscribe$ = new Subject<void>();
  vendor: Vendor;
  orders: VendorOrder[] = [];
  total: number = 0;
  groupId: string;
  store: Store;

  constructor(
    private readonly as: AuthService,
    private readonly pS: PrintService,
    private readonly voS: VendorOrderService,
    private readonly cd: ChangeDetectorRef
  ) {
    // Clear variables after print/cancel
    window.onafterprint = () => this.destroy();
  }

  ngOnInit(): void {
    // Subscribe to invoiceIds changes from the print service
    this.pS
      .onPrintInvoices()
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap((detail: PrintDetails) => {
          const { invoiceIds, groupId, vendor } = detail;
          this.groupId = groupId;
          return this.getVendorOrders(invoiceIds, vendor);
        })
      )
      .subscribe((printDetails) => {
        const { orders, vendor } = printDetails ?? {};
        this.vendor = vendor;
        this.orders = orders;
        this.cd.detectChanges();
        timer(800).subscribe(() => window.print());
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  destroy() {
    this.vendor = undefined;
    this.orders = [];
    this.total = 0;
  }

  getUrlParams() {
    return of(window.location.search.split('=')[1].split(','));
  }

  getVendorOrders(invoiceIds: string[] = [], printVendor?: Vendor) {
    this.store = this.as.getStoreObject();
    console.log(this.store);
    if (invoiceIds?.length) {
      this.total = 0;
      return this.voS.getVendorOrders('all', invoiceIds).pipe(
        takeUntil(this.unsubscribe$),
        map((res) => {
          const orders: Array<VendorOrder> = res.vendorOrders.reduce(
            (acc: Array<VendorOrder>, order: VendorOrders) => {
              order.vendorOrders
                .map((order: any) => {
                  const orderPayments = order.payments
                    ?.filter((p) => p.groupId === this.groupId)
                    .reduce((acc: number, p: any) => acc + p.amount, 0);
                  const partialPayments = order.payments
                    ?.filter((p) => p.groupId !== this.groupId)
                    .reduce((acc: number, p: any) => acc + p.amount, 0);
                  if (partialPayments) {
                    order.partialPayment = partialPayments;
                    order.total -= partialPayments;
                  } else if (orderPayments) {
                    order.total = orderPayments;
                  }
                  order['vendorName'] =
                    order?.vendor2?.name ||
                    order?.['borrowingStore']?.['storeName'] ||
                    order?.vendor?.name;
                  return order;
                })
                .forEach(
                  (order) =>
                    (this.total +=
                      order.groupId && !order.datePaid && order.partialPayment
                        ? order.partialPayment
                        : order.total)
                );
              return [...acc, ...order.vendorOrders];
            },
            []
          );
          const vendor =
            printVendor ??
            (res.vendorOrders?.[0]?.vendor2 ||
              res.vendorOrders?.[0]?.['borrowingStore']?.['storeName'] ||
              res.vendorOrders?.[0]?.vendor);
          const returnAddress =
            (vendor?.returnAddress || vendor?.address)
              ?.split('\n')
              .map((line) => `<span>${line.trim()}</span>`)
              .join('') ?? '';
          return { orders, vendor: { ...vendor, returnAddress } };
        })
      );
    }
    return EMPTY;
  }
}
