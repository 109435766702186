<div *ngIf="dataLoading">
	  <h3 style="text-align:center;" *ngIf="section=='inquiries'">LOADING INQUIRIES</h3>
	  <h3 style="text-align:center;" *ngIf="section=='orders'">LOADING ORDERS</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="container" style="width:100%" *ngIf="!dataLoading">
  <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">
    <ng-container matColumnDef="alert">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px">
        <mat-icon color="warn" *ngIf="order.alert">notification_important</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span style="font-weight:bold;"> {{ order.vendor.name }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order.orders.length != 1"> {{ order.orders.length }} ITEMS</span>
        <span *ngIf="order.orders.length == 1"> {{ order.orders.length }} ITEM</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="accountNumber">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> ACCOUNT # </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement && order.orders.length > 0"> {{ order.vendor.accountNumber }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="repName">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> REP NAME </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement && order.orders.length > 0"> {{ order.vendor.repName }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderProtocol">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> ORDER PROTOCOL </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement && order.orders.length > 0"> {{ order.vendor.orderProtocol }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderInfo">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 300px"> ORDER INFO </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 300px">
        <span *ngIf="order==expandedElement && order.orders.length > 0 && order.vendor.orderProtocol=='Online' && validateUrl('http://'+order.vendor.website)">&nbsp;
          <a [href]="'http://'+order.vendor.website" target="_blank">
            <mat-icon>open_in_browser_outline</mat-icon><span style="position:relative;top: -5px;">{{ order.vendor.website }}</span>
          </a>
        </span>
        <span *ngIf="order==expandedElement && order.vendor.orderProtocol=='Call'">&nbsp;
          <mat-icon>phone</mat-icon><span style="position:relative;top: -5px;">{{ order.vendor.repPhone }} <span *ngIf="order.vendor.repPhoneExt">x{{ order.vendor.repPhoneExt }}</span></span>
        </span>
        <span *ngIf="order==expandedElement && order.vendor.orderProtocol=='Email' && validateEmail(order.vendor.repEmail)">&nbsp;
          <a [href]="'mailto:'+order.vendor.repEmail">
            <mat-icon>email_outline</mat-icon><span style="position:relative;top: -5px;">{{ order.vendor.repEmail }}</span>
          </a>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;">
        <div 
          class="example-element-detail" 
          style="margin-left:20px;width:100%;" 
          *ngIf="order==expandedElement && order.orders.length > 0" 
          [class.mat-elevation-z0]="order!=expandedElement || order.orders.length < 1" 
          [class.mat-elevation-z8]="order==expandedElement && order.orders.length > 0" 
          [@detailExpand]="order == expandedElement && order.orders.length > 0 ? 'expanded' : 'collapsed'">

          <app-stack-inquiries *ngIf="section=='inquiries'" [vendorOrders]="order" [orderIndex]="orderIndex" style="width:100%;"></app-stack-inquiries>
          <app-stack-orders *ngIf="section=='orders'" [vendorOrders]="order" [orderIndex]="orderIndex" style="width:100%;"></app-stack-orders>





        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let order; columns: displayedColumns; let r=dataIndex"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === order && order.orders.length > 0"
      (click)="toggleExpanded(order, r)"
      appHighlight>
    </mat-row>
    <mat-row *matRowDef="let order; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === order && order.orders.length > 0"
      [class.example-detail-row-collapsed]="expandedElement !== order || order.orders.length < 1"
      style="overflow: hidden">
    </mat-row>
  </mat-table>
</div>


