<mat-card>
  <mat-toolbar [style.background-color]="currentStore?.headerColor">
    <span>EYE EXAMS</span>
  </mat-toolbar>
  <mat-card-content>


		<div *ngIf="loading">
			<h3 style="text-align:center;">LOADING EYE EXAMS</h3>
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
		<div class="container" style="width:100%" *ngIf="!loading">

			<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
			  <div>
			  	<mat-form-field>
					  <mat-label>DATE</mat-label>
					  <mat-select value="today" (selectionChange)="selectDateOption($event)">
					    <mat-option *ngFor="let dateOption of dateOptions" [value]="dateOption.value">
					      {{ dateOption.viewValue }}
					    </mat-option>
					  </mat-select>
					</mat-form-field>
			  </div>
			  <div>
			  	<mat-form-field *ngIf="selectedDateOption=='customDateRange'">
					  <mat-label>DATE RANGE</mat-label>
					  <mat-date-range-input [rangePicker]="picker">
					    <input matStartDate placeholder="Start date" (dateChange)="selectCustomDateRangeStart($event)">
					    <input matEndDate placeholder="End date" (dateChange)="selectCustomDateRangeEnd($event)">
					  </mat-date-range-input>
					  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					  <mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
			  </div>
			  <div>
					<button mat-button type="button" (click)="refresh()" [disabled]="refreshing"><mat-icon>refresh</mat-icon></button>
			  </div>
			  <div>
					<mat-progress-spinner *ngIf="refreshing" mode="indeterminate" diameter="30"></mat-progress-spinner>
			  </div>
			  <div class="marginLeftAuto">
					<button mat-button type="button" (click)="open2020VisionPC()">
		  			<img src="/assets/2020-vision-logo.svg" alt="2020 VISION PC" style="width:110px;margin-right:10px" />
		  			<mat-icon>launch</mat-icon>
					</button>
			  </div>
			</div>


		  <mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">

		    <ng-container matColumnDef="patient">
		      <mat-header-cell *matHeaderCellDef fxFlex="1 1 300px"> PATIENT </mat-header-cell>
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;" fxFlex="1 1 300px" style="justify-content:left !important;padding-left: 20px !important;">
		        {{ exam.lastName }}, {{ exam.firstName }}
		      </mat-cell>
		      <mat-footer-cell *matFooterCellDef fxFlex="1 1 300px">
		      	<div [ngPlural]="examCount">
						  <ng-template ngPluralCase="=0">NO EXAMS</ng-template>
						  <ng-template ngPluralCase="=1">1 EXAM</ng-template>
						  <ng-template ngPluralCase="other">{{ examCount }} EXAMS</ng-template>
						</div>
		      </mat-footer-cell>
		    </ng-container>
		    

		    <ng-container matColumnDef="encounter">
		      <mat-header-cell *matHeaderCellDef fxFlex="1 1 150px"> ENCOUNTER </mat-header-cell>
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;" fxFlex="1 1 150px">
		        <span> {{ exam.internalEncounterId }} </span>
		      </mat-cell>
		    </ng-container>

				<ng-container matColumnDef="linkedPatient">
		      <mat-header-cell *matHeaderCellDef fxFlex style="justify-content:start !important;padding-left:10px;"> LINKED PATIENT </mat-header-cell>
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;" fxFlex style="justify-content:start !important;">
	      		<!-- <button mat-button *ngIf="exam.patient" [routerLink]="['/admin/patient', exam.patient._id]">{{exam.patient?.lastName}}, {{exam.patient?.firstName}}</button> -->

	      		<button mat-button *ngIf="exam.patient" [routerLink]="['/admin/patient', exam.patient._id]">{{exam.patient?.store?.abbreviation}}{{exam.patient?.patientNumber}}</button>
		      </mat-cell>
		    </ng-container>

		    <ng-container matColumnDef="date">
		      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> DATE </mat-header-cell>
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;" fxFlex="1 1 200px" style="justify-content:left !important;padding-left: 20px !important;">
		        {{ exam.datetime | date:'short':'GMT' }}
		      </mat-cell>
		    </ng-container>

		    <ng-container matColumnDef="suggestedPatient">
		      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> SUGGESTED PATIENT </mat-header-cell>
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;" fxFlex="1 1 200px">
	      		<button mat-button *ngIf="exam.suggestedPatient" [routerLink]="['/admin/patient', exam.suggestedPatient._id]">{{exam.suggestedPatient?.lastName}}, {{exam.suggestedPatient?.firstName}}</button>
		      </mat-cell>
		    </ng-container>

		    

		    <ng-container matColumnDef="action">
		      <mat-header-cell *matHeaderCellDef fxFlex="1 1 100%">  </mat-header-cell>
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;" fxFlex="1 1 100%" style="padding-left:16px;justify-content: flex-start !important;">

		      </mat-cell>
		    </ng-container>

		    <ng-container matColumnDef="expandedDetail">
		      <mat-cell *matCellDef="let exam; let orderIndex=dataIndex;">
		        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="exam!=expandedElement" [class.mat-elevation-z8]="exam==expandedElement" [@detailExpand]="exam == expandedElement ? 'expanded' : 'collapsed'">
<!-- 
		        	<mat-grid-list cols="3">
							  <mat-grid-tile>NAME:</mat-grid-tile>
							  <mat-grid-tile>{{ exam?.examPatient?.lastName }}, {{ exam?.examPatient?.firstName }}</mat-grid-tile>
							  <mat-grid-tile>{{ exam?.suggestedPatient?.lastName }}, {{ exam?.suggestedPatient?.firstName }}</mat-grid-tile>

							  <mat-grid-tile>DOB:</mat-grid-tile>
							  <mat-grid-tile>{{ exam?.examPatient?.dob | date }}</mat-grid-tile>
							  <mat-grid-tile>{{ exam?.suggestedPatient?.dob | date }}</mat-grid-tile>

							  <mat-grid-tile>ADDRESS:</mat-grid-tile>
							  <mat-grid-tile>
							  	{{ exam?.examPatient?.address }}<br>
							    {{ exam?.examPatient?.address2 }}<br>
							    {{ exam?.examPatient?.city }}, {{ exam?.examPatient?.state }}
							  </mat-grid-tile>
							  <mat-grid-tile>
							  	{{ exam?.suggestedPatient?.address }}<br>
							    {{ exam?.suggestedPatient?.address2 }}<br>
							    {{ exam?.suggestedPatient?.city }}, {{ exam?.suggestedPatient?.state }}
							  </mat-grid-tile>

							  <mat-grid-tile>PHONER:</mat-grid-tile>
							  <mat-grid-tile>{{ exam?.examPatient?.phone | phone }}</mat-grid-tile>
							  <mat-grid-tile>{{ exam?.suggestedPatient?.phone | phone }}</mat-grid-tile>
							</mat-grid-list>
 -->
		        	<!-- {{exam|json}} -->



		        	<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" style="padding:0 20px 20px 20px;">
							  <div *ngIf="!exam.patient" fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px">
								  </div>
								  <div fxFlex style="font-weight:bold;">
								  	<app-autocomplete-patient placeholder="SEARCH PATIENTS" mode="exams" (selectedPatient)="selectPatient($event,exam)" [stopPropagation]="exam==expandedElement"></app-autocomplete-patient>
								  </div>
								<!--   <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.suggestedPatient">
		        				
								  </div>
								  <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.selectedPatient">
								  </div>
								  <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.patient">
								  </div> -->
							  </div>
			        	<div fxFlex style="margin-top:-20px !important;">
				        	<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" style="margin-top:-20px !important;">
									  <div fxFlex="0 0 100px">
									  </div>
									  <div fxFlex="0 0 250px" style="font-weight:bold;">
									    <strong>EXAM PATIENT</strong>
									  </div>
									  <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.suggestedPatient">
									    <strong>SUGGESTED PATIENT</strong>
									  </div>
									  <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.selectedPatient">
									    <strong>SELECTED PATIENT</strong>
									  </div>
									  <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.patient">
									    <strong>LINKED PATIENT</strong>
									  </div>
								  </div>
							  </div>
							  <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>NAME:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    {{ exam.lastName }}, {{ exam.firstName }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    {{ exam.suggestedPatient?.lastName }}, {{ exam.suggestedPatient?.firstName }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    {{ exam.selectedPatient?.lastName }}, {{ exam.selectedPatient?.firstName }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    {{ exam.patient?.lastName }}, {{ exam.patient?.firstName }}
								  </div>
							  </div>
							  <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
							  	<div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>ID:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    {{ exam.suggestedPatient?.patientNumber }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    {{ exam.selectedPatient?.patientNumber }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    {{ exam.patient?.patientNumber }}
								  </div>
							  </div>
			        	<div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>DOB:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    {{ exam.dob | date:'shortDate' }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    {{ exam.suggestedPatient?.dob | date:'shortDate' }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    {{ exam.selectedPatient?.dob | date:'shortDate' }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    {{ exam.patient?.dob | date:'shortDate' }}
								  </div>
							  </div>
			        	<div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>ADDRESS:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    <div>{{ exam.address }}</div>
								    {{ exam.city }}<ng-container *ngIf="exam.city">,</ng-container> {{ exam.state }} {{ exam?.zip }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    <div>{{ exam.suggestedPatient?.address }}</div>
								    <div>{{ exam.suggestedPatient?.address2 }}</div>
								    <div>{{ exam.suggestedPatient?.city }}<ng-container *ngIf="exam.suggestedPatient?.city">,</ng-container> {{ exam.suggestedPatient?.state }} {{ exam.suggestedPatient?.zip }}</div>
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    <div>{{ exam.selectedPatient?.address }}</div>
								    <div>{{ exam.selectedPatient?.address2 }}</div>
								    <div>{{ exam.selectedPatient?.city }}<ng-container *ngIf="exam.selectedPatient?.city">,</ng-container> {{ exam.selectedPatient?.state }} {{ exam.selectedPatient?.zip }}</div>
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    <div>{{ exam.patient?.address }}</div>
								    <div>{{ exam.patient?.address2 }}</div>
								    <div>{{ exam.patient?.city }}<ng-container *ngIf="exam.patient?.city">,</ng-container> {{ exam.patient?.state }} {{ exam.patient?.zip }}</div>
								  </div>
							  </div>
							  <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>PHONE:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    {{ exam.phone | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    {{ exam.suggestedPatient?.phone | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    {{ exam.selectedPatient?.phone | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    {{ exam.patient?.phone | phone }}
								  </div>
							  </div>
							  <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>WORK:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    {{ exam.phone2 | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    {{ exam.suggestedPatient?.phone2 | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    {{ exam.selectedPatient?.phone2 | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    {{ exam.patient?.phone2 | phone }}
								  </div>
							  </div>
							  <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px" style="font-weight:bold;">
								    <strong>MOBILE:</strong>
								  </div>
								  <div fxFlex="0 0 250px">
								    {{ exam.cell | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.suggestedPatient">
								    {{ exam.suggestedPatient?.cell | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.selectedPatient">
								    {{ exam.selectedPatient?.cell | phone }}
								  </div>
								  <div fxFlex="0 0 250px" *ngIf="exam.patient">
								    {{ exam.patient?.cell | phone }}
								  </div>
							  </div>
							  <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
								  <div fxFlex="0 0 100px">
								  </div>
								  <div fxFlex="0 0 250px" style="font-weight:bold;">
										<div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="exam.patient; else noPatientBlock">
											<button mat-raised-button [routerLink]="['/admin/patient', exam.patient._id]">GO TO PATIENT</button>
											<button mat-raised-button (click)="unlinkPatient(exam._id, exam.patient._id)">UNLINK PATIENT</button>
										</div>
										<ng-template #noPatientBlock>
											<button mat-raised-button [routerLink]="['/admin/patient/new']" [queryParams]="{exam: exam._id}">CREATE NEW PATIENT</button>
										</ng-template>
								  </div>
								  <div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.suggestedPatient">
								    <button mat-raised-button (click)="linkPatient(exam._id, exam.suggestedPatient._id)">LINK THIS PATIENT</button>
								  </div>
									<div fxFlex="0 0 250px" style="font-weight:bold;" *ngIf="exam.selectedPatient">
								    <button mat-raised-button (click)="linkPatient(exam._id, exam.selectedPatient._id)">LINK THIS PATIENT</button>
								  </div>
								  <div fxFlex="0 0 250px" style="font-weight:bold;">
								  </div>
							  </div>
						  </div>

				<!-- 		  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="padding:12px; padding-left: 24px;width:200px" class="mat-elevation-z8">
							  <div fxFlex>
							    {{ exam?.examPatient?.lastName }}, {{ exam?.examPatient?.firstName }}
							  </div>
							  <div fxFlex>
							    {{ exam?.examPatient?.dob | date }}
							  </div>
							  <div fxFlex>
							    {{ exam?.examPatient?.address }}<br>
							    {{ exam?.examPatient?.address2 }}<br>
							    {{ exam?.examPatient?.city }}, {{ exam?.examPatient?.state }}
							  </div>
							  <div fxFlex>
							    {{ exam?.examPatient?.phone | phone }}
							  </div>
						  </div>
 -->
		        </div>
		      </mat-cell>
		    </ng-container>


		    <mat-header-row 
		      *matHeaderRowDef="displayedColumns" 
		      fxLayout="row" 
		      fxLayoutAlign="start center">
		    </mat-header-row>
		    <mat-row 
		      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
		      fxLayout="row" 
		      fxLayoutAlign="start center" 
		      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
		      style="border-left:1px solid #dfdfdf !important;" 
		      (click)="toggleExpanded($event, row, i)">
		    </mat-row>
		    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
		      [class.example-detail-row-expanded]="expandedElement === element"
		      [class.example-detail-row-collapsed]="expandedElement !== element"
		      style="overflow: hidden"
		      fxLayoutAlign="start center">
		    </mat-row>
		    <mat-footer-row 
		      *matFooterRowDef="['patient']" 
		      fxLayout="row" 
		      fxLayoutAlign="start center">
		    </mat-footer-row>
		  </mat-table>

		  

		</div>






  </mat-card-content>
</mat-card>

