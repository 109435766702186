<button
	mat-button
	[matMenuTriggerFor]="logMenu"
	*ngIf="_order.value.log && _order.value.log[0]"
	(click)="_clickField()"
  [class.small]="small">
	<img class="log" src="assets/images/log2.svg" alt="View log">
	<ng-container *ngIf="!small">
		<span *ngIf="_order.value.log[0].item">
			{{ _order.value.log[0].item }}&nbsp;&ndash;&nbsp;
		</span>
		<span>
			{{ _order.value.log[0].initials }}&nbsp;&ndash;&nbsp;
			{{ _order.value.log[0].date | date: 'MM/dd/yyyy' : 'PST' }}
		</span>
	</ng-container>
</button>
<mat-menu #logMenu="matMenu">
 	<span mat-menu-item *ngFor="let log of _order.value.log; let j=index">
 		<span *ngIf="log.item">{{ log.item }}&nbsp;&ndash;&nbsp;</span>
 		{{ log.initials }}
 		&nbsp;&ndash;&nbsp;{{ log.date | date: 'MM/dd/yyyy': 'PST' }}
 		<span *ngIf="log.notes">&nbsp;&ndash;&nbsp;{{ log.notes }}</span>
 	</span>
</mat-menu>
