import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '@modules/material/material.module';
import { UiCommonModule } from './common/ui-common.module';
import { V2SharedModule } from './shared/v2-shared.module';
import { V2HomeComponent } from './v2-home.component';
import { PayablesListComponent } from './components/payables/payables-list/payables-list.component';
import { ComponentsModule } from './components/components.module';
import { PayableOrderComponent } from './components/payables/payable-order/payable-order.component';
import { PayablesComponent } from './components/payables/payables/payables.component';
import { PaidListComponent } from './components/payables/paid-list/paid-list.component';
import { S } from '@angular/cdk/keycodes';
import { ShopifyExportComponent } from './components/shopify-export/shopify-export.component';

const routes: Routes = [
  {
    path: '',
    component: V2HomeComponent,
    children: [
      {
        path: 'orders/:orderId',
        component: PayableOrderComponent,
      },
      {
        path: 'payables',
        component: PayablesComponent,
      },
      {
        path: 'payables/distributor/:distributorId/paid',
        component: PaidListComponent,
      },
      {
        path: 'payables/distributor/:distributorId/:status',
        component: PayablesListComponent,
      },
      {
        path: 'payables/distributor/:distributorId',
        component: PayablesListComponent,
      },

      {
        path: 'payables/vendor/:vendorId/paid',
        component: PaidListComponent,
      },
      {
        path: 'payables/vendor/:vendorId/:status',
        component: PayablesListComponent,
      },
      {
        path: 'payables/vendor/:vendorId',
        component: PayablesListComponent,
      },
      {
        path: 'shopify-export',
        component: ShopifyExportComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [V2HomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UiCommonModule,
    V2SharedModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ],
})
export class V2RoutingModule {}
