import { Component, OnInit, ChangeDetectorRef, Input, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { VendorOrderService } from '@services/vendor-order.service';
import { ActivatedRoute } from '@angular/router';
import { VendorBaseComponent } from '@components/admin/inventory/vendor-base/vendor-base.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiResponse } from '@classes/api-response';

@Component({
  selector: 'app-receivable',
  templateUrl: './receivable.component.html',
  styleUrls: ['./receivable.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ReceivableComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<VendorOrders> = new MatTableDataSource<VendorOrders>();
  displayedColumns = ['vendor', 'count', 'action'];
  expandedElement = null;
  expandedIndex:number = null;
  clickedStatus: boolean = false;
  invoiceStatus: string[] = [];
  vendorOrders: VendorOrders[] = [];
  paidCount: number[] = [];
  unpaidCount: number[] = [];
  vendorId: string = null;
  orderId: string = null;
  defaultInvoiceStatus: string = null;

  constructor(public vendorOrderService: VendorOrderService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute, private location: Location) {
    this.route.paramMap.subscribe(params => {
      this.vendorId = params.get('vendorId');
      this.orderId = params.get('orderId');
      this.defaultInvoiceStatus = params.get('invoiceStatus');
    });

  }

  ngOnInit() {
    this.getVendorOrders('receivable');
  }

  getVendorOrders(status: string) {
    this.vendorOrderService.getDistributorVendorOrderCounts(status).subscribe((res: ApiResponse<any[]>) => {
      console.log(status)
      console.log(res)
      try {
        this.expandedElement = res.vendorOrders.find(x => x.vendor2._id === this.vendorId);
      } catch(err) {
        this.expandedElement = null;
      }
      for (let x = 0; x < res.vendorOrderCounts.length; x++) {
        this.invoiceStatus[x] = 'all';
        if (res.vendorOrderCounts[x].vendor2 && res.vendorOrderCounts[x].vendor2._id == this.vendorId && this.defaultInvoiceStatus == 'paid') {
          this.invoiceStatus[x] = 'paid';
        }
      }
      this.vendorOrders = res.vendorOrderCounts;
      this.dataSource = new MatTableDataSource<any>(this.vendorOrders);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.cdRef.detectChanges(); 
    });
  }

  toggleExpanded($event, element, index) {
    if (!this.clickedStatus) {
      if (this.expandedElement !== element) {
        this.expandedElement = element;
        this.expandedIndex = index;
      } else {
        this.expandedElement = null;
        this.expandedIndex = null;
      }
    }
    this.clickedStatus = false;
  }

  refreshVendorOrders() {
    this.expandedElement = null;
    this.getVendorOrders('all');
  }

  setInvoiceStatus(status: string, index: number) {
    this.clickedStatus = true;
    this.invoiceStatus[index] = (status == 'paid') ? 'paid' : 'all';
    this.cdRef.detectChanges(); 
  }



}
