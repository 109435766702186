<!--<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="SEARCH INVENTORY" (input)="productSearch()">
</mat-form-field>-->
<div *ngIf="!dataSource.data">
  <h3 style="text-align:center;">LOADING PRODUCT HISTORY</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!dataSource.data.length">
  <h3 style="text-align:center;">NO PRODUCT HISTORY (ID: {{product}})</h3>
</div>

<mat-table *ngIf="dataSource.data.length" [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumn" matSortDirection="desc" style="width:100%">
	<ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 250px"> ID </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 250px">
    	<span>{{ product }}</span>
    </mat-cell>
  </ng-container>

	<ng-container matColumnDef="size">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 5%"> SIZE </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 5%">
    	<span>{{ productObject.size }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="images">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 185px"> IMAGE </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index; " fxFlex="1 0 185px">

    	<app-images
				[images]="productObject.images" 
				[edit]="false" 
				[delete]="false" 
				[upload]="false" 
				[productId]="productObject._id">
			</app-images>

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> QTY </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 5%">
    	<span>{{ productObject.quantity }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="orders">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> ORDERS </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 5%">
    	<span>{{ orders.length }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="noHistory">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 70%">  </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 70%">
  		<h3 style="text-align:center;">NO PRODUCT HISTORY</h3>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="invoiceNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> INVOICE # </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 10%">
    	<span>{{ history.invoiceNumber }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> RECEIVED DATE </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 10%">
    	<span>{{ history.date | date:'M/d/yyyy': 'GMT' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cost">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> COST </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 5%">
    	<span>{{ history.product.cost | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tax">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> TAX </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 5%">
    	<span>{{ history.product.tax | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="shipping">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> SHIPPING </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 5%">
    	<span>{{ history.product.shipping | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="duty">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> DUTY </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 5%">
  		<mat-checkbox disabled [checked]="history.order.products[0].duty"></mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patientStock">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> PATIENT/STOCK </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=dataIndex;" fxFlex="1 1 10%">
    	<span *ngIf="history.type=='return'">{{history.type}}: {{history.status}}</span>
    	<span *ngIf="history.type!='return'">{{history.product.frame.frameSource}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patient">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> PATIENT </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index;" fxFlex="1 1 10%">
      <button mat-button *ngIf="history.patient" [routerLink]="['/admin/patient', history.patient._id]" style="width:100%;text-transform:uppercase;">{{history.patient.lastName}}, {{history.patient.firstName}}</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="store">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> FRAME LOCATION </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 10%">
    	<span>{{ history.store.storeName }}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row 
  	*matHeaderRowDef="displayedColumns" 
  	fxLayout="row" 
  	fxLayoutAlign="start stretch"></mat-header-row>
  <mat-row 
  	*matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;" 
  	fxLayout="row" 
  	fxLayoutAlign="start stretch" 
  	[ngClass]="{rowEven:even, rowOdd:odd}"></mat-row>

</mat-table>
