<div *ngIf="dataLoading">
	  <h3 style="text-align:center;" *ngIf="section=='inquiries'">LOADING INQUIRIES</h3>
	  <h3 style="text-align:center;" *ngIf="section=='orders'">LOADING ORDERS</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="container" style="width:100%" *ngIf="!dataLoading">
  <mat-table #orderTable matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">
    <ng-container matColumnDef="alert">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px">
        <mat-icon color="warn" *ngIf="order.alert">notification_important</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span style="font-weight:bold;"> {{ order.vendor.name }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order.orders.length != 1"> {{ order.orders.length }} ITEMS</span>
        <span *ngIf="order.orders.length == 1"> {{ order.orders.length }} ITEM</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="accountNumber">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> ACCOUNT # </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement"> {{ order.vendor.accountNumber }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="repName">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> REP NAME </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement"> {{ order.vendor.repName }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderProtocol">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> ORDER PROTOCOL </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement"> {{ order.vendor.orderProtocol }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="returnDetails">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> RETURN DETAILS </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span *ngIf="order==expandedElement"> {{ order.vendor.returnDetails }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="returnAddress">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> RETURN ADDRESS </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <div #container style="width:100%">
          <div *ngIf="order==expandedElement" style="text-align: left !important;width:100%;white-space: pre;" [fittext]="true" [activateOnResize]="true">{{ order.vendor.returnAddress }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderInfo">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 300px"> ORDER INFO </mat-header-cell>
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;" fxFlex="1 1 300px">
        <span *ngIf="order==expandedElement && order.vendor.orderProtocol=='Online' && validateUrl('http://'+order.vendor.website)">&nbsp;
          <a [href]="'http://'+order.vendor.website" target="_blank">
            <mat-icon>open_in_browser_outline</mat-icon><span style="position:relative;top: -5px;">{{ order.vendor.website }}</span>
          </a>
        </span>
        <span *ngIf="order==expandedElement && order.vendor.orderProtocol=='Call'">&nbsp;
          <mat-icon>phone</mat-icon><span style="position:relative;top: -5px;">{{ order.vendor.repPhone }} <span *ngIf="order.vendor.repPhoneExt">x{{ order.vendor.repPhoneExt }}</span></span>
        </span>
        <span *ngIf="order==expandedElement && order.vendor.orderProtocol=='Email' && validateEmail(order.vendor.repEmail)">&nbsp;
          <a [href]="'mailto:'+order.vendor.repEmail">
            <mat-icon>email_outline</mat-icon><span style="position:relative;top: -5px;">{{ order.vendor.repEmail }}</span>
          </a>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let order; let orderIndex=dataIndex;">
        <div 
          class="example-element-detail" 
          style="margin-left:20px;width:100%;" 
          *ngIf="order==expandedElement" 
          [class.mat-elevation-z0]="order!=expandedElement" 
          [class.mat-elevation-z8]="order==expandedElement" 
          [@detailExpand]="order == expandedElement ? 'expanded' : 'collapsed'">

          <app-stack-inquiries *ngIf="section=='inquiries' && order==expandedElement" [vendorOrders]="order" [orderIndex]="orderIndex" style="width:100%;"></app-stack-inquiries>
          <app-stack-orders *ngIf="section=='orders' && order==expandedElement" [vendorOrders]="order" [orderIndex]="orderIndex" style="width:100%;"></app-stack-orders>
          <app-awaiting-frames-orders *ngIf="section=='awaiting frames' && order==expandedElement" [vendorOrders]="order" [orderIndex]="orderIndex" (refreshOrders)="refreshOrders()" style="width:100%;"></app-awaiting-frames-orders>
          <app-returns-orders *ngIf="section=='returns' && order==expandedElement" [vendorOrders]="order" [orderIndex]="orderIndex" (refreshOrders)="refreshOrders()" style="width:100%;" (refreshOrders)="refreshVendorOrders()"></app-returns-orders>

        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let element; columns: displayedColumns; let r=dataIndex"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="toggleExpanded(element, r)"
      fxLayoutAlign="start stretch" 
      >
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden">
    </mat-row>
  </mat-table>
</div>


