import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Payable } from '@models/api.model';

@Component({
  selector: 'app-payables-list-menu',
  template: `<ng-container *ngIf="payable && type === 'options'">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [attr.aria-label]="'Order ' + payable._id + ' options'"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="open-sans">
        <button mat-menu-item (click)="onView.emit(payable._id)">
          <mat-icon>visibility</mat-icon>
          <span>View invoice</span>
        </button>
        <button
          *ngIf="payable.awaitingCredit"
          [disabled]="!payable.invoiceNumber"
          mat-menu-item
          (click)="onMarkForCredit.emit(payable._id)"
        >
          <mat-icon>payments</mat-icon>
          <span>Mark as credited</span>
        </button>
        <button mat-menu-item (click)="onPrint.emit(payable._id)">
          <mat-icon>print</mat-icon>
          <span>Print</span>
        </button>
        <button mat-menu-item (click)="onDelete.emit(payable._id)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </ng-container>
    <ng-container *ngIf="payable && type === 'log'">
      <button
        *ngIf="payable.log.length || payable.notes?.length"
        [matMenuTriggerFor]="menu"
        class="flex"
        [class.indicator]="payable?.notes?.length"
      >
        <mat-icon>notes</mat-icon>
        <span *ngIf="payable?.notes?.length" class="indicator"></span>
      </button>
      <mat-menu #menu="matMenu" class="open-sans log">
        <ng-container *ngIf="payable?.notes?.length">
          <h3 class="ao-log-title">Notes</h3>
          <table class="ao-log open-sans">
            <ng-container *ngFor="let log of payable.notes">
              <tr>
                <td colspan="2">
                  {{ log.notes ?? '' }}
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex align-items-center">
                    <mat-icon class="icon">event</mat-icon>
                    {{ log.date | date: 'shortDate' }}
                  </div>
                </td>
                <td class="d-flex align-items-center sig">
                  <div class="d-flex align-items-center">
                    <mat-icon class="icon">gesture</mat-icon> {{ log.initials }}
                  </div>
                </td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
        <ng-container *ngIf="payable?.log?.length">
          <h3 class="ao-log-title">Log</h3>
          <table class="ao-log open-sans">
            <tr *ngFor="let log of payable.log">
              <td>
                {{ log.item }}
              </td>
              <td>
                <mat-icon class="icon">event</mat-icon>
                {{ log.date | date: 'shortDate' }}
              </td>
              <td class="sig">
                <mat-icon class="icon">gesture</mat-icon> {{ log.initials }}
              </td>
            </tr>
          </table>
        </ng-container>
      </mat-menu>
    </ng-container>`,
})
export class PayablesListMenuComponent {
  @Input() payable: Payable;
  @Input() type: 'options' | 'log' = 'options';
  @Output() onDelete = new EventEmitter<string>();
  @Output() onPrint = new EventEmitter<string>();
  @Output() onMarkForCredit = new EventEmitter<string>();
  @Output() onView = new EventEmitter<string>();
}
