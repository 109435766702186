import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { V2SharedModule } from '../shared/v2-shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '@modules/material/material.module';
import { RouterModule } from '@angular/router';
import { PayablesListComponent } from './payables/payables-list/payables-list.component';
import { PayableOrderComponent } from './payables/payable-order/payable-order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayablesComponent } from './payables/payables/payables.component';
import { ImageModule } from '@components/admin/image/image.module';
import { ProductImageComponent } from './images/product-image/product-image.component';
import { FileUploadComponent } from './images/file-upload/file-upload.component';
import { PayableOrderDialogComponent } from './payables/payable-order/payable-order.dialog.component';
import { PayablesListMenuComponent } from './payables/payables-list/payables-list-menu.component';
import { PaidInvoiceComponent } from './payables/payable-order/paid-invoice/paid-invoice.component';
import { CreditInvoiceComponent } from './payables/payable-order/credit-invoice/credit-invoice.component';
import { UnpaidInvoiceComponent } from './payables/payable-order/unpaid-invoice/unpaid-invoice.component';
import { InvoiceLogComponent } from './payables/payable-order/invoice-log/invoice-log.component';
import { InvoiceNotesComponent } from './payables/payable-order/invoice-notes/invoice-notes.component';
import { PaidListComponent } from './payables/paid-list/paid-list.component';
import { UiCommonModule } from '@v2/common/ui-common.module';
import { ShopifyExportComponent } from './shopify-export/shopify-export.component';
import { MenuComponent } from './shopify-export/menu/menu.component';

@NgModule({
  declarations: [
    PayablesListComponent,
    PayableOrderComponent,
    PayablesComponent,
    ProductImageComponent,
    FileUploadComponent,
    PayableOrderDialogComponent,
    PayablesListMenuComponent,
    PaidInvoiceComponent,
    CreditInvoiceComponent,
    UnpaidInvoiceComponent,
    InvoiceLogComponent,
    InvoiceNotesComponent,
    PaidListComponent,
    ShopifyExportComponent,
    MenuComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    V2SharedModule,
    NgScrollbarModule,
    MaterialModule,
    RouterModule,
    ImageModule,
    UiCommonModule,
  ],
  exports: [
    PayablesListComponent,
    PayableOrderComponent,
    PayablesComponent,
    ProductImageComponent,
    FileUploadComponent,
    PayableOrderDialogComponent,
    PayablesListMenuComponent,
    PaidListComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
