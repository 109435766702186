import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Order, LogElement } from '@models/order';
import { ProductElement } from '@models/product';
import { VendorOrders } from '@models/vendor-order';
import { AuthService } from '@services/auth.service';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ApiResponse } from '@classes/api-response';
import { formatDate } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private base_url = environment.base_url + '/order';

  private orderSource = new Subject<Order>();
  private orderImageSource = new Subject<Object>();
  order$ = this.orderSource.asObservable();
  orderImages$ = this.orderImageSource.asObservable();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public http: HttpClient,
    private auth: AuthService
  ) {}

  queryOrders(query: String): Observable<Object> {
    var store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/store/${store}/query/${query}`)
      .pipe(map((res) => res));
  }

  getOrder(orderId: String): Observable<Order> {
    return this.http
      .get(`${this.base_url}/get/${orderId}`)
      .pipe(map((res) => this.setOrder(res)));
  }

  getPatientOrders(
    patientId: String,
    section: String,
    status: string = null
  ): Observable<any> {
    return this.http
      .get(
        `${this.base_url}/getPatientOrders/${section}/${patientId}/${status}`
      )
      .pipe(map((res: ApiResponse<Order[]>) => res.orders));
  }

  getNewOrders(section: String): Observable<VendorOrders[]> {
    var store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/getNewOrders/store/${store}/${section}`)
      .pipe(map((res: ApiResponse<VendorOrders[]>) => res.orders));
  }

  getDispensedOrders(): Observable<VendorOrders[]> {
    var store = this.auth.getStore();
    return this.http
      .get(`${this.base_url}/getDispensedOrders/store/${store}`)
      .pipe(map((res: ApiResponse<VendorOrders[]>) => res.orders));
  }

  getProductOrders(productId: string): Observable<Order[]> {
    return this.http
      .get(`${this.base_url}/getProductOrders/${productId}`)
      .pipe(map((res: ApiResponse<Order[]>) => res.orders));
  }

  getOrderImages(orderId: String): Observable<any> {
    return this.http
      .get(`${this.base_url}/getImages/${orderId}`)
      .pipe(map((res) => this.setOrderImages(res)));
  }

  getPartsOrders(vendorId: string): Observable<any[]> {
    return this.http
      .get(`${this.base_url}/getPartsOrders/${vendorId}`)
      .pipe(map((res: ApiResponse<any[]>) => res.orders));
  }

  setPatientOrders(res) {
    return res;
  }

  setOrder(res) {
    return res;
  }

  doneUpdatingOrder(res) {
    return res;
  }

  doneDeletingOrder(res) {
    return res;
  }

  setOrderImages(res) {
    return res;
  }

  addOrder(order: Order): Observable<any> {
    let body = JSON.stringify(order);
    return this.http.post(`${this.base_url}`, body).pipe(
      map((res: ApiResponse<Order[]>) => {
        return res.orders;
      })
    );
  }

  addOrdersFromTmp(orders: Order[]): Observable<boolean> {
    let body = JSON.stringify(orders);
    return this.http
      .post(`${this.base_url}/addFromTmp`, body)
      .pipe(map((res) => this.setOrder(res)));
  }

  addOrders(orders: Order[]): Observable<any> {
    let body = JSON.stringify(orders);
    return this.http
      .post(`${this.base_url}`, body)
      .pipe(map((res) => this.setOrder(res)));
  }

  updateOrder(orderId: any, order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/${orderId}`, body).pipe(
      map((res) => {
        return this.doneUpdatingOrder(res);
      })
    );
  }

  updateOrderStatus(order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/status`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  updateOrderDispensed(order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/dispensed`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  updateOrderStatusAndLog(order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/statusLog`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  updateOrderFull(order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/full`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  updateOrderTaxAndShipping(order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/taxAndShipping`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  updateOrderVendorOrder(order: Order): Observable<Order> {
    let body = JSON.stringify(order);
    return this.http.put(`${this.base_url}/vendorOrder`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  updateImages(orderId: any, images: any): Observable<Order> {
    let body = JSON.stringify(images);
    return this.http.put(`${this.base_url}/${orderId}/images`, body).pipe(
      map((res: ApiResponse<Order>) => {
        return res.order;
      })
    );
  }

  deleteImage(orderId: any, imageId: any): Observable<any> {
    return this.http
      .delete(`${this.base_url}/${orderId}/image/${imageId}`)
      .pipe(map((res) => res));
  }

  deleteOrder(orderId: any): Observable<Order> {
    return this.http.delete(`${this.base_url}/${orderId}`).pipe(
      map((res) => {
        return this.doneDeletingOrder(res);
      })
    );
  }

  returnOrder(order: Order, credit: any): Observable<Order> {
    return this.http
      .put(`${this.base_url}/return/credit/${credit}`, order)
      .pipe(
        map((res: ApiResponse<Order>) => {
          return res.order;
        })
      );
  }

  setOrderAsPrescription(orderId: any): Observable<Order> {
    return this.http
      .put(`${this.base_url}/setAsPrescription/${orderId}`, httpOptions)
      .pipe(
        map((res) => {
          return this.doneDeletingOrder(res);
        })
      );
  }

  addStockOrder(
    product: ProductElement,
    type: string,
    status: string
  ): Observable<Order> {
    let newOrder = this.getNewOrder(
      product,
      'ORDER FOR STOCK',
      type,
      true,
      'REORDER',
      status
    );
    return this.addOrder(newOrder);
  }

  addReturnOrder(product: ProductElement, type: string): Observable<Order> {
    let newOrder = this.getNewOrder(
      product,
      'ORDER FOR RETURN',
      type,
      false,
      'RETURN',
      'RETURN'
    );
    return this.addOrder(newOrder);
  }

  getOrderById(orderId: String): Observable<Order> {
    return this.http.get<Order>(`${this.base_url}/v2/${orderId}`);
  }

  updateOrderById(orderId: string, order: Partial<Order>): Observable<Order> {
    const body = JSON.stringify(order);
    return this.http.put<Order>(`${this.base_url}/v2/${orderId}`, body);
  }

  private getNewOrder(product, itemNote, type, restockOrder, todo, status) {
    product.frame.frameSource = 'STOCK';

    let logItem = new LogElement();
    logItem.initials = this.auth.currentUser.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = itemNote;
    logItem.notes = '';
    logItem.date = Date.now().toString();

    const newOrder: Order = {
      _id: '',
      invoiceNumber: '',
      type: type == 'RX' ? 'O' : 'N',
      restockOrder: restockOrder,
      patient: null,
      date: formatDate(
        Date.now(),
        'yyyy/MM/dd HH:mm:ss.SSS',
        this.locale,
        'PST'
      ),
      dateAdded: formatDate(
        Date.now(),
        'yyyy/MM/dd HH:mm:ss.SSS',
        this.locale,
        'PST'
      ),
      dateUpdated: formatDate(
        Date.now(),
        'yyyy/MM/dd HH:mm:ss.SSS',
        this.locale,
        'PST'
      ),
      archived: false,
      notes: '',
      todo: todo,
      images: [],
      products: [product],
      status: status,
      user: this.auth.currentUser._id,
      log: [logItem],
      store: this.auth.getStore(),
    };

    return newOrder;
  }
}
