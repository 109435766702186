import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LogElement } from '@models/order';
import { VendorOrder } from '@models/vendor-order';
import { AuthService } from '@services/auth.service';
import ObjectID from 'bson-objectid';

@Component({
  selector: 'app-invoice-notes',
  templateUrl: './invoice-notes.component.html',
  styleUrls: ['./invoice-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceNotesComponent {
  @Input() order: VendorOrder;
  @ViewChild('notesDetail') notesModal: TemplateRef<any>;
  @ViewChild('notesForm') notesFormModal: TemplateRef<any>;
  @Output() updateOrderEvent = new EventEmitter<Array<LogElement>>();

  noteForm: FormGroup | undefined;

  constructor(private dialog: MatDialog, private readonly aS: AuthService) {}

  addOrderNotes() {
    this.noteForm = new FormGroup({
      date: new FormControl(new Date().toISOString()),
      item: new FormControl('NOTE'),
      notes: new FormControl(null, Validators.required),
      user: new FormControl(this.aS.currentUser._id),
      initials: new FormControl(null, [
        Validators.minLength(2),
        Validators.required,
      ]),
      _id: new FormControl(new ObjectID()),
    });
    this.dialog.open(this.notesFormModal, {
      maxWidth: '450px',
      panelClass: 'minimal',
    });
  }

  addNote() {
    const notes = [this.noteForm.value];
    this.updateOrderEvent.emit(notes);
  }

  vieOrderNotes() {
    this.dialog.open(this.notesModal, {
      maxWidth: '450px',
      panelClass: 'minimal',
    });
  }
}
