import { Vendor } from '@models/vendor';
import { VendorOrder } from '@models/vendor-order';
import { Order } from '@models/order';
import { Patient } from '@models/patient';
import { Store } from '@models/store';

export class Product {
  _id: string;
  model: string;
  color?: string;
  size?: string;
  type: string;
  vendor: Vendor | string;
  status?: string;
  commission?: string;
  cost: number;
  pricingFormula: string;
  retail: number;
  store: Store;
  quantity?: number;
  rush?: boolean;
  send?: boolean;
  orderedBy?: string;
  backordered?: boolean;
  wishlist?: boolean;
  sold?: number;
  images: string[];
  orderDate?: number;
  dateAdded?: number | string;
  dateUpdated?: number | string;
  borrowing?: Borrowing[];
  query?: any;
  queryProducts?: any;
  specialOrder?: any;
  inventoryVendorOrders?: VendorOrder[] | string[];
  return?: boolean;
  notes?: string;
  history?: Array<FrameHistory>;

  constructor(obj?: any) {
    if (obj) {
      this.newProduct(obj);
    } else {
      this._id = '';
      this.model = '';
      this.color = '';
      this.size = '';
      this.type = '';
      this.vendor = null;
      this.status = '';
      this.commission = '';
      this.cost = null;
      this.pricingFormula = '';
      this.retail = null;
      this.store = null;
      this.quantity = 0;
      this.rush = false;
      this.send = false;
      this.orderedBy = '';
      this.backordered = false;
      this.wishlist = false;
      this.sold = 0;
      this.images = [];
      this.orderDate = null;
      this.dateAdded = null;
      this.dateUpdated = Date.now();
      this.borrowing = [];
      this.notes = '';
    }
  }

  newProduct(obj: any) {
    this._id = obj._id;
    this.model = obj.model;
    this.color = obj.color;
    this.size = obj.size;
    this.type = obj.type;
    this.vendor = obj.vendor;
    this.status = obj.status;
    this.commission = obj.commission;
    this.cost = obj.cost;
    this.pricingFormula = obj.pricingFormula;
    this.retail = obj.retail;
    this.store = obj.store;
    this.quantity = obj.quantity;
    this.rush = obj.rush;
    this.send = obj.send;
    this.orderedBy = obj.orderedBy;
    this.backordered = obj.backordered;
    this.wishlist = obj.wishlist;
    this.sold = obj.sold;
    this.images = obj.images;
    this.orderDate = obj.orderDate;
    this.dateAdded = obj.dateAdded;
    this.dateUpdated = Date.now();
    this.borrowing = obj.borrowing;
    this.notes = obj.notes;
  }

  static calculateProductPrice(product) {
    let rx_validate = /^x.+p.+$/;
    let rx_extract = /^x(.+)p(.+)$/;
    let result = [];
    let multiplier = 1;
    let adder = 0;
    if (rx_validate.test(product.pricingFormula)) {
      result = rx_extract.exec(product.pricingFormula);
      multiplier = parseFloat(result[1]);
      adder = parseFloat(result[2]);
    }
    if (parseFloat(product.cost)) {
      return (
        Math.ceil(
          Math.round((parseFloat(product.cost) * multiplier + adder) * 100) /
            100 /
            5
        ) * 5
      );
    }
    return null;
  }
}

export interface FrameHistory {
  id: string;
  orderId?: string;
  vendorOrderId?: string;
  patientId?: string;
  changes: Record<string, { previous: any; current: any }>;
}


export class Borrowing {
  store: number;
  quantity: number;
  date: string;
}

export class FrameMeasurements {
  a: number = null;
  b: number = null;
  ed: number = null;
  dbl: number = null;
}

export class FrameDetails {
  frameSource: string = '';
  measurements: FrameMeasurements = new FrameMeasurements();
  enclosed: boolean = false;
  pof: boolean = false;
  patientToSupply: boolean = false;
  duty: boolean = false;
  hold: boolean = false;
  modification: boolean = false;
  modificationPrice: number = 0;
  modificationDetails: string = '';
  estimate: number = 0;
  deposit: number = 0;
  callPatient: boolean = false;
  notes: string = '';
  price: number = 0;
  orderDate: string = '';
  dueDate: string = '';
  failedInspection: boolean = false;
  approved: boolean = false;
  nosepadInstall: boolean = false;
}

export class ClipOnStatus {
  lab: string = '';
  topBar: boolean = false;
  color: boolean = false;
  material: boolean = false;
  tint: boolean = false;
  coating: boolean = false;
  polar: number = 0;
  mirror: boolean = false;
  mirrorDetails: string = '';
  rush: boolean = false;
  other: boolean = false;
  otherDetails: string = '';
  otherPrice: number = 0;
  price: number = 0;
  notes: string = '';
  labReferenceNumber: string = '';
  orderDate: string = '';
  failedInspection: boolean = false;
}

export class LensDetails {
  bc: number = 0;
  et: number = 0;
  ct: number = 0;
  lab: string = '';
  type: string = '';
  material: string = '';
  transition: string = '';
  polar: string = '';
  design: string = '';
  tint: string = '';
  coating: string = '';
  overPowerSph: string = '';
  edgePolish: string = '';
  mountingEdging: string = '';
  prism: string = '';
  overPowerCyl: string = '';
  bevel: string = '';
  drill: boolean = false;
  backsideAR: boolean = false;
  specialBC: boolean = false;
  specialTool: boolean = false;
  groove: boolean = false;
  sendForAR: boolean = false;
  UVcoating: boolean = false;
  slabOff: boolean = false;
  mirror: boolean = false;
  mirrorDetails: string = '';
  customShape: boolean = false;
  other: boolean = false;
  otherDetails: string = '';
  otherPrice: number = 0;
  price: number = 0;
  POL: string = '';
  POLLabReferenceNumber: string = '';
  POLPrice: number = 0;
  taxable: boolean = false;
  lensAndShapeNotes: string = '';
  machine: string = '';
  grooveSize: string = '';
  lensSize: string = '';
  depth: string = '';
  bevel2: string = '';
  width: string = '';
  placement: string = '';
  technicalNotes: string = '';
  date: string = '';
  labReferenceNumber: string = '';
  failedInspection: string = '';
}

export class SpecialInstructions {
  rush: boolean = false;
  opticianReview: boolean = false;
  approveFrame: boolean = false;
  shipOrder: boolean = false;
  doNotMount: boolean = false;
  approveTint: boolean = false;
  callAsReady: boolean = false;
  doNotNotify: boolean = false;
  tintBeforeCE: boolean = false;
  neededBy: string = '';
}

export class ProductElement {
  _id: string = '';
  product: Product = new Product();
  frame: FrameDetails = new FrameDetails();
  type: string = '';
  //clipOn: ClipOnStatus = new ClipOnStatus();
  lens: LensDetails = new LensDetails();
  //specialInstructions: SpecialInstructions = new SpecialInstructions();
  quantity: number = 1;
  retail: number = 0;
  pricingFormula: string = '';
  cost: number = null;
  tax: number = null;
  shipping: number = null;
  duty: boolean = false;
  destination: string = 'SEND TO STACK';
  store: Store = null;

  uniqueId: string = '';
}

export class ProductHistory {
  _id: string = '';
  product: Product = new Product();
  order: Order = new Order();
  patient: Patient = new Patient();
  invoiceNumber: string = '';
  raNumber: string = '';
  status: string = '';
  date: string;
  type: string = '';
  cost: number = null;
  tax: number = null;
  shipping: number = null;
  duty: boolean = false;
  store: Store = null;
  PRODUCTHISTORY2: any = null;
}

class iProductQueryValue {
  value: string = '';
  selected: boolean = false;
}

export class ProductQuery {
  model: iProductQueryValue = new iProductQueryValue();
  color: iProductQueryValue = new iProductQueryValue();
  type: iProductQueryValue = new iProductQueryValue();
  size: iProductQueryValue = new iProductQueryValue();
};

export interface IProductQuery {
  model: iProductQueryValue;
  color: iProductQueryValue;
  type: iProductQueryValue;
  size: iProductQueryValue;
};

export class ProductLogs {
  _id: string;
  product: Product = new Product();
  log?: ProductLog[];
  dateAdded?: string;

  constructor() {
    this._id = '';
    this.product = null;
    this.log = [];
    this.dateAdded  = '';
  }

}

export class ProductLog {
  _id: string = null;
  user?: string = '';
  quantity?: number = 0;
  action?: string = '';
  notes?: string = '';
  date?: number = null;

  constructor() {
    this._id = null;
    this.user = '';
    this.quantity = 0;
    this.action = '';
    this.notes = '';
    this.date = Date.now();
  }
}
