import { Component, OnInit, ChangeDetectorRef, HostListener, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Vendor } from '@models/vendor';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common'
import { AngularCsv } from 'angular7-csv';

@Component({
  selector: 'app-tax-report-orders',
  templateUrl: './tax-report-orders.component.html',
  styleUrls: ['./tax-report-orders.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [DatePipe]
})
export class TaxReportOrdersComponent implements OnInit {
	@Input() vendorOrders: any;
	@Input() vendor: Vendor;
	@Input() startDate: any;
	@Input() endDate: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	loading: boolean = true;
  displayedColumns = ['model', 'color', 'size', 'type', 'status', 'dateReceived', 'datePaid', 'invoiceNumber', 'date', 'cost', 'tax', 'shipping', 'log'];
  expandedElement = null;

  constructor(public cdRef:ChangeDetectorRef, public route: ActivatedRoute, public datepipe: DatePipe) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  	console.log(this.vendorOrders)
    this.dataSource = new MatTableDataSource<any>(this.vendorOrders);
    console.log(this.dataSource.data)
    this.loading = false;
    this.cdRef.detectChanges(); 
  }

  toggleExpanded($event, element, index) {
    // if (this.expandedElement !== element) {
    //   this.expandedElement = element;
    // } else {
    //   this.expandedElement = null;
    // }
  }

  calculateTaxTotal() {
  	let sum: number = 0;
    if (this.dataSource)
      for (let row of this.dataSource.data) {
        sum += row.products[0].tax;
      }
    return sum;
  }


	saveCSV() {
		let date1 = new Date(this.startDate);
		let date2 = new Date(this.endDate);
		let date1string =this.datepipe.transform(date1, 'MM-dd-yyyy');
		let date2string =this.datepipe.transform(date2, 'MM-dd-yyyy');
		let title = this.vendor.name + ' NON-RX TAX REPORT ' + date1string + ' ' + date2string;
		var options = { 
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true, 
			showTitle: false,
			title: title,
			useBom: false,
			noDownload: false,
			headers: ['VENDOR', 'MODEL', 'COLOR', 'SIZE', 'TYPE', 'STATUS', 'RECEIVED DATE', 'PAID DATE', 'INVOICE NUMBER', 'INVOICE DATE', 'COST', 'TAX', 'SHIPPING']
		};
		var orders = [];
		let toUpper = function(x){ 
		  return x.toUpperCase();
		};
		console.log(this.dataSource.data)
		for (var x = 0; x < this.vendorOrders.length; x++) {
			let vendorOrder = this.vendorOrders[x];

			orders.push({
				vendor: vendorOrder.products[0].product.vendor.name,
				model: vendorOrder.products[0].product.model,
				color: vendorOrder.products[0].product.color,
				size: vendorOrder.products[0].product.size,
				type: vendorOrder.products[0].product.type,
				status: vendorOrder.status,
				dateReceived: vendorOrder.dateReceived,
				datePaid: vendorOrder.datePaid,
				invoiceNumber: vendorOrder.invoiceNumber,
				invoiceDate: vendorOrder.date,
				cost: vendorOrder.products[0].cost,
				tax: vendorOrder.products[0].tax,
				shipping: vendorOrder.products[0].shipping
			})
		}
		new AngularCsv(orders, title, options);
	}

}
