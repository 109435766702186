import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@classes/api-response';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';

const BASE_URL = `${environment.base_url}/eye-exam`;

@Injectable({
  providedIn: 'root'
})
export class EyeExamService {

  constructor(private http: HttpClient) { }

  getAll(date: string[], store: string): Observable<any> {
    return this.http.get<any>(BASE_URL, {
      params: { date, store }
    }).pipe(map(res => res.exams));
  }

  refreshExams(): Observable<boolean> {
    return this.http.post<ApiResponse<never>>(`${BASE_URL}/refresh`, {}).pipe(map(res => res.success));
  }

  linkPatient(examId: string, patientId: string): Observable<boolean> {
    return this.http.post<ApiResponse<never>>(`${BASE_URL}/link`, { examId, patientId }).pipe(map(res => res.success));
  }

  findById(examId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/${examId}`).pipe(map(res => res.exam));
  }

  unlinkPatient(examId: string, patientId: string): Observable<boolean> {
    return this.http.post<ApiResponse<never>>(`${BASE_URL}/unlink`, { examId, patientId }).pipe(map(res => res.success));
  }
}
