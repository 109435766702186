import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Image } from '@models/image';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ImageEditComponent } from '@components/admin/image/image-edit/image-edit.component';
import { ProductService } from '@services/product.service';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css'],
  providers: [ ImageEditComponent]
})
export class ImagesComponent implements OnInit {
  @Input() images: string[] = [];
  @Input() urls: any[] = [];
  @Output() imagesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() tmp: boolean = false;
  @Input() active: boolean = false;
  @Input() edit: boolean = false;
  @Input() delete: boolean = false;
  @Input() upload: boolean = false;
  @Input() autoCrop: boolean = false;
  @Input() productId: string;
  @Input() type: string = '';
  @Input() clickable: boolean = true;
  @Output() imageDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageReplace: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageCrop: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageUpload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('image', {static: false}) imageRef: ElementRef;
  public uploaderProduct:FileUploader = new FileUploader({url: environment.base_url + '/image/product', itemAlias: 'photoProduct'});
  img: Image = new Image();
  imageURL = environment.base_url + '/image/';
  imageHover:boolean = false;
  oneImage:boolean = true;
  timestamp:number;

  constructor(private auth: AuthService, private productService: ProductService, private cdRef:ChangeDetectorRef, public dialog: MatDialog) {}


  ngOnInit() {
    this.initImages();
  }

  ngAfterViewInit() {

  }

  initImages() {
    if (!this.images) {
      this.images = [];
    } else if (!Array.isArray(this.images)) {
      this.images = [this.images];
      this.oneImage = false;
    } else if (this.images.length > 0) {
      this.oneImage = false;
    } else {
      this.oneImage = true;
    }
    this.cdRef.detectChanges(); 
  }

  imageEdit(imageId, productId) {
  	// this.images.push(imageId);
  }

  imageAdd(imageId) {
    if (!this.images) {
      this.images = [];
    }
  	this.images.push(imageId);
    this.initImages();
  	this.imagesChange.emit(this.images);
  }

  replaceImage(imageId, index) {
    this.images[index] = imageId;
    this.imagesChange.emit(this.images);
  }

  deleteImage(imageId, index) {
  	this.images.splice(index, 1);
    this.initImages();
    this.imagesChange.emit(this.images);
  }

}
