<div *ngIf="loading">
	<h3 style="text-align:center;">LOADING REPORT</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!loading" style="width:100%">
  <mat-table #table [dataSource]="dataSource" multiTemplateDataRows>

  	<ng-container matColumnDef="model">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> MODEL </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.products[0].product.model }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> COLOR </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.products[0].product.color }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> SIZE </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.products[0].product.size }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> TYPE </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.products[0].type }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> STATUS </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.status }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

		<ng-container matColumnDef="dateReceived">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> RECEIVED DATE </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex; let row;" fxFlex="1 1 10%">
        <span> {{ vendorOrder.dateReceived | date:'M/d/yyyy': 'GMT' }} </span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="datePaid">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> PAID DATE </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex; let row;" fxFlex="1 1 10%">
        <span> {{ vendorOrder.datePaid | date:'M/d/yyyy': 'GMT' }} </span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="invoiceNumber">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> INVOICE # </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
      	<span>{{ vendorOrder.invoiceNumber }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

		<ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> COST </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.products[0].cost | currency }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" >  </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="tax">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> TAX </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
        <span>{{ vendorOrder.products[0].tax | currency }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > {{ calculateTaxTotal() | currency }} </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="shipping">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> SHIPPING </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex;" fxFlex="1 1 10%">
      	<span>{{ vendorOrder.products[0].shipping | currency }}</span>

      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
    </ng-container>

		<ng-container matColumnDef="date">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> INVOICE DATE </mat-header-cell>
	    <mat-cell *matCellDef="let vendorOrder; let i=dataIndex; let row;" fxFlex="1 1 10%">
				<span> {{ vendorOrder.date | date:'M/d/yyyy': 'GMT' }} </span>
	    </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > </mat-footer-cell>
	  </ng-container>

    <ng-container matColumnDef="log">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"> LOG </mat-header-cell>
      <mat-cell *matCellDef="let vendorOrder; let i=dataIndex; let row;" fxFlex="0 0 40px">
        <app-log-menu [order]="vendorOrder.order" [small]="true"></app-log-menu>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="0 0 40px">
      	<button mat-icon-button (click)="saveCSV();"><mat-icon>cloud_download</mat-icon></button>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let vendorOrder; let vendorOrderdataIndex=datadataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="vendorOrder!=expandedElement" [class.mat-elevation-z8]="vendorOrder==expandedElement" [@detailExpand]="vendorOrder == expandedElement ? 'expanded' : 'collapsed'">
        	product
<!-- 
        <app-payable-products *ngIf="vendorOrder==expandedElement" [vendor]="vendorOrder.controls.vendor" [products]="vendorOrder.controls.products" style="width:100%;"></app-payable-products> -->

        </div>
      </mat-cell>
    </ng-container>





    <mat-header-row 
      *matHeaderRowDef="displayedColumns" 
      fxLayout="row" 
      fxLayoutAlign="start center">
    </mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
      fxLayout="row" 
      fxLayoutAlign="start center" 
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
      style="border-left:1px solid #dfdfdf !important;" 
      (click)="toggleExpanded($event, row, i)">
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>
  	<mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>

  </mat-table>

</div>
<!--
  <div *ngIf="selection.hasValue()">
	  <mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
	    <input matInput required placeholder="" type="text" formControlName="initials" (click)="clickField()">
	    <mat-label><mat-icon>gesture</mat-icon></mat-label>
	  </mat-form-field>
	  <button mat-button type="button" (click)="markvendorOrdersPaid()" [disabled]="vendorOrdersForm.controls.initials.invalid"><mat-icon>save</mat-icon><span>MARK AS PAID</span></button>
	</div>
</div> -->
