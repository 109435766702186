import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { NotificationService } from './notification.service';
import { ApiResponse } from '@classes/api-response';

enum HTTP_STATUS {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
}

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private notificationService: NotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    });

    return next.handle(request).pipe(
      tap((response: HttpResponse<any>) => {
        if (response['token']) {
          this.auth.updateToken(response);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HTTP_STATUS.SUCCESS) {
          const body = error.error.text || '';
          const resp = new HttpResponse({
            body,
          });
          return of(resp);
        } else {
          if (error.status === HTTP_STATUS.UNAUTHORIZED) {
            this.notificationService.show('error', 'You are unauthorized now');
            this.auth.logout();
          }
          return throwError(() => error);
        }
      })
    );
  }
}
