import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ReportService } from '@services/report.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	selectedValue: string;
	report: any;
	loading: boolean = true;
  displayedColumns = ['vendor', 'count'];
  expandedElement = null;

  reports: Report[] = [
    {value: '', viewValue: '-SELECT-'},
    {value: 'tax', viewValue: 'NON-RX TAX'},
    {value: 'customers', viewValue: 'CUSTOMERS'},
    {value: 'customers-email', viewValue: 'NO-SALE CUSTOMER EMAILS'},
    {value: 'customers-expired-rx', viewValue: 'EXPIRED RX CUSTOMER EMAILS'}
  ];

  constructor(public reportService: ReportService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit(): void {

  }

  selectReport(event:any): void {
  	switch(event.value) {
  		case "tax" :
  			this.report = 'tax';
  			break;
      case "customers" :
        this.report = 'customers';
        break;
      case "customers-email" :
        this.report = 'customers-email';
        break;
      case "customers-expired-rx" :
        this.report = 'customers-expired-rx';
        break;
  	}
    this.cdRef.detectChanges(); 
  }

}

interface Report {
  value: string;
  viewValue: string;
}