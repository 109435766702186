import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Vendor } from '@models/vendor';
import { Store } from '@models/store';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { Product, ProductQuery, ProductElement } from '@models/product';
import { ProductForm } from '@classes/product-form';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';

export class VendorOrderForm {

  constructor() {

  }

  static initVendorOrdersForm(vendorOrders:VendorOrders) {
    let fb = new FormBuilder();
    let vendorOrdersFormArray = VendorOrderForm.initVendorOrdersFormArray(vendorOrders);
    let vendorOrdersForm:FormGroup = fb.group({
      vendorOrders: vendorOrdersFormArray,
      'invoiceNumber': ['', Validators.required],
      'dateReceived': [new Date(), Validators.required],
      'tax': ['', Validators.required],
      'total': ['', Validators.required],
      'cost': ['', Validators.required],
      'taxPaid': ['', Validators.required],
      'difference': ['', Validators.required],
      'initials': ['', Validators.required]
    });
    return vendorOrdersForm;
  }

  static initVendorOrdersFormArray(vendorOrders:VendorOrders) {
    let fb = new FormBuilder();
    let vendorOrderFormArray:FormArray = fb.array([]);
    for (var x = 0; x < vendorOrders.vendorOrders.length; x++) {
      let vendorOrderFormGroup = VendorOrderForm.initVendorOrderFormGroup(vendorOrders.vendorOrders[x]);
      vendorOrderFormArray.push(vendorOrderFormGroup);
    }
    return vendorOrderFormArray;
  }

  static initVendorOrderFormGroup(vendorOrder: VendorOrder) {
    let fb = new FormBuilder();
    let productsFormArray:FormArray = fb.array([]);
    for (var x = 0; x < vendorOrder.products.length; x++) {
      let productElement = <ProductElement>vendorOrder.products[x];
      let productElementFormGroup = ProductForm.initProductElementFormGroup(productElement);
      productsFormArray.push(productElementFormGroup);
    }
    let productElement = <ProductElement>vendorOrder.products[0];
    let vendorOrderFormGroup: FormGroup = fb.group({
      '_id': vendorOrder._id,
      'vendor': vendorOrder.vendor,
      'store': vendorOrder.store,
      'raNumber': [vendorOrder.raNumber, Validators.required],
      'date': vendorOrder.date,
      'shippingMethod': vendorOrder.shippingMethod,
      'trackingNumber': vendorOrder.trackingNumber,
      'status': vendorOrder.status,
      'shipping': vendorOrder.shipping,
      'proposedCredit': vendorOrder.proposedCredit,
      'tax': productElement.tax,
      'user': vendorOrder.user,
      'products': productsFormArray,
      'initials': ['', Validators.required],
      'log': fb.array(vendorOrder.log)
    });
    return vendorOrderFormGroup;
  }
}
