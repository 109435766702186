import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, ElementRef, ViewChild, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '@modules/material/material.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { UnsavedComponent } from '@components/admin/dialog/unsaved/unsaved.component';
import { ImageService } from '@services/image.service';
import { StoreService } from '@services/store.service';

import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Store } from '@models/store';
import { Vendor } from '@models/vendor';
import { VendorService } from '@services/vendor.service';
import { AuthService } from '@services/auth.service';

import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { parsePhoneNumber } from 'libphonenumber-js';
import ObjectId from 'bson-objectid';
import { MatSnackBar } from '@angular/material/snack-bar';

const imageURL = environment.base_url + '/image/';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class VendorsComponent implements OnInit {
  @ViewChild('vendorInput', { static: false }) vendorInput: ElementRef;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  vendorCtrl = new FormControl();
  filteredVendors: Observable<Vendor[]>;
  allVendors: Vendor[] = [];
  allVendorsObj: Vendor[] = [];
  selectedVendor: string = '';
  selectedVendorObj: Vendor = null;
  selectedVendorImages: any[] = [];
  vendorSearchForm: FormGroup;
  vendorForm: FormGroup;
  saveButtonClicked: Boolean = false;
  accountStatusOptions: Object[] = [
    { viewValue: 'Managed Board', value: 'Managed Board' },
    { viewValue: 'SILMO/MIDO', value: 'SILMO/MIDO' },
    { viewValue: 'In-Store Rep', value: 'In-Store Rep' },
  ];
  //pricingFormulaOptions: Object[] = [{viewValue: 'x3 + $10', value:'x3p10'}, {viewValue: 'Optical:x3 + $10 / Sun:x2.5 + $10', value:'o:x3p10,sun:x2.5p10'}];
  pricingFormulaOptions: Object[] = [
    { viewValue: 'x2', value: 'x2p0' },
    { viewValue: 'x2 + $10', value: 'x2p10' },
    { viewValue: 'x2 + $15', value: 'x2p15' },
    { viewValue: 'x2 + $30', value: 'x2p30' },
    { viewValue: 'x2.25', value: 'x2.25p0' },
    { viewValue: 'x2.5', value: 'x2.5p0' },
    { viewValue: 'x2.5 + $5', value: 'x2.5p5' },
    { viewValue: 'x2.5 + $10', value: 'x2.5p10' },
    { viewValue: 'x2.5 + $15', value: 'x2.5p15' },
    { viewValue: 'x2.5 + $20', value: 'x2.5p20' },
    { viewValue: 'x2.75', value: 'x2.75p0' },
    { viewValue: 'x2.75 + $10', value: 'x2.75p10' },
    { viewValue: 'x3', value: 'x3p0' },
    { viewValue: 'x3 + $5', value: 'x3p5' },
    { viewValue: 'x3 + $10', value: 'x3p10' },
    { viewValue: 'x3 + $15', value: 'x3p15' },
    { viewValue: 'x3 + $20', value: 'x3p20' },
    { viewValue: 'x3 + $30', value: 'x3p30' },
    { viewValue: 'x3 + $80', value: 'x3p80' },
    { viewValue: 'x4', value: 'x4p0' },
  ];
  updateBoardOptions: Object[] = [
    { viewValue: 'Quarterly', value: 'Quarterly' },
    { viewValue: '3x/Year', value: '3x/Year' },
    { viewValue: 'Bi-annually', value: 'Bi-annually' },
    { viewValue: 'Yearly', value: 'Yearly' },
    { viewValue: 'As Needed', value: 'As Needed' },
  ];
  orderProtocolOptions: Object[] = [
    { viewValue: 'Email', value: 'Email' },
    { viewValue: 'Call', value: 'Call' },
    { viewValue: 'Online', value: 'Online' },
  ];
  shippingMinOptions: Object[] = [
    { viewValue: '0', value: '0' },
    { viewValue: '1', value: '1' },
    { viewValue: '2', value: '2' },
    { viewValue: '3', value: '3' },
    { viewValue: '4', value: '4' },
    { viewValue: '5', value: '5' },
    { viewValue: '10', value: '10' },
    { viewValue: '15', value: '15' },
    { viewValue: '20', value: '20' },
  ];
  validateEmail = Vendor.validateEmail;
  validateUrl = Vendor.validateUrl;
  images: any[] = [];
  allStores: Observable<Store[]>;
  selectedStoreControl: FormControl = new FormControl();
  selectedStore: Store;
  store: Store;
  currentStore: Store;
  storeSubscription: Subscription;

  public uploader: FileUploader = new FileUploader({
    url: environment.base_url + '/image/vendor',
    itemAlias: 'photo',
    disableMultipart: true,
  });

  constructor(
    private snackbar: MatSnackBar,
    private auth: AuthService,
    private vendorService: VendorService,
    private imageService: ImageService,
    public storeService: StoreService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    public cdRef: ChangeDetectorRef
  ) {
    this.allStores = this.storeService.getAllStores();
    this.storeService.getAllStores().subscribe((sdf) => {
      this.selectedStoreControl = new FormControl(this.selectedStore._id, []);
      this.selectedStoreControl.valueChanges.subscribe((val) => {
        this.vendorCtrl.reset();
        this.getVendors(val);

        this.initVendorForm();
        this.vendorForm.markAsPristine();
        this.selectedVendor = '';
        this.selectedVendorObj = null;
        this.selectedVendorImages = [];
        this.location.go('admin/vendors');

        this.cdRef.detectChanges();
      });
      this.cdRef.detectChanges();
    });
    this.selectedStore = this.auth.getStoreObject();
    this.store = this.auth.getStoreObject();

    this.route.paramMap.subscribe((params) => {
      let vendorId = params.get('vendorId');
      if (vendorId) {
        this.vendorService.getVendor(vendorId).subscribe((res) => {
          let vendor: Vendor = res as Vendor;
          if (vendor) {
            this.vendorCtrl.setValue(vendor.name);
            this.selectVendor({ source: { selected: true } }, vendor);
          }
        });
      }
    });

    this.storeSubscription = this.auth
      .getStoreObjectMessage()
      .subscribe((store: Store) => {
        this.currentStore = store;
      });

    this.getVendors();
    this.vendorSearchForm = fb.group({ vendorCtrl: '' });
    this.initVendorForm();
  }

  initVendorForm() {
    this.vendorForm = this.fb.group({
      _id: '',
      name: ['', Validators.required],
      distributor: '',
      distributorVendors: '',
      phone: '',
      phoneExt: '',
      fax: '',
      website: '',
      username: '',
      password: '',
      accountNumber: '',
      repName: '',
      repPhone: '',
      repPhoneExt: '',
      repEmail: '',
      accountStatus: '',
      defaultManagedBoardStatus: '',
      pricingFormulaOptical: '',
      pricingFormulaSun: '',
      taxBothRxAndSun: '',
      idealInventory: '',
      updateBoard: '',
      shippingMin: '',
      orderProtocol: '',
      orderInfo: '',
      shippingFee: '',
      returnAddress: '',
      returnDetails: '',
      returnNotes: '',
      namingKey: '',
    });
  }

  ngOnInit() {
    //this.parentForm.value.vendors = [];
    this.allVendors = [];

    // this.uploader.authToken = `Bearer ${this.auth.getToken()}`;
    // this.uploader.onAfterAddingFile = (file)=> {
    //   file.withCredentials = false;
    //   this.uploader.uploadAll();
    // };

    // this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
    //   console.log("VENDOR IMAGE")

    //   var imageId = JSON.parse(response);
    //   for (let x = 0; x < imageId.length; x++) {
    //     let image = {
    //       id: imageId[0],
    //       thumbnail:imageURL+imageId[0]+'/thumbnail/1?bearer='+this.auth.getToken(),
    //       image:imageURL+imageId[0]+'/thumbnail/0?bearer='+this.auth.getToken()
    //     }
    //     this.selectedVendorImages.push(image);
    //     this.vendorService.addImage(this.selectedVendorObj._id, imageId[0])
    //       .subscribe(vendor => {
    //         console.log("vendor image added " + vendor._id);
    //       });
    //   }

    //   this.getVendors();
    // };

    this.uploader.onAfterAddingFile = (item: FileItem) => {
      const objectKey = new ObjectId().toString();
      this.images.push(objectKey);
      this.imageService
        .getUploadUrl(objectKey, item.file.type)
        .subscribe((url) => {
          item.url = url;
          item.method = 'PUT';
          item.headers = [{ name: 'Content-Type', value: item.file.type }];
          item.withCredentials = false;
          item.upload();
        });
    };

    this.uploader.onCompleteAll = () => {
      for (var x = 0; x < this.images.length; x++) {
        this.selectedVendorImages.push(this.images[x]);
        this.vendorService
          .addImage(this.selectedVendorObj._id, this.images[x])
          .subscribe((vendor) => {});
      }
    };
  }

  getVendors(store = null) {
    this.vendorService.getAllVendors(store).subscribe((res) => {
      this.allVendorsObj = res as Vendor[];
      this.allVendors = [];
      for (let x = 0; x < res.length; x++) {
        if (res[x].store == this.selectedStore._id)
          this.allVendors.push(res[x]);
        //this.allVendors.push(res[x]['name']);
      }
      this.filteredVendors = this.vendorCtrl.valueChanges.pipe(
        startWith(null),
        map((vendor) =>
          vendor ? this._filter(vendor) : this.allVendors.slice()
        )
      );
      this.cdRef.detectChanges();
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      let newVendorName = value.toUpperCase().trim();
      let newVendor = new Vendor();
      newVendor.name = newVendorName;
      this.vendorService.addVendor(newVendor).subscribe((res) => {
        this.allVendors.push(res);
        this.allVendorsObj.push(res);

        this.filteredVendors = this.vendorCtrl.valueChanges.pipe(
          startWith(null),
          map((vendor) =>
            vendor ? this._filter(vendor) : this.allVendors.slice()
          )
        );
      });

      //this.parentForm.controls.favoriteLines.setValue(this.favorites);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.vendorCtrl.setValue(null);
  }

  newVendor(): void {
    let value = this.vendorCtrl.value;
    if (value != undefined) {
      let newVendorName = value.toUpperCase().trim();
      if (newVendorName.length > 1) {
        let newVendor = new Vendor();
        newVendor.name = newVendorName;
        this.vendorService.addVendor(newVendor).subscribe((res) => {
          this.allVendors.push(res);
          this.allVendorsObj.push(res);

          this.filteredVendors = this.vendorCtrl.valueChanges.pipe(
            startWith(null),
            map((vendor) =>
              vendor ? this._filter(vendor) : this.allVendors.slice()
            )
          );

          this.selectVendor({ source: { selected: true } }, res);
        });
      }
    }
  }

  remove(favorite: any): void {
    const index = this.allVendors.indexOf(favorite.value);

    if (index >= 0) {
      this.allVendors.splice(index, 1);
    }
    //this.parentForm.value.favoriteLines = this.allVendors;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //this.allVendors.push(event.option.viewValue);
    //this.parentForm.controls.favoriteLines.setValue(this.allVendors);
    this.vendorInput.nativeElement.value = '';
    this.vendorCtrl.setValue(null);
  }

  private _filter(value: string): Vendor[] {
    const filterValue = value.toLowerCase();
    // return this.allVendorsObj.filter(vendor => vendor.name.toLowerCase().indexOf(filterValue) === 0);
    return this.allVendorsObj.filter(
      (vendor) => vendor.name.toLowerCase().indexOf(filterValue) != -1
    );
  }

  selectVendor($event, vendor) {
    if ($event.source.selected) {
      if (this.isDifferentStoreSelected()) {
        vendor.differentStore = this.selectedStore._id;
      } else {
        this.location.go(`admin/vendors/${vendor._id}`);
      }
      this.vendorForm.reset(vendor);
      this.vendorForm.markAsPristine();
      this.selectedVendor = vendor.name;
      this.selectedVendorObj = vendor;
      this.selectedVendorImages = [];
      for (let x = 0; x < this.selectedVendorObj.images.length; x++) {
        let image = this.selectedVendorObj.images[x]
          ? this.selectedVendorObj.images[x]
          : null;
        this.selectedVendorImages.push(image);
      }
    }
  }

  displayFn(vendor?: Vendor): string | undefined {
    return vendor ? vendor.name : undefined;
  }

  click(e: Event, vendor: Vendor) {
    this.vendorForm.reset(vendor);
    this.vendorForm.markAsPristine();
    this.selectedVendor = vendor.name;
    this.selectedVendorObj = vendor;
    this.selectedVendorImages = [];

    for (let x = 0; x < this.selectedVendorObj.images.length; x++) {
      let image = this.selectedVendorObj.images[x]
        ? this.selectedVendorObj.images[x]
        : null;
      this.selectedVendorImages.push(image);
    }
  }

  imageMouseEnter(image) {
    image.hover = true;
  }

  imageMouseLeave(event, image) {
    image.hover = false;
  }

  imageDelete(image, i): void {
    let dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE IMAGE?' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.vendorService
          .deleteImage(this.selectedVendorObj._id, image.id)
          .subscribe((res) => {
            this.selectedVendorImages.splice(i, 1);
          });
      }
    });
  }

  deleteImage(imageId, replacementImageId = true) {
    for (let x = 0; x < this.selectedVendorImages.length; x++) {
      if (this.selectedVendorImages[x] == imageId) {
        this.selectedVendorImages.splice(x, 1);
      }
    }
    if (replacementImageId !== true) {
      this.selectedVendorImages.push(replacementImageId);
      this.vendorService
        .addImage(this.selectedVendorObj._id, replacementImageId)
        .subscribe((vendor) => {
          console.log('vendor image added ' + vendor._id);
        });
    }
    this.cdRef.detectChanges();
  }

  deleteVendor(vendorId): void {
    let dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE VENDOR?' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.vendorService.deleteVendor(vendorId).subscribe((res) => {
          this.vendorCtrl.setValue(null);
          this.selectedVendor = '';
          this.selectedVendorObj = null;
          this.selectedVendorImages = [];
          this.getVendors();
        });
      }
    });
  }

  saveVendor(vendor) {
    this.saveButtonClicked = true;
    const formModel: Vendor = this.vendorForm.value;
    this.selectedVendorObj = formModel;
    this.cdRef.detectChanges();
    this.vendorService
      .updateVendor(vendor._id, formModel)
      .subscribe((vendor) => {
        this.vendorForm.markAsPristine();
        this.getVendors();
        this.openDialog();
      });
  }

  openDialog(): void {
    this.snackbar.open(`Vendor has been successfully saved.`, 'close', { duration: 5000 });
  }

  openUnsavedDialog(): void {}

  canDeactivate() {
    if (this.vendorForm.dirty && !this.saveButtonClicked) {
      let dialogRef = this.dialog.open(UnsavedComponent, {
        width: '250px',
        data: {
          message:
            'DISCARD UNSAVED CHANGES TO ' + this.selectedVendorObj.name + '?',
        },
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  phoneType(event, field) {
    const phoneNumber = parsePhoneNumber(
      this.vendorForm.controls[field].value,
      'US'
    );
    this.vendorForm.controls[field].setValue(phoneNumber.formatNational());
  }

  isDifferentStoreSelected(): boolean {
    return this.selectedStore._id != this.store._id;
  }

  isDifferentStore(store: any): boolean {
    return store != this.store._id;
  }
}
