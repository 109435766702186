import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order } from '@models/order';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-return-order-dialog',
  templateUrl: './return-order-dialog.component.html',
  styleUrls: ['./return-order-dialog.component.css']
})
export class ReturnOrderDialogComponent {
	credit: any = 0;

  constructor(public dialogRef: MatDialogRef<ReturnOrderDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  	let order: Order = data.order;
  	this.credit = (order.products[0]) ? order.products[0].retail : '';
  }

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	cancel() {
		this.dialogRef.close(false);
	}

	return() {
		this.dialogRef.close(true);
	}

  formatCurrency() {
    let tmpValue = parseFloat(this.credit.replace(/,/g, ''));
    let value = (isNaN(tmpValue)) ? 0 : tmpValue;
    this.credit = formatNumber(value, 'en-US', '1.2-2');
  }

}
