<div class="app-body" [class.isPrinting]="pS.isPrinting">
    <app-navbar [isPrinting]="pS.isPrinting"></app-navbar>

    <div class="container">
        <router-outlet></router-outlet>
    </div>

    <footer class="block"><img src="/assets/logo_footer.png"></footer>
</div>
<router-outlet name="print"></router-outlet>
<app-print-invoice></app-print-invoice>
<app-alert [alertStream]="aS.alert$"></app-alert>
<app-loading [loadingStream]="aS.loading$"></app-loading>
