import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Patient } from '@models/patient';
import { Prescription } from '@models/prescription';
import { Vendor } from '@models/vendor';
import { Store } from '@models/store';
import { Product, ProductQuery } from '@models/product';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { parsePhoneNumber } from 'libphonenumber-js'
import { FormatData } from '@classes/format-data';
import { OrderForm } from '@classes/order-form';

export class PrescriptionForm {

  constructor() {

  }

  static initPrescriptionFormArray(prescriptions:Prescription[]) {
    let fb = new FormBuilder();
    let prescriptionFormArray:FormArray = fb.array([]);
    for (var x = 0; x < prescriptions.length; x++) {
      // prescriptions[x].from2020Now = true;
      let prescriptionFormGroup = PrescriptionForm.initPrescriptionFormGroup(prescriptions[x]);
      if (prescriptions[x].from2020Now) {
        // prescriptionFormGroup.disable();
        // prescriptionFormGroup.get('oneYearExpiration').enable();
        // prescriptionFormGroup.get('from2020Now').enable();
        
      }
      prescriptionFormArray.push(prescriptionFormGroup);
    }
    return prescriptionFormArray;
  }

  static validateDate(c: FormControl) {
    var date = new Date(c.value);
    return (c.value == null || c.value == '' || (date instanceof Date && !isNaN(date.valueOf()))) ? null : {
      validateDate: {
        valid: false
      }
    };
  }

  static initPrescriptionFormGroup(prescription:Prescription) {
    let fb = new FormBuilder();
    if (!prescription.images || !prescription.images[0]) {
      prescription.images = [];
    }
    let prescriptionFormGroup = fb.group({
      '_id': prescription._id,
      'images': fb.array(prescription.images),
      'notes': prescription.notes,
      'type': {'value': prescription.type, 'disabled': prescription.from2020Now},
      'typeOther': prescription.typeOther,
      'expirationDate': [ {'value': prescription.expirationDate, 'disabled': true}, PrescriptionForm.validateDate],
      'archived': prescription.archived,
      'from2020Now': prescription.from2020Now,
      'eyeExam': prescription.eyeExam,
      'examDate': {'value': prescription.examDate, 'disabled': prescription.from2020Now},
      'oneYearExpiration': prescription.oneYearExpiration ? true : false,
      'html': (prescription as any).html,

      'doctorNotes': [{'value': prescription?.['eyeExam']?.['comments'], 'disabled': true}],

      'right': fb.group({
        'sph': {'value': prescription.right.sph, 'disabled': prescription.from2020Now},
        'cyl': {'value': prescription.right.cyl, 'disabled': prescription.from2020Now},
        'axis': {'value': prescription.right.axis, 'disabled': prescription.from2020Now},
        'add': {'value': prescription.right.add, 'disabled': prescription.from2020Now},
        'segHgt': {'value': prescription.right.segHgt, 'disabled': true},
        'prism1': {'value': prescription.right.prism1, 'disabled': prescription.from2020Now},
        'b1': {'value': prescription.right.b1, 'disabled': prescription.from2020Now},
        'prism2': {'value': prescription.right.prism2, 'disabled': prescription.from2020Now},
        'b2': {'value': prescription.right.b2, 'disabled': prescription.from2020Now},
        'pdd': {'value': prescription.right.pdd, 'disabled': true},
        'pdn': {'value': prescription.right.pdn, 'disabled': true},
        'oc': {'value': prescription.right.oc, 'disabled': prescription.from2020Now}
      }),
      'left': fb.group({
        'sph':{'value':  prescription.left.sph, 'disabled': prescription.from2020Now},
        'cyl': {'value': prescription.left.cyl, 'disabled': prescription.from2020Now},
        'axis': {'value': prescription.left.axis, 'disabled': prescription.from2020Now},
        'add': {'value': prescription.left.add, 'disabled': prescription.from2020Now},
        'segHgt': {'value': prescription.left.segHgt, 'disabled': true},
        'prism1': {'value': prescription.left.prism1, 'disabled': prescription.from2020Now},
        'b1': {'value': prescription.left.b1, 'disabled': prescription.from2020Now},
        'prism2': {'value': prescription.left.prism2, 'disabled': prescription.from2020Now},
        'b2': {'value': prescription.left.b2, 'disabled': prescription.from2020Now},
        'pdd': {'value': prescription.left.pdd, 'disabled': true},
        'pdn': {'value': prescription.left.pdn, 'disabled': true},
        'oc': {'value': prescription.left.oc, 'disabled': prescription.from2020Now}
      })
    });

    return prescriptionFormGroup;
  }
}
