<div class="container" style="width:100%" *ngIf="dataSource.data">
	<mat-table #table [dataSource]="dataSource" class="mat-elevation-z2">
	  <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px" style="padding-left:0px !important;">
      	<mat-checkbox (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px">
      	<mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelect(order) : null"
          [checked]="selection.isSelected(order)"
          [aria-label]="checkboxLabel(order,i)">
				</mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 200px">
	  		<ng-container *ngIf="!selection.hasValue()">
      		IMAGE
      	</ng-container>
      	<ng-container *ngIf="selection.hasValue()">
      		<button mat-raised-button [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
						<span>MODIFY {{ selection.selected.length }}</span>
						<span *ngIf="selection.selected.length != 1"> ITEMS</span>
						<span *ngIf="selection.selected.length == 1"> ITEM</span>
					</button>
					<mat-menu #menu="matMenu">
            <div style="display:flex;align-items:center; padding:0px 20px;">
              <mat-form-field style="width:35px;flex: 0 1 35px;" floatLabel="never">
				        <input matInput required placeholder="" type="text" [formControl]="multipleSaveInitials" (click)="$event.stopPropagation();" >
				        <mat-label><mat-icon>gesture</mat-icon></mat-label>
				      </mat-form-field>
              <button mat-menu-item type="button" (click)="multipleSaveChanges()" [disabled]="multipleSaveInitials.value=='' || multipleSaveInitials.value==null"
                style="margin-left:10px;flex-grow:1">

                SAVE CHANGES
                </button>
            </div>
            
            <div style="display:flex;align-items:center; padding:0px 20px;">
              <mat-form-field style="width:35px;flex: 0 1 35px;" floatLabel="never">
				        <input matInput required placeholder="" type="text" [formControl]="multipleSaveAndUpdateInitials" (click)="$event.stopPropagation();" >
				        <mat-label><mat-icon>gesture</mat-icon></mat-label>
				      </mat-form-field>
              <button mat-menu-item type="button" (click)="multipleSaveAndUpdateInventory()"
                [disabled]="multipleSaveAndUpdateInitials.value=='' || multipleSaveAndUpdateInitials.value==null" style="margin-left:10px;flex-grow:1">

                SAVE AND UPDATE INVENTORY
                </button>
            </div>
            
            
            
            <div style="display:flex;align-items:center; padding:0px 20px;">
              <mat-form-field style="width:35px;flex: 0 1 35px;" floatLabel="never">
				        <input matInput required placeholder="" type="text" [formControl]="multipleCancelInitials" (click)="$event.stopPropagation();" >
				        <mat-label><mat-icon>gesture</mat-icon></mat-label>
				      </mat-form-field>
              <button mat-menu-item type="button" (click)="multipleCancel()" [disabled]="multipleCancelInitials.value=='' || multipleCancelInitials.value==null"
                style="margin-left:10px;flex-grow:1" class="green"> CANCEL
              </button>
                </div>

					</mat-menu>
      	</ng-container>
    	</mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 200px">

      	<app-images
					type="product"
					[images]="order.value.products[0].product.images"
					(imagesChange)="updateImages($event,order.controls.products.controls[0].controls.product)"
					[edit]="true"
					[delete]="true"
					[upload]="true"
					[active]="orderId==order.value._id"
					[productId]="order.value.products[0].product._id"
					(click)="clickField()">
				</app-images>

      </mat-cell>
    </ng-container>

	  <ng-container matColumnDef="model">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 12%"> MODEL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 12%" [class.invalid]="order.get('products.0.product.model').invalid">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.model}}</span>
        <app-autocomplete-vendor-products [(productForm)]="order.controls.products['controls'][0]['controls']['product']"
          [vendor]="order.value.products[0].product.vendor"
	  			placeholder="MODEL"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="model"
	  			fxFlex="1 1 90%"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="color">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> COLOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%" [class.invalid]="order.get('products.0.product.color').invalid">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.color}}</span>
        <app-autocomplete-vendor-products [(productForm)]="order.controls.products['controls'][0]['controls']['product']"
          [vendor]="order.value.products[0].product.vendor"
	  			placeholder="COLOR"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="color"
	  			fxFlex="1 1 90%"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="size">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> SIZE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px" [class.invalid]="order.get('products.0.product.size').invalid">
	    	<span *ngIf="orderId!=order.value._id"> {{order.value.products[0].product.size}} </span>
        <app-autocomplete-vendor-products [(productForm)]="order.controls.products['controls'][0]['controls']['product']"
          [vendor]="order.value.products[0].product.vendor"
	  			placeholder="SIZE"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="size"
	  			fxFlex="1 1 90%"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="type">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> TYPE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px">
	    	<span>{{order.value.products[0].type}}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="status">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"> </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px" [ngClass]="order.value.products[0].product.status">
	      <span>&nbsp;</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="cost">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> COST </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px" [formGroup]="order" [class.invalid]="order.get('products.0.cost').invalid">
	      <span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].cost | currency : 'USD' : 'symbol' : '1.0' }}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
			      <mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
			        <input #formRow matInput placeholder="COST" type="text" formControlName="cost" (keyup)="changeCost(order)">
			      </mat-form-field>
			    </ng-container>
		    </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="priceLink">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 35px">
	      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula!='c'">link_outline</mat-icon>
	      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="retail">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> RETAIL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px" [formGroup]="order" [class.invalid]="order.get('products.0.retail').invalid">
	    	<span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].retail | currency : 'USD' : 'symbol' : '1.0' }}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
			    	<mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
			        <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (keyup)="changeRetail(order)">
			      </mat-form-field>
			    </ng-container>
			  </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="patientStock">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 60px"> ORDER FOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 60px">
	    	<span>{{order.value.products[0].frame.frameSource}}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="patient">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> PATIENT </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%">
	      <button mat-button *ngIf="order.value.patient" [routerLink]="['/admin/patient', order.value.patient._id]" style="width:100%;text-transform:uppercase;">{{order.value.patient.lastName}}, {{order.value.patient.firstName}}</button>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="invoiceNumber">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 90px"> INVOICE # </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 90px" [formGroup]="order">
	    	<span *ngIf="orderId!=order.value._id && order.value.invoiceNumber">{{order.value.type}}&nbsp;&ndash;&nbsp;{{order.value.invoiceNumber }}</span>
	    	<mat-form-field *ngIf="orderId==order.value._id" floatLabel="always" style="width:50px;">
				  <mat-select placeholder="TYPE" formControlName="type" (click)="clickField()">
						<mat-option *ngFor="let orderTypeOption of orderTypeOptions" [value]="orderTypeOption.value">
							{{ orderTypeOption.viewValue }}
						</mat-option>
					</mat-select>
				</mat-form-field>
	    	<mat-form-field *ngIf="orderId==order.value._id" >
		      <input matInput placeholder="INVOICE #" formControlName="invoiceNumber" (click)="clickField()">
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="eta">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 120px"> ETA </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 120px" [formGroup]="order">
				<span *ngIf="orderId!=order.value._id"> {{ order.value.eta | date:'M/d/yyyy': 'GMT' }} </span>
	      <mat-form-field *ngIf="orderId==order.value._id" floatLabel="never">
				  <input #formRow [matDatepicker]="picker" matInput placeholder="ETA" type="text" formControlName="eta" (click)="clickField();$event.stopPropagation();">
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	      	<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="store">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 150px"> STORE </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 150px" [formGroup]="order">
      	<!-- <pre>{{ order.value.products[0].store | json }}</pre> -->
				<span *ngIf="orderId!=order.value._id"> {{ order.value.products[0]?.store?.storeName }} </span>
      	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
						<mat-form-field *ngIf="orderId==order.value._id" style="width:90%" floatLabel="never">
		          <mat-select placeholder="STORE" formControlName="store" (click)="clickField();$event.stopPropagation();">
		            <mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption">
     <!--  	<pre>{{ order.value.products[0].store == storeOption }}</pre>
      	<pre>{{ order.value.products[0].store | json }}</pre>
      	<pre>{{ storeOption | json }}</pre> -->

      {{storeOption.storeName}}
    </mat-option>
		          </mat-select>
		        </mat-form-field>
		      </ng-container>
		    </ng-container>
      </mat-cell>
    </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 125px"></mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 125px" [formGroup]="order">
	    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
	      <button mat-button [matMenuTriggerFor]="menu" style="padding:0 10px 0 5px !important;"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
	      <mat-menu #menu="matMenu">
	    	 	<button mat-menu-item type="button" (click)="saveFrame(order)" style="margin-left:10px;" [disabled]="order.invalid">SAVE CHANGES</button>
	    	 	<button mat-menu-item type="button" (click)="saveAndUpdateInventory(order)" style="margin-left:10px;" [disabled]="order.invalid">SAVE AND UPDATE INVENTORY</button>
	        <button mat-menu-item type="button" (click)="onCancelFrame(order)" style="margin-left:10px;" [disabled]="order.value.initials=='' || order.controls.invoiceNumber.invalid">CANCEL ORDER</button>
	      </mat-menu>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="log">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 40px"> LOG </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px">
	    	<app-log-menu [order]="order" (clickField)="clickField()" [small]="true"></app-log-menu>
	    </mat-cell>
	  </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row"
	  	fxLayoutAlign="start center">
	  </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;" fxLayout="row"
	  	fxLayoutAlign="start center"
	  	[ngClass]="{rowEven:even, rowOdd:odd}"
	  	style="border-left:1px solid #dfdfdf !important;"
	  	[class.invalid]="row.invalid"
	  	[class.dirty]="row.dirty"
	  	(click)="clickOrder($event, row, i)">
	  </mat-row>
	</mat-table>

  <app-awaiting-frames-submit *ngIf="selection.hasValue()" [selected]="selection.selected" [(orderForm)]="orderForm" [orderIndex]="orderIndex" (saveIncomingOrder)="saveIncomingOrder($event)" (updateTotal)="calculateTotal()" style="width:100%;"></app-awaiting-frames-submit>

</div>
