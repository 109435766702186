import { Pipe, Component, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order } from '@models/order';
import { Product, ProductElement } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { OrderService } from '@services/order.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription } from 'rxjs';
import { Store } from '@models/store';

@Component({
  selector: 'app-managed-board-stack',
  templateUrl: './managed-board-stack.component.html',
  styleUrls: ['../managed-board.component.css', './managed-board-stack.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ManagedBoardStackComponent implements OnInit {
	@ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource = new MatTableDataSource<VendorOrders>();
  dataSource2: MatTableDataSource<Order>[] = [];
	vendorOrders: VendorOrders[];
  displayedColumns = ['alert', 'vendor', 'count', 'accountNumber', 'repName', 'orderProtocol', 'orderInfo'];
  displayedColumns2 = ['alert', 'model', 'color', 'size', 'orderStatus', 'status', 'cost', 'retail', 'patientStock', 'patient', 'invoiceNumber', 'todo', 'notes', 'action', 'orderedBy'];
  productForm: FormGroup;
  expandedElement = null;
  public getNewOrdersDone: Observable<any>;
  validateEmail = Vendor.validateEmail;
  validateUrl = Vendor.validateUrl;
  storeSubscription: Subscription;
  currentStore: Store;

  constructor(private auth: AuthService, private orderService: OrderService, private vendorOrderService: VendorOrderService, private cdRef:ChangeDetectorRef, private fb: FormBuilder, private route: ActivatedRoute) {
		this.productForm = fb.group({
      orders2: this.fb.array([])
    });
    this.storeSubscription = this.auth.getStoreObjectMessage().subscribe((store: Store) => {
      this.currentStore = store;
    });
	}

  ngOnInit() {
    
      // .pipe(switchMap((params: ParamMap) => this.patientService.getPatient(params.get('id'))))
      // .subscribe(patient => this.setPatient(patient));

  	//this.getNewOrders();
  }

	ngAfterViewInit() {
    this.cdRef.detectChanges(); 
  }

  processNewOrders(orders) {
    
  }

	// getNewOrders() {
 //    this.orderService.getNewOrders().subscribe(vendorOrders => {
 //      console.log(vendorOrders)
 //      this.route.paramMap.subscribe(params => {
 //        let vendorId = params.get('vendorId');
 //        this.expandedElement = vendorOrders.find(x => x.vendor._id === vendorId);
 //      })

 //      this.dataSource = new MatTableDataSource<VendorOrders>(vendorOrders);
 //      this.vendorOrders = vendorOrders;
 //      this.dataSource.sortingDataAccessor = (item, property) => {
 //        switch(property) {
 //          case 'vendor': 
 //            // if (item.vendor) {
 //            //   let vendor: Object = item.vendor;
 //            //   return vendor['name'];
 //            // } else {
 //            //   return item[property];
 //            // }
 //          //case 'vendor.name': return item.vendor.name;
 //          default: return item[property];
 //        }
 //      };
 //      this.dataSource.sort = this.sort;



 //      for (let x = 0; x < vendorOrders.length; x++) {
 //        let ordersArray = <FormArray>this.productForm.controls['orders2'];
 //        ordersArray.reset();
 //        let copyOrders :Order[] = [];
 //        for (let y = 0; y < vendorOrders[x].orders.length; y++) {
 //          copyOrders.push(vendorOrders[x].orders[y]);
 //          ordersArray.push(this.initOrderItem());
 //        }
 //        this.dataSource2.push(new MatTableDataSource<Order>(copyOrders));
 //      }


 //      //this.dataSource.data = orders;
 //     /* let copyOrders :Order[] = [];
 //      let ordersArray = <FormArray>this.productForm.controls['orders2'];
 //      ordersArray.reset();
 //      for (let x = 0; x < orders.length; x++) {
 //        if (orders[x].products.length > 1) {
 //          for (let y = 0; y < orders[x].products.length; x++) {
 //            let newOrder = orders[x];
 //            newOrder.products = [orders[x].products[y]];
 //            copyOrders.push(newOrder);
 //           }
 //        }
 //        orders[x].products = [orders[x].products[0]];
 //        copyOrders.push(orders[x]);
 //        ordersArray.push(this.initOrderItem());
 //      }
 //      console.log(copyOrders)
 //      console.log(this.productForm)
 //      console.log(ordersArray)
 //      */
 //      this.cdRef.detectChanges(); 

      


      
 //    });
	// }

  initOrderItem() {
    return this.fb.group({
      'initial': ''
    });
  }

	orderFrame(order, initials, orderIndex, index) {
    order.products.frame.frameSource = 'ORDERED FOR PATIENT';
		this.vendorOrderService.addVendorOrderProduct(order).subscribe(vendorOrder => {
			console.log(vendorOrder)
      order.status = 'AWAITING FRAME';
      order.initialsOrdered = initials;
      order.initialsOrderedDate = Date.now().toString();

      const ordersArray = <FormArray>this.productForm.controls['orders2'];
        this.dataSource2[orderIndex].data.splice(index,1);
        this.dataSource2[orderIndex] = new MatTableDataSource<Order>(this.dataSource2[orderIndex].data);
        console.log(this.dataSource2[orderIndex])
        console.log(this.dataSource.data)
        console.log(this.dataSource.data[orderIndex])

        this.dataSource.data[orderIndex].orders.splice(index,1);

        if (this.dataSource.data[orderIndex].orders.length < 1) {
          this.expandedElement = null;
        }

        console.log(ordersArray)
        ordersArray.removeAt(index);


			this.orderService.updateOrderStatus(order).subscribe(order2 => {


        //this.getNewOrders();
        // sub.subscribe((tes) => {
        //   console.log(tes)
        //   const ordersArray = <FormArray>this.productForm.controls['orders2'];
        //   ordersArray.removeAt(index)
        // });
				console.log(order2)
			})

		})
		
	}

  toggleExpanded(element, index) {
    console.log(element)
    if (this.expandedElement !== element) {
      this.expandedElement = element;
    } else {
      this.expandedElement = null;
    }
  }

}

