import { Pipe, LOCALE_ID, Inject, Component, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery, ProductHistory } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  combineLatest,
  concatMap,
  forkJoin,
  map,
  Observable,
  of,
  Subscription,
  tap,
} from 'rxjs';
import { OrdersBaseComponent } from '@components/admin/managed-board/base/orders-base/orders-base.component';
import { FileUploader } from 'ng2-file-upload';
import { NotificationService } from '@services/notification.service';
import ObjectID from 'bson-objectid';

@Component({
  selector: 'app-returns-orders',
  templateUrl: './returns-orders.component.html',
  styleUrls: [
    '../../managed-board.component.css',
    './returns-orders.component.css',
  ],
})
export class ReturnsOrdersComponent
  extends OrdersBaseComponent
  implements OnInit
{
  @Output() refreshOrders = new EventEmitter<boolean>();
  displayedColumns = [
    'select',
    'image',
    'model',
    'color',
    'size',
    'type',
    'cost',
    'retail',
    'store',
    'action',
    'log',
  ];
  public uploaderProduct: FileUploader = new FileUploader({
    url: environment.base_url + '/image/product',
    itemAlias: 'photoProduct',
  });
  expandedElement2 = null;
  authorizedRoles: string[] = ['MANAGER', 'OWNER/ADMIN', 'OPTICIAN/INVENTORY'];

  selectedOrderIds: string[] = [];

  get allSelectedIds() {
    return this.selectedOrderIds;
  }

  get framesSelected(): number {
    return this.selectedOrderIds.length;
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public auth: AuthService,
    public orderService: OrderService,
    public vendorOrderService: VendorOrderService,
    public productService: ProductService,
    public storeService: StoreService,
    public cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public notificationService: NotificationService
  ) {
    super(
      locale,
      auth,
      orderService,
      vendorOrderService,
      productService,
      storeService,
      cdRef,
      fb,
      route,
      notificationService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.selection.changed.subscribe((change) => {
      this.selectedOrderIds = this.selection.selected.map((s) => s.value._id);
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.orderForm.addControl(
      'raNumber',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl(
      'date',
      new FormControl(new Date(), Validators.required)
    );
    this.orderForm.addControl(
      'shippingMethod',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl(
      'trackingNumber',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl(
      'proposedCredit',
      new FormControl('', Validators.required)
    );
    this.orderForm.addControl(
      'initials',
      new FormControl('', Validators.required)
    );
    this.cdRef.detectChanges();
  }

  setOptionalOrderFormProperties(order: FormGroup) {}

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.calculateTotal();
    this.cdRef.detectChanges();
  }

  toggleSelect(order) {
    this.selection.toggle(order);
    this.calculateTotal();
    this.cdRef.detectChanges();

    for (let x = 0; x < this.selection.selected.length; x++) {
      let order = this.selection.selected[x].getRawValue();
      this.vendorOrderService
        .getProductHistory(order.products[0].product._id)
        .subscribe((productHistory: ProductHistory[]) => {
          // productHistory[0].tax
          console.log(productHistory);
          if (productHistory) {
            let orders = productHistory
              .filter((hist) => hist.type == 'order')
              .sort(function (a, b) {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
              });
            let orderGroup = this.selection.selected[x];
            if (orders[0]) {
              orderGroup
                .get('products.0.tax')
                .setValue(orders[0].product['tax']);
            } else {
              orderGroup.get('products.0.tax').setValue(0);
            }
          }
        });
    }
  }

  calculateTotal() {
    let total = 0;
    let tax = 0;
    for (let x = 0; x < this.selection.selected.length; x++) {
      total = total + this.selection.selected[x].value.products[0].cost;
      tax = tax + this.selection.selected[x].value.products[0].tax;
    }
    // let tax = parseFloat(this.orderForm.value.tax);
    // let shipping = parseFloat(this.orderForm.value.shipping);
    if (!isNaN(tax)) {
      total = total + tax;
    }
    this.orderForm.get('proposedCredit').setValue(total);
  }

  checkboxLabel(row?: FormGroup, index?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      index + 1
    }`;
  }

  clickOrder($event, order: FormGroup, index) {
    if (!this.fieldOpen) {
      if (this.orderIndex && this.index) {
        order.controls.notify.disable();
      }
      order.controls.notify.enable();
      this.orderId = order.value._id;
      this.index = index;
      this.fieldOpen = true;

      this.cdRef.detectChanges();

      //this.editProduct = product;
      //this.setProductForms(orderIndex);
      // $event.preventDefault();
      // $event.stopPropagation();
      //this.fieldOpen = false;
    }
  }

  saveIncomingOrder(incomingBoolean) {
    // let vendorOrders: VendorOrders = this.dataSource.data[orderIndex];

    this.fieldOpen = true;

    let logItem = new LogElement();
    logItem.initials = this.orderForm.value.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'FRAME RETURNED';
    logItem.notes = '';
    logItem.date = Date.now().toString();

    //this.addCurrentActionToLog(order, 'UPDATED');

    let total = this.orderForm.get('proposedCredit').value;
    if (typeof total === 'string') {
      total = total.replace(/,/g, '');
    }
    total = -1 * parseFloat(total);

    let vendorOrder = new VendorOrder();
    vendorOrder.date = this.orderForm.value.date;
    vendorOrder.type = 'return';
    vendorOrder.raNumber = this.orderForm.value.raNumber;
    vendorOrder.shippingMethod = this.orderForm.value.shippingMethod;
    vendorOrder.trackingNumber = this.orderForm.value.trackingNumber;
    vendorOrder.total = total;
    vendorOrder.proposedCredit = parseFloat(
      this.orderForm.value.proposedCredit
    );
    vendorOrder.vendor = this.vendorOrders.vendor;
    vendorOrder.user = this.auth.currentUser._id;
    vendorOrder.store = this.auth.getStore();
    vendorOrder.log.unshift(logItem);

    for (let x = 0; x < this.selection.selected.length; x++) {
      // let order = JSON.parse(JSON.stringify(this.selection.selected[x]));
      let order = this.selection.selected[x].getRawValue();
      order.status = 'RETURNED';
      order.log.unshift(logItem);

      order.products[0].store = order.products[0].store._id;
      order.products[0].product.cost = order.products[0].cost;
      order.products[0].product.retail = order.products[0].retail;
      order.products[0].product.pricingFormula =
        order.products[0].pricingFormula;
      order.products[0].product.type = order.products[0].type;
      vendorOrder.products.unshift(order.products[0]);
      vendorOrder.orders.unshift(order._id);
      vendorOrder.status = 'RETURNED';

      this.productService
        .incrementProductInventoryQuantity(order.products[0].product, -1)
        .subscribe((incrementProduct) => {
          this.productService
            .setStatus(order.products[0].product._id, 'returned')
            .subscribe((statusProduct) => {
              if (!order.products[0].product._id) {
                order.products[0].product._id = incrementProduct.product._id;
              }

              this.orderService
                .updateOrderStatusAndLog(order)
                .subscribe((order2) => {
                  if (x == this.selection.selected.length - 1) {
                    this.vendorOrderService
                      .addVendorOrder(vendorOrder)
                      .subscribe((vendorOrder) => {
                        this.refreshOrders.emit(true);
                      });
                  }
                });
            });
        });
    }
  }

  removeFramesFromVendorOrder() {
    const updates = Object.values(this.selectedOrderIds).map(
      (orderId): Observable<any> => {
        /* Add log in order  */
        const logItem: LogElement = {
          _id: new ObjectID().toHexString(),
          initials: this.auth.currentUser.name
            .split(' ')
            .map((item) => item[0].toUpperCase())
            .join(''),
          user: this.auth.currentUser._id,
          item: `RETURN FRAME TO INVENTORY`,
          date: new Date().toISOString(),
        };

        return this.updateOrders(orderId, logItem);
      }
    );

    combineLatest(updates).subscribe(() => {
      this.refreshOrders.emit(true);
    });
  }

  getOrderUpdates(orderId: string, logItem: LogElement) {
    return this.orderService.getOrderById(orderId).pipe(
      map((res) => {
        const { images, log, products, todo, status, ...rest } = res;
        /* Add the new log item to existing log */
        /* Remove products from vendor order */
        /* Remove returned status from each product */
        const productsUpdates = products.map((product) => {
          const status = null;
          const dateUpdated = new Date().toISOString();
          const quantity = !product.quantity ? 1 : product.quantity;
          return this.updateProductStatus(product.product._id, {
            status,
            dateUpdated,
            quantity,
          });
        });

        const updates: Partial<Order> = {
          products: [],
          todo: null,
          log: [logItem],
          status: 'CANCELLED',
        };

        return {
          order: updates,
          productsUpdates,
        };
      })
    );
  }

  updateOrders(orderId: string, logItem: LogElement) {
    return this.getOrderUpdates(orderId, logItem).pipe(
      concatMap(({ order, productsUpdates }) =>
        this.orderService
          .updateOrderById(orderId, order)
          .pipe(concatMap(() => forkJoin(productsUpdates)))
      )
    );
  }

  updateProductStatus(
    productId: string,
    productUpdates: Partial<Product>
  ): Observable<any> {
    return this.productService.getProduct(productId).pipe(
      map((res) => ({ ...res, ...productUpdates })),
      concatMap((mergedProduct: Product) =>
        this.productService.updateProduct(productId, mergedProduct)
      )
    );
  }
}
