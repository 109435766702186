import { Order } from '@models/order';
import { Product, ProductElement } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder } from '@models/vendor-order';
import LodashGet from 'lodash/get';
export const defaultColumns = [
  'invoiceNumber',
  'date',
  'status',
  'tax',
  'shipping',
  'total',
  'action',
  'log',
  'fill',
  'expand',
];

export const defaultStatus = 'unpaid';

export const vendorColumns = {
  distributor: ['vendor'],
};

export const colSortOrder = {
  select: 0,
  vendor: 1,
  status: 2,
  datePaid: 3,
  invoiceNumber: 4,
  date: 5,
  tax: 6,
  shipping: 7,
  total: 8,
  log: 9,
  fill: 10,
  expand: 11,
};

export const statusColumns = {
  paid: ['datePaid'],
  unpaid: ['select'],
};

export const sortConfig = {
  paid: {
    sortBy: 'datePaid',
    sortDir: 'desc',
  },
  unpaid: {
    sortBy: 'date',
    sortDir: 'desc',
  },
  all: {
    sortBy: 'date',
    sortDir: 'desc',
  },
};

export const orderStatusMap: Record<string, 'paid' | 'unpaid'> = {
  PAID: 'paid',
  RECEIVED: 'unpaid',
  CREDITED: 'unpaid',
  SOLD: 'unpaid',
  RETURNED: 'unpaid',
  UPDATED: 'unpaid',
  all: 'unpaid',
  paid: 'paid',
  unpaid: 'unpaid',
};

export const getColumns = (vendor: any, status: string): Array<string> => {
  return [
    ...(statusColumns[status] || []),
    ...(vendor?.distributor ? ['vendor'] : []),
    ...defaultColumns,
  ].sort((a, b) => (colSortOrder[a] < colSortOrder[b] ? -1 : 1));
};

export const getSort = (status: string) => {
  return sortConfig[status];
};

export const getTableConfig = (vendor: any, status: string) => {
  const orderStatus = orderStatusMap[status];
  return {
    columns: getColumns(vendor, orderStatus),
    ...getSort(orderStatus),
  };
};

export const getAggregates = (orders: Array<Order>): Record<string, number> => {
  return orders.reduce(
    (acc: Record<string, number>, o: Order) => ({
      shipping: acc.shipping + (o.shipping ?? 0),
      tax: acc.tax + (o.tax ?? 0),
      total: acc.total + (o.total ?? 0),
    }),
    {}
  );
};

export const getKeyVal = (obj: any, key: string): any => {
  const val = LodashGet(obj, key);
  if (
    (typeof val === 'string' && !val?.length) ||
    !['string', 'number'].includes(typeof val)
  ) {
    return '';
  }
  return `${val}`.toLowerCase();
};

export const searchLog = (l: any, search: string): boolean => {
  const searchKeys = ['initials', 'notes', 'item'];
  return searchKeys.some((key: string) => getKeyVal(l, key)?.includes(search));
};

export const searchLogs = (logs: Array<any>, search: string): Array<any> => {
  return logs.filter((l: any) => searchLog(l, search));
};

export const searchOrder = (o: Order, search: string): boolean => {
  const searchKeys = ['_id', 'invoiceNumber', 'notes', 'status'];
  return searchKeys.some((key: string) => getKeyVal(o, key)?.includes(search));
};

export const searchOrders = (
  orders: Array<Order>,
  search: string
): Array<Order> => {
  return orders.filter((o: Order) => searchOrder(o, search));
};

export const searchProduct = (o: Product, search: string): boolean => {
  const searchKeys = ['_id', 'model', 'color'];
  return searchKeys.some((key: string) => getKeyVal(o, key)?.includes(search));
};

export const searchProducts = (
  products: Array<ProductElement>,
  search: string
): boolean => {
  return products.some((p: ProductElement) => searchProduct(p.product, search));
};

export const searchVendor = (v: Vendor | string, search: string): boolean => {
  if (typeof v === 'string') {
    return `${v}`?.toLowerCase()?.includes(search);
  }
  const searchKeys = ['_id', 'name', 'returnNotes'];
  return searchKeys.some((key: string) => getKeyVal(v, key)?.includes(search));
};

export const searchVendor2 = (v: Vendor, search: string): boolean => {
  if (!v) {
    return false;
  }
  const searchKeys = ['_id', 'accountStatus', 'name', 'returnNotes'];
  return searchKeys.some((key: string) => getKeyVal(v, key)?.includes(search));
};

export const searchVendorOrder = (
  vo: VendorOrder,
  search: string
): VendorOrder | undefined => {
  const searchKeys = [
    '_id',
    'invoiceNumber',
    'trackingNumber',
    'tax',
    'total',
    'shipping',
    'status',
  ];
  const orderMatch = searchKeys.some((key: string) =>
    getKeyVal(vo, key)?.includes(search)
  );
  const orderProductsMatch = searchProducts(vo.products, search);
  const orderVendorMatch =
    searchVendor(vo.vendor, search) ||
    searchVendor(vo.vendor?.[0], search) ||
    searchVendor(vo.vendor2, search);
  const orderVendor2Match = searchVendor2(vo.vendor2, search);
  const match =
    orderMatch || orderProductsMatch || orderVendorMatch || orderVendor2Match;
  /* If the search string matches the vendorOrder, vendor, vendor2 or products return all vendor order properties */
  if (match) {
    return vo;
  }
  /* If the search string matches some orders return vendorOrder with those orders with updated totals */
  const ordersMatched = searchOrders(vo.orders, search);
  const { shipping, tax, total } = getAggregates(vo.orders);
  if (ordersMatched?.length) {
    return {
      ...vo,
      orders: ordersMatched,
      shipping,
      tax,
      total,
    };
  }
  return undefined;
};
