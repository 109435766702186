import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { Product, ProductQuery } from '@models/product';
import { LogElement } from '@models/order';
import { ApiResponse } from '@classes/api-response';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private base_url = environment.base_url + '/product';

  constructor(public http: HttpClient, private auth: AuthService) { }

  queryVendorProducts(vendorId: string, query: String): Observable<Product[]> {
    if (query) {
      var store = this.auth.getStore();
      return this.http.get(`${this.base_url}/query/${query}/vendor/${vendorId}/store/${store}`).pipe(map( (res: ApiResponse<Product[]>) => { return res.products} ));
    } else {
      return this.getVendorProducts(vendorId);
    }
  }

  queryVendorProductsField(vendorId: string, query: ProductQuery, queryField: string): Observable<Object> {
    //if (queryModel || queryColor || queryType || querySize) {
      var store = this.auth.getStore();
      let body = JSON.stringify(query);
      return this.http.post(`${this.base_url}/field/${queryField}/vendor/${vendorId}/store/${store}`, body).pipe(map( (res: ApiResponse<Object>) => { return res.products; } ));
    //} else {
    //  return this.getVendorProducts(vendorId);
    //}
  }

  queryVendorProductsFields(vendorId: string, query: ProductQuery): Observable<Object> {
    var store = this.auth.getStore();
    let body = JSON.stringify(query);
    return this.http.post(`${this.base_url}/vendor/${vendorId}/store/${store}`, body).pipe(map( (res: ApiResponse<Object>) => { return res.products} ));
  }

  priceCheck(vendorId: string, query: ProductQuery): Observable<Object> {
    var store = this.auth.getStore();
    let body = JSON.stringify(query);
    return this.http.post(`${this.base_url}/priceCheck/vendor/${vendorId}/store/${store}`, body).pipe(map( (res: ApiResponse<Object>) => { return res.products} ));
  }

  getVendorProducts(vendorId: string): Observable<Product[]> {
    return this.http.get(`${this.base_url}/vendor/${vendorId}`).pipe(map( (res: ApiResponse<Product[]>) => { return res.products; }));
  }

  getAllProducts(): Observable<Product[]> {
    return this.http.get(`${this.base_url}`).pipe(map( (res: ApiResponse<Product[]>) => { return res.products; }));
  }

  addProduct(product: Product): Observable<any> {
    let body = JSON.stringify(product);
    return this.http.post(`${this.base_url}`, body).pipe(map( (res: ApiResponse<any>) => { return res; }));
  }

  getProduct(productId: any): Observable<Product> {
    return this.http.get(`${this.base_url}/${productId}`).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  getDuplicateProducts(): Observable<any> {
    var store = this.auth.getStore();
    return this.http.get(`${this.base_url}/duplicates/store/${store}`).pipe(map( (res: ApiResponse<any>) => { return res.products; }));
  }

  getDuplicateProductsSize(): Observable<any> {
    var store = this.auth.getStore();
    return this.http.get(`${this.base_url}/duplicatesSize/store/${store}`).pipe(map( (res: ApiResponse<any>) => { return res.products; }));
  }

  fixDuplicateProducts(productId: any, deleteIds: any[], qty: any): Observable<Product> {
    let updateObject: any = {
      'keep': productId,
      'delete': deleteIds,
      'quantity': qty,
    }
    let body = JSON.stringify(updateObject);
    return this.http.put(`${this.base_url}/fixDuplicates`, body).pipe(map( (res: ApiResponse<Product>) => { return res.products; }));
  }

  fixDuplicateProductsSize(productId: any, deleteIds: any[], qty: any, size: any): Observable<Product> {
    let updateObject: any = {
      'keep': productId,
      'keepSize': size,
      'delete': deleteIds,
      'quantity': qty,
    }
    let body = JSON.stringify(updateObject);
    return this.http.put(`${this.base_url}/fixDuplicatesSize`, body).pipe(map( (res: ApiResponse<Product>) => { return res.products; }));
  }

  updateProduct(productId: any, product: Product): Observable<ApiResponse<Product>> {
    let body = JSON.stringify(product);
    return this.http.put(`${this.base_url}/${productId}`, body).pipe(map( (res: ApiResponse<Product>) => { return res; }));
  }

  incrementProductInventoryQuantity(product: Product, quantity: any): Observable<ApiResponse<Product>> {
    let body = JSON.stringify(product);
    return this.http.put(`${this.base_url}/increment/${quantity}`, body).pipe(map( (res: ApiResponse<Product>) => { return res; }));
  }

  decrementSpecialOrderProductInventoryQuantity(product: Product, quantity: any): Observable<ApiResponse<Product>> {
    let body = JSON.stringify(product);
    return this.http.put(`${this.base_url}/decrementSpecialOrder/${quantity}`, body).pipe(map( (res: ApiResponse<Product>) => { return res; }));
  }

  addVendorOrderToProduct(productId: any, vendorOrderId: any): Observable<Product> {
    return this.http.put(`${this.base_url}/${productId}/addVendorOrderToProduct/${vendorOrderId}`, {}).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  setQuantity(productId: any, quantity: any): Observable<Product> {
    return this.http.put(`${this.base_url}/${productId}/setQuantity/${quantity}`, {}).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  setStatus(productId: any, status: any): Observable<Product> {
    return this.http.put(`${this.base_url}/${productId}/setStatus/${status}`, {}).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  setCost(productId: any, cost: any): Observable<Product> {
    return this.http.put(`${this.base_url}/${productId}/setCost/${cost}`, {}).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  setRetail(productId: any, retail: any): Observable<Product> {
    return this.http.put(`${this.base_url}/${productId}/setRetail/${retail}`, {}).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  deleteProduct(productId: any): Observable<Product> {
    return this.http.delete(`${this.base_url}/${productId}`).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  addImage(productId: any, imageId: any): Observable<Product> {
    let body = JSON.stringify({imageId: imageId});
    return this.http.post(`${this.base_url}/${productId}/image`, body).pipe(map( (res) => res as Product));
  }

  deleteImage(productId: any, imageId: any): Observable<any> {
    return this.http.delete(`${this.base_url}/${productId}/image/${imageId}`).pipe(map( (res) => res));
  }

  updateImages(productId: any, images: any): Observable<Product> {
    let body = JSON.stringify(images);
    return this.http.put(`${this.base_url}/${productId}/images`, body).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  addLog(productId: any, log: any): Observable<Product> {
    let body = JSON.stringify(log);
    return this.http.put(`${this.base_url}/${productId}/images`, body).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  borrowProduct(productId: string, borrowingStore: string, quantity: number, store: any, log: LogElement): Observable<Product> {
    let body = JSON.stringify({
      borrow: {
        store: borrowingStore,
        quantity: quantity,
        date: Date.now()
      },
      log: log
    });
    return this.http.put(`${this.base_url}/borrow/${productId}/store/${store}`, body).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  returnBorrowedProduct(productId: string, borrowingStore: string, quantity: number, store: any, log: LogElement): Observable<Product> {
    let body = JSON.stringify({
      borrow: {
        borrowingStore: borrowingStore,
        quantity: quantity,
        date: Date.now()
      },
      log: log
    });
    return this.http.put(`${this.base_url}/borrow/return/${productId}/store/${store}`, body).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

  soldBorrowedProduct(productId: string, borrowingStore: string, quantity: number, store: any, log: LogElement): Observable<Product> {
    let body = JSON.stringify({
      store: store,
      borrowingStore: borrowingStore,
      log: log
    });
    return this.http.put(`${this.base_url}/borrow/sold/${productId}/store/${store}`, body).pipe(map( (res: ApiResponse<Product>) => { return res.product; }));
  }

}
