import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { VendorService } from '@services/vendor.service';
import {
  catchError,
  combineLatest,
  concat,
  concatMap,
  debounceTime,
  EMPTY,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { OrdersData } from 'src/app/v2/common/sidebar/sidebar.component';
import { sortTable } from 'src/app/v2/shared/utils';

@Component({
  selector: 'app-payables-v2',
  templateUrl: './payables.component.html',
  styleUrls: ['./payables.component.scss'],
})
export class PayablesComponent implements OnInit {
  ordersCount: Array<OrdersData> = [];
  filteredOrdersCount: Array<OrdersData> = [];
  isToggled = false;
  unpaidOrders: number = 0;
  pageSizes: Array<number>;
  defaultPageSizes: Array<number> = [10, 20, 25];
  unsubscribe$ = new Subject<void>();
  searchControl: FormControl;
  showAll: boolean;
  defaultSort: Sort = { active: 'name', direction: 'asc' };
  displayedColumns: string[] = ['name', 'unpaid', 'paid'];
  sort: Sort;
  dataSource: MatTableDataSource<OrdersData>;
  @ViewChild(MatTable) table: MatTable<OrdersData>;

  constructor(
    private readonly aS: AlertService,
    private readonly vS: VendorService,
    public vendorOrderService: VendorOrderService,
    public cdRef: ChangeDetectorRef,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.sort = this.defaultSort;
    this.searchControl = new FormControl(null);
    this.searchControl.valueChanges
      .pipe(debounceTime(2000), takeUntil(this.unsubscribe$))
      .subscribe((searchText: string) => this.searchDataSource(searchText));
    this.getVendorOrders();
  }

  getVendorOrders() {
    this.aS.loading.next({ isLoading: true });
    this.vendorOrderService
      .getDistributorVendorOrderCounts('all')
      .pipe(
        take(1),
        tap((ordersData: any) => {
          const data: Record<
            string,
            { name: string; paid: number; unpaid: number }
          > = ordersData.vendorOrderCounts.reduce(
            (acc: Record<string, Partial<OrdersData>>, curr: any) => {
              this.unpaidOrders += curr.unpaid;
              if (!curr.vendor?._id) {
                return acc;
              }
              return {
                ...acc,
                [curr.vendor._id]: {
                  name: curr.vendor.name,
                  paid: acc[curr.vendor.name]?.paid ?? curr.paid,
                  unpaid: acc[curr.vendor.name]?.unpaid ?? curr.unpaid,
                  distributor: curr.vendor.distributor ?? false,
                  vendorIds: curr.vendor.distributorVendors,
                },
              };
            },
            {}
          );
          this.ordersCount = Object.entries(data).map(([id, value]) => ({
            vendorId: id,
            ...value,
          }));
          this.filteredOrdersCount = [...this.ordersCount].filter(
            (order) => this.showAll || (!this.showAll && order.unpaid > 0)
          );
          this.pageSizes = Array.from(
            new Set(
              this.defaultPageSizes
                .filter((size) => size <= this.filteredOrdersCount.length)
                .concat([this.filteredOrdersCount.length])
            )
          );
        }),
        concatMap(() => this.getDistributorVendors()),
        catchError(() => {
          this.aS.loading.next({ isLoading: false });
          return EMPTY;
        })
      )
      .subscribe(() => this.aS.loading.next({ isLoading: false }));
  }

  setDistributorVendors(vendorNames: Record<string, string>) {
    this.ordersCount
      .filter((order) => order.distributor)
      .forEach((vendor) => {
        vendor.vendorNames = Object.entries(vendorNames ?? {})
          .filter(([_id, name]) => vendor.vendorIds?.includes(_id))
          .map(([_id, name]) => name);
      });
  }

  getDistributorVendors() {
    const vendorIds = this.ordersCount.reduce(
      (acc: Array<string>, curr: OrdersData) => {
        if (!curr.distributor) {
          return acc;
        }
        return [...acc, ...curr.vendorIds];
      },
      []
    );

    return this.getDistributorVendorNames(vendorIds).pipe(
      tap(() => this.setDataSource())
    );
  }

  getDistributorVendorNames(vendorIds: Array<string>) {
    return this.vS.getVendorNames(vendorIds).pipe(
      take(1),
      tap((res) => this.setDistributorVendors(res))
    );
  }

  searchDataSource(vendor: string) {
    this.showAll = true;
    this.filteredOrdersCount = this.ordersCount.filter(
      (order) =>
        (order.name?.toLowerCase().includes(vendor.toLowerCase()) ||
          order.vendorNames?.some(
            (name) =>
              name?.length && name.toLowerCase().includes(vendor.toLowerCase())
          )) &&
        (this.showAll || (!this.showAll && order.unpaid > 0))
    );
    this.setDataSource();
  }

  setDataSource() {
    if (this.table) {
      this.dataSource = new MatTableDataSource(this.filteredOrdersCount);
      this.cdRef.detectChanges();
    }
  }

  sortData(sort?: Sort) {
    this.sort = sort ?? this.defaultSort;
    const ordersCount = this.ordersCount.filter(
      (order) => this.showAll || (!this.showAll && order.unpaid > 0)
    );
    this.filteredOrdersCount = sortTable(this.sort, ordersCount);
    this.setDataSource();
  }

  viewVendorOrders(vendorId: string, isDistributor?: boolean) {
    const routeConfig = isDistributor
      ? ['/v2/payables/distributor', vendorId]
      : ['/v2/payables/vendor', vendorId];
    this.router.navigate(routeConfig);
  }

  viewType() {
    const ordersCount = this.ordersCount.filter(
      (order) => this.showAll || (!this.showAll && order.unpaid > 0)
    );
    this.filteredOrdersCount = sortTable(this.sort, ordersCount);
    this.setDataSource();
  }
}
