import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayableSelectionService {
  private selectedPayables: Record<string, Record<string, boolean>> = {};
  private selectedInvoices = new Subject<
    Record<string, Record<string, boolean>>
  >();
  selectedInvoices$ = this.selectedInvoices.asObservable();

  get selectedOrders() {
    return this.selectedPayables;
  }

  clearSelectedInvoices(vendorId: string) {
    const { [vendorId ?? '0']: _, ...rest } = this.selectedPayables;
    this.selectedPayables = rest;
    this.selectedInvoices.next(this.selectedPayables);
  }

  setSelectedInvoices(
    vendorId: string,
    selectedInvoices: Record<string, boolean>
  ) {
    this.selectedPayables = {
      ...this.selectedPayables,
      [vendorId]: selectedInvoices,
    };
    this.selectedInvoices.next(this.selectedPayables);
  }
}
