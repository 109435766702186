import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@models/store';
import { ApiResponse } from '@classes/api-response';
import { Config } from '@classes/config';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
	private appConfig: Config = new Config();
  private base_url = environment.base_url + '/store';

  constructor(private http: HttpClient) { }

	loadAppConfig() {
		return this.http.get(environment.base_url + '/store').pipe(map((res: ApiResponse<Store[]>) => {
			this.appConfig.stores = res.stores;
		}))
		.toPromise();
	}

	// loadAppConfig() {
	// 			console.log('getting stores in appConfigService')
	// 	return this.http.get(environment.base_url + '/store')
	// 		.toPromise()
	// 		.then((res: ApiResponse<Store[]>) => {
	// 			this.appConfig.stores = res.stores;
	// 			console.log('got stores in appConfigService')
	// 	});
	// }

	getConfig() {
    return this.appConfig;
  }
}