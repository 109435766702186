import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { ApiResponse } from '@classes/api-response';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private base_url = environment.base_url + '/report';

  constructor(public http: HttpClient, private auth: AuthService) { }

  getTaxReport(startDate: Date, endDate: Date): Observable<any> {
  	let body = JSON.stringify({
      startDate: startDate,
      endDate: endDate
    });
    var store = this.auth.getStore();
  	return this.http.put(`${this.base_url}/tax/${store}`, body).pipe(map( (res: ApiResponse<any[]>) => { return res } ));
  }

  // getCustomersReport(startDate: Date, endDate: Date): Observable<any> {
  getCustomersReport(): Observable<any> {
    let body = JSON.stringify({
      // startDate: startDate,
      // endDate: endDate
    });
    var store = this.auth.getStore();
    return this.http.put(`${this.base_url}/customers/${store}`, body).pipe(map( (res: ApiResponse<any[]>) => { return res } ));
  }

  getCustomerEmailsReport(): Observable<any> {
    let body = JSON.stringify({
      // startDate: startDate,
      // endDate: endDate
    });
    console.log('TEST2')
    var store = this.auth.getStore();
    return this.http.put(`${this.base_url}/customer-emails/${store}`, body).pipe(map( (res: ApiResponse<any[]>) => { return res } ));
  }

  getCustomerExpiredRxReport(): Observable<any> {
    let body = JSON.stringify({
      // startDate: startDate,
      // endDate: endDate
    });
    console.log('TEST')
    var store = this.auth.getStore();
    return this.http.put(`${this.base_url}/customer-expired-rx/${store}`, body).pipe(map( (res: ApiResponse<any[]>) => { return res } ));
  }
}
