
<mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>
	<ng-container matColumnDef="count">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 75px"> COUNT </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex; " fxFlex="0 0 75px">
    	<span>{{product.count}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="images">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 185px"> IMAGE </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex; " fxFlex="1 0 185px">

    	<!-- <app-images
				[images]="product.images" 
				[edit]="false" 
				[delete]="false" 
				[upload]="false" 
				[productId]="product._id">
			</app-images> -->

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="model">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 10%"> MODEL </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex; let row; let even=even; let odd=odd;" fxFlex="1 0 10%">
    	<span>{{product._id.model}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="color">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 2.5%"> COLOR </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="1 0 2.5%">
    	<span>{{product._id.color}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="size">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 75px"> SIZE </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 75px">
    	<span>{{product._id.size}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 65px"> STATUS </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 65px" style="padding-left:0px;min-width:calc(100%-32px);" [ngClass]="product._id.status" >

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 75px"> TYPE </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 75px">
    	<span>{{product._id.type}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cost">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> COST </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 60px">
    	<span>{{ product.value.cost | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="priceLink">
    <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 35px">
      <mat-icon *ngIf="product.value.pricingFormula!='c'">link_outline</mat-icon>
      <mat-icon *ngIf="product.value.pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="retail">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> RETAIL </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 60px">
    	<span>{{ product.value.retail | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="store">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> STORE </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 10%">
    	<span>{{product.value.store.storeName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> QTY. </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px">
    	<span>{{product.value.quantity}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="orderDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 10%"> ORDER DATE </mat-header-cell>
    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 10%">
    	<span>{{ product.orderDate | date:'M/d/yyyy': 'GMT' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let product; let i=dataIndex;">
      <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="product!=expandedElement" [class.mat-elevation-z8]="product==expandedElement" [@detailExpand]="product == expandedElement ? 'expanded' : 'collapsed'">

				<div 
				    fxLayout="column"
				    fxLayoutAlign="space-between"
				    fxLayoutGap="10px"
				    fxLayoutGap.xs="0"
				    style="width:100%">
      		<ng-container *ngFor="let id of product.uniqueIds; let j=index">
	      		<div fxFlex>
	      			<app-duplicate-products-history *ngIf="product==expandedElement" [product]="id" style="width:100%;"></app-duplicate-products-history>
					  </div>

					  <div fxFlex>
					  	<div 
							    fxLayout="row"
							    fxLayoutAlign="start center"
							    fxLayoutGap="10px"
							    fxLayoutGap.xs="0"
							    style="width:50%">
			      		<div fxFlex><h3>KEEP QTY</h3></div>
		      			<div fxFlex><button mat-raised-button (click)="keep(product,id,0,i,j)">0</button></div>
		      			<div fxFlex><button mat-raised-button (click)="keep(product,id,1,i,j)">1</button></div>
		      			<div fxFlex><button mat-raised-button (click)="keep(product,id,2,i,j)">2</button></div>
		      			<div fxFlex><button mat-raised-button (click)="keep(product,id,3,i,j)">3</button></div>
		      			<div fxFlex><button mat-raised-button (click)="keep(product,id,4,i,j)">4</button></div>
		      			<div fxFlex><button mat-raised-button (click)="keep(product,id,5,i,j)">5</button></div>
							</div>
					  </div>
					</ng-container>

				  <div fxFlex> </div>
				</div>

      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row 
  	*matHeaderRowDef="displayedColumns" 
  	fxLayout="row" 
  	fxLayoutAlign="none center"></mat-header-row>
  <mat-row 
  	*matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
  	fxLayout="row" 
  	fxLayoutAlign="none center" 
  	(click)="clickRow($event,row,i)" 
  	(dblclick)="clickRow($event,row,i)" 
  	[ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
  	color="warn"
  	></mat-row>
  <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
    [class.example-detail-row-expanded]="expandedElement === element"
    [class.example-detail-row-collapsed]="expandedElement !== element"
    style="overflow: hidden"
    fxLayoutAlign="start center">
  </mat-row>
</mat-table>

