<!-- <div class="container" style="width:100%" [formGroup]="orderForm" *ngIf="dataSource.data"> -->
<div class="container modify-table" style="width:100%" *ngIf="dataSource.data">

  <div *ngIf="!selection.isEmpty()" class="modify-button">
              <button mat-raised-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation?.();">
                <mat-icon>more_vert</mat-icon>
                <span>MODIFY {{ framesSelected }}</span>
                <span *ngIf="selection.selected.length != 1"> ITEMS</span>
                <span *ngIf="selection.selected.length == 1"> ITEM</span>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item type="button" (click)="removeFramesFromVendorOrder()" style="margin-left:10px;">RETURN TO INVENTORY</button>
              </mat-menu>
            </div>
	<mat-table #table [dataSource]="dataSource" matSort matSortActive="model" matSortDirection="asc" class="mat-elevation-z2">
	<!-- <mat-table #table [dataSource]="dataSource" matSort matSortActive="model" matSortDirection="asc" class="mat-elevation-z2" formArrayName="orders"> -->
	  <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px" style="padding-left:0px !important;">
      	<mat-checkbox (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px">
      	<mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelect(order) : null"
          [checked]="selection.isSelected(order)"
          [aria-label]="checkboxLabel(order,i)">
				</mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> IMAGE </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%">
        <app-image [image]="order.value.products[0].product.images[0]" [tmp]="false" *ngIf="order.value.products[0].product.images && order.value.products[0].product.images.length>0" type="product"></app-image>
      </mat-cell>
    </ng-container>

	  <ng-container matColumnDef="model">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 15%"> MODEL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 15%" [formGroup]="order">
	    <!-- <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 15%" [formGroupName]="i"> -->
	    	<span>{{order.value.products[0].product.model}}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="color">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 15%"> COLOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 15%" [formGroup]="order">
	    <!-- <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 15%" [formGroupName]="i"> -->
	    	<span>{{order.value.products[0].product.color}}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="size">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> SIZE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px" [formGroup]="order">
	    <!-- <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px" [formGroupName]="i"> -->
	    	<span> {{order.value.products[0].product.size}} </span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="type">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> TYPE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 60px" [formGroup]="order">
	    <!-- <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 60px" [formGroupName]="i"> -->
	    	<span>{{order.value.products[0].type}}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="status">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px" [ngClass]="order.value.products[0].product.status">
	      <span>&nbsp;</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="cost">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 1 60px"> COST </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 1 60px" [formGroup]="order">
	      <span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].cost | currency : 'USD' : 'symbol' : '1.0' }}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
			      <mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
			        <input #formRow matInput placeholder="COST" type="text" formControlName="cost" (keyup)="changeCost(order)">
			      </mat-form-field>
			    </ng-container>
		    </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="priceLink">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 35px" [formGroup]="order">
	    <!-- <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 35px" [formGroupName]="i"> -->
	      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula!='c'">link_outline</mat-icon>
	      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="retail">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 1 60px"> RETAIL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 1 60px" [formGroup]="order">
	    	<span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].retail | currency : 'USD' : 'symbol' : '1.0' }}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
			    	<mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
			        <!-- <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (keyup)="changeRetail($event,order,orderIndex,i)"> -->
			        <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (keyup)="changeRetail(order)">
			      </mat-form-field>
			    </ng-container>
			  </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="store">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 5%"> STORE </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 5%" [formGroup]="order">
      <!-- <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 5%" [formGroupName]="i"> -->
				<span *ngIf="orderId!=order.value._id"> {{ order.value.products[0].store.storeName }} </span>
      	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
						<mat-form-field *ngIf="orderId==order.value._id" style="width:90%" floatLabel="never">
		          <mat-select placeholder="STORE" formControlName="store" (click)="clickField();$event.stopPropagation();">
		            <mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption">{{storeOption.storeName}}</mat-option>
		          </mat-select>
		        </mat-form-field>
		      </ng-container>
		    </ng-container>
      </mat-cell>
    </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 150px"></mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 150px" [formGroup]="order">
	    <!-- <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 150px" [formGroupName]="i"> -->
	    	<mat-form-field style="width:35px !important;flex: 0 1 35px !important;padding-left:10px;" floatLabel="never">
		      <input matInput placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
	      <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
	      <mat-menu #menu="matMenu">
	    	 	<button mat-menu-item type="button" (click)="saveFrame(order)" style="margin-left:10px;" [disabled]="order.value.initials==''">SAVE CHANGES</button>
	        <button mat-menu-item type="button" (click)="onCancelFrame(order)" style="margin-left:10px;" [disabled]="order.value.initials==''">CANCEL ORDER</button>
	      </mat-menu>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="log">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 80px"> LOG </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 80px">
	    	<app-log-menu [order]="order" (clickField)="clickField()" [small]="true"></app-log-menu>
	    </mat-cell>
	  </ng-container>

	  <mat-header-row
	  	*matHeaderRowDef="displayedColumns"
	  	fxLayout="row"
	  	fxLayoutAlign="start stretch">
	  </mat-header-row>
	  <mat-row
	  	*matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;"
	  	fxLayout="row"
	  	fxLayoutAlign="start stretch"
	  	[ngClass]="{rowEven:even, rowOdd:odd}"
	  	style="border-left:1px solid #dfdfdf !important;"
	  	(click)="clickOrder($event, row, i)">
	  </mat-row>
	</mat-table>

  <app-returns-submit *ngIf="selection.hasValue()" [selected]="selection.selected" [(orderForm)]="orderForm" [orderIndex]="orderIndex" (saveIncomingOrder)="saveIncomingOrder($event)" (updateTotal)="calculateTotal()" style="width:100%;"></app-returns-submit>

</div>
