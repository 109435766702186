import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-dialog',
  templateUrl: './unsaved.component.html',
  styleUrls: ['./unsaved.component.css']
})
export class UnsavedComponent {

  constructor(
    public dialogRef: MatDialogRef<UnsavedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	cancel() {
		this.dialogRef.close(false);
	}

	ok() {
		this.dialogRef.close(true);
	}
}
