import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Vendor } from '@models/vendor';
import { Observable, shareReplay, Subject, tap } from 'rxjs';

export interface PrintDetails {
  vendor: Vendor | null;
  invoiceIds: string[];
  groupId?: string;
}
@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;

  private printInvoices = new Subject<PrintDetails>();
  private printInvoices$ = this.printInvoices.asObservable();

  constructor(private router: Router) {}

  onPrintInvoices(): Observable<PrintDetails> {
    return this.printInvoices$.pipe(
      tap((details: PrintDetails) => console.log(details)),
      shareReplay(1)
    );
  }

  printVendorInvoices(
    invoiceIds: string[],
    vendor: Vendor = null,
    groupId?: string
  ) {
    this.printInvoices.next({ vendor, invoiceIds, groupId });
  }

  printDocument(documentName: string, documentData: string[]) {
    this.isPrinting = true;
    this.router.navigate([
      '/',
      {
        outlets: {
          print: ['print', documentName, documentData.join()],
        },
      },
    ]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null } }]);
      this.isPrinting = false;
    });
  }
}
