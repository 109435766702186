import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Patient } from '@models/patient';
import { PatientService } from '@services/patient.service';
import { AuthService } from '@services/auth.service';

import { FileUploadModule, FileUploader } from 'ng2-file-upload';
import { environment } from '@environments/environment';

//const URL = environment.base_url + '/api/patient/importCSV';
const URL = environment.base_url + '/api/patient/updateCSV';

@Component({
  selector: 'app-import-customer',
  providers: [PatientService],
  templateUrl: './import-customer.component.html',
  styleUrls: ['./import-customer.component.css']
})
export class ImportCustomerComponent implements OnInit {
	patient: Patient;
	public message:String;
	public csvFile:String;
	public uploader:FileUploader;

  constructor(private patientService: PatientService, private route: ActivatedRoute, private router: Router, private auth: AuthService) { }

	fileChanged(event) {
		console.log("IN");
		console.log(event);
		console.log(event.target.files[0]);
	}
		  
  ngOnInit() {
	this.uploader = new FileUploader({url: URL+'/store/'+this.auth.getStore(), itemAlias: 'csv'});
  	this.uploader.authToken = `Bearer ${this.auth.getToken()}`;
	this.uploader.onAfterAddingFile = (file)=> {
      file.withCredentials = false;
      this.uploader.uploadAll();
    };

	this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
		console.log("CSVUpload:uploaded:", item, status, response);
		this.message = JSON.parse(response);
	};
  }

}
