import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { OrderService } from '@services/order.service';
import { ActivatedRoute } from '@angular/router';
import { VendorsBaseComponent } from '@components/admin/managed-board/base/vendors-base/vendors-base.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-managed-board-awaiting-frames',
  templateUrl: './../base/vendors-base/vendors-base.component.html',
  styleUrls: ['./../base/vendors-base/vendors-base.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ManagedBoardAwaitingFramesComponent extends VendorsBaseComponent implements OnInit {
  @Input() public section: string = 'awaiting frames';

  constructor(public orderService: OrderService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute) {
    super(orderService, cdRef, route);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}