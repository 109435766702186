<div class="container"
  fxLayout="column"
  fxLayoutAlign="left"
  fxLayoutGap="10px"
  style="overflow:hidden;">


	<form [formGroup]="vendorSearchForm" enctype="multipart/form-data" onsubmit="event.preventDefault(); return false;" autocomplete="off">
	  <div>
	  	<div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px" [ngClass]="{'differentStoreSelected': isDifferentStoreSelected()}">
		  	<div fxFlex="80%">
					<mat-form-field>
					 	<input type="text" placeholder="SEARCH VENDORS" matInput [formControl]="vendorCtrl" [matAutocomplete]="auto">
					  <mat-autocomplete #auto="matAutocomplete">
					    <mat-option *ngFor="let vendor of filteredVendors | async" [value]="vendor.name" (onSelectionChange)="selectVendor($event,vendor)" [ngClass]="{'differentStoreName': isDifferentStore(vendor.store)}">
					      {{ vendor.name }} <span *ngIf="isDifferentStore(vendor.store)">({{ storeService.getStoreObjectFromId(vendor.store)?.storeName }})</span>
					    </mat-option>
					    <mat-option (onSelectionChange)="newVendor()">
				        <mat-icon>add_box_outline</mat-icon>NEW VENDOR
				      </mat-option>
					  </mat-autocomplete>
					</mat-form-field>
			  </div>
			  <div fxFlex="20%">

			  	<mat-form-field style="width:300px;">
				  	<mat-label>VIEW VENDOR AT STORE: </mat-label>
					  <mat-select placeholder="VIEW ANOTHER STORE" [formControl]="selectedStoreControl" [(value)]="selectedStore._id">
							<mat-option *ngFor="let store of allStores | async" [value]="store._id">
							  {{ store.storeName }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
		  </div>
	  </div>
	</form>
	<form [formGroup]="vendorForm" enctype="multipart/form-data" onsubmit="event.preventDefault(); return false;" autocomplete="off">
					
			<h3 *ngIf="selectedVendorObj?.borrowed">BORROWING FROM {{ storeService.getStoreObjectFromId(selectedVendorObj.store).storeName }} </h3>

			<mat-accordion multi *ngIf="selectedVendorObj">
				<mat-expansion-panel [expanded]="true">
			    <mat-expansion-panel-header [style.background-color]="currentStore?.headerColor">
			      <mat-panel-title>
        			GENERAL INFORMATION
			      </mat-panel-title>
			    </mat-expansion-panel-header>

	      	<mat-grid-list cols="8" rowHeight="60px" gutterSize="5px">

					  <mat-grid-tile colspan="2">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Sales Rep Name:</mat-label>
						    <input matInput placeholder="" formControlName="repName">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Vendor Name:</mat-label>
						    <input matInput placeholder="" formControlName="name">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Account Number:</mat-label>
		     				<input matInput placeholder="" formControlName="accountNumber" autocomplete="off">
						  </mat-form-field>
					  </mat-grid-tile>

						<mat-grid-tile colspan="2">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Company Website:</mat-label>
						    <input matInput placeholder="" formControlName="website">
						    <a mat-icon-button matSuffix [href]="'http://'+vendorForm.value.website" target="_blank" style="margin-left:10px;">
									<mat-icon *ngIf="vendorForm.value.website">open_in_browser_outline</mat-icon>
								</a>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="2" rowspan="3">
						  <div class="container" fxLayout="row" fxLayoutGap="10px" style="min-height:30px;">
								<div *ngIf="selectedVendorImages" style="flex:1;">
								</div>
								<div *ngFor="let image of selectedVendorImages; let i = index" style="position:relative;">
									<mat-icon style="position:absolute;top:-5px;right:-5px;" *ngIf="image.hover" (click)="imageDelete(image, i)">delete_outline</mat-icon>
										<app-image [image]="image" type="product" [vendorId]="selectedVendorObj._id" (imageDelete)="deleteImage(image,$event)"></app-image>
								</div>
								<div *ngIf="selectedVendorImages" style="flex:1;" fxLayoutAlign="end center">
									<input #fileInput id="fileInput" type="file" name="photo" accept="image/*" ng2FileSelect [uploader]="uploader" multiple [hidden]="true"/>
									<label for="fileInput" class="mat-icon-button">
										<mat-icon>add_photo_alternate_outline</mat-icon>
									</label>
								</div>
							</div>
					  </mat-grid-tile>
					  

					  <mat-grid-tile colspan="2">
					  	<mat-form-field appearance="standard" style="width:70%">
					  		<mat-label>Rep Phone Number:</mat-label>
		     				<input matInput placeholder="" formControlName="repPhone" autocomplete="off" (keyup)="phoneType($event,'repPhone')" >
						  </mat-form-field>
						  <mat-form-field appearance="standard" style="margin-left:10px;width:30%;">
					  		<mat-label>Ext:</mat-label>
		     				<input matInput placeholder="" formControlName="repPhoneExt" autocomplete="off">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="2">
					  	<mat-form-field appearance="standard" style="width:70%">
					  		<mat-label>Company Phone:</mat-label>
		     				<input matInput placeholder="" formControlName="phone" autocomplete="off" (keyup)="phoneType($event,'phone')">
						  </mat-form-field>
						  <mat-form-field appearance="standard" style="margin-left:10px;width:30%;">
					  		<mat-label>Ext:</mat-label>
		     				<input matInput placeholder="" formControlName="phoneExt" autocomplete="off">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Username:</mat-label>
		     				<input matInput placeholder="" formControlName="username" autocomplete="off" style="text-transform: none;">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Password:</mat-label>
		     				<input matInput placeholder="" formControlName="password" autocomplete="off" style="text-transform: none;">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="2">
						  <mat-form-field appearance="standard">
					  		<mat-label>Rep Email:</mat-label>
	     					<input matInput placeholder="" formControlName="repEmail" autocomplete="off">
								<a mat-icon-button matSuffix [href]="'mailto:'+vendorForm.value.repEmail" *ngIf="vendorForm.value.repEmail">
									<mat-icon>email_outline</mat-icon>
								</a>
						  </mat-form-field>
					  </mat-grid-tile>

						<mat-grid-tile colspan="2">
						  <mat-form-field appearance="standard">
					  		<mat-label>Company Fax:</mat-label>
		     				<input matInput placeholder="" formControlName="fax" autocomplete="off" (keyup)="phoneType($event,'fax')" >
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="2">
						  <mat-checkbox formControlName="distributor">
					  		<mat-label>Distributor:</mat-label>
							</mat-checkbox>
						  <mat-select placeholder="SELECT" formControlName="distributorVendors" multiple *ngIf="vendorForm.value.distributor" style="margin-left:10px;">
					  		<mat-label>Vendors:</mat-label>
								<mat-option *ngFor="let vendor of filteredVendors | async" [value]="vendor._id">
								  {{ vendor.name }}
								</mat-option>
							</mat-select>
					  </mat-grid-tile>

					  
					  
					</mat-grid-list>

		      <mat-toolbar [style.background-color]="currentStore?.headerColor">
		        <span>ACCOUNT INFORMATION</span>
		      </mat-toolbar>

	      	<mat-grid-list cols="10" rowHeight="60px" gutterSize="10px">
					  <mat-grid-tile colspan="1">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Account Status:</mat-label>
						    <mat-select placeholder="SELECT" formControlName="accountStatus">
									<mat-option *ngFor="let accountStatusOption of accountStatusOptions" [value]="accountStatusOption.value">
									  {{accountStatusOption.viewValue}}
									</mat-option>
								</mat-select>
						  </mat-form-field>
						</mat-grid-tile>
					  <mat-grid-tile colspan="1" *ngIf="vendorForm.value.accountStatus=='Managed Board'">
						  <mat-form-field appearance="standard">
						    <mat-select #statusSelect #formRow placeholder="SELECT" formControlName="defaultManagedBoardStatus" disableRipple>
									<mat-option value="green" class="green2"></mat-option>
									<mat-option value="yellow" class="yellow2"></mat-option>
									<mat-option value="red" class="red2"></mat-option>
									<mat-option value="white" class="white2"></mat-option>
									<mat-option value="black" class="black2"></mat-option>
								</mat-select>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Update Board:</mat-label>
					  		<mat-select placeholder="SELECT" formControlName="updateBoard">
									<mat-option *ngFor="let updateBoardOption of updateBoardOptions" [value]="updateBoardOption.value">
									  {{updateBoardOption.viewValue}}
									</mat-option>
								</mat-select>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
							<mat-form-field appearance="standard">
					  		<mat-label>Order Protocol:</mat-label>
					  		<mat-select placeholder="SELECT" formControlName="orderProtocol">
									<mat-option *ngFor="let orderProtocolOption of orderProtocolOptions" [value]="orderProtocolOption.value">
									  {{orderProtocolOption.viewValue}}
									</mat-option>
								</mat-select>
						  </mat-form-field>
						</mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Shipping Min:</mat-label>
		     				<mat-select placeholder="SELECT" formControlName="shippingMin">
									<mat-option *ngFor="let shippingMinOption of shippingMinOptions" [value]="shippingMinOption.value">
									  {{shippingMinOption.viewValue}}
									</mat-option>
								</mat-select>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Ideal Inventory Qty:</mat-label>
		     				<input matInput placeholder="" formControlName="idealInventory" autocomplete="off">
						  </mat-form-field>
					  </mat-grid-tile>

						<mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Shipping Fee:</mat-label>
		     				<input matInput placeholder="" formControlName="shippingFee" autocomplete="off">
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Pricing (Optical):</mat-label>
					  		<mat-select placeholder="SELECT" formControlName="pricingFormulaOptical">
									<mat-option *ngFor="let pricingFormulaOption of pricingFormulaOptions" [value]="pricingFormulaOption.value">
									  {{pricingFormulaOption.viewValue}}
									</mat-option>
								</mat-select>
						  </mat-form-field>
						</mat-grid-tile>

					  <mat-grid-tile colspan="1">
							<mat-form-field appearance="standard">
					  		<mat-label>(Sun):</mat-label>
					  		<mat-select placeholder="SELECT" formControlName="pricingFormulaSun">
									<mat-option *ngFor="let pricingFormulaOption of pricingFormulaOptions" [value]="pricingFormulaOption.value">
									  {{pricingFormulaOption.viewValue}}
									</mat-option>
								</mat-select>
						  </mat-form-field>
					  </mat-grid-tile>

					  

					  

						<mat-grid-tile colspan="1">
						  <mat-checkbox formControlName="taxBothRxAndSun">
								TAX BOTH
							</mat-checkbox>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
						  <mat-form-field appearance="standard">
					  		<mat-label>Order Info:</mat-label>
		     				<input matInput placeholder="" formControlName="orderInfo" autocomplete="off">
						  </mat-form-field>
					  </mat-grid-tile>

					</mat-grid-list>


		      <mat-toolbar [style.background-color]="currentStore?.headerColor">
		        <span>RETURNS INFORMATION</span>
		      </mat-toolbar>

	      	<mat-grid-list cols="4" rowHeight="120px" gutterSize="20px">
					  <mat-grid-tile colspan="1">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Return Address:</mat-label>
						    <textarea matInput placeholder="" formControlName="returnAddress" rows="5" style="text-transform:none;"></textarea>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Returns Details:</mat-label>
						    <textarea matInput placeholder="" formControlName="returnDetails" rows="5" style="text-transform:none;"></textarea>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Naming Key:</mat-label>
						    <textarea matInput placeholder="" formControlName="namingKey" rows="5" style="text-transform:none;"></textarea>
						  </mat-form-field>
					  </mat-grid-tile>

					  <mat-grid-tile colspan="1">
					  	<mat-form-field appearance="standard">
					  		<mat-label>Notes:</mat-label>
						    <textarea matInput placeholder="" formControlName="returnNotes" rows="5" style="text-transform:none;"></textarea>
						  </mat-form-field>
					  </mat-grid-tile>
					  
					</mat-grid-list>

		     	<div fxLayout="row wrap" fxLayoutAlign="space-between center" style="width:100%;" fxLayoutGap="10px grid">
			     	<div>
			     		<button mat-raised-button (click)="saveVendor(selectedVendorObj)" [disabled]="saveButtonClicked || selectedVendorObj.borrowed || isDifferentStoreSelected()">SAVE</button>
			     	</div>
    				<div style="display:flex;align-items:center;justify-content:center;">
    					<button mat-raised-button (click)="deleteVendor(selectedVendorObj._id)" [disabled]="selectedVendorObj.borrowed">DELETE VENDOR</button>
						</div>
		     	</div>


				</mat-expansion-panel>

				<mat-expansion-panel [expanded]="false">
					<mat-expansion-panel-header [style.background-color]="currentStore?.headerColor">
			      <mat-panel-title>
			        INVOICES
			      </mat-panel-title>
			    </mat-expansion-panel-header>

					<app-vendor-invoices [vendor]="selectedVendorObj._id"></app-vendor-invoices>
				</mat-expansion-panel>

				<mat-expansion-panel [expanded]="true">
  				<mat-expansion-panel-header [style.background-color]="currentStore?.headerColor" style="margin-bottom:5px">
			      <mat-panel-title>
			        INVENTORY
			      </mat-panel-title>
			    </mat-expansion-panel-header>

      		<app-inventory-base [vendor]="selectedVendorObj" [differentStoreSelected]="selectedStore._id != store._id"></app-inventory-base>



			  </mat-expansion-panel>
			</mat-accordion>

	</form>
</div>
