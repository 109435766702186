import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Vendor } from '@models/vendor';
import { VendorOrder } from '@models/vendor-order';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { PrintService } from '@services/print.service';
import { VendorOrderService } from '@services/vendor-order.service';
import ObjectID from 'bson-objectid';
import {
  Subject,
  Observable,
  takeUntil,
  filter,
  map,
  concatMap,
  take,
  tap,
  catchError,
  EMPTY,
  of,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { VendorService } from '@services/vendor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  createInvoiceForm,
  getProductsCost,
  getNumberPrecision,
  getOrderFormUpdates,
  productUpdated,
  getProductsTotalCost,
  recalculateOrderShipping,
  recalculateOrderTax,
  getProductsTax,
  getProductsShipping,
} from '@v2/shared/utils';
import { LogElement } from '@models/order';
import { DecimalPipe } from '@angular/common';
import { Product } from '@models/product';
import { ProductService } from '@services/product.service';

@Component({
  selector: 'app-payable-order',
  templateUrl: './payable-order.component.html',
  styleUrls: ['./payable-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayableOrderComponent implements AfterViewInit, OnDestroy {
  @Input() set vendorOrder(order: unknown) {
    if (order) {
      this.isModal = true;
      this.hideTable = true;
      const { _id, vendor } = order as any;
      this.getData(_id, vendor?.[0]?._id ?? vendor?._id ?? vendor);
    }
  }

  isModal: boolean;
  isCredit: boolean;
  isPaid: boolean;
  isAwaitingCredit: boolean;
  hideTable: boolean;
  distributorId: string;
  distributor: Vendor;
  order: VendorOrder;
  orderRef: VendorOrder;
  vendorId: string;
  vendor: Vendor;
  orderId: string;
  orderForm: FormGroup;
  editMode = false;
  pageSizes: Array<number>;
  cost: number;
  defaultPageSizes: Array<number> = [5, 10, 20, 25];
  displayedColumns: string[] = [
    'select',
    'image',
    'model',
    'color',
    'size',
    'type',
    'tax',
    'shipping',
    'cost',
  ];
  dataSource: MatTableDataSource<Record<string, string | number>>;
  @ViewChild(MatTable) table: MatTable<Record<string, string | number>>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() close = new EventEmitter<void>();

  private unsubscribe$ = new Subject<void>();
  expectedLoss: number = 0;
  expectedLossText: string;
  productsTotal: number = 0;
  productsCost: number = 0;
  productsTax: number = 0;
  productsShipping: number = 0;
  selectedFrames: Record<string, any> = {};
  fourColumns: boolean = true;

  get framesSelected() {
    console.log(this.selectedFrames);
    console.log(Object.values(this.selectedFrames));
    console.log(Object.values(this.selectedFrames).some((v) => !!v));
    return Object.values(this.selectedFrames).some((v) => !!v);
  }

  get allSelected() {
    return (
      this.order.products.length ===
      Object.values(this.selectedFrames).filter((v) => !!v).length
    );
  }

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly voS: VendorOrderService,
    private readonly aS: AuthService,
    private readonly pS: PrintService,
    private readonly alS: AlertService,
    private readonly cd: ChangeDetectorRef,
    private readonly vS: VendorService,
    private readonly pdS: ProductService
  ) {}

  ngAfterViewInit(): void {
    this.alS.loading.next({
      isLoading: true,
    });
    this.onInit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onInit() {
    const orderId = this.route.snapshot.params['orderId'];
    const distributorId = this.route.snapshot.queryParams['distributorId'];
    if (orderId) {
      this.selectedFrames = {};
      this.getData(orderId, distributorId);
    }
  }

  getData(invoice?: string, distributor?: string) {
    this.getIdsFromRoute(invoice, distributor)
      .pipe(
        take(1),
        tap((order) => {
          if (order) {
            if (order) {
              this.isAwaitingCredit = ['RETURNED', 'AWAITING CREDIT'].includes(
                order.status
              );
              this.isCredit = [
                'CREDITED',
                'RETURNED',
                'AWAITING CREDIT',
              ].includes(order.status);
              console.log(this.isAwaitingCredit);
              this.orderRef = {
                ...order,
                shipping: this.isCredit ? 0 : order.shipping,
              };
              this.isPaid = order.status === 'PAID';
              this.updateLoss(order);
              if (
                !['RETURNED', 'AWAITING CREDIT '].includes(order.status) ||
                !order.products?.length
              ) {
                this.displayedColumns.shift();
                this.fourColumns = false;
              }
            }
          }
        })
      )
      .subscribe((res) => {
        if (res) {
          this.formatOrderData(res);
          const events$ = this.router.events;
          this.listen([events$]);
        }
      });
  }

  getIdsFromRoute(invoice?: string, distributor?: string): Observable<any> {
    this.orderId = invoice ?? this.route.snapshot.params['orderId'];
    this.distributorId =
      distributor ?? this.route.snapshot.queryParams['distributorId'];
    this.cd.detectChanges();
    if (this.distributorId) {
      const vendor$ = this.distributor
        ? of(this.distributor)
        : this.vS.getVendor(this.distributorId);
      return vendor$.pipe(
        take(1),
        tap((res) => {
          this.distributor = res;
        }),
        concatMap(() => this.getOrder(this.orderId))
      );
    } else if (this.orderId) {
      return of(this.orderId).pipe(concatMap((id) => this.getOrder(id)));
    }
    return EMPTY;
  }

  getOrder(orderId: string) {
    return this.voS.getVendorOrders('all', [orderId]).pipe(
      tap((res) => {
        if (!res?.vendorOrders?.[0]?.vendorOrders?.[0]) {
          this.router.navigate(['/v2/payables']);
        }
      }),
      map((res) => res?.vendorOrders?.[0]?.vendorOrders?.[0])
    );
  }

  formatOrderData(order: VendorOrder) {
    if (!order) {
      return;
    }
    const { products, proposedCredit } = order;
    order.products = products.map((product) => ({
      ...product,
      shipping: +getNumberPrecision(product.shipping ?? 0),
      tax: +getNumberPrecision(product.tax ?? 0),
      uniqueId: `${new ObjectID()}`,
    }));

    this.order = {
      ...order,
      cost: getProductsCost(order, this.isCredit),
      credit:
        isNaN(order.proposedCredit) || !order.credit
          ? getProductsCost(order)
          : order.proposedCredit,
      shipping: order.shipping ?? 0,
      tax: order.tax ?? 0,
      partialPayment: order.partialPayment ?? 0,
      amountDue: this.isCredit
        ? order.total + (order.partialPayment ?? 0)
        : Math.abs(order.total) - (order.partialPayment ?? 0),
      total: order.total,
    };

    if (this.isAwaitingCredit && !proposedCredit) {
      this.order.proposedCredit =
        this.order.proposedCredit ?? this.getProposedCredit(this.order);
    }

    this.editMode = order.status?.toLowerCase() !== 'paid';
    this.setupForm(this.order);
    this.vendor = order.vendor2 || order.vendor;
    this.setProductsData(order);
  }

  setProductsData(order: VendorOrder) {
    const productsData = order.products?.reduce(
      (acc: Array<Record<string, string | number>>, product: any) => {
        const orderVendorName =
          typeof order.vendor === 'string' ? '' : order.vendor?.name;
        const {
          product: item,
          tax = 0,
          shipping = 0,
          cost = 0,
          uniqueId,
          type,
          _id,
        } = product;
        return [
          ...acc,
          {
            name: item?.vendor?.name ?? orderVendorName,
            model: item?.model,
            color: item?.color,
            size: item?.size,
            cost: this.isCredit ? Math.abs(cost) * -1 : cost,
            retail: item?.retail,
            images: item.images,
            productId: item._id,
            shipping: this.isCredit ? Math.abs(shipping) * -1 : shipping,
            tax: this.isCredit ? Math.abs(tax) * -1 : tax,
            type,
            uniqueId,
            objectId: _id,
          },
        ];
      },
      []
    );
    if (this.table) {
      this.dataSource = new MatTableDataSource(productsData);
      this.cd.detectChanges();
      this.alS.loading.next({ isLoading: false });
    }
  }

  setupForm(order: VendorOrder) {
    if (this.orderForm) {
      this.orderForm.reset();
      this.orderForm.patchValue({
        ...order,
        amountDue: order.total - order.partialPayment ?? 0,
      });
      this.orderForm.markAsPristine();
      this.orderForm.markAsUntouched();
    } else {
      this.orderForm = createInvoiceForm(order, true, this.isCredit);
    }
    this.cd.detectChanges();
  }

  getOrderUpdates(updateStatus?: string, includeProducts?: boolean) {
    return getOrderFormUpdates(
      this.orderRef,
      this.orderForm,
      this.order.products,
      this.aS,
      updateStatus,
      true
    );
  }

  listen(calls: Array<Observable<any>>) {
    calls.forEach((call: Observable<any>) => {
      call
        .pipe(
          takeUntil(this.unsubscribe$),
          filter((event) => event instanceof NavigationEnd),
          concatMap(() => this.getIdsFromRoute())
        )
        .subscribe((res) => this.formatOrderData(res));
    });
  }

  payOrder() {
    const totalControl = this.orderForm.controls.total;
    if (totalControl.dirty && totalControl.value !== this.order.total) {
      this.alS.alert.next({
        heading: 'Unsaved order updates',
        description:
          'Please save changes before paying/printing order to include the latest in the order updates.',
        success: false,
        duration: 3000,
      });
      return;
    }

    this.updateOrder(this.getOrderUpdates('PAID', true))
      .pipe(concatMap(() => this.getOrder(this.orderId)))
      .subscribe((res) => this.formatOrderData(res));
  }

  payPrint() {
    this.printOrder();
    this.payOrder();
  }

  printOrder() {
    this.pS.printVendorInvoices(
      [this.order._id],
      this.vendor ?? this.distributor
    );
  }

  saveOrder() {
    this.updateOrder(this.getOrderUpdates(null, true))
      .pipe(concatMap(() => this.getOrder(this.orderId)))
      .subscribe((res) => this.formatOrderData(res));
  }

  updateOrder(order: Partial<VendorOrder>) {
    this.alS.loading.next({
      isLoading: true,
      loadingMessage: `Updating  invoice ${this.order.invoiceNumber}`,
    });
    return this.voS.updateVendorOrderById(this.order._id, order).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.snackbar.open(
          `Invoice ${
            this.order.invoiceNumber ?? ''
          } has been successfully updated.`,
          'close'
        );
        this.unsubscribe$.next();
        this.getData();
      }),
      catchError(() => {
        this.alS.loading.next({
          isLoading: false,
        });
        this.snackbar.open(
          `Oops! There was an issue while updating the invoice.`,
          'close'
        );
        return EMPTY;
      })
    );
  }

  markInvoiceCredited() {
    this.updateOrder(this.getOrderUpdates('CREDITED', true))
      .pipe(concatMap(() => this.getOrder(this.orderId)))
      .subscribe((res) => {
        this.isAwaitingCredit = false;
        this.cd.detectChanges();
        this.formatOrderData(res);
      });
  }

  addNote(notes: Array<LogElement>) {
    this.updateOrder({ notes }).subscribe();
  }

  updateProduct(uniqueId: string, val: number, field: string) {
    console.log(uniqueId, val, field);
    const product = this.order.products.find(
      (product) => product.uniqueId === uniqueId
    );
    if (product) {
      const value = Math.abs(+val);
      product[field] = value;
      console.log(product[field]);
      this.orderForm.patchValue(
        productUpdated(this.order, field, this.isCredit)
      );
      this.updateLoss();
    }
  }

  viewProduct(productId: string) {
    // console.log(productId);
  }

  viewVendorOrders() {
    const routeConfig = this.distributor
      ? ['/v2/payables/distributor', this.distributor._id]
      : ['/v2/payables/vendor', this.vendor._id];
    this.router.navigate(routeConfig);
  }

  deleteInvoice() {
    this.voS
      .deleteVendorOrder(this.order._id)
      .pipe(
        take(1),
        tap(() => this.viewVendorOrders())
      )
      .subscribe();
  }

  getProposedCredit(order?: VendorOrder) {
    const o = order ?? this.order;
    return o.proposedCredit ?? o.cost - o.shipping;
  }

  updateCost(val: number) {
    if (this.isCredit) {
      const total = val + this.order.shipping + this.order.tax;
      this.orderForm.patchValue({ cost: val, total });
      this.order.total = total;
    } else {
      this.orderForm.patchValue({ cost: val });
    }
    this.order.cost = val;
    this.updateLoss();
  }

  updateTotal(val: number) {
    this.orderForm.patchValue({ total: val });
    this.order.total = val;
    this.updateLoss();
  }

  updateProposedCredit(val: number) {
    this.orderForm.patchValue({ proposedCredit: val });
    this.order.proposedCredit = val;
    this.updateLoss();
  }

  updatePayment(val: number) {
    const amountDue = this.order.total - val;
    const partialPayment = val;
    this.orderForm.patchValue({ amountDue, partialPayment });
    this.order.amountDue = amountDue;
    this.order.partialPayment = partialPayment;
    this.updateLoss();
  }

  updateShipping(val: number) {
    const { amountDue, shipping, total } = recalculateOrderShipping(
      val,
      this.order,
      this.isCredit
    );
    this.orderForm.patchValue({ amountDue, shipping, total });
    this.updateLoss();
  }

  updateTax(val: number) {
    const { amountDue, tax, total } = recalculateOrderTax(
      val,
      this.order,
      this.isCredit
    );
    this.orderForm.patchValue({ amountDue, tax, total });
    this.updateLoss();
  }

  updateLoss(order?: VendorOrder) {
    const o = order ?? this.order;
    this.productsTotal = getProductsTotalCost(o, this.isCredit);
    this.productsCost = getProductsCost(o);
    this.productsTax = getProductsTax(o);
    this.productsShipping = getProductsShipping(o);
    const proposedCredit = this.getProposedCredit(o);
    this.expectedLoss = getNumberPrecision(proposedCredit - Math.abs(o.total));
    this.expectedLossText = new DecimalPipe('en-US').transform(
      this.expectedLoss,
      '1.2-2'
    );
    this.setProductsData(o);
    this.table?.renderRows?.();
    this.cd.detectChanges();
  }

  selectProduct(e: any, el: any) {
    console.log(el);
    const { [el.productId]: selectedFrame, ...rest } = this.selectedFrames;
    if (selectedFrame) {
      this.selectedFrames = rest;
    } else {
      this.selectedFrames = { ...this.selectedFrames, [el.productId]: true };
    }
    this.cd.detectChanges();
  }

  selectAllProducts(e: any) {
    this.selectedFrames = !e.target.checked
      ? {}
      : this.order.products.reduce(
          (acc, product) => ({ ...acc, [product._id]: true }),
          {}
        );
    this.cd.detectChanges();
  }

  removeFrames() {
    this.removeFramesFromVendorOrder()
      .pipe(
        tap(() => {
          this.dialog.closeAll();
          this.snackbar.open(
            `Invoice ${
              this.order.invoiceNumber ?? ''
            } has been successfully updated.`,
            'close'
          );
          this.unsubscribe$.next();
          this.getData();
        })
      )
      .subscribe();
  }

  removeFramesFromVendorOrder() {
    const selectedProductIds = Object.keys(this.selectedFrames);
    console.log(selectedProductIds);

    /* Order Log  */
    const logItem: LogElement = {
      _id: new ObjectID().toHexString(),
      initials: this.aS.currentUser.name
        .split(' ')
        .map((item) => item[0].toUpperCase())
        .join(''),
      user: this.aS.currentUser._id,
      item: `RETURN FRAME${
        selectedProductIds.length > 1 ? 'S' : ''
      } TO INVENTORY`,
      date: new Date().toISOString(),
      notes: `Removed frames ${selectedProductIds.join(', ')} from the order`,
    };

    /* Remove returned status from each product */
    const productsUpdates = selectedProductIds.map((productId) =>
      this.pdS.getProduct(productId).pipe(
        concatMap((res: Product) => {
          const updatedProduct: any = {
            ...res,
            status: null,
            dateUpdated: new Date().toISOString(),
            quantity: !res.quantity ? 1 : res.quantity - 1,
          };
          return this.pdS.updateProduct(productId, updatedProduct);
        })
      )
    );

    /* Update vendor order */
    return this.updateVendorOrder(
      this.order._id,
      logItem,
      selectedProductIds
    ).pipe(concatMap(() => combineLatest(productsUpdates)));
  }

  updateVendorOrder(
    orderId: string,
    logItem: LogElement,
    removeProductIds: string[]
  ) {
    return of(this.order).pipe(
      map((res) => {
        /* Add the new log item to existing log */
        const { log, framesRemoved } = res;
        res.log = [...(log ?? []), logItem];
        res.framesRemoved = [...(framesRemoved ?? []), ...removeProductIds];
        if (removeProductIds) {
          const mod = this.isCredit ? -1 : 1;
          /* Remove products from vendor order */
          removeProductIds.forEach((productId) => {
            /* Remove product from vendor order */
            const productIndex = res.products.findIndex(
              (p) => p.product._id === productId
            );
            console.log(productIndex);
            const pd = res.products[productIndex];
            console.log(pd);
            res.products = res.products.filter(
              (p, idx) => productIndex !== idx
            );
            res.total = (res.total - pd.cost) * mod;
            res.shipping = (res.shipping - pd.shipping) * mod;
            res.tax = (res.tax - pd.tax) * mod;
          });
        }
        /* Check if there are more products in the vendor order */
        if (res.products.length) {
          /* Update proposed credit */
          if (res.proposedCredit) {
            res.proposedCredit = res.products.reduce((acc, p) => {
              const cost = p.cost as any;
              if (typeof cost === 'string') {
                return acc + parseFloat(cost.replace(/,/g, ''));
              }
              return acc + cost;
            }, 0);
          }
        } else {
          // Cancel order if no products left
          res.status = 'CANCELLED';
        }
        return res;
      }),
      concatMap((mergedOrder: VendorOrder) =>
        this.voS.updateVendorOrder(orderId, mergedOrder)
      )
    );
  }
}
