import { Component, ChangeDetectorRef } from '@angular/core';
import { formatNumber } from '@angular/common';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { ProductService } from '@services/product.service';
import { OrderForm } from '@classes/order-form';

@Component({
  selector: 'app-price-check',
  templateUrl: './price-check.component.html',
  styleUrls: ['./price-check.component.css']
})
export class PriceCheckComponent {
	priceCheckForm: FormGroup;
  productQuery: ProductQuery = new ProductQuery();
  selectedProduct: Product;
  min: number = null;
  max: number = null;

  constructor(private _bottomSheetRef: MatBottomSheetRef<PriceCheckComponent>, private fb: FormBuilder, private productService: ProductService, private cdRef: ChangeDetectorRef) {
  	this.priceCheckForm = fb.group({
			'vendor': '',
      'model': '',
      'query': fb.group(new ProductQuery()),
			'queryProducts': fb.control([]),
      // 'product': fb.group({query: ''})
		});
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  updateProduct($event:ProductQuery) {
    this.productQuery = $event;
    let query = this.productQuery;
    if (query.model.selected) {
	    let vendor: Vendor = this.priceCheckForm.value.vendor;
      this.productService.queryVendorProductsFields(vendor._id, $event)
        .subscribe((res:any[]) => {
					let prices = [];
					for (let x = 0; x < res.length; x++) {
						if (res[x].retail) {
							prices.push(res[x].retail);
						}
					}

					this.min = Math.min( ...prices );
    			this.max = Math.max( ...prices );

          delete res[0].__v;
          this.selectedProduct = res[0] as Product;
          this.selectedProduct.query = query;
          // selectedProduct.queryProducts = product.value.queryProducts;
          // product.setValue(selectedProduct);
          let productFormGroup = OrderForm.initProductFormGroup(this.selectedProduct);


          // this.formatCurrency(null, productElement.get('cost'));
          // this.formatCurrency(null, productElement.get('retail'));

          this.cdRef.detectChanges();
        });
    }
  }

  formatCurrency($event,control) {
  	let tmpValue = parseFloat(control.value);
  	let value = (isNaN(tmpValue)) ? 0 : tmpValue;
    control.setValue(formatNumber(value, 'en-US', '1.2-2'));
  }

}
