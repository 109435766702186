<mat-card class="ao-card">
  <span #matCardHeader></span>
  <mat-card-header class="d-block pb-2">
    <div class="d-block w-100" style="padding-right: 2rem">
      <div class="d-flex align-items-center justify-between w-100 mb-2">
        <div>
          <ol
            class="breadcrumb mb-0 ps-0 d-flex align-items-center futura-book"
          >
            <li class="breadcrumb-item d-flex align-items-center">
              <a [routerLink]="['/v2/payables']">Payables</a>
            </li>
            <ng-container *ngIf="vendorId; else distributorBlock">
              <li *ngIf="vendor?.name" class="breadcrumb-item gray-color">
                <a [routerLink]="['/v2/payables/vendor', vendorId]">
                  <ng-container *ngIf="vendor?.images?.length; else nameBlock">
                    <app-product-image
                      [image]="vendor.images[0]"
                      [alt]="vendor.name"
                      class="logo"
                    ></app-product-image>
                  </ng-container>
                  <ng-template #nameBlock>
                    {{ vendor.name }}
                  </ng-template></a
                >
              </li>
            </ng-container>
            <ng-template #distributorBlock>
              <li *ngIf="vendor?.name" class="breadcrumb-item gray-color">
                <a [routerLink]="['/v2/payables/distributor', distributorId]">
                  <ng-container *ngIf="vendor?.images?.length; else nameBlock">
                    <app-product-image
                      [image]="vendor.images[0]"
                      [alt]="vendor.name"
                      class="logo"
                    ></app-product-image>
                  </ng-container>
                  <ng-template #nameBlock>
                    {{ vendor.name }}
                  </ng-template>
                </a>
              </li>
            </ng-template>
          </ol>
        </div>
        <div class="select-box">
          <div class="status">
            <mat-chip-list aria-label="Select status">
              <mat-chip
                [class.selected]="status === 'unpaid'"
                [disableRipple]="true"
                >Unpaid
                <mat-icon>radio_button_checked</mat-icon></mat-chip
              >
              <mat-chip
                [disableRipple]="true"
                (click)="showPaid()"
                >Paid
                <mat-icon>radio_button_unchecked</mat-icon></mat-chip
              >
            </mat-chip-list>
          </div>
        </div>
        <div *ngIf="searchControl">
          <form class="search-box relative">
            <input
              type="text"
              [formControl]="searchControl"
              class="input-search d-block dark"
              placeholder="Search Orders"
            />
            <button type="submit" class="icon">
              <mat-icon>search</mat-icon>
            </button>
          </form>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="mat-mdc-card-content">
    <div class="mat-elevation-z0 mat-table mt-18">
      <table
        *ngIf="displayedColumns?.length"
        mat-table
        matSort
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        [dataSource]="dataSource | async"
        class="ao-table"
        (matSortChange)="onSortChange($event)"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="pay">
          <th mat-header-cell *matHeaderCellDef class="ao-header-cell center th-dark">
            <span class="ao-checkbox">
              <input
                type="checkbox"
                [checked]="allUnpaidSelected"
                (click)="onSelectAllUnpaid()"
                class="check"
              />
            </span>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            <span class="ao-checkbox" *ngIf="element.unPaid && !element.header && !element.awaitingCredit">
              <input
                type="checkbox"
                [checked]="selectedOrders[element._id] || awaitingOrders[element._id]"
                (click)="onSelectOrder(element._id)"
                class="check"
              />
            </span>
          </td>
        </ng-container>

        <!-- Vendor Column -->
        <ng-container matColumnDef="vendor">
          <th mat-header-cell *matHeaderCellDef class="ao-header-cell center th-dark">
            Vendor
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            {{ element.vendorName }}
          </td>
        </ng-container>

        <!-- Group Id Column -->
        <ng-container matColumnDef="groupId">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="groupId"
          >
            Group #
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            <div class="d-flex align-items-center">
              {{ element.groupId }}
            </div>
          </td>
        </ng-container>

        <!-- Invoice Column -->
        <ng-container matColumnDef="invoiceNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="invoiceNumber"
          >
            Invoice #
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
            [class.td-input]="element.awaitingCredit"
            [class.focus]="focus === element._id || awaitingOrders[element._id]"
            [class.focus-input]="status === 'unpaid'"
            [class.selected-input]="awaitingOrders[element._id]"
            [attr.data-focus]="element._id"
          >
            <ng-container *ngIf="element.awaitingCredit; else invoiceNumber">
              <span [attr.data-focus]="element._id">
                {{ element.invoiceNumber }}
              </span>
              <div [attr.data-focus]="element._id" class="inpt lg">
                <input
                  [attr.data-focus]="element._id"
                  type="text"
                  [(ngModel)]="element.invoiceNumber"
                  (ngModelChange)="
                    updatePayable($event, element._id, 'invoiceNumber')
                  "
                />
              </div>
            </ng-container>
            <ng-template #invoiceNumber>
                <ng-container *ngIf="!element.header">{{ element.invoiceNumber }}</ng-container>
            </ng-template>
          </td>
        </ng-container>

        <!-- Invoice Date Column -->
        <ng-container matColumnDef="invoiceDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="date"
          >
            Invoice Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            <ng-container *ngIf="!element.header">
              {{ element.date | date: 'shortDate' }}
            </ng-container>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="status"
          >
            Status
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            {{ element.status }}
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="datePaid">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="datePaid"
          >
            Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            <ng-container *ngIf="element.header; else rowBlock">
              {{ element.datePaid | date: 'shortDate' }}
            </ng-container>
            <ng-template #rowBlock>
              {{ element.date | date: 'shortDate' }}
            </ng-template>
          </td>
        </ng-container>

        <!-- Shipping Column -->
        <ng-container matColumnDef="shipping">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="shipping"
          >
            Shipping
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center td-input"
          >
          <!-- <ng-container *ngIf="status === 'unpaid'; else paidShipping">
            <span [attr.data-focus]="element._id">
                {{ element.shipping | currency }}
            </span>
            <div [attr.data-focus]="element._id" class="inpt">
              <input
                [attr.data-focus]="element._id"
                type="text"
                format="/[0-9.]/g"
                [(ngModel)]="element.shipping"
                (ngModelChange)="
                  updatePayable($event, element._id, 'shipping')
                "
              />
            </div>
          </ng-container>
          <ng-template #paidShipping>
            {{ element.shipping | currency }}
          </ng-template> -->
          {{ element.shipping | currency }}
          </td>
        </ng-container>

        <!-- Tax Column -->
        <ng-container matColumnDef="tax">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
            mat-sort-header="tax"
          >
            Tax
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center td-input"
          >
          {{ element.tax | currency }}
          </td>
        </ng-container>

        <!-- Payments Column -->
        <ng-container matColumnDef="payments">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="partialPayment"
            class="ao-header-cell center th-dark"
          >
            Amt. Paid
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            {{ element.partialPayment | currency }}
          </td>
        </ng-container>

        <!-- Amount To Pay Column -->
        <ng-container matColumnDef="amountToPay">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
          >
            To Pay
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center td-input"
            [class.focus]="selectedOrders[element._id]"
            [class.focus-input]="status === 'unpaid'"
            [class.selected-input]="selectedOrders[element._id]"
            [attr.data-focus]="element._id"
          >
            <ng-container *ngIf="!element.awaitingCredit; else toPay">
              <span [attr.data-focus]="element._id">
                <ng-container *ngIf="selectedOrders[element._id]; else noAmount">
                  {{ element.amountToPay | currency }}
                </ng-container><ng-template #noAmount>0.00</ng-template>
              </span>
              <div [attr.data-focus]="element._id" class="inpt">
                <app-number-input
                  [data-focus]="element._id"
                  [value]="element.amountToPay"
                  (valueChange)="
                    updatePayable($event, element._id, 'amountToPay')
                  "
                ></app-number-input>
              </div>
            </ng-container>
            <ng-template #toPay>
              0.00
            </ng-template>
          </td>
        </ng-container>

        <!-- Total Column -->
        <ng-container matColumnDef="total">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="total"
            class="ao-header-cell center th-dark"
          >
            Total
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            {{ element.total | currency }}
          </td>
        </ng-container>

        <!-- Amount Due Column -->
        <ng-container matColumnDef="amountDue">
          <th mat-header-cell *matHeaderCellDef class="ao-header-cell center th-dark">
            Amt. Due
          </th>

          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            <span [matTooltip]="'Total: $' + element.total"><strong>{{
              element.amountDue | currency
            }}</strong></span>
          </td>
        </ng-container>

        <!-- Options Column -->
        <ng-container matColumnDef="options">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
          ></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
          >
            <mat-icon
              *ngIf="element.header; else optionsBlock"
              class="pointer icon"
              (click)="printVendorOrders(element.groupId)"
              [matTooltip]="'Print group invoice'"
            >
              print
            </mat-icon>
            <ng-template #optionsBlock>
                <mat-icon class="pointer icon" (click)="viewOrder(element._id)">visibility</mat-icon>
            </ng-template>
          </td>
        </ng-container>

        <!-- Log Column -->
        <ng-container matColumnDef="log">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ao-header-cell center th-dark"
          ></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="ao-table-cell center"
            [class.th-light]="element.header"
          >
            <app-payables-list-menu
              *ngIf="!element.header"
              [payable]="element"
              [type]="'log'"
            ></app-payables-list-menu>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="ao-header-row th"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="ao-table-row"
          [class.td-dark]="row.orderId && selectedOrders[row.orderId]"
          [class.tr-light]="row.header"
        ></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<div [formGroup]="logForm" class="fix-bottom" [class.aside]="aside" *ngIf="someSelected">
  <div *ngIf="aside" class="aside-button">
    <span matTooltip="Show payment form" (click)="aside = false">      <mat-icon>
      visibility
    </mat-icon></span>
  </div>
  <ng-container *ngIf="!minimized && !aside">
    <div class="container">
      <div class="d-flex ao-total" matTooltip="Total of selected order(s)">
        <label>TOTAL</label>
        <div>
          {{ selectedOrderTotal | currency }}
        </div>
      </div>
      <div class="d-flex flex-column align-start justify-start">
        <mat-form-field class="w-100" floatLabel="never">
          <textarea
            matInput
            formControlName="notes"
            rows="2"
            placeholder="NOTES"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!aside" class="fix-actions color">
    <div class="flex-grow-1 d-flex align-items-center justify-between">
      <div class="input">
        <mat-form-field class="w-100" floatLabel="never">
          <input
            matInput
            required
            placeholder=""
            type="text"
            formControlName="initials"
          />
          <span matSuffix><mat-icon>gesture</mat-icon></span>
        </mat-form-field>
      </div>
      <button
        class="btn inverse"
        [disabled]="logForm.invalid"
        (click)="markVendorOrdersPaid()"
      >
        <mat-icon
          class="lightest"
          [matTooltip]="
            logForm.invalid
              ? 'Please initial to pay order(s)'
              : 'Pay selected order(s)'
          "
        >
          attach_money
        </mat-icon>
      </button>

      <button
        class="btn inverse"
        (click)="printSelectedVendorOrders()"
        matTooltip="Print selected orders"
      >
        <mat-icon class="lightest">print</mat-icon>
      </button>

      <button
        class="btn inverse"
        [disabled]="logForm.invalid"
        (click)="markVendorOrdersPaidAndPrint()"
      >
        <mat-icon
          class="lightest"
          [matTooltip]="
            logForm.invalid
              ? 'Please initial to pay/print order(s)'
              : 'Pay and print selected order(s)'
          "
          >receipt_long</mat-icon
        >
      </button>
    </div>
    <span
      class="expand-actions"
      (click)="aside = true"
      matTooltip="Hide"
    >
      <mat-icon matTooltip="Hide payment form">visibility_off</mat-icon>
    </span>
  </div>
</div>
