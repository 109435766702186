<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="space-between center"
  fxLayoutGap="10px"
  *ngIf="user"
>
  <div class="item item-1" fxFlex="100%">
    {{ message }}
  </div>
  <div class="item item-1" fxFlex="100%" *ngIf="user.role !== 'RENTAL'">
    <app-autocomplete-patient
      placeholder="SEARCH EXISTING CUSTOMERS"
    ></app-autocomplete-patient>
  </div>
  <!-- <div class="item item-2" fxFlex="100%" *ngIf="false">
    <button mat-raised-button routerLink="/admin/patient" style="width:300px;">NEW CUSTOMER</button>
  </div> -->
  <div class="item item-1" fxFlex="100%" *ngIf="user.role !== 'RENTAL'">
    <button
      mat-raised-button
      [routerLink]="['/admin/admin', { outlets: { sub: ['patients'] } }]"
      style="width: 300px"
    >
      ADMIN
    </button>
  </div>
  <div class="item item-1" fxFlex="100%" *ngIf="user.role === 'OWNER/ADMIN'">
    <button
      mat-raised-button
      [routerLink]="['/v2/payables']"
      style="width: 300px"
    >
      PAYABLE V2
    </button>
  </div>
  <div class="item item-1" fxFlex="100%" *ngIf="user.role !== 'RENTAL'">
    <button mat-raised-button routerLink="/admin/vendors" style="width: 300px">
      VENDORS
    </button>
  </div>
  <div class="item item-1" fxFlex="100%">
    <button
      mat-raised-button
      [routerLink]="[
        '/admin/managed-board',
        { outlets: { sub: [user.role === 'RENTAL' ? 'inventory' : 'stack'] } }
      ]"
      style="width: 300px"
    >
      {{ user.role === "RENTAL" ? "INVENTORY" : "MANAGED BOARD/INVENTORY" }}
    </button>
  </div>
  <div class="item item-1" fxFlex="100%" *ngIf="user.role !== 'RENTAL'">
    <button mat-raised-button routerLink="/admin/exams" style="width: 300px">
      EYE EXAMS
    </button>
  </div>
  <div class="item item-1" fxFlex="100%" *ngIf="user.role !== 'RENTAL'">
    <button mat-raised-button (click)="openPriceCheck()" style="width: 300px">
      PRICE CHECK
    </button>
  </div>
  <div class="item item-1" fxFlex="100%" *ngIf="user.role !== 'RENTAL'">
    <button
      mat-raised-button
      [routerLink]="['/admin/change-log']"
      style="width: 300px"
    >
      CHANGE LOG
    </button>
  </div>
</div>
