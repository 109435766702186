import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Vendor } from '@models/vendor';
import { Store } from '@models/store';
import { Order } from '@models/order';
import { Product, ProductQuery, ProductElement, FrameDetails, LensDetails, FrameMeasurements } from '@models/product';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';

export class ProductForm {

  constructor() {
    // this.orderForm = fb.group({
  //     orders: this.fb.array([])
  //   });
  }

  static initProductFormArray(products:Product[], auth: AuthService, storeOptions: any[]) {
    let fb = new FormBuilder();
    let productFormArray:FormArray = fb.array([]);
    for (var x = 0; x < products.length; x++) {
      let productFormGroup = ProductForm.initProductFormGroup(products[x]);
      productFormArray.push(productFormGroup);
    }
    return productFormArray;
  }

  static initProductElementFormGroup(productElement:ProductElement) {
    let fb = new FormBuilder();
    let productFormGroup = ProductForm.initProductFormGroup(productElement.product);
    let frameFormGroup = ProductForm.initProductElementFrameFormGroup(productElement.frame);
    let lensFormGroup = ProductForm.initProductElementLensFormGroup(productElement.lens);
    let productElementFormGroup:FormGroup = fb.group({
        '_id': productElement._id,
        'cost': productElement.cost,
        'tax': productElement.tax,
        'shipping': productElement.shipping,
        'pricingFormula': productElement.pricingFormula,
        'retail': productElement.retail,
        'duty': [{value:productElement.duty, disabled: true}],
        'quantity': productElement.quantity,
        'frame': frameFormGroup,
        'lens': lensFormGroup,
        'product': productFormGroup,
        'store': productElement.store,
        'type': productElement.type,
        'destination': productElement.destination
      });
    return productElementFormGroup;
  }

  static initProductFormGroup(product:Product) {
    let fb = new FormBuilder();
    product.notes = product.notes;
    product.images = (Array.isArray(product.images)) ? product.images : [];
    product.inventoryVendorOrders = (Array.isArray(product.inventoryVendorOrders)) ? product.inventoryVendorOrders : [];
    let tmpProduct = Object.assign({}, product);
    let vendorFormGroup;
    if (tmpProduct.vendor) {
      if (Array.isArray(tmpProduct.vendor)) {
        tmpProduct.vendor = tmpProduct.vendor[0];
      }
      if (typeof tmpProduct.vendor === "object") {
        // vendorFormGroup = this.initVendorFormGroup(tmpProduct.vendor as Vendor);
        vendorFormGroup = fb.control(tmpProduct.vendor as Vendor);
      } else {
        vendorFormGroup = fb.control(tmpProduct.vendor);
      }
      product.vendor = null;
    }

    let imageArray: FormControl = fb.control(tmpProduct.images);
    product.images = null;

    let borrowingArray: FormControl = fb.control(tmpProduct.borrowing);
    product.borrowing = null;

    let inventoryVendorOrdersArray: FormControl = fb.control(tmpProduct.inventoryVendorOrders);
    product.inventoryVendorOrders = null;

    let vendorControl: FormControl = fb.control(null);

    product.return = false;
    let productFormGroup = fb.group(product);
    productFormGroup.removeControl('images');
    productFormGroup.removeControl('borrowing');
    productFormGroup.removeControl('vendor');
    productFormGroup.removeControl('inventoryVendorOrders');

    productFormGroup.addControl('images', imageArray);
    productFormGroup.addControl('borrowing', borrowingArray);
    productFormGroup.addControl('inventoryVendorOrders', inventoryVendorOrdersArray);
    if (vendorFormGroup) {
      productFormGroup.addControl('vendor', vendorFormGroup);
    } else {
      productFormGroup.addControl('vendor', vendorControl);
    }

    if (tmpProduct.vendor) {
      productFormGroup.removeControl('vendor');
      productFormGroup.addControl('vendor', vendorFormGroup);
      product.vendor = tmpProduct.vendor;
    }

let cont = fb.control(false);
    productFormGroup.addControl('return', cont);

    productFormGroup.addControl('query', fb.control(new ProductQuery()));
    productFormGroup.addControl('queryProducts', fb.control({model: [], color: [], size: [], type: []}));

    product.images = tmpProduct.images;
    product.borrowing = tmpProduct.borrowing;

    return productFormGroup;
  }

  static initProductElementFrameFormGroup(frame:FrameDetails) {
    let fb = new FormBuilder();
    let measurementsFormGroup = ProductForm.initProductElementFrameMeasurementsFormGroup(frame.measurements);
    let productElementFrameFormGroup: FormGroup = fb.group({
      frameSource: frame.frameSource,
      measurements: measurementsFormGroup,
      enclosed: frame.enclosed,
      pof: frame.pof,
      patientToSupply: frame.patientToSupply,
      duty: frame.duty,
      hold: frame.hold,
      modification: frame.modification,
      modificationPrice: frame.modificationPrice,
      modificationDetails: frame.modificationDetails,
      estimate: frame.estimate,
      deposit: frame.deposit,
      callPatient: frame.callPatient,
      notes: frame.notes ?? '',
      price: frame.price,
      orderDate: frame.orderDate,
      dueDate: frame.dueDate,
      failedInspection: frame.failedInspection,
      approved: frame.approved,
      nosepadInstall: frame.nosepadInstall,
    });
    return productElementFrameFormGroup;
  }

  static initProductElementFrameMeasurementsFormGroup(frameMeasurements:FrameMeasurements) {
    let fb = new FormBuilder();
    let productElementFrameMeasurementsFormGroup:FormGroup = fb.group({
        a: frameMeasurements.a,
        b: frameMeasurements.b,
        ed: frameMeasurements.ed,
        dbl: frameMeasurements.dbl
      });
    return productElementFrameMeasurementsFormGroup;
  }

  static initProductElementLensFormGroup(lens:LensDetails) {
    if (!lens) lens = new LensDetails();
    let fb = new FormBuilder();
    let productElementLensFormGroup:FormGroup = fb.group({
        'bc': lens.bc,
        'et': lens.et,
        'ct': lens.ct,
        'lab': lens.lab,
        'type': lens.type,
        'material': lens.material,
        'transition': lens.transition,
        'polar': lens.polar,
        'design': lens.design,
        'tint': lens.tint,
        'coating': lens.coating,
        'overPowerSph': lens.overPowerSph,
        'edgePolish': lens.edgePolish,
        'mountingEdging': lens.mountingEdging,
        'prism': lens.prism,
        'overPowerCyl': lens.overPowerCyl,
        'bevel': lens.bevel,
        'drill': lens.drill,
        'backsideAR': lens.backsideAR,
        'specialBC': lens.specialBC,
        'specialTool': lens.specialTool,
        'groove': lens.groove,
        'sendForAR': lens.sendForAR,
        'UVcoating': lens.UVcoating,
        'slabOff': lens.slabOff,
        'mirror': lens.mirror,
        'mirrorDetails': lens.mirrorDetails,
        'customShape': lens.customShape,
        'other': lens.other,
        'otherDetails': lens.otherDetails,
        'otherPrice': lens.otherPrice,
        'price': lens.price,
        'POL': lens.POL,
        'POLLabReferenceNumber': lens.POLLabReferenceNumber,
        'POLPrice': lens.POLPrice,
        'taxable': lens.taxable,
        'lensAndShapeNotes': lens.lensAndShapeNotes,
        'machine': lens.machine,
        'grooveSize': lens.grooveSize,
        'lensSize': lens.lensSize,
        'depth': lens.depth,
        'bevel2': lens.bevel2,
        'width': lens.width,
        'placement': lens.placement,
        'technicalNotes': lens.technicalNotes,
        'date': lens.date,
        'labReferenceNumber': lens.labReferenceNumber,
        'failedInspection': lens.failedInspection
      });
    return productElementLensFormGroup;
  }

  static togglePriceLink(productsForm: FormGroup, product: FormGroup, editProduct:FormGroup, vendor: Vendor, index) {
    let saveProduct = false;
    if (product.value.pricingFormula == 'c') {
      // let currentVendor: Vendor = product.get('vendor').value as Vendor;
      // currentVendor.pricingFormulaOptical = vendor.pricingFormulaOptical;
      // currentVendor.pricingFormulaSun = vendor.pricingFormulaSun;
      // product.get('vendor').setValue(currentVendor);
      // product.controls.pricingFormula.setValue((product.value.type == 'RX') ? product.value.vendor.pricingFormulaOptical : product.value.vendor.pricingFormulaSun);
      product.controls.pricingFormula.setValue((product.value.type == 'RX') ? vendor.pricingFormulaOptical : vendor.pricingFormulaSun);
      product.controls.retail.setValue(Product.calculateProductPrice(product.value));
      let arr:FormArray = <FormArray>productsForm.controls['productsFormArray'];
      let control:FormGroup = <FormGroup>arr.controls[index];
      control.controls.retail.setValue(product.value.retail);
      if (!editProduct || editProduct && editProduct.value._id != product.value._id) {
        saveProduct = true;
      }
    } else {
      product.controls.pricingFormula.setValue('c');
    }
    return saveProduct;
  }

  static calculateProductPrice(product: Product) {
    let rx_validate = /^x.+p.+$/;
    let rx_extract = /^x(.+)p(.+)$/;
    let result = [];
    let multiplier = 1;
    let adder = 0;
    if (rx_validate.test(product.pricingFormula)) {
      result = rx_extract.exec(product.pricingFormula);
      multiplier = parseFloat(result[1]);
      adder = parseFloat(result[2]);
    }
    if (parseFloat(product.cost.toString())) {
      return Math.ceil((Math.round((parseFloat(product.cost.toString()) * multiplier + adder) * 100) / 100)/5)*5;
    }
    return null;
  }

  static changeRetail(product: FormGroup, vendor: Vendor, isOrder: boolean = false) {
    // let currentVendor: Vendor = (!isOrder) ? product.get('vendor').value as Vendor : vendor;
    // currentVendor.pricingFormulaOptical = vendor.pricingFormulaOptical;
    // currentVendor.pricingFormulaSun = vendor.pricingFormulaSun;
    if (!isOrder) {
      // product.get('vendor').setValue(currentVendor);
    }
    // product.get('pricingFormula').setValue((product.value.type == 'RX') ? currentVendor.pricingFormulaOptical : currentVendor.pricingFormulaSun);
    product.get('pricingFormula').setValue((product.value.type == 'RX') ? vendor.pricingFormulaOptical : vendor.pricingFormulaSun);
    let calculatedRetail = Product.calculateProductPrice(product.value);
    if (calculatedRetail != product.get('retail').value) {
      product.get('pricingFormula').setValue('c');
    }
  }

  static changeCost(product: FormGroup, vendor: Vendor, isOrder: boolean = false) {
    let pricingFormulaControl = product.get('pricingFormula');
    // let currentVendor: Vendor = (!isOrder) ? product.get('vendor').value as Vendor : vendor;
    // console.log(currentVendor)
    // console.log(vendor)
    // console.log(product)
    if (pricingFormulaControl.value != 'c') {
      // currentVendor.pricingFormulaOptical = vendor.pricingFormulaOptical;
      // currentVendor.pricingFormulaSun = vendor.pricingFormulaSun;
      if (!isOrder) {
        // product.get('vendor').setValue(currentVendor);
        // product.get('vendor').setValue(vendor);
      }
      // pricingFormulaControl.setValue((product.value.type == 'RX') ? currentVendor.pricingFormulaOptical : currentVendor.pricingFormulaSun);
      pricingFormulaControl.setValue((product.value.type == 'RX') ? vendor.pricingFormulaOptical : vendor.pricingFormulaSun);
      product.get('retail').setValue(Product.calculateProductPrice(product.value));
    }
  }

}
