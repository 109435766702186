import { Pipe, Inject, LOCALE_ID, Component, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { Patient } from '@models/patient';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription } from 'rxjs';
import { OrdersBaseComponent } from '@components/admin/managed-board/base/orders-base/orders-base.component';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-patient-inquiries',
  templateUrl: './patient-inquiries.component.html',
  styleUrls: ['./patient-inquiries.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PatientInquiriesComponent extends OrdersBaseComponent implements OnInit {
	@Input() public patient: Patient;
  @Input() public orderIndex: number;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	orders: Order[];
	dataSource = new MatTableDataSource<FormGroup>();
	displayedColumns = ['vendor', 'model', 'color', 'size', 'type', 'todo', 'notify', 'log'];
  displayedColumns2 = ['notes', 'action'];
  todoOptions = [{viewValue: 'CHECK AVAILABILITY', value: 'CHECK AVAILABILITY'}, {viewValue: 'CHECK COLORS', value: 'CHECK COLORS'}, {viewValue: 'CHECK PRICE', value: 'CHECK PRICE'}];

  constructor(@Inject(LOCALE_ID) public locale: string, public auth: AuthService, public orderService: OrderService, public vendorOrderService: VendorOrderService, public productService: ProductService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public fb: FormBuilder, public route: ActivatedRoute, public notificationService: NotificationService) {
    super(locale, auth, orderService, vendorOrderService, productService, storeService, cdRef, fb, route, notificationService);
  }

  ngAfterViewInit() {
    this.getNewOrders();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getNewOrders() {
    const patientId = this.route.snapshot.params.id;
    if (!patientId) {
      return;
    }
    this.orderService.getPatientOrders(this.patient._id, 'inquiries').subscribe((res: Order[]) => {
      this.orders = res;
      this.setOrderForm(this.orders);
      for (var x = 0; x < this.orders.length; x++) {
        let orderFormGroup = this.orderForm.get('orders.' + x) as FormGroup
        this.dataSource2.push(new MatTableDataSource<FormGroup>([orderFormGroup]));
      }
      this.cdRef.detectChanges();
    });
  }

  clickInquiry($event, order: FormGroup, index) {
    console.log(this.fieldOpen);
    if (!this.fieldOpen) {
    	if (this.index !== null) {
	    	order.controls.notify.disable();
    	}
	    order.controls.notify.enable();
	    this.orderId = order.value._id;
	    this.index = index;
	    $event.preventDefault();
	    $event.stopPropagation();
	    this.fieldOpen = false;
	  }
  }

  clickField2($event, order: FormGroup, index) {
    this.fieldOpen = true;
  	order.controls.notify.enable();
    this.orderId = order.value._id;
    this.index = index;
  }

  clickSaveOrder(order: FormGroup) {
    let newOrder = false;
    if (order.get('products.0._id').value == '') {
      newOrder = true;
    }
    this.saveFrame(order, newOrder).subscribe(res => {});
  }

}
