import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';
import { ApiResponse } from '@classes/api-response';

const RENT_URI = `${environment.base_url}/rent`;

@Injectable({
  providedIn: 'root'
})
export class RentService {
  constructor(public http: HttpClient) { }

  fetchRents(userId?: string, storeId?: string) {
    return this.http.get(`${RENT_URI}${userId ? `/${userId}` : ''}`, {
      params: storeId ? { store: storeId } : {}
    }).pipe(map((res: ApiResponse<any>) => res.rents));
  }

  saveRents(payload) {
    return this.http.post(`${RENT_URI}`, payload).pipe(map((res: ApiResponse<any>) => res.rents));
  }

  downloadFrames() {
    return this.http.get(`${RENT_URI}/download`);
  }

  returnProduct(rentId: string) {
    return this.http.post(`${RENT_URI}/${rentId}/return`, {});
  }
}
