import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'case',
})
export class CasePipe implements PipeTransform {
  transform(value?: string, args?: any): unknown {
    if (!value) {
      return '';
    }

    if (args === 'upper') {
      return value.toUpperCase();
    }

    return value
      .split(' ')
      .map(
        (v) => v.substring(0, 1).toUpperCase() + v.substring(1).toLowerCase()
      )
      .join(' ');
  }
}
