import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@models/user';
import { ApiResponse } from '@classes/api-response';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private base_url = environment.base_url + '/user';

  constructor(public http: HttpClient) { }

  queryUsers(query: String): Observable<Object> {
    return this.http.get(`${this.base_url}/query/${query}`).pipe(map( (res: ApiResponse<User[]>) => res.users ));
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get(`${this.base_url}`).pipe(map( (res: ApiResponse<User[]>) => res.users ));
  }

  getUser(userId: String): Observable<User> {
    return this.http.get(`${this.base_url}/${userId}`).pipe(map( (res: ApiResponse<User>) => res.user ));
  }

  addUser(user: User): Observable<ApiResponse<User>> {
    const body = JSON.stringify(user);
    return this.http.post(`${this.base_url}`, body).pipe(map( (res: ApiResponse<User>) => res ));
  }

  updateUser(userId: any, user: User): Observable<User> {
    const body = JSON.stringify(user);
    return this.http.put(`${this.base_url}/${userId}`, body).pipe(map( (res: ApiResponse<User>) => res.user ));
  }

  getRentalUsers(store: number): Observable<User[]> {
    return this.http.get(`${this.base_url}/rental/${store}`).pipe(map( (res: ApiResponse<User[]>) => res.users ));
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete(`${this.base_url}/${id}`).pipe(map( (res: ApiResponse<User>) => res.user ));
  }
}
