import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform, PlatformModule } from '@angular/cdk/platform';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
   format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
         const day = date.getUTCDate();
         const month = date.getUTCMonth() + 1;
         const year = date.getFullYear();
         return `${month}/${day}/${year}`;
      } else {
         return date.toDateString();
      }
   }
   
   parse(value: any): Date | null {
    if ((typeof value === 'string')) {
      let dateRegEx = new RegExp('^(0?[1-9]|1[012])[- \/.]*(0?[1-9]|[12][0-9]|3[01])[- \/.]*((19|20)?[0-9]{2})$');
      var match = dateRegEx.exec(value);
      if (match != null && match.length == 5) {
        let year = Number(match[3]);
        if (year < 100) {
          year = year + 2000;
        }
        var date = new Date(year, Number(match[1])-1, Number(match[2]), 12)
        return date;
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

export const MY_DATE_FORMATS = {
   parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
   },
   display: {
      dateInput: 'input',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
};

@NgModule({
  imports: [
    CommonModule, PlatformModule
  ],
  exports: [ PlatformModule ],
  declarations: [],
  providers: [
  	{
  		provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]
  	},
  	{
  		provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
  	}
  ]
})
export class DateModule { }
