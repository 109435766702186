export class User {
  _id: string;
  email: string;
  password: string;
  name: string;
  initials: string;
  role: string;
  stores: number[];
  anyIp: boolean;
  storeName?: string;
  storeNumber?: string;
  dateAdded?: string;
  dateUpdated?: string;
  disabled?: boolean;

  constructor()
  {
    this._id = '';
    this.email = '';
    this.password = '';
    this.name = '';
    this.initials = '';
    this.role = '';
    this.stores = [];
    this.anyIp = false;
    this.storeName = '';
    this.storeNumber = '';
    this.dateAdded = '';
    this.dateUpdated = '';
    this.disabled = false;
  }

}
