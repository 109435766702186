<div class="container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px">
  <div fxFlex="35%">
    <app-autocomplete-vendors
			placeholder="VENDOR"
			[productForm]="priceCheckForm">
		</app-autocomplete-vendors>
  </div>

  <div fxFlex="35%">
    <app-autocomplete-vendor-products
			[productForm]="priceCheckForm"
			[vendor]="priceCheckForm.value.vendor"
			placeholder="MODEL"
			(selected)="updateProduct($event)"
			displayField="model">
		</app-autocomplete-vendor-products>
  </div>
  <div fxFlex="30%">
    <span *ngIf="!min && !max && selectedProduct">{{ selectedProduct.retail | currency }}</span>
    <span *ngIf="min && max && min === max">{{ min | currency }}</span>
  	<span *ngIf="min && max && min < max">{{ min | currency }} &ndash; {{ max | currency }}</span>
  </div>
</div>
