import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newline',
  pure: false
})
export class NewlinePipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	if (value != undefined)
    	return value.replace(/\n/g, '<br>');
    else
    	return null;

  }

}
