import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Vendor } from '@models/vendor';
import { Store } from '@models/store';
import { Product, ProductQuery } from '@models/product';
import { ProductForm } from '@classes/product-form';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';

export class OrderForm {

  constructor() {
    // this.orderForm = fb.group({
  //     orders: this.fb.array([])
  //   });
  }

  static initOrderFormArray(orders: Order[], auth: AuthService, storeOptions: any[]) {
    const  orderFormArray = new FormArray([]);
    orders.forEach(order => {
      const orderFormGroup = OrderForm.initOrderFormGroup(order, auth, storeOptions);
      orderFormArray.push(orderFormGroup);
    });
    return orderFormArray;
  }

  static initOrderFormGroup(order:Order, auth: AuthService, storeOptions: any[]) {
    let fb = new FormBuilder();
    let productsFormArray:FormArray = fb.array([]);
    for (var x = 0; x < order.products.length; x++) {
      let productFormGroup = this.initProductFormGroup(order.products[x].product);
      let productElementFormGroup = ProductForm.initProductElementFormGroup(order.products[x]);



      // let productElementFormGroup: FormGroup = fb.group({
      //   '_id': order.products[x]._id,
      //   'cost': order.products[x].cost,
      //   'tax': order.products[x].tax,
      //   'shipping': order.products[x].shipping,
      //   'pricingFormula': order.products[x].pricingFormula,
      //   'retail': order.products[x].retail,
      //   'duty': [{value:order.products[x].duty, disabled: true}],
      //   'quantity': order.products[x].quantity,
      //   'frame': fb.group(order.products[x].frame),
      //   'lens': fb.group(order.products[x].lens),
      //   'product': productFormGroup,
      //   'store': (order.products[x].store) ? this.getStoreObject(order.products[x].store, storeOptions) : auth.getStoreObject(),
      //   'type': order.products[x].type,
      //   'destination': order.products[x].destination
      // });
      productsFormArray.push(productElementFormGroup);
    }
    let invoiceNumberOptions = (order.products[0] && order.products[0].frame.frameSource == 'STOCK') ? order.invoiceNumber : [order.invoiceNumber, Validators.required];
    let productImagesFormArray = (!!order.images) ? fb.array(order.images) : fb.array([]);
    let orderFormGroup: FormGroup = fb.group({
      '_id': order._id,
      'archived': false,
      'dateAdded': order.dateAdded,
      'dateUpdated': order.dateUpdated,
      'images': productImagesFormArray,
      'type': order.type,
      'invoiceNumber': invoiceNumberOptions,
      'todo': order.todo,
      // 'notify': [{value:order.notify, disabled: true}],
      'notify': order.notify,
      'eta': order.eta,
      'date': order.date,
      'initials': '',
      // 'notes': (order.log && order.log.length > 0) ? order.log[order.log.length-1].notes : '',
      'notes': order.notes,
      'part': order.part,
      'restockOrder': order.restockOrder,
      'specialOrder': order.specialOrder,
      'warranty': order.warranty,
      'products': productsFormArray,
      'patient': order['patient'],
      'status': order.status,
      'store': order.store,
      'user': order.user,
      'log': fb.array(order.log)
    });

    return orderFormGroup;
  }

  static initProductFormGroup(product:Product) {
    let fb = new FormBuilder();
    product.images = (Array.isArray(product.images)) ? product.images : [];
    product.inventoryVendorOrders = (Array.isArray(product.inventoryVendorOrders)) ? product.inventoryVendorOrders : [];
    let tmpProduct = Object.assign({}, product);
    let vendorFormGroup;
    if (tmpProduct.vendor) {
      if (Array.isArray(tmpProduct.vendor)) {
        tmpProduct.vendor = tmpProduct.vendor[0];
      }
      if (typeof tmpProduct.vendor === "object") {
        // vendorFormGroup = this.initVendorFormGroup(tmpProduct.vendor as Vendor);
        vendorFormGroup = fb.control(tmpProduct.vendor as Vendor);
      } else {
        vendorFormGroup = fb.control(tmpProduct.vendor);
      }
      product.vendor = null;
    }

    let imageArray: FormControl = fb.control(tmpProduct.images);
    product.images = null;

    let borrowingArray: FormControl = fb.control(tmpProduct.borrowing);
    product.borrowing = null;

    let vendorControl: FormControl = fb.control(null);

    let inventoryVendorOrdersArray: FormControl = fb.control(tmpProduct.inventoryVendorOrders);
    product.inventoryVendorOrders = null;

    product.images = null;

    let productFormGroup = fb.group(product);
    productFormGroup.removeControl('images');
    productFormGroup.removeControl('borrowing');
    productFormGroup.removeControl('vendor');
    productFormGroup.removeControl('inventoryVendorOrders');

    productFormGroup.addControl('images', imageArray);
    productFormGroup.addControl('borrowing', borrowingArray);
    productFormGroup.addControl('inventoryVendorOrders', inventoryVendorOrdersArray);
    if (vendorFormGroup) {
      productFormGroup.addControl('vendor', vendorFormGroup);
    } else {
      productFormGroup.addControl('vendor', vendorControl);
    }

    if (tmpProduct.vendor) {
      productFormGroup.removeControl('vendor');
      productFormGroup.addControl('vendor', vendorFormGroup);
      product.vendor = tmpProduct.vendor;
    }

    productFormGroup.addControl('query', fb.control(new ProductQuery()));
    productFormGroup.addControl('queryProducts', fb.control({model: [], color: [], size: [], type: []}));

    product.images = tmpProduct.images;


    return productFormGroup;
  }

  static initVendorFormGroup(vendor:Vendor) {
    let fb = new FormBuilder();
    vendor.images = (Array.isArray(vendor.images)) ? vendor.images : [];
    let tmpVendor = Object.assign({}, vendor);

    let imageArray: FormControl = fb.control(tmpVendor.images);
    vendor.images = null;

    let vendorFormGroup = fb.group(vendor);
    vendorFormGroup.removeControl('images');
    vendorFormGroup.addControl('images', imageArray);

    return vendorFormGroup;
  }

  static getStoreObject(store: Store, storeOptions: any[]): Store {
    for (let x = 0; x < storeOptions.length; x++) {
      if (storeOptions[x]._id == store._id) {
        return storeOptions[x];
      }
    }
  }
}
