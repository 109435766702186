<!--<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="SEARCH INVENTORY" (input)="productSearch()">
</mat-form-field>-->
<div *ngIf="loading">
  <h3 style="text-align:center;">LOADING PRODUCT HISTORY</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!loading && !dataSource.data.length">
  <h3 style="text-align:center;">NO PRODUCT HISTORY</h3>
</div>
<mat-table *ngIf="!loading && dataSource.data.length" [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumn" matSortDirection="desc">
  <ng-container matColumnDef="invoiceNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 110px"> INVOICE # </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 110px">

      <ng-container>
        <button *ngIf="history.status=='PAID'" mat-button [routerLink]="['/admin/admin', { outlets: { sub: ['payable', history.product.product.vendor._id, history._id, 'paid'] } }]" style="width:300px;">{{ history.invoiceNumber }}</button>

        <button *ngIf="history.status!='PAID'" mat-button [routerLink]="['/admin/admin', { outlets: { sub: ['payable', history.product.product.vendor._id, history._id, 'unpaid'] } }]" style="width:300px;">{{ history.invoiceNumber }}</button>
      </ng-container>

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> DATE </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 100px">
    	<span *ngIf="!history.order.status.includes('AWAITING')">{{ history.date | date:'M/d/yyyy': 'GMT' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cost">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> COST </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 100px">
      <span>{{ history.product.cost | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tax">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> TAX </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 100px">
      <span>{{ history.product.tax | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="shipping">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> SHIPPING </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 100px">
      <span>{{ history.product.shipping | currency }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="duty">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> DUTY </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 40px">
      <mat-checkbox disabled [checked]="history.order.products[0].duty"></mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="notes">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 40%"> NOTES </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 40%">
      <span>{{ history.order.notes }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patientInvoiceNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 110px"> PATIENT INVOICE # </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index;" fxFlex="0 0 110px">
      <button mat-button *ngIf="history.patient" (click)="openImage(history?.product?.product?.images?.[0])" style="width:100%;text-transform:uppercase;">
        <ng-container *ngIf="(['O','N','F','A']).includes(history.type)">{{ history.type }}-</ng-container>{{history.order.invoiceNumber}}
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patientStock">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 140px"> PATIENT/STOCK </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index;" fxFlex="1 0 140px">
      <button mat-button *ngIf="history.patient"(click)="openPatient(history.patient._id)" style="width:100%;text-transform:uppercase;">{{history.patient.lastName}}, {{history.patient.firstName}}</button>
      <button mat-button *ngIf="!history.patient" style="width:100%;text-transform:uppercase;">STOCK</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 130px"> FRAME STATUS </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="0 0 130px">
      <span>{{ history.order.status }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="log">
    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> LOG </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index;" fxFlex="0 0 50px">
      <app-log-menu [order]="history.order" (clickField)="_clickField()" [small]="true"></app-log-menu>
      <!-- <app-log-menu [order]="history.order" [small]="true"></app-log-menu> -->
    </mat-cell>
  </ng-container>

<!--
  <ng-container matColumnDef="patientStock">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> PATIENT INVOICE # </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=dataIndex;" fxFlex="1 1 10%">
    	<span *ngIf="history.type=='return'">{{history.type}}: {{history.status}}</span>
    	<span *ngIf="history.type!='return'">{{history.product.frame.frameSource}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patient">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> PATIENT/STOCK </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index;" fxFlex="1 1 10%">
      <button mat-button *ngIf="history.patient" [routerLink]="['/admin/patient', history.patient._id]" style="width:100%;text-transform:uppercase;">{{history.patient.lastName}}, {{history.patient.firstName}}</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="store">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> FRAME LOCATION </mat-header-cell>
    <mat-cell *matCellDef="let history; let i=index" fxFlex="1 1 10%">
    	<span>{{ history.store.storeName }}</span>
    </mat-cell>
  </ng-container> -->

  <mat-header-row
  	*matHeaderRowDef="displayedColumns"
  	fxLayout="row"
  	fxLayoutAlign="start stretch"
    (click)="clickRow($event)"></mat-header-row>
  <mat-row
  	*matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;"
  	fxLayout="row"
  	fxLayoutAlign="start stretch"
  	[ngClass]="{rowEven:even, rowOdd:odd}"
    (click)="clickRow($event)"></mat-row>

</mat-table>
