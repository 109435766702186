<div *ngIf="loading">
	<h3 style="text-align:center;">LOADING PRESCRIPTIONS</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div style="width:100%">

	<mat-table [dataSource]="dataSource" class="mat-elevation-z2" multiTemplateDataRows>

		<ng-container matColumnDef="status">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 150px"> STATUS </mat-header-cell>
      <mat-cell *matCellDef="let prescription; let i=dataIndex; let row;" fxFlex="1 1 150px" (click)="toggleExpanded(row)">
        <span *ngIf="prescription.value.archived; else notArchived">ARCHIVED</span>
        <ng-template #notArchived>
          <span *ngIf="isExpired(prescription); else active">EXPIRED</span>
          <ng-template #active><span>ACTIVE</span></ng-template>
        </ng-template>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="rxType">
		  <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> RX TYPE </mat-header-cell>
      <mat-cell *matCellDef="let prescription; let i=dataIndex; let row" fxFlex="1 1 200px" (click)="toggleExpanded(row)">
		  	{{ prescription.controls['type']['value'] }}
		  </mat-cell>
		</ng-container>

	  <ng-container matColumnDef="examDate">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 100px"> EXAM DATE </mat-header-cell>
      <mat-cell *matCellDef="let prescription; let i=dataIndex; let row;" fxFlex="1 1 100px" (click)="toggleExpanded(row)">
	    	{{ prescription.controls['examDate']['value'] | date:'shortDate' }}
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="doctor">
	    <mat-header-cell *matHeaderCellDef fxFlex> DOCTOR </mat-header-cell>
      <mat-cell *matCellDef="let prescription; let i=dataIndex; let row;" fxFlex (click)="toggleExpanded(row)">
	    	{{ prescription.controls?.['eyeExam']?.['value']?.['finalRx']?.['signoffDoctor'] }}
	    </mat-cell>
	  </ng-container>

		<ng-container matColumnDef="hide">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 75px"> HIDE </mat-header-cell>
	    <mat-cell *matCellDef="let prescription; let i=dataIndex; let row;" fxFlex="0 0 75px">
        <mat-checkbox [formControl]="prescription.controls.archived" (click)="onHidePrescription(row)"></mat-checkbox>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="compare">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 75px"> COMPARE </mat-header-cell>
	    <mat-cell *matCellDef="let prescription; let i=dataIndex; let row;" fxFlex="0 0 75px">
        <mat-checkbox
          (change)="$event ? toggleSelect(prescription) : null"
          [checked]="selection.isSelected(prescription)">
        </mat-checkbox>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="view">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px"> VIEW </mat-header-cell>
	    <mat-cell *matCellDef="let prescription; let i=dataIndex; let row;" fxFlex="0 0 50px">
        <button mat-button (click)="toggleExpanded(row)">
          <mat-icon>visibility</mat-icon>
        </button>
	    </mat-cell>
	  </ng-container>




	  <ng-container matColumnDef="expandedDetail">
	 	  <mat-cell *matCellDef="let prescription; let orderIndex=dataIndex;" class="expandedCell">
	      <div class="example-element-detail" style="width:100%" [class.mat-elevation-z0]="prescription!=expandedElement" [class.mat-elevation-z8]="prescription==expandedElement" [@detailExpand]="prescription == expandedElement ? 'expanded' : 'collapsed'" style=>

					<app-prescription [prescription]="prescription" style="width:100%" [withDelete]="true" (clickDelete)="deletePrescription($event)" (clickSetAsOrder)="setAsOrder($event)" [index]="orderIndex"></app-prescription>

				</div>
	    </mat-cell>
	  </ng-container>




    <mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row"
	  	fxLayoutAlign="start center">
	  </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="start center"
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" style="border-left:1px solid #dfdfdf !important;">
	  </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']"
	  	[class.example-detail-row-expanded]="expandedElement === element"
	  	[class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>

	</mat-table>
</div>
