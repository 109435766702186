import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appClickField]'
})
export class ClickFieldDirective {
  @Input() event: any;
  @Input() fieldOpen: boolean;
  @Output() fieldOpenChange = new EventEmitter<boolean>();

  constructor() { }

  @HostListener('click', ['$event']) onClick(event) {
    this.fieldOpen = true;
    this.fieldOpenChange.emit(true);
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    this.fieldOpen = true;
    this.fieldOpenChange.emit(true);
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event) {
    this.fieldOpen = true;
    this.fieldOpenChange.emit(true);
  }

  @HostListener('dblclick', ['$event']) onDblClick(event) {
    this.fieldOpen = true;
    this.fieldOpenChange.emit(true);
  }

  @HostListener('focus', ['$event']) onFocus(event) {
    setTimeout(function () { event.target.select(); }, 100);
  }

  @HostListener('selectionChange', ['$event']) onSelectionChange(event) {
    this.fieldOpen = true;
    this.fieldOpenChange.emit(true);
  }

  

}
