import { Sort } from '@angular/material/sort';

export const defaultStatus = 'all';
export const defaultPageSizes: Array<number> = [5, 10, 20, 25];
export const orderStatusMap: Record<string, 'paid' | 'unpaid'> = {
  PAID: 'paid',
  RECEIVED: 'unpaid',
  CREDITED: 'unpaid',
  SOLD: 'unpaid',
  RETURNED: 'unpaid',
  UPDATED: 'unpaid',
  all: 'unpaid',
  paid: 'paid',
  unpaid: 'unpaid',
};

/* Column definitions */
export const paidColumns: string[] = [
  'vendor',
  'invoiceNumber',
  'datePaid',
  'tax',
  'shipping',
  'total',
  'options',
];

export const unpaidColumns: string[] = [
  'pay',
  'vendor',
  'status',
  'invoiceNumber',
  'invoiceDate',
  'tax',
  'shipping',
  'payments',
  'amountDue',
  'amountToPay',
  'options',
  'log',
];

export const allColumns: string[] = [
  'pay',
  'vendor',
  'status',
  'invoiceNumber',
  'invoiceDate',
  'tax',
  'shipping',
  'total',
  'link',
  'print',
];

export const defaultColumns = [
  'invoiceNumber',
  'date',
  'status',
  'tax',
  'shipping',
  'total',
  'action',
  'log',
  'fill',
  'expand',
];

export const vendorColumns = {
  distributor: ['vendor'],
};

export const colSortOrder = {
  select: 0,
  vendor: 1,
  status: 2,
  datePaid: 3,
  invoiceNumber: 4,
  date: 5,
  tax: 6,
  shipping: 7,
  total: 8,
  log: 9,
  fill: 10,
  expand: 11,
};

export const statusColumns = {
  paid: ['datePaid'],
  unpaid: ['select'],
};

/* Sort definitions */

export const defaultSort: Sort = { active: 'datePaid', direction: 'desc' };

export const sortConfig = {
  paid: {
    sortBy: 'datePaid',
    sortDir: 'desc',
  },
  unpaid: {
    sortBy: 'date',
    sortDir: 'desc',
  },
  all: {
    sortBy: 'date',
    sortDir: 'desc',
  },
};
