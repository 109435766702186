import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@models/store';
import { StoreService } from '@services/store.service';
import { AuthService } from '@services/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-borrowing',
  templateUrl: './borrowing.component.html',
  styleUrls: ['./borrowing.component.css', './../base/vendors-base/vendors-base.component.css', './../managed-board.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class BorrowingComponent implements OnInit, OnDestroy {
  storeOptions: any[];
  borrowingStore: Store = null;
  currentStore: Store = new Store();
  storeSubscription: Subscription;
  rentalUsers = [];
  currentRentalUser;

  constructor(public storeService: StoreService, private userService: UserService, public auth: AuthService, public cdRef: ChangeDetectorRef) {
    this.storeSubscription = this.auth.getStoreObjectMessage().subscribe((store: Store) => {
      this.currentStore = store;
    });
  }

  ngOnInit() {
    this.storeOptions = this.storeService.stores;
    this.currentStore = this.auth.getStoreObject();
    this.setDefaultBorrowingStore();

    this.userService.getRentalUsers(parseInt(this.auth.getStore(), 0)).subscribe(users => {
      this.rentalUsers = users;
      this.currentRentalUser = users[0];
    });
  }

  ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
  }

  setDefaultBorrowingStore() {
    const store = this.auth.getStore();
  	if (this.storeOptions.length === 2) {
    	for (let x = 0; x < this.storeOptions.length; x++) {
    		if (this.storeOptions[x]._id !== store) {
    			this.borrowingStore = this.storeOptions[x];
          this.cdRef.detectChanges();
    			break;
    		}
	    }
    }
  }

  changeRentalUser({ value }) {
    this.currentRentalUser = this.rentalUsers.find(u => u._id === value);
  }
}
