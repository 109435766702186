import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomizerSettingsService } from './customizer-settings/customizer-settings.service';
import { CustomizerSettingsComponent } from './customizer-settings/customizer-settings.component';
import { MaterialModule } from '@modules/material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatIconModule } from '@angular/material/icon';
import { CasePipe } from './pipes/case.pipe';
import { StoreColorDirective } from '@directives/store-color.directive';
import { NumberPipe } from './pipes/number.pipe';
@NgModule({
  declarations: [
    CustomizerSettingsComponent,
    CasePipe,
    StoreColorDirective,
    NumberPipe,
  ],
  imports: [CommonModule, MaterialModule, NgScrollbarModule, MatIconModule],
  providers: [CustomizerSettingsService],
  exports: [
    CustomizerSettingsComponent,
    CasePipe,
    StoreColorDirective,
    NumberPipe,
  ],
})
export class V2SharedModule {}
