<!--<mat-form-field style="width:100%">
	<input matInput #search autocomplete="off" placeholder="SEARCH INVENTORY" (input)="productSearch()">
</mat-form-field>-->

<div *ngIf="dataLoading">
  <h3 style="text-align:center;">LOADING INVENTORY</h3>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<form [formGroup]="productsForm" enctype="multipart/form-data" (ngSubmit)="submit()" autocomplete="off">
	<input type="hidden" autocomplete="off">

	<div *ngIf="selection.hasValue()">
		<app-inventory-menu [selection]="selection" [productsForm]="productsForm" (borrowing)="borrowMultipleProducts($event)"></app-inventory-menu>
	</div>

  <mat-table #table [dataSource]="dataSource" matSort formArrayName="productsFormArray" multiTemplateDataRows>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef fxFlex="0 0 50px" style="padding-left:0px !important;">
        <mat-checkbox (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelect(order) : null"
          [checked]="selection.isSelected(order)"
          [aria-label]="checkboxLabel(order,i)">
        </mat-checkbox>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 50px" class="alignLeftCellFooter" style="padding-left:0px !important;"> TOTAL </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 85px"> IMAGE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex; " fxFlex="1 0 85px" [formGroupName]="i">
      	<app-images
					[images]="product.value.images"
					[urls]="imageUrls"
					type="product"
					(imagesChange)="updateImages($event,product,productsForm.controls.productsFormArray['controls'][i])"
					[edit]="true"
					[delete]="true"
					[upload]="true"
					[active]="productId==product.value._id"
					[productId]="product.value._id"
					(click)="clickImage($event,product,i)">
				</app-images>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 85px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="model">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 10%"> MODEL </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex; let row; let even=even; let odd=odd;" fxFlex="1 0 10%" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.model}}</span>
      	<app-autocomplete-vendor-products
	  			[productForm]="product"
	    		[vendor]="_vendor"
	  			placeholder="MODEL"
	  			*ngIf="productId==product.value._id && !showParts"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="model"
					style="width:90%"
	  			>
				</app-autocomplete-vendor-products>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 10%"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 0 2.5%"> COLOR </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="1 0 2.5%" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.color}}</span>
      	<app-autocomplete-vendor-products
	  			[productForm]="product"
	    		[vendor]="_vendor"
	  			placeholder="COLOR"
	  			*ngIf="productId==product.value._id && !showParts"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="color"
					style="width:90%"
	  			>
				</app-autocomplete-vendor-products>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="1 0 2.5%"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> SIZE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 40px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.size}}</span>
      	<app-autocomplete-vendor-products
	  			[productForm]="product"
	    		[vendor]="_vendor"
	  			placeholder="SIZE"
	  			*ngIf="productId==product.value._id && !showParts"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="size"
					style="width:90%"
	  			>
				</app-autocomplete-vendor-products>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 40px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 65px"> STATUS </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 65px" style="padding-left:0px;min-width:calc(100%-32px);" [ngClass]="product.value.status" [formGroupName]="i">

      	<mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
					<mat-select #formRow #statusSelect placeholder="STATUS" [formControl]="product.controls.status" (click)="clickField($event,product,index);$event.stopPropagation();" (selectionChange)="changeProductStatus($event,product,i)">
						<mat-option value="green" class="green"></mat-option>
						<mat-option value="yellow" class="yellow"></mat-option>
						<mat-option value="red" class="red"></mat-option>
						<mat-option value="purple" class="purple"></mat-option>
						<mat-option value="white" class="white"></mat-option>
						<mat-option value="black" class="black"></mat-option>
					</mat-select>
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 65px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> TYPE </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px" [formGroupName]="i">
      	<span *ngIf="(productId!=product.value._id && product.value._id!='NEW') || showParts">{{product.value.type}}</span>
				<mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
					<mat-select #formRow placeholder="TYPE" [formControl]="product.controls.type" (click)="clickField($event,product,index);$event.stopPropagation();" (selectionChange)="changeProductType(product)">
						<mat-option *ngFor="let typeOption of typeOptions" [value]="typeOption.value">
							{{typeOption.viewValue}}
						</mat-option>
					</mat-select>
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 50px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> COST </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 60px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || !authorized || showParts">{{ product.value.cost | currency }}</span>
				<mat-form-field *ngIf="productId==product.value._id && authorized && !showParts" floatLabel="never">
          <input #formRow matInput placeholder="COST" type="text" [formControl]="product.controls.cost" (keyup)="changeCost(product)">
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 60px"> {{ getTotalCost() | currency }} </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="retail">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> RETAIL </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 60px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || !authorized || showParts">{{ product.value.retail | currency }}</span>
				<mat-form-field *ngIf="productId==product.value._id && authorized && !showParts" floatLabel="never">
          <input #formRow matInput placeholder="RETAIL" type="text" [formControl]="product.controls.retail" (keyup)="changeRetail(product)">
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 60px"> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> QTY. </mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px" [formGroupName]="i">
      	<span *ngIf="productId!=product.value._id || showParts">{{product.value.quantity}}</span>
				<mat-form-field *ngIf="productId==product.value._id && !showParts" floatLabel="never">
          <input #formRow matInput placeholder="QTY." type="text" [formControl]="product.controls.quantity">
        </mat-form-field>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 50px"> {{ getTotalQty() }} </mat-footer-cell>
    </ng-container>

 		<ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 120px">
        <ng-container *ngIf="!rentingUser">RETURN</ng-container>
      </mat-header-cell>
	    <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 120px" [formGroupName]="i">

        <button mat-icon-button type="button" *ngIf="!rentingUser" (click)="borrowingStore ? returnBorrowedProduct($event,product) : returnRentingProduct($event,product)" style="margin-left:10px;"><mat-icon>undo</mat-icon></button>

        <button *ngIf="rentingUser" type="button" (click)="markAsSold($event,product)" style="margin-left:10px;">MARK AS SOLD</button>

	    </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 120px"> </mat-footer-cell>
	  </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"></mat-header-cell>
      <mat-cell *matCellDef="let product; let i=dataIndex;" fxFlex="0 0 50px" [formGroupName]="i">
				<mat-icon (click)="deleteProduct($event, product,i)">delete_outline</mat-icon>
      </mat-cell>
    	<mat-footer-cell *matFooterCellDef fxFlex="0 0 50px"> </mat-footer-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let product; let orderIndex=dataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="product!=expandedElement" [class.mat-elevation-z8]="product==expandedElement && product.value._id!='NEW'" [@detailExpand]="product == expandedElement ? 'expanded' : 'collapsed'">

        <app-inventory-history *ngIf="product==expandedElement" [product]="product" [showParts]="showParts" style="width:100%;"></app-inventory-history>

        </div>
      </mat-cell>
    </ng-container> -->

    <mat-header-row
    	*matHeaderRowDef="displayedColumns"
    	fxLayout="row"
    	fxLayoutAlign="none center"></mat-header-row>
    <mat-row
    	*matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;"
    	fxLayout="row"
    	fxLayoutAlign="none center"
    	(click)="clickRow($event,row,i)"
    	(dblclick)="clickRow($event,row,i)"
    	[class.dirty-row]="row.dirty"
    	[ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}"
    	[class.return]="row.value.status=='return'"
    	[class.returned]="row.value.status=='returned'"
    	color="warn"
    	></mat-row>
    <!-- <mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row> -->


    <ng-container *ngIf="authorizedSendToStack">
	  	<mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
	  </ng-container>



  </mat-table>
</form>
