<div>
	<form [formGroup]="editUserForm">
		<!--<div *ngFor="let user of editUserForm.controls.editUserForms.controls; let i=index " class="item" fxFlex>
			{{i}}
			<div [formGroupName]="i">
			<span *ngIf="editUserId!=user._id">{{user.name}}</span>
				<span *ngIf="editUserId==user._id">
					<mat-form-field style="width:100%" floatlabel="never">
	                  <input matInput placeholder="Name" type="text" formControlName="name" [(value)]="user.name">
	                </mat-form-field>
	            </span>
	            <span *ngIf="editUserId==user._id">
					<mat-form-field style="width:100%" floatlabel="never">
	                  <mat-select placeholder="ROLE" formControlName="role" [(value)]="user.role">
	                    <mat-option value="ADMIN">ADMIN</mat-option>
	                    <mat-option value="SUPERUSER">SUPERUSER</mat-option>
	                  </mat-select>
	                </mat-form-field>
	            </span>
	        </div>
        </div>
        </div>
-->
		<mat-table #table [dataSource]="dataSource" formArrayName="editUserForms">
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef> NAME </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index;" [formGroupName]="getIdx(i)">
					<span *ngIf="editUserId!=user._id">{{user.name}}</span>
					<!--<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <input matInput placeholder="Name" type="text" formControlName="name" [(value)]="editUserForm.controls.editUserForms.controls[i].value.name">
		                </mat-form-field>
					</span>-->
					<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <input matInput placeholder="NAME" type="text" formControlName="name">
		                </mat-form-field>
					</span>
	             </mat-cell>
			</ng-container>

			<ng-container matColumnDef="initials">
				<mat-header-cell *matHeaderCellDef> INITIALS </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index;" [formGroupName]="getIdx(i)">
					<span *ngIf="editUserId!=user._id">{{user.initials}}</span>
					<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
                            <input matInput placeholder="INITIALS" type="text" formControlName="initials">
                        </mat-form-field>
					</span>
                </mat-cell>
			</ng-container>

			<ng-container matColumnDef="email">
				<mat-header-cell *matHeaderCellDef> EMAIL </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index;" [formGroupName]="getIdx(i)">
					<span *ngIf="editUserId!=user._id">{{user.email}}</span>
					<!--<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <input matInput placeholder="Email" type="text" formControlName="email" [(value)]="editUserForm.controls.editUserForms.controls[i].value.email">
		                </mat-form-field>
					</span>-->
					<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <input matInput placeholder="EMAIL" type="text" formControlName="email">
		                </mat-form-field>
					</span>
	             </mat-cell>
			</ng-container>

			<ng-container matColumnDef="role">
				<mat-header-cell *matHeaderCellDef> ROLE </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index" [formGroupName]="getIdx(i)">
					<span *ngIf="editUserId!=user._id">{{user.role}}</span>
					<!--<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <mat-select placeholder="ROLE" formControlName="role" [(value)]="editUserForm.controls.editUserForms.controls[i].value.role">
		                    <mat-option value="ADMIN">ADMIN</mat-option>
		                    <mat-option value="SUPERUSER">SUPERUSER</mat-option>
		                  </mat-select>
		                </mat-form-field>
		            </span>-->
		            <span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <mat-select placeholder="ROLE" formControlName="role" (click)="clickRole($event,user,i)" (selectionChange)="changeRole(user,i)">
		                    <mat-option *ngFor="let roleOption of roleOptions" [value]="roleOption">{{roleOption}}</mat-option>
		                  </mat-select>
		                </mat-form-field>
		            </span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="stores">
				<mat-header-cell *matHeaderCellDef> STORES </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index" [formGroupName]="getIdx(i)">
					<span *ngIf="editUserId!=user._id">{{getStoreNames(user.stores)}}</span>
		            <span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <mat-select multiple placeholder="STORES" formControlName="stores" (click)="clickStores($event,user,i)" (selectionChange)="changeStores(user,i)">
		                    <mat-option *ngFor="let storeOption of storeOptions" [value]="storeOption._id" (click)="clickStoresOption($event,user,i)">{{storeOption.storeName}}</mat-option>
		                  </mat-select>
		                </mat-form-field>
		            </span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="password">
				<mat-header-cell *matHeaderCellDef> PASSWORD </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index;" [formGroupName]="getIdx(i)">
					<!--<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <input matInput placeholder="Email" type="text" formControlName="email" [(value)]="editUserForm.controls.editUserForms.controls[i].value.email">
		                </mat-form-field>
					</span>-->
					<span *ngIf="editUserId==user._id">
						<mat-form-field style="width:100%" floatLabel="never">
		                  <input matInput placeholder="PASSWORD" type="password" formControlName="password">
		                </mat-form-field>
					</span>
	             </mat-cell>
			</ng-container>

			<ng-container matColumnDef="anyIp">
				<mat-header-cell *matHeaderCellDef> ANY IP </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index" [formGroupName]="getIdx(i)">
                    <ng-template #noAnyIpEditBlock>
                        <mat-icon *ngIf="user.anyIp">check</mat-icon>
                    </ng-template>
		            <span *ngIf="editUserId === user._id; else noAnyIpEditBlock">
						<mat-checkbox formControlName="anyIp" (click)="$event.stopPropagation()"></mat-checkbox>
		            </span>
				</mat-cell>
			</ng-container>

            <ng-container matColumnDef="disabled">
				<mat-header-cell *matHeaderCellDef> DISABLED </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index" [formGroupName]="getIdx(i)">
                    <ng-template #noDisabledEditBlock>
                        <mat-icon *ngIf="user.disabled">check</mat-icon>
                    </ng-template>
		            <span *ngIf="editUserId === user._id; else noDisabledEditBlock">
						<mat-checkbox formControlName="disabled" (click)="$event.stopPropagation()"></mat-checkbox>
		            </span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="save">
				<mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;">  </mat-header-cell>
				<mat-cell *matCellDef="let user; let i=index" [formGroupName]="getIdx(i)" style="flex: 0 0 100px;">
					<span *ngIf="editUserId!=user._id">
                        <button mat-icon-button title="Delete" (click)="deleteUser($event, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
		            <span *ngIf="editUserId==user._id">
                        <button mat-icon-button title="Save" (click)="saveEditedUser()">
                            <mat-icon>save</mat-icon>
                        </button>
                        <button mat-icon-button title="Cancel" (click)="cancelUserEdit($event)">
                            <mat-icon>close</mat-icon>
                        </button>
		            </span>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns; let i=index;" appHighlight (click)="clickRow($event,row,i)"></mat-row>
		</mat-table>

		<div class="container"
			  fxLayout="row"
			  fxLayoutAlign="space-between center"
			  fxLayoutGap="10px"
			  fxLayoutGap.xs="0">
			<div class="item item-1" fxFlex="50%">
			  <button mat-raised-button type="button" (click)="addUser($event)">+ ADD USER</button>
			</div>
			<div class="item item-2" fxFlex="50%">
				<mat-paginator #paginator
					[length]="dataSource.data.length"
					[pageSize]="10"
					[pageSizeOptions]="[5, 10, 20]">
				</mat-paginator>
			</div>
		</div>
	</form>
    <ng-template #confirmDelete>
        <h1 mat-dialog-title>Delete user</h1>
        <div mat-dialog-content>Would you like to delete this user?</div>
        <div mat-dialog-actions>
            <button mat-button [mat-dialog-close]="false">No</button>
            <button mat-button [mat-dialog-close]="true">Yes</button>
        </div>
    </ng-template>
</div>
