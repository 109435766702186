import { Pipe, Inject, LOCALE_ID, Component, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor } from '@models/vendor';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, of, Subscription } from 'rxjs';
import { OrdersBaseComponent } from '@components/admin/managed-board/base/orders-base/orders-base.component';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-stack-orders',
  templateUrl: './stack-orders.component.html',
  styleUrls: ['../../managed-board.component.css', './stack-orders.component.scss'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class StackOrdersComponent extends OrdersBaseComponent implements OnInit {
	@Input() public vendorOrders: VendorOrders;
  @Input() public orderIndex: number;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
  displayedColumns = ['alert', 'images', 'model', 'color', 'size', 'type', 'patientStock', 'patient', 'invoiceNumber', 'todo', 'eta', 'log'];
  displayedColumns2 = ['notes', 'cost', 'priceLink', 'retail', 'duty', 'action'];
  displayedColumns3 = ['notes', 'cost', 'priceLink', 'retail', 'duty', 'retailPlusDuty', 'action'];

  constructor(@Inject(LOCALE_ID) public locale: string, public auth: AuthService, public orderService: OrderService, public vendorOrderService: VendorOrderService, public productService: ProductService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public fb: FormBuilder, public route: ActivatedRoute, public notificationService: NotificationService) {
    super(locale, auth, orderService, vendorOrderService, productService, storeService, cdRef, fb, route, notificationService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  // initOrderFormItem(order: Order) {
  // 	let productsFormArray:FormArray = this.fb.array([]);
  // 	for (var x = 0; x < order.products.length; x++) {
  // 		order.products[x].product.images = null;
  //     let productElementFormGroup: FormGroup = this.fb.group({
  //       'cost': order.products[x].cost,
  //       'pricingFormula': order.products[x].pricingFormula,
  //       'retail': order.products[x].retail,
  //       'duty': [{value:order.products[x].duty, disabled: true}],
  //       'quantity': order.products[x].quantity,
  //       'frame': this.fb.group(order.products[x].frame),
  //       'product': this.fb.group(order.products[x].product),
  //       'store': (order.products[x].store) ? order.products[x].store : this.auth.getStoreObject(),
  //       'type': order.products[x].type
  //     });
  //     order.products[x].product.images = (Array.isArray(order.products[x].product.images)) ? order.products[x].product.images : [];
  //     let imageArray: FormArray = this.fb.array(order.products[x].product.images);
  //     let productFormGroup = productElementFormGroup.get('product') as FormGroup;
  //     productFormGroup.removeControl('images');
  //     productFormGroup.addControl('images', imageArray);
      
  //     productElementFormGroup.get('product.vendor').setValidators(Validators.required);
  //     productsFormArray.push(productElementFormGroup);
  // 	}
  //   return this.fb.group({
  //     '_id': order._id,
  //     'vendor': this.vendorOrders.vendor,
  //     'type': [order.type, Validators.required],
  //     'invoiceNumber': [order.invoiceNumber, Validators.required],
  //     'todo': order.todo,
  //     'notify': [{value:order.notify, disabled: true}],
  //     'eta': order.eta,
  //     'initials': ['', Validators.required],
  //     'notes': (order.log && order.log.length > 0) ? order.log[order.log.length-1].notes : '',
  //     'products': productsFormArray,
  //     'patient': order['patient'],
  //     'status': order.status,
  //     'log': this.fb.array(order.log)
  //   });
  // }


}
