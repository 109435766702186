
<mat-dialog-content>
	<div class="container" style="width:100%;"
	  fxLayout="row"
	  fxLayoutAlign="space-between start">
	  <div fxFlex="1 0 45%" *ngFor="let image of images">
	    <!-- <app-image [src]="image" style="max-width:100%"></app-image> -->
	    <app-image [image]="image" type="gallery" style="max-width:100%">
			</app-image>

	  </div>
	</div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">CLOSE</button>
</mat-dialog-actions>
