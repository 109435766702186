import { Pipe, Component, Inject, LOCALE_ID, OnInit, Output, EventEmitter, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Product, ProductElement, ProductQuery } from '@models/product';
import { Vendor, VendorInventory } from '@models/vendor';
import { Store } from '@models/store';
import { Order, LogElement } from '@models/order';
import { Image } from '@models/image';
import { ProductService } from '@services/product.service';
import { VendorService } from '@services/vendor.service';
import { StoreService } from '@services/store.service';
import { OrderService } from '@services/order.service';
import { OrderForm } from '@classes/order-form';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSelect } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';;
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { ImageEditComponent } from '@components/admin/image/image-edit/image-edit.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { OkCancelComponent } from '@components/admin/dialog/ok-cancel/ok-cancel.component';
import { formatNumber, formatDate } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-duplicate-products',
  templateUrl: './duplicate-products.component.html',
  styleUrls: ['./duplicate-products.component.css'],
  animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DuplicateProductsComponent implements OnInit {
  @Input()
  set vendorInventory(val: VendorInventory) {
    this._vendorInventory = val;
    console.log(val);
    this.dataSource = new MatTableDataSource<any>(val.products);
    this.cdRef.detectChanges();
  }
  public _vendorInventory: VendorInventory;
	duplicatesRoutePath = '';

	@Output() removeDuplicate : EventEmitter<boolean> = new EventEmitter<boolean>();


	dataSource = new MatTableDataSource<any>();
	// displayedColumns: string[] = ['images', 'model', 'color', 'size', 'status', 'type', 'cost', 'priceLink', 'retail', 'store', 'quantity', 'action', 'delete'];
	displayedColumns: string[] = ['count', 'model', 'color', 'size', 'type'];
  expandedElement = null;

  constructor(@Inject(LOCALE_ID) private locale: string, private vendorService: VendorService, private storeService: StoreService, private auth: AuthService, private productService: ProductService, private orderService: OrderService, private fb: FormBuilder, private cdRef:ChangeDetectorRef, public dialog: MatDialog, public route: ActivatedRoute) {
  	this.duplicatesRoutePath = this.route.routeConfig.path;
  }

  ngOnInit() {

  }

	clickRow($event: Event, product: FormGroup, index) {
    this.expandedElement = product;
		this.cdRef.detectChanges();
	}

	keep(product:Product, id:string, qty:number, index:number, index2:number) {
		let deleteIds = product['uniqueIds'].slice(0);
		deleteIds.splice(index2,1);


		if (this.duplicatesRoutePath == 'fix-duplicate-products-size') {
	    this.productService.fixDuplicateProductsSize(id, deleteIds, qty, product['_id']['size']).subscribe((productObject: Product) => {

		  	this.dataSource.data.splice(index, 1);
				this.dataSource._updateChangeSubscription();
		    this.cdRef.detectChanges(); 
	    });

  	} else {
	    this.productService.fixDuplicateProducts(id, deleteIds, qty).subscribe((productObject: Product) => {

		  	this.dataSource.data.splice(index, 1);
				this.dataSource._updateChangeSubscription();
		    this.cdRef.detectChanges(); 
	    });

  	}

	    this.productService.fixDuplicateProducts(id, deleteIds, qty).subscribe((productObject: Product) => {

		  	this.dataSource.data.splice(index, 1);
				this.dataSource._updateChangeSubscription();
		    this.cdRef.detectChanges(); 
	    });

		// deleteIds: update vendor orders with products.product._id -> new keep ID
		// deleteIds: update orders with products.product._id -> new keep ID
	}

}
