<div *ngIf="order" class="d-flex align-items-center">
  <button class="btn icon dark" [disabled]="!order?.notes?.length" (click)="vieOrderNotes()">
    <mat-icon matTooltip="View Notes">notes</mat-icon>
  </button>
  <button class="btn icon dark" (click)="addOrderNotes()">
    <mat-icon matTooltip="Add Note">edit_note</mat-icon>
  </button>
</div>

<ng-template #notesDetail>
  <ul *ngIf="order?.notes?.length" class="ao-log open-sans">
    <ng-container *ngFor="let log of order.notes">
      <li class="d-flex flex-column">
        <p class="log-item flex-grow-1">
          {{ log.notes ?? '' }}
        </p>
        <div
          class="d-flex align-items-center justify-between flex-grow-1 w-100"
        >
          <span class="log-date d-flex align-items-center"
            ><mat-icon class="icon">event</mat-icon>
            <span class="log-value">{{ log.date | date: 'short' }}</span></span
          >
          <span class="log-initials d-flex align-items-center"
            ><mat-icon class="icon">gesture</mat-icon>
            <span class="log-value">{{ log.initials }}</span></span
          >
        </div>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #notesForm>
  <div *ngIf="noteForm" class="notes-form" [formGroup]="noteForm">
    <div class="container note-text">
      <mat-form-field class="w-100" floatLabel="never">
        <textarea
          matInput
          class="invoice-notes"
          formControlName="notes"
          rows="3"
          placeholder="Add Note"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="fix-actions color">
      <div
        class="flex-grow-1 d-flex align-items-center justify-content-between no-shrink"
      >
        <div class="input signature">
          <mat-form-field floatLabel="never">
            <input
              class="sm signature"
              matInput
              required
              placeholder=""
              type="text"
              formControlName="initials"
            />
            <span matSuffix><mat-icon>gesture</mat-icon></span>
          </mat-form-field>
        </div>
        <div class="flex-grow-1 d-flex align-items-center justify-right">
          <button
            class="btn inverse"
            [disabled]="noteForm.invalid"
            (click)="addNote()"
          >
            <mat-icon class="lightest"> save </mat-icon>
          </button>
          <button mat-dialog-close class="btn icon secondary">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
