<div *ngIf="loading">
	<h3 style="text-align:center;">LOADING REPORT</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!loading" style="width:100%">

  <mat-table #table [dataSource]="dataSource">

  	<ng-container matColumnDef="frames">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> # FRAMES </mat-header-cell>
      <mat-cell *matCellDef="let data; let i=index;" fxFlex="1 1 10%">
        <!-- <span>{{ data._id }} - {{ report.data[i+1]?._id }}</span> -->
        <span>{{ data._id }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" >{{ calculateFramesTotal() }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="customers">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> # CUSTOMERS </mat-header-cell>
      <mat-cell *matCellDef="let data; let i=index;" fxFlex="1 1 10%">
        <span>{{ data.count}}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%" > {{ calculateCustomersTotal() }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="percentage">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 10%"> % CUSTOMERS </mat-header-cell>
      <mat-cell *matCellDef="let data; let i=index;" fxFlex="1 1 10%">
        <span>{{ getPercentage(data.count, calculateCustomersTotal()) }}%</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef fxFlex="1 1 10%"> </mat-footer-cell>
    </ng-container>


    <mat-header-row 
      *matHeaderRowDef="displayedColumns" 
      fxLayout="row" 
      fxLayoutAlign="start center">
    </mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
      fxLayout="row" 
      fxLayoutAlign="start center" 
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}">
    </mat-row>
  	<mat-footer-row *matFooterRowDef="displayedColumns" style="background-color:#fcfcfc;"></mat-footer-row>

  </mat-table>


</div>