<div class="container" style="width:100%" [formGroup]="orderForm" *ngIf="dataSource.data">
	<mat-table [dataSource]="dataSource3" class="mat-elevation-z2" fxFlex>
		<ng-container matColumnDef="invoiceNumber">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> VENDOR INVOICE # </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
		      <input matInput required placeholder="INVOICE #" formControlName="invoiceNumber" style="text-align:center;" (click)="clickField()">
		      <mat-error *ngIf="order.controls.invoiceNumber.hasError('required')"></mat-error>
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="date">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> INVOICE DATE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
				  <input #formRow required [matDatepicker]="picker" matInput placeholder="DATE" type="text" style="text-align:center;" formControlName="date" (click)="clickField()">
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	      	<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="tax">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> TAX </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
		      <input matInput required placeholder="TAX" formControlName="tax" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.tax)" (keyup)="calculateTotal()">
		      <span matPrefix>$&nbsp;</span>
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="shipping">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> SHIPPING </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<mat-form-field floatLabel="never">
		      <input matInput required placeholder="SHIPPING" formControlName="shipping" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.shipping)" (keyup)="calculateTotal()">
		      <span matPrefix>$&nbsp;</span>
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="total">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 20%"> TOTAL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 20%">
	    	<span>{{ order.value.total | currency }}</span>
	    	<!-- <mat-form-field floatLabel="never">
		      <input matInput required placeholder="TOTAL" formControlName="total" style="text-align:right;" (click)="clickField()" (blur)="formatCurrency($event,order.controls.total)" (keyup)="calculateTotal()">
		      <span matPrefix>$&nbsp;</span>
		    </mat-form-field> -->
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef fxFlex="1 1 150px">  </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 150px" style="border-right:0px !important;">
	    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text" formControlName="initials" (click)="clickField()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
	      <button 
	      	mat-button 
	      	type="button" 
	      	(click)="saveFrames()" 
	      	[disabled]="order.controls.invoiceNumber.invalid ||
	      	order.controls.date.invalid ||
	      	order.controls.tax.invalid ||
	      	order.controls.shipping.invalid ||
	      	selectionInvalid ||
	      	order.controls.total.invalid ||
	      	order.controls.initials.invalid
	      	">
	      	<mat-icon>save</mat-icon><span>SAVE</span>
	      </button>

	    </mat-cell>
	  </ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns" fxLayout="row" fxLayoutAlign="none center"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" fxLayout="row" fxLayoutAlign="none center" class="action-row"></mat-row>
	</mat-table>
</div>