import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, BehaviorSubject, map, tap, timer } from 'rxjs';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import ObjectId from 'bson-objectid';
import { ImageService } from '@services/image.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css'],
})
export class ImageDialogComponent {
  imageDelete: EventEmitter<string> = new EventEmitter<string>();
  imageReplace: EventEmitter<string> = new EventEmitter<string>();
  imageCrop: EventEmitter<string> = new EventEmitter<string>();
  imageUpload: EventEmitter<string> = new EventEmitter<string>();
  imageAdd: EventEmitter<string> = new EventEmitter<string>();
  imageUrl: BehaviorSubject<any> = new BehaviorSubject('');
  public uploaderProduct: FileUploader = new FileUploader({
    url: environment.base_url + '/image/product',
    itemAlias: 'photoProduct',
    disableMultipart: true,
  });
  rnd = Math.floor(Math.random() * 1000000);
  image: string;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    // this.imageUrl.next(data.image);
    console.log(data);
  }

  ngOnInit() {
    this.uploaderProduct.onAfterAddingFile = (item: FileItem) => {
      const objectKey = new ObjectId().toString();
      this.image = objectKey;
      this.imageService
        .getUploadUrl(objectKey, item.file.type)
        .subscribe((url) => {
          item.url = url;
          item.method = 'PUT';
          item.headers = [{ name: 'Content-Type', value: item.file.type }];
          item.withCredentials = false;
          item.upload();
        });
    };

    this.uploaderProduct.onCompleteAll = () => {
      this.imageService.deleteImage(this.data.imageId).subscribe((res) => {
        this.imageReplace.emit(this.image);
      });

      // for (let x = 0; x < this.images.length; x++) {
      //   	this.imageAdd.emit(this.images[x]);
      // }
    };
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.dialogRef.close(true);
  }

  clickDelete(imageId) {
    this.imageDelete.emit(imageId);
  }

  clickCrop(imageId) {
    this.imageCrop.emit(imageId);
  }

  clickUpload(imageId) {
    this.imageUpload.emit(imageId);
  }

  clickOpenUpload(id: string) {
    document.getElementById(id).click();
  }

  onPrint() {
    this.getFileStream().subscribe((res: any) => {
      const content = `<img src="${res}" />`;
      const iframe = document.createElement('iframe');
      iframe.width = '1px';
      iframe.height = '1px';
      iframe.src = res;
      document.body.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(content);
      iframe.contentWindow.onafterprint = () => {
        document.body.removeChild(iframe);
      };
      timer(250).subscribe(() => iframe.contentWindow.print());
    });
  }

  onDownload() {
    this.getFileStream().subscribe((res: any) => {
      window.open(res, '_blank', 'width=1,height=1,menubar=no,toolbar=no');
    });
  }

  getFileStream() {
    return this.imageService.getImage(this.data.imageId);
  }
}

