import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { AuthService } from '@services/auth.service';
import { VendorBaseComponent } from '@components/admin/inventory/vendor-base/vendor-base.component';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-managed-board-inventory',
  templateUrl: './managed-board-inventory.component.html',
  styleUrls: ['./managed-board-inventory.component.css']
})
export class ManagedBoardInventoryComponent implements OnInit {

  constructor(public auth: AuthService, public vendorService: VendorService, public productService: ProductService, public vendorOrderService: VendorOrderService, public storeService: StoreService, public cdRef:ChangeDetectorRef, public route: ActivatedRoute, public fb: FormBuilder, public dialog: MatDialog) {
  }

  ngOnInit() {
  }

}
