import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {
  NgModule,
  LOCALE_ID,
  APP_INITIALIZER,
  SecurityContext,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RoutingModule } from '@modules/routing/routing.module';
import { AppComponent } from '@components/app.component';
import { MaterialModule } from '@modules/material/material.module';
import {
  DateModule,
  CustomDateAdapter,
  MY_DATE_FORMATS,
} from '@modules/date/date.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FileUploadModule, FileSelectDirective } from 'ng2-file-upload';

import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Platform, PlatformModule } from '@angular/cdk/platform';
// import { NgImageSliderModule } from 'ng-image-slider';
// import { SliderModule } from 'angular-image-slider';
// import { NgxGalleryModule } from 'ngx-gallery';

import { AdminComponent } from './components/admin/admin.component';
import { AdminAdminComponent } from './components/admin/admin/admin.component';
import { AutocompletePatientComponent } from './components/admin/autocomplete-patient/autocomplete-patient.component';
import { FavoritesComponent } from './components/admin/favorites/favorites.component';
import { FormFavoritesComponent } from './components/admin/patient/form-favorites/form-favorites.component';
import { FormPatientComponent } from './components/admin/patient/form-patient/form-patient.component';
import { FormPrescriptionComponent } from './components/admin/patient/form-prescription/form-prescription.component';
import { FormPrescriptionGroupComponent } from './components/admin/patient/form-prescription-group/form-prescription-group.component';
import { ImportCustomerComponent } from './components/admin/import-customer/import-customer.component';
import { ImportCustomerImagesComponent } from './components/admin/patient/import-customer-images/import-customer-images.component';
import { MenuComponent } from './components/admin/menu/menu.component';
import { OkCancelComponent } from './components/admin/dialog/ok-cancel/ok-cancel.component';
import { SaveComponent } from './components/admin/dialog/save/save.component';
import { UnsavedComponent } from './components/admin/dialog/unsaved/unsaved.component';
import { LogoutComponent } from './components/admin/dialog/logout/logout.component';
import { DeleteComponent } from './components/admin/dialog/delete/delete.component';
import { PatientsComponent } from './components/admin/patients/patients.component';
import { VendorsComponent } from './components/admin/vendors/vendors.component';
import { UsersComponent } from './components/admin/users/users.component';
import { AppConfigService } from '@services/app-config.service';
import { PatientService } from '@services/patient.service';
import { FavoriteLineService } from '@services/favorite-line.service';
import { TokenInterceptorService } from '@services/token-interceptor.service';
import { ThumbnailDirective } from '@directives/thumbnail.directive';
import { NewlinePipe } from '@pipes/newline.pipe';
import { HighlightDirective } from '@directives/highlight.directive';
import { PhonePipe } from '@pipes/phone.pipe';
import { StoreNamePipe } from './pipes/store-name.pipe';

import { InventoryComponent } from './components/admin/inventory/inventory.component';
import { ManagedBoardComponent } from './components/admin/managed-board/managed-board.component';
import { ManagedBoardInventoryComponent } from './components/admin/managed-board/managed-board-inventory/managed-board-inventory.component';
import { ManagedBoardAwaitingFramesComponent } from './components/admin/managed-board/managed-board-awaiting-frames/managed-board-awaiting-frames.component';
import { ManagedBoardStackComponent } from './components/admin/managed-board/managed-board-stack/managed-board-stack.component';
import { StackOrdersComponent } from './components/admin/managed-board/managed-board-stack/stack-orders/stack-orders.component';

import { FormOrderImageComponent } from './components/admin/patient/form-order-image/form-order-image.component';
import { FormOrderImageGroupComponent } from './components/admin/patient/form-order-image-group/form-order-image-group.component';
import { AutocompleteVendorProductsComponent } from './components/admin/autocomplete-vendor-products/autocomplete-vendor-products.component';
import { AutocompleteVendorsComponent } from './components/admin/autocomplete-vendors/autocomplete-vendors.component';
import { StackInquiriesComponent } from './components/admin/managed-board/managed-board-stack/stack-inquiries/stack-inquiries.component';
import { StackVendorsComponent } from './components/admin/managed-board/managed-board-stack/stack-vendors/stack-vendors.component';
import { PatientInquiriesComponent } from './components/admin/patient/patient-inquiries/patient-inquiries.component';
import { PatientOrdersComponent } from './components/admin/patient/patient-orders/patient-orders.component';
import { OrdersBaseComponent } from './components/admin/managed-board/base/orders-base/orders-base.component';
import { VendorsBaseComponent } from './components/admin/managed-board/base/vendors-base/vendors-base.component';
import { AwaitingFramesOrdersComponent } from './components/admin/managed-board/managed-board-awaiting-frames/awaiting-frames-orders/awaiting-frames-orders.component';
import { AwaitingFramesSubmitComponent } from './components/admin/managed-board/managed-board-awaiting-frames/awaiting-frames-submit/awaiting-frames-submit.component';
import { VendorBaseComponent } from './components/admin/inventory/vendor-base/vendor-base.component';
import { InventoryBaseComponent } from './components/admin/inventory/inventory-base/inventory-base.component';
import { PayableComponent } from './components/admin/admin/payable/payable.component';
import { PayableProductsComponent } from './components/admin/admin/payable/payable-products/payable-products.component';
import { PayableOrdersComponent } from './components/admin/admin/payable/payable-orders/payable-orders.component';
import { InventoryHistoryComponent } from './components/admin/inventory/inventory-history/inventory-history.component';
import { LogMenuComponent } from './components/admin/log-menu/log-menu.component';
import { ReturnsComponent } from './components/admin/managed-board/returns/returns.component';
import { ReturnsOrdersComponent } from './components/admin/managed-board/returns/returns-orders/returns-orders.component';
import { ReturnsSubmitComponent } from './components/admin/managed-board/returns/returns-submit/returns-submit.component';
import { ReturnsSentComponent } from './components/admin/managed-board/returns/returns-sent/returns-sent.component';
import { ReturnOrderDialogComponent } from './components/admin/patient/return-order-dialog/return-order-dialog.component';
import { FixOrdersComponent } from './components/admin/admin/fix-orders/fix-orders.component';
import { GalleryComponent } from './components/admin/dialog/gallery/gallery.component';
import { FixDuplicateProductsComponent } from './components/admin/admin/fix-duplicate-products/fix-duplicate-products.component';
import { DuplicateProductsComponent } from './components/admin/admin/fix-duplicate-products/duplicate-products/duplicate-products.component';
import { DuplicateProductsHistoryComponent } from './components/admin/admin/fix-duplicate-products/duplicate-products-history/duplicate-products-history.component';
import { BorrowingComponent } from './components/admin/managed-board/borrowing/borrowing.component';
import { BorrowingProductsComponent } from './components/admin/managed-board/borrowing/borrowing-products/borrowing-products.component';
import { BorrowingVendorsComponent } from './components/admin/managed-board/borrowing/borrowing-vendors/borrowing-vendors.component';
import { ReceivableComponent } from './components/admin/admin/receivable/receivable.component';
import { PriceCheckComponent } from './components/admin/inventory/price-check/price-check.component';
import { InventoryMenuComponent } from './components/admin/inventory/inventory-menu/inventory-menu.component';
import { PatientComponent } from './components/admin/patient/patient.component';
import { ReportsComponent } from './components/admin/admin/reports/reports.component';
import { TaxReportComponent } from './components/admin/admin/reports/tax-report/tax-report.component';
import { TaxReportOrdersComponent } from './components/admin/admin/reports/tax-report/tax-report-orders/tax-report-orders.component';
import { BorrowingOrdersComponent } from './components/admin/managed-board/borrowing/borrowing-orders/borrowing-orders.component';
import { OrderComponent } from './components/admin/order/order.component';
import { FrameDetailsComponent } from './components/admin/order/frame-details/frame-details.component';
import { PrescriptionDetailsComponent } from './components/admin/order/prescription-details/prescription-details.component';
import { LensDetailsComponent } from './components/admin/order/lens-details/lens-details.component';
import { PaymentDetailsComponent } from './components/admin/order/payment-details/payment-details.component';
import { RepairDetailsComponent } from './components/admin/order/repair-details/repair-details.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CustomersReportComponent } from './components/admin/admin/reports/customers-report/customers-report.component';
import { ExamsComponent } from './components/admin/exams/exams.component';
import { PrescriptionsComponent } from './components/admin/patient/prescriptions/prescriptions.component';
import { PrescriptionComponent } from './components/admin/patient/prescription/prescription.component';
import { PrescriptionSideComponent } from './components/admin/patient/prescription/prescription-side/prescription-side.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ClickFieldDirective } from '@directives/click-field.directive';
import { VendorInvoicesComponent } from './components/admin/vendors/invoices/invoices.component';
import { Ng2FittextModule } from 'ng2-fittext';
import { ChangeLogComponent } from './components/admin/change-log/change-log.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ManagedBoardInvoiceComponent } from '@components/admin/managed-board/invoice/invoice.component';
import { PayableOrderComponent } from './components/admin/admin/payable/payable-order/payable-order.component';
import { CustomersEmailComponent } from './components/admin/admin/reports/customers-email/customers-email.component';
import { HomeComponent } from '@components/home/home.component';
import { V2Module } from './v2/v2.module';
import { ManagedBoardCreditComponent } from '@components/admin/managed-board/credit/credit.component';
import { UiCommonModule } from './v2/common/ui-common.module';
import { ImageModule } from '@components/admin/image/image.module';
import { CustomersExpiredRxComponent } from './components/admin/admin/reports/customers-expired-rx/customers-expired-rx.component';
import { PrintModule } from '@components/admin/printing/print.module';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminAdminComponent,
    AutocompletePatientComponent,
    FavoritesComponent,
    FormFavoritesComponent,
    FormPatientComponent,
    FormPrescriptionComponent,
    FormPrescriptionGroupComponent,
    ImportCustomerComponent,
    ImportCustomerImagesComponent,
    MenuComponent,
    SaveComponent,
    LogoutComponent,
    DeleteComponent,
    OkCancelComponent,
    PatientsComponent,
    VendorsComponent,
    UsersComponent,
    ThumbnailDirective,
    NewlinePipe,
    HighlightDirective,
    PhonePipe,
    InventoryComponent,
    UnsavedComponent,
    ManagedBoardComponent,
    ManagedBoardInventoryComponent,
    ManagedBoardAwaitingFramesComponent,
    ManagedBoardStackComponent,
    FormOrderImageComponent,
    FormOrderImageGroupComponent,
    AutocompleteVendorProductsComponent,
    AutocompleteVendorsComponent,
    StackOrdersComponent,
    StackInquiriesComponent,
    StackVendorsComponent,
    PatientInquiriesComponent,
    PatientOrdersComponent,
    OrdersBaseComponent,
    VendorsBaseComponent,
    AwaitingFramesOrdersComponent,
    AwaitingFramesSubmitComponent,
    VendorBaseComponent,
    InventoryBaseComponent,
    PayableComponent,
    PayableProductsComponent,
    PayableOrdersComponent,
    InventoryHistoryComponent,
    LogMenuComponent,
    ReturnsComponent,
    ReturnsOrdersComponent,
    ReturnsSubmitComponent,
    ReturnsSentComponent,
    ReturnOrderDialogComponent,
    FixOrdersComponent,
    GalleryComponent,
    FixDuplicateProductsComponent,
    DuplicateProductsComponent,
    DuplicateProductsHistoryComponent,
    BorrowingComponent,
    BorrowingProductsComponent,
    BorrowingVendorsComponent,
    ReceivableComponent,
    PriceCheckComponent,
    InventoryMenuComponent,
    PatientComponent,
    ReportsComponent,
    TaxReportComponent,
    TaxReportOrdersComponent,
    StoreNamePipe,
    BorrowingOrdersComponent,
    OrderComponent,
    FrameDetailsComponent,
    PrescriptionDetailsComponent,
    LensDetailsComponent,
    PaymentDetailsComponent,
    RepairDetailsComponent,
    NavbarComponent,
    CustomersReportComponent,
    ExamsComponent,
    PrescriptionsComponent,
    PrescriptionComponent,
    PrescriptionSideComponent,
    ClickFieldDirective,
    VendorInvoicesComponent,
    ChangeLogComponent,
    ManagedBoardInvoiceComponent,
    PayableOrderComponent,
    CustomersEmailComponent,
    HomeComponent,
    ManagedBoardCreditComponent,
    CustomersExpiredRxComponent,
  ],
  imports: [
    BrowserModule,
    HammerModule,
    HttpClientModule,
    DateModule,
    MaterialModule,
    RoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxMaskModule.forRoot(),
    CKEditorModule,
    Ng2FittextModule,
    MarkdownModule.forRoot({ sanitize: SecurityContext.NONE }),
    V2Module,
    UiCommonModule,
    ImageModule,
    PrintModule,
    Ng2FittextModule,
  ],
  entryComponents: [
    SaveComponent,
    DeleteComponent,
    OkCancelComponent,
    LogoutComponent,
    UnsavedComponent,
    ReturnOrderDialogComponent,
    GalleryComponent,
    PriceCheckComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    PatientService,
    DateModule,
    MaterialModule,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS,
    },
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
