<div class="export"></div>

<mat-card class="mb-25 ao-card">
  <mat-card-header class="d-block pb-2">
    <div class="d-flex align-items-center justify-between w-100">
      <div>
        <button
          #download
          mat-raised-button
          [disabled]="!dataSource?.data?.length"
        >
          Export Shopify Data
        </button>

        <button #generate mat-raised-button  style="margin-left: 20px;">
          Generate Products Data
        </button>
      </div>
      <form class="search-box relative d-flex">
        <input type="text" [formControl]="discountControl" class="input-search d-block dark" placeholder="Add Discount" style="width:auto" />
        <button type="submit" class="icon">
          <mat-icon>percent</mat-icon>
        </button>
      </form>
      <div>
        <form class="search-box relative d-flex">
          <input
            type="text"
            [formControl]="searchControl"
            class="input-search d-block dark"
            placeholder="Search Products"
          />
          <button type="submit" class="icon">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="mat-mdc-card-content">
    <div
      class="mat-elevation-z0 mat-table mt-18"
      [ngClass]="
        dataSource?.data?.length ? 'overflow-x-auto' : 'overflow-x-hidden'
      "
    >
      <table
        mat-table
        matSort
        class="ao-table"
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
      >
        <ng-container *ngFor="let column of displayedColumns">
          <ng-container [matColumnDef]="column">
            <ng-container *ngIf="column !== 'selected'; else selectAllCol">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center"
                [mat-sort-header]="column"
              >{{ tableKeys[column] }}</th>

            </ng-container>
            <ng-template #selectAllCol>
              <th
                mat-header-cell
                *matHeaderCellDef
                class="ao-header-cell center"
              >
                <span class="ao-checkbox">
                  <input
                    type="checkbox"
                    [checked]="allSelected"
                    (click)="onAllSelection()"
                    class="check centered"
                    [disabled]="!dataSource?.data?.length"
                  />
                </span>
              </th>
            </ng-template>
            <td
              mat-cell
              *matCellDef="let element"
              class="ao-table-cell"
              [ngClass]="
                column === 'productStatus' ? element.productStatus : ''
              "
            >
              <div class="d-flex align-items-center">
                <ng-container *ngIf="column !== 'selected'; else selectCol">
                  {{ element[column] }}
                </ng-container>
                <ng-template #selectCol>
                  <span class="ao-checkbox">
                    <input
                      type="checkbox"
                      [checked]="element[column]"
                      (click)="selectProduct(element)"
                      class="check"
                    />
                  </span>
                </ng-template>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="ao-header-row th-light"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="ao-table-row"
        ></tr>
      </table>
    </div>
    <div *ngIf="!dataSource?.data?.length" class="no-data">
        Generate products data to view shopify data
    </div>
    <mat-paginator
      #paginator
      [length]="dataSource?.data?.length ?? 0"
      [pageSize]="defaultPageSizes[0]"
      [pageSizeOptions]="defaultPageSizes"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
