import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageEditComponent } from './image-edit/image-edit.component';
import { ImagesComponent } from './images/images.component';
import { ImageComponent } from './image.component';
import { MaterialModule } from '@modules/material/material.module';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [
    ImageDialogComponent,
    ImageEditComponent,
    ImagesComponent,
    ImageComponent,
  ],
  imports: [CommonModule, ImageCropperModule, MaterialModule, FileUploadModule],
  exports: [
    ImageDialogComponent,
    ImageEditComponent,
    ImagesComponent,
    ImageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageModule {}
