import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Order } from '@models/order';
import { OrderForm } from '@classes/order-form';
import { StoreService } from '@services/store.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-log-menu',
  templateUrl: './log-menu.component.html',
  styleUrls: ['./log-menu.component.css'],
})
export class LogMenuComponent implements OnInit {
  @Input() showLog: boolean;
  @Input() order: any;
  @Input() small: boolean = false;
  @Output() clickField = new EventEmitter<boolean>();
  _order: FormGroup;

  constructor(public auth: AuthService, public storeService: StoreService) {}

  ngOnInit() {
    if (!this.order.value && this.order['_id']) {
      this._order = OrderForm.initOrderFormGroup(
        this.order as Order,
        this.auth,
        this.storeService.stores
      );
    } else {
      this._order = this.order as FormGroup;
    }
  }

  ngAfterViewInit() {}

  _clickField() {
    this.clickField.emit(true);
  }
}
