import { Component, OnInit } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { Prescription } from '@models/prescription';
import { PrescriptionForm } from '@classes/prescription-form';

@Component({
  selector: 'app-prescription-details',
  templateUrl: './prescription-details.component.html',
  styleUrls: ['./prescription-details.component.css']
})
export class PrescriptionDetailsComponent implements OnInit {
  formGroup: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.formGroup = PrescriptionForm.initPrescriptionFormGroup(new Prescription());
    console.log(this.formGroup)
  }

}
