import {
  Inject,
  LOCALE_ID,
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Order, LogElement } from '@models/order';
import { Patient } from '@models/patient';
import { OrderService } from '@services/order.service';
import { ProductService } from '@services/product.service';
import { StoreService } from '@services/store.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { VendorOrderService } from '@services/vendor-order.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { OrdersBaseComponent } from '@components/admin/managed-board/base/orders-base/orders-base.component';
import { ImageDialogComponent } from '@components/admin/image/image-dialog/image-dialog.component';
import { ImageService } from '@services/image.service';
import {
  MatDialog,
} from '@angular/material/dialog';
import { ReturnOrderDialogComponent } from '@components/admin/patient/return-order-dialog/return-order-dialog.component';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { SaveComponent } from '@components/admin/dialog/save/save.component';
import { FormatData } from '@classes/format-data';
import { map, takeWhile } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-patient-orders',
  templateUrl: './patient-orders.component.html',
  styleUrls: ['./patient-orders.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PatientOrdersComponent
  extends OrdersBaseComponent
  implements OnInit
{
  @Input() public patient: Patient;
  @Input() public orderIndex: number;
  @Input() public storeCreditControl: FormControl;
  @Output() clickDelete = new EventEmitter<any>();
  @Output() clickReturn = new EventEmitter<any>();
  @Output() clickExchange = new EventEmitter<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  orders: Order[];
  imageUrl: BehaviorSubject<any> = new BehaviorSubject('');

  displayedColumns = [
    'status',
    'vendor',
    'model',
    'color',
    'size',
    'type',
    'invoiceNumber',
    'view',
    'action',
    'log',
  ];
  displayedColumns2 = [
    'notes',
    'part',
    'specialOrder',
    'warranty',
    'cost',
    'priceLink',
    'retail',
    'duty',
    'action',
  ];
  displayedColumns3 = [
    'notes',
    'part',
    'specialOrder',
    'warranty',
    'cost',
    'priceLink',
    'retail',
    'duty',
    'retailPlusDuty',
    'action',
  ];
  orderStatus = ['ORDER FRAME', 'ORDER ACCESSORY', 'ORDER PART'];
  awaitingStatus = ['AWAITING FRAME', 'AWAITING ACCESSORY', 'AWAITING PART'];

  orderForm: FormGroup;

  fieldOpen: boolean = false;
  orderId: string;
  index: number = null;
  loaded: boolean = false;
  imageURL = environment.base_url + '/image/';

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public auth: AuthService,
    public orderService: OrderService,
    public vendorOrderService: VendorOrderService,
    public productService: ProductService,
    public storeService: StoreService,
    private imageService: ImageService,
    public cdRef: ChangeDetectorRef,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public notificationService: NotificationService
  ) {
    super(
      locale,
      auth,
      orderService,
      vendorOrderService,
      productService,
      storeService,
      cdRef,
      fb,
      route,
      notificationService
    );
  }

  ngAfterViewInit() {
    this.getNewOrders();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getNewOrders() {
    const patientId = this.route.snapshot.params.id;
    if (!patientId) {
      this.loaded = true;
      return;
    }
    this.orderService
      .getPatientOrders(this.patient._id, 'orders')
      .pipe(
        map((orders) =>
          orders.sort(
            (a, b) =>
              new Date(b.dateUpdated).getTime() -
              new Date(a.dateUpdated).getTime()
          )
        )
      )
      .subscribe((res: Order[]) => {
        this.orders = res;
        this.setOrderForm(this.orders);
        for (let x = 0; x < this.orders.length; x++) {
          const orderFormGroup = this.orderForm.get('orders.' + x) as FormGroup;
          this.dataSource2.push(
            new MatTableDataSource<FormGroup>([orderFormGroup])
          );
        }
        this.loaded = true;
        this.cdRef.detectChanges();
      });
  }

  toggleDuty($event, order: FormGroup, index) {
    this.clickField2($event, order, index);
  }

  clickPart($event, order: FormGroup, index) {
    this.fieldOpen = true;
    this.orderId = order.value._id;
    this.index = index;
    if ($event.checked) {
      order.get('products.0.cost').setValue(0);
      order.get('products.0.retail').setValue(0);
      order.get('type').setValue('F');
    } else {
      order.get('type').setValue('O');
    }
  }

  clickField2($event, order: FormGroup, index) {
    this.fieldOpen = true;
    this.orderId = order.value._id;
    this.index = index;
  }

  clickSaveOrder(order: FormGroup, destination, index) {
    order
      .get('todo')
      .setValue(destination == 'SEND TO STACK' ? 'ORDER' : destination);
    order
      .get('products.0.frame.frameSource')
      .setValue(
        destination == 'ORDERED ON APPROVAL' ? 'PATIENT APPROVAL' : 'PATIENT'
      );

    const newOrder = order.get('products.0._id').value == '' ? true : false;
    let status = this.getOrderStatusString(
      order.get('type').value,
      destination
    );

    if (destination == 'ADDED TO INVENTORY') {
      status = 'CANCELED';
      const invoiceNumber = order.get('invoiceNumber').value;
      order.get('invoiceNumber').setValue(invoiceNumber + '-C');

      this.productService
        .incrementProductInventoryQuantity(
          order.get('products.0.product').value,
          1
        )
        .subscribe((incrementProduct) => {
          if (!order.get('products.0.product._id').value) {
            order
              .get('products.0.product._id')
              .setValue(incrementProduct.product._id);

            this.vendorOrderService
              .addProductIdToVendorOrder(order.value)
              .subscribe((res) => {});
          }
          if (destination != 'SAVE') {
            order.get('status').setValue(status);
          }
          this.saveFrame(order, newOrder).subscribe((res) => {});
        });
    } else {
      if (destination != 'SAVE') {
        order.get('status').setValue(status);
      }
      this.saveFrame(order, newOrder).subscribe((res) => {});
    }
  }

  getOrderStatusString(type, destination): string {
    const status = /^ORDERED/.test(destination) ? 'AWAITING ' : 'ORDER ';
    switch (type) {
      case 'O':
      case 'N':
        return status + 'FRAME';
      case 'A':
        return status + 'ACCESSORY';
      case 'F':
        return status + 'PART';
    }
  }

  openImage(imageId) {
    this.imageService.getImageUrl(imageId).subscribe((res) => {
      this.imageUrl.next(res);
      this.openImageDialog(imageId, res);
    });
  }

  openImageDialog(imageId, imageUrl) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '',
      height: '',
      data: { image: imageUrl, imageId: imageId, message: '' },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  printImage(images) {
    //let html = '<script type="text/javascript">setTimeout(function () { window.print(); }, 500);window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script><html><body style="width:100%;"><div style="width:100%;"><img src="'+image.image+'" style="width:100%;"></div></body></html>';
    let html =
      '<html><style type="text/css" media="print">@media screen { @page {size: A4 portrait; } html { width:8.5in; height:11in; margin: 0 0 0 0;} body{margin: 0 0 0 0;} img{display: block; max-height:9in;page-break-inside: avoid;}</style><script type="text/javascript">setTimeout(function () { window.print(); }, 500);window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }</script><body style="width:100%;"><div style="width:100%;text-align:center;">';
    for (let x = 0; x < images.length; x++) {
      const url =
        this.imageURL +
        images[x] +
        '/thumbnail/0?bearer=' +
        this.auth.getToken();
      html = html + '<img src="' + url + '" style="max-height:9in">';
    }
    html = html + '</div></body></html>';
    const WindowPrt = window.open(
      '',
      '',
      'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0'
    );
    WindowPrt.document.write(html);
    WindowPrt.document.close();
    //WindowPrt.focus();
    //WindowPrt.print();
    //WindowPrt.close();
  }

  deleteOrder(orderFormGroup: FormGroup, index): void {
    const order: Order = orderFormGroup.value;
    const orderId = order._id;
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE ORDER?' },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        this.orderService.deleteOrder(orderId).subscribe((order) => {
          let orderFormArray: FormArray;
          orderFormArray = this.orderForm.controls.orders as FormArray;
          orderFormArray.removeAt(index);
          this.setDataSource(this.orderForm.controls.orders['controls']);
          // this.openDialog('ORDER DELETED');
          this.notificationService.show('success', 'ORDER DELETED', 'OK');
        });
      });
  }

  returnOrder(orderFormGroup: FormGroup): void {
    const order: Order = orderFormGroup.value;
    const orderId = order._id;
    const dialogRef = this.dialog.open(ReturnOrderDialogComponent, {
      width: '250px',
      data: { message: 'RETURN ORDER?', order: order },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        let credit = dialogRef.componentInstance.credit;
        if (typeof credit === 'string') {
          credit = parseFloat(credit.replace(/,/g, ''));
        }

        const logItem = new LogElement();
        logItem.initials = order['initials'];
        logItem.user = this.auth.currentUser._id;
        logItem.item = 'ORDER RETURNED';
        logItem.notes = '';
        logItem.date = Date.now().toString();
        order.log.unshift(logItem);
        order.invoiceNumber = order.invoiceNumber + '-R';
        order.patient = this.patient._id;

        this.orderService
          .returnOrder(order, credit)
          .subscribe((order: Order) => {
            const orderStatusControl = orderFormGroup.get('status');
            const storeCreditValue =
              FormatData.formatFloat(this.storeCreditControl.value) +
              FormatData.formatFloat(credit);
            this.storeCreditControl.setValue(storeCreditValue);
            orderStatusControl.setValue('RETURNED');
            this.notificationService.show('success', 'ORDER RETURNED', 'OK');
          });
      });
  }

  exchangeOrder(orderFormGroup: FormGroup): void {
    const order: Order = orderFormGroup.value;
    const orderId = order._id;
    const dialogRef = this.dialog.open(ReturnOrderDialogComponent, {
      width: '250px',
      data: { message: 'EXCHANGE ORDER?', order: order },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        let credit = dialogRef.componentInstance.credit;
        if (typeof credit === 'string') {
          credit = parseFloat(credit.replace(/,/g, ''));
        }

        const logItem = new LogElement();
        logItem.initials = order['initials'];
        logItem.user = this.auth.currentUser._id;
        logItem.item = 'ORDER EXCHANGED';
        logItem.notes = '';
        logItem.date = Date.now().toString();
        order.log.unshift(logItem);
        order.invoiceNumber = order.invoiceNumber + '-R';
        order.patient = this.patient._id;

        this.orderService
          .returnOrder(order, credit)
          .subscribe((order: Order) => {
            const orderStatusControl = orderFormGroup.get('status');
            const storeCreditValue =
              FormatData.formatFloat(this.storeCreditControl.value) +
              FormatData.formatFloat(credit);
            this.storeCreditControl.setValue(storeCreditValue);
            orderStatusControl.setValue('RETURNED');
            this.notificationService.show('success', 'ORDER EXCHANGED', 'OK');
          });
      });
  }

  openDialog(message): void {
    const dialogRef = this.dialog.open(SaveComponent, {
      width: '250px',
      data: { message: message },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
