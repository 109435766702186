export class Prescription {
  _id: string;
  patientId: string;
  notes: string;
  dateAdded: string;
  dateUpdated: string;
  type: string;
  typeOther: string;
  images: Object[];
  expirationDate: string;
  examDate?: string;
  archived: boolean;
  oneYearExpiration: boolean;
  from2020Now?: boolean;
  eyeExam?: Object;
  left: PrescriptionAttributes;
  right: PrescriptionAttributes;
  hide?: boolean;

  constructor() {
    this._id = '';
    this.patientId = null;
    this.notes = '';
    this.dateAdded = '';
    this.dateUpdated = '';
    this.type = '';
    this.typeOther = '';
    this.images = [];
    this.expirationDate = '';
    this.examDate = '';
    this.archived = false;
    this.oneYearExpiration = false;
    this.from2020Now = false;
    this.eyeExam = null;
    this.left = new PrescriptionAttributes(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
    this.right = new PrescriptionAttributes(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
    this.hide = false;
  }
}

export class PrescriptionAttributes {
	sph: string;
	cyl: string;
	axis: string;
	add: string;
	segHgt: string;
	prism1: string;
	b1: string;
	prism2: string;
	b2: string;
	pdd: string;
	pdn: string;
	oc: string;

	constructor(sph?, cyl?, axis?, add?, segHgt?, prism1?, b1?, prism2?, b2?, pdd?, pdn?, oc?) {
		this.sph = (sph) ? sph : '';
		this.cyl = (cyl) ? cyl : '';
		this.axis = (axis) ? axis : '';
		this.add = (add) ? add : '';
		this.segHgt = (segHgt) ? segHgt : '';
		this.prism1 = (prism1) ? prism1 : '';
		this.b1 = (b1) ? b1 : '';
		this.prism2 = (prism2) ? prism2 : '';
		this.b2 = (b2) ? b2 : '';
		this.pdd = (pdd) ? pdd : '';
		this.pdn = (pdn) ? pdn : '';
		this.oc = (oc) ? oc : '';
	}
}
