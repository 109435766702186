
<mat-form-field style="padding-right:20px;">
  <mat-label>DATE</mat-label>
  <mat-select (selectionChange)="selectDateOption($event)">
    <mat-option *ngFor="let dateOption of dateOptions" [value]="dateOption.value">
      {{ dateOption.viewValue }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field *ngIf="selectedDateOption=='customDateRange'" style="padding-right:20px;">
  <mat-label>DATE RANGE</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date" (dateChange)="selectCustomDateRangeStart($event)">
    <input matEndDate placeholder="End date" (dateChange)="selectCustomDateRangeEnd($event)">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<button mat-icon-button (click)="getTaxReport()"><mat-icon>refresh</mat-icon></button>

<div *ngIf="loading">
	<h3 style="text-align:center;">LOADING REPORT</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!loading && startDate && endDate" style="width:100%">
  <mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z2">

    <ng-container matColumnDef="vendor">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> VENDOR </mat-header-cell>
      <mat-cell *matCellDef="let reportData; let orderIndex=dataIndex;" fxFlex="1 1 200px">
        <span style="font-weight:bold;"> {{ reportData.name }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="count">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 200px"> QUANTITY </mat-header-cell>
      <mat-cell *matCellDef="let reportData; let orderIndex=dataIndex;" fxFlex="1 1 200px">
	        <span *ngIf="reportData.vendorOrders.length == 1"> {{ reportData.vendorOrders.length }} ORDER</span>
	        <span *ngIf="reportData.vendorOrders.length != 1"> {{ reportData.vendorOrders.length }} ORDERS</span>
    	<!-- 	<ng-container *ngIf="invoiceStatus[orderIndex]=='all'">
	        <span *ngIf="unpaidCount[orderIndex] != 1"> {{ unpaidCount[orderIndex] }} ORDERS</span>
	        <span *ngIf="unpaidCount[orderIndex] == 1"> {{ unpaidCount[orderIndex] }} ORDER</span>
	      </ng-container>
	      <ng-container *ngIf="invoiceStatus[orderIndex]=='paid'">
	        <span *ngIf="paidCount[orderIndex] != 1"> {{ paidCount[orderIndex] }} ORDERS</span>
	        <span *ngIf="paidCount[orderIndex] == 1"> {{ paidCount[orderIndex] }} ORDER</span>
	      </ng-container> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 100%">  </mat-header-cell>
      <mat-cell *matCellDef="let reportData; let orderIndex=dataIndex;" fxFlex="1 1 100%" style="padding-left:16px;justify-content: flex-start !important;">
 <!--      	<mat-radio-group *ngIf="expandedElement === vendor" matInput>
					<mat-radio-button value="all" [checked]="invoiceStatus[orderIndex]=='all'" (click)="setInvoiceStatus('all',orderIndex)">UNPAID</mat-radio-button>&nbsp;&nbsp;
					<mat-radio-button value="paid" [checked]="invoiceStatus[orderIndex]=='paid'" (click)="setInvoiceStatus('paid',orderIndex)">PAID</mat-radio-button>
				</mat-radio-group> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let reportData; let orderIndex=dataIndex;">
        <div class="example-element-detail" style="margin-left:20px;width:100%;" [class.mat-elevation-z0]="reportData!=expandedElement" [class.mat-elevation-z8]="reportData==expandedElement" [@detailExpand]="reportData == expandedElement ? 'expanded' : 'collapsed'">

        <app-tax-report-orders *ngIf="reportData==expandedElement" [vendorOrders]="reportData.vendorOrders" [vendor]="reportData" [startDate]="startDate" [endDate]="endDate" style="width:100%;"></app-tax-report-orders>

        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row 
      *matHeaderRowDef="displayedColumns" 
      fxLayout="row" 
      fxLayoutAlign="start center">
    </mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns; let i=dataIndex; let even=even; let odd=odd;" 
      fxLayout="row" 
      fxLayoutAlign="start center" 
      [ngClass]="{rowEven:i%2, rowOdd:(i+1)%2}" 
      style="border-left:1px solid #dfdfdf !important;" 
      (click)="toggleExpanded($event, row, i)">
    </mat-row>
    <mat-row *matRowDef="let element; columns: ['expandedDetail']" 
      [class.example-detail-row-expanded]="expandedElement === element"
      [class.example-detail-row-collapsed]="expandedElement !== element"
      style="overflow: hidden"
      fxLayoutAlign="start center">
    </mat-row>
  </mat-table>
</div>
