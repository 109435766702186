import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { User } from '@models/user';
// import { Observable } from 'rxjs';
import { Store } from '@models/store';
import { AccountService } from '@services/account.service';
import { AuthService } from '@services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { StoreService } from '@services/store.service';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { removeStoreClasses } from 'src/app/v2/shared/utils/store';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() isPrinting: boolean;
  user: User;
  message: any;
  objDate = Date.now();
  stores: Store[];
  allStores: Store[];
  currentStore: Store;
  userSubscription: Subscription;
  storeSubscription: Subscription;
  fullHeader: boolean = false;
  currentRoute: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    public accountService: AccountService,
    public authService: AuthService,
    public storeService: StoreService,
    private router: Router,
    private ren: Renderer2
  ) {
    this.userSubscription = this.authService
      .getMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.user = user;
        this.stores = user?.stores.map((storeId) =>
          this.allStores.find(({ _id }) => storeId === parseInt(_id, 0))
        );
      });
    this.storeSubscription = this.authService
      .getStoreObjectMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((store: Store) => {
        this.currentStore = store;
      });

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event: any) => event instanceof NavigationEnd)
      )
      .subscribe((navEnd) => {
        this.currentRoute = navEnd.url;
        this.fullHeader =
          this.currentRoute === '/admin' || this.currentRoute === '/login';
      });
  }

  goHome() {
    this.router.navigate(['/admin/']);
  }

  get store(): string {
    return this.currentStore?._id;
  }

  set store(val: string) {
    const store = this.allStores.find((s) => s._id === val);
    this.authService.setStore(store);
    localStorage.setItem('store', store.storeName);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    removeStoreClasses(this.allStores, this.ren);
    this.ren.addClass(
      document.body,
      store.storeName.split(' ').join('').toLowerCase()
    );
    if (this.router.url.startsWith('/admin/patient')) {
      this.router.navigate(['']);
    } else if (this.router.url.startsWith('/v2')) {
      this.router.navigate(['v2/payables']);
    } else {
      this.router.navigateByUrl(this.router.url);
    }
  }

  setStore(val: string) {
    this.store = val;
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   let vendorId = params.get('vendorId');
    //   if (vendorId) {
    //     this.vendorService.getVendor(vendorId).subscribe((res) => {
    //       let vendor:Vendor = res as Vendor;
    //       if (vendor) {
    //         this.vendorCtrl.setValue(vendor.name);
    //         this.selectVendor({source:{selected:true}}, vendor);
    //       }
    //     });
    //   }
    // });

    this.allStores = this.storeService.stores;
    if (this.authService.isLoggedIn()) {
      this.authService.syncLocalFromStorage();
      this.accountService.getAccount().subscribe(
        (currentUser: User) => {
          this.authService.setUser(currentUser);
        },
        () => {
          this.router.navigate(['/login']);
        }
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.authService.logout();
    this.user = null;
    this.message = 'Logged out';
  }
}
