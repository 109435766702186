<div *ngIf="order" class="d-flex">
  <button
    [disabled]="!order?.log?.length"
    class="btn icon dark"
    (click)="vieOrderLog()"
  >
    <mat-icon matTooltip="View Logs">event_note</mat-icon>
  </button>
</div>
<ng-template #logDetail>
  <ul *ngIf="order?.log?.length" class="ao-log open-sans">
    <ng-container *ngFor="let log of order.log">
      <li *ngIf="log.item !== 'NOTE'" class="d-flex flex-column">
        <p class="log-item flex-grow-1">
          {{ log.item }}
        </p>
        <div class="d-flex align-items-center justify-between flex-grow-1">
          <span class="log-date d-flex align-items-center"
            ><mat-icon class="icon">event</mat-icon>
            <span class="log-value">{{ log.date | date: 'short' }}</span></span
          >
          <span class="log-initials d-flex align-items-center"
            ><mat-icon class="icon">gesture</mat-icon>
            <span class="log-value">{{ log.initials }}</span></span
          >
        </div>
      </li>
    </ng-container>
  </ul>
</ng-template>
