import { Component, OnInit, ViewChild, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FavoriteLine } from '@models/favorite-line';
import { FavoriteLineService } from '@services/favorite-line.service';
import { StoreService } from '@services/store.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css'],
  providers: [FavoriteLineService]
})
export class FavoritesComponent implements OnInit {
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChildren('formRow') rows: QueryList<ElementRef>;
	@ViewChild(MatSort, {static: false}) sort: MatSort;

  favoritesForm: FormGroup;
  favorites: FavoriteLine[];
  displayedColumns = ['name', 'save', 'delete'];
  dataSource = new MatTableDataSource<FavoriteLine>();
  favoriteId: String;
  favoriteIndex: Number;
  editFavorite: FavoriteLine;
  favoritesFormArray: FormArray[] = [];
  editFormArray: FormArray;
  roleOpen: Boolean = false;
  storesOpen: Boolean = false;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.rows.changes.subscribe(list => {
    	if (list.last) {
    		list.last.nativeElement.focus();
    		this.cdRef.detectChanges();
    	}
		});
  }

  constructor(private favoriteLineService: FavoriteLineService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private cdRef:ChangeDetectorRef, public dialog: MatDialog) {
    this.favoritesForm = fb.group({
      favoritesFormArray: this.fb.array([])
    })
  }

  ngOnInit() {
  	this.route.paramMap
	    .pipe(switchMap((params: ParamMap) => this.favoriteLineService.getAllFavoriteLines()))
	    .subscribe(favorites => {
        this.dataSource.data = favorites;
        this.setFavoriteForms(favorites);
        this.favorites = favorites;
        this.dataSource.paginator = this.paginator;
      	this.dataSource.sort = this.sort;
      });
  }

  clickRow($event: Event, favorite, index) {
    console.log("click row")
    console.log(this.favoriteId)
    console.log(favorite)
    console.log(index)
    this.favoriteId = favorite._id;
    this.favoriteIndex = index;
    this.editFavorite = favorite;
    this.editFormArray = this.favoritesForm.value.favoritesFormArray[index];
    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('document:click', ['$event']) clickOutside ($event: Event) {
    if (this.favoriteId == "NEW" && this.editFavorite.name == "") {
    	this.removeFavoriteFormItem(this.favoriteIndex);
      this.favoriteId = "";
      this.editFavorite = null;
    } else {
			if (this.favoriteId != "" && this.favoriteId != undefined) {
        this.saveEditedFavorite(this.editFavorite, this.favoriteIndex);
      }
      this.favoriteId = "";
      this.editFavorite = null;
    }
  }

  saveEditedFavorite(editFavorite, index) {
    if (this.favoriteId == "NEW") {
    	if (this.editFavorite.name != '') {
        editFavorite.name = editFavorite.name.toUpperCase();
	      this.favoriteLineService.addFavoriteLine(editFavorite).subscribe((res) => {
	          if (res["success"]) {
	          	const formArray = <FormArray>this.favoritesForm.controls['favoritesFormArray'];//.value[index];
          		formArray.at(index).patchValue(res["favoriteLine"])
          		this.dataSource.data[index] = res["favoriteLine"];
	            this.favoriteId = "";
	            this.editFavorite = null;
    					this.favoriteIndex = null;
	          }
	        });
      }
    } else {
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => this.favoriteLineService.updateFavoriteLine(editFavorite._id, editFavorite)))
        .subscribe(favorite => {
          console.log("favorite updated " + favorite._id);
          this.favoriteId = "";
          this.editFavorite = null;
					this.favoriteIndex = null;
        });
    }
  }


  deleteFavorite(editFavorite, index) {
    let dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE FAVORITE?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        if (this.favoriteId == "NEW") {
          this.removeFavoriteFormItem(index);
          this.favoriteId = "";
          this.editFavorite = null;
          this.favoriteIndex = null;
        } else {
          console.log(editFavorite);
          this.route.paramMap
            .pipe(switchMap((params: ParamMap) => this.favoriteLineService.deleteFavoriteLine(editFavorite._id)))
            .subscribe(favorite => {
              console.log("favorite deleted " + favorite._id);
              this.favoriteId = "";
              this.editFavorite = null;
              this.favoriteIndex = null;
              this.removeFavoriteFormItem(index);
            });
        }
      }
    });
  }

  prepareSaveFavorite(editFavorite) {
    var favorite = new FavoriteLine();
    favorite._id = editFavorite._id;
    favorite.name = editFavorite.name;
    return favorite;
  }

  setFavoriteForms(favorites) {
    for (var x = 0; x < this.dataSource.data.length; x++) {
      var favorite = this.dataSource.data[x] as FavoriteLine;
      var newFavorite = new FavoriteLine();
      newFavorite._id = favorite._id;
      newFavorite.name = favorite.name;
      this.addFavoriteFormItem(newFavorite);
    }
  }

  addFavoriteFormItem(favorite) {
    const control = <FormArray>this.favoritesForm.controls['favoritesFormArray'];
    const itemsCtrl = this.fb.group({
      '_id': favorite._id,
      'name': favorite.name
    });
    control.push(itemsCtrl);
    //console.log(control.controls[control.controls.length-1]['controls']['name']);
    //control.controls[control.controls.length-1]['name'].focus();
  }

  removeFavoriteFormItem(index) {
    const control = <FormArray>this.favoritesForm.controls['favoritesFormArray'];
    console.log(index)
    console.log(control)
    console.log(this.dataSource.data)
    control.removeAt(index);
  	this.dataSource.data.splice(index, 1);
    console.log(this.dataSource.data)
    this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
    this.dataSource.paginator = this.paginator;

  }

  addFavorite($event) {
    var newFavorite = new FavoriteLine();
    newFavorite._id = "NEW";
    console.log(this.paginator)
    console.log(this.paginator.pageIndex)

    let newIndex = ((this.paginator.pageIndex + 1) * this.paginator.pageSize - 1);
    newIndex = (newIndex > this.paginator.length) ? this.paginator.length : newIndex;
    console.log(newIndex)
    this.dataSource.data.splice(newIndex, 0, newFavorite);
    this.dataSource.data = this.dataSource.data;
    this.addFavoriteFormItem(newFavorite);
    this.favoriteId = newFavorite._id;
    //this.favoriteIndex = this.dataSource.data.length-1;
    this.favoriteIndex = newIndex;
    this.editFavorite = newFavorite;
    this.editFormArray = this.favoritesForm.value.favoritesFormArray[this.dataSource.data.length-1];
    console.log(this.rows)
    $event.preventDefault();
    $event.stopPropagation();
  }

  submit() {
    console.log(this.favoritesForm)
    for (var x = 0; x < this.favoritesForm.controls["favoritesFormArray"]["length"]; x++) {
      var form = this.favoritesForm.controls["favoritesFormArray"]["controls"][x];
      console.log(form)
      if (form.dirty) {
        console.log(form.value.name + " is dirty")
      }
    }
  }

}
