<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
  <div fxFlex="calc(100%-160px)">

							<div>
  				<mat-checkbox>1/2</mat-checkbox>
  			</div>
  	<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
  			



			<div fxFlex="1 1 190px">



  			<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
					<div fxFlex="1 1 150px">
						<div style="width:100%">
					    <mat-form-field style="width:100%">
							  <mat-label>LAB</mat-label>
							  <mat-select>
							    <mat-option></mat-option>
							  </mat-select>
							</mat-form-field>
						</div>
						<div style="width:100%">
							<mat-form-field style="width:100%">
							  <mat-label>LENS TYPE</mat-label>
							  <mat-select>
							    <mat-option></mat-option>
							  </mat-select>
							</mat-form-field>
						</div>
					</div>
					<div fxFlex="0 0 40px" style="font-size:100px;margin-top:50px;padding-top:50px;">}</div>
				</div>
				<div style="margin-left:40px;">
    			<span matBadge="ET" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
    			<mat-form-field style="width:50%">
	          <input matInput placeholder="" type="" autocomplete="off">
	        </mat-form-field>
    		</div>
				<div style="margin-left:40px;">
    			<span matBadge="CT" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
    			<mat-form-field style="width:50%">
	          <input matInput placeholder="" type="" autocomplete="off">
	        </mat-form-field>
    		</div>
		  </div>


		  <div fxFlex="1 1 150px" style="padding-top:38px;">
		    


		    <mat-form-field style="width:100%">
				  <mat-label>MATERIAL</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>

				<mat-form-field style="width:100%">
				  <mat-label>DESIGN / STYLE</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>


		  </div>

				<mat-card fxFlex="1 1 calc(100%-370px)">
		  		<mat-card-subtitle>LENS OPTIONS</mat-card-subtitle>
		  		<mat-card-content style="width:100%;">
  					<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
  						<div fxFlex="1 1 33%">
				  			<mat-form-field style="width:100%">
								  <mat-label>AR</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
							<div fxFlex="1 1 33%">
				  			<mat-form-field style="width:100%">
								  <mat-label>TINT</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
							<div fxFlex="1 1 33%">
				  			<mat-form-field style="width:100%">
								  <mat-label>MIRROR</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
						</div>

						<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
			  			<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">BS/AR</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">SLAB OFF</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">LAB FEE</mat-checkbox>
      				</div>
						</div>

						<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
			  			<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">EDGE POLISH</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">OVER POWER</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">CLIP-ON</mat-checkbox>
      				</div>
						</div>

    				<div>
      				<mat-checkbox style="margin-top:15px;margin-right:10px"></mat-checkbox>
    					<mat-form-field style="width:300px">
			          <input matInput placeholder="OTHER" type="" autocomplete="off">
			        </mat-form-field>
			        <mat-form-field style="width:50px;margin-left:10px">
			          <input matInput placeholder="" type="" autocomplete="off">
			          <span matPrefix>$&nbsp;</span>
			        </mat-form-field>
    				</div>


		  		</mat-card-content>
		  	</mat-card>
		</div>










		<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%;">
			<div fxFlex="0 0 150px">
				<div style="height:100px;width:150px;">INVOICE IMAGE</div>

				<div>
	      	<mat-form-field style="width:80px;">
	          <input matInput placeholder="COST" type="" autocomplete="off">
	          <span matPrefix>$&nbsp;</span>
	        </mat-form-field>
	      </div>
			</div>
			<div fxFlex="0 0 310px">
				<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
					<div fxFlex>
						<mat-form-field style="width:150px;">
						  <mat-label>ORDER DATE</mat-label>
						  <input matInput [matDatepicker]="pickerOrderDate">
						  <mat-datepicker-toggle matSuffix [for]="pickerOrderDate"></mat-datepicker-toggle>
						  <mat-datepicker #pickerOrderDate></mat-datepicker>
						</mat-form-field>
					</div>
					<div fxFlex>
						<mat-form-field style="width:120px;">
		          <input matInput placeholder="ORDER #" type="" autocomplete="off">
		        </mat-form-field>
		      </div>
		    </div>
				<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
		      <div fxFlex>
						<mat-form-field style="width:150px;">
						  <mat-label>DATE RECEIVED</mat-label>
						  <input matInput [matDatepicker]="pickerDateReceived">
						  <mat-datepicker-toggle matSuffix [for]="pickerDateReceived"></mat-datepicker-toggle>
						  <mat-datepicker #pickerDateReceived></mat-datepicker>
						</mat-form-field>
					</div>
					<div fxFlex>
						<mat-form-field style="width:120px;">
		          <input matInput placeholder="INSPECTION #1" type="" autocomplete="off">
		        </mat-form-field>
		      </div>
		    </div>
			</div>
			<div fxFlex>
				<mat-form-field fxFlex="1 1 100%">
				  <mat-label>LENS SHAPE</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>
			</div>

		</div>

		<div style="width:100%">
			<mat-form-field style="width:100%">
			  <mat-label>LAB NOTES & SPECIAL INSTRUCTIONS</mat-label>
			  <textarea matInput rows="6"></textarea>
			</mat-form-field>
		</div>





<!-- 

		<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%;background-color: red;">
			<div fxFlex="0 0 150px" style="background-color:orange">
				INVOICE IMAGE
			</div>
			<div fxFlex="0 0 400px" style="background-color:brown">
				ORDER DATE
			</div>
			<div fxFlex style="background-color:black">
				LENS SHAPE
			</div>

		</div>

		<div style="width:100%" style="background-color:pink">
			LAB NOTES & SPECIAL INSTRUCTIONS
		</div>
 -->



	</div>

	<div fxFlex="0 0 150px">
		



		<mat-card>
			<mat-card-content>
  			<mat-form-field style="width:100%">
          <input matInput placeholder="LENSES" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
  			<mat-form-field style="width:100%">
          <input matInput placeholder="TINT" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
  			<mat-form-field style="width:100%">
          <input matInput placeholder="COATINGS" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="MISC" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="MISC" type="" autocomplete="off">
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="SUBTOTAL" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card>
			<mat-card-content>

				<mat-form-field style="width:100%">
          <input matInput placeholder="LAST EDITOR" type="" autocomplete="off" value="11/9/2021" disabled>
          <span matPrefix>KM&nbsp;</span>
        </mat-form-field>

  			<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>

		    <button mat-button [matMenuTriggerFor]="menu">
		    	<mat-icon>more_vert</mat-icon>
		    	<span>SAVE</span>
		    </button>
	      <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED FRAME</button>
          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED ON APPROVAL</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SEND TO STACK</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/HOLD</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/QUOTE</button>
          <button mat-menu-item type="button" style="margin-left:10px;">ADD TO CART</button>
          <button mat-menu-item type="button" style="margin-left:10px;">VOID</button>
        </mat-menu>

      </mat-card-content>
    </mat-card>


	</div>
</div>





























<!-- 



<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
  <div fxFlex="calc(100%-160px)" style="background-color:blue">

  	<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
		  <div fxFlex="1 1 190px">
  			<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
					<div fxFlex>
						<div style="width:100%">
					    <mat-form-field style="width:100%">
							  <mat-label>LAB</mat-label>
							  <mat-select>
							    <mat-option></mat-option>
							  </mat-select>
							</mat-form-field>
						</div>
						<div style="width:100%">
							<mat-form-field style="width:100%">
							  <mat-label>LENS TYPE</mat-label>
							  <mat-select>
							    <mat-option></mat-option>
							  </mat-select>
							</mat-form-field>
						</div>
					</div>
					<div fxFlex="0 0 40px" style="font-size:100px;margin-top:50px;padding-top:50px;">}</div>
				</div>
				<div style="margin-left:40px;">
    			<span matBadge="ET" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
    			<mat-form-field style="width:50%">
	          <input matInput placeholder="" type="" autocomplete="off">
	        </mat-form-field>
    		</div>
				<div style="margin-left:40px;">
    			<span matBadge="CT" matBadgeSize="medium" matBadgePosition="before" matBadgeOverlap="false"></span>
    			<mat-form-field style="width:50%">
	          <input matInput placeholder="" type="" autocomplete="off">
	        </mat-form-field>
    		</div>
		  </div>
		  <div fxFlex="1 1 150px">
		    <mat-form-field style="width:100%">
				  <mat-label>MATERIAL</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>

				<mat-form-field style="width:100%">
				  <mat-label>DESIGN / STYLE</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>
		  </div>

		  <div fxFlex="1 1 auto">
		  	<mat-card style="width:100%; background-color:red">
		  		<mat-card-title>LENS OPTIONS</mat-card-title>
		  		<mat-card-content style="width:100%">
  					<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
  						<div fxFlex="1 1 20%" style="background-color:green">
				  			<mat-form-field style="width:90%">
								  <mat-label>AR</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
							<div fxFlex="1 1 20%" style="background-color:pink">
				  			<mat-form-field style="width:90%">
								  <mat-label>TINT</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
							<div fxFlex="1 1 20%" style="background-color:pink">
				  			<mat-form-field style="width:90%">
								  <mat-label>MIRROR</mat-label>
								  <mat-select>
								    <mat-option></mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
						</div>

						<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
			  			<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">BS/AR</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">SLAB OFF</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">LAB FEE</mat-checkbox>
      				</div>
						</div>

						<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
			  			<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">EDGE POLISH</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">OVER POWER</mat-checkbox>
      				</div>
      				<div fxFlex="1 1 33%">
        				<mat-checkbox style="margin-top:15px;">CLIP-ON</mat-checkbox>
      				</div>
						</div>

    				<div>
      				<mat-checkbox style="margin-top:15px;margin-right:10px"></mat-checkbox>
    					<mat-form-field style="width:300px">
			          <input matInput placeholder="OTHER" type="" autocomplete="off">
			        </mat-form-field>
			        <mat-form-field style="width:50px;margin-left:10px">
			          <input matInput placeholder="" type="" autocomplete="off">
			          <span matPrefix>$&nbsp;</span>
			        </mat-form-field>
    				</div>


		  		</mat-card-content>
		  	</mat-card>

		  </div>
		</div>




		<div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%;background-color: red;">
			<div fxFlex="0 0 150px">
				INVOICE IMAGE
			</div>
			<div fxFlex="0 0 400px">
				<div fxLayout="row wrap" fxLayoutAlign="left" fxLayoutGap="10px" style="width:100%">
					<div fxFlex>
						<mat-form-field style="width:150px;">
						  <mat-label>ORDER DATE</mat-label>
						  <input matInput [matDatepicker]="pickerOrderDate">
						  <mat-datepicker-toggle matSuffix [for]="pickerOrderDate"></mat-datepicker-toggle>
						  <mat-datepicker #pickerOrderDate></mat-datepicker>
						</mat-form-field>
					</div>
					<div fxFlex>
						<mat-form-field style="width:120px;">
		          <input matInput placeholder="ORDER #" type="" autocomplete="off">
		        </mat-form-field>
		      </div>
		      <div fxFlex>
		      	<mat-form-field style="width:80px;">
		          <input matInput placeholder="COST" type="" autocomplete="off">
		          <span matPrefix>$&nbsp;</span>
		        </mat-form-field>
		      </div>
		      <div fxFlex>
						<mat-form-field style="width:150px;">
						  <mat-label>DATE RECEIVED</mat-label>
						  <input matInput [matDatepicker]="pickerDateReceived">
						  <mat-datepicker-toggle matSuffix [for]="pickerDateReceived"></mat-datepicker-toggle>
						  <mat-datepicker #pickerDateReceived></mat-datepicker>
						</mat-form-field>
					</div>
					<div fxFlex>
						<mat-form-field style="width:120px;">
		          <input matInput placeholder="INSPECTION #1" type="" autocomplete="off">
		        </mat-form-field>
		      </div>
		    </div>
			</div>
			<div fxFlex>
				<mat-form-field fxFlex="1 1 100%">
				  <mat-label>LENS SHAPE</mat-label>
				  <mat-select>
				    <mat-option></mat-option>
				  </mat-select>
				</mat-form-field>
			</div>

		</div>

		<div style="width:100%">
			<mat-form-field style="width:100%">
			  <mat-label>LAB NOTES & SPECIAL INSTRUCTIONS</mat-label>
			  <textarea matInput rows="6"></textarea>
			</mat-form-field>
		</div>




	</div>

	<div fxFlex="150px">
		<mat-card>
			<mat-card-content>
  			<mat-form-field style="width:100%">
          <input matInput placeholder="LENSES" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
  			<mat-form-field style="width:100%">
          <input matInput placeholder="TINT" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
  			<mat-form-field style="width:100%">
          <input matInput placeholder="COATINGS" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="MISC" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="MISC" type="" autocomplete="off">
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="SUBTOTAL" type="" autocomplete="off">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card>
			<mat-card-content>

				<mat-form-field style="width:100%">
          <input matInput placeholder="LAST EDITOR" type="" autocomplete="off" value="11/9/2021" disabled>
          <span matPrefix>KM&nbsp;</span>
        </mat-form-field>

  			<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>

		    <button mat-button [matMenuTriggerFor]="menu">
		    	<mat-icon>more_vert</mat-icon>
		    	<span>SAVE</span>
		    </button>
	      <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED FRAME</button>
          <button mat-menu-item type="button" style="margin-left:10px;">ORDERED ON APPROVAL</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SEND TO STACK</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/HOLD</button>
          <button mat-menu-item type="button" style="margin-left:10px;">SAVE/QUOTE</button>
          <button mat-menu-item type="button" style="margin-left:10px;">ADD TO CART</button>
          <button mat-menu-item type="button" style="margin-left:10px;">VOID</button>
        </mat-menu>

      </mat-card-content>
    </mat-card>
	</div>
</div> -->