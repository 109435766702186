<!-- <div (mouseenter)="imageMouseEnter();" (mouseleave)="imageMouseLeave();" style="position:relative;display:block;" *ngIf="img._id"> -->
<div (mouseenter)="imageMouseEnter();" (mouseleave)="imageMouseLeave();" style="position:relative;display:block;">
	<img
		#image
		[src]="thumbnailUrl | async"
		[id]="img._id"
		[style.max-width]="styleMaxWidth"
		(click)="openImage(img._id)"
		loading="lazy">
		<!-- [appThumbnail]="[width,thumbnailWidth,img.image,img.thumbnail,img._id]"> -->

<!-- 	<mat-icon style="position:absolute;top:-5px;right:5px;" *ngIf="delete && imageHover && active" (click)="clickDelete(img._id)">delete_outline</mat-icon>

	<mat-icon style="position:absolute;top:-5px;right:40px;" *ngIf="edit && imageHover && active" (click)="clickCrop(img._id)">crop</mat-icon> -->
</div>
<div *ngIf="upload && !uploaderProduct.isUploading && !(thumbnailUrl | async)" floatLabel="never">
  <input #fileInputProduct id="{{'fileInputProduct'+rnd}}" type="file" name="photoProduct" accept="image/*" ng2FileSelect [uploader]="uploaderProduct" multiple [hidden]="true" (click)="clickButton($event)"/>
	<label for="{{'fileInputProduct'+rnd}}" class="mat-icon-button">
		<mat-icon>add_photo_alternate_outline</mat-icon>
	</label>
</div>
<mat-spinner mode="determinate" [value]="uploaderProduct.progress" diameter="36" *ngIf="uploaderProduct.isUploading && active"></mat-spinner>
