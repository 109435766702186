<mat-form-field style="padding-right:20px;">
	<mat-label>REPORT</mat-label>
	<mat-select [(ngModel)]="selectedValue" name="report" (selectionChange)="selectReport($event)">
		<mat-option *ngFor="let report of reports" [value]="report.value">
			{{report.viewValue}}
		</mat-option>
	</mat-select>
</mat-form-field>


<app-tax-report *ngIf="report=='tax'"></app-tax-report>
<app-customers-report *ngIf="report=='customers'"></app-customers-report>
<app-customers-email *ngIf="report=='customers-email'"></app-customers-email>
<app-customers-expired-rx *ngIf="report=='customers-expired-rx'"></app-customers-expired-rx>

