import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Vendor } from '@models/vendor';
import { PrintService } from '@services/print.service';
import { VendorOrderService } from '@services/vendor-order.service';
import { VendorService } from '@services/vendor.service';
import {
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { SortDirection } from '@angular/material/sort';
import { AlertService } from '@services/alert.service';
import { Payable, PayableGroup } from '@models/api.model';
import { routeParams } from 'src/app/v2/shared/utils/route';
import { getDisplayedColumns, searchPayable } from '@v2/shared/utils';
import { OrderDetails } from '@v2/shared/models';
@Component({
  selector: 'app-paid-list',
  templateUrl: './paid-list.component.html',
  styleUrls: ['./paid-list.component.scss'],
})
export class PaidListComponent implements AfterViewInit, OnDestroy {
  vendor: Vendor;
  vendorId: string;
  distributorId: string;
  orders: Array<OrderDetails>;
  filteredOrders: Array<OrderDetails>;
  payables: Array<Payable>;
  payableGroups: Array<PayableGroup>;
  unsubscribe$ = new Subject<void>();
  sort: Sort = { active: 'date', direction: 'desc' };
  displayedColumns: string[] = [];
  dataSource = new Subject<MatTableDataSource<OrderDetails>>();
  @ViewChild('matCardHeader') header: ElementRef;
  @ViewChild(MatTable) table: MatTable<OrderDetails>;
  searchControl: FormControl;
  vendorNames: Record<string, string> = {};
  status = 'paid';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private als: AlertService,
    private readonly pS: PrintService,
    private readonly vS: VendorService,
    private readonly voS: VendorOrderService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.searchControl = new FormControl(
      this.route.snapshot.queryParams.search ?? null
    );
    this.searchControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((searchText: string) => this.searchOrders(searchText));
    this.getOrderData();
    this.onRouterChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  filterOrderGroup(payables: Array<Payable>) {
    const { search } = this.route.snapshot.queryParams;
    if (search?.length) {
      const [groupPayable, ...orderPayables] = payables;
      const matchedOrders = orderPayables?.reduce(
        (acc: Array<Payable>, order: Payable) => {
          const matched = searchPayable(order, search, [
            'invoiceNumber',
            'total',
          ]);
          if (matched) {
            return [...acc, order];
          }
          return acc;
        },
        [] as Array<Payable>
      );
      if (matchedOrders?.length) {
        console.log(groupPayable, matchedOrders);
        return [groupPayable, ...matchedOrders];
      } else {
        return [];
      }
    }
    return payables;
  }

  filterOrders(payables: Array<Payable>) {
    console.log(payables);
    const { search } = this.route.snapshot.queryParams;
    if (search?.length) {
      const matchedOrders = payables?.reduce(
        (acc: Array<Payable>, order: Payable) => {
          const matched = searchPayable(order, search, [
            'invoiceNumber',
            'total',
          ]);
          if (matched) {
            return [...acc, order];
          }
          return acc;
        },
        [] as Array<Payable>
      );
      console.log(matchedOrders);
      return matchedOrders;
    }
    return payables;
  }

  formatVendorData(res: Array<PayableGroup>, sort?: Sort) {
    this.payableGroups = res;
    this.payables = res.reduce((acc: Array<Payable>, group: PayableGroup) => {
      const { orders, datePaid, tax, shipping, total, groupId, _id, vendor } =
        group;
      const groupPayable = {
        _id,
        datePaid,
        tax,
        shipping,
        total,
        groupId,
        status: 'PAID',
        vendor,
        invoiceNumber: '',
        invoiceDate: datePaid,
        header: true,
        printable: true,
      };
      const orderPayables = orders.map((order: Payable) => {
        const { vendor } = order;
        return {
          ...order,
          vendorName: this.vendorNames[vendor],
        };
      });
      const filteredOrderGroup = this.filterOrderGroup([
        groupPayable,
        ...orderPayables,
      ]);
      return [...acc, ...filteredOrderGroup];
    }, [] as Array<Payable>);
    // this.payables = this.filterOrders(payables);
    this.cd.detectChanges();
    this.setDataSource();
  }

  getIdsFromRoute() {
    const { col, dir, vendorId, status, distributorId, search } = routeParams(
      this.route
    );
    this.setVendorId(vendorId);
    this.setDistributorId(distributorId);
    this.setDisplayedColumns(status);
    this.setSort(col, dir);
    this.cd.detectChanges();
    return distributorId ?? vendorId;
  }

  setDistributorId(id: string) {
    this.distributorId = id;
  }

  setVendorId(id: string) {
    this.vendorId = id;
  }

  setDisplayedColumns(status?: string) {
    this.displayedColumns = getDisplayedColumns('paid');
  }

  setSort(col?: string, dir?: SortDirection) {
    const { active, direction } = this.sort;
    this.sort = { active: col ?? active, direction: dir ?? direction };
  }

  getOrderData(vendorId?: string) {
    const id = vendorId ?? this.getIdsFromRoute();
    this.getVendor(id)
      .pipe(take(1))
      .subscribe((res) => {
        this.formatVendorData(res, this.sort);
      });
  }

  getVendor(vendorId: string, resetSelections: boolean = false) {
    if (!vendorId) {
      return EMPTY;
    }
    return this.vS.getVendor(this.vendorId ?? this.distributorId).pipe(
      take(1),
      tap((vendor: Vendor) => {
        const { _id } = vendor;
        this.vendorNames[_id] = vendor.name;
        this.vendor = vendor;
        this.cd.detectChanges();
        if (!this.orders?.length) {
          this.als.loading.next({
            isLoading: true,
            loadingMessage: `Loading ${vendor.name}`,
          });
        }
      }),
      concatMap((vendor: Vendor) => {
        const { distributorVendors, _id } = vendor;
        const ids = distributorVendors?.length ? distributorVendors : [_id];
        return this.getVendorOrders(ids);
      })
    );
  }

  getVendorOrders(vendorIds: Array<string>) {
    if (!vendorIds?.length) {
      return EMPTY;
    }
    return this.vS.getVendorNames(vendorIds).pipe(
      take(1),
      tap((res) => {
        this.vendorNames = Object.entries(res).reduce(
          (acc: any, [key, data]: [string, any]) => {
            const { name } = data;
            return { ...acc, [key]: name };
          },
          {}
        );
      }),
      concatMap(() =>
        this.voS.getVendorInvoices(vendorIds, this.route.snapshot.queryParams)
      )
    );
  }

  onRouterChange() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getIdsFromRoute()),
        tap((vendorId: string) => {
          this.getOrderData(vendorId);
        })
      )
      .subscribe((event) => {
        window.scrollTo(0, 0);
      });
  }

  onSortChange(sort: Sort) {
    const { active, direction } = sort;
    const {
      col: sortBy,
      dir: orderBy,
      ...rest
    } = this.route.snapshot.queryParams;

    let dir: SortDirection;
    if (orderBy) {
      dir = orderBy === 'desc' ? 'asc' : 'desc';
    } else if (!direction) {
      dir = 'desc';
    } else {
      dir = direction;
    }

    const queryParams = { ...rest, col: active, dir };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  printVendorOrders(groupId: string) {
    const orderIds = this.payableGroups
      .find((group) => group._id === groupId)
      ?.orders?.map((order: Payable) => order._id);
    this.pS.printVendorInvoices(orderIds ?? [], this.vendor, groupId);
  }

  searchOrders(search: string) {
    this.als.loading.next({
      isLoading: true,
      loadingMessage: `Searching ${search}...`,
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        search,
      },
    });
  }

  setDataSource() {
    if (this.table) {
      const dataSource = new MatTableDataSource(this.payables);
      this.dataSource.next(dataSource);
      this.table.renderRows();
      this.cd.detectChanges();
    }
    timer(1000).subscribe(() => this.als.loading.next({ isLoading: false }));
  }

  showUnPaid() {
    this.als.loading.next({
      isLoading: true,
      loadingMessage: `Loading unpaid`,
    });
    const subUrl = this.vendorId
      ? `vendor/${this.vendorId}`
      : `distributor/${this.distributorId}`;
    this.router.navigate([`/v2/payables/${subUrl}/${status}`], {
      queryParams: {
        ...this.route.snapshot.queryParams,
      },
    });
  }

  viewOrder(orderId: string) {
    this.router.navigate([`/v2/orders/${orderId}`], {
      queryParams: { distributorId: this.distributorId },
    });
  }
}
