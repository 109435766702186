import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { LogElement } from '@models/order';
import { VendorOrder, VendorOrders } from '@models/vendor-order';
import { Product, ProductElement } from '@models/product';
import { VendorOrderService } from '@services/vendor-order.service';
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { ProductForm } from '@classes/product-form';
import { VendorOrderForm } from '@classes/vendor-order-form';
import { FormatData } from '@classes/format-data';
import { Location, formatDate, formatNumber } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Observable,
  combineLatest,
  concatMap,
  forkJoin,
  map,
  of,
  tap,
} from 'rxjs';
import { ProductService } from '@services/product.service';
import ObjectID from 'bson-objectid';

@Component({
  selector: 'app-returns-sent',
  templateUrl: './returns-sent.component.html',
  styleUrls: [
    '../../managed-board.component.css',
    './returns-sent.component.css',
  ],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnsSentComponent implements OnInit {
  @Input() public vendorOrders: VendorOrders;
  @Input() set selected(isSelected: boolean) {}
  @Output() refreshVendorOrders = new EventEmitter<boolean>();

  vendorOrdersForm: FormGroup;
  defaultSortColumn: string = '';
  dataSource = new MatTableDataSource<FormControl>();
  dataSource2 = new MatTableDataSource<boolean>();
  dataSource3 = new MatTableDataSource<ProductElement>();
  displayedColumns: string[] = [
    'select',
    'count',
    'raNumber',
    'date',
    'shippingMethod',
    'trackingNumber',
    'proposedCredit',
    'action',
  ];
  displayedColumns2: string[] = [
    'invoiceNumber',
    'dateReceived',
    'tax',
    'total',
    'proposedCredit',
    'taxPaid',
    'difference',
    'action',
  ];
  displayedColumns3: string[] = [
    'frame',
    'image',
    'model',
    'color',
    'size',
    'type',
    'status',
    'cost',
    'retail',
    'store',
  ];
  fieldOpen: boolean = false;
  vendorOrderId: string = null;
  vendorOrder: FormGroup = null;
  selection: SelectionModel<FormControl> = new SelectionModel<FormControl>(
    true,
    []
  );
  expandedElement = null;
  selectedFrameIds: string[] = [];
  selectedFrames: Record<
    string,
    { products: Array<Product>; vendorOrder: FormGroup }
  > = {};

  get allSelectedIds() {
    return Object.values(this.selectedFrames).reduce(
      (acc, item) => [...acc, ...item.products.map((p) => p._id)],
      []
    );
  }

  get framesSelected(): number {
    return Object.values(this.selectedFrames).reduce(
      (acc, item) => acc + item.products.length,
      0
    );
  }

  constructor(
    private vendorOrderService: VendorOrderService,
    private auth: AuthService,
    private storeService: StoreService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    protected readonly productService: ProductService
  ) {
    this.vendorOrdersForm = this.fb.group({
      vendorOrders: this.fb.array([]),
      initials: ['', Validators.required],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.setVendorOrderForms(this.vendorOrders);
    this.cdRef.detectChanges();
  }

  setVendorOrderForms(vendorOrders: VendorOrders) {
    this.vendorOrdersForm = VendorOrderForm.initVendorOrdersForm(vendorOrders);
    this.dataSource = new MatTableDataSource<FormControl>(
      this.vendorOrdersForm.controls.vendorOrders['controls']
    );
    this.dataSource2 = new MatTableDataSource<boolean>([true]);
    this.cdRef.detectChanges();
  }

  // addVendorOrderFormItem(vendorOrder: VendorOrder) {
  //   const control = <FormArray>this.vendorOrdersForm.get('vendorOrders');
  //   const itemsCtrl = this.initVendorOrderFormItem(vendorOrder);
  //   control.push(itemsCtrl);
  //   return itemsCtrl;
  // }

  // initVendorOrderFormItem(vendorOrder: VendorOrder) {
  // 	let productsFormArray:FormArray = this.fb.array([]);
  // 	for (var x = 0; x < vendorOrder.products.length; x++) {
  //     let productElement = <ProductElement>vendorOrder.products[x];
  //     let productFormGroup = ProductForm.initProductFormGroup(productElement.product);
  // 		productsFormArray.push(this.fb.group({
  //       '_id': productElement._id,
  // 			'cost': productElement.cost,
  //     	'pricingFormula': productElement.pricingFormula,
  // 			'retail': productElement.retail,
  // 			'duty': [{value:productElement.duty, disabled: true}],
  // 			'quantity': productElement.quantity,
  // 			'frame': this.fb.group(productElement.frame),
  // 			'product': productFormGroup,
  // 			'store': productElement.store,
  //       'type': productElement.type
  // 		}));
  // 	}
  //   let productElement = <ProductElement>vendorOrder.products[0];
  //   return this.fb.group({
  //     '_id': vendorOrder._id,
  //     'vendor': vendorOrder.vendor,
  //     'store': vendorOrder.store,
  //     'raNumber': [vendorOrder.raNumber, Validators.required],
  //     'date': vendorOrder.date,
  //     'shippingMethod': vendorOrder.shippingMethod,
  //     'trackingNumber': vendorOrder.trackingNumber,
  //     'status': vendorOrder.status,
  //     'shipping': vendorOrder.shipping,
  //     'proposedCredit': vendorOrder.proposedCredit,
  //     'tax': productElement.tax,
  //     'user': vendorOrder.user,
  //     'products': productsFormArray,
  //     'initials': ['', Validators.required],
  //     'log': this.fb.array(vendorOrder.log)
  //   });
  // }

  @HostListener('document:click', ['$event']) clickOutside($event) {
    // if (!this.fieldOpen) {
    //   if (this.vendorOrderId != '' && this.vendorOrderId != undefined) {
    //     //this.saveEditedProduct(this.editProduct, this.productIndex);
    //   }
    //   this.vendorOrderId = '';
    //   this.expandedElement = null;
    // } else {
    //   this.fieldOpen = false;
    // }
  }

  clickRow($event: Event, vendorOrder: FormGroup, index) {
    if (!this.fieldOpen) {
      this.vendorOrderId = vendorOrder.value._id;
      if (this.expandedElement == vendorOrder) {
        this.expandedElement = null;
      } else {
        this.expandedElement = vendorOrder;
        let productElements = <ProductElement[]>(
          this.vendorOrders.vendorOrders[index].products
        );
        this.dataSource3 = new MatTableDataSource<ProductElement>(
          productElements
        );
        this.cdRef.detectChanges();
      }
      $event.preventDefault();
      $event.stopPropagation();
      this.fieldOpen = false;
    } else {
      this.expandedElement = null;
    }
  }

  clickField() {
    this.fieldOpen = true;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));

    this.calculateTotal();
  }

  toggleSelect(vendorOrder: FormControl) {
    this.selection.clear();
    this.selection.toggle(vendorOrder);
    this.calculateTotal();
  }

  calculateTotal() {
    let total: number = 0;
    let tax: number = 0;
    for (let x = 0; x < this.selection.selected.length; x++) {
      total =
        total +
        this.formatFloat(this.selection.selected[x].value.proposedCredit);
      tax = tax + this.formatFloat(this.selection.selected[x].value.tax);
    }

    this.vendorOrdersForm.get('total').setValue(total);
    this.vendorOrdersForm.get('tax').setValue(tax);
    this.vendorOrdersForm.get('cost').setValue(total);
    this.vendorOrdersForm.get('taxPaid').setValue(tax);
    this.vendorOrdersForm.get('difference').setValue(0);
    this.calculateDifference();
  }

  calculateDifference() {
    let total = parseFloat(this.vendorOrdersForm.get('total').value);
    let taxPaid = parseFloat(this.vendorOrdersForm.get('taxPaid').value);
    let tax = parseFloat(this.vendorOrdersForm.get('tax').value);
    let cost = parseFloat(this.vendorOrdersForm.get('cost').value);
    this.vendorOrdersForm.get('difference').setValue(total - taxPaid - cost);
  }

  checkboxLabel(row?: FormControl, index?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      index + 1
    }`;
  }

  saveVendorOrder(vendorOrder: FormGroup) {
    this.fieldOpen = true;
    let logItem = new LogElement();
    logItem.initials = vendorOrder.value.initials;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'UPDATED';
    logItem.notes = '';
    logItem.date = Date.now().toString();

    vendorOrder.get('log').value.push(logItem);
    this.vendorOrderService
      .updateVendorOrder(vendorOrder.value._id, vendorOrder.value)
      .subscribe((vendorOrder2) => {
        this.vendorOrderId = null;
        this.calculateTotal();
      });
  }

  markVendorOrdersPaid() {
    this.fieldOpen = true;

    let logItem = new LogElement();
    logItem.initials = this.vendorOrdersForm.get('initials').value;
    logItem.user = this.auth.currentUser._id;
    logItem.item = 'PAID';
    logItem.notes = '';
    logItem.date = Date.now().toString();

    for (let x = 0; x < this.selection.selected.length; x++) {
      let vendorOrder = this.selection.selected[x].value;
      vendorOrder.status = 'CREDITED';
      vendorOrder.invoiceNumber =
        this.vendorOrdersForm.get('invoiceNumber').value;
      vendorOrder.dateReceived =
        this.vendorOrdersForm.get('dateReceived').value;
      vendorOrder.log.push(logItem);
      vendorOrder.tax =
        FormatData.formatFloat(this.vendorOrdersForm.get('tax').value) * -1;
      vendorOrder.total =
        FormatData.formatFloat(this.vendorOrdersForm.get('total').value) * -1;

      this.vendorOrderService
        .updateVendorOrder(vendorOrder._id, vendorOrder)
        .subscribe((vendorOrder2) => {
          this.refreshVendorOrders.emit(true);
        });
    }
  }

  refreshProposedCredit($event, vendorOrderFormGroup: FormGroup) {
    $event.preventDefault();
    $event.stopPropagation();
    let totalCost = 0;
    let vendorOrder: VendorOrder = vendorOrderFormGroup.value;
    for (let x = 0; x < vendorOrder.products.length; x++) {
      let cost = vendorOrder.products[x].cost as any;
      if (typeof cost === 'string') {
        cost = parseFloat(cost.replace(/,/g, ''));
      }
      totalCost = totalCost + cost;
    }
    vendorOrderFormGroup.get('proposedCredit').setValue(totalCost);
    // this.formatCurrency(null, vendorOrderFormGroup.get('proposedCredit'));
    this.calculateTotal();
  }

  formatCurrency($event, control) {
    control.setValue(FormatData.formatCurrency(control.value));
  }

  formatFloat(value: any) {
    return FormatData.formatFloat(value);
  }

  toggleSelectedFrame(productEl: ProductElement, vendorOrder: FormGroup) {
    const {
      product: { _id: productId },
      product,
    } = productEl;
    const { _id: orderId } = vendorOrder.value;
    if (!this.selectedFrames[orderId]) {
      this.selectedFrames[orderId] = {
        products: [product],
        vendorOrder: vendorOrder,
      };
      return;
    }

    const productIndex = this.selectedFrames[orderId].products.findIndex(
      (p) => p._id === productId
    );
    if (productIndex === -1) {
      this.selectedFrames[orderId].products = [
        ...(this.selectedFrames[orderId]?.products ?? []),
        product,
      ];
    } else {
      this.selectedFrames[orderId].products = this.selectedFrames[
        orderId
      ].products.filter((p, idx) => productIndex !== idx);
    }
    this.selectedFrameIds = this.allSelectedIds;
    this.cdRef.detectChanges();
  }

  removeFramesFromVendorOrder() {
    const updates = Object.values(this.selectedFrames).map(
      ({ products, vendorOrder: vo }): Observable<any> => {
        const vendorOrder = vo.value as VendorOrder;

        /* Add log in order  */
        const logItem: LogElement = {
          _id: new ObjectID().toHexString(),
          initials: this.auth.currentUser.name
            .split(' ')
            .map((item) => item[0].toUpperCase())
            .join(''),
          user: this.auth.currentUser._id,
          item: `RETURN FRAME${products.length > 1 ? 'S' : ''} TO INVENTORY`,
          date: new Date().toISOString(),
          notes: `Removed frames ${products
            .map((p) => p._id)
            .join(', ')} from the order`,
        };

        /* Remove returned status from each product */
        const productsUpdates = products.map((product) => {
          const status = null;
          const dateUpdated = new Date().toISOString();
          const quantity = !product.quantity ? 1 : product.quantity - 1;
          return this.updateProductStatus(product._id, {
            status,
            dateUpdated,
            quantity,
          });
        });

        /* Get product ids to remove */
        const removeProductIds = products.map((p) => p._id);
        return this.updateVendorOrder(
          vendorOrder._id,
          logItem,
          removeProductIds
        ).pipe(concatMap(() => forkJoin(productsUpdates)));
      }
    );

    combineLatest(updates).subscribe(() => {
      this.refreshVendorOrders.emit(true);
    });
  }

  updateProductStatus(
    productId: string,
    productUpdates: Partial<Product>
  ): Observable<any> {
    return this.productService.getProduct(productId).pipe(
      map((res) => ({ ...res, ...productUpdates })),
      concatMap((mergedProduct: Product) =>
        this.productService.updateProduct(productId, mergedProduct)
      )
    );
  }

  updateVendorOrder(
    orderId: string,
    logItem: LogElement,
    removeProductIds: string[]
  ) {
    return this.vendorOrderService.getVendorOrder(orderId).pipe(
      map((res) => {
        /* Add the new log item to existing log */
        const { log, framesRemoved } = res;
        res.log = [...(log ?? []), logItem];
        res.framesRemoved = [...(framesRemoved ?? []), ...removeProductIds];
        if (removeProductIds) {
          /* Remove products from vendor order */
          removeProductIds.forEach((productId) => {
            /* Remove product from vendor order */
            const productIndex = res.products.findIndex(
              (p) => p.product._id === productId
            );
            res.products = res.products.filter(
              (p, idx) => productIndex !== idx
            );
          });
        }
        /* Check if there are more products in the vendor order */
        if (res.products.length) {
          /* Update proposed credit */
          res.proposedCredit = res.products.reduce((acc, p) => {
            const cost = p.cost as any;
            if (typeof cost === 'string') {
              return acc + parseFloat(cost.replace(/,/g, ''));
            }
            return acc + cost;
          }, 0);
        } else {
          res.status = 'CANCELLED';
        }
        return res;
      }),
      concatMap((mergedOrder: VendorOrder) =>
        this.vendorOrderService.updateVendorOrder(orderId, mergedOrder)
      )
    );
  }
}
