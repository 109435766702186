<div class="container" style="width:100%" [formGroup]="orderForm">
	<mat-table #table [dataSource]="dataSource" matSort matSortActive="vendor" matSortDirection="asc" class="mat-elevation-z2" formArrayName="orders">
	  <ng-container matColumnDef="alert">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px">
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="vendor">
		  <mat-header-cell *matHeaderCellDef fxFlex="1 1 220px"> VENDOR </mat-header-cell>
		  <mat-cell *matCellDef="let order; let i=index" [formGroupName]="i" fxFlex="1 1 220px">
		  	<span *ngIf="orderId!=order.value._id && order.value.products[0].product.vendor"> {{ order.value.products[0].product.vendor.name }} </span>
		    <app-autocomplete-vendors
		    	placeholder="VENDOR"
	  			*ngIf="orderId==order.value._id"
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']"
		    	[(fieldOpen)]="fieldOpen"
		    	fxFlex="1 1 190px"></app-autocomplete-vendors>
		  </mat-cell>
		</ng-container>

	  <ng-container matColumnDef="model">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> MODEL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.model}}</span>
	    	<app-autocomplete-vendor-products
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']"
	    		[vendor]="order.value.products[0].product.vendor"
	  			placeholder="MODEL"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="model"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="color">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 8%"> COLOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 8%" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.color}}</span>
	    	<app-autocomplete-vendor-products
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']"
	    		[vendor]="order.value.products[0].product.vendor"
	  			placeholder="COLOR"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="color"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="size">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 50px"> SIZE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 50px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id">{{order.value.products[0].product.size}}</span>
	    	<app-autocomplete-vendor-products
	  			[(productForm)]="order.controls.products['controls'][0]['controls']['product']"
	    		[vendor]="order.value.products[0].product.vendor"
	  			placeholder="SIZE"
	  			*ngIf="orderId==order.value._id"
	  			(selected)="updateProduct($event,order,i)"
	  			[(fieldOpen)]="fieldOpen"
	  			displayField="size"
	  			>
				</app-autocomplete-vendor-products>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="type">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 60px"> TYPE </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 60px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id && order.value._id!=''">{{order.value.products[0].type}}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
						<mat-form-field *ngIf="orderId==order.value._id || order.value._id==''" floatLabel="never">
							<mat-select #formRow placeholder="TYPE" formControlName="type" (click)="clickField();$event.stopPropagation();" (selectionChange)="changeProductType($event,order)">
								<mat-option *ngFor="let typeOption of productTypeOptions" [value]="typeOption.value">
									{{typeOption.viewValue}}
								</mat-option>
							</mat-select>
		        </mat-form-field>
	        </ng-container>
		    </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="status">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 40px"> </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 40px" [ngClass]="order.value.products[0].product.status">
	      <span>&nbsp;</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="cost">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 1 60px"> COST </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" class="alignRightCell" fxFlex="0 1 60px" [formGroupName]="i">
	      <span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].cost | currency : 'USD' : 'symbol' : '1.0' }}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
			      <mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
			        <input #formRow matInput placeholder="COST" type="text" formControlName="cost" (keyup)="changeCost(order)">
			      </mat-form-field>
			    </ng-container>
		    </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="priceLink">
	    <mat-header-cell *matHeaderCellDef fxFlex="0 0 35px">  </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 35px" [formGroupName]="i">
	      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula!='c'">link_outline</mat-icon>
	      <mat-icon (click)="togglePriceLink($event,order)" *ngIf="order.value.products[0].pricingFormula=='c'" color="warn">link_off_outline</mat-icon>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="retail">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 1 60px"> RETAIL </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" class="alignRightCell" fxFlex="0 1 60px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id || (!authorized && order.value.products[0].product._id)">{{order.value.products[0].retail | currency : 'USD' : 'symbol' : '1.0' }}</span>
	    	<ng-container formGroupName="products">
		    	<ng-container [formGroupName]="0">
			    	<mat-form-field *ngIf="orderId==order.value._id && (authorized || !order.value.products[0].product._id)" floatLabel="never">
			        <input #formRow matInput placeholder="RETAIL" type="text" formControlName="retail" (keyup)="changeRetail(order)">
			      </mat-form-field>
			    </ng-container>
			  </ng-container>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="patientStock">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 110px"> ORDER FOR </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 110px" [formGroupName]="i">
	    	<span>{{order.value.products[0].frame.frameSource}}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="patient">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 10%"> PATIENT </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 10%" [formGroupName]="i">
	      <button mat-button [routerLink]="['/admin/patient', order.value.patient._id]" style="width:100%;text-transform:uppercase;">{{order.value.patient.lastName}}, {{order.value.patient.firstName}}</button>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="invoiceNumber">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="1 1 110px"> INVOICE # </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="1 1 110px" [formGroupName]="i">
	    	<span *ngIf="orderId!=order.value._id && order.value.invoiceNumber">{{order.value.type}}&nbsp;&ndash;&nbsp;{{order.value.invoiceNumber }}</span>
	    	<mat-form-field *ngIf="orderId==order.value._id" floatLabel="always">
				  <mat-select placeholder="TYPE" formControlName="type" style="width:30px;" (click)="clickField()">
						<mat-option *ngFor="let orderTypeOption of orderTypeOptions" [value]="orderTypeOption.value">
							{{ orderTypeOption.viewValue }}
						</mat-option>
					</mat-select>
				</mat-form-field>
	    	<mat-form-field *ngIf="orderId==order.value._id" >
		      <input matInput placeholder="INVOICE #" formControlName="invoiceNumber" (click)="clickField()">
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="todo">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> TO-DO </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 100px" [formGroupName]="i">
	    	<span>{{ order.value.todo }}</span>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="notes">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> NOTES </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 100px" [formGroupName]="i">
	      <span *ngIf="orderId!=order.value._id">{{ order.value?.notes }}</span>
	      <mat-form-field *ngIf="orderId==order.value._id" style="width:125px;flex: 0 1 125px;padding-left:10px;padding-right:10px;" floatLabel="never">
		      <input matInput placeholder="NOTES" formControlName="notes" (click)="clickField()">
		    </mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="eta">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 100px"> ETA </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 100px" [formGroupName]="i">
				<span *ngIf="orderId!=order.value._id"> {{ order.value.eta | date:'M/d/yyyy': 'GMT' }} </span>
	      <mat-form-field *ngIf="orderId==order.value._id" floatLabel="never">
				  <input #formRow [matDatepicker]="picker" matInput placeholder="ETA" type="text" formControlName="eta" (dateChange)="clickDate($event,order,i)">
				  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	      	<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="action">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 150px"></mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index;" fxFlex="0 0 150px" [formGroupName]="i">
	    	<mat-form-field style="width:35px;flex: 0 1 35px;padding-left:10px;" floatLabel="never">
		      <input matInput required placeholder="" type="text" formControlName="initials" (click)="$event.stopPropagation()">
		      <mat-label><mat-icon>gesture</mat-icon></mat-label>
		    </mat-form-field>
	      <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon><span>SAVE</span></button>
	      <mat-menu #menu="matMenu">
	    	 	<button mat-menu-item type="button" (click)="saveFrame(order)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid">SAVE CHANGES</button>
	        <button mat-menu-item type="button" (click)="orderFrame(order,i)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">ORDERED FRAME</button>
	        <button mat-menu-item type="button" (click)="onCancelFrame(order)" style="margin-left:10px;" [disabled]="order.controls.initials.invalid || order.controls.invoiceNumber.invalid">CANCEL ORDER</button>
	      </mat-menu>
	    </mat-cell>
	  </ng-container>

	  <ng-container matColumnDef="log">
	    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="0 0 300px"> LOG </mat-header-cell>
	    <mat-cell *matCellDef="let order; let i=index; let row;" fxFlex="0 0 300px">
	    	<app-log-menu [order]="order" (clickField)="clickField()"></app-log-menu>
	    </mat-cell>
	  </ng-container>

	  <mat-header-row
	  	*matHeaderRowDef="displayedColumns"
	  	fxLayout="row"
	  	fxLayoutAlign="space-between center">
	  </mat-header-row>
	  <mat-row
	  	*matRowDef="let row; columns: displayedColumns; let i=index; let even=even; let odd=odd;"
	  	fxLayout="row"
	  	fxLayoutAlign="space-between center"
	  	appHighlight
	  	[ngClass]="{rowEven:even, rowOdd:odd}"
	  	style="border-left:1px solid #dfdfdf !important;"
	  	(click)="clickOrder($event, row, i)">
	  </mat-row>
	</mat-table>
</div>
