import { Component, OnInit , Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-prescription-side',
  templateUrl: './prescription-side.component.html',
  styleUrls: ['./prescription-side.component.css']
})
export class PrescriptionSideComponent implements OnInit {
  @Input() public label:string;
  @Input() public formGroup:FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
