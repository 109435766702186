import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { PrintService } from '@services/print.service';
import { Subject, filter, take, takeUntil, tap } from 'rxjs';
import { getStoreClass, removeStoreClasses } from '../v2/shared/utils/store';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  stores: Array<any>;
  unsubscribe$ = new Subject<void>();
  constructor(
    public readonly pS: PrintService,
    public readonly aS: AlertService,
    private readonly authS: AuthService,
    private readonly router: Router,
    private ren: Renderer2
  ) {}

  ngOnInit(): void {
    this.authS
      .getAllStores()
      .pipe(
        take(1),
        tap((stores) => {
          this.stores = stores;
          this.onRouteChange();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRouteChange() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const storeName = getStoreClass(this.authS.getStoreObject()?.storeName);
        const storeAdded =
          storeName && document.body.classList.contains(storeName);
        if (storeName && !storeAdded) {
          removeStoreClasses(this.stores, this.ren);
          this.ren.addClass(document.body, storeName);
        }
      });
  }
}
