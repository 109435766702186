import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { GalleryComponent } from '@components/admin/dialog/gallery/gallery.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from '@components/admin/dialog/delete/delete.component';
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Order } from '@models/order';
import { OrderForm } from '@classes/order-form';
import { OrderService } from '@services/order.service';
import { environment } from '@environments/environment';
import { takeWhile } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-form-order-image-group',
  templateUrl: './form-order-image-group.component.html',
  styleUrls: ['./form-order-image-group.component.css'],
})
export class FormOrderImageGroupComponent implements OnInit {
  @Input() public orders: Order[];
  @Output() clickReturn = new EventEmitter<any>();
  @Output() clickExchange = new EventEmitter<any>();
  @Output() clickSetAsPrescription = new EventEmitter<any>();
  formGroup: FormGroup;
  ordersFormArray: FormArray;
  selection: SelectionModel<FormGroup> = new SelectionModel<FormGroup>(
    true,
    []
  );
  imageURL = environment.base_url + '/image/';

  images: Array<any> = [];
  loading: boolean = true;

  constructor(
    private auth: AuthService,
    public storeService: StoreService,
    private orderService: OrderService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    this.formGroup = this.fb.group({});
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.formGroup.addControl(
      'orders',
      OrderForm.initOrderFormArray(
        this.orders as Order[],
        this.auth,
        this.storeService.stores
      )
    );
    this.ordersFormArray = this.formGroup.get('orders') as FormArray;

    // let orderFormArray = OrderForm.initOrderFormArray(patient.orders as Order[], auth, storeOptions);

    // this.orders = this.parentForm.controls.orderForms['controls'];
    this.loading = false;
    this.cdRef.detectChanges();

    this.selection.changed.subscribe((selector) => {
      this.updateSelection();
    });
  }

  compareOrders() {
    const dialogRef = this.dialog.open(GalleryComponent, {
      width: '90%',
      height: '90%',
      data: {
        images: this.images,
        message: 'COMPARE ORDERS',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  updateSelection() {
    const timestamp = new Date().getTime();
    this.images = [];
    this.selection.selected.forEach((order: FormGroup) => {
      for (let x = 0; x < order.value.images.length; x++) {
        this.images.push(order.value.images[x]);
      }
    });
  }

  deleteOrder(inputObj: any): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '250px',
      data: { message: 'DELETE ORDER?' },
    });
    dialogRef
      .afterClosed()
      .pipe(takeWhile((result) => !!result))
      .subscribe(() => {
        this.orderService.deleteOrder(inputObj._id).subscribe((order) => {
          this.ordersFormArray.removeAt(inputObj.index);
          this.notificationService.show('success', 'ORDER DELETED', 'OK');
        });
      });
  }

  returnOrder(inputObj) {
    this.clickReturn.emit(inputObj);
  }

  exchangeOrder(inputObj) {
    this.clickExchange.emit(inputObj);
  }

  setOrderAsPrescription(inputObj) {
    this.clickSetAsPrescription.emit(inputObj);
  }
}
