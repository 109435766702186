import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@models/store';
import { StoreService } from '@services/store.service';
import { AuthService } from '@services/auth.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { map, Subject, take, takeUntil, tap } from 'rxjs';
import { ApiResponse } from '@classes/api-response';
import { Vendor } from '@models/vendor';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import ObjectID from 'bson-objectid';
import { VendorService } from '@services/vendor.service';
import { VendorOrderService } from '@services/vendor-order.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class ManagedBoardInvoiceComponent implements OnInit, OnDestroy {
  currentStore: Store = new Store();
  unsubscribe$ = new Subject<void>();
  invoiceForm: FormGroup;
  logForm: FormGroup;
  vendors: Array<Vendor>;
  today = new Date().toISOString();
  storeColor = localStorage.getItem('storeColor');

  private readonly staticFields = {
    type: 'order',
    borrowingStore: null,
    groupId: '',
    raNumber: '',
    trackingNumber: '',
    proposedCredit: '',
    datePaid: null,
    archived: false,
    store: this.currentStore._id,
  };

  constructor(
    private readonly vendorOrderService: VendorOrderService,
    private readonly vendorService: VendorService,
    private readonly storeService: StoreService,
    private readonly auth: AuthService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.auth
      .getStoreObjectMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((store: Store) => {
        this.currentStore = store;
      });
  }

  ngOnInit() {
    this.setupForm();
    this.getVendors();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getVendors() {
    this.vendorService
      .getVendorList()
      .pipe(
        take(1),
        tap((vendors: Array<Vendor>) => {
          this.vendors = vendors.filter((v) => !v.distributor);
        })
      )
      .subscribe();
  }

  mapVendors(res: ApiResponse<any[]>): Array<Vendor> {
    return res.vendorOrderCounts
      ?.filter((voc) => !!voc.vendor)
      .map((voc: any) => voc.vendor);
  }

  setupForm() {
    this.logForm = new FormGroup({
      _id: new FormControl(new ObjectID().toString()),
      notes: new FormControl(),
      initials: new FormControl(null, Validators.required),
      date: new FormControl(new Date().toISOString()),
      user: new FormControl(this.auth.currentUser._id),
    });
    this.invoiceForm = new FormGroup({
      _id: new FormControl(new ObjectID().toString()),
      vendor: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      products: new FormArray([]),
      status: new FormControl('RECEIVED'),
      order: new FormArray([]),
      shipping: new FormControl(null),
      tax: new FormControl(null),
      total: new FormControl(null, Validators.required),
      log: new FormArray([this.logForm]),
      store: new FormControl(this.currentStore._id),
      invoiceNumber: new FormControl(null, Validators.required),
    });
    this.cd.detectChanges();
  }

  saveInvoice() {
    const {
      value,
      value: { shipping, tax, total, date },
    } = this.invoiceForm;
    const payload = {
      ...this.staticFields,
      ...value,
      log: [this.logForm.value],
      shipping: +Number(shipping ?? 0).toFixed(2),
      tax: +Number(tax ?? 0).toFixed(2),
      total: +Number(total).toFixed(2),
      dateAdded: date,
    };
    this.vendorOrderService
      .addVendorOrder(payload)
      .pipe(
        take(1),
        tap(() => this.invoiceForm.reset())
      )
      .subscribe();
  }
}
